<template>
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    id="offcanvasBottom"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <div class="offcanvas-title" id="offcanvasBottomLabel">
        <h5 class="mb-4">{{ $t("Add new meal") }}</h5>
        <p>{{ $t("enter this data to continue") }}</p>
      </div>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body small">
      <form @submit.prevent="addMeal()">
        <div class="row mb-5 mt-3">
          <div class="col-lg-4">
            <label>{{ $t("Meal Name in arabic") }}</label>
            <input
              type="text"
              :placeholder="$t('meal name')"
              v-model="mealName_ar"
            />
          </div>
          <div class="col-lg-4">
            <label>{{ $t("Meal Name in english") }}</label>
            <input
              type="text"
              :placeholder="$t('meal name')"
              v-model="mealName_en"
            />
          </div>
          <div class="col-lg-4 mt-3">
            <div class="card flex justify-content-center border-0">
              <label>{{ $t("default") }}</label>
              <Checkbox v-model="checked" :binary="true" />
            </div>
          </div>
        </div>

        <button type="submit" class="add save btn">{{ $t("add") }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

import Checkbox from "primevue/checkbox";

export default {
  emits: ["fetch-data"],
  data() {
    return {
      mealName_ar: "",
      mealName_en: "",
      checked: false,
    };
  },
  methods: {
    addMeal() {
      axios
        .post("/meal_types", {
          title_en: this.mealName_en,
          title_ar: this.mealName_ar,
          is_default: this.checked ? 1 : 0,
        })
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$emit("fetch-data");
          this.$router.push("/meal");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  components: {
    Checkbox,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../view/dashboard/wallet/_wallet.scss";
h5 {
  color: #0b1427;
  font-weight: 700;
  font-size: 32px;
}
p {
  font-size: 24px;
  color: #656b78;
}
form {
  label {
    display: inline-block;
    margin-bottom: 10px;
    font-family: "bold" !important;
  }
  input,
  select {
    display: block;
    width: 100% !important;
    // height: 68px;
    border-radius: 4px;
    border: 1px solid #9ca2ac !important;
    color: #9ca2ac;
    padding: 10px 15px;
  }
  i {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: #0a909b;
    color: #fff;
    text-align: center;
    line-height: 58px;
    font-size: 24px;
    margin-inline-start: 15px;
    cursor: pointer;
  }
}
</style>
