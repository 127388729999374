<template>
  <section class="all_emp card_style">
    <div class="row options mb-2">
      <div class="col-3">
        <div class="main_header_style">
          <h4>{{ $t("Meals") }}</h4>
        </div>
      </div>
      <form class="col-4">
        <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
        <input class="sear" type="text" :placeholder="$t('search by name')" />
      </form>

      <div class="col-5">
        <button
          class="PrimaryButton btn w-50"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasBottom"
          aria-controls="offcanvasBottom"
        >
          <i class="fa-solid fa-plus"></i> {{ $t("New Meal") }}
        </button>
      </div>
      <addMeal @fetch-data="reloadData" />
    </div>

    <div class="row">
      <table class="table" id="ViewTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(meal, index) in meals.data" :key="index">
            <td>{{ meal.meal_type_id }}</td>
            <td>{{ meal.title }}</td>
            <td>
              <router-link
                :to="`/meal/edit/${meal.meal_type_id}`"
                class="btn btn-success text-white mx-1"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1"
                @click="deleteMeal(meal.meal_type_id, index)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="meals"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchMealData"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import addMeal from "../../../../components/room/meal/addMeal.vue";

export default {
  name: "allMeals",
  components: {
    addMeal,
    pagination,
  },
  data() {
    return {
      meals: [],
    };
  },
  methods: {
    fetchMealData(page = 1) {
      axios
        .post(`/fetch_meal_types?page=${page}`, { count: 10 })
        .then(({ data }) => {
          console.log(data.data);
          this.meals = data.data;
        });
    },
    deleteMeal(mealId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios
            .delete(`/meal_types/${mealId}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.meals.data.splice(index, 1);
              } else {
                Swal.fire("", data.message, "error");
              }
            })
            .catch((error) => {
              if(error.response.status != 403){
                Swal.fire("", error.message, "error");
              }
            });
        }
      });
    },
    reloadData() {
      this.fetchMealData();
    },
  },
  created() {
    this.fetchMealData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "../_room";
</style>
