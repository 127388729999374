<template>
  <section class="card_style tree_organization">
    <div class="main_header_style">
      <h4>{{ $t("branch_detials") }}({{ tree.title_ar }})</h4>
      <p class="border border-dark fw-bold rounded-1 p-2">
        {{ $t("balance") }}:{{ tree.balance }}
      </p>
    </div>

    <div class="row align-items-center mb-2 justify-content-center">
      <div class="col-12 col-lg-4">
        <div class="input-group">
          <datepicker
            v-model="from"
            name="from_date"
            :placeholder="$t('from')"
          ></datepicker>
        </div>
      </div>

      <div class="col-12 col-lg-3">
        <div class="input-group">
          <datepicker
            v-model="to"
            name="to_date"
            :placeholder="$t('to')"
          ></datepicker>
        </div>
      </div>

      <div class="col-12 col-lg-3">
        <!-- filtter with cost_Center -->
        <div class="input-group">
          <!-- <label>{{ $t("Cost_center") }}</label> -->
          <multiselect
            v-model="costCenter"
            :options="costCenters"
            :clear-on-select="true"
            :placeholder="$t('Cost_center')"
            label="title"
            track-by="id"
            select-label=""
            :hide-selected="true"
          ></multiselect>
        </div>
      </div>
      <div class="col-lg-2">
        <button class="btn PrimaryButton w-100" v-print="'#printMe'">
          {{ $t("print") }}
          <i class="fa-solid fa-print"></i>
        </button>
      </div>

      <!-- filtter with brach id  -->
      <!-- <div class="col-12 col-lg-4">
        <div class="input-group">
          <multiselect
            v-model=""
            :options=""
            :clear-on-select="true"
            :placeholder="$t('branch_id')"
            label="title"
            track-by="id"
            select-label=""
            :hide-selected="true"
          ></multiselect>
        </div>
      </div> -->
    </div>
    <div v-if="!branch?.data?.length && !loading">
      <div class="d-flex align-items-center justify-content-center">
        <img src="@/assets/media/image/no-data.png" class="img-fluid" alt="" />
      </div>
      <p class="text-center my-5 fs-3">{{ $t("Please Enter Data") }}</p>
    </div>

    <div class="row table-responsive" id="printMe" v-else>
      <HeaderPdf
        :title="$t('branch_detials') + ' ' + tree.title_ar"
        :fromDate="from"
        :toDate="to"
      />
      <table class="table" id="RoomTable">
        <thead>
          <tr>
            <th class="no-print">ID</th>
            <th>{{ $t("time") }}</th>
            <th>{{ $t("Cost_center") }}</th>
            <th>{{ $t("creditor") }}</th>
            <th>{{ $t("debtor") }}</th>
            <th>{{ $t("balance") }}</th>
            <th class="w-25">{{ $t("the statement") }}</th>
            <!-- <th>{{ $t("clerck") }}</th> -->
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="client in 10" :key="client">
            <td v-for="client in 9" :key="client" class="no-print">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(client, index) in branch.data" :key="index">
            <td class="no-print">
              <button
                @click="getTransferItem(client.daily_restrictions_id)"
                class="btn text-primary"
                type="button"
                data-bs-toggle="offcanvas"
                :data-bs-target="`#offcanvasBottomDetails${item.id}`"
                aria-controls="offcanvasBottom"
                :title="$t('details')"
              >
                {{ client.daily_restrictions_id }}
              </button>
            </td>
            <td>{{ client.date }}</td>
            <td>
              {{ client.cost_center?.title }}
            </td>
            <td>{{ client.creditor }}</td>
            <td>{{ client.debtor }}</td>
            <td>
              {{
                client.balance >= 0
                  ? client.daily_balance
                  : "(" + client.daily_balance + ")"
              }}
            </td>
            <td>{{ client.report }}</td>
            <!-- <td>{{ client.employee }}</td> -->
            <!-- <td class="no-print">
              <button
                @click="getTransferItem(client.daily_restrictions_id)"
                class="btn btn-info"
                type="button"
                data-bs-toggle="offcanvas"
                :data-bs-target="`#offcanvasBottomDetails${item.id}`"
                aria-controls="offcanvasBottom"
                :title="$t('details')"
              >
                {{ client.daily_restrictions_id }}
              </button>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="branch"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="Fetchbranch"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
  <detailsOffCanvas :item="item" v-if="item" />
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Datepicker from "vuejs3-datepicker";
import Multiselect from "vue-multiselect";
import detailsOffCanvas from "@/components/dailyConstruct/detailsOffCanvas.vue";
import moment from "moment";
import HeaderPdf from "@/components/pdf/headerPdf.vue";

import Skeleton from "primevue/skeleton";
export default {
  name: "detials-branch",
  components: {
    pagination,
    Datepicker,
    Multiselect,
    detailsOffCanvas,
    Skeleton,
    HeaderPdf,
  },
  data() {
    return {
      branch: [],
      tree: {},
      from: "",
      to: "",
      costCenter: {
        id: null,
        title: this.$t("show_all"),
        value: "show_all",
      },
      costCenters: [{ title: this.$t("show_all"), value: "show_all" }],
      item: {},
      branch_id: this.$route.params.id,
      cost_center_id: this.$route.params.cost_center_id,
      loading: true,
    };
  },
  watch: {
    from: "Fetchbranch",
    to: "Fetchbranch",
    costCenter: function (newCostCenter) {
      if (newCostCenter === null) {
        this.Fetchbranch(null); // Pass null as the costCenter parameter
      } else {
        this.Fetchbranch(); // Call Fetchbranch without the costCenter parameter
      }
    },
  },
  methods: {
    getTransferItem(daily_restrictions_id) {
      axios
        .get(`/daily_restriction/${daily_restrictions_id}`)
        .then(({ data }) => {
          this.item = data.data;
          // console.log(this.item);
        });
    },
    // fetch suppliers data
    async Fetchbranch(page = 1) {
      if (this.costCenter?.value === "show_all") {
        if (this.$route.params.type == "cost_center") {
          const formData = new FormData();
          formData.append("cost_center_id", this.cost_center_id);
          formData.append("branch_id", this.branch_id);
          if (this.from) {
            formData.append("from", moment(this.from).format("YYYY-MM-DD"));
          }
          if (this.to) {
            formData.append("to", moment(this.to).format("YYYY-MM-DD"));
          }
          await axios
            .post(`/fetch_restrictions_for_branch?page=${page}`, formData)
            .then(({ data }) => {
              this.branch = data.data;
              // console.log(this.branch);
              this.loading = false;
            });
        } else {
          const formData = new FormData();
          formData.append("branch_id", this.branch_id);
          if (this.from) {
            formData.append("from", moment(this.from).format("YYYY-MM-DD"));
          }
          if (this.to) {
            formData.append("to", moment(this.to).format("YYYY-MM-DD"));
          }
          await axios
            .post(`/fetch_restrictions_for_branch`, formData)
            .then(({ data }) => {
              this.branch = data.data;
              this.loading = false;
            });
        }
      } else {
        if (this.$route.params.type == "cost_center") {
          const formData = new FormData();
          formData.append("branch_id", this.branch_id);
          formData.append("cost_center_id", this.cost_center_id);
          if (this.from) {
            formData.append("from", moment(this.from).format("YYYY-MM-DD"));
          }
          if (this.to) {
            formData.append("to", moment(this.to).format("YYYY-MM-DD"));
          }
          await axios
            .post(`/fetch_restrictions_for_branch`, formData)
            .then(({ data }) => {
              this.branch = data.data;
              this.loading = false;
            });
        } else {
          const formData = new FormData();
          formData.append("branch_id", this.branch_id);
          if (this.from) {
            formData.append("from", moment(this.from).format("YYYY-MM-DD"));
          }
          if (this.to) {
            formData.append("to", moment(this.to).format("YYYY-MM-DD"));
          }
          await axios
            .post(`/fetch_restrictions_for_branch`, formData)
            .then(({ data }) => {
              this.branch = data.data;
              this.loading = false;
            });
        }
      }
    },
    async fetch_cost_center() {
      await axios.post("/fetch_cost_center").then(({ data }) => {
        this.costCenters.push(...data.data.data);
      });
    },
    async fetchTreeData() {
      await axios.get("tree_branch/" + this.branch_id).then(({ data }) => {
        this.tree = data.data;
        // console.log(this.tree);
      });
    },
  },

  async mounted() {
    await this.Fetchbranch();
    await this.fetch_cost_center();
    await this.fetchTreeData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.vuejs3-datepicker {
  width: 90%;
}

@media print {
  .no-print {
    display: none !important;
  }
  .table {
    thead {
      tr {
        th {
          padding-inline: 1.5rem !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-inline: 1.5rem !important;
        }
      }
    }
  }
}
</style>
