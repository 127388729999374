<template>
  <section class="card_style">
    <div class="container">
      <div class="main_header">
        <h3>add season</h3>
      </div>
      <form @submit.prevent="sendData()">
        <div class="row">
          <div class="col-lg-12 col-12"></div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          {{$t("season name")}}
                      </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                          <div class="form-group" v-for="(season , index) in seasonsName" :key="index">
                              <label for="name" class="form-label">{{$t("season name")}} {{season.lang}}</label>
                              <input type="text" :placeholder="$t('enter season name')" class="form-control" :id="`name${index}`" v-model="season.name" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <label>{{$t("period_from")}}</label>
            <!-- <datepicker
              v-model="fromDate"
              name="fromDate"
              :placeholder="$t('from')"
              :disabled-dates="{
                to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
              }"
            ></datepicker> -->
            <VueDatePicker
                  v-model="fromDate"
                  locale="en-US"
                  time-picker-inline
                />
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <label>{{$t("period_to")}}</label>
            <!-- <datepicker
              v-model="toDate"
              name="toDate"
              :placeholder="$t('from')"
              :disabled-dates="{
                to: new Date(new Date(fromDate).getTime() + 24 * 60 * 60 * 1000),
              }"
            ></datepicker> -->
            <VueDatePicker
                  v-model="toDate"
                  locale="en-US"
                  time-picker-inline
                />
          </div>
        </div>
        <button type="submit" class="btn PrimaryButton seasons">{{$t("save")}}</button>
      </form>
    </div>
  </section>
</template>

<script>
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: "flight-season-add",
    components: {
      VueDatePicker,
    },
    data(){
      return{
        languages:["ar","en","id"],
        seasonsName:[],
        fromDate: new Date(),
        toDate: new Date(new Date(this.fromDate).getTime() + 24 * 60 * 60 * 1000),
        contract_id: this.$route.params.id
      }
    },
    methods:{
      setSeasonName(){
        this.languages.forEach((language)=>{
          this.seasonsName.push({
            lang: language,
            name: ""
          })
        })
      },
      sendData(){
        let seasonData = {
          flight_contract_id: this.contract_id,
          from_date: moment(this.fromDate).format("YYYY-MM-DD"),
          to_date: moment(this.toDate).format("YYYY-MM-DD"),
          from_time: moment(this.fromDate).format("hh:mm:ss"),
          to_time: moment(this.toDate).format("hh:mm:ss")
        }
        this.seasonsName.forEach((season)=>{
          seasonData['name_'+season.lang] = season.name
        })

        axios.post("/flight_season" , seasonData).then(({data})=>{
          Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
          });
          this.$router.push(`/flight-contract/${this.contract_id}/flight-season`);
        }).catch((error)=>{
            Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
            });
        })
      },
    },
    mounted(){
      this.setSeasonName();
    }
}
</script>

<style>

</style>