<template>
  <section class="card_style">
    <div class="main_header mb-3">
      <h4>
        <router-link :to="`/hotels/book_offline/${$route.params.id}`" target="_blank"
          >{{ $route.params.serial_book }} &nbsp; </router-link
        >{{ $t("hotel_book_offline_attachments") }}
      </h4>
      <div class="">
        <router-link
          :to="`/hotels/book_offline/${$route.params.id}/attachments/add`"
          class="PrimaryButton btn"
          ><i class="fa-solid fa-plus"></i> {{ $t("add") }}
        </router-link>
      </div>
    </div>
    <div class="payment_methods">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>{{ $t("id") }}</th>
            <th>{{ $t("image") }}</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("Suplier") }} / {{ $t("Client") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td>
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
            <td width="10%">
              <Skeleton shape="circle" size="5rem"></Skeleton>
            </td>
            <td v-for="item in 3" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in attachments" :key="item.id">
            <td>{{ item?.id }}</td>
            <td>
              <!-- Button trigger modal -->
              <span
                data-bs-toggle="modal"
                :data-bs-target="`#attachmentModal${item?.id}`"
                v-if="isPDF(item?.attachment)"
                class="btn btn-info"
              >
                <i class="fa-solid fa-file-pdf"></i>
              </span>
              <img
                v-else
                data-bs-toggle="modal"
                :data-bs-target="`#attachmentModal${item?.id}`"
                :src="item?.attachment"
                alt="image"
                class="attach_img"
              />
              <!-- Modal -->
              <div
                class="modal fade"
                :id="`attachmentModal${item?.id}`"
                tabindex="-1"
                aria-labelledby="attachmentModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-body d-flex justify-content-center">
                      <!-- Check if the attachment is a PDF -->
                      <template v-if="isPDF(item?.attachment)">
                        <!-- Display PDF -->
                        <iframe
                          width="375"
                          height="500"
                          :src="item?.attachment"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </template>
                      <template v-else>
                        <!-- Display image -->
                        <img :src="item?.attachment" alt="image" width="375" />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <img :src="item?.attachment" alt="image" class="attach_img" /> -->
            </td>
            <td>{{ item?.title }}</td>
            <td>{{ item.type == 2 ? $t("Suplier") : $t("Client") }}</td>
            <td>
              <router-link
                class="btn btn-success mx-1 w-auto"
                :to="`/hotels/book_offline/${item.offline_booking_hotel_id}/attachments/${item.id}`"
                :title="$t('edit')"
              >
                <i class="fa-solid fa-pen"></i>
              </router-link>
              <div
                v-if="item?.status !== 2"
                class="btn btn-danger mx-1 w-auto"
                @click="deleteItem(index)"
              >
                <i class="fa-solid fa-trash"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div class="row">
      <pagination
        :data="attachments"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchAttachment"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div> -->
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "hotelAttachments",
  data() {
    return {
      hotel_id: this.$route.params.id,
      serial_book: this.$route.params.serial_book,
      attachments: [],
      loading: true,
    };
  },
  methods: {
    async fetchAttachment(page = 1) {
      await axios
        .post(`/show_book_attachments_by_book_id?page=${page}`, {
          offline_booking_hotel_id: this.hotel_id,
        })
        .then(({ data }) => {
          this.attachments = data.data;
          this.loading = false;
        });
    },
    deleteItem(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.attachments[index].id;
          const fromClick = true;
          axios
            .delete("/delete_book_attachment/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.attachments.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    isPDF(url) {
      return url.toLowerCase().endsWith(".pdf");
    },
  },
  mounted() {
    this.fetchAttachment();
  },
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
.attach_img {
  max-width: 100px;
  max-height: 100px;
}
.modal-body {
  img {
    width: 375px;
  }
}
</style>
