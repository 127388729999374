<template>
  <section class="setting">
    <form class="card_style" @submit.prevent="addPaymentFees()">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
              <label for="">{{ $t("value") }}</label>
              <input
                type="number"
                name=""
                id=""
                class="form-control"
                v-model="valuePayment"
                :placeholder="$t('value')"
              />
              <span
                class="text-danger"
                v-if="v$.valuePayment.$error"
                v-text="v$.valuePayment.$errors[0].$message"
              ></span>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
              <label for="">{{ $t("percentages") }}</label>
              <input
                type="number"
                name=""
                id=""
                class="form-control"
                v-model="percentagePayment"
                :placeholder="$t('percentages')"
              />
              <span
                class="text-danger"
                v-if="v$.percentagePayment.$error"
                v-text="v$.percentagePayment.$errors[0].$message"
              ></span>
            </div>
          </div>
          <div class="col-12">
            <button type="submit" class="btn PrimaryButton">
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "setting-website",
  data() {
    return {
      v$: useVuelidate(),
      percentagePayment: null,
      valuePayment: null,
    };
  },
  validations() {
    return {
      percentagePayment: {
        required,
      },
      valuePayment: {
        required,
      },
    };
  },
  methods: {
    addPaymentFees() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const profitData = {
          value: this.valuePayment,
          percentage: this.percentagePayment,
        };
        axios
          .post("/payment_fees", profitData)
          .then(() => {
            // console.log(data)
            Swal.fire({
              title: "",
              text: this.$t("payment fees storeor updated successfully"),
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    paymentFees() {
      axios.get("/payment_fees").then(({ data }) => {
        this.percentagePayment = data.data.percentage;
        this.valuePayment = data.data.value;
      });
    },
  },
  created() {
    this.paymentFees();
  },
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0.25rem;
  font-size: 0.8rem !important;
}
.form-control {
  padding: 0.5rem !important;
  font-size: 0.75rem !important;
}
.PrimaryButton {
  display: block;
  width: fit-content;
  margin-inline-start: auto;
  margin-top: 0.5rem;
}

.setting {
  background-color: #fff;
  border-radius: 15px;
  width: 95%;
  padding: 2rem 0;
  margin: 1.5rem auto;
  form {
    .form-group {
      width: 100%;
      position: relative;
      margin-bottom: 1rem;
      label {
        font-family: "bold";
        color: #0a909b;
        margin-bottom: 0.7rem;
      }
      .form-control {
        font-size: 0.8rem;
        padding: 0.75rem;
        font-family: "regular";
        color: #000;
      }
      select {
        &.form-control {
          width: fit-content;
          padding: 0.75rem 1.5rem;
          border-color: transparent;
          position: absolute;
          top: 2.2rem;
          left: 0.25rem;
          appearance: auto;
          background-color: transparent !important;
          &:focus {
            box-shadow: unset !important;
            border-color: transparent !important;
          }
        }
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    .SecondButton {
      margin-inline-end: 1rem;
    }
    hr {
      margin: 2rem 0;
    }
  }
}
</style>
