<template>
  <section class="card_style">
    <div class="main_header">
        <h4 v-if="this.$route.params.id != undefined">{{$t("edit_meta")}}</h4>
        <h4 v-else>{{$t("add_meta")}}</h4>
    </div>
    <form @submit.prevent="add_meta()">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="form-label" for="title">{{$t("meta_title")}}</label>
                    <input type="text" class="form-control" id="title" v-model="meta_title" />
                </div>
            </div>

            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="form-label" for="desc">{{$t("meta_description")}}</label>
                    <textarea class="form-control" id="desc" v-model="meta_description"></textarea>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row" v-for="(meta , index) in meta_tags" :key="index">
                    <div class="col-11">
                        <div class="form-group">
                            <label class="form-label" for="tag">{{$t("meta_tag")}}</label>
                            <input type="text" class="form-control" id="tag" v-model="meta.meta_tag" />
                        </div>
                    </div>
                    <div class="col-1 d-flex align-items-center" v-if="index == 0">
                        <div class="circle" @click="add_meta_tag()">
                            <i class="fa-solid fa-plus"></i>
                        </div>
                    </div>
                    <div class="col-1 d-flex align-items-center" v-else>
                        <div class="circle remove" @click="remove_meta_tag(index)">
                            <i class="fa-solid fa-minus"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row" v-for="(meta , index) in meta_keywords" :key="index">
                    <div class="col-11">
                        <div class="form-group">
                            <label class="form-label" for="keyword">{{$t("meta_keywords")}}</label>
                            <input type="text" class="form-control" id="keyword" v-model="meta.meta_key_word" />
                        </div>
                    </div>
                    <div class="col-1 d-flex align-items-center" v-if="index == 0">
                        <div class="circle" @click="add_meta_keyword()">
                            <i class="fa-solid fa-plus"></i>
                        </div>
                    </div>
                    <div class="col-1 d-flex align-items-center" v-else>
                        <div class="circle remove" @click="remove_meta_keyword(index)">
                            <i class="fa-solid fa-minus"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <button type="submit" class="btn PrimaryButton">{{$t("save")}}</button>
            </div>
        </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    name: "addMeta",
    data(){
        return{
            meta_title: "",
            meta_description: "",
            meta_tags: [{
                meta_tag: ""
            }],
            meta_keywords: [{
                meta_key_word: ""
            }],
        }
    },
    methods:{
        add_meta_tag(){
            this.meta_tags.push({
                meta_tag: ""
            })
        },
        remove_meta_tag(index){
            this.meta_tags.splice(index , 1)
        },
        add_meta_keyword(){
            this.meta_keywords.push({
                meta_key_word: ""
            })
        },
        remove_meta_keyword(index){
            this.meta_keywords.splice(index , 1)
        },
        add_meta(){
            let meta_data = {
                meta_title: this.meta_title,
                meta_description: this.meta_description,
                meta_tags: this.meta_tags.map((ele)=> ele.meta_tag),
                meta_key_words: this.meta_keywords.map((ele)=> ele.meta_key_word)
            }

            if(this.$route.params.id != undefined){
                axios.post(`/update_meta/${this.$route.params.id}` , meta_data).then(({data})=>{
                    Swal.fire({
                        title: "",
                        text: data.message,
                        icon: "success",
                    });
                    this.$router.push(`/all_meta`);
                }).catch((error)=>{
                    Swal.fire({
                        title: "",
                        text: error.response.data.message,
                        icon: "error",
                    });
                });
            }
            else{
                axios.post(`/store_meta` , meta_data).then(({data})=>{
                    Swal.fire({
                        title: "",
                        text: data.message,
                        icon: "success",
                    });
                    this.$router.push(`/all_meta`);
                }).catch((error)=>{
                    Swal.fire({
                        title: "",
                        text: error.response.data.message,
                        icon: "error",
                    });
                });
            }
        },
        fetchMetaData(){
            axios.get(`/show_meta/${this.$route.params.id}`).then(({data})=>{
                this.meta_title = data.data.meta_title;
                this.meta_description = data.data.meta_description;
                this.meta_tags = data.data.met_tags;
                this.meta_keywords = data.data.met_key_words;
            })
        }
    },
    mounted(){
        if(this.$route.params.id != undefined){
            this.fetchMetaData();
        }
    }
}
</script>

<style lang="scss" scope>
@import "@/assets/scss/variables.scss";

.circle{
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: $PrimaryColor;
    color: #fff;
    border-radius: 50%;
    &.remove{
        background-color: red;
    }
}
</style>