<template>
  <section class="card_style">
    <!-- skelton -->
    <div class="row" v-if="loading && $route.params.id">
      <div class="col-12 my-3">
        <Skeleton width="20%" class="my-2"></Skeleton>
      </div>
      <div class="col-12 my-3" v-for="i in 2" :key="i">
        <Skeleton width="20%" class="my-2"></Skeleton>
        <Skeleton width="70%" height="2rem" class="my-2"></Skeleton>
      </div>
      <div class="col-12 my-3">
        <Skeleton width="10%" height="10rem"></Skeleton>
      </div>
    </div>
    <!-- skelton -->
    <form @submit.prevent="addBanner()" v-else>
      <div class="main_header">
        <h4 v-if="this.$route.params.id != undefined">
          {{ $t("edit_banner") }}
        </h4>
        <h4 v-else>{{ $t("add_banner") }}</h4>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label" for="banner_link">{{
              $t("banner_link")
            }}</label>
            <input
              type="url"
              class="form-control"
              id="banner_link"
              v-model="banner_link"
            />
            <span class="input-errors" v-if="v$.banner_link.$error"
              >{{ v$.banner_link.$errors[0].$message }}
            </span>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label class="form-label" for="banner_file">{{
              $t("banner_image")
            }}</label>
            <input
              type="file"
              class="form-control"
              id="banner_file"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              @change="onFileChange"
            />
          </div>

          <img
            :src="imageEntry"
            v-if="imageEntry != ''"
            class="entry_image"
            alt="entry"
          />
          <span class="input-errors" v-if="v$.imageEntry.$error"
            >{{ v$.imageEntry.$errors[0].$message }}
          </span>
        </div>

        <div class="col-12">
          <button type="submit" class="btn PrimaryButton">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import Skeleton from "primevue/skeleton";

export default {
  name: "addBanner",
  data() {
    return {
      v$: useVuelidate(),
      loading: true,
      banner_type: this.$route.params.type,
      banner_link: "",
      imageEntry: "",
      banner_id: this.$route.params.id,
    };
  },

  validations() {
    return {
      banner_link: { required },
      imageEntry: { required },
    };
  },

  methods: {
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    addBanner() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        formData.append(
          "type",
          this.banner_type == "hotels"
            ? "hotels"
            : this.banner_type == "flight"
            ? "flight"
            : this.banner_type == "package"
            ? "package"
            : ""
        );
        formData.append("link", this.banner_link);
        if (this.fileData instanceof File) {
          formData.append("image", this.fileData);
        }

        if (this.banner_id != undefined) {
          //  edit banner
          axios
            .post(`/update_banner/${this.banner_id}`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push(`/banners/${this.banner_type}`);
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          // add banner
          axios
            .post(`/store_banner`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push(`/banners/${this.banner_type}`);
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
    fetchBannerData() {
      axios.get(`/show_banner/${this.banner_id}`).then(({ data }) => {
        this.banner_link = data.data.link;
        this.imageEntry = data.data.image;
        this.loading = false;
      });
    },
  },
  mounted() {
    if (this.$route.params.id != undefined) {
      this.fetchBannerData();
    }
  },
  components: {
    Skeleton,
  },
};
</script>

<style></style>
