<template>
  <lottie-player
    :src="lottiePlayer"
    background="transparent"
    speed="1"
    loop
    autoplay
  ></lottie-player>
</template>

<script>
export default {
  name: "loader-component",
  data() {
    return {
      lottiePlayer: require("@/assets/lottiefiles/loader.json"),
    };
  },
};
</script>

<style scoped>
lottie-player {
  width: 100vw;
  height: 100vh;
}
</style>
