<template>
  <section class="show_receipt card_style">
    <div class="main_header_style"></div>
    <div class="row options mb-2">
      <div class="col-lg-4 col-md-12 col-12">
        <h4 class="head-we">{{ $t("All Flight Contract Season") }}</h4>
      </div>
      <form class="col-lg-4 col-md-12 col-12">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          :placeholder="$t('search by name')"
          v-model="titlenation"
          @keyup="FetchSeason"
          class="form-control"
        />
      </form>

      <div class="col-lg-4 col-md-12 col-12">
        <router-link
          :to="`/flight-contract/${this.contract_id}/flight-season/add`"
          class="PrimaryButton btn seasons"
          ><i class="fa-solid fa-plus"></i> {{ $t("Add Season") }}
        </router-link>
      </div>
    </div>

    <div class="row">
    <div class="table-responsive">
        <table class="table seasons" id="SeasonTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("type") }}</th>
            <th>{{ $t("show_start_date") }}</th>
            <th>{{ $t("show_end_date") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(season, index) in seasons.data" :key="index">
            <td> {{season.id}} </td>
            <td>{{ season.name }}</td>
            <td>{{season.from_date}}</td>
            <td>{{season.to_date}}</td>
            <td :data-label="$t('actions')">
 
                <router-link
                  class="btn edit_btn"
                  :title="$t('edit')"
                  :to="`/flight-contract/${this.contract_id}/flight-season/edit/${season.id}`"
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                </router-link>
   
                
                <router-link
                  class="btn edit_btn"
                  :title="$t('rate code')"
                  :to="`/flight-contract/${this.contract_id}/flight-season/${season.id}/rateCodes`"
                >
                  <i class="fa-solid fa-water"></i>
                </router-link>

                <button
                  class="btn remove_btn"
                  type="button"
                  :title="$t('delete')"
                  @click="deleteSeason(index)"
                >
                  <i class="fa-regular fa-trash-can"></i>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <div class="row">
      <pagination
        :data="seasons"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchSeason"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
export default {
  name: "show-flight-season",
  components: {
    pagination,
  },
  data() {
    return {
      contract_id: this.$route.params.id,
      seasons: [],
      titlenation: "",
    };
  },
  methods: {
    // fetch Seasons data
    FetchSeason(page = 1) {
      axios.get(`/flight_season?flight_contract=${this.contract_id}&page=${page}`).then(({ data }) => {
        this.seasons = data.data;
        // console.log(this.seasons);
      });
    },
    //delete expness
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.seasons.data[index].id;
          const fromClick = true;
          axios
            .delete("/flight_season/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.seasons.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchSeason();
  },
};
</script>
<style lang="scss" scoped>
@import "./_seasons.scss";
</style>
