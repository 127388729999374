<template>
  <div class="grid-container">
    <div
      class="grid-item"
      v-if="fromDates && $route.path !== '/rooming-list-report'"
    >
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("checkin") }} :</p>
        <p>{{ fromDates }}</p>
      </div>
    </div>
    <div class="grid-item" v-if="toDates">
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("checkout") }} :</p>
        <p>{{ toDates }}</p>
      </div>
    </div>
    <div class="grid-item" v-if="typeTransactionValue">
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("typeTransaction") }} :</p>
        <p>{{ typeTransactionValue?.title }}</p>
      </div>
    </div>
    <div class="grid-item" v-if="branch">
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("type") }} :</p>
        <p>{{ branch?.branch_name }}</p>
      </div>
    </div>
    <div class="grid-item" v-if="typeValue">
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("account_statement_to") }} :</p>
        <p>{{ typeValue?.title }}</p>
      </div>
    </div>
    <div class="grid-item" v-if="statusValues">
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("books_status") }} :</p>
        <p v-for="status in statusValues" :key="status?.id">
          {{ status?.title }} ,
        </p>
      </div>
    </div>
    <div class="grid-item" v-if="roomstatusValue">
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("room_status") }} :</p>
        <p v-for="room in roomstatusValue" :key="room?.id">
          {{ room?.title }} ,
        </p>
      </div>
    </div>
    <div class="grid-item" v-if="sent_to_accounts_value">
      <div class="d-flex align-items-center gap-1">
        <p class="fw-bold">{{ $t("sent_to_accounts") }} :</p>
        <p>
          {{ sent_to_accounts_value.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "fromDates",
    "toDates",
    "typeTransactionValue",
    "branch",
    "typeValue",
    "statusValues",
    "roomstatusValue",
    "sent_to_accounts_value",
  ],
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three equal columns */
  gap: 10px; /* Adjust spacing between grid items */
}

.grid-item {
  padding: 8px; /* Add padding inside each grid item */
}
</style>
