<template>
  <section class="rate_code card_style">
    <div class="title">
      <h4>{{ $t("Rate Code") }}</h4>
      <!-- <div class="contract_details">
            <span>{{$t("contract name")}} : </span>
            <span>{{$t("Duration of contract")}} : </span>
        </div> -->
    </div>
    <form @submit.prevent="addRateCode()">
      <div class="row mb-3">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label>{{ $t("Rate name") }}</label>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("Rate name") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(rate, index) in rate_name"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("Rate name") }} ({{ rate.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="rate.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="from">{{ $t("Period from") }}</label>
            <Datepicker v-model="fromDate" lang="ar" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="to">{{ $t("Period to") }}</label>
            <Datepicker v-model="toDate" lang="ar" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-12">
          <div class="switch_container">
            <label class="switch">
              <input type="checkbox" v-model="contain_bag" />
              <span class="slider round"></span>
            </label>
            <span>{{ $t("The flight includes bags") }}</span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12" v-if="contain_bag">
          <div class="form-group">
            <input
              type="number"
              class="form-control"
              v-model="bags_number"
              :placeholder="$t('number of bags')"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12" v-if="contain_bag">
          <div class="form-group weight_bag">
            <input
              type="number"
              class="form-control"
              v-model="bags_weight"
              :placeholder="$t('Weight per bag')"
            />
            <span>KG</span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <h5>All Passengers</h5>
        <div
          class="col-lg-6 col-md-6 col-12 visaData"
          v-for="(passenger, index) in allPassengers"
          :key="index"
        >
          <div class="visa_name">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`passengerCheck${index}`"
              v-model="passenger.checked"
            />
            <label class="form-check-label" :for="`passengerCheck${index}`">
              {{ passenger.title }}
            </label>
          </div>
          <div class="price" v-if="passenger.checked">
            <div class="form-group">
              <label>{{ $t("cost") }}</label>
              <input
                type="number"
                class="form-control"
                placeholder="0"
                v-model="passenger.cost"
              />
            </div>
            <div class="form-group">
              <label>{{ $t("selling") }}</label>
              <input
                type="number"
                class="form-control"
                placeholder="0"
                v-model="passenger.price"
              />
            </div>
            <div class="form-group">
              <label>{{ $t("count") }}</label>
              <input
                type="number"
                class="form-control"
                placeholder="0"
                v-model="passenger.number"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn PrimaryButton">
          {{ $t("save") }}
        </button>
      </div>
      <div class="row">
        <div class="table-responsive">
          <table class="table" v-if="checkedPassengers.length">
            <thead>
              <tr>
                <th></th>
                <th v-for="day in selectedDays" :key="day.date">
                  <div class="w-100 d-flex justify-content-center">
                    <div class="date">{{ day.date }}</div>
                  </div>
                </th>
              </tr>
              <tr>
                <th>{{ $t("type") }}</th>
                <th
                  v-for="(day, index) in selectedDays"
                  :key="day.date"
                  :class="{ blue: index % 2 == 0, yellow: index % 2 == 1 }"
                >
                  <div>{{ $t("cost") }}</div>
                  <div>{{ $t("selling") }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(passenger, passengerIndex) in checkedPassengers"
                :key="passengerIndex"
              >
                <td>{{ passenger.title }}</td>
                <td
                  v-for="(day, index) in passenger.days"
                  :key="day.date"
                  :class="{ blue: index % 2 == 0, yellow: index % 2 == 1 }"
                >
                  <div>
                    <input
                      type="number"
                      class="form-control"
                      v-model="day.cost"
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      class="form-control"
                      v-model="day.selling_price"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-danger">{{ $t("Deactivate day") }}</td>
                <td
                  v-for="(day, index) in selectedDays"
                  :key="day.date"
                  :class="{ blue: index % 2 == 0, yellow: index % 2 == 1 }"
                >
                  <div class="w-100 d-flex justify-content-end ps-4">
                    <label class="switch" :for="`diactive${index}`">
                      <input
                        type="checkbox"
                        :id="`diactive${index}`"
                        v-model="day.diactivate"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "flightRateCodesAdd",
  components: { Datepicker: VueDatepickerUi },
  data() {
    return {
      contract_id: this.$route.params.id,
      season_id: this.$route.params.seasonId,
      languages: ["ar", "en", "id"],
      rate_name: [],
      fromDate: new Date(),
      toDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      number_of_nights: 1,
      contain_bag: false,
      bags_number: null,
      bags_weight: null,
      allPassengers: [
        {
          id: 1,
          title: this.$t("Adults"),
          value: 0,
          cost: 0,
          price: 0,
          number: 0,
          checked: false,
          days: [],
        },
        {
          id: 2,
          title: this.$t("Childrens"),
          value: 1,
          cost: 0,
          price: 0,
          number: 0,
          checked: false,
          days: [],
        },
        {
          id: 3,
          title: this.$t("Babies"),
          value: 2,
          cost: 0,
          price: 0,
          number: 0,
          checked: false,
          days: [],
        },
      ],
    };
  },
  computed: {
    selectedDays() {
      const selectedDays = [];
      if (this.fromDate && this.toDate && this.number_of_nights > 0) {
        const fromDate = new Date(this.fromDate);
        for (let i = 0; i < this.number_of_nights; i++) {
          const currentDate = new Date(fromDate);
          currentDate.setDate(fromDate.getDate() + i);
          selectedDays.push({
            date: currentDate.toISOString().split("T")[0],
            dayOfWeek: this.getDayOfWeek(currentDate.getDay()),
            diactivate: false,
          });
        }
      }
      return selectedDays;
    },
    checkedPassengers() {
      let checkedPassengers = [];
      checkedPassengers = this.allPassengers.filter((ele) => {
        return ele.checked == true;
      });
      return checkedPassengers;
    },
  },
  methods: {
    getDayOfWeek(dayIndex) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[dayIndex];
    },
    setRateCodeName() {
      this.languages.forEach((language) => {
        this.rate_name.push({
          lang: language,
          name: "",
        });
      });
    },
    addRateCode() {
      let rate_data = {
        bags: this.contain_bag == false ? 0 : true,
        num_of_bags: this.bags_number,
        weghit_bag: this.bags_weight,
        from: moment(this.fromDate).format("YYYY-MM-DD"),
        to: moment(this.toDate).format("YYYY-MM-DD"),
        flight_season: this.season_id,
      };
      this.rate_name.forEach((rate) => {
        rate_data["name_" + rate.lang] = rate.name;
      });

      rate_data.passengers = this.checkedPassengers.map((ele) => {
        return {
          type_passenger: ele.value,
          cost: ele.cost,
          price: ele.price,
          count: ele.number,
          days: ele.days,
        };
      });

      axios
        .post("/flight_rate_code", rate_data)
        .then(({ data }) => {
          // console.log(data);
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push(
            `/flight-contract/${this.contract_id}/flight-season/${this.season_id}/rateCodes`
          );
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    setPassengersDays() {
      this.allPassengers.forEach((ele) => {
        ele.days = this.selectedDays.map((day) => {
          return {
            ...day,
            cost: 0,
            selling_price: 0,
          };
        });
      });
    },
  },
  mounted() {
    this.setRateCodeName();
    this.setPassengersDays();
  },
  watch: {
    toDate() {
      if (this.toDate) {
        const toDate = new Date(this.toDate);
        const fromDate = new Date(this.fromDate);
        var timeDiff = Math.abs(toDate.getTime() - fromDate.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
        this.number_of_nights = numberOfNights;

        this.allPassengers.forEach((ele) => {
          ele.days = this.selectedDays.map((day) => {
            return {
              ...day,
              cost: 0,
              selling_price: 0,
            };
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $PrimaryColor;
}

input:focus + .slider {
  box-shadow: 0 0 1px $PrimaryColor;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .contract_details {
    margin-right: 30px;
    margin-left: 30px;
    color: #0b1427;
    font-size: 18px;
    span {
      color: #656b78;
    }
  }
}
.form-group {
  margin-bottom: 20px;
  label {
    font-weight: 700;
    font-family: "bold";
    font-size: 16px;
    color: #0b1427;
    margin-bottom: 10px;
  }
}
h5 {
  font-family: "bold";
  font-size: 20px;
  color: #0b1427;
}
.visaData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .form-group {
    display: flex;
    align-items: center;
    margin-inline-start: 10px;
    label {
      font-size: 12px;
      margin: 0px 10px 0px 10px;
    }
    .form-control {
      width: 80px;
    }
  }
  .form-check-input:checked {
    background-color: $PrimaryColor;
    border-color: $PrimaryColor;
  }
  .form-check-label {
    font-family: "bold";
    margin: 0px 10px 0px 10px;
  }
}
.price {
  display: flex;
  align-items: center;
}
table {
  border-bottom: 1px solid #d0d2d7;
  width: auto;
  th,
  td {
    border-left: 1px solid #d0d2d7;
    min-width: 300px;
    width: 300px;
    &.yellow {
      background-color: #faf4ec !important;
    }
    &.blue {
      background-color: #ebf6f7 !important;
    }
  }
  th > div,
  td > div {
    display: inline-flex;
    width: 50%;
    text-align: center;
    justify-content: center;
    .form-control {
      width: 100px;
      background-color: transparent;
    }
  }
  .date {
    border: 1px solid $PrimaryColor;
    color: $PrimaryColor;
    padding: 7px 20px;
    text-align: center;
    width: fit-content;
    border-radius: 5px;
  }
  thead tr:first-child {
    th {
      border: 0;
      background-color: #fff;
    }
  }
}
.accordion-item:first-of-type .accordion-button {
  font-size: 14px;
  color: #adadad;
  padding: 10px;
  justify-content: space-between;
  &::after {
    width: 30px;
    margin-left: 0;
    color: #adadad;
  }
}
.v-calendar {
  width: 100%;
}
.visaData .price {
  .form-group {
    margin-bottom: 0;
    .form-control {
      text-align: center;
      padding: 8px 3px !important;
    }
  }
}
.weight_bag {
  position: relative;
  span {
    display: block;
    position: absolute;
    left: 5px;
    background-color: #fff;
    height: calc(100% - 4px);
    width: 70px;
    text-align: center;
    top: 2px;
    line-height: 43px;
    border-right: 1px solid #000;
  }
}
</style>
