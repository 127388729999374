<template>
  <section class="card_style">
    <div class="main_header">
        <h5>{{$t("Choose a display method")}}</h5>
    </div>
    <div class="methods">
        <div class="first_card" :class="{selected: this.selected == 1}" @click="choose_card(1)">
            <div class="image">
                <img src="@/assets/media/image/icon/card_img.png" />
            </div>
            <div class="title">{{$t("main_title")}}</div>
            <div class="description">{{$t("message_description")}}</div>
            <div class="buttons">
                <button type="button" class="btn PrimaryButton">{{$t("first_button")}}</button>
                <button type="button" class="btn SecondButton">{{$t("second_button")}}</button>
            </div>
        </div>
        <div class="second_card" :class="{selected: this.selected == 2}" @click="choose_card(2)">
            <div class="image">
                <img src="@/assets/media/image/icon/card_img.png" />
            </div>
            <div class="title">{{$t("main_title")}}</div>
            <div class="description">{{$t("message_description")}}</div>
            <div class="buttons">
                <button type="button" class="btn PrimaryButton">{{$t("first_button")}}</button>
                <button type="button" class="btn SecondButton">{{$t("second_button")}}</button>
            </div>
        </div>
    </div>

    <button type="button" class="btn PrimaryButton Confirmation">{{$t("Confirmation and publication")}}</button>
  </section>
</template>

<script>
export default {
    name: "displayMethod",
    data(){
        return{
            selected: null,
        }
    },
    methods:{
        choose_card(card_number){
            this.selected = card_number
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.main_header{
    h5{
        font-size: 24px;
        font-weight: 700;
        color: #0B1427;
    }
}
.PrimaryButton{
    margin-inline-start: unset;
    margin-top: unset;
}
.methods{
    max-width: 70%;
    @media(max-width: 768px){
        max-width: 100%;
    }
    .first_card , .second_card{
        border: 1px solid #D0D2D7;
        border-radius: 16px;
        padding: 20px;
        text-align: center;
        cursor: pointer;
        &.selected{
            border: 3px solid $PrimaryColor;
        }
        .title{
            border: 1px solid #D0D2D7;
            border-radius: 3px;
            padding: 3px;
            margin-top: 20px;
            color: #656B78;
            font-weight: 700;
            font-size: 14px;
        }
        .description{
            border: 1px solid #D0D2D7;
            border-radius: 3px;
            height: 100px;
            width: 80%;
            margin: 10px auto;
            text-align: center;
            line-height: 100px;
            color: #656B78;
            font-weight: 700;
            font-size: 14px;
        }
        .buttons{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
        }
    }
    .second_card{
        text-align: start;
        margin-top: 30px;
        img{
            width: 54px;    
        }
        .title{
            width: 80%;
            padding-right: 20px;
            margin-top: 20px;
        }
        .description{
            width: 100%;
        }
        .buttons{
            justify-content: flex-start;
        }
    }
}
.Confirmation{
    margin-top: 1rem;
    width: 300px;
}
</style>