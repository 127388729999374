<template>
  <section class="card_style">
    <!-- skelton -->

    <div class="row" v-if="loading && $route.params.id">
      <div class="col-12">
        <Skeleton width="20%" class="my-2"></Skeleton>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12" v-for="i in 2" :key="i">
        <div class="d-flex my-3 flex-column" v-for="i in 3" :key="i">
          <Skeleton width="20%" class="mb-2"></Skeleton>
          <Skeleton width="70%" height="2rem"></Skeleton>
        </div>
      </div>
      <div class="col-12">
        <Skeleton width="20%" class="my-2"></Skeleton>
      </div>
      <div class="col-12 my-3">
        <Skeleton width="10%" class="mb-2"></Skeleton>
        <Skeleton width="80%" height="2rem"></Skeleton>
      </div>
    </div>
    <!-- skelton -->
    <form @submit.prevent="add_cost_center()" v-else>
      <div class="d-flex align-items-center gap-3 mb-3">
        <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
        <div class="title">
          <h4 v-if="this.$route.params.id != undefined">
            {{ $t("edit_cost_center") }}
          </h4>
          <h4 v-else>{{ $t("add_cost_center") }}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <!-- <label>{{$t("title")}}</label> -->
            <div class="accordion" id="accordionExampleTitle">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExampleTitle"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(cost, index) in cost_title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("center_title") }} ({{ cost.locale }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="cost.title"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.cost_title.$error"
              v-text="v$.cost_title.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <!-- <label>{{$t("description")}}</label> -->
            <div class="accordion" id="accordionExampleDesc">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("description") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExampleDesc"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(cost, index) in cost_desc"
                      :key="index"
                    >
                      <label for="desc"
                        >{{ $t("description") }} ({{ cost.locale }})</label
                      >
                      <textarea
                        :placeholder="$t('description')"
                        class="form-control"
                        :id="`desc${index}`"
                        v-model="cost.title"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.cost_desc.$error"
              v-text="v$.cost_desc.$errors[0].$message"
            ></span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center gap-3">
        <div class="d-flex align-items-center gap-3">
          <RadioButton
            v-model="transaction_type"
            inputId="receive"
            name="transaction_type"
            :value="1"
          />
          <label for="receive" class="ml-2">{{ $t("flight") }}</label>
        </div>

        <div class="flex align-items-center">
          <RadioButton
            v-model="transaction_type"
            inputId="pay"
            name="transaction_type"
            :value="2"
          />
          <label for="pay" class="ml-2">{{ $t("hotels") }}</label>
        </div>
      </div>

      <div class="form-group" v-if="transaction_type == 1">
        <label for="">{{ $t("flight") }}</label>
        <Multiselect
          v-model="flight"
          :options="flights"
          :placeholder="$t('flight')"
          label="name"
          track-by="id"
          :clear-on-select="true"
          select-label=""
          :hide-selected="true"
          @keyup="fetchFlights($event)"
        >
        </Multiselect>
        <span
          class="text-danger"
          v-if="v$.flight.$error"
          v-text="v$.flight.$errors[0].$message"
        ></span>
      </div>
      <div class="form-group" v-if="transaction_type == 2">
        <label for="">{{ $t("hotels") }}</label>
        <Multiselect
          v-model="hotel"
          :options="hotels"
          :placeholder="$t('hotels')"
          label="title"
          track-by="id"
          :clear-on-select="true"
          select-label=""
          :hide-selected="true"
          @keyup="fetchHotels($event)"
        >
        </Multiselect>
        <!-- <span
          class="text-danger"
          v-if="v$.hotel.$error"
          v-text="v$.hotel.$errors[0].$message"
        ></span> -->
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn PrimaryButton">
          {{ $t("save") }}
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import RadioButton from "primevue/radiobutton";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";

export default {
  name: "addCostCenter",
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      loading: true,
      cost_title: [],
      cost_desc: [],
      center_id: this.$route.params.id,
      transaction_type: 1,
      hotels: [],
      hotel: "",
      flights: [],
      flight: "",
    };
  },
  validations() {
    return {
      cost_title: this.cost_title.map(() => {
        return {
          title: { required },
        };
      }),
      cost_desc: this.cost_desc.map(() => {
        return {
          title: { required },
        };
      }),
      transaction_type: { required },
      flight: { required },
      // hotel: { required },
    };
  },
  methods: {
    fetchHotels(event) {
      const searchText = { word: event.target.value };
      if (event.target.value.length > 3) {
        axios
          .post("/website/search_home_hotels", searchText)
          .then(({ data }) => {
            this.hotels = data.data.hotels;
            // console.log(this.hotels);
          });
      }
    },
    fetchFlights(event) {
      const search = event.target.value;
      axios.get(`/flight_company/search?search=${search}`).then(({ data }) => {
        if (data.data) {
          this.flights = data.data;
        }
      });
    },
    setCostTitle() {
      this.languages.forEach((lang) => {
        this.cost_title.push({
          locale: lang,
          title: "",
        });

        this.cost_desc.push({
          locale: lang,
          title: "",
        });
      });
    },
    fetchCenterData() {
      axios.get(`/show_cost_center/${this.center_id}`).then(({ data }) => {
        this.cost_title = data.data.titles;
        this.cost_desc = data.data.descriptions.map((ele) => {
          return {
            locale: ele.locale,
            title: ele.description,
          };
        });
        this.loading = false;
      });
    },
    add_cost_center() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();

        this.cost_title.forEach((ele) => {
          formData.append(`title_${ele.locale}`, ele.title);
        });
        this.cost_desc.forEach((ele) => {
          formData.append(`description_${ele.locale}`, ele.title);
        });
        if (this.transaction_type == 1) {
          formData.append("cost_centerable_type", "Company");
          formData.append("cost_centerable_id", this.flight.id);
        } else if (this.transaction_type == 2) {
          formData.append("cost_centerable_type", "Hotel");
          formData.append("cost_centerable_id", this.hotel.id);
        }

        if (this.center_id != undefined) {
          axios
            .post(`/update_cost_center/${this.center_id}`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/cost_center");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          axios
            .post(`/store_cost_center`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/cost_center");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
  },
  components: {
    RadioButton,
    Multiselect,
    Skeleton,
    AddFavDailog,
  },
  mounted() {
    this.setCostTitle();
    if (this.$route.params.id != undefined) {
      this.fetchCenterData();
    }
  },
};
</script>

<style></style>
