<template>
    <section class="card_style">
        <div class="main_header">
            <h4>{{ $t("The Transfers") }}</h4>
        </div>

        <form class="charge_wallet_request" @submit.prevent="save()">
            <div class="row">
                <div class="col-lg-6 mb-2">
                    <div class="transfer-data">

                        <div class="row">
                            <h5> {{$t("From")}} </h5>
                            <div class="col-lg-6">
                                <div class="bank service_type">
                                    <input
                                    v-model="transferTypeFrom"
                                    value="bank"
                                    type="radio"
                                    name="transfer_from"
                                    id="bank" 
                                    @change="getTransferType()" />
                                    <label for="bank"><i class="fa-solid fa-building-columns"></i> {{$t("bank account")}}</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="bank service_type">
                                    <input
                                    v-model="transferTypeFrom"
                                    value="safe"
                                    type="radio"
                                    name="transfer_from"
                                    id="safe"
                                    @change="getTransferType()" />
                                    <label for="safe"><i class="fa-solid fa-box-archive"></i> {{$t("safe")}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div v-if="transferTypeFrom == 'bank'">
                                <label class="d-block mb-2">{{$t("bank account")}}</label>
                                <Multiselect
                                    v-model="from_account"
                                    :options="accounts"
                                    :placeholder="$t('bank account')"
                                    label="title"
                                    track-by="id"
                                    :clear-on-select="true"
                                    select-label=""
                                    :hide-selected="true"
                                    @update:model-value="reset_FromMoneyAfter"
                                >
                                </Multiselect>
                                <!-- <select class="col-12" v-model="from_account" @change="reset_FromMoneyAfter">
                                    <option v-for="(account) in accounts" :key="account.id" :value="account">{{account.title}}</option>
                                </select> -->
                            </div>
                            <div v-if="transferTypeFrom == 'safe'">
                                <label class="d-block mb-2">{{$t("The Safe")}}</label>
                                <Multiselect
                                    v-model="from_account"
                                    :options="safes"
                                    :placeholder="$t('The Safe')"
                                    label="title"
                                    track-by="id"
                                    :clear-on-select="true"
                                    select-label=""
                                    :hide-selected="true"
                                    @update:model-value="reset_FromMoneyAfter"
                                >
                                </Multiselect>
                                <!-- <select class="col-12" v-model="from_account" @change="reset_FromMoneyAfter">
                                    <option v-for="(safe) in safes" :key="safe.id" :value="safe">{{safe.title}}</option>
                                </select> -->
                            </div>
                            
                        </div>

                        <div class="row mt-4">
                            <div class="col-4">
                                <label for="amount" class="d-block mb-2">{{$t("the amount")}}</label>
                                <input type="text" id="amount" placeholder="0.00" v-model="amount" @keyup="calcTransferFromMoney()"/>
                            </div>
                            <div class="col-4">
                                <label for="before" class="d-block mb-2">{{$t("avalible_before")}}</label>
                                <input type="text" id="before" disabled :value="from_account.money"/>
                            </div>
                            <div class="col-4">
                                <label for="after" class="d-block mb-2">{{$t("avalible_after")}}</label>
                                <input type="text" id="after" :value="FromMoneyAfter" disabled />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mb-2">
                    <div class="to_transfer_container">
                        <div class="row mb-3" v-for="(item , index) in this.toAccounts" :key="item.id">
                            <div class="col-11">
                                <div class="transfer-data">
                                    <div class="row">
                                        <h5> {{$t("To")}} </h5>
                                        <div class="col-lg-6">
                                            <div class="bank service_type">
                                                <input
                                                v-model="item.transferTypeTo"
                                                value="bank"
                                                type="radio"
                                                :name="`transfer_to${index}${item.id}`"
                                                :id="`bank${index}${item.id}`"
                                                />
                                                <label :for="`bank${index}${item.id}`"><i class="fa-solid fa-building-columns"></i> {{$t("bank account")}}</label>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="bank service_type">
                                                <input
                                                v-model="item.transferTypeTo"
                                                value="safe"
                                                type="radio"
                                                :name="`transfer_to${index}${item.id}`"
                                                :id="`safe${index}${item.id}`" />
                                                <label :for="`safe${index}${item.id}`"><i class="fa-solid fa-box-archive"></i> {{$t("safe")}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-4">
                                        <div v-if="item.transferTypeTo == 'bank'">
                                            <label class="d-block mb-2">{{$t("bank account")}}</label>
                                            <Multiselect
                                                v-model="item.to_account"
                                                :options="accounts"
                                                :placeholder="$t('bank account')"
                                                label="title"
                                                track-by="id"
                                                :clear-on-select="true"
                                                select-label=""
                                                :hide-selected="true"
                                            >
                                            </Multiselect>
                                            <!-- <select class="col-12" v-model="item.to_account">
                                                <option v-for="(account) in accounts" :key="account.id" :value="account">{{account.title}}</option>
                                            </select> -->
                                        </div>
                                        <div v-if="item.transferTypeTo == 'safe'">
                                            <label class="d-block mb-2">{{$t("The Safe")}}</label>
                                            <Multiselect
                                                v-model="item.to_account"
                                                :options="safes"
                                                :placeholder="$t('The Safe')"
                                                label="title"
                                                track-by="id"
                                                :clear-on-select="true"
                                                select-label=""
                                                :hide-selected="true"
                                            >
                                            </Multiselect>
                                            <!-- <select class="col-12" v-model="item.to_account">
                                                <option v-for="(safe) in safes" :key="safe.id" :value="safe">{{safe.title}}</option>
                                            </select> -->
                                        </div>
                                    </div>

                                    <div class="row mt-4">
                                        <div class="col-4">
                                            <label for="amount" class="d-block mb-2">{{$t("the amount")}}</label>
                                            <input type="text" id="amount" placeholder="0.00" v-model="item.amount" @keyup=" (index)"/>
                                        </div>
                                        <div class="col-4">
                                            <label for="before" class="d-block mb-2">{{$t("avalible_before")}}</label>
                                            <input type="text" id="before" disabled :value="item.to_account?.money"/>
                                        </div>
                                        <div class="col-4">
                                            <label for="after" class="d-block mb-2">{{$t("avalible_after")}}</label>
                                            <input type="text" id="after" :value="item.toMoneyAfter" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">
                                <div
                                    class="addAccount"
                                    @click="addToAccount()"
                                    v-if="index == 0"
                                >
                                    <i class="fa-solid fa-plus"></i>
                                </div>
                                <div
                                    class="removeAccount"
                                    @click="removeToAccount(index)"
                                    v-else
                                >
                                    <i class="fa-solid fa-minus"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row my-4">
                <div class="col-6">
                    <label for="date" class="d-block mb-3">{{$t("transfer's date and time")}}</label>
                    <Datepicker
                        v-model="date"
                        class="w-100"
                        lang="ar"
                    />
                    <!-- <input type="date" id="date" v-model="date"/> -->
                </div>
                <div class="col-6">
                    <label for="notes" class="d-block mb-3">{{$t("notes")}}</label>
                    <input type="text" id="notes" placeholder="..." v-model="notes">
                </div>
            </div>

            <button type="submit" class="btn save">{{$t("save")}}</button>
        </form>
    </section>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";

export default {
    data(){
        return{
            transfer_id: this.$route.params.id,
            transferTypeFrom:'bank',
            transferTypeTo: 'bank',
            type: 1,
            accounts:[],
            from_account:'',
            to_account:'',
            amount: 0,
            FromMoneyAfter:"",
            toMoneyAfter:"",
            safes:[],
            date: new Date(),
            notes:"",
            toAccounts:[
                {
                    id:1,
                    transferTypeTo: "bank",
                    to_account: "",
                    amount: 0,
                    toMoneyAfter: ""
                }
            ],
        }
    },
    components: {
        Multiselect,
        Datepicker
    },
    methods:{
        getTransferType(){
            this.type = this.transferTypeFrom == "bank" ? 1 : 2;
        },
        fetchAccountData(){
            axios.post("/fetch_my_organization_accounts" , {type: this.type}).then(({data}) => {
                this.accounts = data.data.data
            })
        },
        fetchSafeData(){
            axios.post("/fetch_my_organization_accounts" , {type: 2}).then(({data})=>{
                this.safes = data.data.data
            })
        },
        calcTransferFromMoney(){
            this.FromMoneyAfter = parseFloat(this.from_account.money) - parseFloat(this.amount);
        },
        calcTransferToMoney(index) {
            this.toAccounts[index].toMoneyAfter = parseFloat(this.toAccounts[index].to_account.money) + parseFloat(this.toAccounts[index].amount);
        },
        addToAccount(){
            this.toAccounts.push({
                id: this.toAccounts.length + 1,
                transferTypeTo: "bank",
                to_account: "",
                amount: 0,
                toMoneyAfter: "",
            })
        },
        removeToAccount(index){
            this.toAccounts.splice(index , 1);
        },
        reset_FromMoneyAfter(){
            this.calcTransferFromMoney();
        },
        save(){
            let transferData = {
                text: this.notes,
                date: moment(this.date).format("YYYY-MM-DD"),
                from: {
                        account_id: this.from_account.id,
                        amount: this.amount,
                        // report: "transfer_from_first_account"
                    }
            }
            transferData.to = this.toAccounts.map((ele)=>{
                return{
                    account_id: ele.to_account.id,
                    amount: ele.amount,
                    // report: "transfer_to_first_account"
                }
            })

            axios.post(`/store_transfer_accounts` , transferData).then(({data})=>{
                // console.log(data)
                Swal.fire({
                    title: "",
                    text: data.message,
                    icon: "success",
                });
                this.$router.push('/allTransfers')
            }).catch((error)=>{
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            })
        },
    },
    created() {
        this.fetchAccountData();
        this.fetchSafeData();
    }
}
</script>

<style lang="scss" scoped>
@import "./_transfer.scss";
</style>


