<template>
  <form class="card_style" @submit.prevent="addSetting()">
    <div class="container">
      <div class="row">
        <div class="d-flex align-items-center gap-2 mb-3">
          <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
          <h3 class="my-2">
            {{ $t("Image size required to upload") }}(1400x700)
          </h3>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Header image</span>
          <div class="image_upload">
            <label
              for="imageEntry"
              class="upload_img"
              v-if="imageEntryHeaderImage == ''"
            >
              <img
                :src="Form.header_image ? Form.header_image : defaultImage"
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryHeaderImage"
              v-else-if="imageEntryHeaderImage"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntry"
              class="form-control"
              @change="onFileChangeHeaderImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Header Hotel image</span>
          <div class="image_upload">
            <label
              for="imageEntryHeaderHotelImage"
              class="upload_img"
              v-if="imageEntryHeaderHotelImage == ''"
            >
              <img
                :src="
                  Form.header_hotel_image
                    ? Form.header_hotel_image
                    : defaultImage
                "
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryHeaderHotelImage"
              v-else-if="imageEntryHeaderHotelImage"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntryHeaderHotelImage"
              class="form-control"
              @change="onFileChangeHeaderHotelImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Header Flight image</span>
          <div class="image_upload">
            <label
              for="imageEntryHeaderFlightImage"
              class="upload_img"
              v-if="imageEntryHeaderFlightImage == ''"
            >
              <img
                :src="
                  Form.header_flight_image
                    ? Form.header_flight_image
                    : defaultImage
                "
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryHeaderFlightImage"
              v-else-if="imageEntryHeaderFlightImage"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntryHeaderFlightImage"
              class="form-control"
              @change="onFileChangeHeaderFlightImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Header Offer image</span>
          <div class="image_upload">
            <label
              for="imageEntryHeaderOfferImage"
              class="upload_img"
              v-if="imageEntryHeaderOfferImage == ''"
            >
              <img
                :src="
                  Form.header_offer_image
                    ? Form.header_offer_image
                    : defaultImage
                "
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryHeaderOfferImage"
              v-else-if="imageEntryHeaderOfferImage"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntryHeaderOfferImage"
              class="form-control"
              @change="onFileChangeHeaderOfferImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Header Umrah image</span>
          <div class="image_upload">
            <label
              for="imageEntryHeaderUmrahImage"
              class="upload_img"
              v-if="imageEntryHeaderUmrahImage == ''"
            >
              <img
                :src="
                  Form.header_umrah_image
                    ? Form.header_umrah_image
                    : defaultImage
                "
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryHeaderUmrahImage"
              v-else-if="imageEntryHeaderUmrahImage"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntryHeaderUmrahImage"
              class="form-control"
              @change="onFileChangeHeaderUmrahImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Header offer section image</span>
          <div class="image_upload">
            <label
              for="imageEntryHeaderOfferSectionImage"
              class="upload_img"
              v-if="imageEntryHeaderOfferSectionImage == ''"
            >
              <img
                :src="
                  Form.offer_section_background_image
                    ? Form.offer_section_background_image
                    : defaultImage
                "
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryHeaderOfferSectionImage"
              v-else-if="imageEntryHeaderOfferSectionImage"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntryHeaderOfferSectionImage"
              class="form-control"
              @change="onFileChangeHeaderOfferSectionImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Header Subscription image</span>
          <div class="image_upload">
            <label
              for="imageEntryHeaderSubscriptionImage"
              class="upload_img"
              v-if="imageEntryHeaderSubscriptionImage == ''"
            >
              <img
                :src="
                  Form.subscription_image
                    ? Form.subscription_image
                    : defaultImage
                "
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryHeaderSubscriptionImage"
              v-else-if="imageEntryHeaderSubscriptionImage"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntryHeaderSubscriptionImage"
              class="form-control"
              @change="onFileChangeHeaderSubscriptionImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Login image</span>
          <div class="image_upload">
            <label
              for="imageEntryLogin"
              class="upload_img"
              v-if="imageEntryLogin == ''"
            >
              <img
                :src="Form.login_image ? Form.login_image : defaultImage"
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryLogin"
              v-else-if="imageEntryLogin"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntryLogin"
              class="form-control"
              @change="onFileChangeLoginImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Register image</span>
          <div class="image_upload">
            <label
              for="imageEntryRegister"
              class="upload_img"
              v-if="imageEntryRegister == ''"
            >
              <img
                :src="Form.register_image ? Form.register_image : defaultImage"
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryRegister"
              v-else-if="imageEntryRegister"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntryRegister"
              class="form-control"
              @change="onFileChangeRegisterImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <span>Contact image</span>
          <div class="image_upload">
            <label
              for="imageEntryContact"
              class="upload_img"
              v-if="imageEntryContact == ''"
            >
              <img
                :src="Form.contact_image ? Form.contact_image : defaultImage"
                class="upload_img"
                alt="user"
              />
            </label>
            <img
              :src="imageEntryContact"
              v-else-if="imageEntryContact"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              name=""
              id="imageEntryContact"
              class="form-control"
              @change="onFileChangeContactImage"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "setting-website",
  data() {
    return {
      Form: {
        header_image: null,
        header_hotel_image: null,
        header_flight_image: null,
        header_offer_image: null,
        header_umrah_image: null,
        header_offer_section_image: null,
        subscription_image: null,
        offer_section_background_image: null,
        login_image: null,
        register_image: null,
        contact_image: null,
      },
      imageEntryHeaderImage: "",
      imageEntryHeaderHotelImage: "",
      imageEntryHeaderFlightImage: "",
      imageEntryHeaderOfferImage: "",
      imageEntryHeaderUmrahImage: "",
      imageEntryHeaderOfferSectionImage: "",
      imageEntryHeaderSubscriptionImage: "",
      imageEntryLogin: "",
      imageEntryRegister: "",
      imageEntryContact: "",
      defaultImage: require("@/assets/media/image/placeholder.jpg"),
    };
  },
  methods: {
    onFileChangeHeaderImage(event) {
      this.fileDataHeaderImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataHeaderImage);
      reader.onload = () => (this.imageEntryHeaderImage = reader.result);
    },
    onFileChangeHeaderHotelImage(event) {
      this.fileDataHeaderHotelImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataHeaderHotelImage);
      reader.onload = () => (this.imageEntryHeaderHotelImage = reader.result);
    },
    onFileChangeHeaderFlightImage(event) {
      this.fileDataHeaderFlightImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataHeaderFlightImage);
      reader.onload = () => (this.imageEntryHeaderFlightImage = reader.result);
    },

    onFileChangeHeaderOfferImage(event) {
      this.fileDataHeaderOfferImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataHeaderOfferImage);
      reader.onload = () => (this.imageEntryHeaderOfferImage = reader.result);
    },

    onFileChangeHeaderUmrahImage(event) {
      this.fileDataHeaderUmrahImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataHeaderUmrahImage);
      reader.onload = () => (this.imageEntryHeaderUmrahImage = reader.result);
    },

    onFileChangeHeaderOfferSectionImage(event) {
      this.fileDataHeaderOfferSectionImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataHeaderOfferSectionImage);
      reader.onload = () =>
        (this.imageEntryHeaderOfferSectionImage = reader.result);
    },

    onFileChangeHeaderSubscriptionImage(event) {
      this.fileDataHeaderSubscriptionImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataHeaderSubscriptionImage);
      reader.onload = () =>
        (this.imageEntryHeaderSubscriptionImage = reader.result);
    },

    onFileChangeLoginImage(event) {
      this.loginImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.loginImage);
      reader.onload = () => (this.imageEntryLogin = reader.result);
    },

    onFileChangeRegisterImage(event) {
      this.registerImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.registerImage);
      reader.onload = () => (this.imageEntryRegister = reader.result);
    },

    onFileChangeContactImage(event) {
      this.contactImage = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.contactImage);
      reader.onload = () => (this.imageEntryContact = reader.result);
    },

    addSetting() {
      const formData = new FormData();

      if (this.fileDataHeaderImage instanceof File) {
        formData.append("header_image", this.fileDataHeaderImage);
      }
      if (this.fileDataHeaderHotelImage instanceof File) {
        formData.append("header_hotel_image", this.fileDataHeaderHotelImage);
      }
      if (this.fileDataHeaderFlightImage instanceof File) {
        formData.append("header_flight_image", this.fileDataHeaderFlightImage);
      }
      if (this.fileDataHeaderOfferImage instanceof File) {
        formData.append("header_offer_image", this.fileDataHeaderOfferImage);
      }
      if (this.fileDataHeaderUmrahImage instanceof File) {
        formData.append("header_umrah_image", this.fileDataHeaderUmrahImage);
      }
      if (this.fileDataHeaderOfferSectionImage instanceof File) {
        formData.append(
          "offer_section_background_image",
          this.fileDataHeaderOfferSectionImage
        );
      }
      if (this.fileDataHeaderSubscriptionImage instanceof File) {
        formData.append(
          "subscription_image",
          this.fileDataHeaderSubscriptionImage
        );
      }
      if (this.loginImage instanceof File) {
        formData.append("login_image", this.loginImage);
      }
      if (this.registerImage instanceof File) {
        formData.append("register_image", this.registerImage);
      }
      if (this.contactImage instanceof File) {
        formData.append("contact_image", this.contactImage);
      }

      axios
        .post("/website/web_site_headers", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.Form = {};
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.Form = {};
        });
    },
    showSetting() {
      axios.get(`/website/web_site_headers`).then(({ data }) => {
        // console.log(data.data);
        this.Form = data.data;
      });
    },
  },
  created() {
    this.showSetting();
  },
  components: {
    AddFavDailog,
  },
};
</script>

<style lang="scss" scoped>
@import url("./_style.scss");
</style>
