<template>
  <section class="card_style">
    <div class="container">
      <div class="w-100" v-if="loading">
        <Skeleton width="15%" class="mb-2"></Skeleton>
        <Skeleton height="2rem" class="mt-4"></Skeleton>
        <div class="d-flex mt-4">
          <div
            class="d-flex flex-column gap-2 w-100 my-2"
            v-for="i in 2"
            :key="i"
          >
            <Skeleton width="15%"></Skeleton>
            <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
          </div>
        </div>
      </div>
      <form @submit.prevent="sendData()" v-else>
        <div class="main_header">
          <h3>{{ $t("add season") }}</h3>
        </div>
        <div class="row">
          <div class="col-lg-12 col-12"></div>
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  {{ $t("season name") }}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div
                    class="form-group"
                    v-for="(season, index) in seasonsName"
                    :key="index"
                  >
                    <label for="name" class="form-label"
                      >{{ $t("season name") }} {{ season.lang }}</label
                    >
                    <input
                      type="text"
                      :placeholder="$t('enter season name')"
                      class="form-control"
                      :id="`name${index}`"
                      v-model="season.name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <label>{{ $t("period_from") }}</label>
            <datepicker
              v-model="fromDate"
              name="fromDate"
              :placeholder="$t('from')"
              :disabled-dates="{
                to: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
              }"
              disabled
            ></datepicker>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <label>{{ $t("period_to") }}</label>
            <datepicker
              v-model="toDate"
              name="toDate"
              :placeholder="$t('from')"
              :disabled-dates="{
                to: new Date(
                  new Date(fromDate).getTime() + 24 * 60 * 60 * 1000
                ),
              }"
              disabled
            ></datepicker>
          </div>
        </div>
        <button type="submit" class="btn PrimaryButton">
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";

export default {
  name: "editSeason",
  components: { Datepicker, Skeleton },
  data() {
    return {
      languages: ["ar", "en", "id"],
      seasonsName: [],
      fromDate: "",
      toDate: "",
      contract_id: this.$route.params.id,
      season_id: this.$route.params.season_id,
      loading: true,
    };
  },
  methods: {
    // fetchContractData(){
    //   axios.get(`/visa_contract/${this.contract_id}`).then(({data})=>{
    //     console.log(data)
    //   })
    // },
    fetchSeasonData() {
      axios.get(`/season_contract/${this.season_id}`).then(({ data }) => {
        // console.log(data);
        data.data.name.forEach((season) => {
          this.seasonsName.push({
            lang: season.locale,
            name: season.name,
          });
        });
        this.fromDate = data.data.from;
        this.toDate = data.data.to;
        this.loading = false;
      });
    },
    sendData() {
      let seasonData = {
        visa_contract_id: this.contract_id,
        from: moment(this.fromDate).format("YYYY-MM-DD"),
        to: moment(this.toDate).format("YYYY-MM-DD"),
      };
      this.seasonsName.forEach((season) => {
        seasonData["name_" + season.lang] = season.name;
      });

      axios
        .put(`/season_contract/${this.season_id}`, seasonData)
        .then(({ data }) => {
          console.log(data);
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push(`/visa/contract/${this.contract_id}/seasons`);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    // this.fetchContractData();
    this.fetchSeasonData();
  },
};
</script>

<style></style>
