<template>
  <div class="row">
    <div class="col-12 hotel_services">
      <h2 class="mx-0 my-3 px-0">{{ $t("all_attachment") }}</h2>
      <div class="card_lists">
        <!-- v-if="amenities.length" -->
        <div class="card_list">
          <h5><i class="fa-solid fa-bed mx-2"></i> {{ $t("relax_method") }}</h5>
          <ul v-if="amenities?.length > 0">
            <li v-for="(amenitie, index) in amenities" :key="index">
              <i class="fa-solid fa-check mx-2"></i>
              {{ amenitie.title[0].title }}
            </li>
          </ul>
          <p v-else>{{ $t("no_amenities") }}</p>
        </div>
        <!-- v-if="entertainments.length" -->
        <div class="card_list">
          <h5>
            <i class="fa-solid fa-utensils mx-2"></i> {{ $t("entertainments") }}
          </h5>
          <ul v-if="entertainments?.length > 0">
            <li v-for="(entertainment, index) in entertainments" :key="index">
              <i class="fa-solid fa-check mx-2"></i>
              {{ entertainment.title[0].title }}
            </li>
          </ul>
          <p v-else>{{ $t("no_entertainments") }}</p>
        </div>
        <!-- v-if="business.length" -->
        <div class="card_list">
          <h5>
            <i class="fa-solid fa-briefcase mx-2"></i>{{ $t("service_work") }}
          </h5>
          <ul v-if="business?.length > 0">
            <li v-for="(busines, index) in business" :key="index">
              <i class="fa-solid fa-check"></i> {{ busines.title[0].title }}
            </li>
          </ul>
          <p v-else>{{ $t("no_services") }}</p>
        </div>
      </div>
    </div>
    <ul class="annexes">
      <li v-for="(amenity, index) in hotel_amenities" :key="index">
        <div class="annex_name">
          <i class="fa-solid fa-utensils"></i>{{ amenity.amenity_type.title }}
        </div>
        <div class="annexes_items">
          <span
            v-for="(item, itemIndex) in amenity.amenities"
            :key="itemIndex"
            >{{ item.title }}</span
          >
        </div>
        <div class="control">
          <!-- <button type="button" class="btn edit">{{$t("edit")}}</button> -->
          <button
            type="button"
            class="btn delete"
            @click="delete_amenity(amenity.row_id, index)"
          >
            {{ $t("delete") }}
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  inject: ["hotel_amenities"],
  methods: {
    delete_amenity(amenityId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("hotel_id", this.$route.params.id);
          formData.append("id", amenityId);

          axios
            .post(`/delete_hotel_amenity_type`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.hotel_amenities.splice(index, 1);
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      });
    },
  },
  computed: {
    ...mapState(["business", "entertainments", "amenities"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.card_lists {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  //   gap: 20px;
  //   margin-bottom: 20px;
  //   padding-top: 30px;

  .card_list {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;

    h5 {
      font-family: "bold";
      color: $PrimaryColor;
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      i {
        margin-right: 10px;
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        color: #656b78;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        i {
          color: $PrimaryColor;
          margin-right: 8px;
        }
      }
    }

    p {
      color: #656b78;
    }
  }
}

.description {
  color: #656b78;
  font-size: 15px;
  margin: 15px 0;
  line-height: 1.5;
}

.annexes {
  margin: 0;
  padding: 0.5rem 0.5rem;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px;
    margin-bottom: 10px;
    background: #fff;
    border: 1px solid #d0d2d7;
    border-radius: 6px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .annex_name {
      color: $PrimaryColor;
      font-weight: 700;
      flex: 0 0 230px;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        flex: 0 0 140px;
      }

      i {
        margin-right: 5px;
      }
    }

    .annexes_items {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      span {
        background: #f5f5f5;
        padding: 5px 10px;
        border-radius: 4px;
      }
    }

    .control {
      display: flex;
      align-items: center;

      button {
        margin-right: 10px;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        transition: 0.3s ease;

        &.edit {
          color: $PrimaryColor;
          border-color: $PrimaryColor;

          &:hover {
            background-color: $PrimaryColor;
            color: #fff;
          }
        }

        &.delete {
          color: #e23535;
          border-color: #e23535;

          &:hover {
            background-color: #e23535;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
