<template>
  <section class="card_style">
    <div class="d-flex align-items-center gap-3 mb-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4>{{ $t("default_currency") }}</h4>
    </div>
    <form @submit.prevent="updateDefaultCurrency()">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <label for="currencies">{{ $t("currencies") }}</label>
            <Multiselect
              v-model="Form.defaultCurrencyValue"
              :options="Form.defaultCurrencies"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('select_currency')"
              select-label=""
              :hide-selected="true"
              @open="getCurrencies"
            ></Multiselect>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-4 col-md-4 col-12"
          v-for="(converter, index) in Form.converters"
          :key="index"
        >
        {{converter.percentage}}
          <div class="form-group">
            <label>{{ $t("price") }} {{ converter?.title }}</label>
            <input
              type="text"
              class="form-control"
              placeholder="0"
              v-model="converter.percentage"
            />
            <input
              class="form-control d-none"
              v-model="converter.currency_id"
            />
          </div>
        </div>
      </div>

      <div class="col-12 d-flex justify-content-end mt-2" v-if="isDataValid">
        <button type="submit" class="btn PrimaryButton">
          {{ $t("save") }}
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "updateDefaultCurrency",
  components: {
    Multiselect,
    AddFavDailog,
  },
  data() {
    return {
      Form: {
        defaultCurrencies: [],
        defaultCurrencyValue: "",
        converters: [
          {
            currency_id: "",
            percentage: "",
            title: "",
          },
        ],
      },
    };
  },
  computed: {
    isDataValid() {
      return this.Form.defaultCurrencyValue !== "";
    },
  },
  methods: {
    getCurrencies() {
      axios.post("/index_organization_currencies").then(({ data }) => {
        this.Form.defaultCurrencies = data.data.data;
      });
      this.getMyDefaultCurrency();
    },
    getMyDefaultCurrency() {
      axios.get("/fetch_my_default_currency").then(({ data }) => {
        this.Form.defaultCurrencyValue = data?.data?.currency;
        // console.log(data.data)
        this.Form.converters =
          data?.data?.all_currencies &&
          data?.data?.all_currencies.length > 0 &&
          data?.data?.all_currencies?.map((el) => {
            return {
              percentage: el.price,
              currency_id: el.id,
              title: el.title,
            };
          });
        // console.log(this.Form.converters)
      });
    },

    updateDefaultCurrency() {
      const formData = new FormData();

      formData.append(
        "default_currency_id",
        this.Form.defaultCurrencyValue?.id
      );
      this.Form.converters &&
        this.Form.converters.length > 0 &&
        this.Form.converters.forEach((converter, index) => {
          // console.log(converter);
          formData.append(
            `converters[${index}][currency_id]`,
            converter.currency_id
          );
          formData.append(
            `converters[${index}][percentage]`,
            converter.percentage
          );
        });

      axios
        .post(`/organization_default_currencies`, formData)
        .then(({ data }) => {
          this.getMyDefaultCurrency();
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.getMyDefaultCurrency();
  },
};
</script>
