<template>
  <section class="show_receipt card_style">
    <div class="main_header_style">
      <h4>{{ $t("revenues") }}</h4>

      <router-link :to="{ name: 'AddRevenue' }" class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i>
        {{ $t("add_new_revenue") }}</router-link
      >
    </div>
    <div class="row">
      <table class="table" id="revenueTable">
        <thead>
          <tr>
            <th>{{ $t("created_by") }}</th>
            <!-- <th>{{ $t("number") }}</th> -->
            <th>{{ $t("spent_money") }}</th>
            <th>{{ $t("construction_date") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in revenue" :key="index">
            <td>{{ item.employee.name }}</td>
            <!-- <td>{{ index }}</td> -->
            <td>{{ item.title }}</td>
            <td>{{ item.created_at }}</td>

            <td>
              <router-link
                class="btn btn-success text-white mx-1 edit_btn"
                :to="`/revenue/edit/${item.id}`"
                :title="$t('edit')"
              >
                <i class="fa-solid fa-pen"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1 remove_btn"
                @click="DeleteAlert(index)"
                :title="$t('recipt_delete')"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="revenue"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchRevenue"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import $ from "jquery";
export default {
  name: "show-revnue",
  components: {
    pagination,
  },
  data() {
    return {
      bankAccount: "",
      banks: [
        {
          id: 1,
          title: "zzz",
        },
        {
          id: 2,
          title: "zzz",
        },
      ],
      revenue: [],
      isMenuVisible: false,
    };
  },
  methods: {
    // toggle menu

    // fetch revenue data
    FetchRevenue(page = 1) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/revenue_type?page=" + page).then(({ data }) => {
        this.revenue = data.data;
        console.log(this.revenue);
      });
    },
    //delete revenue
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.revenue[index].id;
          const fromClick = true;
          axios.delete("/revenue_type/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.revenue.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchRevenue();
  },
  updated() {
    // axios.get("API_Goes_Here").then((response) => {
    //   this.products = response.data;
    // });
    $("#revenueTable").DataTable({
      bPaginate: false,
      retrieve: true,
      // displayStart: 5,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("revenuees"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("revenuees"),
      },
    });
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_revenue";
</style>
