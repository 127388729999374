<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>
        <span>{{ $t("add_new_season") }}</span>
        <span v-if="seasons.length">
          / {{ seasons[0].contract }} /
          {{ seasons[0].hotel_name }}
        </span>
      </h4>
      <h6>{{ seasons.length ? getLastObjectSeason : fromDateContract }} / {{ toDateContract }}</h6>
    </div>
    <form class="form_topic" @submit.prevent="addSeason()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('write_name')"
              v-model="name"
            />
            <!-- required -->
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_from") }}</label>
            <datepicker
              v-model="start_date"
              name="start_date"
              :placeholder="$t('from')"
              :disabled-dates="{
                to: new Date(
                  seasons.length ? getLastObjectSeason : fromDateContract
                ),
                from: new Date(
                  seasons.length ? getLastObjectSeason : fromDateContract
                ),
              }"
              :value="seasons.length ? getLastObjectSeason : fromDateContract"
            ></datepicker>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("to") }}</label>
            <datepicker
              v-model="end_date"
              name="end_date"
              :placeholder="$t('to')"
              :disabled-dates="{
                to: new Date(
                  start_date == ''
                    ? seasons.length
                      ? getLastObjectSeason
                      : fromDateContract
                    : start_date
                ),
                from: new Date(toDateContract),
              }"
              :value="toDateContract"
            ></datepicker>
            <!-- start_date == '' ?  fromDateContract : start_date -->
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import Loading from "@/components/LottieFiles/loading.vue";

export default {
  name: "add-season",
  components: {
    Datepicker,
    Loading,
  },

  data() {
    return {
      name: "",
      end_date: "",
      start_date: "",
      fromDateContract: "",
      toDateContract: "",
      seasons: [],
      loading: false,
    };
  },
  methods: {
    async addSeason() {
      let id = this.$route.params.id;
      const formData = new FormData();
      formData.append("contract_id", id);
      formData.append("name", this.name);
      formData.append(
        "start_date",
        this.seasons.length ? this.getLastObjectSeason : this.fromDateContract
      );
      formData.append("end_date", this.end_date == "" ? this.toDateContract : moment(this.end_date).format("YYYY-MM-DD"));
      this.loading = true; // Start loading

      axios
        .post("/seasons", formData)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.loading = false; //the loading ended
          this.$router.push(`/contract/${id}/seasons`);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },

    // fetch contract data
    FetchContract() {
      let id = this.$route.params.id;
      axios.get(`/contracts/${id}`).then(({ data }) => {
        this.fromDateContract = moment(data.data.from).format("YYYY-MM-DD");
        this.toDateContract = moment(data.data.to).format("YYYY-MM-DD");
        this.seasons = data.data.seasons;
        // console.log(data.data);
        // console.log(this.fromDateContract);
      });
    },
  },
  computed: {
    getLastObjectSeason() {
      if (this.seasons.length > 0) {
        const startDateSeason = new Date(
          new Date(this.seasons[this.seasons.length - 1].end_date).getTime() +
            24 * 60 * 60 * 1000
        );
        const startDateSeasonFormat =
          moment(startDateSeason).format("YYYY-MM-DD");
        // console.log(startDateSeasonFormat)
        return startDateSeasonFormat;
      } else {
        return null;
      }
    },
  },
  created() {
    this.FetchContract();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style lang="scss" scoped>
@import "./_season.scss";
</style>
