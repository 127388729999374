<template>
  <div class="added_services">
    <!-- <h4 class="title">{{ $t("added services") }}</h4> -->
    <form>
      <div class="table-responsive">
        <table class="table-list">
          <thead class="table-title">
            <tr>
              <th>{{ $t("service provider") }}</th>
              <th>{{ $t("service type") }}</th>
              <th>{{ $t("the count") }}</th>
              <th>{{ $t("cost") }}</th>
              <th>{{ $t("selling price") }}</th>
              <th>{{ $t("total cost") }}</th>
              <th>{{ $t("total selling price") }}</th>
              <th>{{ $t("notes") }}</th>
              <th>{{ $t("remove") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(service, index) in services" :key="index">
              <td>
                <Multiselect
                  v-model="service.supplier_id"
                  :options="providers"
                  label="name"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose_provider')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
              </td>
              <td>
                <Multiselect
                  v-model="service.service_type"
                  :options="services_types"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('service type')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
              </td>
              <td>
                <input
                  type="number"
                  name="service_count"
                  placeholder="0"
                  class="form-control"
                  v-model="service.service_count"
                  @keyup="Service_cost(index), Service_selling(index)"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="service_cost"
                  placeholder="0"
                  class="form-control"
                  v-model="service.Service_cost"
                  @keyup="Service_cost(index)"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="service_selling"
                  placeholder="0"
                  class="form-control"
                  v-model="service.Service_selling"
                  @keyup="Service_selling(index)"
                />
              </td>
              <td>
                <input
                  type="number"
                  name="service_total_cost"
                  placeholder="0"
                  class="form-control"
                  v-model="service.service_total_cost"
                  disabled
                />
              </td>
              <td>
                <input
                  type="number"
                  name="service_total_selling"
                  placeholder="0"
                  class="form-control"
                  v-model="service.service_total_selling"
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  name="notes"
                  :placeholder="$t('write here ....')"
                  class="form-control"
                  v-model="service.service_notes"
                />
              </td>
              <td>
                <button
                  type="button"
                  class="minus btn"
                  @click="removeService(index)"
                  v-if="services.length - 1 == index"
                >
                  <i class="fa-solid fa-minus"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="SecondButton btn w-40"
          @click="addService()"
        >
          <i class="fa-solid fa-plus"></i> {{ $t("New Service") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";

export default {
  emits: ["getServices"],
  components: { Multiselect },
  data() {
    return {
      providers: [],
      services_types: [],
      services: [
        {
          supplier_id: "",
          service_type: "",
          service_count: 0,
          Service_cost: 0,
          Service_selling: 0,
          service_total_cost: 0,
          service_total_selling: 0,
          service_notes: "",
        },
      ],
    };
  },
  methods: {
    FetchSuppliers() {
      axios.post("/fetch_consumer_by_role", { role: 2 }).then(({ data }) => {
        this.providers = data.data.data;
      });
    },
    fetch_services() {
      axios.post("/fetch_additional_services").then(({ data }) => {
        this.services_types = data.data;
      });
    },
    Service_cost(index) {
      this.services[index].service_total_cost =
        this.services[index].Service_cost * this.services[index].service_count;
    },
    Service_selling(index) {
      this.services[index].service_total_selling =
        this.services[index].Service_selling *
        this.services[index].service_count;
    },
    addService() {
      this.services.push({
        supplier_id: "",
        service_type: "",
        service_count: 0,
        Service_cost: 0,
        Service_selling: 0,
        service_total_cost: 0,
        service_total_selling: 0,
        service_notes: "",
      });
    },
    removeService(index) {
      this.services.splice(index, 1);
    },
  },
  mounted() {
    this.FetchSuppliers();
    this.fetch_services();

    if (this.$route.params.id) {
      let fetched_services = JSON.parse(localStorage.getItem("services"));
      // console.log(fetched_services)
      this.services = fetched_services;
    }
  },
  updated() {
    this.$emit("getServices", this.services);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style></style>
