<template>
  <section class="all_emp card_style mt-0">
    <div class="d-flex align-items-center gap-3">
      <Fav v-tooltip.left="$t('add_to_favorites')" />
      <h4>{{ $t("Gifts") }}</h4>
    </div>
    <div class="main_header_style">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <input
          type="text"
          v-model="giftText"
          class="form-control w-50"
          @keyup="Gifts_categories((page = 1))"
          :placeholder="$t('search...')"
        />
        <router-link to="/gift/add" class="PrimaryButton btn"
          ><i class="fa-solid fa-plus"></i> {{ $t("Add New") }}
        </router-link>
      </div>
    </div>

    <!-- <div class="search-visa my-3">
           <form>
             <i class="fa-solid fa-magnifying-glass"></i>
             <input
               type="text"
               class="form-control"
               :placeholder="$t('search...')"
             />
           </form>
         </div> -->

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("description") }}</th>
            <th>{{ $t("photo") }}</th>
            <th>{{ $t("points") }}</th>
            <th>{{ $t("status") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="gift in 10" :key="gift">
            <td>
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
            <td>
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
            <td>
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
            <td width="10%">
              <Skeleton shape="circle" size="5rem"></Skeleton>
            </td>

            <td v-for="gift in 3" :key="gift">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(gift, index) in Gifts.data" :key="index">
            <td>{{ gift.id }}</td>
            <td>{{ gift.title }}</td>
            <td><p v-html="gift.description"></p></td>
            <td><img :src="gift.image" width="50" height="50" /></td>
            <td>{{ gift.points }}</td>
            <td>{{ gift.status }}</td>
            <td>
              <button
                @click="this.$router.push(`/gift/edit/${gift.id}`)"
                class="btn btn-success mx-1 w-auto"
              >
                <i class="fa-regular fa-edit"></i>
              </button>
              <button
                @click="deleteGifts(gift.id, index)"
                class="btn btn-danger mx-1 w-auto"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="Gifts"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="Gifts_categories"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Fav from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
// import $ from "jquery";
export default {
  name: "GiftsPage",
  components: {
    pagination,
    Fav,
    Skeleton,
  },
  data() {
    return {
      Gifts: [],
      giftText: "",
      loading: true,
    };
  },
  methods: {
    Gifts_categories(page = 1) {
      axios
        .post(`/index_package_book_gift?page=${page}`, { word: this.giftText })
        .then(({ data }) => {
          this.Gifts = data.data;
          this.loading = false;
          // console.log(this.Gifts);
        });
    },
    //delete visa
    deleteGifts(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.affiliates.id;
          const fromClick = true;
          axios.delete(`/package_book_gift/${id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.Gifts.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  created() {
    this.Gifts_categories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
