<template>
  <div class="col-lg-7 col-12">
    <div class="card_lists">
      <div class="card_list">
        <div class="text">
          <h6>{{ $t("online hotel reservation") }}</h6>
          <span>{{booking_online_count}}</span>
        </div>
        <div class="img">
          <img src="@/assets/media/image/icon/1.svg" alt="btn" />
        </div>
      </div>

      <div class="card_list" v-if="userEmail != targetEmail">
        <div class="text">
          <h6>{{ $t("offline hotel reservation") }}</h6>
          <span>{{booking_offline_count}}</span>
        </div>
        <div class="img">
          <img src="@/assets/media/image/icon/1.svg" alt="btn" />
        </div>
      </div>

      <div class="card_list">
        <div class="text">
          <h6>{{ $t("online flight reservation") }}</h6>
          <span>{{flight_online_count}}</span>
        </div>
        <!-- <div class="img">
          <img src="@/assets/media/image/btn3.png" alt="btn" />
        </div> -->
        <div class="img">
          <img src="@/assets/media/image/icon/2.svg" alt="btn" />
        </div>
      </div>

      <div class="card_list" v-if="userEmail != targetEmail">
        <div class="text">
          <h6>{{ $t("offline flight reservation") }}</h6>
          <span>{{flight_offline_count}}</span>
        </div>
        <div class="img">
          <img src="@/assets/media/image/icon/2.svg" alt="btn" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reservation-component",
  props:['booking_offline_count','booking_online_count','flight_offline_count','flight_online_count'],
  data() {
    return {
      userEmail: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).email_company : null,
      targetEmail: "info@tripsavisor.com"
    }
  }
};

</script>

<style></style>
