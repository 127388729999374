<template>
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    id="offcanvasBottom"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <div class="offcanvas-title" id="offcanvasBottomLabel">
        <h5>{{ $t("add view") }}</h5>
        <p>{{ $t("enter this data to continue") }}</p>
      </div>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <form @submit.prevent="addView()">
        <div class="row">
          <div class="col-lg-6 mb-4">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="countryValue"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry"
            >
            </Multiselect>
          </div>
          <div class="col-lg-6 mb-4">
            <label>{{ $t("The City") }}</label>
            <Multiselect
              v-model="cityValues"
              :options="cities.map((city) => city.id)"
              :custom-label="(opt) => cities.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :multiple="true"
              :hide-selected="true"
            >
            </Multiselect>
          </div>
          <!-- view ar -->
        </div>

        <div
          v-for="(title, index) in titles"
          :key="index"
          class="row align-items-end mb-4"
        >
          <div class="col-lg-5">
            <div class="w-100">
              <label>{{ $t("view name") }} ar</label>
              <input
                type="text"
                :placeholder="$t('enter view name')"
                v-model="title.title_ar"
              />
            </div>
          </div>
          <!-- view en -->
          <div class="col-lg-5">
            <div class="w-100">
              <label>{{ $t("view name") }} en</label>
              <input
                type="text"
                :placeholder="$t('enter view name')"
                v-model="title.title_en"
              />
            </div>
          </div>
          <!-- view id -->
          <div class="col-lg-5">
            <div class="w-100">
              <label>{{ $t("view name") }} en</label>
              <input
                type="text"
                :placeholder="$t('enter view name')"
                v-model="title.title_id"
              />
            </div>
          </div>
          <div
            class="plus col-lg-2"
            @:click="addContractRooms"
            v-if="titles.length - 1 <= index"
          >
            <i class="fa-solid fa-plus"></i>
          </div>
          <div
            class="col-lg-2 minus"
            v-on:click="removeContractRooms(index)"
            v-if="titles.length - 1 >= index && titles.length - 1 != index"
          >
            <i class="fa-solid fa-minus"></i>
          </div>
        </div>

        <button type="submit" class="add save btn mt-5">{{ $t("add") }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  emits: ["fetch-data"],
  components: {
    Multiselect,
  },
  data() {
    return {
      countries: [],
      cities: [],
      countryValue: "",
      cityValues: [],
      titles: [
        {
          title_ar: "",
          title_en: "",
          title_id: "",
        },
      ],
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    FetchCityFromCountry(value) {
      let basic_country_ids = {
        country_id: [value],
      };
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },
    addView() {
      axios
        .post("/view_types", {
          titles: this.titles,
          city_ids: this.cityValues,
          country_id: this.countryValue,
        })
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$emit("fetch-data");
          this.$router.push("/view");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    addContractRooms: function () {
      this.titles.push({});
    },
    removeContractRooms: function (index) {
      this.titles.splice(index, 1);
    },
  },
  created() {
    this.fetch_country();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "@/view/dashboard/wallet/_wallet.scss";
h5 {
  color: #0b1427;
  font-weight: 700;
  font-size: 32px;
}
p {
  font-size: 24px;
  color: #656b78;
}
form {
  label {
    display: inline-block;
    margin-bottom: 10px;
    font-family: "bold" !important;
  }
  i {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #0a909b;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 24px;
    margin-inline-start: 15px;
    cursor: pointer;
  }
  .minus i {
    background-color: red;
  }
}
</style>
