<template>
  <section class="card_style tree_organization">
    <div class="main_header_style">
      <h4>{{ $t("proces_cost_center") }}</h4>
    </div>

    <div class="row align-items-center mb-2 justify-content-center">
      <div class="col-12 col-lg-4">
        <div class="input-group">
          <datepicker
            v-model="from"
            name="from_date"
            :placeholder="$t('from')"
          ></datepicker>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="input-group">
          <datepicker
            v-model="to"
            name="to_date"
            :placeholder="$t('to')"
          ></datepicker>
        </div>
      </div>
    </div>
    <h4 v-if="branch.data === null">{{ $t("no_data") }}</h4>

    <div class="row" v-else>
      <table class="table" id="RoomTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("the statement") }}</th>

            <th>{{ $t("the value") }}</th>
            <th>{{ $t("Status") }}</th>
            <th>{{ $t("time") }}</th>
            <th>{{ $t("clerck") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 7" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(client, index) in branch.data" :key="index">
            <td>{{ client.id }}</td>
            <td>{{ client.text }}</td>

            <td>{{ client.amount }}</td>
            <td>
              <span v-if="client.type === 1"> {{ $t("debtor") }}</span>
              <span v-if="client.type === 2"> {{ $t("creditor") }}</span>
            </td>
            <td>{{ client.date }}</td>
            <td>{{ client.employee?.name }}</td>
            <td>
              <!-- <button
                @click="getTransferItem(client)"
                class="btn btn-info"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottomDetails"
                aria-controls="offcanvasBottom"
                :title="$t('details')"
              >
                <i class="fa-solid fa-info"></i>
              </button> -->
              <button
                @click="getTransferItem(client.id)"
                class="btn btn-info"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottomDetails"
                aria-controls="offcanvasBottom"
                :title="$t('details')"
              >
                {{ client.id }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="branch"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="Fetchbranch"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
    <detailsOffCanvas :item="item" />
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Datepicker from "vuejs3-datepicker";
import detailsOffCanvas from "@/components/dailyConstruct/detailsOffCanvas.vue";
// import Multiselect from "vue-multiselect";
// import moment from "moment";
import Skeleton from "primevue/skeleton";

export default {
  name: "detials-branch",
  components: {
    pagination,
    Datepicker,
    // Multiselect,
    detailsOffCanvas,
    Skeleton,
  },
  data() {
    return {
      branch: [],
      from: "",
      to: "",
      costCenter: null,
      item: {},
      loading: true,
      //   costCenters: ["test", "test2", "test3"],
    };
  },
  methods: {
    // fetch suppliers data
    getTransferItem(id) {
      axios.get(`/daily_restriction/${id}`).then(({ data }) => {
        this.item = data.data;
        // console.log(this.item);
      });
    },
    Fetchbranch() {
      const data = {
        branch_id: this.$route.params.branch_id,
        from: this.from,
        to: this.to,
      };
      axios.post(`/fetch_branch_process`, data).then(({ data }) => {
        this.branch = data.data;
        this.loading = false;
        // console.log(this.branch);
      });
    },
  },
  watch: {
    from: "Fetchbranch",
    to: "Fetchbranch",
  },

  created() {
    this.Fetchbranch();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.vuejs3-datepicker {
  width: 90%;
}
</style>
