f
<template>
  <section class="setting">
    <form class="card_style" @submit.prevent="addSetting()">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="d-flex gap-2 align-items-center px-3 mb-4">
              <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
              <h4 class="title">{{ $t("over_price") }}</h4>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
              <label for="">{{ $t("over_price_hotel") }}</label>
              <input
                type="number"
                name=""
                id=""
                class="form-control"
                v-model="percentageHotel"
                :placeholder="$t('over_price_hotel')"
              />
              <span
                class="text-danger"
                v-if="v$.percentageHotel.$error"
                v-text="v$.percentageHotel.$errors[0].$message"
              ></span>
              <select class="form-control" v-model="typeProfitHotel">
                <option value="1" selected>{{ $t("percentages") }}</option>
                <option value="2">{{ $t("value") }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
              <label for="">{{ $t("over_price_flight") }}</label>
              <input
                type="number"
                name=""
                id=""
                class="form-control"
                v-model="percentageFlight"
                :placeholder="$t('over_price_flight')"
              />
              <span
                class="text-danger"
                v-if="v$.percentageFlight.$error"
                v-text="v$.percentageFlight.$errors[0].$message"
              ></span>
              <select class="form-control" v-model="typeProfitFlight">
                <option value="1" selected>{{ $t("percentages") }}</option>
                <option value="2">{{ $t("value") }}</option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <button type="submit" class="btn PrimaryButton">
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "setting-website",
  data() {
    return {
      v$: useVuelidate(),
      percentageHotel: null,
      percentageFlight: null,
      typeProfitHotel: 1,
      typeProfitFlight: 1,
    };
  },
  validations() {
    return {
      percentageHotel: {
        required,
      },
      percentageFlight: {
        required,
      },
    };
  },
  methods: {
    addSetting() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const profitData = {
          hotel: {
            profit: this.percentageHotel,
            type_profit: this.typeProfitHotel, // 1=>PERCENTAGE ; 2=>FIXED;
          },

          flight: {
            profit: this.percentageFlight,
            type_profit: this.typeProfitFlight, // 1=>PERCENTAGE ; 2=>FIXED;
          },
        };
        axios
          .post("/store_default_organization_settings", profitData)
          .then(({ data }) => {
            // console.log(data)
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    showSetting() {
      axios.get("/get_default_organization_settings").then(({ data }) => {
        this.percentageHotel = data.data[0]?.percentage;
        this.typeProfitHotel = data.data[0]?.type;
        this.percentageFlight = data.data[1]?.percentage;
        this.typeProfitFlight = data.data[1]?.type;
      });
    },
  },
  created() {
    this.showSetting();
  },
  components: {
    AddFavDailog,
  },
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0.25rem;
  font-size: 0.8rem !important;
}
.form-control {
  padding: 0.5rem !important;
  font-size: 0.75rem !important;
}
.PrimaryButton {
  display: block;
  width: fit-content;
  margin-inline-start: auto;
  margin-top: 0.5rem;
}

.setting {
  background-color: #fff;
  border-radius: 15px;
  width: 95%;
  padding: 2rem 0;
  margin: 1.5rem auto;
  form {
    .form-group {
      width: 100%;
      position: relative;
      margin-bottom: 1rem;
      label {
        font-family: "bold";
        color: #0a909b;
        margin-bottom: 0.7rem;
      }
      .form-control {
        font-size: 0.8rem;
        padding: 0.75rem;
        font-family: "regular";
        color: #000;
      }
      select {
        &.form-control {
          width: fit-content;
          padding: 0.75rem 1.5rem;
          border-color: transparent;
          position: absolute;
          top: 2.2rem;
          left: 0.25rem;
          appearance: auto;
          background-color: transparent !important;
          &:focus {
            box-shadow: unset !important;
            border-color: transparent !important;
          }
        }
      }
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    .SecondButton {
      margin-inline-end: 1rem;
    }
    hr {
      margin: 2rem 0;
    }
  }
}
</style>
