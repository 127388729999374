<template>
  <form>
    <div class="form-group">
      <label class="form-label">{{ $t("add_note") }}</label>
      <textarea
        class="form-control"
        v-model="notes"
        :disabled="
          booking_portal_type === 2
            ? true
            : booking_portal_type === 1
              ? true
              : booking_portal_type === 3
                ? true
                : false
        "
      ></textarea>
    </div>
    <button
      type="button"
      class="btn add_note"
      @click.prevent="add_notes"
      :disabled="
        booking_portal_type === 2
          ? true
          : booking_portal_type === 1
            ? true
            : booking_portal_type === 3
              ? true
              : false
      "
    >
      {{ $t("add_note") }}
    </button>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      notes: "",
    };
  },
  props: ["note", "id", "booking_portal_type"],
  methods: {
    add_notes() {
      const formData = new FormData();
      formData.append("notes", this.notes);
      formData.append("offline_booking_hotel_id", this.$route.params.id);

      axios
        .post("/store_offline_booking_hotel_notes", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  watch: {
    note(newValue) {
      if (newValue) {
        this.notes = newValue;
      }
    },
  },
  mounted() {
    this.notes = this.note;
    // console.log(this.note, "m");
  },
};
</script>

<style lang="scss" scoped>
.add_note {
  border: none;
  outline: none;
  background-color: #0a909b;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
</style>
