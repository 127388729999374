<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>{{ $t("Insurance_Type") }}</h4>
    </div>
    <div class="main_header_style mb-3">
      <input
        type="text"
        v-model="InsuranceText"
        class="form-control w-50"
        @keyup="insurances_categories((page = 1))"
        :placeholder="$t('search...')"
      />
      <div>
        <router-link to="/Insurance_Type_Add" class="PrimaryButton btn w-100"
          ><i class="fa-solid fa-plus"></i> {{ $t("Add_Insurance_Type") }}
        </router-link>
      </div>
    </div>

    <!-- <div class="search-visa my-3">
          <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('search...')"
            />
          </form>
        </div> -->

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="insurance in 10" :key="insurance">
            <td v-for="insurance in 3" :key="insurance">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(insurance, index) in insurances.data" :key="index">
            <td>{{ insurance.id }}</td>
            <td>{{ insurance.titles }}</td>
            <td>
              <button
                @click="
                  this.$router.push(`/Insurance_Type_Edit/${insurance.id}`)
                "
                class="btn btn-success mx-1 w-auto"
              >
                <i class="fa-regular fa-edit"></i>
              </button>
              <button
                @click="deleteinsurances(insurance.id, index)"
                class="btn btn-danger mx-1 w-auto"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="insurances"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="insurances_categories"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
import Swal from "sweetalert2";
// import $ from "jquery";
export default {
  name: "allVisa",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      insurances: [],
      InsuranceText: "",
      loading: true,
    };
  },
  methods: {
    insurances_categories(page = 1) {
      axios
        .post(`/get_all_insurance?page=${page}`, { word: this.InsuranceText })
        .then(({ data }) => {
          this.insurances = data.data;
          this.loading = false;
          // console.log(this.insurances);
        });
    },
    //delete visa
    deleteinsurances(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.affiliates.id;
          const fromClick = true;
          axios.delete(`/insurance/${id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.insurances.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  created() {
    this.insurances_categories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
