<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("flight Contract") }}</h4>
    </div>
    <form class="form_topic w-100" @submit.prevent="addContract()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("contract_name") }} </label>

            <div class="form-group">
              <TabView>
                <TabPanel
                  v-for="(contract, index) in contract_name"
                  :key="index"
                  :class="[contract.name !== '' ? 'text-success' : '']"
                  :header="$t('name') + ' - ' + contract.lang"
                >
                  <div class="form-group mb-0">
                    <input
                      type="text"
                      :placeholder="$t('name')"
                      class="form-control"
                      :id="`name${index}`"
                      v-model="contract.name"
                    />
                    <!-- Add any additional content specific to each tab here -->
                  </div>
                </TabPanel>
              </TabView>
              <span class="input-errors" v-if="v$.contract_name.$error">{{
                v$.contract_name.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="d-flex justify-content-center h-100">
            <div class="input-group">
              <label class="form-label mb-4 align-self-end">{{
                $t("tour_degree")
              }}</label>
              <Multiselect
                v-model="cabin_code"
                :options="cabin_codes"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('cabin_codes')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
              <span class="input-errors" v-if="v$.cabin_code.$error">
                {{ v$.cabin_code.$errors[0].$message }}
              </span>
            </div>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("flight_line") }}</label>
            <Multiselect
              v-model="flight_company"
              :options="company"
              label="name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('flight_line')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.flight_company.$error">
              {{ v$.flight_company.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("suppliers") }}</label>
            <Multiselect
              v-model="supplierValues"
              :options="suppliers"
              label="name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('suppliers')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.supplierValues.$error">
              {{ v$.supplierValues.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("trip_kind") }}</label>
            <Multiselect
              v-model="trip_kind"
              :options="flighTypes"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('trip_kind')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.trip_kind.$error">
              {{ v$.trip_kind.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->
        <div class="col-12 my-3">
          <div class="d-flex align-items-center gap-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="1"
                v-model="directTrip"
              />
              <label class="form-label" for="inlineRadio1">{{
                $t("stop")
              }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio0"
                value="0"
                v-model="directTrip"
              />
              <label class="form-check-label" for="inlineRadio0">{{
                $t("Direct flight")
              }}</label>
            </div>
            <span class="input-errors" v-if="v$.directTrip.$error">
              {{ v$.directTrip.$errors[0].$message }}
            </span>
          </div>

          <div v-if="directTrip == '1'">
            <label for="transiteInputNumber">{{ $t("number_stoped") }}:</label>
            <input
              type="number"
              id="transiteInputNumber"
              class="form-control w-50"
              v-model="transiteInputNumber"
              @keyup="generateSections"
              @input="validateMaxValue"
            />
            <span class="input-errors" v-if="v$.transiteInputNumber.$error">
              {{ v$.transiteInputNumber.$errors[0].$message }}
            </span>
            <!-- Generate sections based on the input number -->
          </div>
        </div>

        <!-- start input group -->
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("Take-off airport") }}</label>
            <!-- v-model="Form.country_id" -->
            <!-- Launch -->
            <div class="form-group ent">
              <input
                type="search"
                class="form-control"
                :placeholder="$t('Launch')"
                v-model="searchValueLaunch"
                @keyup="searchLaunch"
              />
              <img
                class="ent"
                src="@/assets/media/image/icon/entlak.svg"
                alt=""
              />
              <div
                class="resultSearch"
                v-if="searchValueLaunch.length > 2 && launchSearch.length"
              >
                <h6 class="head_search">{{ $t("Launch") }}</h6>
                <ul class="hotels">
                  <li
                    v-for="(launch, index) in launchSearch"
                    :key="index"
                    @click="
                      sendLaunchName(
                        launch.title == null ? '' : launch.title,
                        launch.code == null ? '' : launch.code,
                        launch.id
                      )
                    "
                  >
                    <div class="hotel_image">
                      {{ launch.code == null ? "" : launch.code }}
                    </div>
                    <div class="texts">
                      <p class="title">
                        {{ launch.title == null ? "" : launch.title }}
                      </p>
                      <p class="country">
                        {{ launch.country == null ? "" : launch.country }} -
                        {{ launch.city_title == null ? "" : launch.city_title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <span class="input-errors" v-if="v$.searchValueLaunch.$error">
              {{ v$.searchValueLaunch.$errors[0].$message }}
            </span>
            <!-- Launch -->
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("Arrival airport") }}</label>
            <div class="form-group ent">
              <input
                type="search"
                class="form-control"
                :placeholder="$t('back')"
                v-model="searchValueReturn"
                @keyup="searchReturn"
              />

              <div
                class="resultSearch"
                v-if="searchValueReturn.length > 2 && returnSearch.length"
              >
                <h6 class="head_search">{{ $t("back") }}</h6>
                <ul class="hotels">
                  <li
                    v-for="(returns, indexx) in returnSearch"
                    :key="indexx"
                    @click="
                      sendReturnName(
                        returns.title == null ? '' : returns.title,
                        returns.code == null ? '' : returns.code,
                        returns.id
                      )
                    "
                  >
                    <div class="texts">
                      <p class="title">
                        {{ returns.title == null ? "" : returns.title }}
                      </p>
                      <p class="country">
                        {{ returns.country == null ? "" : returns.country }} -
                        {{
                          returns.city_title == null ? "" : returns.city_title
                        }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <span class="input-errors" v-if="v$.searchValueReturn.$error">
              {{ v$.searchValueReturn.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->

        <div class="row">
          <div
            class="col-6"
            v-for="(transite, transiteIndex) in transiteDetails"
            :key="transiteIndex"
          >
            <div class="form-group ent">
              <label class="form-label">{{ $t("stooped_airport") }}</label>
              <input
                type="search"
                class="form-control"
                :placeholder="$t('stooped_airport')"
                v-model="transite.stopTitle"
                @keyup="searchStop($event, transiteIndex)"
              />

              <div
                class="resultSearch"
                v-if="
                  transite.stopTitle.length > 2 && transite.stopSearch.length
                "
              >
                <h6 class="head_search">{{ $t("stop") }}</h6>
                <ul class="hotels">
                  <li
                    v-for="(stop, index) in transite.stopSearch"
                    :key="index"
                    @click="
                      sendStopName(
                        stop.title == null ? '' : stop.title,
                        stop.id,
                        transiteIndex
                      )
                    "
                  >
                    <div class="hotel_image">
                      {{ stop.code == null ? "" : stop.code }}
                    </div>
                    <div class="texts">
                      <p class="title">
                        {{ stop.title == null ? "" : stop.title }}
                      </p>
                      <p class="country">
                        {{ stop.country == null ? "" : stop.country }} -
                        {{ stop.city_title == null ? "" : stop.city_title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_from") }}</label>
            <Datepicker
              v-model="from"
              lang="ar"
              :placeholder="$t('period_from')"
            />
            <span class="input-errors" v-if="v$.from.$error">
              {{ v$.from.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_to") }}</label>
            <Datepicker v-model="to" lang="ar" :placeholder="$t('period_to')" />
            <span class="input-errors" v-if="v$.to.$error">
              {{ v$.to.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
// import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import Loading from "@/components/LottieFiles/loading.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

const contract_name = helpers.withMessage(
  "Please fill at least one name",
  (value) => value.some((item) => item.name.trim() !== "")
);

export default {
  name: "flight-contract-edit",
  data() {
    return {
      v$: useVuelidate(),
      contract_id: this.$route.params.id,
      languages: ["ar", "en", "id"],
      contract_name: [],
      searchValueLaunch: "",
      searchValueStop: "",
      searchValueReturn: "",
      searchValueLaunchCode: "",
      searchValueStopCode: "",
      searchValueReturnCode: "",
      launchSearch: [],
      stopSearch: [],
      returnSearch: [],
      cabin_codes: [
        {
          id: 1,
          title: "First",
          value: 0,
        },
        {
          id: 2,
          title: "Business",
          value: 1,
        },
        {
          id: 3,
          title: "Premium",
          value: 3,
        },
        {
          id: 4,
          title: "classHome",
          value: 4,
        },
      ],
      cabin_code: "",
      company: [],
      flight_company: "",
      flightLine: "",
      trip_kind: "",
      flighTypes: [
        {
          id: 0,
          title: this.$t("go_only"),
        },
        {
          id: 1,
          title: this.$t("go_back"),
        },
      ],
      transiteInputNumber: 0,
      transiteDetails: [],
      directTrip: "",
      from: "",
      to: "",
      suppliers: [],
      supplierValues: "",

      loading: false,
    };
  },
  components: {
    Multiselect,
    Datepicker: VueDatepickerUi,
    Loading,
    TabView,
    TabPanel,
  },
  validations() {
    return {
      contract_name: { contract_name },
      cabin_code: {
        id: { required },
      },
      flight_company: {
        required,
      },
      supplierValues: {
        required,
      },
      trip_kind: { required },
      directTrip: { required },
      transiteInputNumber: this.directTrip == "1" ? { required } : {},

      searchValueLaunch: { required },
      searchValueReturn: { required },
      from: { required },
      to: { required },
    };
  },
  methods: {
    generateSections() {
      this.transiteDetails = [];
      for (let i = 0; i < this.transiteInputNumber; i++) {
        console.log(i);
        this.transiteDetails.push({
          stopId: "",
          stopTitle: "",
          stopSearch: [],
        });
      }
    },

    searchLaunch(event) {
      const searchText = { title: event.target.value };
      axios
        .post(`/search_airports`, searchText)
        .then((res) => (this.launchSearch = res.data.data))
        .catch((err) => console.log(err));
    },
    searchStop(event, index) {
      const searchText = { title: event.target.value };
      axios
        .post(`/search_airports`, searchText)
        .then(({ data }) => {
          this.transiteDetails[index].stopSearch = data.data;
        })
        .catch((err) => console.log(err));
    },

    searchReturn(event) {
      const searchText = { title: event.target.value };
      axios
        .post(`/search_airports`, searchText)
        .then((res) => (this.returnSearch = res.data.data))
        .catch((err) => console.log(err));
    },

    // fetch launch
    sendLaunchName(launchTitle, launchCode, launchId) {
      this.launchTitle = launchTitle;
      this.searchValueLaunch = launchTitle;
      this.searchValueLaunchCode = launchCode;

      this.launch_id = launchId;
      // console.log(launchId);

      this.launchSearch = [];
    },

    sendStopName(stopTitle, stop_id, transiteIndex) {
      this.transiteDetails[transiteIndex].stopId = stop_id;
      this.transiteDetails[transiteIndex].stopTitle = stopTitle;

      this.transiteDetails[transiteIndex].stopSearch = [];
    },
    // fetch return
    sendReturnName(returnTitle, returnCode, returnId) {
      this.returnTitle = returnTitle;
      this.searchValueReturn = returnTitle;
      this.searchValueReturnCode = returnCode;

      this.return_id = returnId;
      // console.log(returnId);
      this.returnSearch = [];
    },

    // fetch airport data
    FetchFlightCompany() {
      axios.get(`/flight_companies`).then(({ data }) => {
        this.company = data.data.data;
        // console.log(this.company);
      });
    },
    FetchSuppliers() {
      axios.post(`/fetch_consumer_by_role`, { role: 2 }).then(({ data }) => {
        this.suppliers = data.data.data;
      });
    },
    fetchContractData() {
      axios.get(`/flight_contract/${this.contract_id}`).then(({ data }) => {
        // console.log(data.data);
        this.from = data.data.from;
        this.to = data.data.to;
        this.contract_name = data.data.name.map((ele) => {
          return {
            lang: ele.locale,
            name: ele.name,
          };
        });
        this.supplierValues = data.data.supplier_id;
        this.flight_company = data.data.flight_companie_id;

        this.cabin_codes.forEach((ele) => {
          if (ele.value == data.data.trip_degree) {
            this.cabin_code = ele;
          }
        });

        this.flighTypes.forEach((ele) => {
          if (ele.id == data.data.flight_type) {
            this.trip_kind = ele;
          }
        });
        this.directTrip = data.data.flight_stop;
        this.launch_id = data.data.arrive_airport.id;
        this.searchValueLaunch = data.data.arrive_airport.title;

        this.return_id = data.data.dep_airport.id;
        this.searchValueReturn = data.data.dep_airport.title;
      });
    },
    validateMaxValue() {
      if (this.transiteInputNumber > 6) {
        this.transiteInputNumber = 6;
      }
    },
    async addContract() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let contractData = {
          from: moment(this.from).format("YYYY-MM-DD"),
          to: moment(this.to).format("YYYY-MM-DD"),
          supplier_id: this.supplierValues.id,
          trip_degree: this.cabin_code.value,
          flight_type: this.trip_kind.id,
          flight_companie_id: this.flight_company.id,
          flight_stop: this.directTrip,
          arrive_airport_id: this.launch_id,
          dep_airport_id: this.return_id,
        };
        this.contract_name.forEach((contract) => {
          contractData[`name_${contract.lang}`] = contract.name;
        });
        contractData.stops = this.transiteDetails.map((transite) => {
          return {
            airport: transite.stopId,
          };
        });

        // console.log(contractData);

        this.loading = true; // Start loading

        axios
          .put(`/flight_contract/${this.contract_id}`, contractData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.loading = false; //the loading ended
            this.$router.push("/flight-contract");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          });
      }
    },
  },
  created() {
    this.FetchSuppliers();
    this.FetchFlightCompany();
    this.fetchContractData();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_contract.scss";
section.v-calendar.left {
  width: 80%;
}
.form-group.ent img.ent {
  position: absolute;
  top: 17px;
  right: -3px;
}
</style>
