<template>
  <div class="notification-container" ref="container">
    <div class="notification-icon" @click="toggleDropdown">
      <i class="fas fa-bell"></i>
    </div>
    <div
      v-if="showDropdown"
      :class="`${
        notifications.length > 0
          ? 'notification-dropdown'
          : 'notification-dropdown-empty'
      }`"
      :style="
        $i18n.locale === 'ar' ? 'left: 0 !important;' : 'right: 0 !important;'
      "
    >
      <!-- Check if notifications array is empty -->
      <div v-if="notifications.length === 0">
        <p class="notifications">{{ $t("No notifications") }}</p>
      </div>
      <!-- If notifications array is not empty, loop through notifications -->
      <div
        v-else
        :class="`${notifications.length > 3 ? 'notification-items' : ''}`"
      >
        <router-link
          v-for="(notification, index) in notifications"
          :to="
            notification.type == 'book_package'
              ? `/bookUmrah/${notification.book_id}`
              : notification.type == 'book_flight'
              ? `/b2cUsersflight/${notification.book_id}`
              : notification.type == 'book_online_hotel'
              ? `/hotel-view/${notification.book_id}`
              : notification.type == 'book_offline_hotel'
              ? `/hotels/book_offline/${notification.book_id}`
              : notification.url
          "
          :key="index"
          class="notification-item d-flex align-items-center justify-content-between"
          @click="handleNotificationClick(notification.notification_id)"
        >
          <div class="notification-title d-flex flex-column">
            <div class="notification-title">{{ notification.title }}</div>
            <div class="notification-description">
              {{ notification.body }}
            </div>
          </div>
          <div class="d-flex flex-column">
            <span class="time">{{ notification.time }}</span>
            <span class="time" v-if="notification.is_read == 'UnRead'"
              ><i class="fa-solid fa-circle"></i
            ></span>
          </div>
        </router-link>
      </div>
    </div>
    <!-- Show notification count badge only if there are notifications -->
    <span
      v-if="unreadNotificationsCount > 0"
      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
    >
      {{ unreadNotificationsCount }}
    </span>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import axios from "axios";

const store = useStore();
const showDropdown = ref(false);
const container = ref(null); // Reference to the notification container

const notifications = computed(() => {
  return store.state.notifications;
});

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const unreadNotificationsCount = computed(() => {
  return store.state.notifications.filter(
    (notification) => notification.is_read === "UnRead"
  ).length;
});

const handleNotificationClick = (id) => {
  showDropdown.value = false;
  axios
    .post(`/employee_read_single_notification`, {
      notification_id: id,
    })
    .then(() => {
      store.dispatch("fetchNotifications");
    });
};

const closeDropdown = () => {
  showDropdown.value = false;
};

onMounted(() => {
  store.dispatch("fetchNotifications");

  // Add event listener to close dropdown when clicking outside
  document.addEventListener("click", handleClickOutside);
});

const handleClickOutside = (event) => {
  if (!showDropdown.value) return;

  const dropdown = container.value; // Access the container using the reference
  if (!dropdown.contains(event.target)) {
    closeDropdown();
  }
};

// Remove event listener when component is unmounted
watch(
  () => showDropdown,
  (newValue) => {
    if (!newValue) {
      document.removeEventListener("click", handleClickOutside);
    }
  }
);
</script>

<script>
export default {
  name: "notifications-page",
};
</script>
<style lang="scss" scoped>
.notification-container {
  position: relative;
  display: inline-block;
}

.notification-icon {
  cursor: pointer;
}

.notification-dropdown {
  position: absolute;
  top: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: auto;
  overflow: auto;
}
.notification-dropdown-empty {
  position: absolute;
  top: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.notification-item {
  padding: 0.8rem;
  border-bottom: 1px solid #ccc;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-title {
  font-weight: bold;
  color: black;
}

.notification-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 400px;
  color: gray;
  font-size: 0.9rem;
  font-weight: 200;
}
.notifications {
  margin: 0;
  padding: 0.8rem 6rem;
  width: max-content;
}
.time {
  width: 60px;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: end;
}
.notification-items {
  height: 30vh;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 10px;
}
</style>
