<template>
  <section class="all_emp card_style">
    <div class="main_header_style app">
      <h4>{{ $t("packages") }}</h4>
      <router-link to="/package_sections/add" class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add_section") }}</router-link
      >
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table app-sections">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("Title") }}</th>
              <th>{{ $t("describtion") }}</th>
              <th>{{ $t("The Countries") }}</th>
              <th>{{ $t("nationality") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(app_section, index) in app_section.data" :key="index">
              <td>{{ app_section.id }}</td>
              <td>{{ app_section.title }}</td>
              <td v-html="app_section.description"></td>
              <td>{{ app_section.countries[0]?.title }}</td>
              <td>
                <p
                  v-for="(nationality, index) in app_section.nationalites"
                  :key="index"
                >
                  {{ nationality?.title }}
                </p>
              </td>
              <td>
                <button
                  class="btn btn-danger"
                  @click="deleteSection(app_section.id, index)"
                >
                  {{ $t("delete") }} <i class="fa-solid fa-trash"></i>
                </button>

                <button
                  class="btn btn-success mx-2"
                  @click="
                    $router.push(`/package_sections/edit/${app_section.id}`)
                  "
                >
                  {{ $t("edit") }} <i class="fa-solid fa-pen"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
  import axios from "axios";
  import Swal from "sweetalert2";

  export default {
    name: "appSections",
    data() {
      return {
        app_section: {},
      };
    },
    methods: {
      fetch_app_section() {
        axios.get("/package_cards").then(({ data }) => {
          this.app_section = data.data;
          // console.log(this.app_section.data);
        });
      },
      deleteSection(id, index) {
        Swal.fire({
          title: this.$t("delete_section"),
          text: this.$t("are_you_sure_you_want_to_delete"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("back"),
          confirmButtonText: this.$t("confirm_decline"),
        }).then((result) => {
          if (result.isConfirmed) {
            const fromClick = true;
            axios
              .delete(`/package_cards/${id}`, { fromClick })
              .then(({ data }) => {
                Swal.fire({
                  title: "",
                  text: data.message,
                  icon: "success",
                });
                this.app_section.data.splice(index, 1); // Remove the deleted item from the array
              })
              .catch((error) => {
                if(error.response.status != 403){
                  Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                  });
                }
              });
          }
        });
      },
    },
    mounted() {
      this.fetch_app_section();
    },
  };
</script>

<style>
  table img {
    width: 100px;
  }
</style>
