<template>
  <div class="markters">
    <div class="w-100" v-if="loading && $route.params.id">
      <Skeleton width="25%" class="mt-3" height="2rem"></Skeleton>
      <div
        class="d-flex align-items-center justify-content-between mt-5 w-100"
        v-for="i in 5"
        :key="i"
      >
        <Skeleton width="25%" height="3rem"></Skeleton>
        <Skeleton width="25%" class="mx-2" height="3rem"></Skeleton>
        <Skeleton width="25%" class="mx-2" height="3rem"></Skeleton>
        <Skeleton width="25%" height="3rem"></Skeleton>
      </div>
    </div>
    <form @submit.prevent="addService()" v-else>
      <div class="row p-4">
        <h4 class="markter" v-if="!$route.params.id">
          {{ $t("Add Markter") }}
        </h4>
        <h4 class="markter" v-else>{{ $t("Edit Markter") }}</h4>

        <div class="row border">
          <div class="d-flex align-items-center justify-content-between">
            <div class="col-lg-2 col-md-4 col-12 mx-3">
              <div class="form-group">
                <label> {{ $t("first_name") }} </label>
                <input
                  type="text"
                  v-model="Form.first_name"
                  class="form-control"
                  :placeholder="$t('first_name')"
                />
                <span
                  class="text-danger"
                  v-if="v$.Form.first_name.$error"
                  v-text="v$.Form.first_name.$errors[0].$message"
                ></span>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-12">
              <div class="form-group">
                <label> {{ $t("last_name") }} </label>
                <input
                  type="text"
                  v-model="Form.last_name"
                  class="form-control"
                  :placeholder="$t('last_name')"
                />
                <span
                  class="text-danger"
                  v-if="v$.Form.last_name.$error"
                  v-text="v$.Form.last_name.$errors[0].$message"
                ></span>
              </div>
            </div>
            <div class="col-lg-2 col-md-4 col-12 mt-3">
              <label>{{ $t("nationality") }}</label>
              <div class="form-group">
                <Multiselect
                  v-model="Form.nationalityValue"
                  :options="nationalities"
                  :placeholder="$t('choose_nationality')"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  select-label=""
                  :hide-selected="true"
                  @keyup="searchNationality"
                >
                </Multiselect>
                <span
                  class="text-danger"
                  v-if="v$.Form.nationalityValue.$error"
                  v-text="v$.Form.nationalityValue.$errors[0].$message"
                ></span>
              </div>
            </div>

            <div class="col-lg-2 col-md-4 col-12">
              <div class="form-group">
                <label>{{ $t("identity_number") }}</label>
                <input
                  type="number"
                  min="0"
                  v-model="Form.identity_number"
                  class="form-control"
                  :placeholder="$t('identity_number')"
                />
                <span
                  class="text-danger"
                  v-if="v$.Form.identity_number.$error"
                  v-text="v$.Form.identity_number.$errors[0].$message"
                ></span>
              </div>
            </div>

            <div class="col-lg-2 col-md-4 col-12 my-3">
              <div class="form-group">
                <label> {{ $t("phone") }} </label>
                <input
                  type="number"
                  v-model="Form.phone"
                  class="form-control"
                  :placeholder="$t('phone')"
                />
                <span
                  class="text-danger"
                  v-if="v$.Form.phone.$error"
                  v-text="v$.Form.phone.$errors[0].$message"
                ></span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row border my-3 d-flex align-items-center justify-content-between"
        >
          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="form-group">
              <label> {{ $t("Account Number") }} </label>
              <input
                type="number"
                v-model="Form.account_number"
                class="form-control"
                :placeholder="$t('Account Number')"
              />
              <span
                class="text-danger"
                v-if="v$.Form.account_number.$error"
                v-text="v$.Form.account_number.$errors[0].$message"
              ></span>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="form-group">
              <label> {{ $t("Bank Name") }} </label>
              <input
                type="text"
                v-model="Form.bank_name"
                class="form-control"
                :placeholder="$t('Bank Name')"
              />
              <span
                class="text-danger"
                v-if="v$.Form.bank_name.$error"
                v-text="v$.Form.bank_name.$errors[0].$message"
              ></span>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-12 my-3">
            <div class="form-group">
              <label> {{ $t("Bank Name person") }} </label>
              <input
                type="text"
                v-model="Form.account_owner_name"
                class="form-control"
                :placeholder="$t('Bank Name person')"
              />
              <span
                class="text-danger"
                v-if="v$.Form.account_owner_name.$error"
                v-text="v$.Form.account_owner_name.$errors[0].$message"
              ></span>
            </div>
          </div>
        </div>

        <div class="row border my-3 p-3">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <label for="image_one">{{ $t("upload image") }}</label>
            <input
              type="file"
              id="image_one"
              class="form-control"
              @change="onFileChange"
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
            <img :src="image_one" class="w-50 mt-2" />
            <span
              class="text-danger"
              v-if="v$.image_one.$error"
              v-text="v$.image_one.$errors[0].$message"
            ></span>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <label for="image_two">{{ $t("upload image") }}</label>
            <input
              type="file"
              id="image_two"
              class="form-control"
              @change="onFileChangeTwo"
              accept="image/png, image/gif, image/jpeg, image/jpg"
            /><img :src="image_two" class="w-50 mt-2" />
            <span
              class="text-danger"
              v-if="v$.image_two.$error"
              v-text="v$.image_two.$errors[0].$message"
            ></span>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <label for="image_three">{{ $t("upload image") }}</label>
            <input
              type="file"
              id="image_three"
              class="form-control"
              @change="onFileChangeThree"
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
            <img :src="image_three" class="w-50 mt-2" />
            <span
              class="text-danger"
              v-if="v$.image_three.$error"
              v-text="v$.image_three.$errors[0].$message"
            ></span>
          </div>
        </div>

        <div
          class="row border d-flex align-items-center justify-content-between my-3"
        >
          <div class="col-lg-3 col-md-4 col-sm-12">
            <div class="form-group">
              <label> {{ $t("email") }} </label>
              <input type="email" v-model="Form.email" class="form-control" />
              <span
                class="text-danger"
                v-if="v$.Form.email.$error"
                v-text="v$.Form.email.$errors[0].$message"
              ></span>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-sm-12 my-3">
            <div class="form-group">
              <label> {{ $t("password") }} </label>
              <input
                type="password"
                v-model="Form.password"
                class="form-control"
              />
              <span
                class="text-danger"
                v-if="v$.Form.password.$error"
                v-text="v$.Form.password.$errors[0].$message"
              ></span>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-sm-12 mt-3">
            <label>{{ $t("affiliate_category") }}</label>
            <div class="form-group">
              <Multiselect
                v-model="Form.value"
                :options="options"
                :placeholder="$t('type')"
                label="title"
                track-by="id"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
              <span
                class="text-danger"
                v-if="v$.Form.value.$error"
                v-text="v$.Form.value.$errors[0].$message"
              ></span>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-4 col-sm-12">
          <button type="submit" class="add btn PrimaryButton">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import Skeleton from "primevue/skeleton";

export default {
  data() {
    return {
      v$: useVuelidate(),
      loading: true,
      Form: {
        identity_number: null,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: null,
        nationalityValue: "",
        value: "",
        affiliate_category_id: 0,
        grade_id: 1,
        account_owner_name: "",
        bank_name: "",
        account_number: null,
      },
      image_three: "",
      image_two: "",
      image_one: "",

      nationalities: [],
      options: [],
      defaultImage: require("@/assets/media/image/icon/user.png"),
    };
  },
  validations() {
    return {
      image_three: {
        required,
      },
      image_two: {
        required,
      },
      image_one: {
        required,
      },
      Form: {
        identity_number: {
          required,
        },
        first_name: {
          required,
        },
        last_name: {
          required,
        },
        email: {
          required,
          email,
        },
        phone: {
          required,
        },
        password: {
          required,
        },
        nationalityValue: {
          required,
        },
        account_number: {
          required,
        },
        bank_name: {
          required,
        },
        account_owner_name: {
          required,
        },
        value: {
          required,
        },
      },
    };
  },
  components: {
    Multiselect,
    Skeleton,
  },
  methods: {
    addService() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        formData.append("identity_number", this.Form.identity_number);
        formData.append("first_name", this.Form.first_name);
        formData.append("last_name", this.Form.last_name);
        formData.append("email", this.Form.email);
        formData.append("phone", this.Form.phone);
        formData.append("password", this.Form.password);
        formData.append("grade_id", this.Form.grade_id);
        formData.append("nationality_id", this.Form.nationalityValue.id);
        if (this.Form.value != null) {
          formData.append("affiliate_category_id", this.Form.value.id);
        }
        if (this.Form.value != null) {
          formData.append("affiliate_category", this.Form.value.id);
        }

        formData.append("account_owner_name", this.Form.account_owner_name);
        formData.append("bank_name", this.Form.bank_name);
        formData.append("account_number", this.Form.account_number);

        if (this.$route.params.id != undefined) {
          if (this.fileData_one instanceof File) {
            formData.append("image_one", this.fileData_one);
          }

          if (this.fileData_two instanceof File) {
            formData.append("image_two", this.fileData_two);
          }

          if (this.fileData_three instanceof File) {
            formData.append("image_three", this.fileData_three);
          }
          axios
            .post(`/org_update_marketer/${this.$route.params.id}`, formData)
            .then(() => {
              Swal.fire({
                title: "",
                text: this.$t("marketer updated by organization successfully"),
                icon: "success",
              });

              this.$router.push("/markters");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          if (this.fileData_one != undefined) {
            formData.append("image_one", this.fileData_one);
          }
          if (this.fileData_two != undefined) {
            formData.append("image_two", this.fileData_two);
          }
          if (this.fileData_three != undefined) {
            formData.append("image_three", this.fileData_three);
          }

          axios
            .post("/organization_marketers", formData)
            .then(() => {
              Swal.fire({
                title: "",
                text: this.$t(
                  "a new marketer has added by organization successfully"
                ),
                icon: "success",
              });
              // console.log(this.Form, "add");
              this.$router.push("/markters");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
    searchNationality(event) {
      const searchText = {
        word: event == undefined ? "" : event.target.value,
      };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
    },
    onFileChange(event) {
      this.fileData_one = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData_one);
      reader.onload = () => (this.image_one = reader.result);
    },
    onFileChangeTwo(event) {
      this.fileData_two = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData_two);
      reader.onload = () => (this.image_two = reader.result);
    },
    onFileChangeThree(event) {
      this.fileData_three = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData_three);
      reader.onload = () => (this.image_three = reader.result);
    },
    affliliates() {
      axios.post(`/index_affiliate_categories`).then(({ data }) => {
        this.options = data.data.data;
        // console.log(this.options, "options");
      });
    },
  },
  async mounted() {
    this.affliliates();

    if (this.$route.params.id != undefined) {
      await axios
        .get(`/organization_marketers/${this.$route.params.id}`)
        .then((res) => {
          this.Form = res.data.data;
          this.Form.value = res.data.data.affiliate_category;
          this.Form.nationalityValue = res.data.data.nationality;
          this.Form.password = res.data.data.password;
          this.Form.first_name = res.data.data.first_name;
          this.Form.last_name = res.data.data.last_name;
          this.Form.email = res.data.data.email;
          this.Form.phone = res.data.data.phone;
          this.Form.identity_number = res.data.data.identity_number;
          this.Form.account_owner_name = res.data.data.account_owner_name;
          this.Form.bank_name = res.data.data.bank_name;
          this.Form.account_number = res.data.data.account_number;
          this.Form.affiliate_category_id =
            res.data.data?.affiliate_category?.id;
          this.image_one = res.data.data.image_one;
          this.image_two = res.data.data.image_two;
          this.image_three = res.data.data.image_three;
          this.loading = false;

          // this.Form.affiliate_category_id =
          // console.log(res.data.data, "edit");
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.row {
  height: 100%;
  background: #f9fafb;
}
.markters {
  background-color: #fff;
}
.mt {
  margin-top: 2.4rem;
}
.markter {
  color: #000;
  font-family: "bold";
  font-size: 2rem;
}
label {
  font-size: 1.2rem !important;
}
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
.point_title {
  color: #000;
  font-family: "bold";
  font-size: 1.2rem;
}
</style>
