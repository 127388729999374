<template>
  <section class="rate_code card_style">
    <!-- skelton -->
    <div class="row" v-if="loading && $route.params.rate_id">
      <div class="col-12">
        <Skeleton width="10%" class="mb-2"></Skeleton>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 my-3" v-for="n in 4" :key="n">
        <Skeleton width="10%" class="mb-2"></Skeleton>
        <Skeleton width="70%" height="2rem" class="mb-2"></Skeleton>
      </div>
      <div class="col-12 my-3">
        <Skeleton width="10%" class="mb-2"></Skeleton>
      </div>
    </div>
    <!-- skelton -->

    <form @submit.prevent="addRateCode()" v-else>
      <div class="title">
        <h4>Rate Code</h4>
        <!-- <div class="contract_details">
                <span>{{$t("contract name")}} : </span>
                <span>{{$t("Duration of contract")}} : </span>
            </div> -->
      </div>
      <div class="row mb-3">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label>{{ $t("Rate name") }}</label>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("Rate name") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(rate, index) in rate_name"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("Rate name") }} ({{ rate.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="rate.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.rate_name.$error">{{
              v$.rate_name.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="name">{{ $t("Destination of arrival") }}</label>
            <Multiselect
              v-model="country_id"
              :options="countries"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.country_id.id.$error">{{
              v$.country_id.id.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="from">{{ $t("Period from") }}</label>
            <Datepicker v-model="fromDate" lang="ar" disabled />
            <span class="input-errors" v-if="v$.fromDate.$error">{{
              v$.fromDate.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="to">{{ $t("Period to") }}</label>
            <Datepicker v-model="toDate" lang="ar" disabled />
            <span class="input-errors" v-if="v$.toDate.$error">{{
              v$.toDate.$errors[0].$message
            }}</span>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <h5>All Visa Cards</h5>
        <div
          class="col-lg-6 col-md-6 col-12 visaData"
          v-for="(visa, index) in allVisa"
          :key="index"
        >
          <div class="visa_name">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`visaCheck${index}`"
              v-model="visa.checked"
              @change="addToCheckedVisa"
            />
            <label class="form-check-label" :for="`visaCheck${index}`">
              {{ visa.type_name }}
            </label>
          </div>
          <div class="price" v-if="visa.checked">
            <div class="form-group">
              <label>{{ $t("cost") }}</label>
              <input
                type="number"
                class="form-control"
                placeholder="0"
                v-model="visa.cost"
              />
            </div>
            <div class="form-group">
              <label>{{ $t("selling") }}</label>
              <input
                type="number"
                class="form-control"
                placeholder="0"
                v-model="visa.price"
              />
            </div>
            <div class="form-group">
              <label>{{ $t("count") }}</label>
              <input
                type="number"
                class="form-control"
                placeholder="0"
                v-model="visa.number"
              />
            </div>
          </div>
        </div>
        <span class="input-errors" v-if="v$.isCheckedVisaData.$error">{{
          v$.isCheckedVisaData.$errors[0].$message
        }}</span>
        <span class="input-errors" v-if="v$.checkedVisa.$error">{{
          v$.checkedVisa.$errors[0].$message
        }}</span>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn PrimaryButton">
          {{ $t("save") }}
        </button>
      </div>
      <div class="row">
        <div class="table-responsive">
          <table class="table" v-if="checkedVisa.length">
            <thead>
              <tr>
                <th></th>
                <th v-for="day in selectedDays" :key="day.date">
                  <div class="w-100 d-flex justify-content-center">
                    <div class="date">{{ day.date }}</div>
                  </div>
                </th>
              </tr>
              <tr>
                <th>{{ $t("type") }}</th>
                <th
                  v-for="(day, index) in selectedDays"
                  :key="day.date"
                  :class="{ blue: index % 2 == 0, yellow: index % 2 == 1 }"
                >
                  <div>{{ $t("cost") }}</div>
                  <div>{{ $t("selling") }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(visa, visaIndex) in checkedVisa" :key="visaIndex">
                <td class="column_title">
                  <div>{{ visa.type_name }}</div>
                  <div class="text-danger">{{ $t("Deactivate day") }}</div>
                </td>
                <td
                  v-for="(day, index) in visa.days"
                  :key="day.date"
                  :class="{ blue: index % 2 == 0, yellow: index % 2 == 1 }"
                >
                  <div>
                    <input
                      type="number"
                      class="form-control"
                      v-model="day.cost"
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      class="form-control"
                      v-model="day.selling_price"
                    />
                  </div>

                  <div class="w-100 d-flex justify-content-end ps-4">
                    <label class="switch">
                      <input
                        type="checkbox"
                        :id="`diactive${index}`"
                        v-model="day.date_activation"
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import moment from "moment";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import Skeleton from "primevue/skeleton";

export default {
  components: { Multiselect, Datepicker: VueDatepickerUi, Skeleton },
  data() {
    return {
      v$: useVuelidate(),
      contract_id: this.$route.params.id,
      season_id: this.$route.params.season_id,
      rate_id: this.$route.params.rate_id,
      languages: ["ar", "en", "id"],
      rate_name: [],
      name: "",
      fromDate: new Date(),
      toDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      number_of_nights: 1,
      countries: [],
      country_id: "",
      allVisa: [],
      fetchedRateVisa: [],
      checkedVisa: [],
      isCheckedVisaData: [],
      loading: true,
    };
  },
  validations() {
    return {
      rate_name: this.rate_name.map(() => {
        return {
          name: { required },
        };
      }),
      country_id: {
        id: { required },
      },
      fromDate: { required },
      toDate: { required },
      isCheckedVisaData: { required },
      checkedVisa: this.checkedVisa.map((ele) => {
        return {
          cost: { required },
          price: { required },
          number: { required },
          days: ele.days.map(() => {
            return {
              cost: { required },
              selling_price: { required },
            };
          }),
        };
      }),
    };
  },
  computed: {
    selectedDays() {
      const selectedDays = [];
      if (this.fromDate && this.toDate && this.number_of_nights > 0) {
        const fromDate = new Date(this.fromDate);
        for (let i = 0; i < this.number_of_nights; i++) {
          const currentDate = new Date(fromDate);
          currentDate.setDate(fromDate.getDate() + i);
          selectedDays.push({
            date: currentDate.toISOString().split("T")[0],
            dayOfWeek: this.getDayOfWeek(currentDate.getDay()),
          });
        }
      }
      return selectedDays;
    },
  },
  methods: {
    addToCheckedVisa() {
      this.checkedVisa = this.allVisa.filter((visa) => {
        return visa.checked == true;
      });
      this.checkedVisa.forEach((visa) => {
        visa.days = this.selectedDays.map((day) => {
          return {
            ...day,
            cost: "",
            selling_price: "",
            count: 1,
            date_activation: false,
          };
        });
      });
      this.isCheckedVisaData = this.checkedVisa;
    },
    getDayOfWeek(dayIndex) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[dayIndex];
    },
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    async fetchVisaFromContract() {
      let visa_contract = { visa_contract: this.contract_id };
      await axios
        .post(`/fetch_visa_from_contract`, visa_contract)
        .then(({ data }) => {
          this.allVisa = data.data.map((visa) => {
            return {
              ...visa,
              cost: "",
              price: "",
              number: "",
              checked: false,
            };
          });
          this.loading = false;
        });
      this.fetchRateData();
    },
    fetchRateData() {
      axios.get(`/visa_rate_code/${this.rate_id}`).then(({ data }) => {
        this.fromDate = data.data.from;
        this.toDate = data.data.to;
        this.rate_name = data.data.names.map((rate) => {
          return {
            lang: rate.locale,
            name: rate.name,
          };
        });
        this.country_id = data.data.country;
        this.fetchedRateVisa = data.data.Visa_card;

        this.fetchedRateVisa.forEach((fetchedVisa) => {
          this.allVisa.forEach((visa) => {
            if (visa.id == fetchedVisa["visa_type"]["id"]) {
              visa.checked = true;
              visa.cost = fetchedVisa.cost;
              visa.price = fetchedVisa.price;
              visa.number = fetchedVisa.count;
              visa.days = fetchedVisa.days.map((day) => {
                return {
                  date: day.date,
                  count: day.count,
                  cost: day.cost,
                  selling_price: day.selling_price,
                  date_activation: day.date_activation == 1 ? true : false,
                };
              });
            }
          });
        });
        this.checkedVisa = this.allVisa.filter((visa) => {
          return visa.checked == true;
        });
        this.isCheckedVisaData = this.checkedVisa;
      });
    },
    addRateCode() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let rate_data = {
          season_contract_id: this.season_id,
          country_id: this.country_id.id,
          from: moment(this.fromDate).format("YYYY-MM-DD"),
          to: moment(this.toDate).format("YYYY-MM-DD"),
        };
        this.rate_name.forEach((rate) => {
          rate_data["name_" + rate.lang] = rate.name;
        });
        rate_data.visa_types = this.checkedVisa.map((visa) => {
          return {
            visa_type_id: visa.id,
            number: visa.number,
            cost: visa.cost,
            price: visa.price,
            days: visa.days.map((day) => {
              return {
                date: day.date,
                cost: day.cost,
                selling_price: day.selling_price,
                date_activation: day.date_activation == true ? 1 : 0,
              };
            }),
          };
        });
        axios
          .put(`/visa_rate_code/${this.rate_id}`, rate_data)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push(
              `/visa/contract/${this.contract_id}/seasons/${this.season_id}/allRateCode`
            );
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    from_to_date_update() {
      const toDate = new Date(this.toDate);
      const fromDate = new Date(this.fromDate);
      var timeDiff = Math.abs(toDate.getTime() - fromDate.getTime());
      var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.number_of_nights = numberOfNights;

      this.checkedVisa.forEach((visa) => {
        let old_days = [...visa.days];
        console.log(old_days);
        visa.days = this.selectedDays.map((day) => {
          return {
            ...day,
            cost: "",
            selling_price: "",
            count: 1,
            date_activation: false,
          };
        });
        visa.days.forEach((new_day) => {
          old_days.forEach((old_day) => {
            if (new_day.date == old_day.date) {
              new_day.cost = old_day.cost;
              new_day.selling_price = old_day.selling_price;
              new_day.count = old_day.count;
              new_day.date_activation = old_day.date_activation;
            }
          });
        });
      });
    },
  },
  mounted() {
    this.fetch_country();
    this.fetchVisaFromContract();
  },
  watch: {
    fromDate() {
      this.from_to_date_update();
    },
    toDate() {
      if (this.toDate) {
        this.from_to_date_update();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $PrimaryColor;
}

input:focus + .slider {
  box-shadow: 0 0 1px $PrimaryColor;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .contract_details {
    margin-right: 30px;
    margin-left: 30px;
    color: #0b1427;
    font-size: 18px;
    span {
      color: #656b78;
    }
  }
}
.form-group {
  margin-bottom: 20px;
  label {
    font-weight: 700;
    font-family: "bold";
    font-size: 16px;
    color: #0b1427;
    margin-bottom: 10px;
  }
}
h5 {
  font-family: "bold";
  font-size: 20px;
  color: #0b1427;
}
.visaData {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .form-group {
    display: flex;
    align-items: center;
    margin-inline-start: 10px;
    label {
      font-size: 12px;
      margin: 0px 10px 0px 10px;
    }
    .form-control {
      width: 80px;
    }
  }
  .form-check-input:checked {
    background-color: $PrimaryColor;
    border-color: $PrimaryColor;
  }
  .form-check-label {
    font-family: "bold";
    margin: 0px 10px 0px 10px;
  }
}
.price {
  display: flex;
  align-items: center;
}
table {
  border-bottom: 1px solid #d0d2d7;
  width: auto;
  th,
  td {
    border-left: 1px solid #d0d2d7;
    min-width: 300px;
    width: 300px;
    &.yellow {
      background-color: #faf4ec !important;
    }
    &.blue {
      background-color: #ebf6f7 !important;
    }
  }
  th > div,
  td > div {
    display: inline-flex;
    width: 50%;
    text-align: center;
    justify-content: center;
    .form-control {
      width: 100px;
      background-color: transparent;
    }
  }
  .date {
    border: 1px solid $PrimaryColor;
    color: $PrimaryColor;
    padding: 7px 20px;
    text-align: center;
    width: fit-content;
    border-radius: 5px;
  }
  thead tr:first-child {
    th {
      border: 0;
      background-color: #fff;
    }
  }
  .column_title {
    display: flex;
    flex-direction: column;
    height: 100%;
    & > div {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
  .column_title {
    display: flex;
    flex-direction: column;
    height: 100%;
    & > div {
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
}
.accordion-item:first-of-type .accordion-button {
  font-size: 14px;
  color: #adadad;
  padding: 10px;
  justify-content: space-between;
  &::after {
    width: 30px;
    margin-left: 0;
    color: #adadad;
  }
}
.v-calendar {
  width: 100%;
}
.visaData .price {
  .form-group {
    margin-bottom: 0;
    .form-control {
      text-align: center;
      padding: 8px 3px !important;
    }
  }
}
</style>
