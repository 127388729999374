<template>
  <section class="card_style mt-0">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        <span v-if="blog_id">{{ $t("edit blog") }}</span>
        <span v-else>{{ $t("add blog") }}</span>
      </h4>
    </div>

    <form @submit.prevent="addBlog()">
      <div class="row">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-12">
            <div class="form-group">
              <label for="imageEntry" class="fw-bold fs-4">
                {{ $t("upload image") }}</label
              >
              <p>
                {{ $t("image size should be 400 * 300") }}
              </p>
              <input
                type="file"
                name="imageEntry"
                id="imageEntry"
                class="form-control w-50"
                @change="onFileChange"
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
              <span
                class="text-danger"
                v-if="v$.imageEntry.$error"
                v-text="v$.imageEntry.$errors[0].$message"
              ></span>
            </div>
            <img :src="imageEntry" class="blog_img w-25" />
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
          <label for="">{{ $t("blog_title") }}</label>
          <TabView>
            <TabPanel
              v-for="(title, index) in Form.title"
              :key="index"
              :header="$t('blog_title') + ' - ' + title.lang"
            >
              <div class="form-group">
                <input
                  :placeholder="$t('blog_title')"
                  class="form-control"
                  :id="`title${index}`"
                  v-model="title.name"
                />
              </div>
            </TabPanel>
          </TabView>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
          <label for="">{{ $t("blog_content") }}</label>
          <TabView>
            <TabPanel
              v-for="(desc, index) in Form.subtitle"
              :key="index"
              :header="$t('blog_content') + ' - ' + desc.lang"
            >
              <div class="form-group">
                <Editor
                  :placeholder="$t('blog_content')"
                  class="form-control"
                  :id="`desc${index}`"
                  v-model="desc.name"
                />
              </div>
            </TabPanel>
          </TabView>
        </div>
        <div class="col-lg-6 col-md-6 col-12" v-if="type === 'website'">
          <div class="form-group">
            <label for="">{{ $t("categotey-blogs") }}</label>
            <!-- {{ categoryBlogs }} -->
            <Multiselect
              v-model="categoryBlogs"
              :options="categoriesBlogs"
              :clear-on-select="true"
              :placeholder="$t('categotey-blogs')"
              label="title"
              track-by="id"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <!--            <span-->
            <!--              class="text-danger"-->
            <!--              v-if="v$.categoryBlogs.$error"-->
            <!--              v-text="v$.categoryBlogs.$errors[0].$message"-->
            <!--            ></span>-->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12" v-if="type === 'website'">
          <div class="form-group">
            <label for="">{{ $t("hastag-blogs") }}</label>
            <!-- {{ categoryBlogs }} -->
            <Multiselect
              v-model="hashTagBlogs"
              :options="hashtagsBlogs"
              :clear-on-select="true"
              :placeholder="$t('hastag-blogs')"
              label="title"
              track-by="id"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <!--            <span-->
            <!--              class="text-danger"-->
            <!--              v-if="v$.hashTagBlogs.$error"-->
            <!--              v-text="v$.hashTagBlogs.$errors[0].$message"-->
            <!--            ></span>-->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="alt"> {{ $t("alt_image") }}</label>
            <input
              type="text"
              name="alt"
              id="alt"
              class="form-control"
              v-model="alt"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="slug"> {{ $t("slug") }}</label>
            <input
              type="text"
              name="slug"
              id="alt"
              class="form-control"
              v-model="slug"
            />
            <span
              class="text-danger"
              v-if="v$.slug.$error"
              v-text="v$.slug.$errors[0].$message"
            ></span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="">{{ $t("meta_title") }}</label>
          <TabView>
            <TabPanel
              v-for="(meta_title, index) in Form.meta_title"
              :key="index"
              :header="$t('meta_title') + ' - ' + meta_title.lang"
            >
              <div class="form-group">
                <input
                  :placeholder="$t('meta_title')"
                  class="form-control"
                  :id="`meta_title${index}`"
                  v-model="meta_title.name"
                />
              </div>
            </TabPanel>
          </TabView>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("meta_describtion") }}</label>
            <TabView>
              <TabPanel
                v-for="(meta_description, index) in Form.meta_description"
                :key="index"
                :header="$t('meta_description') + ' - ' + meta_description.lang"
              >
                <div class="form-group">
                  <textarea
                    :placeholder="$t('meta_description')"
                    class="form-control"
                    :id="`meta_description${index}`"
                    v-model="meta_description.name"
                  ></textarea>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Editor from "primevue/editor";
export default {
  name: "blogsWebsiteAdd",
  data() {
    return {
      v$: useVuelidate(),
      alt: "",
      categoriesBlogs: [],
      categoryBlogs: "",
      hashtagsBlogs: [],
      hashTagBlogs: "",
      slug: "",
      languages: ["ar", "en", "id"],
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      Form: {
        title: [],
        subtitle: [],
        meta_description: [],
        meta_title: [],
      },
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),
      blog_id: undefined,
      type: this.$route.params.type,
    };
  },
  components: {
    Editor,
    Multiselect,
    AddFavDailog,
    TabView,
    TabPanel,
  },
  validations() {
    return {
      imageEntry: {
        required,
      },
      slug: {
        required,
      },
      // categoryBlogs: {
      //   required,
      // },
      // hashTagBlogs: {
      //   required,
      // },
      Form: {
        title: this.Form.title.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
        subtitle: this.Form.subtitle.map(() => {
          return {
            name: {
              required,
            },
          };
        }),
      },
    };
  },
  methods: {
    setBlogTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          lang: language,
          name: "",
        });
        this.Form.subtitle.push({
          lang: language,
          name: "",
        });
        this.Form.meta_description.push({
          lang: language,
          name: "",
        });
        this.Form.meta_title.push({
          lang: language,
          name: "",
        });
      });
    },
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    addBlog() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        if (this.$route.params.type === "portal") {
          formData.append("is_portal", 1);
        }
        this.Form.title.forEach((ele) => {
          formData.append(`title_${ele.lang}`, ele.name);
        });
        this.Form.subtitle.forEach((ele) => {
          formData.append(`content_${ele.lang}`, ele.name);
        });
        this.Form.meta_description.forEach((ele) => {
          formData.append(`meta_description_${ele.lang}`, ele.name);
        });
        this.Form.meta_title.forEach((ele) => {
          formData.append(`meta_title_${ele.lang}`, ele.name);
        });
        formData.append(`slug`, this.slug);
        formData.append("alt", this.alt);

        if (this.categoryBlogs) {
          formData.append("blog_category_id", this.categoryBlogs.id);
        }

        if (this.hashTagBlogs.length > 0) {
          this.hashTagBlogs.forEach((ele, index) => {
            // console.log(index);
            formData.append(`hashtag_blog_ids[${index}]`, ele.id);
          });
        }

        if (this.$route.params.id !== undefined) {
          if (this.fileData instanceof File) {
            formData.append("image", this.fileData);
            // console.log(this.fileData)
            // myVariable is a file
          } else {
            // myVariable is not a file
          }
          formData.append("blog_id", this.blog_id);

          axios
            .post("/website/blogs/update", formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push(`/blogs/${this.$route.params.type}`);
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.message,
                icon: "error",
              });
            });
        } else {
          if (this.fileData !== undefined) {
            formData.append("image", this.fileData);
          }

          axios
            .post(`/website/blogs`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push(
                `/blogs/${this.$route.params.type === "portal" ? "portal" : "website"}`,
              );
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error,
                icon: "error",
              });
            });
        }
      }
      console.log(this.v$);
    },
    fetchBlog() {
      if (this.$route.params.id !== undefined) {
        this.blog_id = this.$route.params.id;
        axios.get(`/fetch_blog/${this.blog_id}`).then(({ data }) => {
          this.Form.title = data.data.titles.map((ele) => {
            return { lang: ele.locale, name: ele.title };
          });
          this.Form.subtitle = data.data.contents.map((ele) => {
            return { lang: ele.locale, name: ele.content };
          });
          this.Form.meta_description = data.data.meta_descriptions.map(
            (ele) => {
              return { lang: ele.locale, name: ele.meta_description };
            },
          );
          this.Form.meta_title = data.data.meta_titles.map((ele) => {
            return { lang: ele.locale, name: ele.meta_title };
          });
          this.slug = data.data.slug;
          this.alt = data.data.alt;
          this.imageEntry = data.data.image;
          this.categoryBlogs = data.data.blog_category;
          this.hashTagBlogs = data.data.hashtag_blogs;
        });
      }
    },
    fetch_categories() {
      axios.post("/index_blog_categories").then(({ data }) => {
        this.categoriesBlogs = data.data.data;
        // console.log(data.data.data);
      });
    },
    hashtag_categories() {
      axios.post(`/index_hashtag_blogs`).then(({ data }) => {
        this.hashtagsBlogs = data.data.data;
        // console.log(this.hashtagsBlogs);
      });
    },
  },
  created() {
    this.setBlogTitle();
    this.fetchBlog();
    this.fetch_categories();
    this.hashtag_categories();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.blog_img {
  max-height: 300px;
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
