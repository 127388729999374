<template>
  <button
    class="btn btn-primary text-white mx-1"
    type="button"
    @click="visibleAddtional = true"
    v-tooltip.top="$t('addtional_contract_price')"
  >
    <i class="fa-solid fa-money-bill-trend-up"></i>
  </button>
  <Dialog
    v-model:visible="visibleAddtional"
    modal
    :dismissableMask="true"
    :header="$t('addtional_contract_price')"
    :style="{ width: '30vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <form @submit.prevent="editContract">
      <p>{{ $t("price_contract") }}: {{ total + exrt + addtionalTotal }}</p>
      <div class="form-group">
        <label for="">{{ $t("amount") }}</label>
        <input
          type="number"
          class="form-control"
          v-model="addtionalTotal"
          :placeholder="$t('amount')"
        />
      </div>
      <button class="PrimaryButton btn">{{ $t("save") }}</button>
    </form>
  </Dialog>
</template>
<script>
import Dialog from "primevue/dialog";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: ["contract_id", "total", "exrt", "contracst"],
  data() {
    return {
      visibleAddtional: false,
      addtionalTotal: 0,
    };
  },
  components: {
    Dialog,
  },
  methods: {
    async editContract() {
      const formData = new FormData();
      formData.append("extra_price", this.addtionalTotal);
      formData.append("contract_id", this.contract_id);
      axios
        .post(`/store_contract_extra_price`, formData)
        .then(({ data }) => {
          this.visibleAddtional = false;
          //   this.fetchData();
          this.contracst();
          this.addtionalTotal = 0;
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          this.visibleAddtional = false;
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    // fetchData() {
    //   axios.post(`/fetch_contract_extra_price`).then((response) => {
    //     response.data.data.data.forEach((element) => {
    //       console.log(element.extra_price);
    //       this.addtionalTotal = element.extra_price;
    //     });
    //     console.log(this.addtionalTotal, "ooo");
    //   });
    // },
  },
  mounted() {
    // this.fetchData();
  },
};
</script>
