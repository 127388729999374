import axios from "axios";
export default {
  async Login({ commit }, form) {
    try {
      let response = await axios.post("/login", form);
      localStorage.setItem("token", response?.data?.data?.api_token);
      const UserResource = {
        name: response?.data?.data?.name,
        phone: response?.data?.data?.phone,
        email_company: response?.data?.data?.organization?.email,
        logo: response?.data?.data?.organization?.image,
        default_image: response?.data?.data?.organization?.default_image,
        website_link: response?.data?.data?.organization?.website_link,
        email: response?.data?.data?.email,
        salary: response?.data?.data?.salary,
        status: response?.data?.data?.status,
        stage: response?.data?.data?.organization?.stage,
        services: response?.data?.data?.services?.map(
          (service) => service?.service_name
        ),
        apps: response?.data?.data?.apps?.map((app) => app?.app_name),
        is_master: response?.data?.data?.is_master,
        PrimaryColor: response?.data?.data?.organization?.primary_color,
        SecondColor: response?.data?.data?.organization?.secondary_color,
        organization_name: response?.data?.data?.organization?.name,
        invoice_theme: response?.data?.data?.organization?.invoice_theme?.code,
        currency: response?.data?.data?.currency,
        is_portal_contract:
          response?.data?.data?.organization?.is_portal_contract,

        portal_email_receiver:
          response?.data?.data?.organization?.portal_email_receiver,
        portal_email_sender:
          response?.data?.data?.organization?.portal_email_sender,
        portal_email_sender_password:
          response?.data?.data?.organization?.portal_email_sender_password,
      };

      localStorage.setItem("user", JSON.stringify(UserResource));
      commit("setUser", { user: response });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  logout({ commit }) {
    commit("logout");
  },
};
