<template>
  <div class="container">
    <div class="row">
      <!-- اختر شركه الطيران -->
      <div class="col-lg-4 col-md-4 col-12">
        <div class="form-group">
          <label class="typo__label my-2">{{ $t("flight companies") }}</label>
          <input
            type="search"
            class="form-control"
            :placeholder="$t('flight companies')"
            v-model="companyData"
            @keyup="searchCompany"
          />

          <div
            class="resultSearch"
            v-if="companyData.length > 2 && companies.length"
          >
            <h6 class="head_search">{{ $t("flight companies") }}</h6>
            <ul class="hotels">
              <li
                v-for="(stop, index) in companies"
                :key="index"
                @click="
                  sendCompany(stop.name == null ? '' : stop.name, stop.id)
                "
              >
                <div class="hotel_image">
                  {{ stop.code == null ? "" : stop.code }}
                </div>
                <div class="texts">
                  <p class="title">
                    {{ stop.name == null ? "" : stop.name }}
                  </p>
                  <p class="country">
                    {{ stop.country == null ? "" : stop.country }} -
                    {{ stop.city == null ? "" : stop.city }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- اختر شركه الطيران -->
    </div>
    <div
      class="row"
      v-for="(airport, airportIndex) in allAirports"
      :key="airportIndex"
    >
      <div class="col-lg-4 col-md-4 col-12">
        <div class="form-group">
          <label class="typo__label my-2">{{ $t("Take-off airport") }} </label>
          <input
            type="search"
            class="form-control"
            :placeholder="$t('Take-off airport')"
            v-model="airport.lunchTitle"
            @keyup="searchLunchAirport($event, airportIndex)"
          />

          <div
            class="resultSearch"
            v-if="airport.lunchTitle.length > 2 && airport.lunchArr.length"
          >
            <h6 class="head_search">{{ $t("Take-off airport") }}</h6>
            <ul class="hotels">
              <li
                v-for="(stop, index) in airport.lunchArr"
                :key="index"
                @click="
                  sendStopName(
                    stop.title == null ? '' : stop.title,
                    stop.id,
                    airportIndex
                  )
                "
              >
                <div class="hotel_image">
                  {{ stop.code == null ? "" : stop.code }}
                </div>
                <div class="texts">
                  <p class="title">
                    {{ stop.title == null ? "" : stop.title }}
                  </p>
                  <p class="country">
                    {{ stop.country == null ? "" : stop.country }} -
                    {{ stop.city_title == null ? "" : stop.city_title }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-12">
        <div class="form-group">
          <label class="typo__label my-2">{{ $t("Arrival airport") }}</label>
          <input
            type="search"
            class="form-control"
            :placeholder="$t('Arrival airport')"
            v-model="airport.returnTitle"
            @keyup="searchReturnAirport($event, airportIndex)"
          />

          <div
            class="resultSearch"
            v-if="airport.returnTitle.length > 2 && airport.returnArr.length"
          >
            <h6 class="head_search">{{ $t("Arrival airport") }}</h6>
            <ul class="hotels">
              <li
                v-for="(stop, index) in airport.returnArr"
                :key="index"
                @click="
                  sendreturnName(
                    stop.title == null ? '' : stop.title,
                    stop.id,
                    airportIndex
                  )
                "
              >
                <div class="hotel_image">
                  {{ stop.code == null ? "" : stop.code }}
                </div>
                <div class="texts">
                  <p class="title">
                    {{ stop.title == null ? "" : stop.title }}
                  </p>
                  <p class="country">
                    {{ stop.country == null ? "" : stop.country }} -
                    {{ stop.city_title == null ? "" : stop.city_title }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center col-lg-4 col-md-4 col-12">
        <button class="add-btn" @click="add_airport" v-if="airportIndex == 0">
          <i class="fa-solid fa-plus"></i>
        </button>
        <button class="remove-btn" @click="remove_airport(airportIndex)" v-else>
          <i class="fa-solid fa-minus"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-12 mt-2">
        <button @click="add_new_airport" class="sumbit-btn mx-1">Sumbit</button>
      </div>
    </div>
  </div>
</template>

<script>
// import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "flightCompanies",
  components: {
    // Multiselect,
  },
  data() {
    return {
      params_company_id: this.$route.params.id,
      companies: [],
      companyData: "",
      companyId: "",
      minSearc: 1,
      airports: [],
      launchAirport: {},
      depAirport: {},
      show: false,

      tableRows: [],

      allAirports: [
        {
          lunchTitle: "",
          lunchId: "",
          lunchArr: [],
          returnTitle: "",
          returnId: "",
          returnArr: [],
        },
      ],
    };
  },
  methods: {
    async fetchCompanyRoutes() {
      await axios
        .get(`/flight_company_routes/${this.params_company_id}`)
        .then(({ data }) => {
          console.log(data.data);
          this.companyData = data.data.name;
          this.companyId = data.data.id;

          this.allAirports = data.data.routes.map((ele) => {
            return {
              lunchId: ele.from_airport.id,
              lunchTitle: ele.from_airport.title,
              returnId: ele.to_airport.id,
              returnTitle: ele.to_airport.title,
              lunchArr: [],
              returnArr: [],
            };
          });

          // console.log(this.allAirports);
        });
    },
    //  Start flight companies
    fetchFlightCompany(event) {
      const search = event.target.value;
      axios.get(`/flight_company/search?search=${search}`).then(({ data }) => {
        if (data.data) {
          this.companies = data.data;
        }
      });
    },

    searchLunchAirport(event, index) {
      const searchText = { title: event.target.value };
      axios
        .post(`/search_airports`, searchText)
        .then(({ data }) => {
          this.allAirports[index].lunchArr = data.data;
        })
        .catch((err) => console.log(err));
    },

    searchReturnAirport(event, index) {
      const searchText = { title: event.target.value };
      axios
        .post(`/search_airports`, searchText)
        .then(({ data }) => {
          this.allAirports[index].returnArr = data.data;
        })
        .catch((err) => console.log(err));
    },

    sendStopName(stopTitle, stop_id, airportIndex) {
      this.allAirports[airportIndex].lunchId = stop_id;
      this.allAirports[airportIndex].lunchTitle = stopTitle;
      this.allAirports[airportIndex].lunchArr = [];
    },

    sendreturnName(stopTitle, stop_id, airportIndex) {
      this.allAirports[airportIndex].returnId = stop_id;
      this.allAirports[airportIndex].returnTitle = stopTitle;
      this.allAirports[airportIndex].returnArr = [];
    },

    searchCompany() {
      const search = event.target.value;
      axios.get(`/flight_company/search?search=${search}`).then(({ data }) => {
        // console.log(data.data)
        if (data.data) {
          this.companies = data.data;
          console.log(this.companies);
        }
      });
    },

    sendCompany(stopTitle, stop_id) {
      this.companyData = stopTitle;
      this.companyId = stop_id;
      this.companies = [];
    },
    //  End flight companies

    //  Start flight companies
    checkAirports(event) {
      if (event.target.value.length > this.minSearc) {
        this.fetchAirports(event.target.value);
      }
    },
    fetchAirports(title_airport) {
      const title = { title: title_airport };
      axios.post(`/search_airports`, title).then((res) => {
        this.airports = res.data.data;
        console.log(this.arivalAirports);
      });
    },

    //  End flight companies

    add_airport() {
      this.allAirports.push({
        lunchTitle: "",
        lunchId: "",
        lunchArr: [],
        returnTitle: "",
        returnId: "",
        returnArr: [],
      });
    },

    remove_airport(index) {
      this.allAirports.splice(index, 1);
    },
    add_new_airport() {
      let airportObj = {
        flight_company_id: this.companyId,
        routes: [],
      };
      airportObj.routes = this.allAirports.map((item) => {
        return {
          from_airport_id: item.lunchId == null ? null : item.lunchId,
          to_airport_id: item.returnId == null ? null : item.returnId,
        };
      });

      console.log(airportObj);

      axios
        .patch(`/flight_company_routes/${this.params_company_id}`, airportObj)
        .then(() => {
          Swal.fire({
            title: "success",
            text: "Success Added",
            icon: "success",
          });
          this.$router.push(`/all-flight-companies`);
        })
        .catch(() => {
          Swal.fire({
            title: "error",
            text: "something went wrong",
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetchCompanyRoutes();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.fa-plus {
  padding: 0.8rem;
  background-color: #0a909b;
  border-radius: 1rem;
  color: #fff;
}

.add-btn,
.remove-btn,
.sumbit-btn {
  background-color: transparent;
  border: none;
  outline: none;
  margin-top: auto;
}
.remove-btn {
  background-color: rgb(199, 34, 34);
  color: #fff;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
}
.sumbit-btn {
  background-color: #0a909b;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.form-group {
  position: relative;
  .resultSearch {
    position: absolute;
    top: 78px;
    background-color: #fff;
    height: 300px;
    width: 100%;
    overflow-y: auto;
    z-index: 999;
    .head_search {
      font-family: "bold";
      background-color: $PrimaryColor;
      color: #fff;
      padding: 0.5rem;
      margin-bottom: 0;
    }
    .hotels,
    .cities {
      margin-bottom: 0;
      li {
        display: flex;
        align-items: center;
        font-family: "regular";
        font-size: 0.8rem;
        padding: 0.5rem;
        border-bottom: 1px solid #292d32;
        transition: 0.7s ease-in-out;
        cursor: pointer;
        &:last-child {
          border-bottom: unset;
        }
        .hotel_image {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-inline-end: 0.5rem;
        }
        i {
          font-size: 1.5rem;
          color: #b5b5b5;
          margin-inline-end: 0.5rem;
        }
        .title,
        .country {
          margin-bottom: 0;
        }
        .title {
          font-size: 0.8rem;
        }
        .country {
          font-size: 0.7rem;
          color: #292d32;
        }
        &:hover {
          transition: 0.7s ease-in-out;
          background-color: rgba($color: $PrimaryColor, $alpha: 0.1);
        }
      }
    }
  }
  .resultSearch {
    background-color: white;
    padding: 2%;
    z-index: 999;
    li {
      cursor: pointer;
    }
  }
}
</style>
