<template>
  <div class="">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center py-3">
        <addFavDailogVue v-tooltip.left="$t('add_to_favorites')" />
        <h4 v-if="$route.params.type === 'exchange'" class="px-2">
          {{ $t("show_exchange") }}
        </h4>
        <h4 v-else-if="$route.params.type === 'receipt'" class="px-2">
          {{ $t("show_receipt") }}
        </h4>
      </div>

      <router-link
        v-if="$route.params.type === 'exchange'"
        to="/add/exchange"
        class="PrimaryButton btn"
        style="margin-top: 0 !important"
        ><i class="fa-solid fa-plus"></i> {{ $t("add_exchange_paper") }}
      </router-link>
      <router-link
        v-else-if="$route.params.type === 'receipt'"
        to="/add/receipt"
        class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add_receipt_paper") }}
      </router-link>

      <button
        class="SecondButton btn action d-flex align-items-center mx-2"
        @click="exportToExcel"
      >
        <i class="fa-solid fa-file-excel"></i>
        {{ $t("export_to_excel") }}
      </button>
    </div>
    <div class="">
      <TabView v-if="$route.params.type === 'receipt'">
        <TabPanel
          :header="$t('current')"
          :tab-selected="indexShow(this.$route.params.type, 0)"
        >
          <p v-if="receiptsActive.length === 0">{{ $t("no_show_receipt") }}</p>
          <div class="row" v-else>
            <div class="table-responsive">
              <table class="table safe" id="safeTable">
                <thead>
                  <tr>
                    <th>{{ $t("id") }}</th>
                    <th>{{ $t("organization_account") }}</th>
                    <!-- <th>{{ $t("Branch") }}</th> -->
                    <th>{{ $t("consumer") }}</th>
                    <th>{{ $t("check_number") }}</th>
                    <th>{{ $t("due_date") }}</th>
                    <th>{{ $t("value") }}</th>
                    <th>{{ $t("status") }}</th>
                    <th>{{ $t("action") }}</th>
                  </tr>
                </thead>
                <tbody v-if="loading" class="text-center">
                  <tr v-for="reciept in 10" :key="reciept">
                    <td v-for="reciept in 8" :key="reciept">
                      <Skeleton
                        height="2rem"
                        width="90%"
                        class="mb-2"
                      ></Skeleton>
                    </td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <tr v-for="(receipt, index) in receiptsActive" :key="index">
                    <td>{{ receipt.id }}</td>
                    <td>{{ receipt.organization_account?.title }}</td>
                    <td>{{ receipt.consumer?.name }}</td>
                    <td>{{ receipt.check_number }}</td>
                    <td>{{ receipt.due_date }}</td>
                    <td>{{ receipt.value }}</td>
                    <td>
                      <span
                        class="coupon_status"
                        :class="{
                          active: receipt.status === 0,
                          disactive:
                            receipt.status === 1 || receipt.status === 2,
                        }"
                      >
                        {{ receipt.status ? $t("expired") : $t("current") }}
                      </span>
                    </td>
                    <td class="d-flex align-items-center">
                      <acceptReceipt :id="receipt.id" />
                      <!-- <button
                        @click="actionReceipt('accept_check', receipt.id)"
                        class="btn btn-success mx-1 w-auto"
                      >
                        <i class="fa-solid fa-check"></i>
                      </button> -->
                      <button
                        @click="actionReceipt('reject_check', receipt.id)"
                        class="btn btn-danger mx-1 w-auto"
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
        <TabPanel
          :header="$t('expired')"
          :tab-selected="indexShow(this.$route.params.type, 1)"
        >
          <p v-if="receiptsExpired.length === 0">{{ $t("no_show_receipt") }}</p>
          <div class="row" v-else>
            <div class="table-responsive">
              <table class="table safe" id="safeTable">
                <thead>
                  <tr>
                    <th>{{ $t("id") }}</th>
                    <th>{{ $t("organization_account") }}</th>
                    <!-- <th>{{ $t("Branch") }}</th> -->
                    <th>{{ $t("consumer") }}</th>
                    <th>{{ $t("check_number") }}</th>
                    <th>{{ $t("due_date") }}</th>
                    <th>{{ $t("value") }}</th>
                    <th>{{ $t("status") }}</th>
                  </tr>
                </thead>
                <tbody v-if="loading" class="text-center">
                  <tr v-for="receipt in 10" :key="receipt">
                    <td v-for="receipt in 8" :key="receipt">
                      <Skeleton
                        height="2rem"
                        width="90%"
                        class="mb-2"
                      ></Skeleton>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(receipt, index) in receiptsExpired" :key="index">
                    <td>{{ receipt.id }}</td>
                    <td>{{ receipt.organization_account?.title }}</td>
                    <td>{{ receipt.consumer?.name }}</td>
                    <td>{{ receipt.check_number }}</td>
                    <td>{{ receipt.due_date }}</td>
                    <td>{{ receipt.value }}</td>
                    <td>
                      <span
                        class="coupon_status"
                        :class="{
                          active: receipt.status === 0,
                          disactive:
                            receipt.status === 1 || receipt.status === 2,
                        }"
                      >
                        {{ receipt.status ? $t("expired") : $t("current") }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div></TabPanel
        >
      </TabView>
      <TabView v-else-if="$route.params.type === 'exchange'">
        <TabPanel
          :header="$t('current')"
          :tab-selected="indexShow(this.$route.params.type, 0)"
        >
          <p v-if="exchangesActive.length === 0">
            {{ $t("no_show_exchange") }}
          </p>
          <div class="row" v-else>
            <div class="table-responsive">
              <table class="table safe" id="safeTable">
                <thead>
                  <tr>
                    <th>{{ $t("id") }}</th>
                    <th>{{ $t("organization_account") }}</th>
                    <!-- <th>{{ $t("Branch") }}</th> -->
                    <th>{{ $t("consumer") }}</th>
                    <th>{{ $t("check_number") }}</th>
                    <th>{{ $t("due_date") }}</th>
                    <th>{{ $t("value") }}</th>
                    <th>{{ $t("status") }}</th>
                    <th>{{ $t("action") }}</th>
                  </tr>
                </thead>
                <tbody v-if="loading" class="text-center">
                  <tr v-for="reciept in 10" :key="reciept">
                    <td v-for="reciept in 8" :key="reciept">
                      <Skeleton
                        height="2rem"
                        width="90%"
                        class="mb-2"
                      ></Skeleton>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(exchange, index) in exchangesActive" :key="index">
                    <td>{{ exchange.id }}</td>
                    <td>{{ exchange.organization_account.title }}</td>
                    <td>{{ exchange.consumer?.name }}</td>
                    <td>{{ exchange.check_number }}</td>
                    <td>{{ exchange.due_date }}</td>
                    <td>{{ exchange.value }}</td>
                    <td>
                      <span
                        class="coupon_status"
                        :class="{
                          active: exchange.status === 0,
                          disactive:
                            exchange.status === 1 || exchange.status === 2,
                        }"
                      >
                        {{ exchange.status ? $t("expired") : $t("current") }}
                      </span>
                    </td>
                    <td class="d-flex align-items-center">
                      <button
                        @click="actionReceipt('accept_check', exchange.id)"
                        class="btn btn-success mx-1 w-auto"
                      >
                        <i class="fa-solid fa-check"></i>
                      </button>
                      <button
                        @click="actionReceipt('reject_check', exchange.id)"
                        class="btn btn-danger mx-1 w-auto"
                      >
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
        <TabPanel
          :header="$t('expired')"
          :tab-selected="indexShow(this.$route.params.type, 1)"
        >
          <p v-if="exchangesExpired.length === 0">
            {{ $t("no_show_exchange") }}
          </p>
          <div class="row" v-else>
            <div class="table-responsive">
              <table class="table safe" id="safeTable">
                <thead>
                  <tr>
                    <th>{{ $t("id") }}</th>
                    <th>{{ $t("organization_account") }}</th>
                    <!-- <th>{{ $t("Branch") }}</th> -->
                    <th>{{ $t("consumer") }}</th>
                    <th>{{ $t("check_number") }}</th>
                    <th>{{ $t("due_date") }}</th>
                    <th>{{ $t("value") }}</th>
                    <th>{{ $t("status") }}</th>
                  </tr>
                </thead>

                <tbody v-if="loading" class="text-center">
                  <tr v-for="exchange in 10" :key="exchange">
                    <td v-for="exchange in 9" :key="exchange">
                      <Skeleton
                        height="2rem"
                        width="90%"
                        class="mb-2"
                      ></Skeleton>
                    </td>
                  </tr>
                </tbody>

                <tbody v-else>
                  <tr
                    v-for="(exchange, index) in exchangesExpired"
                    :key="index"
                  >
                    <td>{{ exchange.id }}</td>
                    <td>{{ exchange.organization_account.title }}</td>
                    <td>{{ exchange.consumer.title }}</td>
                    <td>{{ exchange.check_number }}</td>
                    <td>{{ exchange.due_date }}</td>
                    <td>{{ exchange.value }}</td>
                    <td>
                      <span
                        class="coupon_status"
                        :class="{
                          active: exchange.status === 0,
                          disactive:
                            exchange.status === 1 || exchange.status === 2,
                        }"
                      >
                        {{ exchange.status ? $t("expired") : $t("current") }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>

<script>
import addFavDailogVue from "@/components/layout/addFavDailog.vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import axios from "axios";
import Swal from "sweetalert2";
import * as XLSX from "xlsx/xlsx.mjs";
import acceptReceipt from "./acceptReceipt.vue";
import Skeleton from "primevue/skeleton";
export default {
  name: "showExchangeReceipt",
  components: {
    addFavDailogVue,
    TabView,
    TabPanel,
    acceptReceipt,
    Skeleton,
  },
  data() {
    return {
      exchangesActive: [],
      exchangesExpired: [],
      receiptsActive: [],
      receiptsExpired: [],
      exchanges: [],
      receipts: [],
      loading: true,
    };
  },
  methods: {
    async exportToExcel() {
      let responseData;

      if (this.$route.params.type === "exchange") {
        const response = await axios.post("/index_check", { type: 2 });
        responseData = response.data.data.data;
      } else if (this.$route.params.type === "receipt") {
        const response = await axios.post("/index_check", { type: 1 });
        responseData = response.data.data.data;
      }

      // Extract headers from the first item in responseData
      const headers = Object.keys(responseData[0]);

      // Create tableData array
      const tableData = [
        // Header row
        headers.map((header) => this.$t(header)),
        // Data rows
        ...responseData.map((item) => headers.map((header) => item[header])),
      ];

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(tableData);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, "data_export.xlsx");
    },

    indexShow(type, status) {
      if (type === "exchange") {
        axios
          .post("/index_check", {
            type: 2,
            status: status == 0 ? [0] : [1, 2],
            per_page: 10,
          })
          .then(({ data }) => {
            if (status === 0) {
              this.exchangesActive = data.data.data;
              this.loading = false;
            } else {
              this.exchangesExpired = data.data.data;
              this.loading = false;
            }
          });
      } else if (type === "receipt") {
        axios
          .post("/index_check", {
            type: 1,
            status: status == 0 ? [0] : [1, 2],
            per_page: 10,
          })
          .then(({ data }) => {
            if (status === 0) {
              this.receiptsActive = data.data.data;
              this.loading = false;
            } else {
              this.receiptsExpired = data.data.data;
              this.loading = false;
            }
          });
      }
    },
    actionReceipt(endpoint, id) {
      axios
        .post(`/${endpoint}`, { check_id: id })
        .then(({ data }) => {
          Swal.fire({
            title: data.message,
            icon: "success",
            confirmButtonText: this.$t("ok"),
          });
          this.indexShow(this.$route.params.type, 0);
          this.indexShow(this.$route.params.type, 1);
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.message,
            icon: "error",
            confirmButtonText: this.$t("ok"),
          });
        });
    },
  },
  mounted() {
    // this.indexShow(this.$route.params.type);
  },
};
</script>

<style lang="scss" scoped>
.coupon_status {
  display: block;
  padding: 10px;
  border-radius: 10px;
  width: 85px;
  text-align: center;

  &.active {
    background-color: #00800026;
  }

  &.disactive {
    background-color: #ff000024;
  }
}
.PrimaryButton {
  margin-top: 0 !important;
}
</style>
