<template>
  <div class="container mt-3">
    <skeltonNatinalityPrice v-if="loading" />
    <div class="row" v-else>
      <h4 class="my-3" v-if="$route.params.nationality_id">
        {{ $t("edit_nationality_packages") }}
      </h4>
      <h4 class="my-3" v-else>{{ $t("add_nationality_packages") }}</h4>
      <div class="col-lg-3">
        <div class="d-flex align-items-center justify-content-between">
          <h4>{{ $t("nationality") }}</h4>
          <div class="d-flex align-items-center">
            <label class="mx-2">{{$t("Available")}}</label>
            <InputSwitch v-model="checkedNationality" @change="FetchRooms" />
          </div>
        </div>
        <div class="title d-flex align-items-center">
          <div class="form-group w-100">
            <Multiselect
              v-model="nationalityValue_last_reservation"
              :options="nationalities"
              :placeholder="$t('choose_nationality')"
              label="title"
              track-by="id"
              :clear-on-select="true"
              select-label=""
              :hide-selected="true"
              @keyup="searchNationality"
            >
            </Multiselect>
          </div>
        </div>
      </div>

      <div class="col-lg-9">
        <div class="rooms" v-if="checkedNationality">
          <div v-for="room in rooms" :key="room.id">
            <div class="natilality_adult_child">
              <div class="col-lg-3">
                <div class="d-flex align-items-center justify-content-between">
                  <h6>{{ $t("room name") }}</h6>
                  <InputSwitch v-model="room.is_available" />
                </div>
                <!-- <label for="" class="not_visiable"></label> -->
                <div class="room_name form-control">
                  {{
                    room?.room?.title ? room?.room?.title : room?.title
                  }}
                </div>
              </div>
              <div class="col-lg-9">
                <div class="adult_child">
                  <div class="adult w-50">
                    <h4>{{ $t("adults") }}</h4>
                    <div class="second_field">
                      <div class="d-flex flex-column">
                        <div class="form-group">
                          <label for="">{{$t('cost')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('cost')"
                            v-model="room.adult_cost"
                            disabled
                          />
                        </div>
                        <div class="form-group">
                          <label for="">{{$t('cost')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('cost')"
                            v-model="room.extra_adult_cost"
                          />
                        </div>
                        <div class="form-group">
                          <label for="">{{$t('cost')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('cost')"
                            :value="room.adult_cost + room.extra_adult_cost"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="d-flex flex-column">
                        <div class="form-group">
                          <label for="">{{$t('selling price')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('selling price')"
                            v-model="room.adult_selling"
                            disabled
                          />
                        </div>
                        <div class="form-group">
                          <label for="">{{$t('selling price')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('selling price')"
                            v-model="room.extra_adult_selling"
                          />
                        </div>
                        <div class="form-group">
                          <label for="">{{$t('selling price')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('selling price')"
                            :value="room.adult_selling + room.extra_adult_selling"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="child w-50">
                    <h4>{{ $t("childrens") }}</h4>
                    <div class="second_field">
                      <div class="d-flex flex-column">
                        <div class="form-group">
                          <label for="">{{$t('cost')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('cost')"
                            v-model="room.child_cost"
                            disabled
                          />
                        </div>
                        <div class="form-group">
                          <label for="">{{$t('cost')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('cost')"
                            v-model="room.extra_child_cost"
                          />
                        </div>
                        <div class="form-group">
                          <label for="">{{$t('cost')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('cost')"
                            :value="room.child_cost + room.extra_child_cost"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="d-flex flex-column">
                        <div class="form-group">
                          <label for="">{{$t('selling price')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('selling price')"
                            v-model="room.child_selling"
                            disabled
                          />
                        </div>
                        <div class="form-group">
                          <label for="">{{$t('selling price')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('selling price')"
                            v-model="room.extra_child_selling"
                          />
                        </div>
                        <div class="form-group">
                          <label for="">{{$t('selling price')}}</label>
                          <input
                            type="number"
                            class="form-control"
                            :placeholder="$t('selling price')"
                            :value="room.child_selling + room.extra_child_selling"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-primary" @click="set_package_nationalities">
            {{ $t("save") }}
          </button>
          <button
            v-if="!$route.params.nationality_id"
            class="btn btn-success mx-1"
            type="button"
            @click="add_anthor_package"
          >
            {{ $t("add_new_nationality") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import skeltonNatinalityPrice from "@/components/UmrahPackage/skeltonNatinalityPrice.vue";
import InputSwitch from 'primevue/inputswitch';

export default {
  name: "nationalityPacakgecost",
  data() {
    return {
      nationalityValue_last_reservation: "",
      nationalities: [],
      rooms: [
        {
          room: {
            // title: "",
            adult_cost: 0,
            child_cost: 0,
            adult_selling: 0,
            child_selling: 0,
            is_available: true,
          },
        },
      ],
      adult_cost_view: 0,
      child_cost_view: 0,
      adult_selling_view: 0,
      child_selling_view: 0,
      loading: true,
      checkedNationality: true,
    };
  },
  methods: {
    searchNationality(event) {
      const searchText = {
        word: event == undefined ? "" : event.target.value,
      };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => {
          // console.log(res.data.data.data, "searchNationality");
          this.nationalities = res.data.data.data;
        })
        .catch((err) => console.log(err));
    },
    FetchRooms() {
      axios.post("/fetch_room_prices_by_package_id", { package_id: this.$route.params.id }).then(({ data }) => {
        this.rooms = data.data.map((room) => {
          return {
            ...room,
            is_available: true
          };
        });
        this.loading = false;
        // console.log(this.rooms)
      });
    },
    set_package_nationalities() {
      // console.log(this.nationalityValue_last_reservation)
      let data = {
        country_id: this.nationalityValue_last_reservation?.id,
        is_free: 0,
        is_available: this.checkedNationality ? 1 : 0,
      };
      if (!this.$route.params.nationality_id) {
        const rooms = this.rooms.map((room) => {
          return {
            room_id: room.room_id,
            adult_cost: room.extra_adult_cost ?? 0,
            adult_selling: room.extra_adult_selling ?? 0,
            child_cost: room.extra_child_cost ?? 0,
            child_selling: room.extra_child_selling ?? 0,
            is_available: room?.is_available == true ? 1 : 0
          };
        });
        if(this.nationalityValue_last_reservation) {
          axios
            .post("/store_package_nationalities", {
              package_id: this.$route.params.id,
              ...data,
              rooms: rooms,
            })
            .then((res) => {
              Swal.fire({
                title: "",
                text: res.data.message,
                icon: "success",
                confirmButtonText: this.$t("ok"),
              });
              this.$router.push(`/package_nationality/${this.$route.params.id}`);
            }).catch((err) => {
              Swal.fire({
                icon: "error",
                title: err.response.data.message,
              })
            })
        }
        else {
          Swal.fire({
            icon: "warning",
            title: this.$t("please_select_country"),
          })
        }
      } else {
        const rooms = this.rooms.map((room) => {
          return {
            room_id: room.room_id == undefined ? room?.room?.id : room.room_id,
            adult_cost: room?.extra_adult_cost ?? 0,
            adult_selling: room?.extra_adult_selling ?? 0,
            child_cost: room?.extra_child_cost ?? 0,
            child_selling: room?.extra_child_selling ?? 0,
            is_available: room?.is_available == true ? 1 : 0
          };
        });
        axios
          .put(`/update_package_nationalities`, {
            package_id: this.$route.params.id,
            package_nationality_id: this.$route.params.nationality_id,
            ...data,
            rooms: this.checkedNationality ? rooms : null,
          })
          .then((res) => {
            Swal.fire({
              title: "",
              text: res.data.message,
              icon: "success",
              confirmButtonText: this.$t("ok"),
            });
            this.$router.push(`/package_nationality/${this.$route.params.id}`);
          });
      }
    },

    getRoomsData() {
      return this.rooms.map((room) => ({
        room_id: room.id,
        adult_cost: room.adult_cost,
        adult_selling: room.adult_selling,
        child_cost: room.child_cost,
        child_selling: room.child_selling,
      }));
    },

    add_anthor_package() {
      window.location.reload();
    },
    fetch_rooms_by_nationalities() {
      axios
        .post(`/fetch_rooms_by_nationalities`, {
          package_nationality_id: this.$route.params.nationality_id,
        })
        .then(({ data }) => {
          if(data.status) {
            // this.rooms = data.data.nationality_rooms;
            this.rooms = data.data.nationality_rooms.map((room) => {
            return {
              ...room,
              is_available: room?.is_available == 1 ? true : false
            };
          });
            this.nationalityValue_last_reservation = data.data.nationality;
          }
          this.loading = false;
        });
    },

    checkPackageNationalityIsAvailable() {
      axios
        .post(`/checkPackageNationalityIsAvailable`, {
          package_nationality_id: this.$route.params.nationality_id,
        })
        .then(({ data }) => {
          this.checkedNationality = data.data.is_available;
          this.nationalityValue_last_reservation = data.data.nationality;
          this.loading = false;
          // console.log(this.checkedNationality);
        });
    },
  },
  mounted() {
    this.searchNationality();
    if (this.$route.params.nationality_id) {
      this.fetch_rooms_by_nationalities();
      this.checkPackageNationalityIsAvailable();
    } else {
      this.FetchRooms();
    }
  },
  components: {
    Multiselect,
    skeltonNatinalityPrice,
    InputSwitch
  },
};
</script>

<style lang="scss" scoped>
.first_field,
.second_field {
  display: flex;
  align-items: center;
  //   justify-content: space-evenly;
}
.form-group {
  margin-inline-start: 1rem;
  margin-bottom: 0 !important;
}

.adult_child {
  display: flex;
  align-items: center;
}
.natilality_adult_child {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  //   justify-content: center;
}
.form-control {
  border-radius: 0.375rem;
  padding: 0.6rem 1rem !important;
  font-size: 0.75rem !important;
}
.room_name {
  background: #e9ecef;
}
.not_visiable {
  visibility: hidden;
}
</style>
