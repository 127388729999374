<template>
    <div class="terms">
        <h4 v-if="terms">الشروط والاحكام</h4>
        <p class="text" v-html="terms?.description"></p>
        <div class="terms">
            <h4 v-if="pills?.book_notes">الملاحظات</h4>
            <p class="text" v-html="pills?.book_notes"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "terms-ar",
    props: {
        terms: Object,
        book_notes: String
    }
}
</script>

<style>

</style>