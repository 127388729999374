<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("edit_page") }}</h4>
    </div>
    <form @submit.prevent="updatePage()">
      <div class="form-group">
        <label>{{ $t("title") }}</label>
        <input v-model="title" class="form-control" type="text" />
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
const title = ref("");

const router = useRouter();
const route = useRoute();

const getTitle = async (id) => {
  await axios
    .get(`show_page/${id}`)
    .then((res) => {
      title.value = res.data.data.title;
    })
    .catch((err) => {
      console.log(err);
    });
};

const updatePage = async () => {
  await axios
    .put(`update_page/${route.params.id}`, {
      title: title.value,
    })
    .then((res) => {
      if (res) router.push("/index-page");
    })
    .catch((err) => {
      console.log(err);
    });
};

onMounted(async () => {
  await getTitle(route.params.id);
});
</script>

<style lang="scss" scoped></style>
