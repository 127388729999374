<template>
  <a class="btn SecondButton offline w-25" @click="visible = true">
    {{ $t("Filter Bookings") }}</a
  >

  <Dialog
    v-model:visible="visible"
    modal
    :pt="{
      root: 'border-none',
      mask: {
        style: 'backdrop-filter: blur(2px)',
      },
    }"
    :dismissableMask="true"
  >
    <!-- <div class="d-flex gap-2 justify-content-end">
      or <InputSwitch v-model="$store.state.filteroffline.checked" /> and
    </div> -->
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <form class="modal_form" @submit.prevent="filterBooking()">
            <div class="row">
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="name" class="form-label">{{
                    $t("Hotel Name")
                  }}</label>
                  <Multiselect
                    v-model="$store.state.filteroffline.hotelValue"
                    :options="$store.state.filteroffline.hotels"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('Choose_hotel')"
                    select-label=""
                    :hide-selected="true"
                    :custom-label="formatHotelLabel"
                    @search-change="searchHotel"
                    :multiple="true"
                    @open="handlehotelData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <label class="form-label"> {{ $t("room_name") }}</label>
                <multiselect
                  class="w-100"
                  v-model="$store.state.filteroffline.roomValue"
                  :options="$store.state.filteroffline.rooms"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                  @open="handleRoomData"
                ></multiselect>
              </div>

              <div class="col-lg-3">
                <div class="form-group">
                  <label for="supplier_campany" class="form-label">{{
                    $t("Supplier")
                  }}</label>
                  <Multiselect
                    v-model="$store.state.filteroffline.supplierValue"
                    :options="$store.state.filteroffline.suppliers"
                    label="name"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    :multiple="true"
                    @search-change="FetchSuppliers"
                    @open="handlesuplierData"
                  >
                  </Multiselect>
                </div>
              </div>

              <div class="col-lg-3">
                <div class="form-group">
                  <label for="client" class="form-label">{{
                    $t("client")
                  }}</label>
                  <Multiselect
                    v-model="$store.state.filteroffline.client_id"
                    :options="$store.state.filteroffline.clients"
                    label="name"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    :multiple="true"
                    @search-change="fetchClients"
                    @open="handleClientData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="checkin" class="form-label">checkin</label>
                  <Calendar
                    v-model="$store.state.filteroffline.checkin_from"
                    selectionMode="range"
                    :manualInput="true"
                    showIcon
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="checkout" class="form-label">checkout</label>
                  <Calendar
                    v-model="$store.state.filteroffline.checkout_to"
                    selectionMode="range"
                    :manualInput="true"
                    showIcon
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="checkout" class="form-label">{{
                    $t("From_Date")
                  }}</label>

                  <Calendar
                    v-model="$store.state.filteroffline.create_at_to"
                    selectionMode="range"
                    :manualInput="false"
                    showIcon
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="checkout" class="form-label">option Date</label>
                  <Calendar
                    v-model="$store.state.filteroffline.optionDate"
                    selectionMode="range"
                    :manualInput="true"
                    showIcon
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("payment_book_status") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.payment_status"
                    :options="payment_statuses"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("booking_offline_status") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.booking_status"
                    :options="$store.state.filteroffline.booking_statuses"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("post_status") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.post_status"
                    :options="$store.state.filteroffline.post_statuses"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("city") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.city"
                    :options="$store.state.filteroffline.cities"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                    @search-change="fetchCity"
                    @open="handlecityData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("nationality") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.nationality"
                    :options="$store.state.filteroffline.nationalities"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                    @search-change="fetchNationality"
                    @open="handlenationalityData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("Book Number") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.book_number"
                    :options="$store.state.filteroffline.book_numbers"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="client_name"
                    :multiple="true"
                    @search-change="fetchBookNums"
                    :custom-label="formatBookNumLabel"
                    @open="handlebook_numberData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("hotel_refs") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.hotel_ref"
                    :options="$store.state.filteroffline.hotel_refs"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :custom-label="formatHotelRefLabel"
                    :multiple="true"
                    @search-change="fetchHotelRefs"
                    @open="handlehotelRefData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("suplier_refs") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.suplier_ref"
                    :options="$store.state.filteroffline.suplier_refs"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                    @search-change="fetchsuplierRefs"
                    :custom-label="formatSuplierRefLabel"
                    @open="handlesuplierRefData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("hotel_as_supliers") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.hotel_as_suplier"
                    :options="$store.state.filteroffline.hotel_as_supliers"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="name"
                    :multiple="true"
                    @search-change="fetchhotel_as_supliers"
                    @open="handlehotel_as_suplierData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("employ") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.employ"
                    :options="$store.state.filteroffline.emploies"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="name"
                    :multiple="true"
                    @search-change="fetchemployyes"
                    @open="handleemployData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("meals") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.meal"
                    :options="$store.state.filteroffline.meals"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                    @search-change="fetchmeals"
                    @open="handlemealData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("Rate Code") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.rate"
                    :options="$store.state.filteroffline.rates"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="name"
                    :multiple="true"
                    @search-change="fetchRates"
                    :custom-label="formatRateLabel"
                    @open="handlefetchRates"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("client category") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.client_category"
                    :options="$store.state.filteroffline.client_categorys"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("payment method") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.paymentMethod"
                    :options="paymentMethods"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("payment suplier status") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.paymentSuplier"
                    :options="paymentSupliers"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("client type") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.clientType"
                    :options="$store.state.filteroffline.clientTypes"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                    @search-change="fetchClientTypes"
                    @open="handleclientTypeData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("suplier type") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.suplierType"
                    :options="$store.state.filteroffline.suplierTypes"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                    @search-change="supplier_types"
                    @open="handlesuplierTypeData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="status" class="form-label"
                    >{{ $t("dsicount type") }}
                  </label>
                  <Multiselect
                    v-model="$store.state.filteroffline.discountType"
                    :options="$store.state.filteroffline.discountTypes"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    track-by="id"
                    label="title"
                    :multiple="true"
                    @search-change="fetchDiscount"
                    @open="handlediscountTypeData"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="checkin" class="form-label">{{
                    $t("guest")
                  }}</label>
                  <input
                    class="form-control"
                    type="text"
                    :placeholder="$t('guest')"
                    v-model="$store.state.filteroffline.guest"
                  />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label for="checkout" class="form-label">{{
                    $t("share_amount")
                  }}</label>
                  <input
                    class="form-control"
                    type="number"
                    min="0"
                    :placeholder="$t('share_amount')"
                    v-model="$store.state.filteroffline.share_amount"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn filter mx-3" type="submit">
                {{ $t("Filter Bookings") }}
              </button>
              <button
                style="width: 13%"
                class="btn reset PrimaryButton del"
                @click="resetForm"
                type="button"
              >
                {{ $t("Reset_data") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import Dialog from "primevue/dialog";
import moment from "moment";
import Calendar from "primevue/calendar";

// import InputSwitch from "primevue/inputswitch";

export default {
  props: ["booking_type", "bookSearch", "book_offline_status"],
  components: {
    Multiselect,
    Dialog,
    Calendar,
    // InputSwitch
  },
  emits: ["getData"],
  data() {
    return {
      hotelData: false,
      roomsData: false,
      suplierData: false,
      clientData: false,
      cityData: false,
      nationalityData: false,
      book_numberData: false,
      hotel_as_suplierData: false,
      employData: false,
      post_statusData: false,
      payment_statusData: false,
      booking_statusData: false,
      hotelRefData: false,
      suplierRefData: false,
      mealData: false,
      rateData: false,
      paymentMethodData: false,
      clientTypeData: false,
      paymentSuplierData: false,
      suplierTypeData: false,
      discountTypeData: false,

      checked: false,
      visible: false,
      clients: [],
      client_id: "",
      suppliers: [],
      formData: {},
      supplierValue: "",
      hotels: [],
      hotelValue: null,
      fromDate: "",
      toDate: "",
      roomValue: "",
      rooms: [],
      checkin_from: [],
      client_categorys: [
        {
          id: 1,
          title: "Individual",
        },
        {
          id: 2,
          title: "Company",
        },
      ],
      client_category: "",

      checkout_to: [],
      optionDate: [],
      post_status: "",
      payment_status: "",
      booking_status: "",
      nationalities: [],
      nationality: "",
      create_at_in: [],
      create_at_to: [],
      book_numbers: [],
      book_number: "",
      hotel_refs: [],
      hotel_ref: "",
      suplier_refs: [],
      suplier_ref: "",
      hotel_as_supliers: [],
      hotel_as_suplier: "",
      emploies: [],
      employ: "",
      post_statuses: [
        {
          id: 0,
          title: "unposted",
        },
        {
          id: 1,
          title: "posted",
        },
      ],
      payment_statuses: [
        {
          id: 0,
          title: this.$t("unpaid"),
        },
        {
          id: 1,
          title: this.$t("paid"),
        },
        {
          id: 3,
          title: this.$t("partially_paid"),
        },
      ],
      paymentSupliers: [
        {
          id: 0,
          title: this.$t("unpaid"),
        },
        {
          id: 1,
          title: this.$t("paid"),
        },
        {
          id: 3,
          title: this.$t("partially_paid"),
        },
      ],
      paymentSuplier: "",
      booking_statuses: [
        {
          id: 0,
          title: this.$t("pending"),
        },
        {
          id: 1,
          title: this.$t("tentative"),
        },
        {
          id: 2,
          title: this.$t("confirmed"),
        },
        {
          id: 3,
          title: this.$t("cancelled"),
        },
      ],
      room_statuses: [
        {
          id: 0,
          title: this.$t("not available"),
        },
        {
          id: 1,
          title: this.$t("available"),
        },
      ],
      room_status: "",
      guest: "",
      share_amount: "",
      localBookSearch: "",
      meals: [],
      meal: "",
      rates: [],
      rate: "",
      cities: [],
      clientTypes: [],
      clientType: "",
      city: "",
      suplierTypes: [],
      discountTypes: [],
      discountType: "",
      suplierType: "",
      paymentMethod: "",
      paymentMethods: [
        {
          id: 0,
          title: this.$t("cash_on_delivery"),
        },
        {
          id: 1,
          title: this.$t("Pay immediately"),
        },
      ],
    };
  },
  methods: {
    fetchhotelData() {
      this.searchHotel();
      this.hotelData = true;
    },
    handlehotelData() {
      if (!this.hotelData) {
        this.fetchhotelData();
      }
    },
    fetchRoomData() {
      this.fetchRooms();
      this.roomsData = true;
    },
    handleRoomData() {
      if (!this.roomsData) {
        this.fetchRoomData();
      }
    },
    fetchFetchSuppliers() {
      this.FetchSuppliers();
      this.suplierData = true;
    },
    handlesuplierData() {
      if (!this.suplierData) {
        this.fetchFetchSuppliers();
      }
    },
    fetchClientData() {
      this.fetchClients();
      this.clientData = true;
    },
    handleClientData() {
      if (!this.clientData) {
        this.fetchClientData();
      }
    },
    fetchcityData() {
      this.fetchCity();
      this.cityData = true;
    },
    handlecityData() {
      if (!this.cityData) {
        this.fetchcityData();
      }
    },
    fetchnationalityData() {
      this.fetchNationality();
      this.nationalityData = true;
    },
    handlenationalityData() {
      if (!this.nationalityData) {
        this.fetchnationalityData();
      }
    },
    fetchbook_numberData() {
      this.fetchBookNums();
      this.book_numberData = true;
    },
    handlebook_numberData() {
      if (!this.book_numberData) {
        this.fetchbook_numberData();
      }
    },
    fetchhotelRefData() {
      this.fetchHotelRefs();
      this.hotel_refData = true;
    },
    handlehotelRefData() {
      if (!this.hotel_refData) {
        this.fetchhotelRefData();
      }
    },
    fetchSuplierRefData() {
      this.fetchsuplierRefs();
      this.suplierRefData = true;
    },
    handlesuplierRefData() {
      if (!this.suplierRefData) {
        this.fetchSuplierRefData();
      }
    },
    fetchemployData() {
      this.fetchemployyes();
      this.employData = true;
    },
    handleemployData() {
      if (!this.employData) {
        this.fetchemployData();
      }
    },
    fetchmealData() {
      this.fetchmeals();
      this.mealData = true;
    },
    handlemealData() {
      if (!this.mealData) {
        this.fetchmealData();
      }
    },
    fetchfetchRates() {
      this.fetchRates();
      this.rateData = true;
    },
    handlefetchRates() {
      if (!this.rateData) {
        this.fetchfetchRates();
      }
    },
    fetchclientTypeData() {
      this.fetchClientTypes();
      this.clientTypeData = true;
    },
    handleclientTypeData() {
      if (!this.clientTypeData) {
        this.fetchclientTypeData();
      }
    },
    fetchhotel_as_suplierData() {
      this.fetchhotel_as_supliers();
      this.hotel_as_suplierData = true;
    },
    handlehotel_as_suplierData() {
      if (!this.hotel_as_suplierData) {
        this.fetchhotel_as_suplierData();
      }
    },
    fetchsuplierTypeData() {
      this.supplier_types();
      this.suplierTypeData = true;
    },
    handlesuplierTypeData() {
      if (!this.suplierTypeData) {
        this.fetchsuplierTypeData();
      }
    },
    fetchdiscountTypeData() {
      this.fetchDiscount();
      this.discountTypeData = true;
    },
    handlediscountTypeData() {
      if (!this.discountTypeData) {
        this.fetchdiscountTypeData();
      }
    },
    fetchDiscount(discount_search) {
      axios
        .post("/discount_type_with_search", { word: discount_search })
        .then(({ data }) => {
          this.$store.state.filteroffline.discountTypes = data.data.data;
        });
    },
    supplier_types(suplier_searrch) {
      let data = {
        word: suplier_searrch,
      };
      console.log(data);

      axios.get("/supplier_types").then(({ data }) => {
        this.$store.state.filteroffline.suplierTypes = data.data.data;
      });
    },
    fetchClientTypes(clientTypeSearch) {
      axios
        .post("/index_client_types", { word: clientTypeSearch })
        .then(({ data }) => {
          this.$store.state.filteroffline.clientTypes = data.data.data;
        });
    },
    fetchCity(citySearch) {
      axios
        .post("/search_city", {
          title: citySearch,
        })
        .then(({ data }) => {
          this.$store.state.filteroffline.cities = data.data.data;
        });
    },
    formatRateLabel(rate) {
      return `(${rate.name}) - ${rate.season_name} - ${rate.contract_name}`;
    },
    fetchRates(rateSearhc) {
      axios
        .post("/searchSeasonRateCodeName", { word: rateSearhc })
        .then(({ data }) => {
          this.$store.state.filteroffline.rates = data.data;
        });
    },
    fetchmeals(mealSearch) {
      axios
        .post("/fetch_meal_types", {
          word: mealSearch,
          count: 0,
        })
        .then(({ data }) => {
          this.$store.state.filteroffline.meals = data.data.data;
        });
    },
    fetchemployyes(employeeSearch) {
      axios
        .post("/searchEmployeeName", {
          word: employeeSearch,
        })
        .then(({ data }) => {
          this.$store.state.filteroffline.emploies = data.data;
        });
    },
    fetchHotelRefs(HotelRefSearch) {
      axios
        .post("searchOfflineRoomHotelRef", {
          word: HotelRefSearch,
        })
        .then(({ data }) => {
          this.$store.state.filteroffline.hotel_refs = data.data;
        });
    },
    formatHotelRefLabel(hotel) {
      return `${hotel.hotel_reference} - (${hotel.hotel_name})`;
    },
    fetchsuplierRefs(SuplierRefSearch) {
      axios
        .post("searchOfflineRoomSupplierRef", {
          word: SuplierRefSearch,
        })
        .then(({ data }) => {
          this.$store.state.filteroffline.suplier_refs = data.data;
        });
    },
    formatSuplierRefLabel(suplier) {
      return `${suplier.supplier_reference} - (${suplier.supplier_name})`;
    },
    fetchClients(clint_search) {
      axios
        .post("/fetch_consumer_by_role", { role: 1, word: clint_search })
        .then(({ data }) => {
          this.$store.state.filteroffline.clients = data.data.data;
        });
    },
    // fetch suppliers data
    FetchSuppliers(supplierText) {
      axios
        .post("/fetch_consumer_by_role", { role: 2, word: supplierText })
        .then(({ data }) => {
          this.$store.state.filteroffline.suppliers = data.data.data;
          // this.providers = data.data;
        });
    },
    formatHotelLabel(hotel) {
      return `${hotel.title} -
              ${hotel.city_title}, ${hotel.country_title} (${hotel.id})`;
    },
    searchHotel(hotel_search) {
      // const searchText = { word: event.target.value };
      axios
        .post(`/fetch_new_home_hotels`, { word: hotel_search })
        .then(
          (res) =>
            (this.$store.state.filteroffline.hotels = res.data.data.hotels)
        );
    },
    fetchBookNums(BookingSearch) {
      axios
        .post("/searchBookingNumber", {
          word: BookingSearch,
        })
        .then(({ data }) => {
          this.$store.state.filteroffline.book_numbers = data.data;
        });
    },
    formatBookNumLabel(book) {
      return `${book.book_number} - (${book.client_name})`;
    },
    fetchhotel_as_supliers(holtelasuplierSearch) {
      axios
        .post("/fetch_consumer_hotels", {
          word: holtelasuplierSearch,
        })
        .then(({ data }) => {
          this.$store.state.filteroffline.hotel_as_supliers = data.data.data;
        });
    },
    async fetchRooms() {
      await axios.post("/fetchHotelRoomViewByHotelIdV2").then(({ data }) => {
        this.$store.state.filteroffline.rooms = data.data;
        console.log(this.$store.state.filteroffline.rooms);
      });
    },
    fetchNationality() {
      axios.post("/fetch_custom_countries", {}).then(({ data }) => {
        this.$store.state.filteroffline.nationalities = data.data.data;
      });
    },
    filterBooking() {
      // let form = {};

      const formData = new FormData();

      if (this.$store.state.filteroffline.hotelValue) {
        this.$store.state.filteroffline.hotelValue.forEach((hotel, index) => {
          formData.append(`hotel_ids[${index}]`, hotel.id);
        });
      }

      if (this.book_offline_status) {
        formData.append("book_offline_status", this.book_offline_status);
      }

      if (this.$store.state.filteroffline.supplierValue) {
        this.$store.state.filteroffline.supplierValue.forEach(
          (supplier, index) => {
            formData.append(`supplier_ids[${index}]`, supplier.id);
          }
        );
      }
      if (this.$store.state.filteroffline.client_id) {
        this.$store.state.filteroffline.client_id.forEach((client, index) => {
          formData.append(`client_ids[${index}]`, client.id);
        });
      }
      if (this.$store.state.filteroffline.hotel_as_suplier) {
        this.$store.state.filteroffline.hotel_as_suplier.forEach(
          (hotel_as_suplier, index) => {
            formData.append(
              `hotel_as_supplier_ids[${index}]`,
              hotel_as_suplier.id
            );
          }
        );
      }
      if (this.$store.state.filteroffline.hotel_ref) {
        this.$store.state.filteroffline.hotel_ref.map((hotel_ref, index) =>
          formData.append(`hotel_refs[${index}]`, hotel_ref.hotel_reference)
        );
      }
      if (this.$store.state.filteroffline.suplier_ref) {
        this.$store.state.filteroffline.suplier_ref.map((suplier_ref, index) =>
          formData.append(`suplier_refs[${index}]`, suplier_ref.id)
        );
      }
      if (this.$store.state.filteroffline.guest) {
        formData.append(`guest_name`, this.$store.state.filteroffline.guest);
      }
      if (this.$store.state.filteroffline.share_amount) {
        formData.append(`amount`, this.$store.state.filteroffline.share_amount);
      }
      if (this.$store.state.filteroffline.create_at_to) {
        if (this.$store.state.filteroffline.create_at_to[0]) {
          formData.append(
            "create_at_start",
            moment(this.$store.state.filteroffline.create_at_to[0]).format(
              "YYYY-MM-DD"
            )
          );
        }
        if (this.$store.state.filteroffline.create_at_to[1]) {
          formData.append(
            "create_at_end",
            moment(this.$store.state.filteroffline.create_at_to[1]).format(
              "YYYY-MM-DD"
            )
          );
        }
      }
      if (this.$store.state.filteroffline.city) {
        this.$store.state.filteroffline.city.forEach((city, index) => {
          formData.append(`city_ids[${index}]`, city.id);
        });
      }

      if (this.$store.state.filteroffline.nationality) {
        this.$store.state.filteroffline.nationality.forEach(
          (nationality, index) => {
            formData.append(`nationality_ids[${index}]`, nationality.id);
          }
        );
      }

      if (this.$store.state.filteroffline.employ) {
        this.$store.state.filteroffline.employ.forEach((employ, index) => {
          formData.append(`employee_ids[${index}]`, employ.id);
        });
      }

      if (this.$store.state.filteroffline.optionDate) {
        if (this.$store.state.filteroffline.optionDate[0]) {
          formData.append(
            "option_date_from",
            moment(this.$store.state.filteroffline.optionDate[0]).format(
              "YYY-MM-DD"
            )
          );
        }
        if (this.$store.state.filteroffline.optionDate[1]) {
          formData.append(
            "option_date_to",
            moment(this.$store.state.filteroffline.optionDate[1]).format(
              "YYY-MM-DD"
            )
          );
        }
      }
      if (this.$store.state.filteroffline.checkin_from) {
        if (this.$store.state.filteroffline.checkin_from[0]) {
          formData.append(
            "check_in_from",
            moment(this.$store.state.filteroffline.checkin_from[0]).format(
              "YYYY-MM-DD"
            )
          );
        }
        if (this.$store.state.filteroffline.checkin_from[1]) {
          formData.append(
            "check_in_to",
            moment(this.$store.state.filteroffline.checkin_from[1]).format(
              "YYYY-MM-DD"
            )
          );
        }
      }
      if (this.$store.state.filteroffline.checkout_to) {
        if (this.$store.state.filteroffline.checkout_to[1]) {
          formData.append(
            "check_out_from",
            moment(this.$store.state.filteroffline.checkout_to[1]).format(
              "YYYY-MM-DD"
            )
          );
        }
        if (this.$store.state.filteroffline.checkout_to[0]) {
          formData.append(
            "check_out_to",
            moment(this.$store.state.filteroffline.checkout_to[0]).format(
              "YYYY-MM-DD"
            )
          );
        }
      }
      if (this.$store.state.filteroffline.booking_status) {
        this.$store.state.filteroffline.booking_status.map(
          (booking_status, index) =>
            formData.append(`books_status[${index}]`, booking_status.id)
        );
      }
      if (this.$store.state.filteroffline.payment_status) {
        this.$store.state.filteroffline.payment_status.map(
          (payment_status, index) =>
            formData.append(`payment_status[${index}]`, payment_status.id)
        );
      }

      if (this.$store.state.filteroffline.book_number) {
        this.$store.state.filteroffline.book_number.map((book_number, index) =>
          formData.append(`book_numbers[${index}]`, book_number.book_number)
        );
      }

      if (this.$store.state.filteroffline.paymentMethod) {
        this.$store.state.filteroffline.paymentMethod.map(
          (paymentMethod, index) =>
            formData.append(`payment_method[${index}]`, paymentMethod.id)
        );
      }
      if (this.$store.state.filteroffline.roomValue) {
        formData.append(
          `hotel_room_view_id`,
          this.$store.state.filteroffline.roomValue.id
        );
      }
      if (this.$store.state.filteroffline.meal) {
        this.$store.state.filteroffline.meal.forEach((meal, index) => {
          formData.append(`meal_ids[${index}]`, meal.id);
        });
      }
      if (this.$store.state.filteroffline.clientType) {
        this.$store.state.filteroffline.clientType.forEach(
          (clientType, index) => {
            formData.append(`client_type_ids[${index}]`, clientType.id);
          }
        );
      }
      if (this.$store.state.filteroffline.rate) {
        this.$store.state.filteroffline.rate.forEach((rate, index) => {
          formData.append(`rate_code_ids[${index}]`, rate.id);
        });
      }
      if (this.$store.state.filteroffline.paymentSuplier) {
        this.$store.state.filteroffline.paymentSuplier.map(
          (paymentSuplier, index) =>
            formData.append(
              `payment_supplier_status[${index}]`,
              paymentSuplier.id
            )
        );
      }

      if (this.$store.state.filteroffline.post_status) {
        formData.append(
          `post_status`,
          this.$store.state.filteroffline.post_status.id
        );
      }
      if (this.$store.state.filteroffline.client_category) {
        this.$store.state.filteroffline.client_category.map(
          (client_category, index) =>
            formData.append(`client_category[${index}]`, client_category.id)
        );
      }
      if (this.$store.state.filteroffline.suplierType) {
        this.$store.state.filteroffline.suplierType.forEach((s, index) => {
          formData.append(`supplier_type_ids[${index}]`, s.id);
        });
      }
      if (this.$store.state.filteroffline.discountType) {
        this.$store.state.filteroffline.discountType.forEach((s, index) => {
          formData.append(`discount_type_ids[${index}]`, s.id);
        });
      }

      // formData.append(
      //   "filter_status",
      //   this.$store.state.filteroffline.checked ? "or" : "and"
      // );

      if (this.localBookSearch) {
        formData.append("word", this.localBookSearch);
      }

      if (this.$route.path == "/BookHotels-portal") {
        formData.append("is_portal", 1);
      }

      this.formData = formData;
      this.$emit("getData", formData);
      this.visible = false;
    },
    resetForm() {
      this.$store.state.filteroffline.hotelValue = null;
      this.$store.state.filteroffline.roomValue = null;
      this.$store.state.filteroffline.supplierValue = null;
      this.$store.state.filteroffline.client_id = null;
      this.$store.state.filteroffline.checkin_from = [];
      this.$store.state.filteroffline.checkout_to = [];
      this.$store.state.filteroffline.create_at_to = [];
      this.$store.state.filteroffline.optionDate = [];
      this.$store.state.filteroffline.payment_status = null;
      this.$store.state.filteroffline.booking_status = null;
      this.$store.state.filteroffline.post_status = null;
      this.$store.state.filteroffline.city = null;
      this.$store.state.filteroffline.nationality = null;
      this.$store.state.filteroffline.book_number = null;
      this.$store.state.filteroffline.hotel_ref = null;
      this.$store.state.filteroffline.suplier_ref = null;
      this.$store.state.filteroffline.hotel_as_suplier = null;
      this.$store.state.filteroffline.employ = null;
      this.$store.state.filteroffline.meal = null;
      this.$store.state.filteroffline.rate = null;
      this.$store.state.filteroffline.client_category = null;
      this.$store.state.filteroffline.paymentMethod = null;
      this.$store.state.filteroffline.paymentSuplier = null;
      this.$store.state.filteroffline.clientType = null;
      this.$store.state.filteroffline.suplierType = null;
      this.$store.state.filteroffline.discountType = null;
      this.$store.state.filteroffline.guest = null;
      this.$store.state.filteroffline.share_amount = null;
      this.$store.state.filteroffline.checked = false;
    },
  },

  mounted() {
    this.localBookSearch = this.bookSearch;
    if (this.$store.state.filteroffline) {
      this.filterBooking();
    }
  },
  watch: {
    bookSearch(newVal) {
      this.localBookSearch = newVal;
      if (this.localBookSearch) {
        this.filterBooking();
      } else {
        this.filterBooking();
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "../../view/dashboard/offline/_offline.scss";

.selects {
  .form-group {
    width: 100%;
  }
}
.reset.PrimaryButton {
  width: unset !important;
}
.modal-footer {
  align-items: flex-end;
}
</style>
