<template>
  <section class="card_style">
    <skeltonTitles v-if="loading && $route.params.id" />
    <form @submit.prevent="addService()" v-else>
      <div class="main_header">
        <h4 v-if="this.$route.params.id">{{ $t("edit Insurance Company") }}</h4>
        <h4 v-else>{{ $t("add Insurance Company") }}</h4>
      </div>
      <div class="row">
        <!-- <h4>
           <i class="fa-solid fa-caret-left"></i>
           المرحله الاولي
         </h4> -->
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <label>{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.locale }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.title"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span class="input-errors" v-if="v$.Form.title.$error">{{
                v$.Form.title.$errors[0].$message
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import skeltonTitles from "@/components/skelton/skeltonTitles.vue";
export default {
  name: "add-service",
  components: { skeltonTitles },
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      fileData: null,
      Form: {
        title: [],
      },
      loading: true,
    };
  },
  validations() {
    return {
      Form: {
        title: this.Form.title.map(() => {
          return {
            title: { required },
          };
        }),
      },
    };
  },
  methods: {
    setBlogTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          locale: language,
          title: "",
        });
      });
    },

    addService() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.$route.params.id != undefined) {
          const params = new URLSearchParams();

          this.Form.title.forEach((ele) => {
            params.append(`title_${ele.locale}`, ele.title);
          });

          axios
            .put(`/issurance_company/${this.$route.params.id}`, params)
            .then(() => {
              Swal.fire({
                title: "",
                text: this.$t("issurance_company_updated_successfully"),
                icon: "success",
              });
              this.$router.push("/Insurance_Companies");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          const formData = new FormData();

          this.Form.title.forEach((ele) => {
            formData.append(`title_${ele.locale}`, ele.title);
          });
          axios
            .post(`/issurance_company`, formData)
            .then(() => {
              Swal.fire({
                title: "",
                text: this.$t("add Insurance Company"),
                icon: "success",
              });
              this.$router.push("/Insurance_Companies");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
  },
  created() {
    this.setBlogTitle();
  },
  mounted() {
    if (this.$route.params.id != undefined) {
      axios.get(`/issurance_company/${this.$route.params.id}`).then((res) => {
        this.Form.title = res.data.data.title;
        this.loading = false;
      }).catch((error) => {
        Swal.fire({
          title: "",
          text: error.response.data.message,
          icon: "error",
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

.btn-stratigy {
  background-color: #0a909b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}

input.form-control {
  padding: 0.8rem !important;
}
.fa-caret-left {
  color: #0a909b;
}
</style>
