<template>
  <section class="card_style">
    <div class="main_header_style"></div>
    <div class="row options mb-2">
      <div class="col-12 col-md-3 d-flex align-items-center">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("cost_centers") }}</h4>
      </div>
      <div class="col-12 col-md-3">
        <div class="form-group search">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            :placeholder="$t('search by name')"
            class="form-control"
            v-model="search_center"
            @keyup="fetch_all_centers"
          />
        </div>
      </div>
      <div class="col-12 col-md-3">
        <router-link
          :to="{ name: 'addCostCenter' }"
          class="PrimaryButton btn w-50"
          ><i class="fa-solid fa-plus"></i>{{ $t("add_new_center") }}
        </router-link>
      </div>
      <div class="col-12 col-md-3">
        <button
          class="SecondButton btn action d-flex align-items-center justify-content-center"
          @click="exportToExcel"
        >
          <i class="fa-solid fa-file-excel"></i>
          {{ $t("export_to_excel") }}
        </button>
      </div>
    </div>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("description") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 4" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in all_centers.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.description }}</td>
            <td :data-label="$t('actions')">
              <router-link
                class="btn btn-success mx-1"
                :title="$t('edit')"
                :to="`/cost_center/edit/${item.id}`"
              >
                <i class="fa-solid fa-pen-to-square text-white"></i>
              </router-link>

              <button
                class="btn remove_btn btn-danger"
                @click="DeleteAlert(index)"
              >
                <i
                  class="fa-solid fa-trash text-white"
                  :title="$t('delete')"
                ></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="all_centers"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_all_centers"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import Skeleton from "primevue/skeleton";
export default {
  name: "costCenterIndex",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },

  data() {
    return {
      all_centers: [],
      search_center: "",
      loading: true,
    };
  },
  methods: {
    async exportToExcel() {
      try {
        const response = await axios.post("/index_cost_center");
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "cost_center.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    fetch_all_centers() {
      let data = {
        per_page: 10,
      };
      if (this.search_center != "") {
        data.word = this.search_center;
      }
      axios.post(`/index_cost_center`, data).then(({ data }) => {
        this.all_centers = data.data;
        this.loading = false;
      });
    },
    //delete expness
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.all_centers.data[index].id;
          const fromClick = true;
          axios
            .delete("/delete_cost_center/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.all_centers.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.fetch_all_centers();
  },
};
</script>

<style lang="scss" scope>
.search {
  position: relative;
  i {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.PrimaryButton {
  margin-top: auto !important;
}
</style>
