<template>
  <section class="all_emp card_style">
    <transportatonContrac v-if="loading" />
    <form @submit.prevent="sendData()" v-else>
      <div class="title">
        <h4>{{ $t("Edit Visa Contract") }}</h4>
        <p>{{ $t("enter this data to continue") }}</p>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  {{ $t("contract name") }}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div
                    class="form-group"
                    v-for="(contract, index) in contract_name"
                    :key="index"
                  >
                    <label for="name" class="form-label"
                      >{{ $t("contract name") }} {{ contract.lang }}</label
                    >
                    <input
                      type="text"
                      :placeholder="$t('enter contract name')"
                      class="form-control"
                      id="name"
                      v-model="contract.name"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="input-errors" v-if="v$.contract_name.$error">{{
            v$.contract_name.$errors[0].$message
          }}</span>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="visa_type" class="form-label">{{
              $t("visa type")
            }}</label>
            <Multiselect
              v-model="visa_value"
              :options="allVisa"
              label="type_name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.visa_value.$error">{{
              v$.visa_value.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="visa_country" class="form-label">{{
              $t("visa country")
            }}</label>
            <Multiselect
              v-model="visaValue"
              :options="nationalities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_nationality')"
              select-label=""
              :hide-selected="true"
              @keyup="searchNationality"
            >
            </Multiselect>
            <!-- <span class="input-errors" v-if="v$.visaValue.id.$error">{{
              v$.visaValue.id.$errors[0].$message
            }}</span> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label for="nationality" class="form-label">{{
                $t("nationality")
              }}</label>
              <div class="switch-container">
                <label class="switch">
                  <input type="checkbox" v-model="allNationality" />
                  <span class="slider round"></span>
                </label>
                <span class="text">{{ $t("all nationalities") }}</span>
              </div>
            </div>
            <Multiselect
              v-model="nationalityValue"
              :options="nationalities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_nationality')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
              @keyup="searchNationality"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.nationalityValue.$error">{{
              v$.nationalityValue.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="supplier" class="form-label">{{
              $t("supplier")
            }}</label>
            <Multiselect
              v-model="provider_id"
              :options="providers"
              label="name"
              track-by="id"
              :clear-on-select="true"
              placeholder="اختار المورد"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.provider_id.id.$error">{{
              v$.provider_id.id.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="period" class="form-label">{{ $t("period") }}</label>
            <div class="row">
              <div class="col-6">
                <Datepicker v-model="fromDate" lang="ar" />
                <span class="input-errors" v-if="v$.fromDate.$error">{{
                  v$.fromDate.$errors[0].$message
                }}</span>
              </div>
              <div class="col-6">
                <Datepicker v-model="toDate" lang="ar" />
                <span class="input-errors" v-if="v$.toDate.$error">{{
                  v$.toDate.$errors[0].$message
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn PrimaryButton">{{ $t("save") }}</button>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import moment from "moment";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import transportatonContrac from "@/components/skelton/transportatonContrac.vue";
export default {
  name: "editContract",
  components: {
    Multiselect,
    Datepicker: VueDatepickerUi,
    transportatonContrac,
  },
  data() {
    return {
      v$: useVuelidate(),
      contract_id: this.$route.params.id,
      languages: ["ar", "en", "id"],
      contract_name: [],
      visa_type: "",
      nationalities: [],
      nationalityValue: [],
      allNationality: false,
      allVisa: [],
      visa_value: [],
      fromDate: new Date(),
      toDate: "",
      providers: [],
      provider_id: "",
      loading: true,
    };
  },
  validations() {
    return {
      contract_name: this.contract_name.map(() => {
        return {
          name: { required },
        };
      }),
      visa_value: [{ id: { required } }],
      nationalityValue: [{ required }],
      // visaValue: {
      //   id: { required },
      // },
      provider_id: {
        id: { required },
      },
      fromDate: { required },
      toDate: { required },
    };
  },
  methods: {
    FetchSuppliers() {
      axios.post("/fetch_consumer_by_role", { role: 2 }).then(({ data }) => {
        this.providers = data.data.data;
        this.loading = false;
      });
    },
    fetchAllVisa() {
      axios.post("/fetch_visa_types").then(({ data }) => {
        this.allVisa = data.data.data;
        this.loading = false;
      });
    },
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios.post(`/fetch_custom_countries`, searchText).then(({ data }) => {
        this.nationalities = data.data.data;
      });
    },
    sendData() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let contractData = {
          from: moment(this.fromDate).format("YYYY-MM-DD"),
          to: moment(this.toDate).format("YYYY-MM-DD"),
          consumer_id: this.provider_id.id,
          all_country: this.allNationality == false ? 0 : 1,
        };
        contractData.country_ids = this.nationalityValue.map((nationality) => {
          return nationality.id;
        });
        this.contract_name.forEach((contract) => {
          contractData["name_contract_" + contract.lang] = contract.name;
        });

        contractData.visa_type_ids = this.visa_value.map((visa) => {
          return visa.id;
        });

        console.log(contractData);
        axios
          .put(`/visa_contract/${this.contract_id}`, contractData)
          .then(({ data }) => {
            console.log(data);
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/visa/contracts");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    fetchContractData() {
      axios.get(`/visa_contract/${this.contract_id}`).then(({ data }) => {
        console.log(data.data);
        this.allNationality = data.data.all_country == 0 ? false : true;
        this.fromDate = data.data.from;
        this.toDate = data.data.to;
        this.nationalityValue = data.data.country_ids;

        this.contract_name = data.data.name_contract.map((contract) => {
          return {
            lang: contract.locale,
            name: contract.name,
          };
        });
        this.visa_value = data.data.visa_type_ids;
        this.provider_id = data.data.supplier;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.FetchSuppliers();
    this.fetchAllVisa();
    this.fetchContractData();
  },
};
</script>

<style lang="scss" scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-group {
  margin-bottom: 20px;
  label {
    font-weight: 700;
    font-family: "bold";
    font-size: 16px;
    color: #0b1427;
    margin-bottom: 10px;
  }
}
.switch-container {
  span.text {
    font-size: 14px;
    margin-inline-start: 10px;
  }
}
</style>
