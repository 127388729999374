<template>
  <form @submit.prevent="addHeaderText()">
    <!-- <General /> -->
    <section class="card_style">
      <div class="main_header_style">
        <h4>{{ $t("Add discription") }} {{ $t("General") }}</h4>
      </div>
      <div class="row d-flex align-items-start w-100">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingEight">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseEight"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form_General.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingnine">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsenine"
                    aria-expanded="false"
                    aria-controls="collapsenine"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapsenine"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingnine"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form_General.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <Editor
                        v-model="blog_content.name"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <General /> -->

    <!-- hotels -->
    <section class="card_style">
      <div class="main_header_style">
        <h4>{{ $t("Add discription") }} {{ $t("hotels") }}</h4>
      </div>
      <div class="row d-flex align-items-start w-100">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordiThreexample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordiThreexample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form_Hotel.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordiThreexample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSex">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSex"
                    aria-expanded="false"
                    aria-controls="collapseSex"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseSex"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingSex"
                  data-bs-parent="#accordiThreexample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form_Hotel.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <Editor
                        v-model="blog_content.name"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- hotels -->

    <!-- <HeaderFlight /> -->
    <section class="card_style">
      <div class="main_header_style">
        <h4>{{ $t("Add discription") }} {{ $t("flight") }}</h4>
      </div>
      <div class="row d-flex align-items-start w-100">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordiTwoxample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordiTwoxample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form_flight.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordiFourxample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordiFourxample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form_flight.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <Editor
                        v-model="blog_content.name"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <HeaderFlight /> -->

    <!-- <Headerpackage /> -->
    <section class="card_style">
      <div class="main_header_style">
        <h4>{{ $t("Add discription") }} {{ $t("Umrah_packages") }}</h4>
      </div>
      <div class="row d-flex align-items-start w-100">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form_package.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFive"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form_package.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <Editor
                        v-model="blog_content.name"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <Headerpackage /> -->

    <!-- <offers /> -->
    <section class="card_style">
      <div class="main_header_style">
        <h4>{{ $t("Add discription") }} {{ $t("offers") }}</h4>
      </div>
      <div class="row d-flex align-items-start w-100">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingEleven">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseEleven"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form_offers.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTen">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form_offers.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <Editor
                        v-model="blog_content.name"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <offers /> -->

    <button type="submit" class="add btn PrimaryButton my-3">
      {{ $t("save") }}
    </button>
  </form>
</template>

<script>
  import axios from "axios";
  import Swal from "sweetalert2";
  import Editor from "primevue/editor";

  export default {
    name: "Add-package",
    data() {
      return {
        languages: ["ar", "en", "id"],
        options: {
          placeholder: this.$t("describtion"),
          theme: "snow",
        },
        Form_Hotel: {
          title: [],
          subtitle: [],
        },
        Form_flight: {
          title: [],
          subtitle: [],
        },
        Form_package: {
          title: [],
          subtitle: [],
        },
        Form_offers: {
          title: [],
          subtitle: [],
        },
        Form_General: {
          title: [],
          subtitle: [],
        },
      };
    },
    components: {
      Editor,
    },
    methods: {
      setHeaderTitle() {
        this.languages.forEach((language) => {
          this.Form_Hotel.title.push({
            lang: language,
            name: "",
            services: "Hotel",
          });
          this.Form_Hotel.subtitle.push({
            lang: language,
            name: "",
            services: "Hotel",
          });

          this.Form_flight.title.push({
            lang: language,
            name: "",
            services: "Flight",
          });
          this.Form_flight.subtitle.push({
            lang: language,
            name: "",
            services: "Flight",
          });

          this.Form_package.title.push({
            lang: language,
            name: "",
            services: "Package",
          });

          this.Form_package.subtitle.push({
            lang: language,
            name: "",
            services: "Package",
          });

          this.Form_offers.title.push({
            lang: language,
            name: "",
            services: "Offers",
          });

          this.Form_offers.subtitle.push({
            lang: language,
            name: "",
            services: "Offers",
          });

          this.Form_General.title.push({
            lang: language,
            name: "",
            services: "General",
          });

          this.Form_General.subtitle.push({
            lang: language,
            name: "",
            services: "General",
          });
        });
      },

      addHeaderText() {
        const headers = [];
        // commentService = {
        //   1: "hotel",
        //   2: "flight",
        //   5: "PACKAGE",
        //   6: "GENERAL",
        //   7: "OFFER",
        // };

        const addHeader = (form, service) => {
          const serviceHeaders = {
            service: service,
          };

          form.title.forEach((ele) => {
            serviceHeaders[`title_${ele.lang}`] = ele.name;
          });

          form.subtitle.forEach((ele) => {
            serviceHeaders[`sub_title_${ele.lang}`] = ele.name;
          });

          headers.push(serviceHeaders);
        };

        addHeader(this.Form_Hotel, 1);
        addHeader(this.Form_flight, 2);
        addHeader(this.Form_package, 5);
        addHeader(this.Form_General, 6);
        addHeader(this.Form_offers, 7);

        const requestData = {
          headers: headers,
        };

        axios
          .post(`/store_header`, requestData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      },
    },
    mounted() {
      this.setHeaderTitle();
      // this.fetchGifts();
    },
  };
</script>
