<template>
  <section class="card_style">
    <div class="main_header_style">
      <h5 class="mb-4">{{ $t("Edit Meal") }}</h5>
    </div>

    <form @submit.prevent="editMeal()">
      <div class="row mb-5 mt-3">
        <div class="col-lg-4">
          <label>{{ $t("Meal Name in arabic") }}</label>
          <input
            type="text"
            :placeholder="$t('meal name')"
            v-model="mealName_ar"
          />
        </div>
        <div class="col-lg-4">
          <label>{{ $t("Meal Name in english") }}</label>
          <input
            type="text"
            :placeholder="$t('meal name')"
            v-model="mealName_en"
          />
        </div>
        <div class="col-lg-4 mt-3">
          <div class="card flex justify-content-center border-0">
            <label>{{ $t("default") }}</label>
            <Checkbox v-model="checked" :binary="true" />
          </div>
        </div>
      </div>

      <button type="submit" class="edit save btn">{{ $t("edit") }}</button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Checkbox from "primevue/checkbox";

export default {
  data() {
    return {
      mealId: this.$route.params.id,
      mealName_ar: "",
      mealName_en: "",
      checked: "",
    };
  },
  methods: {
    fetchMealData() {
      axios.get(`/meal_types/${this.mealId}`).then(({ data }) => {
        this.mealName_en = data.data.title_en;
        this.mealName_ar = data.data.title_ar;
        this.checked = data.data.is_default == 1 ? true : false;
      });
    },
    editMeal() {
      axios
        .put(`/meal_types/${this.mealId}`, {
          title_en: this.mealName_en,
          title_ar: this.mealName_ar,
          is_default: this.checked ? 1 : 0,
        })
        .then(({ data }) => {
          // console.log(data)
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/meal");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  components: {
    Checkbox,
  },
  created() {
    this.fetchMealData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "../_crudStyle.scss";
</style>
