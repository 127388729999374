import { createApp } from "vue";
import App from "./App.vue";
import { createI18n } from "vue-i18n";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import router from "../src/router/router.js";
import store from "./store/index.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import FlagIcon from "vue-flag-icon";
import rate from "vue-rate";
import "vue-rate/dist/vue-rate.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import PrimeVue from "primevue/config";
import "primevue/resources/themes/aura-light-green/theme.css"; //theme
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import { globalMixin } from "@/mixins";
import Tooltip from "primevue/tooltip";
import print from "vue3-print-nb";
import { createPinia } from "pinia";
import Swal from "sweetalert2";
import { Buffer } from "buffer";
import process from "process";

window.Buffer = Buffer;
window.process = process;

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
let lang = navigator.language.split("-")[0] || navigator.userLanguage.split("-")[0];
if (localStorage.getItem("lang")) {
  lang = localStorage.getItem("lang");
}

const i18n = createI18n({
  locale: lang, // set locale
  allowComposition: true, // you need to specify that!
  fallbackLocale: lang, // set fallback locale
  messages: loadLocaleMessages(), // set locale messages
});
const globalOptions = {
  mode: "auto",
};
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const fromClick = error.config.fromClick; // Retrieve fromClick parameter
    if (error.response.status === 403) {
      // Handle 403 Forbidden error globally
      console.log(fromClick);
      console.log(error.response.status);
      if (fromClick) {
        // Check if request originated from click event
        // Redirect to new route
        // Handle 403 Forbidden error globally
        Swal.fire({
          imageUrl: require("@/assets/media/image/403Icon.png"),
          imageWidth: 150,
          imageAlt: "Custom image",
          title:
            "Unauthorized. You do not have permission to access this route.",
        });
      } else {
        router.push("/403");
      }
    }
    return Promise.reject(error);
  },
);
const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
const newLocal = lang;
axios.defaults.headers.common["Accept-Language"] = newLocal;
// axios.defaults.headers.common["website_link"] = "https://softtechweb.crazyidea.online";

// axios.defaults.baseURL = "https://softtech.crazyidea.online/organization/api";
axios.defaults.baseURL = "https://travelsofttechsys.com/organization/api";
// axios.defaults.baseURL = "https://api.x-coders.net/organization/api";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "token",
)}`;

app.config.globalProperties.$websiteLink = JSON.parse(
  localStorage.getItem("user"),
)?.website_link;
app.config.globalProperties.axios = axios;
app.use(rate);
app.use(i18n);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC1hpAsZWGRTOGSzsyfZT4rGH3iVlEPrUM",
  },
});

app.use(VueTelInput, globalOptions); // Define default global options here (optional)
app.use(PrimeVue);
app.use(FlagIcon);
app.use(Donut);
app.use(router);
app.use(store);
app.use(createPinia());
app.use(print);
app.mount("#app");

app.directive("tooltip", Tooltip);

// Mixins -----------------------------------------------
app.mixin(globalMixin);
