<script setup>
import Skeleton from "primevue/skeleton";
import { onMounted, ref } from "vue";
import axios from "axios";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import pagination from "laravel-vue-pagination";

const offlineServices = ref([]);
const loader = ref(true);
const total = ref(0);
const fetchOfflineService = async () => {
  await axios
    .post("/fetch_offline_booking_hotel_by_service_type")
    .then(({ data }) => {
      offlineServices.value = data.data;
      loader.value = false;
      total.value = data.data.meta?.total ?? 0;
    })
    .catch((err) => {
      console.log(err);
    });
};
onMounted(() => {
  fetchOfflineService();
});
</script>

<template>
  <div class="card_style">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center gap-3 mb-4">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("book_offline_service") }}</h4>
      </div>
      <router-link
        :to="`/book-offline-service`"
        class="btn SecondButton offline w-25"
        ><i class="fa-solid fa-plus"></i> {{ $t("add_book") }}</router-link
      >
    </div>
    <Transition name="fade">
      <div class="books-wrapper" v-if="loader">
        <div
          class="books mt-1 row py-1 shadow px-3 align-items-center"
          v-for="book in 10"
          :key="book"
        >
          <div class="col-1">
            <Skeleton height="2rem"></Skeleton>
          </div>
          <div class="col-1 mt-2 mb-2">
            <Skeleton height="1rem" class="mb-2"></Skeleton>
            <Skeleton height="1rem"></Skeleton>
          </div>
          <div v-for="index in 4" :key="index" class="col-2 mt-2 mb-2">
            <Skeleton height="1rem" class="mb-2"></Skeleton>
            <Skeleton height="1rem"></Skeleton>
          </div>
        </div>
      </div>
      <div class="books-wrapper" v-else>
        <div
          v-for="(book, index) in offlineServices"
          :class="[
            'books',
            'mt-1',
            'd-flex',
            'align-items-center',
            'py-1',
            'px-3',
            'flex-lg-nowrap',
            book?.status == 0
              ? 'bg-warring'
              : book?.status == 1
                ? 'bg-default'
                : book?.status == 2
                  ? 'bg-confirmed'
                  : book?.status == 3
                    ? 'bg-canceled'
                    : book?.status == 4
                      ? 'bg-canceled'
                      : '',
          ]"
          :key="index"
        >
          <div class="id">
            <router-link
              class="details_id"
              :to="`/book-offline-service/${book.id}`"
              :title="$t('edit')"
            >
              <h4 class="text-center" v-tooltip.left="$t('show_details')">
                {{ index + 1 }}
                <!-- {{ (indexPage - 1) * 5 + index + 1 }} -->
              </h4>
            </router-link>
          </div>
          <div
            class="d-flex serial align-items-center justify-content-center flex-column"
          >
            <p class="text-center text-temperature">{{ book.book_number }}</p>
            <span
              v-tooltip.bottom="$t('book_status')"
              :class="[
                'badge mx-1',
                book?.status == 0
                  ? 'text-warning text-capitalize'
                  : book?.status == 1
                    ? 'text-primary text-capitalize'
                    : book?.status == 2
                      ? 'text-success text-capitalize'
                      : book?.status == 3
                        ? 'text-danger text-capitalize'
                        : book?.status == 4
                          ? 'text-danger text-capitalize'
                          : '',
              ]"
            >
              {{
                book?.status == 0
                  ? $t("pending")
                  : book?.status == 1
                    ? $t("tentative")
                    : book?.status == 2
                      ? $t("confirmed")
                      : book?.status == 3
                        ? $t("cancelled")
                        : book?.status == 4
                          ? $t("canceled")
                          : ""
              }}
            </span>
          </div>
          <div class="option_date">
            <p>{{ $t("option_date") }}</p>
            <p>
              {{ book?.client_option_date ? "--" : book?.client_option_date }}
            </p>
          </div>
          <div class="rooms mt-0 mb-0">
            <div
              v-for="(item, index) in book.service"
              :key="index"
              class="room d-block flex-row-reverse"
            >
              <!-- <span>{{ room.count }}</span> -->
              <div
                class="d-flex align-items-center text-center flex-wrap flex-column"
              >
                <!-- <i class="fa-solid fa-bed mx-1"></i> -->
                <div class="d-flex number_nights">
                  <p
                    class="text-center"
                    v-tooltip.left="
                      item?.service.title +
                      '\n ' +
                      item?.date +
                      ' / ' +
                      item?.time
                    "
                  >
                    {{ item.service?.title }}
                  </p>

                  <div class="d-flex align-items-center gap-1 mx-3">
                    <p
                      class="text-center text-primary"
                      v-tooltip.left="$t('supplier')"
                    >
                      {{ item?.supplier?.name }}
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center gap-1 mx-3">
                  <span>{{ $t("date") }} : </span>
                  <span>{{ item.date }}</span>
                  <span>{{ item.time }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex room_con flex-column">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
              style="line-height: 4"
            >
              <p v-tooltip.left="$t('client_name')">{{ book.client.name }}</p>
              <p v-tooltip.left="$t('created_at')">{{ book.created_at }}</p>
            </div>
          </div>

          <div class="cost d-flex justify-content-end gap-3">
            <div class="d-flex flex-column">
              <!-- <p class="total mb-0" v-tooltip.left="$t('total')">
                {{ book.price.total_selling_price }}
              </p> -->
              <div
                class="d-flex flex-column"
                :class="`${book.lock_status == 1 ? '' : 'd-none'}`"
              >
                <div
                  :class="`icons w-100 ${
                    book.lock_status == 1 ? ' text-white p-1 rounded ' : ''
                  }`"
                >
                  <p
                    class="paid d-flex text-primary align-items-center justify-content-center px-2"
                  >
                    {{ $t("POST") }}
                    <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                  </p>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center total_price w-50">
              <p class="total mb-0" v-tooltip.left="$t('total')">
                {{ book.price.total_selling_price }}
              </p>

              <!-- <p>{{ book.client_currency_code }}</p> -->
              <!-- <p class="total_company" v-tooltip.left="$t('total_without_tax')">
                {{ Math.round(book?.selling?.total) }}
              </p> -->
              <div
                :class="` ${
                  book.client_payment_status != 'PAID'
                    ? ' text-danger p-1 rounded mb-1'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                  :class="
                    book.client_payment_status == 'PAID'
                      ? 'text-success'
                      : book.client_payment_status == 'PARTIALLY_PAID'
                        ? 'text-primary'
                        : 'text-danger'
                  "
                >
                  {{
                    book.client_payment_status
                      ? $t(book.client_payment_status)
                      : ""
                  }}
                  <span>{{
                    book.payment_method == "Cash_on_delivery"
                      ? $t("Payment upon arrival")
                      : ""
                  }}</span>
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <span class="hint_element bank_hint">{{
                $t("total_company")
              }}</span>
              <span class="hint_element bank_hint">{{
                $t("total_client")
              }}</span>

              <div
                v-if="book.book_status == '1'"
                :class="`icons w-100 ${
                  book.book_status == '1'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("paid_ok") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '2'"
                :class="`icons w-100 ${
                  book.book_status == '2'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("Decline_payment") }}
                  <!-- <img src="@/assets/media/image/Vector.sfvg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '3'"
                :class="`icons  w-100 ${
                  book.book_status == '3' ? ' text-white p-1 rounded' : ''
                }`"
              >
                <p
                  class="paid text-warning d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND PENDING") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '4'"
                :class="`icons w-100 ${
                  book.book_status == '4'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Accept") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '5'"
                :class="`icons w-100 ${
                  book.book_status == '5'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Decline") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '6'"
                :class="`icons w-100 ${
                  book.book_status == '6'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("payment sent") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
            </div>

            <!--            <div class="d-flex flex-column align-items-center total_price w-50">-->
            <!--              <p class="total mb-0" v-tooltip.left="$t('total')">-->
            <!--                {{ book.total_selling_price }}-->
            <!--              </p>-->

            <!--              &lt;!&ndash; <p>{{ book.client_currency_code }}</p> &ndash;&gt;-->
            <!--              <p class="total_company" v-tooltip.left="$t('total_without_tax')">-->
            <!--                &lt;!&ndash; {{ book.organization_currency_code }} &ndash;&gt;-->
            <!--                {{ Math.round(book?.selling?.total) }}-->
            <!--              </p>-->
            <!--              <div-->
            <!--                :class="` ${-->
            <!--                  book.client_payment_status != 'PAID'-->
            <!--                    ? ' text-danger p-1 rounded mb-1'-->
            <!--                    : ''-->
            <!--                }`"-->
            <!--              >-->
            <!--                <p-->
            <!--                  class="paid d-flex align-items-center justify-content-center px-2"-->
            <!--                  :class="-->
            <!--                    book.client_payment_status == 'PAID'-->
            <!--                      ? 'text-success'-->
            <!--                      : book.client_payment_status == 'PARTIALLY_PAID'-->
            <!--                        ? 'text-primary'-->
            <!--                        : 'text-danger'-->
            <!--                  "-->
            <!--                >-->
            <!--                  {{ $t(book.client_payment_status) }}-->
            <!--                  <span>{{-->
            <!--                    book.payment_method == "Cash_on_delivery"-->
            <!--                      ? $t("Payment upon arrival")-->
            <!--                      : ""-->
            <!--                  }}</span>-->
            <!--                  &lt;!&ndash; <img src="@/assets/media/image/Vector.svg" alt="" /> &ndash;&gt;-->
            <!--                </p>-->
            <!--              </div>-->
            <!--              <span class="hint_element bank_hint">{{-->
            <!--                $t("total_company")-->
            <!--              }}</span>-->
            <!--              <span class="hint_element bank_hint">{{-->
            <!--                $t("total_client")-->
            <!--              }}</span>-->

            <!--              <div-->
            <!--                v-if="book.book_status == '1'"-->
            <!--                :class="`icons w-100 ${-->
            <!--                  book.book_status == '1'-->
            <!--                    ? 'bg-success text-white p-1 rounded'-->
            <!--                    : ''-->
            <!--                }`"-->
            <!--              >-->
            <!--                <p-->
            <!--                  class="paid d-flex align-items-center justify-content-center px-2"-->
            <!--                >-->
            <!--                  {{ $t("paid_ok") }}-->
            <!--                  &lt;!&ndash; <img src="@/assets/media/image/Vector.svg" alt="" /> &ndash;&gt;-->
            <!--                </p>-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-if="book.book_status == '2'"-->
            <!--                :class="`icons w-100 ${-->
            <!--                  book.book_status == '2'-->
            <!--                    ? 'bg-danger text-white p-1 rounded'-->
            <!--                    : ''-->
            <!--                }`"-->
            <!--              >-->
            <!--                <p-->
            <!--                  class="paid d-flex align-items-center justify-content-center px-2"-->
            <!--                >-->
            <!--                  {{ $t("Decline_payment") }}-->
            <!--                  &lt;!&ndash; <img src="@/assets/media/image/Vector.sfvg" alt="" /> &ndash;&gt;-->
            <!--                </p>-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-if="book.book_status == '3'"-->
            <!--                :class="`icons  w-100 ${-->
            <!--                  book.book_status == '3' ? ' text-white p-1 rounded' : ''-->
            <!--                }`"-->
            <!--              >-->
            <!--                <p-->
            <!--                  class="paid text-warning d-flex align-items-center justify-content-center px-2"-->
            <!--                >-->
            <!--                  {{ $t("REFUND PENDING") }}-->
            <!--                  &lt;!&ndash; <img src="@/assets/media/image/Vector.svg" alt="" /> &ndash;&gt;-->
            <!--                </p>-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-if="book.book_status == '4'"-->
            <!--                :class="`icons w-100 ${-->
            <!--                  book.book_status == '4'-->
            <!--                    ? 'bg-success text-white p-1 rounded'-->
            <!--                    : ''-->
            <!--                }`"-->
            <!--              >-->
            <!--                <p-->
            <!--                  class="paid d-flex align-items-center justify-content-center px-2"-->
            <!--                >-->
            <!--                  {{ $t("REFUND Accept") }}-->
            <!--                  &lt;!&ndash; <img src="@/assets/media/image/Vector.svg" alt="" /> &ndash;&gt;-->
            <!--                </p>-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-if="book.book_status == '5'"-->
            <!--                :class="`icons w-100 ${-->
            <!--                  book.book_status == '5'-->
            <!--                    ? 'bg-danger text-white p-1 rounded'-->
            <!--                    : ''-->
            <!--                }`"-->
            <!--              >-->
            <!--                <p-->
            <!--                  class="paid d-flex align-items-center justify-content-center px-2"-->
            <!--                >-->
            <!--                  {{ $t("REFUND Decline") }}-->
            <!--                  &lt;!&ndash; <img src="@/assets/media/image/Vector.svg" alt="" /> &ndash;&gt;-->
            <!--                </p>-->
            <!--              </div>-->
            <!--              <div-->
            <!--                v-if="book.book_status == '6'"-->
            <!--                :class="`icons w-100 ${-->
            <!--                  book.book_status == '6'-->
            <!--                    ? 'bg-success text-white p-1 rounded'-->
            <!--                    : ''-->
            <!--                }`"-->
            <!--              >-->
            <!--                <p-->
            <!--                  class="paid d-flex align-items-center justify-content-center px-2"-->
            <!--                >-->
            <!--                  {{ $t("payment sent") }}-->
            <!--                  &lt;!&ndash; <img src="@/assets/media/image/Vector.svg" alt="" /> &ndash;&gt;-->
            <!--                </p>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="actions">
            <router-link
              class="btn btn-sm btn-success"
              :to="`/hotels/book_offline/${book.id}/history`"
              v-tooltip="$t('History')"
            >
              <i class="fa-solid fa-clock-rotate-left"></i>
            </router-link>
            <!-- <router-link
              class="details_id btn btn-sm btn-primary"
              :to="`/hotels/book_offline/${book.id}`"
              v-tooltip="$t('show_details')"
              style="width: max-content !important"
            >
              <i class="fa fa-eye"></i>
            </router-link> -->
            <router-link
              class="details_id btn btn-sm btn-secondary"
              :to="`/${
                invoice_theme == 'IN01'
                  ? 'book_table'
                  : invoice_theme == 'IN02'
                    ? 'invoice'
                    : 'book_table'
              }/${book.id}/${
                book?.book_offline_status == 0
                  ? 'option-date'
                  : book?.book_offline_status == 1
                    ? 'price-offer'
                    : book?.book_offline_status == 2
                      ? 'pill-confirm'
                      : book?.book_offline_status == 3
                        ? 'cancelation'
                        : 'price-offer'
              }`"
              v-tooltip="$t('invoices')"
              style="width: max-content !important"
            >
              <i class="fa-solid fa-file-invoice"></i>
            </router-link>

            <!-- <router-link
              class="details_id btn btn-sm btn-primary"
              :to="`/tax-invoice-ar/${book.base_booking_id}`"
              v-tooltip="$t('TAX INVOICE') + ' EN'"
              style="width: max-content !important"
              v-if="book.lock_status == 'posted'"
            >
              <i class="fa-solid fa-file-invoice"></i>
            </router-link>


            <router-link
              class="details_id btn btn-sm btn-primary"
              :to="`/tax-invoice-ar/${book.base_booking_id}`"
              v-tooltip="$t('TAX INVOICE') + ' AR'"
              style="width: max-content !important"
              v-if="book.lock_status == 'posted'"
            >
              <i class="fa-solid fa-file-invoice"></i>
            </router-link> -->

            <router-link
              class="details_id btn btn-sm btn-info"
              :to="`/hotels/book_offline/${book.base_booking_id}/payment/${book.id}/${book?.book_offline_status}`"
              v-tooltip="$t('paid')"
              style="width: max-content !important"
            >
              <i class="fa-solid fa-money-bill-1-wave"></i>
            </router-link>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <div class="row">
    <pagination
      :data="offlineServices"
      class="mx-auto d-flex align-items-center justify-content-center pagination"
      @pagination-change-page="fetchOfflineService"
    >
      <template v-slot:prev-nav>&lt;</template>
      <template v-slot:next-nav>&gt;</template>
    </pagination>
  </div>
</template>

<style scoped lang="scss">
@import "@/view/dashboard/hotelsBookOffline/_hotelsBookOffline.scss";
@import "@/assets/scss/_variables.scss";

.text-primary {
  font-size: 0.8rem !important;
}
.text-temperature {
  text-align: center !important;
}
.postToAccount {
  width: 10%;
}
.SecondButton {
  height: unset !important;
}
.total_price {
  width: 10%;
}
.book_search {
  position: relative;
  margin-bottom: unset !important;
  i {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  input {
    width: 100%;
    padding-right: unset !important;
    padding-right: 40px !important;
  }
}
.add_book.PrimaryButton {
  margin: unset !important;
}
</style>
