<template>
  <section class="show_receipt card_style">
    <div class="main_header_style"></div>
    <div class="d-flex align-items-center mb-2 gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{ $t("car_type") }}
      </h4>
    </div>
    <div class="row options mb-2">
      <div class="col-lg-10 col-md-12 col-12">
        <div class="form-group">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            :placeholder="$t('search by name')"
            v-model="typeName"
            class="form-control w-50"
            @keyup="FetchTypes((page = 1))"
          />
        </div>
      </div>

      <div class="col-lg-2 col-md-12 col-12">
        <router-link
          :to="{ name: 'carTypeAdd' }"
          class="PrimaryButton btn cartype"
          ><i class="fa-solid fa-plus"></i>
          {{ $t("New Car type") }}</router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table cartype">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("car_name") }}</th>
              <th>{{ $t("total_number") }}</th>
              <!-- <th>{{ $t("number") }}</th> -->
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 4" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in Types" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.max_number }}</td>

              <td>
                <router-link
                  class="btn btn-success mx-1 w-auto"
                  :to="`/car_type/edit/${item.id}`"
                  :title="$t('edit')"
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>

                <button
                  class="btn btn-danger mx-1 w-auto"
                  @click="DeleteAlert(index)"
                >
                  <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="Types"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchTypes"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "show-nation",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      Types: [],
      typeName: "",
      loading: true,
    };
  },
  methods: {
    // fetch Types data
    FetchTypes(page = 1) {
      axios
        .post(`/fetch_car_types?page=${page}`, { word: this.typeName })
        .then(({ data }) => {
          this.Types = data.data.data;
          this.loading = false;
        });
    },
    //delete expness
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.Types[index].id;
          const fromClick = true;
          axios.delete("/car_types/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.Types.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.FetchTypes();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style lang="scss" scoped>
// @import "./_cartype.scss";
.form-group {
  position: relative;
  i {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.options {
  a.btn {
    margin-top: 0;
  }
}
</style>
