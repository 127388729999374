<template>
  <!-- {{ additional_services }} -->
  <div class="Transformation_seeting">
    <div class="container">
      <div class="row border">
        <div class="col-lg-8 col-md-8 col-6">
          <div class="d-flex align-items-center justify-content-start p-2">
            <div class="d-flex align-items-center justify-content-center mx-1">
              <img src="@/assets/media/image/icon/user-octagon.svg" />
              <h6 class="hotel_settings m-0">
                {{ $t("Added Services") }}
              </h6>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-6 border-start border-end bg-price d-flex align-items-center justify-content-center"
        >
          <div class="d-flex align-items-center justify-content-start p-2">
            <div class="d-flex align-items-center justify-content-center mx-1">
              <img src="@/assets/media/image/icon/moneys.svg" />
              <h6 class="hotel_settings m-0">{{ $t("prices") }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row border-start border-end py-2"
        v-for="(service, index) in servicesData"
        :key="index"
      >
        <div class="col-12 trip d-flex align-items-center">
          <i class="fa-regular fa-user"></i>
          <p class="first_diriction mx-1 mb-0">{{$t("service")}} {{ index + 1 }}</p>
        </div>

        <div class="col-lg-8 col-md-8 col-12">
          <div class="row">
            <div class="col-lg-5 col-md-6 col-11">
              <label class="typo__label custom_label">{{
                $t("service_name")
              }}</label>
              <Multiselect
                v-model="service.serviceObj"
                :options="services"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
              ></Multiselect>
            </div>

            <div class="col-lg-5 col-md-5 col-11">
              <label class="typo__label custom_label">{{
                $t("Supplier")
              }}</label>
              <Multiselect
                v-model="service.providerObj"
                :options="providers"
                label="name"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
              ></Multiselect>
            </div>

            <div
              class="col-lg-2 col-md-1 col-1 d-flex align-items-center justify-content-center"
              v-if="index == 0"
            >
              <i
                @click="addTableRow()"
                class="fa-solid fa-plus custom_btn_add"
              ></i>
            </div>
            <div
              class="col-lg-2 col-md-1 col-1 d-flex align-items-center justify-content-center"
              v-else
            >
              <i
                @click="removeTableRow(index)"
                class="fa-solid fa-minus custom_btn_remove"
              ></i>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4 col-12">
          <div class="row">
            <div class="d-flex align-items-center justify-content-start">
              <!-- <span class="mt-4 mx-1 font-size-text">{{ $t("service") }} {{ index + 1 }}</span> -->
              <div class="mx-2">
                <label class="typo__label custom_label m-0">{{
                  $t("cost")
                }}</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder=""
                  v-model="service.cost"
                />
              </div>
              <div class="mx-2">
                <label class="typo__label custom_label m-0">{{
                  $t("selling")
                }}</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder=""
                  v-model="service.selling"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Multiselect } from "vue-multiselect";
import axios from "axios";

export default {
  name: "hotelSettings",
  data() {
    return {
      services: [],
      servicesData: [
        {
          providerObj: {},
          serviceObj: {},
          cost: null,
          selling: null,
        },
      ],
    };
  },
  props: ["additional_services" , "providers"],
  emits: ["saveServicesData"],
  components: {
    Multiselect,
  },
  watch: {
    servicesData: {
      deep: true,
      handler(newValue) {
        let service = [];
        newValue.forEach((ele) => {
          service.push({
            additional_service_id: ele.serviceObj.id,
            supplier_id: ele.providerObj.id,
            cost: ele.cost,
            selling: ele.selling,
          });
        });
        this.$emit("saveServicesData", service);
      },
    },
    additional_services:{
      deep: true,
      immediate: true,
      handler(newValue) {
        if (this.$route.params.id && newValue.length > 0) {
          this.servicesData = [];
          newValue.forEach((element) => {
            this.servicesData.push({
              providerObj: element.supplier,
              serviceObj: element.additional_service,
              cost: element.cost,
              selling: element.selling,
            });
          });
        }
      }
    }
  },
  methods: {
    addTableRow() {
      this.servicesData.push({
        providerObj: {},
        serviceObj: {},
        cost: null,
        selling: null,
      });
    },
    removeTableRow(index) {
      this.servicesData.splice(index, 1);
    },
    fetch_services(searchTerm) {
      axios
        .post("/fetch_additional_services", { word: searchTerm })
        .then(({ data }) => {
          this.services = data.data.data;
        });
    },
  },
  mounted() {
    this.fetch_services();
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}
.Transformation_seeting {
  background-color: #fff;
  .title {
    font-family: "bold";
    color: #0b1427;
    font-size: 1.25rem;
  }
  .flight_num {
    color: #656b78;
    font-family: "regular";
    font-size: 0.8rem;
  }
  .flight-details {
    color: #0b1427;
    font-size: 1.2rem;
    font-family: "regular";
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
    width: min-content;
  }
  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }
  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }
  .hotel_settings {
    font-family: "bold";
    color: #0b1427;
    font-size: 1rem;
  }
  .subtitle-hotel-settings {
    color: #0b1427;
    font-size: 1rem;
  }

  .custom_label {
    font-size: 0.6875rem;
    font-family: "bold";
    color: #0b1427;
    position: relative;
    z-index: 22;
    // bottom: 5.5rem; //4.2rem
    top: 0.675rem;
    background: white;
    border-radius: 50%;
    text-align: center;
    color: #656b78;
    margin: 0 0.6rem;
  }

  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
  }
  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }
  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }
}

.bg-wheate {
  background-color: #fdfbf8;
}

.bg-light-blue {
  background-color: rgba($color: #1664b8, $alpha: 3%);
}

.bg-price,
.adult {
  background-color: rgba($color: #0a909b, $alpha: 10%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap-4 {
  gap: 4.5rem !important;
}

.time {
  color: #0b1427;
  font-family: "bold";
  font-size: 1rem;
}
.number-available {
  border: 1px solid gray;
  width: fit-content;
  padding: 0 0.8rem;
  border-radius: 20px;
}
.live {
  color: #0a909b;
  font-family: "bold";
  font-size: 0.875rem;
}

// input[type="number"]::-webkit-outer-spin-button,
// input[type="number"]::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// input[type="number"] {
//   width: 50px;
// }

span {
  font-size: 0.875rem;
}
.custom_btn_remove {
  border: 1px solid red;
  border-radius: 50%;
  color: red;
  padding: 0.5rem;
  cursor: pointer;
}
.custom_btn_add {
  border: 1px solid #0a909b;
  border-radius: 50%;
  color: #0a909b;
  padding: 0.5rem;
  cursor: pointer;
}

.first_direction,
.second_direction {
  font-size: 0.8rem;
  font-family: "bold";
  color: #656b78;
}
.d-content {
  display: contents;
}
.trip{
  i{
    width: 15px;
  }
  p{
    font-size: 1rem;
    font-family: "bold";
    color: #0b1427;
  }
}
</style>
