<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-12 mb-4">
      <div class="form-group">
        <label for="">{{ $t("choose_country") }}</label>
        <!-- v-model="Form.country_id" -->
        <Multiselect
          v-model="countryValues"
          @update:model-value="emitRadioSelected"
          :options="countries.map((country) => country.id)"
          :custom-label="(opt) => countries.find((x) => x.id == opt).title"
          :clear-on-select="true"
          :placeholder="$t('choose_country')"
          select-label=""
          :multiple="true"
          :hide-selected="true"
        >
        </Multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from "vue-multiselect";

export default {
    name: "Country-custom",
    components: {
      Multiselect  
    },
    data() {
        return {
          countries: [],
          countryValues: [],
        }
    },
    methods: {
      //fetch country
      fetch_country() {
          axios.post("/fetch_countries").then(({ data }) => {
              this.countries = data.data;
              // console.log(this.countries);
          });
      },

      emitRadioSelected() {
        this.$emit('multiselect-changed', this.countryValues);
      },
    },
    created() {
        this.fetch_country();
    }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss" scoped>
    @import "../../../view/setup/_setup.scss";
</style>