<template>
  <div class="bookCount bg-white p-3">
    <div class="container">
      <div class="row d-flex align-items-center jucstify-content-center">
        <div class="col-12">
          <div class="d-flex gap-3 mb-3">
            <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
            <h4>{{ $t("Book Count") }}</h4>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group mb-1">
            <h5>{{ $t("points") }}</h5>
            <input
              type="number"
              min="0"
              v-model="book.points"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.book.points.$error"
            v-text="v$.book.points.$errors[0].$message"
          ></span>
        </div>

        <div
          class="col-lg-1 d-flex align-items-end justify-content-center text-center"
        >
          <i class="fa-solid fa-arrow-right fs"></i>
        </div>

        <div class="col-lg-4">
          <div class="form-group mb-1">
            <h5>{{ $t("Book Count") }}</h5>
            <input
              type="number"
              min="0"
              v-model="book.counts"
              class="form-control"
            />
          </div>
          <span
            class="text-danger"
            v-if="v$.book.counts.$error"
            v-text="v$.book.counts.$errors[0].$message"
          ></span>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-lg-4">
          <div class="form-group">
            <h5>{{ $t("expired_date") }}</h5>
            <Datepicker class="w-100" v-model="book.selectedDate" />
            <span
              class="text-danger"
              v-if="v$.book.selectedDate.$error"
              v-text="v$.book.selectedDate.$errors[0].$message"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <button @click="save" type="submit" class="add btn PrimaryButton">
      {{ $t("save") }}
    </button>
  </div>
</template>

<script>
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  data() {
    return {
      v$: useVuelidate(),
      book: {
        counts: 0,
        points: 0,
        selectedDate: moment(new Date()).format("YYYY-MM-DD"),
      },
    };
  },
  validations() {
    return {
      book: {
        counts: {
          required,
        },
        points: {
          required,
        },
        selectedDate: {
          required,
        },
      },
    };
  },
  components: { Datepicker: VueDatepickerUi, AddFavDailog },
  methods: {
    save() {
      this.v$.$validate();
      if (!this.v$.$error) {
        axios
          .post(`/affiliate_settings`, this.book)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bookCount {
  width: 100%;
  height: 100dvh;
}
input.form-control {
  padding: 0.8rem !important;
}

.fs {
  font-size: 2rem;
}
</style>
