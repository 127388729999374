<template>
  <section class="all_emp card_style">
    <div class="row mb-3">
      <div class="col-lg-4 col-12 d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>
          {{ $t("Hotel_reservation_revenues") }}
        </h4>
      </div>
      <form class="col-lg-6 col-md-9 col-12 d-flex align-items-center">
        <input
          type="text"
          v-model="hotelText"
          class="form-control"
          @keyup="BookingProfitFunction((page = 1))"
          :placeholder="$t('search...')"
        />
      </form>
      <!-- print & excel -->
      <div class="col-lg-2 col-md-3 col-12 d-flex justify-content-end align-items-center">
        <button
          class="SecondButton btn action d-flex align-items-center mx-2"
          @click="exportToExcel"
        >
          <i class="fa-solid fa-file-excel"></i>
          <div
            class="img-border d-flex align-items-center justify-content-center"
          >
            <img src="@/assets/media/image/fi_7268609.svg" alt="" />
          </div>
        </button>
        <div id="print-button" class="print_button">
          <button
            class="SecondButton btn action d-flex align-items-center"
            v-print="`#booking_profit_table`"
          >
            <img src="@/assets/media/image/fi_7180363.svg" alt="" />
            <div
              class="img-border d-flex align-items-center justify-content-center"
            >
              <img src="@/assets/media/image/fi_7268609.svg" alt="" />
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table visaType" id="booking_profit_table">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("booking date") }}</th>
            <th>{{ $t("Check In") }}</th>
            <th>{{ $t("Check Out") }}</th>
            <th style="width: 150px">{{ $t("confirmation_number") }}</th>
            <th>{{ $t("final_price") }}</th>
            <th>{{ $t("organization_price") }}</th>
            <th>{{ $t("original_price") }}</th>
            <th>{{ $t("payment_profit") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center table_loader">
          <tr v-for="profit in 10" :key="profit">
            <td v-for="profit in 9" class="text-center" :key="profit">
              <Skeleton width="90%" height="2rem" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(profit, index) in BookingProfit.data" :key="index">
            <td>
              <router-link :to="`/hotel-view/${profit.id}`">{{
                profit.id
              }}</router-link>
            </td>
            <td>{{ profit.created_at }}</td>
            <td>{{ profit.checkin }}</td>
            <td>{{ profit.checkout }}</td>
            <td>{{ profit.confirmation_number }}</td>
            <td>{{ profit.final_price }}</td>
            <td>
              <span class="text-success">{{ profit.final_booking_price }}</span>
            </td>
            <td>{{ profit.original_price }}</td>
            <td>{{ profit.payment_profit }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="BookingProfit"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="BookingProfitFunction"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
// import "jquery/dist/jquery.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
import * as XLSX from "xlsx/xlsx.mjs";

// import $ from "jquery";
export default {
  name: "allVisa",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      BookingProfit: [],
      hotelText: "",
      loading: true,
      print_booking: [],
    };
  },
  methods: {
    BookingProfitFunction(page = 1) {
      axios
        .post(`/fetchBookingProfit?page=${page}`, { word: this.hotelText , per_page: 15 })
        .then(({ data }) => {
          this.BookingProfit = data.data;
          // console.log(this.BookingProfit);
          this.loading = false;
        });
    },
    exportToExcel() {
      this.loading = true;
      axios.post(`fetchBookingProfit`).then(({data})=>{
        let responseData = data.data.data;
        const headers = Object.keys(responseData[0]);

        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "booking_profit.xlsx");
        this.loading = false;
      }).catch((error)=>{
        console.error("Error fetching data:", error);
        this.loading = false;
      })
    },
  },
  mounted() {
    this.BookingProfitFunction();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "_bookingprofit";
</style>
