<template>
  <section>
    <div class="container">
      <div class="main-parnt">
        <div id="print-button" class="print_button">
          <button class="PrimaryButton btn" @click="printContent">Print</button>
        </div>

        <div class="row mt-5">
          <div class="col-lg-9 col-12">
            <div class="specific-section">
              <div class="row">
                <div class="col-lg-6 col-12">
                  <div class="logo">
                    <img src="@/assets/media/image/logo.png" alt="LOGO" />
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="main-text">
                    <div class="text-logo">
                      <h4>AMENDMENT</h4>
                      <p>{{ pills.id }}</p>
                    </div>
                    <div class="next-text">
                      <h5 v-if="pills.hotels">{{ pills.hotels.title }}</h5>
                      <h6 v-if="pills.hotels">
                        {{ pills.hotels.city }} - {{ pills.hotels.country }}
                      </h6>
                      <h6>Phone:</h6>
                      <h6>FAX:</h6>
                      <h6>MADINAH - SAUDI ARABIA</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="under-text">
                <div class="head">
                  <h4>Date : {{ pills.date }}</h4>
                  <h4>To : {{ pills.date }}</h4>
                  <h4>Dear Sir : {{ pills.guest_name }}</h4>
                  <h5>
                    Greeting From <span>Soft tech travel and tourism</span>
                  </h5>
                  <div class="pragra">
                    <p>
                      First of All, We would like to take this opportunity to
                      welcome you at Soft Tech travel and tourism We are pleased
                      to confirm the following reservation on a
                      <span>definite basis.</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="main-list">
                <div class="list-one">
                  <div class="right">
                    <h4>Guest Name</h4>
                    <h4>Hotel Name</h4>

                    <!-- <h4>Mail</h4> -->
                  </div>
                  <div class="left">
                    <h5 v-if="pills.hotels">: {{ pills.hotels.title }}</h5>
                    <h5>: {{ pills.guest_name }}</h5>
                    <!-- <h5>: booking@madatouris.com</h5> -->
                  </div>
                </div>
                <div class="list-tow">
                  <div class="right-1">
                    <h4>REF #</h4>
                  </div>
                  <div class="left-1">
                    <h5>: {{ pills.client_name }}</h5>
                  </div>
                </div>
              </div>
              <div
                class="table-responsive"
                v-if="pills.rooms && pills.rooms.length > 0"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th class="frist">ROOM TYPE</th>
                      <!-- <th scope="col">MEAL</th> -->
                      <th scope="col">VIEW</th>
                      <th scope="col">QTY</th>
                      <th scope="col">PRICE PER NIGHT</th>
                      <th scope="col">MEAL</th>
                      <th scope="col">CHECK IN</th>
                      <th scope="col">CHECK OUT</th>
                      <th class="last">TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(room, index) in pills.rooms" :key="index">
                      <td class="frist">{{ room.view_type }}</td>
                      <!-- <td>  {{ room.view_type }}  </td> -->
                      <td>{{ pills.check_in }}</td>
                      <td>{{ pills.check_out }}</td>
                      <td>{{ room.days.length }}</td>
                      <td>{{ room.number_of_beds }}</td>
                      <td>{{ pills.reference_number }}</td>
                      <td></td>
                      <td class="last">{{ pills.total_selling_price }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <h2>there is no room</h2>
              </div>
              <div class="mide-list">
                <ul>
                  <li>
                    <h3>Net Accommodation Charge</h3>

                    <h4>{{ pills.price_model.total_selling_price }}</h4>
                  </li>
                  <li>
                    <h3>VAT Charge</h3>
                    <h4>{{ pills.price_model.selling_price_tax }}</h4>
                  </li>
                  <div class="last-li">
                    <li>
                      <h3>
                        {{ pills.price_model.total_selling_price_with_tax }}
                      </h3>
                    </li>
                  </div>
                </ul>
              </div>
              <div class="last-sec">
                <!-- <div class="header">
            <h4>Hotel Details</h4>
          </div> -->
                <!-- <div class="second-header">
            <h4>Bank Details</h4>
          </div> -->
                <!-- <div class="compar">
                  <div class="list-one">
                    <div class="right">
                      <h4>Bank name</h4>
                      <h4>Account Name</h4>
                      <h4>IBAN</h4>
                      <h4>Children</h4>
                      <h4>Check In</h4>
                    </div>
                    <div class="left">
                      <h5>: alreajhey</h5>
                      <h5>: Wateen Bander AlRehili</h5>
                      <h5>: SA6580000228608016034270</h5>
                      <h5>: Two children under 6 years are allowed.</h5>
                      <h5>: {{ pills.check_in }}</h5>
                    </div>
                  </div>
                  <div class="list-1">
                    <div class="right-1">
                      <h4>Account number:</h4>
                      <h4>Swift Code: <span> 12456</span></h4>
                      <h4>
                        Check Out:<span> {{ pills.check_out }}</span>
                      </h4>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="center">
                <div class="center-text">
                  <h4>jiwar assafwa</h4>
                  <h5>reservation</h5>
                </div>
              </div>
              <div class="end-page">
                <div class="hed">
                  <h4>Terms & Conditions</h4>
                  <p>
                    Check in time at:{{ pills.check_in }} any early arrival
                    subject to availability. Check out time at:{{
                      pills.check_out
                    }}
                    , after {{ pills.check_out }} one night will be charged. To
                    guarantee your booking total amount to be transfer to our
                    Account. before option date mentioned in the booking In case
                    of guarantee cancellation full payment will be charged.
                  </p>
                </div>
              </div>
              <div class="lastes">
                <h4>Travel Soft - VAT NO : {{ pills.id }}</h4>
                <h4>{{ pills.check_in }} - BOOKING NO : {{ pills.id }}</h4>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <ul>
              <li>
                <router-link :to="{ name: 'invoicesFirst' }">
                  AMENDMENT
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "pill-hotel",
  data() {
    return {
      pills: {
        price_model: {},
        guests: [
          {
            nationality: {},
          },
        ],
        rooms: [],
      },
      bookId: null,
    };
  },

  methods: {
    fetchPayments() {
      axios
        .post("/fetch_booking_offline_details", {
          booking_id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.pills = data.data;
          console.log(this.pills);
        });
    },
    calculateTotalCost(days) {
      return days.reduce((total, day) => total + day.cost, 0);
    },
    calculateTotalSellingPrice(days) {
      return days.reduce((total, day) => total + day.selling_price, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main-parnt {
  margin: 1rem;
  border-radius: 8px;
  background-color: #fff;
  padding: 2rem;
  direction: ltr;
  position: relative;
}
.badge_sec {
}
.text {
}
.text-logo {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.342);
  margin-bottom: 3%;
  h4 {
    font-family: "bold";
    font-size: 1.2rem;
    text-transform: capitalize;
  }
  p {
    font-family: "bold";
    font-size: 1.2rem;
    margin-inline-start: 5%;
  }
  flex-wrap: wrap;
}

.next-text h5 {
  color: #0a909b;
  text-align: start;
}
.next-text h4 {
  color: #656b78;
  text-align: start;
}
.next-text span {
  color: #0a909b;
  text-align: start;
}
.next-text h6 {
  // width: 53%;
  text-align: start;
}


@media print {
  .next-text {
    h6, h5, h4, span {
      text-align: start;
    }
  }
}

.head h5 {
  color: #656b78;
  font-size: 1rem;
}
.head span {
  color: #0a909b;
}
.pragra p {
  font-size: 1rem;
  width: 76%;
  color: #656b78;
}
.pragra span, .pragra strong {
  font-size: 1rem;
  font-family: bold;
  color: #0a909b;
  text-transform: capitalize;
}
.list-one {
  display: flex;
  gap: 15px;
  align-items: center;
}
.list-tow {
  display: flex;
  gap: 78px;
  align-items: center;
}
.main-list {
  display: flex;
  gap: 207px;
  flex-wrap: wrap;
  direction: ltr;
}
.right h4 {
  color: #656b78;
  font-size: 1rem;
}
.right-1 h4 {
  color: #656b78;
  font-size: 1rem;
}
thead tr th {
  background-color: #0a8f9b38 !important;
  color: #656b78 !important;
}
.frist {
  border-left: 1px solid rgba(0, 0, 0, 0.164);
}
.last {
  border-right: 1px solid rgba(0, 0, 0, 0.164);
}
li {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 61px;
  border: 1px solid #0000002e;
  padding: 0.5rem 0.5rem;
  flex-wrap: wrap;
}
.last-li {
  background-color: #cee9eb;
  width: 20%;
  display: block;
  margin-inline-start: auto;
}
.last-li h3 {
  font-size: 1.2rem;
  color: #656b78;
}
.mide-list h3 {
  font-size: 1.2rem;
}
.mide-list h4 {
  font-size: 1.2rem;
  color: #656b78;
}
.header h4 {
  color: #656b78;
  font-size: 1rem;
}
.compar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  direction: ltr;
  margin: 2% 0;
  .list-one,
  .list-1 {
    border: 1px solid #0000002e;
    padding: 2%;
    border-radius: 10px;
    h4 {
      font-size: 1rem;
      color: #656b78;
      margin-bottom: 10px;
      span {
        font-family: "bold";
        font-size: 1.25rem;
      }
    }
    h5 {
      font-family: "bold";
    }
  }
}
.compar span {
  color: black;
  margin-inline-start: 46px;
}
.center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  @media print {
    justify-content: flex-start;
  }
}
.center-text {
  // border-left: 1px solid #00000033;
  padding: 0.5rem;
}
.center-text h4 {
  color: #0a909b;
  font-family: bold;
}
.end-page h4 {
  color: #656b78;
}
.end-page p {
  font-size: 1.5rem;
  color: #656b78;
  margin-bottom: 3rem;
}
.lastes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  direction: ltr;
}
.lastes h4 {
  color: #656b78;
  font-size: 1rem;
  font-family: "light";
}
.header {
  margin-bottom: 2rem;
}
.second-header {
  margin-bottom: 2rem;
}
.under-text {
  direction: ltr;
  margin-top: 1rem;
  .head {
    border-bottom: 1px solid #000;
    margin-bottom: 1rem;
  }
  h4 {
    font-size: 1rem;
  }
}
.pragra {
  //   text-align: end;
}
.table-responsive {
  direction: ltr;
  margin-top: 3%;
}
.mide-list {
  direction: ltr;
  margin-top: 1%;
}
.hed {
  direction: ltr;
  margin: 3% 0;
  h4 {
    font-size: 1rem;
    color: #101010;
    font-family: "bold";
  }
  p {
    font-size: 1rem;
    font-family: "regular";
  }
}
.main-text {
  direction: ltr;
}
.last-sec {
  direction: ltr;
  h2 {
    text-align: start;
    @media print {
      text-align: end !important;
    }
  }
}

@media print {
  #print-button {
    display: none;
  }
}
</style>
