<template>
  <section class="card_style">
    <!-- skelton -->
    <div class="row" v-if="loading && $route.params.id">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <Skeleton shape="circle" size="2rem" class="mx-2"></Skeleton>
          <Skeleton width="10%" class="mb-2"></Skeleton>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 my-3" v-for="i in 2" :key="i">
        <Skeleton width="20%" class="my-2"></Skeleton>
        <Skeleton width="70%" height="2rem" class="my-2"></Skeleton>
      </div>
      <div class="col-12">
        <Skeleton width="20%" class="my-2"></Skeleton>
        <Skeleton width="70%" height="2rem" class="my-2"></Skeleton>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 my-3" v-for="i in 6" :key="i">
        <Skeleton width="20%" class="my-2"></Skeleton>
        <Skeleton width="70%" height="2rem" class="my-2"></Skeleton>
      </div>
      <div class="col-12">
        <Skeleton width="20%" class="my-2"></Skeleton>
        <Skeleton width="70%" height="2rem" class="my-2"></Skeleton>
      </div>
    </div>
    <!-- skelton -->

    <form @submit.prevent="addCoupon()" v-else>
      <div class="main_header_style">
        <div class="d-flex align-items-center gap-3">
          <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
          <h4 v-if="this.$route.params.id != undefined" class="mb-0">
            {{ $t("edit coupon") }}
          </h4>
          <h4 class="mb-0" v-else>
            {{ $t("add coupon") }}
          </h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="form-group">
            <label class="form-label" for="title">{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingName">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseName"
                    aria-expanded="false"
                    aria-controls="collapseName"
                  >
                    {{ $t("coupon name") }}
                  </button>
                </h2>
                <div
                  id="collapseName"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingName"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(coupon, index) in couponNames"
                      :key="index"
                    >
                      <label :for="`name${index}`"
                        >{{ $t("coupon name") }} ({{ coupon.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="coupon.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.couponNames.$error">{{
              v$.couponNames.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <div class="form-group">
            <label class="form-label">{{ $t("nationality") }}</label>
            <Multiselect
              v-model="nationalityValue"
              :options="nationalities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_nationality')"
              select-label=""
              :hide-selected="true"
              @keyup="searchNationality"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.nationalityValue.id.$error">{{
              v$.nationalityValue.id.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <div class="form-group">
            <label class="form-label" for="image"
              >{{ $t("photo") }}(500*500)</label
            >
            <input
              type="file"
              id="imageEntry"
              class="form-control"
              @change="onFileChange"
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
          <img :src="imageEntry" class="w-25" v-if="$route.params.id" />
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <div class="form-group">
            <label class="form-label" for="image"
              >{{ $t("photo") }}(1280*600)</label
            >
            <input
              type="file"
              id="image_Entry"
              class="form-control"
              @change="onFile_Change"
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
          <img :src="image_Entry" class="w-25" v-if="$route.params.id" />
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="couponStatus"
              id="couponStatus1"
              value="0"
              v-model="coupon_status"
            />
            <label class="form-check-label" for="couponStatus1">
              {{ $t("active") }}
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="couponStatus"
              id="couponStatus2"
              value="1"
              v-model="coupon_status"
            />
            <label class="form-check-label" for="couponStatus2">
              {{ $t("disactive") }}
            </label>
          </div>
          <span class="input-errors" v-if="v$.coupon_status.$error">{{
            v$.coupon_status.$errors[0].$message
          }}</span>
        </div>
        <div class="col-lg-12 col-12">
          <div class="form-group">
            <label for="description">{{ $t("description") }}</label>

            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingDesc">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseDesc"
                    aria-expanded="false"
                    aria-controls="collapseDesc"
                  >
                    {{ $t("description") }}
                  </button>
                </h2>
                <div
                  id="collapseDesc"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingDesc"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(desc, index) in description"
                      :key="index"
                    >
                      <label :for="`desc${index}`"
                        >{{ $t("description") }} ({{ desc.lang }})</label
                      >
                      <textarea
                        class="form-control"
                        :placeholder="$t('description')"
                        :id="`desc${index}`"
                        v-model="desc.name"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <span class="input-errors" v-if="v$.description.$error">{{v$.description.$errors[0].$message}}</span> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="coupon_type">{{ $t("coupon_type") }}</label>
            <Multiselect
              v-model="coupon_type_value"
              :options="coupon_type.map((statu) => statu.id)"
              :custom-label="
                (opt) => coupon_type.find((x) => x.id == opt).title
              "
              :clear-on-select="true"
              :placeholder="$t('choose coupon type')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.coupon_type_value.$error">{{
              v$.coupon_type_value.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 col-12"
          v-if="
            coupon_type_value.includes(1) || coupon_type_value.includes('1')
          "
        >
          <div class="form-group">
            <label for="city">{{ $t("city") }}</label>
            <Multiselect
              v-model="cities_value"
              :options="cities"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose city')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
              @keyup="searchCity"
              @update:model-value="fetchHotelsByCityId"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.cities_value[0].id.$error">{{
              v$.cities_value[0].id.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 col-12"
          v-if="
            coupon_type_value.includes(1) || coupon_type_value.includes('1')
          "
        >
          <div class="form-group">
            <label for="hotel">{{ $t("hotel") }}</label>
            <Multiselect
              v-model="hotel_value"
              :options="hotels"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('Choose_hotel')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.hotel_value[0].id.$error">{{
              v$.hotel_value[0].id.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 col-12"
          v-if="
            coupon_type_value.includes(2) || coupon_type_value.includes('2')
          "
        >
          <div class="form-group">
            <label class="form-label">{{ $t("flight company") }}</label>
            <Multiselect
              v-model="company_values"
              :options="companies"
              label="name"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose flight company')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
              @keyup="searchCompany"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.company_values[0].id.$error">{{
              v$.company_values[0].id.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 col-12"
          v-if="
            coupon_type_value.includes(5) || coupon_type_value.includes('5')
          "
        >
          <div class="form-group">
            <label class="form-label">{{ $t("packages") }}</label>

            <Multiselect
              v-model="package_value"
              :options="all_packages"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose package')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.package_value[0].id.$error">{{
              v$.package_value[0].id.$errors[0].$message
            }}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <label class="form-label" for="value_type">{{
            $t("value_type")
          }}</label>
          <Multiselect
            v-model="value_type"
            :options="valueTypes"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_value_type')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
          <span class="input-errors" v-if="v$.value_type.id.$error">{{
            v$.value_type.id.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-4 col-md-6 col-12">
          <label class="form-label" for="value">{{ $t("value") }}</label>
          <input
            type="number"
            class="form-control"
            placeholder="0"
            v-model="value"
          />
          <span class="input-errors" v-if="v$.value.$error">{{
            v$.value.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-4 col-md-6 col-12">
          <label class="form-label" for="code">{{ $t("Code") }}</label>
          <input
            type="text"
            class="form-control"
            placeholder="0"
            v-model="code_text"
          />
          <span class="input-errors" v-if="v$.code_text.$error">{{
            v$.code_text.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-4 col-md-6 col-12">
          <label class="form-label" for="start_date">{{
            $t("Start_date")
          }}</label>
          <VueDatePicker
            v-model="from_date"
            locale="en-US"
            time-picker-inline
          />
          <span class="input-errors" v-if="v$.from_date.$error">{{
            v$.from_date.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-4 col-md-6 col-12">
          <label class="form-label" for="end_date">{{ $t("End_date") }}</label>
          <VueDatePicker v-model="to_date" locale="en-US" time-picker-inline />
          <span class="input-errors" v-if="v$.to_date.$error">{{
            v$.to_date.$errors[0].$message
          }}</span>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <label class="form-label" for="max_use">{{ $t("max_use") }}</label>
          <input
            type="text"
            name=""
            class="form-control"
            id="max_use"
            v-model="max_use"
          />
          <span class="input-errors" v-if="v$.max_use.$error">{{
            v$.max_use.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-4 col-md-6 col-12" v-if="value_type.id != 1">
          <label class="form-label" for="min">{{ $t("min") }}</label>
          <input
            type="text"
            class="form-control"
            placeholder="0"
            v-model="min"
          />
          <span class="input-errors" v-if="v$.min.$error">{{
            v$.min.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-4 col-md-6 col-12" v-if="value_type.id != 1">
          <label class="form-label" for="max">{{ $t("max") }}</label>
          <input
            type="text"
            class="form-control"
            placeholder="0"
            v-model="max"
          />
          <span class="input-errors" v-if="v$.max.$error">{{
            v$.max.$errors[0].$message
          }}</span>
        </div>

        <div class="col-lg-12 col-12">
          <div class="form-group mb-5">
            <label class="form-label" for="conditions">{{
              $t("Terms and Conditions")
            }}</label>

            <div class="accordion" id="accordionExample3">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingCond">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseCond"
                    aria-expanded="false"
                    aria-controls="collapseCond"
                  >
                    {{ $t("Terms and Conditions") }}
                  </button>
                </h2>
                <div
                  id="collapseCond"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingCond"
                  data-bs-parent="#accordionExample3"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(condition, index) in conditions"
                      :key="index"
                    >
                      <label :for="`cond${index}`"
                        >{{ $t("Terms and Conditions") }} ({{
                          condition.lang
                        }})</label
                      >
                      <Editor
                        editorStyle="height: 120px"
                        :id="`cond${index}`"
                        v-model="condition.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-end">
          <button
            type="submit"
            class="btn PrimaryButton"
            :disabled="disable_button"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import moment from "moment";
import Editor from "primevue/editor";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import Skeleton from "primevue/skeleton";

export default {
  name: "addCoupon",
  components: {
    Multiselect,
    VueDatePicker,
    Editor,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      v$: useVuelidate(),
      language: ["ar", "en", "id"],
      couponId: this.$route.params.id,
      couponNames: [],
      description: [],
      loading: true,
      image: "",
      coupon_type: [
        {
          id: 1,
          title: this.$t("Hotels"),
        },
        {
          id: 2,
          title: this.$t("Flights"),
        },
        {
          id: 5,
          title: this.$t("packages"),
        },
      ],
      max_use: null,
      coupon_type_value: [],
      cities: [],
      cities_value: [],
      hotels: [],
      hotel_value: [],
      companies: [],
      company_values: [],
      value_type: "",
      valueTypes: [
        {
          id: 1,
          title: "value",
        },
        {
          id: 2,
          title: "percentage",
        },
      ],
      value: "",
      code_text: "",
      min: null,
      max: null,
      from_date: new Date(),
      to_date: null,
      conditions: [],
      // fileData: {},
      all_packages: [],
      package_value: [],
      fetched_coupon_data: {},
      nationalityValue: "",
      nationalities: [],
      imageEntry: "",
      image_Entry: "",
      coupon_status: 0,
      package_search: "",
      disable_button: false,
    };
  },
  validations() {
    return {
      couponNames: this.couponNames.map(() => {
        return {
          name: { required },
        };
      }),
      nationalityValue: {
        id: { required },
      },
      coupon_status: { required },
      coupon_type_value: [{ required }],
      value_type: {
        id: { required },
      },
      value: { required },
      code_text: { required },
      from_date: { required },
      to_date: { required },
      max_use: { required },
      min: this.value_type?.id != 1 ? { required } : "",
      max: this.value_type?.id != 1 ? { required } : "",
      cities_value: [
        {
          id: this.coupon_type_value.includes(1) ? { required } : "",
        },
      ],
      hotel_value: [
        {
          id: this.coupon_type_value.includes(1) ? { required } : "",
        },
      ],
      company_values: [
        {
          id: this.coupon_type_value.includes(2) ? { required } : "",
        },
      ],
      package_value: [
        {
          id: this.coupon_type_value.includes(5) ? { required } : "",
        },
      ],
    };
  },
  methods: {
    setCouponName() {
      this.language.forEach((ele) => {
        this.couponNames.push({
          lang: ele,
          name: "",
        });

        this.conditions.push({
          lang: ele,
          name: "",
        });

        this.description.push({
          lang: ele,
          name: "",
        });
      });
    },
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
    },
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
      // console.log(this.fileData);
    },
    onFile_Change(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.image_Entry = reader.result);
      // console.log(this.fileData);
    },
    searchCity(event) {
      const search = { word: event.target.value };

      axios.post("/fetch_cities", search).then(({ data }) => {
        this.cities = data.data.data;
      });
    },
    searchCompany(event) {
      const search = event.target.value;
      axios.get(`/flight_company/search?search=${search}`).then(({ data }) => {
        if (data.data) {
          this.companies = data.data;
        }
      });
    },
    fetchHotelsByCityId(value) {
      let cities_ids = value.map((ele) => {
        return ele.id;
      });
      axios
        .post(`/fetch_hotels_by_city_id`, {
          city_ids: cities_ids,
        })
        .then(({ data }) => {
          this.hotels = data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.hotels = [];
        });
    },
    fetch_all_packages() {
      axios
        .post(`/fetch_all_packages`)
        .then(({ data }) => {
          this.all_packages = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addCoupon() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.disable_button = true;
        const formData = new FormData();
        // formData.append("image", this.fileData);
        if (this.fileData instanceof File) {
          formData.append("image", this.fileData);
        }
        if (this.fileData instanceof File) {
          formData.append("main_image", this.fileData);
        }

        let coupon = {
          code: this.code_text,
          value: this.value,
          value_type: this.value_type.id,
          start_date: moment(this.from_date).format("YYYY-MM-DD"),
          start_time: moment(this.from_date).format("hh:mm:ss"),
          expired_date: moment(this.to_date).format("YYYY-MM-DD"),
          expired_time: moment(this.to_date).format("hh:mm:ss"),
          nationality_id: this.nationalityValue.id, ///////////////////////////////////
          status: this.coupon_status,
          max_use: this.max_use,
          // service_ids: this.coupon_type_value,
        };
        if (this.value_type.id != 1) {
          coupon[`max_discount`] = this.max;
          coupon[`minimun_order_price`] = this.min;
        }

        this.couponNames.forEach((ele) => {
          coupon[`title_${ele.lang}`] = ele.name;
        });

        this.description.forEach((ele) => {
          coupon[`description_${ele.lang}`] = ele.name;
        });

        this.conditions.forEach((ele) => {
          coupon[`conditions_${ele.lang}`] = ele.name;
        });

        for (let index = 0; index < this.coupon_type_value.length; index++) {
          formData.append(
            `service_ids[${index}]`,
            this.coupon_type_value[index]
          );
        }

        this.coupon_type_value.forEach((ele, index) => {
          if (ele == 1) {
            if (this.hotel_value.length > 0) {
              formData.append(
                `specifications[${this.coupon_type_value.length}][type]`,
                "hotel"
              );
              this.hotel_value.forEach((ele, hotelIndex) => {
                formData.append(
                  `specifications[${this.coupon_type_value.length}][ids][${hotelIndex}]`,
                  ele.id
                );
              });
            }

            if (this.cities_value.length > 0) {
              formData.append(`specifications[${index}][type]`, "city");
              this.cities_value.forEach((ele, cityIndex) => {
                formData.append(
                  `specifications[${index}][ids][${cityIndex}]`,
                  ele.id
                );
              });
            }
          }
          if (ele == 2) {
            if (this.company_values.length > 0) {
              formData.append(
                `specifications[${index}][type]`,
                "flight_company"
              );
              this.company_values.forEach((ele, flightIndex) => {
                formData.append(
                  `specifications[${index}][ids][${flightIndex}]`,
                  ele.id
                );
              });
            }
          }
          if (ele == 5) {
            if (this.package_value.length > 0) {
              formData.append(`specifications[${index}][type]`, "package");
              this.package_value.forEach((ele, packageIndex) => {
                formData.append(
                  `specifications[${index}][ids][${packageIndex}]`,
                  ele.id
                );
              });
            }
          }
        });

        Object.entries(coupon).forEach(([key, value]) => {
          formData.append(key, value);
        });

        if (this.$route.params.id != undefined) {
          // edit
          axios
            .post(`coupon/update/${this.couponId}`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/coupons");
            })
            .catch((error) => {
              this.disable_button = false;
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          // add
          axios
            .post(`/coupons`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/coupons");
            })
            .catch((error) => {
              this.disable_button = false;
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
    fetch_coupon_data() {
      axios.get(`/coupons/${this.couponId}`).then(({ data }) => {
        this.fetched_coupon_data = data.data;
        // console.log(this.fetched_coupon_data, "fetched_coupon_data");

        this.couponNames = this.fetched_coupon_data.titles.map((ele) => {
          return {
            lang: ele.locale,
            name: ele.title,
          };
        });

        this.description = this.fetched_coupon_data.description.map((ele) => {
          return {
            lang: ele.locale,
            name: ele.description,
          };
        });

        this.conditions = this.fetched_coupon_data.conditions.map((ele) => {
          return {
            lang: ele.locale,
            name: ele.condition,
          };
        });

        this.min = this.fetched_coupon_data.minimun_order_price;
        this.max = this.fetched_coupon_data.max_discount;
        this.value = this.fetched_coupon_data.value;
        this.max_use = this.fetched_coupon_data.max_use;

        if (this.fetched_coupon_data.value_type == "Value") {
          this.value_type = {
            id: 1,
            title: "value",
          };
        } else if (this.fetched_coupon_data.value_type == "percentage") {
          this.value_type = {
            id: 2,
            title: "percentage",
          };
        }

        this.code_text = this.fetched_coupon_data.code;
        this.to_date = `${this.fetched_coupon_data.expired_date} ${this.fetched_coupon_data.expired_time}`;
        // this.from_date = `${this.fetched_coupon_data}`
        this.coupon_type_value = this.fetched_coupon_data.service.map(
          (serv) => {
            return serv.service_id;
          }
        );

        this.fetched_coupon_data.specifications.forEach((ele) => {
          if (ele.specification_type == "Package") {
            this.package_value.push({
              id: ele.specification_id,
              title: ele.specification_title,
            });
          } else if (ele.specification_type == "Hotel") {
            this.hotel_value.push({
              id: ele.specification_id,
              title: ele.specification_title,
            });
          } else if (ele.specification_type == "City") {
            this.cities_value.push({
              id: ele.specification_id,
              title: ele.specification_title,
            });
          } else if (ele.specification_type == "FlightCompany") {
            this.company_values.push({
              id: ele.specification_id,
              name: ele.specification_title,
            });
          }
        });

        // console.log(this.fetched_coupon_data, "fetched_coupon_data");
        this.nationalityValue = this.fetched_coupon_data.id;
        this.coupon_status =
          this.fetched_coupon_data.status == "Active"
            ? 0
            : this.fetched_coupon_data.status == "Disactive"
            ? 1
            : null;
        // console.log(this.coupon_status);

        this.imageEntry = this.fetched_coupon_data.image;
        this.image_Entry = this.fetched_coupon_data.main_image;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.setCouponName();
    this.fetch_all_packages();
    if (this.$route.params.id != undefined) {
      this.fetch_coupon_data();
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.form-group {
  position: relative;
  .resultSearch {
    position: absolute;
    top: 66px;
    background-color: #fff;
    height: 300px;
    width: 100%;
    overflow-y: auto;
    z-index: 999;
    .head_search {
      font-family: "bold";
      background-color: $PrimaryColor;
      color: #fff;
      padding: 0.5rem;
      margin-bottom: 0;
    }
    .hotels,
    .cities {
      margin-bottom: 0;
      li {
        display: flex;
        align-items: center;
        font-family: "regular";
        font-size: 0.8rem;
        padding: 0.5rem;
        border-bottom: 1px solid #292d32;
        transition: 0.7s ease-in-out;
        cursor: pointer;
        &:last-child {
          border-bottom: unset;
        }
        .hotel_image {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-inline-end: 0.5rem;
        }
        i {
          font-size: 1.5rem;
          color: #b5b5b5;
          margin-inline-end: 0.5rem;
        }
        .title,
        .country {
          margin-bottom: 0;
        }
        .title {
          font-size: 0.8rem;
        }
        .country {
          font-size: 0.7rem;
          color: #292d32;
        }
        &:hover {
          transition: 0.7s ease-in-out;
          background-color: rgba($color: $PrimaryColor, $alpha: 0.1);
        }
      }
    }
  }
  .resultSearch {
    background-color: white;
    padding: 2%;
    z-index: 999;
    li {
      cursor: pointer;
    }
  }
}
.form-check .form-check-input {
  float: none;
  margin-left: 5px;
  &:checked {
    background-color: $PrimaryColor;
  }
}
.accordion-button {
  font-size: 14px;
  padding: 10px;
  // border-bottom-color: #dee2e6;
  color: #adadad;
}
</style>
