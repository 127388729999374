<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_new_page") }}</h4>
    </div>
    <form @submit.prevent="addPage()">
      <div class="form-group">
        <label>{{ $t("title") }}</label>
        <input v-model="title" class="form-control" type="text" />
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";

const router = useRouter();

const title = ref("");

const addPage = async () => {
  await axios
    .post("store_page", {
      title: title.value,
    })
    .then((res) => {
      if (res) router.push("/index-page");
    })
    .catch((err) => {
      console.log(err);
    });
};
</script>

<style lang="scss" scoped></style>
