<template>
  <!-- <div v-if="loading" class="loading">
    <Loading />
  </div> -->
  <section class="card_style">
    <div class="w-100" v-if="loading">
      <Skeleton width="10%" class="mb-2"></Skeleton>
      <Skeleton width="5%" class="mb-3"></Skeleton>
      <div class="d-flex align-items-center gap-3 w-100">
        <div
          class="d-flex flex-column gap-2 w-100 my-2"
          v-for="i in 3"
          :key="i"
        >
          <Skeleton width="15%"></Skeleton>
          <Skeleton height="2rem" width="60%%" class="mb-2"></Skeleton>
        </div>
      </div>
    </div>

    <form class="form_topic" @submit.prevent="editCategory()" v-else>
      <div class="main_header_style">
        <h4>{{ $t("Edit Car Category") }}</h4>
      </div>
      <div class="row">
        <div class="col-lg-4 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("choose_type") }}</label>
            <Multiselect
              v-model="car_category_id"
              :options="category"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose_type')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.car_category_id.id.$error">{{
              v$.car_category_id.id.$errors[0].$message
            }}</span>
          </div>
        </div>
        <!-- start input group -->
        <div class="col-12 col-lg-4">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  {{ car_name[0]?.title }}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <div
                    class="form-group"
                    v-for="(car, index) in car_name"
                    :key="index"
                  >
                    <label :for="`name${index}`"
                      >{{ $t("car_name") }} ({{ car.locale }})</label
                    >
                    <input
                      type="text"
                      :placeholder="$t('name')"
                      class="form-control"
                      :id="`name${index}`"
                      v-model="car.title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span class="input-errors" v-if="v$.car_name.$error">{{
            v$.car_name.$errors[0].$message
          }}</span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("total_number") }}</label>
            <input
              type="number"
              class="form-control"
              :placeholder="$t('total_number')"
              v-model="max_number"
              required
            />
          </div>
          <span class="input-errors" v-if="v$.max_number.$error">{{
            v$.max_number.$errors[0].$message
          }}</span>
        </div>
        <!-- finish input group -->
        <div class="col-md-6 mb-6">
          <input type="file" id="file" @change="imageUpload" multiple hidden />

          <label for="file" class="add_file"
            ><i class="fa-solid fa-upload"></i> {{ $t("Add image") }}</label
          >
          <p>{{ this.imageCompanyName }}</p>
        </div>

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Loading from "@/components/LottieFiles/loading.vue";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Skeleton from "primevue/skeleton";
export default {
  name: "edit-category",
  data() {
    return {
      v$: useVuelidate(),
      car_id: this.$route.params.id,
      car_category_id: "",
      max_number: "",
      category: [],
      imageCompany: "",
      imageCompanyName: "",

      car_name: [],
      loading: true,
    };
  },
  components: {
    // Loading,
    Multiselect,
    Skeleton,
  },
  validations() {
    return {
      car_category_id: {
        id: { required },
      },
      car_name: this.car_name.map(() => {
        return {
          title: { required },
        };
      }),
      max_number: { required },
    };
  },
  methods: {
    imageUpload(event) {
      this.imageCompany = event.target.files[0];
      const name = this.imageCompany.name;

      const reader = new FileReader();
      reader.readAsDataURL(this.imageCompany);
      reader.onload = () => (this.imageCompanyName = name);
      // reader.onload = () => (this.imageEntry = reader.result);
    },

    fetchCategoryData() {
      axios.post(`/fetch_car_categories`).then(({ data }) => {
        this.category = data.data.data;
        this.loading = false;
        // console.log(this.category);
      });
    },

    fetchCarData() {
      axios.get(`/car_types/${this.car_id}`).then(({ data }) => {
        this.car_category_id = data?.data?.cateogry;
        this.max_number = data?.data?.max_number;
        this.car_name = data?.data?.title;
        this.imageCompany = data?.data?.images;
        this.loading = false;
        // console.log(data?.data);
      });
    },

    // add expness
    async editCategory() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const params = new URLSearchParams();
        params.append("car_category_id", this.car_category_id?.id);
        params.append("max_number", this.max_number);
        params.append("image", this.imageCompany);

        this.car_name &&
          this.car_name?.length > 0 &&
          this.car_name.forEach((car) => {
            params.append(`title_${car.locale}`, car.title);
          });

        axios
          .patch(`/car_types/${this.car_id}`, params)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response?.data?.message,
              icon: "success",
            });

            this.$router.push("/car_type");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response?.data?.message,
              icon: "error",
            });
          });
      }
    },
  },
  created() {
    this.fetchCategoryData();
    this.fetchCarData();
  },
};
</script>
<style lang="scss" scoped>
// @import "./_carType.scss";
</style>
