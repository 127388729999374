<template>
  <section class="card_style">
    <div class="main_header">
      <h5>{{$t("add Pop Up message")}}</h5>
    </div>
    <form>
      <div class="row">
        <label class="form-label">{{$t("Date of appearance")}}</label>
        <div class="col-md-6 col-12">
          <div class="form-group d-flex align-items-center gap-2">
            <Datepicker
              v-model="trip_date"
              @click="selectedTripDate"
              :lang="page_lang"
              class="w-75"
            />
            <Calendar id="calendar-timeonly" v-model="time" timeOnly />
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group d-flex align-items-center gap-2">
            <Datepicker
              v-model="trip_date"
              @click="selectedTripDate"
              :lang="page_lang"
              class="w-75"
            />
            <Calendar id="calendar-timeonly" v-model="time" timeOnly />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label class="form-label">{{$t("choose_nationality")}}</label>
            <Multiselect
                v-model="nationality_value"
                :options="nationalities"
                :placeholder="$t('choose_one_or_more')"
                label="title"
                track-by="id"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
                @keyup="searchNationality"
              >
              </Multiselect>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <div class="form-group">
            <label class="form-label">{{$t("choose_countries")}}</label>
            <Multiselect
                v-model="country_value"
                :options="countries"
                :placeholder="$t('choose_one_or_more')"
                label="title"
                track-by="id"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <Toast />
            <FileUpload name="demo[]" accept="image/*" :multiple="false" :maxFileSize="1000000" :showUploadButton="false" v-model="popup_image">
                <template #empty>
                    <p>
                      <img src="@/assets/media/image/icon/gallery.png" alt="image" />
                      <span>{{$t("Drop the image you want to add to the message or add it from here")}}</span>
                    </p>
                </template>
            </FileUpload>
          </div>
        </div>


        <div class="col-12">
          <div class="form-group">
            <label class="form-label">{{$t("message_title")}} ({{$t("optional")}})</label>
            <input type="text" class="form-control" :placeholder="$t('enter title here')" />
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label class="form-label">{{$t("description")}}</label>
            <textarea :placeholder="$t('enter the message text')" class="form-control"></textarea>
          </div>
        </div>

        <div class="col-md-6 col-12">
          <div class="form-group">
            <label class="form-label">{{$t("Text of the first button")}}</label>
            <input type="text" class="form-control" :placeholder="$t('the_text')" />
          </div>
        </div>

        <div class="col-md-6 col-12">
          <div class="form-group">
            <label class="form-label">{{$t("Text of the second button")}}</label>
            <input type="text" class="form-control" :placeholder="$t('the_text')" />
          </div>
        </div>

        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="link" id="url" value="1" v-model="routing"/>
            <label class="form-check-label" for="url">{{$t("Routing by URL")}}</label>
          </div>
          <div class="form-group" v-if="routing == 1">
            <label class="form-label">{{$t("Enter the URL")}}</label>
            <input type="text" class="form-control" :placeholder="$t('write here')" />
          </div>
        </div>

        <div class="col-12">
          <div class="form-check">
            <input class="form-check-input" type="radio" name="link" id="directions" value="2" v-model="routing"/>
            <label class="form-check-label" for="directions">{{$t("Routing by specific Directions")}}</label>
          </div>

          <div class="row" v-if="routing == 2">
            <p class="service_title">{{$t("Choose the service you want to forward the message to")}}</p>
            <div class="services">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="service" id="hotels" value="01" v-model="service"/>
                <label class="form-check-label" for="hotels">{{$t("hotels")}}</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="service" id="flight" value="02" v-model="service"/>
                <label class="form-check-label" for="flight">{{$t("flight")}}</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="service" id="packages" value="03" v-model="service"/>
                <label class="form-check-label" for="packages">{{$t("packages")}}</label>
              </div>
            </div>

            <div class="row" v-if="service == '01'">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label class="form-label">{{$t("choose_country")}}</label>
                  <Multiselect
                    v-model="country_value"
                    :options="countries"
                    :placeholder="$t('choose_one_or_more')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label class="form-label">{{$t("choose_city")}}</label>
                  <Multiselect
                    v-model="country_value"
                    :options="countries"
                    :placeholder="$t('choose_one_or_more')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label class="form-label">{{$t("choose_hotel")}} ( {{$t("optional")}} )</label>
                  <Multiselect
                    v-model="country_value"
                    :options="countries"
                    :placeholder="$t('choose_one_or_more')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
            </div>

            <div class="row" v-if="service == '02'">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label class="form-label">{{$t("launch_airport")}}</label>
                  <Multiselect
                    v-model="country_value"
                    :options="countries"
                    :placeholder="$t('choose_one_or_more')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label class="form-label">{{$t("arrival_airport")}}</label>
                  <Multiselect
                    v-model="country_value"
                    :options="countries"
                    :placeholder="$t('choose_one_or_more')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label class="form-label">{{$t("flight_company")}} ( {{$t("optional")}} )</label>
                  <Multiselect
                    v-model="country_value"
                    :options="countries"
                    :placeholder="$t('choose_one_or_more')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
            </div>

            <div class="row" v-if="service == '03'">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label class="form-label">{{$t("country")}}</label>
                  <Multiselect
                    v-model="country_value"
                    :options="countries"
                    :placeholder="$t('choose_one_or_more')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-8 col-md-6 col-12">
                <div class="form-group">
                  <label class="form-label">{{$t("packages")}} ( {{$t("optional")}} )</label>
                  <Multiselect
                    v-model="country_value"
                    :options="countries"
                    :placeholder="$t('choose_one_or_more')"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="btn PrimaryButton" type="sumbit">{{$t("save message")}}</button>
    </form>
  </section>
</template>

<script>
import Calendar from 'primevue/calendar';
import Datepicker from "vuejs3-datepicker";
import Multiselect from "vue-multiselect";
import axios from 'axios';
import FileUpload from 'primevue/fileupload';

export default {
    name: "add_popUp_msg",
    components: {
      Multiselect,
      Datepicker,
      Calendar,
      FileUpload
    },
    data(){
      return{
        nationalities: [],
        nationality_value: "",
        countries: [],
        country_value: "",
        routing: 1,
        service: "01",
        popup_image: "",
      }
    },
    methods:{
      searchNationality(event) {
        const searchText = {
          word: event == undefined ? "" : event.target.value,
        };
        axios
          .post(`/fetch_custom_countries`, searchText)
          .then((res) => {
            this.nationalities = res.data.data.data;
          })
          .catch((err) => console.log(err));
      },
      fetch_country() {
        axios.post("/fetch_countries").then(({ data }) => {
            this.countries = data.data;
        });
      },
    },
    mounted(){
      this.searchNationality();
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.main_header h5{
  color: #0B1427;
  font-size: 24px;
  font-weight: 700;
}
.form-check-input{
  float: unset;
  margin-left: 10px;
  &:checked{
    background-color: $PrimaryColor;
  }
}
.service_title{
  color: #656B78;
  font-size: 12px;
}
.services{
  display: flex;
  align-items: center;
  gap: 10px;
  .form-check{
    width: 90px;
    height: 35px;
    // background-color: red;
    border-radius: 8px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 14px;
    .form-check-input{
      width: 100%;
      height: 100%;
      margin: 0;
      border-radius: 0;
      opacity: 0;
      position: absolute;
      cursor: pointer;
      &:checked ~ .form-check-label{
        background-color: $PrimaryColor;
        color: #fff;
        border: 1px solid $PrimaryColor;
      }
    }
    .form-check-label{
      width: 100%;
      height: 100%;
      border-radius: 8px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #656B78;
      background-color: #D0D2D780;
      border: 1px solid #D0D2D780;
    }
  }
}
.card{
  p{
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      display: inline-block;
      max-width: 260px;
    }
  }
}
.PrimaryButton{
  margin-inline-start: unset;
}
</style>