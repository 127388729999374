<template>
  <section class="show_receipt card_style">
    <div class="row options mb-2">
      <h4>
        <router-link :to="`/hotels/book_offline/${$route.params.id}`" target="_blank"
          >{{ $store.state.reservation_number }} &nbsp;</router-link
        >{{ $t("History") }}
      </h4>
    </div>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("empoly_name") }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("time") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 7" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in history.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.employee_name ? item.employee_name : "-" }}</td>
            <td>{{ item.create_at ? item.create_at : "-" }}</td>
            <td>{{ item.create_time ? item.create_time : "-" }}</td>
            <td>
              {{ $t(`${item.action ? item.action : "-"}`) }}
              <span v-if="item.booking_status != null" style="font-size: 0.8rem"
                >({{ $t(item.booking_status.old_status) }}
                <i class="fa-solid fa-arrow-left"></i>
                {{ $t(item.booking_status.new_status) }})</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="history"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchHotelsHistory"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
export default {
  name: "HotelsBookOfflineHistory-page",
  data() {
    return {
      history: [],
      loading: true,
    };
  },

  components: {
    pagination,
    Skeleton,
  },
  methods: {
    FetchHotelsHistory(page = 1) {
      axios
        .post(`/booking_operation_logs_by_booking_id?page=${page}`, {
          booking_id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.history = data.data;
          this.loading = false;
          //   console.log(this.history);
        });
    },
  },
  mounted() {
    this.FetchHotelsHistory();
  },
};
</script>

<style></style>
