import { defineStore } from "pinia";

export const usePermissionStore = defineStore("permission", {
  state: () => ({
    userRole: null,
  }),
  getters: {
    canAdd() {
      return this.userRole === "admin" || this.userRole === "employee";
    },
    canEdit() {
      return this.userRole === "admin";
    },
    canDelete() {
      return this.userRole === "admin";
    },
  },
  actions: {
    setUserRole(role) {
      this.userRole = role;
      console.log("setUserRole", role);
    },
  },
});
