<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Create Transportation Contract") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="addContract()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("contract_name") }} </label>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("contract_name") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(contract, index) in contract_name"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("contract_name") }} ({{ contract.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="contract.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.contract_name.$error">{{v$.contract_name.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <label class="form-label">{{ $t("contract_country") }}</label>
            <div class="form-group w-100">
              <Multiselect
                v-model="nationalityValue"
                :options="nationalities"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose_nationality')"
                select-label=""
                :hide-selected="true"
                @keyup="searchNationality"
              >
              </Multiselect>
              <span class="input-errors" v-if="v$.nationalityValue.id.$error">{{v$.nationalityValue.id.$errors[0].$message}}</span>
            </div>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-lg-4 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("Routes") }}</label>
            <Multiselect
              v-model="routeValues"
              :options="routes.map((route) => route.id)"
              :custom-label="(opt) => routes.find((x) => x.id == opt).text"
              :clear-on-select="true"
              :placeholder="$t('Routes')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.routeValues.$error">{{v$.routeValues.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-4 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("transportation type") }}</label>
            <Multiselect
              v-model="categoryValues"
              :options="cat.map((cats) => cats.id)"
              :custom-label="(opt) => cat.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('transportation type')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.categoryValues.$error">{{v$.categoryValues.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-4 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("car_type") }}</label>
            <Multiselect
              v-model="typeValues"
              :options="types.map((car) => car.id)"
              :custom-label="(opt) => types.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('car_type')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.typeValues.$error">{{v$.typeValues.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-4 col-12">
          <div class="input-group">
            <label class="form-label">{{ $t("suppliers") }}</label>
            <Multiselect
              v-model="supplierValues"
              :options="suppliers.map((supplyer) => supplyer.id)"
              :custom-label="(opt) => suppliers.find((x) => x.id == opt).name"
              :clear-on-select="true"
              :placeholder="$t('suppliers')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
            <span class="input-errors" v-if="v$.supplierValues.$error">{{v$.supplierValues.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_from") }}</label>
            <Datepicker
              v-model="from_date"
              lang="ar"
              :placeholder="$t('period_from')"
              :disabled-dates="{
                  to: new Date()
              }"
            />
            <span class="input-errors" v-if="v$.from_date.$error">{{v$.from_date.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-4">
          <div class="input-group">
            <label class="form-label"> {{ $t("period_to") }}</label>
            <Datepicker
              v-model="to_date"
              lang="ar"
              :placeholder="$t('period_to')"
              :disabled-dates="{
                to: new Date( from_date )
              }"
            />
            <span class="input-errors" v-if="v$.to_date.$error">{{v$.to_date.$errors[0].$message}}</span>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import Loading from "@/components/LottieFiles/loading.vue";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: "add-car-type",
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      contract_name: [],
      nationalityValue: {},
      nationalities: [],
      routes: [],
      routeValues: [],
      types: [],
      typeValues: [],
      cat: [],
      categoryValues: [],
      suppliers: [],
      supplierValues: "",
      from_date: new Date( new Date().getTime() + 24 * 60 * 60 * 1000 ),
      to_date: new Date( new Date().getTime() + 2 * 24 * 60 * 60 * 1000 ),
      loading: false,
    };
  },
  components: {
    Multiselect,
    Datepicker,
    Loading,
  },
  validations(){
    return{
      nationalityValue: {
        id: { required }
      },
      routeValues: { required },
      categoryValues: { required },
      typeValues: { required },
      supplierValues: { required },
      from_date: { required },
      to_date: { required },
      contract_name: this.contract_name.map(()=>{
        return{
          name : {required}
        }
      })
    }
  },
  methods: {
    setContractName() {
      this.languages.forEach((language) => {
        this.contract_name.push({
          lang: language,
          name: "",
        });
      });
    },
    searchNationality(event) {
      const searchText = { word: event == undefined ? "" : event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))

        .catch((err) => console.log(err));
    },
    fetchRoutes() {
      axios.post("/fetch_car_routes").then(({ data }) => {
        this.routes = data.data.data;
      });
    },
    fetchCarTypes() {
      axios.post("/fetch_car_types").then(({ data }) => {
        this.types = data.data.data;
      });
    },
    fetchCarCategories(searchTrem) {
      axios.post("/fetch_car_categories", { word: searchTrem }).then(({ data }) => {
        this.cat = data.data.data;
      });
    },
    FetchSuppliers() {
      axios.post(`/fetch_consumer_by_role`, { role: 2 }).then(({ data }) => {
        this.suppliers = data.data.data;
      });
    },
    async addContract() {
      this.v$.$validate();
      if(!this.v$.$error){
      let contractData = {
        country_id: this.nationalityValue.id,
        supplier_id: this.supplierValues,
        from_date: moment(this.from_date).format("YYYY-MM-DD"),
        to_date: moment(this.to_date).format("YYYY-MM-DD"),
        route_ids: this.routeValues,
        car_category_ids: this.categoryValues,
        car_type_ids: this.typeValues,
      };
      this.contract_name.forEach((contract) => {
        contractData[`title_${contract.lang}`] = contract.name;
      });

      this.loading = true; // Start loading

      axios
        .post("/transportation_contracts", contractData)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.loading = false; //the loading ended
          this.$router.push("/contract-transport");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
      }
    },
  },
  created() {
    this.fetchRoutes();
    this.fetchCarTypes();
    this.fetchCarCategories();
    this.FetchSuppliers();
    this.setContractName();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_contract.scss";
</style>
