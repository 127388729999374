<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{
          $route.params.packageType == "umrah"
            ? $t("Umrah_packages")
            : $t("Tourism_programs")
        }}
      </h4>
    </div>
    <div class="main_header_style">
      <div class="search-user my-3 w-50">
        <form>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('search...')"
            v-model="package_search"
            @keyup="fetch_all_package((page = 1))"
          />
        </form>
      </div>
      <div>
        <router-link
          :to="`/${$route.params.packageType == 'umrah' ? 'package/add/umrah' : 'package/add/tourism'}`"
          class="PrimaryButton btn w-100"
        >
          {{ $t("New_package") }}
        </router-link>
      </div>
    </div>
    <Suspense>
      <div class="row">
        <table class="table" id="userTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("pagage_name") }}</th>
              <th>{{ $t("package_type") }}</th>
              <th>{{ $t("contry") }}</th>
              <th>{{ $t("city") }}</th>
              <th>{{ $t("program_date") }}</th>
              <th>{{ $t("created_at") }}</th>
              <th>{{ $t("created_by") }}</th>
              <th>{{ $t("status") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 10" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody>
            <tr v-for="(item, index) in allPackages.data" :key="index">
              <td>{{ total - ((indexPage - 1) * per_page + index) }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.package_type.title }}</td>
              <td>
                {{ item.countries[0]?.country_title }}
              </td>
              <td>
                <span v-for="(city, indexCity) in item.cities" :key="indexCity"
                  >{{ city
                  }}<span v-if="indexCity !== item.cities.length - 1"
                    >,
                  </span></span
                >
              </td>
              <td>{{ item.trip_date }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ item.created_by?.name }}</td>
              <td>
                <span
                  class="coupon_status"
                  :class="{
                    disactive: item.status == 'INACTIVE',
                    active: item.status == 'ACTIVE',
                  }"
                  >{{ this.$t(item.status) }}</span
                >
              </td>
              <td>
                <a
                  v-tooltip.top="$t('view_package')"
                  class="btn btn-info mx-1 w-auto"
                  target="_blank"
                  :href="`${$websiteLink}/umrah/${item.id}`"
                  ><i class="fa-solid fa-circle-info"></i>
                </a>

                <div
                  class="btn btn-warning mx-1 w-auto"
                  @click="change_status(item.id)"
                  v-tooltip.top="$t('change status')"
                >
                  <i class="fa-solid fa-triangle-exclamation"></i>
                </div>
                <router-link :to="`/package_nationality/${item.id}`">
                  <button
                    class="btn btn-secondary mx-1 w-auto"
                    v-tooltip.top="$t('nationality_price')"
                  >
                    <i class="fa-solid fa-dollar-sign"></i>
                  </button>
                </router-link>

                <router-link
                  :to="`/package/${this.$route.params.packageType == 'umrah' ? 'umrah' : 'tourism'}/${item.id}`"
                  class="btn btn-success mx-1 w-auto"
                  v-tooltip.top="$t('Edit Package')"
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                </router-link>

                <button
                  class="btn btn-danger mx-1 w-auto"
                  @click="deletePackage(index)"
                  v-tooltip.top="$t('Delete Package')"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- loading state via #fallback slot -->
      <template #fallback>
        <Loading />
      </template>
    </Suspense>
    <div class="row">
      <pagination
        :data="allPackages"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_all_package"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/loading.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";

export default {
  data() {
    return {
      allPackages: [],
      package_search: "",
      loading: true,
      indexPage: 1,
      per_page: 1,
      total: 1,
      defaultImage: require("@/assets/media/image/placeholder.png"), // Replace with your default image URL,
    };
  },
  methods: {
    handleImageError(event) {
      // When the image fails to load, replace it with the default image
      event.target.src = this.defaultImage;
    },
    fetch_all_package(page = 1) {
      axios
        .post(`/fetch_all_packages?page=${page}`, {
          word: this.package_search,
          package_tourism_type:
            this.$route.params.packageType == "umrah" ? 1 : 2,
        })
        .then(({ data }) => {
          this.allPackages = data.data;
          // console.log(this.allPackages);
          this.loading = false;
          this.indexPage = page;
          this.indexPage = page;
          this.per_page = data.data.meta.per_page;
          this.total = data.data.meta.total;
        });
    },
    // delete package
    deletePackage(user_index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.allPackages.data[user_index].id;
          const newLocal = this.$i18n.locale;
          axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios.post(`/delete_basic_package/${delete_id}`).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              if (
                data.message == "هذه الباقه تحتوي علي حجوزات" ||
                data.message == "This package has reservations." ||
                data.message == "Paket ini sudah termasuk reservasi"
              ) {
                return;
              } else {
                this.allPackages.data.splice(user_index, 1); // Remove the deleted item from the array
              }
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
    change_status(id) {
      axios
        .post("/change_package_status", {
          package_id: id,
        })
        .then(({ data }) => {
          this.fetch_all_package();
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  // mounted() {
  //   this.fetch_all_package();
  // },
  components: {
    pagination,
    Loading,
    AddFavDailog,
    Skeleton,
  },
  watch: {
    "$route.params.packageType": {
      handler: function () {
        this.fetch_all_package();
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_user.scss";

.main_header_style {
  align-items: center;

  h4 {
    margin-bottom: 0;
  }
}

.search-user {
  form {
    position: relative;

    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }

    input {
      padding-right: 40px !important;
    }
  }
}

.btn_trip {
  border: 1px solid #0a909b;
  outline: none;
  padding: 0.5rem 0.8rem;
  color: #0a909b;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 0.2rem;
  font-family: "reqular";
}

.coupon_status {
  display: block;
  padding: 10px;
  border-radius: 10px;
  width: 85px;
  text-align: center;

  &.active {
    background-color: #00800026;
  }

  &.disactive {
    background-color: #ff000024;
  }
}
</style>
