<template>
  <section class="auth">
    <div class="container">
      <form class="forget-card" @submit.prevent="reset_password">
        <h3>{{$t("new_password")}}</h3>
        <h6>{{$t("password_validation")}}</h6>
        <div class="form-group">
          <label for="exampleInputEmail1"
            >{{$t("password")}}</label
          >
          <input
            type="password"
            class="form-control"
            v-model="Form.password"
            :placeholder="$t('password')"
            required
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1"
            >{{$t("confirm_password")}}</label
          >
          <input
            type="password"
            class="form-control"
            v-model="Form.confirm_password"
            :placeholder="$t('password')"
            required
          />
        </div>
        <button type="submit" class="btn submit_btn">{{$t("send")}}</button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";

export default {
    name: "reset-password",
    data() {
        return {
            Form: {
                password: "",
                confirm_password: "",
                email: localStorage.getItem("email")
            }
        }
    },
    methods: {
        reset_password() {
            if (this.Form.password != this.Form.confirm_password) {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: this.$t("password_not_match"),
                    showConfirmButton: true,
                    showCancelButton: false,
                });
            }
            else {
                axios
                    .post("/forget_password", this.Form)
                    .then(({ data }) => {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: data.message,
                            timer: 1500,
                        });
                        this.$router.push("/login");
                        localStorage.removeItem("email")
                        // console.log(data.message)
                    })
                    .catch((error) => {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: error.response.data.message,
                            timer: 1500,
                        });
                        return error;
                    })
                    .finally(() => {
                        //Perform action in always
                    });
            }
        },
    },
    created() {
        if (this.Form.email == null) {
            this.$router.push("/forget")
        }
    }
};
</script>

<style lang="scss" scoped>
  @import "./_auth.scss";
</style>