<template>
  <div class="Trip-options" :class="{ fixed: isTabFixed }" ref="tabMenu">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-start">
        <div class="col-lg-2 col-md-2 col-12">
          <div class="d-flex align-items-center py-2">
            <h4 class="title">{{ $t("Trip_options") }}</h4>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-check p-0">
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: max-content"
            >
              <input
                class="form-check-input mx-1"
                type="checkbox"
                value=""
                id="checkFlight"
                :checked="editedFlights.length > 0 && $route.params.id"
                @click="flightEmit"
              />
              <label class="form-check-label mb-0" for="checkFlight">
                <img src="@/assets/media/image/icon/Vector.svg" alt="" />
                {{ $t("flight") }}
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-check p-0">
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: max-content"
            >
              <input
                class="form-check-input mx-1"
                type="checkbox"
                value=""
                @click="transportEmit"
                :checked="trans_setting_Data.length > 0 && $route.params.id"
                id="checkTransportation"
              />
              <label class="form-check-label mb-0" for="checkTransportation">
                <img src="@/assets/media/image/icon/car2.svg" alt="" />
                {{ $t("transportation") }}
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-check p-0">
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: max-content"
            >
              <input
                class="form-check-input mx-1"
                type="checkbox"
                value=""
                :checked="visaData.length > 0 && $route.params.id"
                @click="visaEmit"
                id="checkVisa"
              />
              <label class="form-check-label mb-0" for="checkVisa">
                <img src="@/assets/media/image/icon/tree.svg" alt="" />
                {{ $t("visa") }}
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-2 col-12">
          <div class="form-check p-0">
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: max-content"
            >
              <input
                class="form-check-input mx-1"
                type="checkbox"
                value=""
                :checked="insurancesData.length > 0 && $route.params.id"
                @click="insuranceEmit"
                id="checkInsurances"
              />
              <label class="form-check-label mb-0" for="checkInsurances">
                <img src="@/assets/media/image/icon/tree.svg" alt="" />
                {{ $t("Insurances") }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TripOptions",
  props: ["trans_setting_Data", "editedFlights", "insurancesData", "visaData"],
  emits: [
    "flightEmit",
    "transportEmit",
    "visaEmit",
    "insuranceEmit",
    "hotelEmit",
  ],
  data() {
    return {
      checkHotel: false,
      checkFlight: false,
      checkTransport: false,
      checkVisa: false,
      checkinsuranceEmit: false,
      isTabFixed: false,
      tabMenuOffsetTop: 0,
    };
  },
  watch: {
    editedFlights: {
      deep: true,
      immediate: true,
      handler() {
        if (this.$route.params.id && this.editedFlights.length > 0) {
          this.checkFlight = true;
          this.$emit("flightEmit", this.checkFlight);
        }
      },
    },
    trans_setting_Data: {
      deep: true,
      immediate: true,
      handler() {
        if (this.$route.params.id && this.trans_setting_Data.length > 0) {
          this.checkTransport = true;
          this.$emit("transportEmit", this.checkTransport);
        }
      },
    },
    visaData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.$route.params.id && this.visaData.length > 0) {
          this.checkVisa = true;
          this.$emit("visaEmit", this.checkVisa);
        }
      },
    },
    insurancesData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.$route.params.id && this.insurancesData.length > 0) {
          this.checkinsuranceEmit = true;
          this.$emit("insuranceEmit", this.checkinsuranceEmit);
        }
      },
    },
  },
  methods: {
    checkHotelFunction() {
      this.checkHotel = !this.checkHotel;
      // return this.checkHotel;
    },
    hotelEmit() {
      this.$emit("hotelEmit", (this.checkHotel = !this.checkHotel));
    },
    flightEmit() {
      this.$emit("flightEmit", (this.checkFlight = !this.checkFlight));
    },
    transportEmit() {
      this.$emit("transportEmit", (this.checkTransport = !this.checkTransport));
    },
    visaEmit() {
      this.$emit("visaEmit", (this.checkVisa = !this.checkVisa));
    },
    insuranceEmit() {
      this.$emit(
        "insuranceEmit",
        (this.checkinsuranceEmit = !this.checkinsuranceEmit),
      );
    },
    handleScroll() {
      this.isTabFixed = window.scrollY > this.tabMenuOffsetTop;
    },
  },
  mounted() {
    this.tabMenuOffsetTop = this.$refs.tabMenu.offsetTop;
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.Trip-options {
  background-color: #fff;
  transition: 0.7s ease-in-out;
  z-index: 9999999;
  margin: 1rem 0;
  .title {
    font-family: "bold";
    color: #0b1427;
    font-size: 1.25rem;
    justify-content: center;
  }
  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
  }
  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }
  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }
  &.fixed {
    position: fixed;
    top: 0;
    margin: 0 !important;
    width: 100%;
    margin-inline-start: 15%;
    left: 0;
    background-color: #7eb3b8;
    transition: 0.7s ease-in-out;
    .form-check-label {
      color: #fff;
      img {
        filter: brightness(0) invert(1);
      }
    }
    .title {
      color: #fff;
    }
  }
}
</style>
