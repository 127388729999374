<template>
  <section class="card_style">
    <div class="row options mb-2">
      <div class="d-flex mb-2 align-items-center col-12 col-lg-12 col-md-12">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>
          {{ $t("Bank Account") }}
        </h4>
      </div>

      <form class="col-lg-6 col-md-12 col-12 d-flex align-items-center">
        <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
        <input
          class="sear w-50 form-control"
          type="text"
          v-model="accountText"
          :placeholder="$t('search by safe name or bank account')"
          @keyup="FetchAccounts((page = 1))"
        />
      </form>
      <!-- <div class="col-3">
        <select class="form-select" aria-label="Default select example">
          <option value="all">{{ $t("All Status") }}</option>
          <option value="all">{{ $t("Active Safe") }}</option>
          <option value="all">{{ $t("Not Active Safe") }}</option>
        </select>
      </div> -->
      <button
        class="SecondButton mt-0 btn action d-flex align-items-center justify-content-center col-lg-3 col-md-12 col-12"
        @click="exportToExcel"
      >
        <i class="fa-solid fa-file-excel"></i>
        {{ $t("export_to_excel") }}
      </button>
      <div class="col-lg-3 col-md-12 col-12">
        <router-link
          :to="{ name: 'AddAccount' }"
          class="PrimaryButton btn mt-0 ban"
          ><i class="fa-solid fa-plus"></i>
          {{ $t("New Bank Account") }}</router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table bank" id="accountTable">
          <thead>
            <tr>
              <th>{{ $t("Bank Account") }}</th>
              <th>{{ $t("Account Number") }}</th>
              <th>{{ $t("Value") }}</th>
              <th>{{ $t("Status") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="account in 10" :key="account">
              <td v-for="account in 5" :key="account">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr
              v-for="(account, index) in accounts.data"
              :key="index"
              :class="{
                main: account.is_online == 1,
                not_main: account.is_online == 0,
              }"
            >
              <td>{{ account.title }}</td>
              <td>{{ account.account_number }}</td>
              <td>{{ account.money }} {{ currency?.code }}</td>
              <td>
                <span
                  class="status"
                  :class="{
                    active: account.active == 1,
                    not_active: account.active == 0,
                  }"
                  >{{
                    account.active == 0 ? $t("Not Active") : $t("Active")
                  }}</span
                >
                <!-- <span class="status" :class="{main:account.is_main == 1 , active:account.active==1 , not_active:account.active==0}">{{account.is_main == 1 ? $t("main") : account.active == 0 ? $t("Not Active") : $t("Active")}}</span> -->
              </td>
              <td>
                <router-link
                  :to="`/account/details/${account.id}`"
                  :title="$t('details')"
                  class="btn btn-info"
                >
                  <i class="fa-solid fa-circle-exclamation"></i>
                </router-link>

                <router-link
                  :to="`/account/edit/${account.id}`"
                  :title="$t('Edit')"
                  class="btn btn-success text-white mx-2"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>

                <button
                  class="btn btn-danger text-white"
                  :title="$t('Delete')"
                  @click="deleteAccount(index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>

                <button
                  class="btn btn-warning mx-1"
                  :title="$t('Online')"
                  @click="onlineAccount(index)"
                >
                  <i class="fa-solid fa-list-check"></i>
                </button>

                <button
                  class="btn btn-secondary"
                  :title="account.active == 1 ? $t('Disable') : $t('Activate')"
                  @click="disableAccount(index, account.active)"
                >
                  <i class="fa-solid fa-ban"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <pagination
          :data="accounts"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="FetchAccounts"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import * as XLSX from "xlsx";
// import $ from "jquery";
import Skeleton from "primevue/skeleton";
export default {
  name: "bankAccount",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      accounts: [],
      accountText: "",
      loading: true,
      currency: JSON.parse(localStorage.getItem("user")).currency
    };
  },
  methods: {
    async exportToExcel() {
      try {
        const response = await axios.post("/fetch_my_organization_accounts", {
          type: 1,
        });
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "bank_accounts.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    // fetch safes data
    FetchAccounts(page = 1) {
      axios
        .post(`/fetch_my_organization_accounts?page=${page}`, {
          type: 1,
          word: this.accountText,
          per_page: 10,
        })
        .then(({ data }) => {
          // ?page=" + page
          this.accounts = data.data;
          this.loading = false;
          // console.log(this.accounts)
        });
    },
    //delete safe
    deleteAccount(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.accounts.data[index].id;
          const fromClick = true;
          axios
            .delete(`/organization_accounts/${delete_id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.accounts.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    //Disable Account
    disableAccount(index, active) {
      Swal.fire({
        title:
          active == 1 ? this.$t("check_disable") : this.$t("check_activate"),
        text:
          active == 1
            ? this.$t("are_you_sure_disable")
            : this.$t("are_you_sure_activate"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText:
          active == 1
            ? this.$t("confirm_disable")
            : this.$t("confirm_activate"),
      }).then((result) => {
        if (result.isConfirmed) {
          let organization_account_id = {
            organization_account_id: this.accounts.data[index].id,
          };
          const newLocal = this.$i18n.locale;
          axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios
            .post(
              "/change_organization_account_status",
              organization_account_id
            )
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.FetchAccounts();
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    // Main Account
    onlineAccount(index) {
      Swal.fire({
        title: this.$t("check_online"),
        text: this.$t("are_you_sure_get-it-online"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_online"),
      }).then((result) => {
        if (result.isConfirmed) {
          let organization_account_id = {
            organization_account_id: this.accounts.data[index].id,
          };
          axios
            .post("/make_organization_account_online", organization_account_id)
            .then(({ data }) => {
              // console.log(data)
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.FetchAccounts();
                this.$router.push("/account");
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  created() {
    this.FetchAccounts();
  },
};
</script>

<style lang="scss" scoped>
@import "./_accounts";
</style>
