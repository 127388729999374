<script setup>
import Datepicker from "vuejs3-datepicker";
import axios from "axios";
import { ref, onMounted, computed } from "vue";
import Skeleton from "primevue/skeleton";
import HeaderPdf from "@/components/pdf/headerPdf.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import moment from "moment";
const from = ref(null);
const to = ref(null);
const loader = ref(true);
const financials = ref([]);
const getFinancials = () => {
  axios
    .post("/financial_income_statement")
    .then(({ data }) => {
      financials.value = data;
      loader.value = false;
      // console.log(financials.value);
    })
    .catch((error) => {
      console.log(error);
    });
};
const totalAsset = computed(() => {
  return financials?.value.asset?.reduce((sum, item) => sum + item.balance, 0);
});
const totalLiability = computed(() => {
  return financials?.value.discount?.reduce(
    (sum, item) => sum + item.balance,
    0
  );
});
const totalProperty = computed(() => {
  return financials?.value.property_rights?.reduce(
    (sum, item) => sum + item.balance,
    0
  );
});

onMounted(() => {
  getFinancials();
});
const filterFinancials = () => {
  const formData = new FormData();
  formData.append("from", moment(from.value).format("YYYY-MM-DD"));
  formData.append("to", moment(to.value).format("YYYY-MM-DD"));
  axios
    .post("/financial_income_statement", formData)
    .then(({ data }) => {
      financials.value = data;
    })
    .catch((error) => {
      console.log(error);
    });
};
</script>

<template>
  <div class="card_style">
    <div class="d-flex align-items-center gap-3 mb-3">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />

      <h4>
        {{ $t("Financial Center") }}
      </h4>
    </div>
    <form @submit.prevent="filterFinancials" class="row">
      <div class="col-4">
        <label for="from">{{ $t("from") }}</label>
        <Datepicker
          v-model="from"
          :not-after="to"
          name="from"
          :placeholder="$t('From')"
        />
      </div>
      <div class="col-4">
        <label for="to">{{ $t("to") }}</label>
        <Datepicker
          v-model="to"
          :not-before="from"
          name="from"
          :placeholder="$t('To')"
        />
      </div>
      <div class="col-4 d-flex gap-2 align-items-center justify-content-end">
        <button type="submit" class="btn PrimaryButton">
          {{ $t("filter") }}
        </button>
        <button class="PrimaryButton btn" v-print="'#table'">
          {{ $t("print") }}
        </button>
      </div>
    </form>
    <hr />
    <div id="table" class="row">
      <HeaderPdf
        :title="$t('Financial Center')"
        :fromDate="from"
        :toDate="to"
      />
      <div class="col-6">
        <table class="table">
          <thead>
            <tr>
              <th class="text-start">{{ $t("Fixed") }}</th>
              <th class="text-start">{{ $t("amount") }}</th>
            </tr>
          </thead>
          <tbody v-if="loader">
            <tr v-for="financial in 5" :key="financial">
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="financial in financials.asset" :key="financial.id">
              <td>
                <router-link :to="`/tree/cost_center/${financial.id}`">
                  {{ financial.title_ar }}
                </router-link>
              </td>
              <td>{{ financial.balance }}</td>
            </tr>
            <tr>
              <td class="bg-success text-white">{{ $t("total") }}</td>
              <td class="bg-success text-white">{{ totalAsset }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="text-start">{{ $t("extract") }}</th>
              <th class="text-start">{{ $t("amount") }}</th>
            </tr>
          </thead>
          <tbody v-if="loader">
            <tr v-for="financial in 5" :key="financial">
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="financial in financials.discount" :key="financial.id">
              <td>
                <router-link :to="`/tree/cost_center/${financial.id}`">
                  {{ financial.title_ar }}
                </router-link>
              </td>
              <td>{{ financial.balance }}</td>
            </tr>
            <tr>
              <td class="bg-info text-white">{{ $t("total extract") }}</td>
              <td class="bg-info text-white">{{ totalLiability }}</td>
            </tr>
          </tbody>
        </table>
        <table class="table">
          <thead>
            <tr>
              <th class="text-start">{{ $t("Property rights") }}</th>
              <th class="text-start">{{ $t("amount") }}</th>
            </tr>
          </thead>
          <tbody v-if="loader">
            <tr v-for="financial in 5" :key="financial">
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="financial in financials.property_rights"
              :key="financial.id"
            >
              <td>
                <router-link :to="`/tree/cost_center/${financial.id}`">
                  {{ financial.title_ar }}
                </router-link>
              </td>
              <td>{{ financial.balance }}</td>
            </tr>
            <tr>
              <td class="bg-info text-white">
                {{ $t("total Property rights") }}
              </td>
              <td class="bg-info text-white">{{ totalProperty }}</td>
            </tr>
            <tr>
              <td class="bg-success text-white">{{ $t("total") }}</td>
              <td class="bg-success text-white">
                {{ totalProperty + totalLiability }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
