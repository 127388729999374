import { defineStore } from "pinia";

export const useBookStore = defineStore({
  id: "book",
  state: () => ({
    day: null,
    status: null,
    contract: null,
  }),
  actions: {
    fetchBookInDay(day, status, contract) {
      console.log("Fetching data for:", { day, status, contract });
      // You can update the store state accordingly
      this.day = day;
      this.status = status;
      this.contract = contract;
    },
  },
});
