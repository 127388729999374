export default {
    setUser(state, { user }) {
      state.user = user.data;
      state.token = user.token;
      state.name = user.name;
      state.phone = user.phone;
      state.email = user.email;
      state.salary = user.salary;
      state.status = user.status;
      state.stage = user.stage;
      state.email_company = user.email_company
  },logout(state) {
    state.user = null;
    state.name = null;
    state.phone = null;
    state.email = null;
    state.salary = null;
    state.status = null;
    state.stage = null;
    state.email_company = null,
    localStorage.removeItem("email_company");
    localStorage.removeItem("stage");
    localStorage.removeItem("name");
    localStorage.removeItem("phone");
    localStorage.removeItem("email");
    localStorage.removeItem("salary");
    localStorage.removeItem("status");
  }
};