<template>
  <div v-if="loading" class="loading w-100">
    <Loading />
  </div>
  <div class="card_airport" v-for="(flight, index) in offers" :key="index">
    <div class="image text-center">
      <img
        :src="
          flight.company.image == null
            ? this.defaultImage
            : flight.company.image
        "
        :alt="flight.company.name"
        @error="handleImageError"
      />
      <p class="name_company">{{ flight.company.name }}</p>
    </div>
    <div class="time_card">
      <h5>{{ flight.round_trips[0].launch.time }}</h5>
      <h6>
        {{
          flight.round_trips[0].launch.airport_title == null
            ? flight.round_trips[0].launch.airport
            : flight.round_trips[0].launch.airport_title == ""
            ? flight.round_trips[0].launch.airport
            : flight.round_trips[0].launch.airport_title
        }}
      </h6>
      <span>{{ flight.round_trips[0].launch.date }}</span>
    </div>
    <div class="stop">
      <h4 :class="flight.round_trips.length == 1 ? '' : 'text-danger'">
        {{ flight.round_trips.length == 1 ? $t("live") : $t("non_live") }}
      </h4>
      <div class="flight_trip_icon">
        <hr class="auto_end" />
        <i class="fa-solid fa-plane mx-3"></i>
        <hr />
      </div>
      <h5>
        {{
          calculateTimeDifference(
            flight.round_trips[0].launch.date,
            flight.round_trips[0].launch.time,
            flight.round_trips[flight.round_trips.length - 1].arrive.date,
            flight.round_trips[flight.round_trips.length - 1].arrive.time
          )
        }}
        {{ $t("hours") }}
      </h5>
    </div>
    <div class="time_card">
      <h5>
        {{ flight.round_trips[flight.round_trips.length - 1].arrive.time }}
      </h5>
      <h6>
        {{
          flight.round_trips[flight.round_trips.length - 1].arrive
            .airport_title == null
            ? flight.round_trips[flight.round_trips.length - 1].arrive.airport
            : flight.round_trips[flight.round_trips.length - 1].arrive
                .airport_title == ""
            ? flight.round_trips[flight.round_trips.length - 1].arrive.airport
            : flight.round_trips[flight.round_trips.length - 1].arrive
                .airport_title
        }}
      </h6>
      <span>{{
        flight.round_trips[flight.round_trips.length - 1].arrive.date
      }}</span>
    </div>
    <div class="detials_card">
      <h5>
        {{ flight.price.total_amount.toFixed(2) }} /
        <span> {{ $t("low_cost") }}</span>
      </h5>
      <button class="btn PrimaryButton" @click="bookNow(index)">
        {{ $t("book_now") }}
      </button>
      <button
        class="btn serve_now"
        type="button"
        data-bs-toggle="offcanvas"
        :data-bs-target="`#offcanvasBottom${flight.id}`"
        :key="`#offcanvasBottom${flight.id}`"
      >
        {{ $t("trip_detials") }}
        <i class="fa-solid fa-chevron-down"></i>
      </button>
    </div>
    <flight-details :flight="flight" />
  </div>

  <!-- <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    id="offcanvasBottom"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasBottomLabel">
        Offcanvas bottom
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body small">...</div>
  </div> -->
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import flightDetails from "./flightDetails.vue";
import Loading from "@/components/LottieFiles/loading.vue";

export default {
  name: "airport-card",
  data() {
    return {
      defaultImage: require("@/assets/media/image/placeholder.png"), // Replace with your default image URL,
      loading: false,
    };
  },
  components: {
    flightDetails,
    Loading,
  },
  props: {
    offers: Array,
    paxs: Array,
    metadata: Object,
  },
  methods: {
    handleImageError(event) {
      // When the image fails to load, replace it with the default image
      event.target.src = this.defaultImage;
    },

    calculateTimeDifference(launchDate, launchTime, arriveDate, arriveTime) {
      const launchDateTime = new Date(`${launchDate}T${launchTime}`);
      const arriveDateTime = new Date(`${arriveDate}T${arriveTime}`);

      if (launchDateTime > arriveDateTime) {
        // Adjust date if launch time is on a different day
        arriveDateTime.setDate(launchDateTime.getDate());
      }

      const timeDifference = arriveDateTime - launchDateTime;

      const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDiff = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      return `${hoursDiff.toString().padStart(2, "0")}:${minutesDiff
        .toString()
        .padStart(2, "0")}`;
    },
    async bookNow(index) {
      this.loading = true; //the loading begin
      const metadata = {
        metadata: {
          session_id: this.metadata.session_id,
          owner_code: this.metadata.owner_code,
        },
      };

      const pax = { paxs: this.paxs };
      const flight_company_id = this.offers[index].company.id;
      localStorage.setItem(
        "BaggageAllowance",
        JSON.stringify(this.offers[index].BaggageAllowance)
      );
      const offers = {
        offers: [
          {
            // flight_company_id: this.offers[index].company.id,
            id: this.offers[index].id,
            price: this.offers[index].price.total_amount,
            currency: "USD",
            items: [],
          },
        ],
      };

      localStorage.setItem("company", this.offers[index].company.id);
      for (
        let itemIndex = 0;
        itemIndex < this.offers[index].offeritem.length;
        itemIndex++
      ) {
        const offerItem = this.offers[index].offeritem[itemIndex];

        offers.offers[0].items.push({
          // flight_company_id: offerItem,
          id: offerItem.offerItemID,
          paxid: [offerItem.Service.PaxRefID],
        });
      }

      this.dataOfferPrice = {
        ...metadata,
        ...pax,
        ...offers,
        ...flight_company_id,
      };

      localStorage.setItem(
        "flightTrips",
        JSON.stringify(this.offers[index].round_trips)
      );

      await axios
        .post("/flight/offer_price", this.dataOfferPrice)
        .then(({ data }) => {
          this.offersFlight = data.data.offers;
          this.loading = false; //the loading ended
          // console.log(this.offersFlight)
          if (data.data) {
            localStorage.setItem(
              "bookDataFlight",
              JSON.stringify(this.dataOfferPrice)
            );
            this.$router.push("/bookFlight");
            this.loading = false; //the loading ended
          } else {
            Swal.fire({
              title: "",
              text: "book not found",
              icon: "error",
            });
            this.loading = false; //the loading ended
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../view/dashboard/flight/_flight.scss";
.flight_trip_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  i {
    position: absolute;
    top: 5px;
    color: #0a909b;
    transform: rotate(180deg);
    font-size: 1.5rem;
  }
  hr {
    border-top: 1px dashed #000;
    width: 45%;
  }
  .auto_end {
    margin-inline-end: auto;
  }
}
.card_airport .stop h5 {
  margin-top: 0.5rem;
}
button.btn.PrimaryButton {
  margin: 0;
}
</style>
