<template>
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    id="addRoomoffcanvasBottom"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="addRoomoffcanvasBottomLabel">
        {{ $t("Details of rooms") }}
      </h4>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        @click="close()"
      ></button>
      <!-- <button type="button" class="btn-close text-reset" aria-label="Close" @click="close()" id="close_btn"></button> -->
    </div>
    <div class="offcanvas-body small">
      <div class="row">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="selectAll" :id="`selectAll`" @change="selectAllRooms" v-model="selectAll">
          <label for="selectAll">Select all room</label>
        </div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>{{ $t("room_name") }}</th>
              <th>{{$t("number_of_adults")}}</th>
              <th>{{$t("number_of_childrens")}}</th>
              <th>{{$t("number_of_beds")}}</th>
              <th>Choose</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="room in rooms" :key="room.id">
              <td><span class="d-block mx-2">{{ room.title }}</span></td>
              <td><span class="d-block mx-2">{{ room.room.number_of_adults }}</span></td>
              <td><span class="d-block mx-2">{{ room.room.number_of_children }}</span></td>
              <td><span class="d-block mx-2">{{ room.room.number_of_beds }}</span></td>
              <td>
                <input class="form-check-input" type="checkbox" name="room" v-model="room.selected" :id="`roomId${room.id}`">
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <button type="button" class="btn PrimaryButton" @click="addRoom()">
        {{ $t("save") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  emits: ["fetch_hotel_rooms"],
  data() {
    return {
      component: {},
      inputImages: [],
      meals: [],
      meal_value: "",
      views: [],
      view_values: "",
      room_name: "",
      pax_value: null,
      children_count: null,
      max_pax: null,
      room_value: { id: '' },
      attachment_values: [],
      attachments: [],
      videoEntry: "",
      selectAll: false,
      rooms: [],
      hotel_id: this.$route.params.id,
      images: [],
    };
  },
  methods: {
    selectAllRooms() {
      this.rooms.forEach(room => {
        room.selected = this.selectAll;
      });
    },
    clear() {
      this.component.clear = true;
    },
    onVideoChange(event) {
      this.VideoData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.VideoData);
      reader.onload = () => (this.videoEntry = reader.result);
    },
    fetchRooms() {
      axios.get(`/fetch_final_rooms`).then(({ data }) => {
        this.rooms = data.data;
      });
    },
    // fetch meals
    fetchMeals() {
      axios.post("/fetch_meal_types").then(({ data }) => {
        this.meals = data.data.data;
      });
    },
    fetchAllViews() {
      axios.post(`/fetch_all_view`).then(({ data }) => {
        this.views = data.data.data;
      });
    },
    fetch_attachments() {
      axios.post(`/fetch_amenities`, { amenity_status: 2 }).then(({ data }) => {
        this.attachments = data.data.data;
      });
    },
    fetch_hotel_room_data(roomId) {
      const formData = new FormData();
      formData.append("hotel_id", this.hotel_id);
      formData.append("room_view_id", roomId);

      axios
        .post(`/fetch_hotel_room_view_by_room_view_id`, formData)
        .then(({ data }) => {
          this.room_value = data.data.room;
          this.pax_value = data.data.pax;
          this.children_count = data.data.child_count;
          this.max_pax = data.data.max_pax;
          this.view_values = data.data.view_type;
          this.meal_value = data.data.meals;
          this.attachment_values = data.data.amenities;
          this.videoEntry = data.data.video;
          this.inputImages = data.data.images;
          this.uploadImages = data.data.images;
        });
    },
    addRoom() {
      const formData = new FormData();
      // console.log(this.view_values)
      formData.append("hotel_id", this.hotel_id);
      this.rooms.forEach((room, index) => {
        if (room.selected) {
          formData.append(`hotel_room_view_ids[${index}]`, room.id);
        }
      });
      axios
        .post(`/custom_store_hotel_room_view`, formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.resetData();
          this.$emit("fetch_hotel_rooms");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    resetData() {
      this.room_value = {}
    },
    uploadImages(e) {
      this.inputImages = e;

      // The way to append images to FormData.
      let formData = new FormData();
      let base64Strings = []; // Array to store base64 strings

      // Convert each image to base64 and append to formData
      this.inputImages.forEach((imgFile, index) => {
        const reader = new FileReader();

        // Read the file as a data URL
        reader.readAsDataURL(imgFile);

        // Once the file is loaded, convert it to base64 and append to formData
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];
          formData.append(`images[${index}]`, base64String);
          base64Strings.push(base64String);
        };
      });
    },
    close() {
      this.resetData();
      // Swal.fire({
      //     title: "Are you sure?",
      //     text: "You won't be able to revert this!",
      //     icon: "warning",
      //     showCancelButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "#d33",
      //     confirmButtonText: "Yes, delete it!"
      //     }).then((result) => {
      //     if (result.isConfirmed) {
      //         this.resetData();
      //         console.log(document.getElementById("close_btn"))
      //         document.getElementById("close_btn").setAttribute("data-bs-dismiss" , "offcanvas")

      //         Swal.fire({
      //         title: "Deleted!",
      //         text: "Your file has been deleted.",
      //         icon: "success"
      //         });
      //     }
      // });
    },
  },
  watch: {
    hotel_room_id(value) {
      if (value != undefined) {
        this.fetch_hotel_room_data(value);
        console.log(value);
      }
    },
  },
  mounted() {
    this.fetchRooms();
    this.fetchMeals();
    this.fetchAllViews();
    this.fetch_attachments();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

h4 {
  color: #0b1427;
  font-weight: 700;
  font-size: 16px;
}

.control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.image_upload {
  margin: unset;
  border: unset;
  .upload_img {
    border: 1px dashed #d0d2d7;
    color: #576b74;
    font-size: 12px;
    background-color: #d0d2d736;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    div {
      display: flex;
      flex-direction: column;
      text-align: center;
      i {
        color: #656b78;
        font-size: 18px;
      }
      a {
        color: $PrimaryColor;
        text-decoration: underline;
      }
    }
  }
}
hr {
  width: 100%;
  height: 4px;
  background-color: #e7e8eb;
  border-color: #e7e8eb;
}
.add_data {
  width: auto;
  color: $PrimaryColor;
}
.offcanvas {
  height: 80%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.image_upload {
  margin: unset;
  border: unset;
  .upload_img {
    border: 1px dashed #d0d2d7;
    color: #576b74;
    font-size: 12px;
    background-color: #d0d2d736;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    div {
      display: flex;
      flex-direction: column;
      text-align: center;
      i {
        color: #656b78;
        font-size: 18px;
      }
      a {
        color: $PrimaryColor;
        text-decoration: underline;
      }
    }
  }
}
.form-group {
  .form-control {
    padding: 0.7rem !important;
  }
}
</style>
