<template>
  <section class="card_style">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3 mb-2">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4 class="mb-0">
          {{ $t("paid") }}
        </h4>
      </div>
    </div>

    <div class="row options mb-2">
      <form class="col-lg-4 col-md-6 col-12">
        <div class="pai">
          <i class="fa-solid fa-magnifying-glass"></i>

          <input
            type="text"
            class="form-control"
            :placeholder="$t('search by name')"
          />
        </div>
      </form>
      <!-- start input group -->
      <div class="col-lg-2 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="statusValue"
            :options="statuses.map((status) => status.id)"
            :custom-label="(opt) => statuses.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            @update:model-value="fetchPayments"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->
      <div class="col-lg-3 col-md-6 col-12">
        <Datepicker
          range
          v-model="selectedDate"
          lang="ar"
          :placeholder="$t('date')"
        />
      </div>
      <div class="col-lg-2 col-md-6 col-12">
        <button
          class="SecondButton btn action d-flex align-items-center mx-2"
          @click="exportToExcel"
        >
          <i class="fa-solid fa-file-excel"></i>
          {{ $t("export_to_excel") }}
        </button>
      </div>
      <!-- start filter -->
      <div class="col-lg-1 col-md-6 col-12 d-flex">
        <button class="filter_btn btn" @click="showFilterRow()">
          <i class="fa-solid fa-filter"></i>
        </button>
      </div>
      <!-- finish filter -->
    </div>
    <div class="row show_filter" v-if="showFilter">
      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="paymentValue"
            :options="paymentss.map((payment) => payment.id)"
            :custom-label="(opt) => paymentss.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('payment_type_choose')"
            select-label=""
            :hide-selected="true"
            @update:model-value="fetchPayments"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="safeValue"
            :options="safes.map((safe) => safe.id)"
            :custom-label="(opt) => safes.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('safe_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="bankValue"
            :options="banks.map((bank) => bank.id)"
            :custom-label="(opt) => banks.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('bank_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="itemValue"
            :options="itemss.map((item) => item.id)"
            :custom-label="(opt) => itemss.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="benfitesValue"
            :options="benfites.map((benfite) => benfite.id)"
            :custom-label="(opt) => benfites.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('pay_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="col-lg-4 col-md-6 col-12 mb-4">
        <div class="input-group">
          <Multiselect
            v-model="createsValue"
            :options="creates.map((create) => create.id)"
            :custom-label="(opt) => creates.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('creates_before_choose')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
      <!-- finish input group -->

      <div class="col-lg-2 col-md-6 col-12 mb-4">
        <button class="PrimaryButton btn w-100">{{ $t("search_now") }}</button>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table paid" id="paymentsTable">
          <thead>
            <tr>
              <th>{{ $t("payment_methods") }}</th>
              <th>{{ $t("version_date") }}</th>
              <th>{{ $t("discription") }}</th>
              <th>{{ $t("the amount") }}</th>
              <th>{{ $t("usage") }}</th>
              <th>{{ $t("payment_type") }}</th>
              <th>{{ $t("the recepit") }}</th>
              <th>{{ $t("Status") }}</th>
              <th></th>
              <!-- <th></th> -->
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 8" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in payments.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ formatDate(item.created_at) }}</td>
              <td>{{ item.report }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.employee == null ? "" : item.employee.name }}</td>
              <td>
                <span v-if="item.payment_way == 1"> Cash</span>
                <span v-if="item.payment_way == 2"> Online</span>
              </td>

              <td>
                <router-link class="btn attachment" to="#">
                  {{ $t("download_attachment") }}
                </router-link>
              </td>

              <td>
                <span
                  :class="[
                    'badge',
                    item.status === 1
                      ? 'bg-primary'
                      : item.status === 2
                      ? 'bg-success'
                      : '',
                  ]"
                >
                  {{
                    item.status === 1
                      ? $t("new")
                      : item.status === 2
                      ? $t("Accept")
                      : ""
                  }}
                </span>
              </td>

              <td>
                <div>
                  <span @click="changeBookingStatus(index, 2)" class="ok mx-2"
                    ><i class="fa-solid fa-circle-check"></i
                  ></span>
                  <span @click="changeBookingStatus(index, 3)" class="wrong"
                    ><i class="fa-solid fa-circle-xmark"></i
                  ></span>
                </div>
                <!-- <div>
                <span class="return">
                  <i class="fa-solid fa-arrow-rotate-left"></i>
                </span>
              </div> -->
              </td>

              <!-- <td>
              <button
                class="btn attachment"
                type="button"
                data-bs-toggle="offcanvas"
                :data-bs-target="`#offcanvasBottom${index}`"
                :key="`#offcanvasBottom${index}`"
              >
                {{ $t("details_booking") }}
              </button>
            </td> -->
              <!-- offcavans -->
              <!-- <div
              :key="'offcanvas' + index"
              class="offcanvas offcanvas-bottom"
              :id="'offcanvasBottom' + index"
              aria-labelledby="offcanvasBottomLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                  <span> {{ $t("payment_methos_detials") }}</span>
                  {{ item.id }}
                </h5>
                <button
                  type="button"
                  class="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body small">
                <div class="information">
                  <div class="row">
                    <div class="col-lg-8 col-12">
                      <div>
                        <span>{{ $t("item") }}</span>
                        <h6>Alwalid Golden</h6>
                      </div>
                      <div>
                        <span>{{ $t("date") }}</span>
                        <h6>{{ item.created_at }}</h6>
                      </div>

                      <div>
                        <span>Amount to pay</span>
                        <h6>12815.00</h6>
                      </div>

                      <div>
                        <span>{{ $t("payment_type") }}</span>
                        <h6>تسبقة</h6>
                      </div>
                    </div>

                    <div class="col-lg-4 col-12">
                      <div>
                        <span>{{ $t("the amount") }}</span>
                        <h6>200.000 SAR</h6>
                      </div>
                    </div>
                  </div>
                  <div class="booking">
                    <div class="row">
                      <div class="col-lg-6 col-6"></div>
                      <div class="col-lg-6 col-6">
                        <div class="recipt">
                          <h6>{{ $t("receipt") }}</h6>
                          <div class="row">
                            <div class="col-lg-6 col-12">
                              <div class="img">
                                <img
                                  src="@/assets/media/image/recit.png"
                                  alt="resipt"
                                />
                              </div>
                            </div>
                            <div class="col-lg-6 col-12"></div>
                          </div>
                          <div class="content"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
              <!-- offcavans -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="payments"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchPayments"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import Skeleton from "primevue/skeleton";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import "t-datepicker";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  name: "paid-hotel",
  components: {
    pagination,
    Multiselect,
    Datepicker,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      loading: true,
      bookId: null,
      value: "",
      report: "",
      service_types: [{ value: 1 }, { value: 2 }],
      selectedServiceType: 1,
      paidValues: "",
      paids: [
        {
          id: 1,
          title: "Online",
        },
        {
          id: 2,
          title: "Cash",
        },
      ],
      bankValues: "",

      safes: [
        {
          id: 1,
          title: "safe 1",
        },
        {
          id: 2,
          title: "save 2",
        },
      ],
      safeValues: "",
      payments: [],
      // status: 0,
      statuses: [
        {
          id: 1,
          title: this.$t("new"),
        },
        {
          id: 2,
          title: this.$t("Accept"),
        },
      ],
      statusValue: "",
      paymentss: [
        {
          id: 1,
          title: "cash",
        },
        {
          id: 2,
          title: "تحويل",
        },
      ],
      paymentValue: "",
      bankValue: "",
      banks: [
        {
          id: 0,
          title: "bank2",
        },
        {
          id: 1,
          title: "bank2",
        },
      ],

      safeValue: "",
      itemss: [
        {
          id: 1,
          title: "item 1",
        },
        {
          id: 2,
          title: "item 2",
        },
      ],
      itemValue: "",
      benfites: [
        {
          id: 1,
          title: "Benfite 1",
        },
        {
          id: 2,
          title: "Benfite 2",
        },
      ],
      benfitesValue: "",
      createsValue: "",
      creates: [
        {
          id: 1,
          title: "Create 1",
        },
        {
          id: 2,
          title: "Create 2",
        },
      ],
      showFilter: false,
      selectedDate: "",
    };
  },
  created() {
    this.bookId = this.$route.params.id;
  },
  methods: {
    async exportToExcel() {
      try {
        const response = await axios.post("/fetch_booking_money_payments");
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "booking_money_payments.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    fetchPayments(page = 1) {
      const data = {
        status: this.statusValue,
        payment_way: this.paymentValue,
        per_page: 10,
        // from: moment(this.form).format("YYYY-MM-DD"),
        // from: this.from,
        // date: this.to,
      };
      axios
        .post(`/fetch_booking_money_payments?page=${page}`, data)
        .then(({ data }) => {
          this.payments = data.data;
          this.loading = false;
          // console.log(this.payments);
        });
    },
    changeBookingStatus(index, statusBooking) {
      let confirm_id = this.payments.data[index].id;
      let statusBook = statusBooking;
      axios
        .post(`/change_booking_money_payments_status`, {
          booking_id: confirm_id,
          money_payment_status: statusBook,
        })
        .then(({ data }) => {
          //   console.log(data)
          if (data.status == true) {
            Swal.fire("", data.message, "success");
            this.fetchPayments();
          } else {
            Swal.fire("", data.message, "error");
          }
          return data;
        })
        .catch((error) => {
          Swal.fire("", error.response.data.message, "error");
        });
    },
    showFilterRow() {
      this.showFilter = !this.showFilter;
    },

    formatDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },
  },
  mounted() {
    this.fetchPayments();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.card_style {
  background-color: white;
  padding: 15px 40px;
  margin: 20px 0;
  border-radius: 8px;
  font-family: "regular";

  h4,
  label {
    font-family: "bold";
  }

  .safe-status {
    input {
      width: 20px;
      height: 20px;
      border-radius: 5px;
    }
  }

  form {
    .safe-status {
      input:checked {
        background-color: $PrimaryColor;
      }
    }

    label {
      font-family: "regular";
    }
  }

  .initial-balance::-webkit-outer-spin-button,
  .initial-balance::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .initial-balance[type="number"] {
    -moz-appearance: textfield;
  }
}

.main_header_style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  // margin-top: 20px;

  h4 {
    font-size: 2rem;
    color: $PrimaryText;
    font-family: "bold";
    margin-bottom: 1.25rem;
  }
}

.options {
  form {
    position: relative;

    .form-control {
      border: 1px solid #aaa;
      outline: none;
      border-radius: 5px;
      width: 100%;
      padding: 10px 50px;
      padding-inline-start: 3rem !important;
    }

    i {
      position: absolute;
      top: 1.3rem;
      transform: translateY(-50%);
      right: 30px;
      font-size: 20px;
    }
  }

  .PrimaryButton {
    width: 100%;
  }

  .multiselect {
    border-radius: 4px;
    border: 1px solid #9ca2ac;
  }
}

.table {
  thead {
    border-radius: 10px 10px 0px 0px;
    border-radius: 20px;

    // border: 1px solid $PrimaryColor !important ;
    tr {
      border-radius: 10px 10px 0px 0px !important;

      // border: 1px solid $PrimaryColor !important ;
      th {
        text-align: start !important;
        font-family: "bold";
        font-weight: 100;
        background-color: #eef4f4;
        color: #656b78;
      }
    }
  }

  tbody {
    tr {
      border-bottom: unset !important;
      border-top: unset !important;

      &.main {
        td {
          background-color: #0a909b17;
        }
      }

      td {
        padding: 0.75rem 0;
        font-family: "regular";
        font-size: 0.9rem;
        border-bottom: unset !important;
        border-top: unset !important;
        color: black;
        padding-inline-start: 0 !important;

        .userImage {
          margin-inline-end: 20px;
        }

        .btn-sm {
          padding: 0.35rem 0.55rem;
        }

        .status {
          display: inline-block;
          width: 88px;
          border-radius: 24px;
          height: 40px;
          line-height: 40px;
          text-align: center;

          &.main {
            background-color: #1664b857;
            color: #1664b8;
          }

          &.active {
            background-color: #88c64b3d;
            color: #88c64b;
          }

          &.not_active {
            color: #656b78;
            background-color: #656b7852;
          }
        }
      }
    }
  }

  .fa-bars {
    cursor: pointer;
  }

  .dropdown {
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      padding: 5px;
      // width: 100%;
      outline: none;
      text-align: start;
      font-size: 0.8rem;

      .dropdown-item {
        padding: 4px 5px;

        i {
          margin-inline-end: 7px;
        }
      }

      li {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      div {
        cursor: pointer;
        padding: 0;
        padding-left: 5px;
        padding-right: 5px;
      }

      a {
        color: black;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .dropdown-item.active {
      background-color: white;
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
      border-color: transparent;
    }
  }
}

.dataTables_filter {
  width: 100%;
  margin-bottom: 1.5rem;

  label {
    display: flex;
    align-items: center;
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.form_topic {
  width: 100%;

  .input-group {
    position: relative;

    .icon_form {
      position: absolute;
      right: 0.5rem;
      top: 55%;
      z-index: 999;

      i {
        color: $SecondText;
      }
    }

    .form-label {
      width: 100%;
      font-size: 1rem;
      color: $PrimaryText;
      font-family: "bold";
    }

    .form-control {
      border-radius: 2px;
      background-color: transparent;
      color: $SecondText;
      font-family: "regular";
      border: 1px solid #dee2e6;
      border-radius: 4px !important;
      font-size: 0.8rem;
      padding: 0.7rem;
      padding-inline-start: 1%;

      &:focus {
        box-shadow: none !important;
      }

      &::placeholder {
        color: $SecondText;
        font-family: "light" !important;
        font-size: 0.9rem;
      }
    }

    .multiselect {
      .multiselect__tags {
        border-radius: 2px;
        padding-inline-start: 4%;
        background-color: transparent;
        color: $SecondText;
        font-family: "regular";
        border: 1px solid #dee2e6;
        border-radius: 4px !important;
      }
    }

    .xyz {
      padding-inline-start: 2rem !important;
    }
  }
}

.edit_btn {
  color: #0a909b;
  font-size: 1rem;
}

.remove_btn {
  color: #e23535;
  font-size: 1rem;
}

.pagination {
  .page-item {
    margin-inline-end: 1%;

    .page-link {
      color: $PrimaryColor;
      font-family: "regular";
      border-radius: 8px;
    }

    &.active {
      .page-link {
        background-color: $PrimaryColor;
        border-color: $PrimaryColor;
        color: #fff;
      }
    }
  }
}

.safe-details {
  .col-3 {
    margin-bottom: 20px;
  }

  i {
    color: $PrimaryColor;
    background-color: #0a8f9b27;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    margin-inline-end: 15px;
  }

  p {
    margin-bottom: 10px;
    color: #130c04;

    &.title {
      color: #576b74;
    }
  }
}

.main_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.transactions_select {
  padding-inline-start: 4%;
  background-color: transparent;
  color: $SecondText;
  font-family: "regular";
  border: 1px solid #dee2e6;
  border-radius: 4px !important;
  padding: 15px 30px;
}

.save {
  background-color: $PrimaryColor;
  color: #fff;
  width: 236px;
  height: 60px;
  opacity: 0.5;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
}

.form-check-input {
  width: 20%;
  float: right;
  margin-left: 1em;
}

.form-check-inline {
  margin-top: 7%;
}

.form-check-input:checked {
  background-color: $PrimaryColor;
  border-color: $PrimaryColor;
}

.btn.attachment {
  border: unset;
  border-bottom: 1px solid $PrimaryColor;
  border-radius: 0;
  color: $PrimaryColor;
}

.ok {
  color: #88c64b;
  font-size: 1.5rem;
  cursor: pointer;
}

.wrong {
  color: #e23535;
  font-size: 1.5rem;
  cursor: pointer;
}

.return {
  color: $PrimaryColor;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  h5 {
    font-size: 24px;
    font-family: "bold";
  }
}

.modal-footer {
  justify-content: center;
  border-top: unset;
}

.vuejs3-datepicker,
.v-calendar {
  width: 100% !important;
}

.v-calendar {
  border-radius: 4px !important;
  border: 1px solid #9ca2ac !important;
}

.filter_btn {
  border-radius: 4px;
  border: 1px solid #9ca2ac;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $PrimaryColor;
  height: 40px;
  transition: 0.25s all ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: $PrimaryColor;
    color: #fff;

    i {
      color: white;
    }
  }

  i {
    font-size: 1.2rem;
    color: $PrimaryColor;
    transition: 0.25s all ease-in-out;
  }
}

.offcanvas-header {
  .offcanvas-title {
    font-size: 32px;
    font-family: "bold";
    span {
      color: #656b78;
      font-family: "light";
      font-size: 16px;
      display: block;
    }
  }

  button {
    border: 1px solid black;
    border-radius: 50%;
    margin-right: auto;
  }
}

.offcanvas {
  border-radius: 55px 55px 0 0;
  padding: 2rem;
  height: fit-content;

  .offcanvas-body {
    .cancel {
      background-color: $PrimaryColor;
      color: #fff;
      padding: 10px 40px;
    }

    .transfer-data {
      border: 1px solid #9ca2ac;
      border-radius: 10px;
      padding: 20px;

      h5 {
        font-size: 24px;
        margin-bottom: 20px;
      }

      .bank {
        height: 100%;
      }

      label {
        font-family: bold;
        font-size: 16px;
      }

      input,
      select {
        width: 100%;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 0.75rem;
        font-size: 0.8rem;

        &:disabled {
          background-color: #d0d2d7;
        }
      }
    }

    input[type="date"],
    input {
      width: 100%;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      padding: 0.75rem;
      font-size: 0.8rem;
    }

    .save {
      background-color: $PrimaryColor;
      color: #fff;
      width: 236px;
      height: 60px;
      opacity: 0.5;
      transition: 0.3s;

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
