<template>
  <section class="card_style">
    <div class="d-flex align-items-center gap-3 mb-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>{{ $t("report") }} {{ $t(Type) }}</h4>
    </div>

    <div class="row mb-2">
      <div class="col-12 col-lg-3">
        <div class="input-group stu">
          <datepicker
            v-model="from"
            name="from_date"
            class="w-100"
            :placeholder="$t('from')"
          ></datepicker>
        </div>
      </div>

      <div class="col-12 col-lg-3">
        <div class="input-group stu">
          <datepicker
            v-model="to"
            name="to_date"
            class="w-100"
            :placeholder="$t('to')"
          ></datepicker>
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="form-group search">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            :placeholder="$t('search by name')"
            class="form-control w-100"
            v-model="search_center"
            @keyup="fetch_motion_detections"
          />
        </div>
      </div>
      <div class="col-12 col-lg-3">
        <div class="d-flex align-items-center w-100">
          <button
            class="SecondButton btn action d-flex align-items-center justify-content-center"
            @click="exportToExcel"
          >
            <i class="fa-solid fa-file-excel"></i>
            {{ $t("export_to_excel") }}
          </button>
          <button
            class="btn SecondButton mx-2"
            v-print="'#printMe'"
            title="PDF"
          >
            <i class="fa-solid fa-file-pdf"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row" v-if="motion_detections?.data?.length > 0" id="printMe">
      <HeaderPdf
        :title="$t('report') + ' ' + $t(Type)"
        :fromDate="from"
        :toDate="to"
      />

      <table class="table">
        <thead>
          <tr>
            <th class="no-print">{{ $t("ID") }}</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("date") }}</th>
            <th>{{ $t("type") }}</th>
            <th>{{ $t("debtor") }}</th>
            <th>{{ $t("creditor") }}</th>
            <th>{{ $t("balance") }}</th>
            <th class="no-print">{{ $t("details") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 8" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in motion_detections?.data" :key="index">
            <td class="no-print">{{ item?.id }}</td>
            <td>{{ item?.name }}</td>
            <td>{{ item?.date }}</td>
            <td>{{ item?.type !== null ? $t(item?.type) : "-" }}</td>
            <td>{{ item?.debtor_amount }}</td>
            <td>{{ item?.creditor_amount }}</td>
            <td>{{ item?.final_amount }}</td>
            <td class="no-print">
              <router-link :to="`/tree/${type}/${item.id}`" class="btn btn-info"
                ><i class="fa-solid fa-info"></i
              ></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p v-else class="text-center text fs-1">{{ $t("no_data") }}....</p>
    <div class="row">
      <pagination
        :data="motion_detections"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_motion_detections"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import Datepicker from "vuejs3-datepicker";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import moment from "moment";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import Skeleton from "primevue/skeleton";
import HeaderPdf from "@/components/pdf/headerPdf.vue";

export default {
  name: "costCenterIndex",
  components: {
    pagination,
    Datepicker,
    AddFavDailog,
    Skeleton,
    HeaderPdf,
  },

  data() {
    return {
      loading: true,
      motion_detections: [],
      search_center: "",
      Type: this.$route.params.type,
      response: [],
      from: "",
      to: "",
      type: this.$route.params.type,
    };
  },
  watch: {
    $route(to) {
      this.Type = to.params.type;
      this.fetch_motion_detections();
    },
    from: "fetch_motion_detections",
    to: "fetch_motion_detections",
  },
  methods: {
    async exportToExcel() {
      try {
        if (this.Type == "safe") {
          this.response = await axios.post("/fetch_safe_report", {
            per_page: 0,
          });
        } else if (this.Type == "cost_center") {
          this.response = await axios.post("/fetch_cost_center_report", {
            per_page: 0,
          });
        } else if (this.Type == "client") {
          this.response = await axios.post("/fetch_client_report", {
            per_page: 0,
          });
        } else if (this.Type == "client-accounts") {
          this.response = await axios.post(
            "/fetch_organization_account_report",
            {
              per_page: 0,
            }
          );
        } else if (this.Type == "suppliers") {
          this.response = await axios.post("/fetch_supplier_report", {
            per_page: 0,
          });
        } else if (this.Type == "sales") {
          this.response = await axios.post("/fetch_sales_report", {
            per_page: 0,
          });
        } else if (this.Type == "purchases") {
          this.response = await axios.post("/fetch_purchase_report", {
            per_page: 0,
          });
        } else if (this.Type == "client_balance") {
          this.response = axios.post("/fetch_client_balance_report", {
            per_page: 0,
          });
        }
        const responseData = this.response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, `${this.Type}.xlsx`);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    fetch_motion_detections(page = 1) {
      const formData = new FormData();
      if (this.search_center != "") {
        formData.append("word", this.search_center);
      }
      if (this.from != "") {
        formData.append("from", moment(this.from).format("YYYY-MM-DD"));
      }
      if (this.to != "") {
        formData.append("to", moment(this.to).format("YYYY-MM-DD"));
      }
      formData.append("per_page", 10);

      if (this.Type == "safe") {
        axios
          .post(`/fetch_safe_report?page=${page}`, formData)
          .then(({ data }) => {
            this.motion_detections = data.data;
            this.loading = false;
          });
      } else if (this.Type == "cost_center") {
        axios
          .post(`/fetch_cost_center_report?page=${page}`, formData)
          .then(({ data }) => {
            this.motion_detections = data.data;
            this.loading = false;
            // console.log(this.motion_detections.data);
          });
      } else if (this.Type == "client") {
        axios
          .post(`/fetch_client_report?page=${page}`, formData)
          .then(({ data }) => {
            this.motion_detections = data.data;
            this.loading = false;
          });
      } else if (this.Type == "client-accounts") {
        axios
          .post(`/fetch_organization_account_report?page=${page}`, formData)
          .then(({ data }) => {
            this.motion_detections = data.data;
            this.loading = false;
          });
      } else if (this.Type == "suppliers") {
        axios
          .post(`/fetch_supplier_report?page=${page}`, formData)
          .then(({ data }) => {
            this.motion_detections = data.data;
            this.loading = false;
          });
      } else if (this.Type == "sales") {
        axios
          .post(`/fetch_sales_report?page=${page}`, formData)
          .then(({ data }) => {
            this.motion_detections = data.data;
            this.loading = false;
          });
      } else if (this.Type == "purchases") {
        axios
          .post(`/fetch_purchase_report?page=${page}`, formData)
          .then(({ data }) => {
            this.motion_detections = data.data;
            this.loading = false;
          });
      } else if (this.Type == "client_balance") {
        axios
          .post(`/fetch_client_balance_report?page=${page}`, formData)
          .then(({ data }) => {
            this.motion_detections = data.data;
            this.loading = false;
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
  },
  mounted() {
    this.fetch_motion_detections();
    // console.log(this.Type);
  },
};
</script>

<style lang="scss" scope>
.search {
  position: relative;
  i {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.PrimaryButton {
  margin-top: auto !important;
}

@media print {
  .no-print {
    display: none !important;
  }
  .table {
    thead {
      tr {
        th {
          padding-inline: 1.5rem !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-inline: 1.5rem !important;
        }
      }
    }
  }
}
</style>
