<template>
  <section class="card_style">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3 mb-3">
        <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />

        <h4>
          <span v-if="feature_id">{{ $t("edit feature") }}</span>
          <span v-else>{{ $t("add feature") }}</span>
        </h4>
      </div>
    </div>

    <form @submit.prevent="addFeature()">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.lang }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.Form.title.$error">{{
              v$.Form.title.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="form-group">
            <label for="">{{ $t("describtion") }}</label>
            <div class="accordion" id="accordionExample2">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("describtion") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_content, index) in Form.subtitle"
                      :key="index"
                    >
                      <label class="mt-3"
                        >{{ $t("describtion") }} ({{
                          blog_content.lang
                        }})</label
                      >
                      <Editor
                        v-model="blog_content.name"
                        editorStyle="height: 120px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span class="input-errors" v-if="v$.Form.subtitle.$error">{{
              v$.Form.subtitle.$errors[0].$message
            }}</span>
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Editor from "primevue/editor";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "webFeaturesAdd",
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      options: {
        placeholder: this.$t("describtion"),
        theme: "snow",
      },
      Form: {
        title: [],
        subtitle: [],
      },
      app_section_id: this.$route.params.webId,
      feature_id: this.$route.params.id,
      editBtn: false,
    };
  },
  components: {
    Editor,
    AddFavDailog,
  },
  validations() {
    return {
      Form: {
        title: this.Form.title.map(() => {
          return {
            name: { required },
          };
        }),
        subtitle: this.Form.subtitle.map(() => {
          return {
            name: { required },
          };
        }),
      },
    };
  },
  methods: {
    setFeatureTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          lang: language,
          name: "",
        });
        this.Form.subtitle.push({
          lang: language,
          name: "",
        });
      });
    },
    addFeature() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const params = new URLSearchParams();
        this.Form.title.forEach((ele) => {
          params.append(`title_${ele.lang}`, ele.name);
        });
        this.Form.subtitle.forEach((ele) => {
          params.append(`subtitle_${ele.lang}`, ele.name);
        });

        if (this.editBtn) {
          axios
            .put(`/website/web_features/${this.feature_id}`, params)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          axios
            .post("/website/web_features", params)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
    showService() {
      axios.post(`/web_features`).then(({ data }) => {
        if (data.data.data.length != 0) {
          this.Form.title = data.data.data[0].title.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.title,
            };
          });
          this.Form.subtitle = data.data.data[0].subtitle.map((ele) => {
            return {
              lang: ele.locale,
              name: ele.subtitle,
            };
          });
          this.editBtn = true;
          this.feature_id = data.data.data[0].id;
        } else {
          this.editBtn = false;
        }
      });
    },
  },
  mounted() {
    this.showService();
    this.setFeatureTitle();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
</style>
