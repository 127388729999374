<template>
  <section class="show_receipt card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{ $t("recipt") }}
      </h4>
    </div>

    <div class="main_header_style mb-3 mt-2">
      <div class="d-flex flex-row justify-content-between w-100 mb-2">
        <input
          type="text"
          v-model="reciptText"
          @keyup="FetchRecipt((page = 1))"
          class="form-control w-50 mb-2"
          :placeholder="$t('search...')"
        />
        <div class="d-flex align-items-center gap-3">
          <router-link
            :to="{ name: 'AddReceipt' }"
            class="PrimaryButton btn rec"
            ><i class="fa-solid fa-plus"></i>
            {{ $t("new_recipt") }}</router-link
          >
          <button
            class="SecondButton btn action d-flex align-items-center mx-2"
            @click="exportToExcel"
          >
            <i class="fa-solid fa-file-excel"></i>
            {{ $t("export_to_excel") }}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table receipt">
          <thead>
            <tr>
              <th>{{$t("Bond No")}}</th>
              <th>{{$t("Registration_number")}}</th>
              <th>{{ $t("created_by") }}</th>
              <th>{{ $t("recipient") }}</th>
              <th>{{ $t("the_value") }}</th>
              <th>{{ $t("statement") }}</th>
              <th>{{ $t("construction_date") }}</th>
              <th>{{ $t("account") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 7" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in recipt.data" :key="index">
              <td>{{ item.main_serial_number }}</td>
              <td>{{ item.exchange_serial_number ? item.exchange_serial_number : item.receipt_serial_number ? item.receipt_serial_number : "" }}</td>
              <td>{{ item?.employee?.name ? item?.employee?.name : "-" }}</td>
              <td>{{ item.from[0]?.branch?.title }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.text }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.to[0]?.branch?.title }}</td>
              <td>
                <PdfDailog :id="item.id" type="2" />
                <!--                <router-link-->
                <!--                  class="btn btn-success text-white mx-1 edit_btn"-->
                <!--                  :to="`/add-receipt/copy/${item.id}`"-->
                <!--                >-->
                <!--                  <i class="fa-solid fa-copy"></i>-->
                <!--                </router-link>-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="recipt"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchRecipt"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
// import Multiselect from "vue-multiselect";
import "jquery/dist/jquery.min.js";
import PdfDailog from "@/components/Dialogs/PdfDialog.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import $ from "jquery";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import * as XLSX from "xlsx/xlsx.mjs";
import Skeleton from "primevue/skeleton";
export default {
  name: "show-recipt",
  components: {
    pagination,
    AddFavDailog,
    PdfDailog,
    Skeleton,
  },
  data() {
    return {
      loading: true,
      reciptText: "",
      bankAccount: "",
      banks: [
        {
          id: 1,
          title: "xxxx",
        },
        {
          id: 2,
          title: "xxxx",
        },
      ],
      recipt: {
        data: [],
      },
      isMenuVisible: true,
    };
  },
  methods: {
    async exportToExcel() {
      try {
        const response = await axios.post("/fetch_daily_restrictions", {
          type: 2,
        });
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "daily_restrictions_recipt.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    // fetch recipt data
    FetchRecipt(page = 1) {
      axios
        .post(`/fetch_daily_restrictions?page=${page}&type=2`, {
          word: this.reciptText,
          per_page: 10,
        })
        .then(({ data }) => {
          this.recipt = data.data;
          this.loading = false;
          //  console.log(this.exchange);
        });
    },

    //delete recipt
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.recipt.data[index].id;
          const fromClick = true;
          axios
            .delete("/receipt/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.recipt.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchRecipt();
  },

  updated() {
    // axios.get("API_Goes_Here").then((response) => {
    //   this.products = response.data;
    // });
    $("#employTable").DataTable({
      bPaginate: false,
      retrieve: true,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("employ"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("employ"),
      },
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_recipt";
</style>
