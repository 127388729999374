<template>
  <Skeleton width="15%" class="mb-2"></Skeleton>
  <div class="row my-4">
    <div class="col-lg-3">
      <Skeleton width="30%" class="mb-2"></Skeleton>
      <Skeleton height="2rem" class="mb-2"></Skeleton>
    </div>
    <div class="col-lg-3">
      <Skeleton width="30%" class="mb-2"></Skeleton>
      <Skeleton height="2rem" class="mb-2"></Skeleton>
    </div>
    <div class="col-lg-3">
      <Skeleton width="30%" class="mb-2"></Skeleton>
      <div class="d-flex align-items-center gap-3">
        <Skeleton height="2rem" class="mb-2"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
      </div>
    </div>
    <div class="col-lg-3">
      <Skeleton width="30%" class="mb-2"></Skeleton>
      <div class="d-flex align-items-center gap-3">
        <Skeleton height="2rem" class="mb-2"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <Skeleton height="2rem" width="20%" class="my-2"></Skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
export default {
  components: {
    Skeleton,
  },
};
</script>

<style></style>
