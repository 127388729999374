<template>
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    :id="`editOffcanvasBottom${branchId}`"
    aria-labelledby="editOffcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <h4 class="offcanvas-title" id="editOffcanvasBottomLabel">
        {{ $t("Edit Account") }}
      </h4>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>

    <div class="offcanvas-body small">
      <form class="charge_wallet_request" @submit.prevent="edit(branchId)">
        <div class="row my-4">
          <div class="col-lg-6">
            <label for="name" class="form-label"
              >{{ $t("account name") }} AR</label
            >
            <input
              type="text"
              :placeholder="$t('write account name')"
              id="name"
              class="form-control"
              v-model="title_ar"
            />
          </div>
          <div class="col-lg-6">
            <label for="name" class="form-label"
              >{{ $t("account name") }} EN</label
            >
            <input
              type="text"
              :placeholder="$t('write account name')"
              id="name"
              class="form-control"
              v-model="title_en"
            />
          </div>
        </div>

        <div class="row my-4">
          <div class="col-lg-6 col-12">
            <h5>{{ $t("display in") }}</h5>
            <div class="row">
              <div class="form-check col-lg-3">
                <input
                  type="radio"
                  id="income"
                  value="1"
                  v-model="report_type"
                />
                <label for="income">
                  {{ $t("income value") }}
                </label>
              </div>
              <div class="form-check col-lg-3">
                <input
                  type="radio"
                  id="budget"
                  value="2"
                  v-model="report_type"
                />
                <!-- 1=>income_list;2=>budget;3=>both;  report_type -->
                <label for="budget">
                  {{ $t("the budget") }}
                </label>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <h5>{{ $t("account type") }}</h5>
            <div class="row">
              <div class="form-check col-lg-3">
                <input
                  type="radio"
                  id="debtor"
                  v-model="balance_type"
                  value="1"
                />
                <label for="debtor">
                  {{ $t("debtor") }}
                </label>
              </div>
              <div class="form-check col-lg-3">
                <input
                  type="radio"
                  id="creditor"
                  v-model="balance_type"
                  value="2"
                />
                <!-- 1=>debtor;2=>creditor;3=>both;   balance_type -->
                <label for="creditor">
                  {{ $t("creditor") }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-lg-6">
            <label for="statement" class="form-label">{{
              $t("the statement")
            }}</label>
            <textarea
              :placeholder="$t('account describtion')"
              id="statement"
              class="form-control"
              v-model="statement"
            ></textarea>
          </div>
        </div>
        <button
          type="submit"
          class="btn save"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          {{ $t("edit") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "editBranches",
  props: ["branch", "parentID"],
  emits: ["parentData"],
  data() {
    return {
      branchId: this.branch.id,
      title_ar: "",
      title_en: "",
      balance: "",
      statement: "",
      report_type: "",
      balance_type: "",
    };
  },
  mounted() {
    // console.log(this.branch)
    this.branch_id = this.branch.id;
    this.parent_id = this.parentID;
    this.title_ar = this.branch.title_ar;
    this.title_en = this.branch.title_en;
    this.balance = this.branch.opening_balance;
    this.statement = this.branch.text;
    this.report_type = this.branch.report_type;
    this.balance_type = this.branch.balance_type;
  },
  methods: {
    edit(branchId) {
      // console.log(this.branch)
      // console.log(this.report_type)
      // console.log(this.balance_type)
      // console.log(branchId)
      // console.log(this.parent_id)
      axios
        .put(`/tree_branch/${branchId}`, {
          branch_id: this.branch_id,
          parent_id: this.parent_id,
          title_ar: this.title_ar,
          title_en: this.title_en,
          text: this.statement,
          opening_balance: this.balance,
          report_type: this.report_type,
          balance_type: this.balance_type,
        })
        .then(({ data }) => {
          // console.log(data)

          // this.$emit('edit_data' , data.data)

          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          // fetch parent branch
          let parent_element = [];
          let parent_id = { parent_id: this.branch.parent_id };
          axios.post("/fetch_tree_branches", parent_id).then(({ data }) => {
            parent_element = data.data.map((branch) => {
              return {
                ...branch,
                children: [],
                showChild: false,
                deleted: false,
              };
            });
            // console.log(parent_element)
            // branch.showChild = !branch.showChild;
          });
          this.$emit("parentData", parent_element);
          // finish fetch parent branch
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../view/dashboard/wallet/_wallet.scss";
.form-check {
  display: flex;
  align-items: center;
  input[type="radio"] {
    width: 15px !important;
    height: 15px;
  }
  label {
    margin-inline-start: 10px;
  }
}
</style>
