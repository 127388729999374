<template>
  <section class="card_style">
    <h3 class="main_header">{{$t("add_attachment_type")}}</h3>

    <form @submit.prevent="add_attachment_type()">
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="type">
                            <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#typeOne"
                            aria-expanded="false"
                            aria-controls="typeOne"
                            >
                            {{ $t("attachment_type") }}
                            </button>
                        </h2>
                        <div
                            id="typeOne"
                            class="accordion-collapse collapse"
                            aria-labelledby="type"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body">
                            <div
                                class="form-group"
                                v-for="(attachment, index) in attachment_type"
                                :key="index"
                            >
                                <label for="name"
                                >{{ $t("name") }} ({{ attachment.lang }})</label
                                >
                                <input
                                type="text"
                                :placeholder="$t('name')"
                                class="form-control"
                                :id="`name${index}`"
                                v-model="attachment.name"
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button type="submit" class="btn PrimaryButton">{{$t("save")}}</button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    name: "addAttachmentType",
    data(){
        return{
            languages: ["ar","en","id"],
            attachment_type: [],
        }
    },
    methods:{
        set_attachment_type(){
            this.languages.forEach((ele)=>{
                this.attachment_type.push({
                    lang: ele,
                    name: ""
                })
            })
        },
        fetch_attachment_data(){
            axios.get(`/amenity_types/${this.$route.params.id}`).then(({data})=>{
                this.attachment_type = data.data.titles.map((ele)=>{
                    return{
                        lang: ele.locale,
                        name: ele.title
                    }
                })
            })
        },
        add_attachment_type(){
            const formData = new FormData();

            this.attachment_type.forEach((ele)=>{
                formData.append(`title_${ele.lang}` , ele.name);
            });

            if(this.$route.params.id != undefined)  {
                axios.post(`/update_amenity_type/${this.$route.params.id}` , formData).then(({data})=>{
                    Swal.fire({
                        title: "",
                        text: data.message,
                        icon: "success",
                    });
                    this.$router.push("/hotel/all_attachment_type");
                }).catch((error) => {
                    Swal.fire({
                        title: "",
                        text: error.response.data.message,
                        icon: "error",
                    });
                });
            }
            else{
                axios.post(`/amenity_types` , formData).then(({data})=>{
                    Swal.fire({
                        title: "",
                        text: data.message,
                        icon: "success",
                    });
                    this.$router.push("/hotel/all_attachment_type");
                }).catch((error) => {
                    Swal.fire({
                        title: "",
                        text: error.response.data.message,
                        icon: "error",
                    });
                });
            }
        }
    },
    mounted(){
        this.set_attachment_type();
        if(this.$route.params.id != undefined){
            this.fetch_attachment_data();
        }
    }
}
</script>

<style scoped>
.main_header{
    font-size: 20px;
    font-weight: bold;
}
</style>