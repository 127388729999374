
<template>
    <button class="btn btn-secondary text-white mx-1" type="button" @click="visible = true" v-tooltip.top="$t('change_contract_price')">
        <i class="fa-solid fa-dollar-sign"></i>
    </button>
    <Dialog v-model:visible="visible" modal :dismissableMask="true" :header="$t('change_contract_price')" :style="{ width: '30vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <form @submit.prevent="editContract">
            <div class="form-group">
                <label for="">{{ $t("amount") }}</label>
                <input type="number" class="form-control" v-model="editedTotal" :placeholder="$t('amount')">
            </div>
            <button class="PrimaryButton btn">{{$t("save")}}</button>
        </form>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    props: {
        contract_id: Number,
        total: Number,
    },
    data() {
        return {
            visible: false,
            editedTotal: this.total // Initialize editedTotal with the value of total prop
        }
    },
    components: {
        Dialog
    },
    methods: {
        async editContract() {
            const formData = new FormData();
            formData.append("total", this.editedTotal);
            formData.append("contract_id", this.contract_id);
            axios
                .post(`/update_contract_custom_column`, formData)
                .then(({ data }) => {
                    this.visible = false;
                    Swal.fire({
                        title: "",
                        text: data.message,
                        icon: "success",
                    });
                })
                .catch((error) => {
                    this.visible = false;
                    Swal.fire({
                        title: "",
                        text: error.response.data.message,
                        icon: "error",
                    });
                });
        },
    }
};
</script>
