<template>
  <div class="trip_programe">
    <div class="main_header_style py-2">
      <h4>{{ $t("Trip_programme") }} <sub>(10ايام)</sub></h4>
      <div>
        <button class="PrimaryButton btn w-100">
          {{ $t("save_results") }}
        </button>
      </div>
    </div>

    <div class="checkboxes">
      <div v-for="n in 12" :key="n" class="form-check p-0 m-2">
        <div class="d-flex align-items-center gap-3">
          <input
            class="form-check-input mx-1"
            type="checkbox"
            value=""
            @click="insuranceEmit"
            id="flexCheckDefault"
          />
          <label class="form-check-label" for="flexCheckDefault">
            كل الايام
          </label>
        </div>
      </div>
      <div class="container">
        <br />
        <div class="row my-2">
          <div class="col-lg-8 col-md-8 col-12">
            <p class="m-0 day">
              <img
                src="@/assets/media/image/icon/Ellipse1946.svg"
                class="mx-1"
                width="28"
                height="28"
              />
              اليوم الاول
            </p>
            <label class="typo__label custom_label my-1"> مكان القضاء</label>
            <div class="form-group nas d-flex align-items-center gap-2 w-100">
              <input type="search" class="form-control" />
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS25PyJsR4an1VCwWCf0cpGJ7KHcZyRDi8I7odHES7Dzg&s"
                height="40"
                width="60"
                class="rounded"
                v-for="n in 3"
                :key="n"
              />
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <label class="typo__label custom_label">وصف البرنامج</label>
            <Editor editorStyle="height: 120px; border:1px solid   #80808054" />
          </div>
        </div>
        <br />
        <div class="row my-2">
          <div class="col-lg-8 col-md-8 col-12">
            <p class="m-0 day">
              <img
                src="@/assets/media/image/icon/Ellipse1946.svg"
                class="mx-1"
                width="28"
                height="28"
              />
              اليوم الثاني
            </p>
            <label class="typo__label custom_label my-1"> مكان القضاء</label>
            <div class="form-group nas d-flex align-items-center gap-2 w-100">
              <input type="search" class="form-control" />
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS25PyJsR4an1VCwWCf0cpGJ7KHcZyRDi8I7odHES7Dzg&s"
                height="40"
                width="60"
                class="rounded"
                v-for="n in 3"
                :key="n"
              />
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <label class="typo__label custom_label">وصف البرنامج</label>
            <Editor editorStyle="height: 120px; border:1px solid   #80808054" />
          </div>
        </div>
        <br />
        <div class="row my-2">
          <div class="col-lg-8 col-md-8 col-12">
            <p class="m-0 day">
              <img
                src="@/assets/media/image/icon/Ellipse1946.svg"
                class="mx-1"
                width="28"
                height="28"
              />
              اليوم الثالث
            </p>
            <label class="typo__label custom_label my-1"> مكان القضاء</label>
            <div class="form-group nas d-flex align-items-center gap-2 w-100">
              <input type="search" class="form-control" />
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS25PyJsR4an1VCwWCf0cpGJ7KHcZyRDi8I7odHES7Dzg&s"
                height="40"
                width="60"
                class="rounded"
                v-for="n in 3"
                :key="n"
              />
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <label class="typo__label custom_label">وصف البرنامج</label>
            <Editor editorStyle="height: 120px; border:1px solid  #80808054 " />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "primevue/editor";

export default {
  components: {
    Editor,
  },
};
</script>
<style lang="scss" scoped>
$PrimaryColor: #0a909b;
.PrimaryButton {
  padding: 0.8rem 5rem;
}
.trip_programe {
  background-color: #fff;
}
.btn_trip {
  border: 1px solid #0a909b;
  outline: none;
  padding: 0.5rem 0.8rem;
  color: #0a909b;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 0.2rem;
  font-family: "reqular";
}

.Trip-options {
  background-color: #fff;
  .title {
    font-family: "bold";
    color: #0b1427;
    font-size: 1.25rem;
  }
  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
  }
  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }
  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }
}
.checkboxes {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  max-width: 60%;
}
.custom_label {
  font-size: 0.6875rem;
  font-family: "bold";
  color: #0b1427;
  position: relative;
  z-index: 22;
  // bottom: 5.5rem; //4.2rem
  top: 0.675rem;
  background: white;
  border-radius: 50%;
  text-align: center;
  color: #656b78;
  margin: 0 0.7rem;
}

.resultSearch {
  position: absolute;
  // top: 40px;
  background-color: #fff;
  height: 300px;
  width: 23%;
  overflow-y: auto;
  z-index: 99999;
  .head_search {
    font-family: "bold";
    background-color: $PrimaryColor;
    color: #fff;
    padding: 0.5rem;
    margin-bottom: 0;
  }
  .hotels,
  .cities {
    margin-bottom: 0;
    li {
      display: flex;
      align-items: center;
      font-family: "regular";
      font-size: 0.8rem;
      padding: 0.5rem;
      border-bottom: 1px solid #292d32;
      transition: 0.7s ease-in-out;
      cursor: pointer;
      &:last-child {
        border-bottom: unset;
      }
      .hotel_image {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-inline-end: 0.5rem;
        position: unset !important;
      }
      i {
        font-size: 1.5rem;
        color: #b5b5b5;
        margin-inline-end: 0.5rem;
      }
      .title,
      .country {
        margin-bottom: 0;
      }
      .title {
        font-size: 0.8rem;
      }
      .country {
        font-size: 0.7rem;
        color: #292d32;
      }
      &:hover {
        transition: 0.7s ease-in-out;
        background-color: rgba($color: $PrimaryColor, $alpha: 0.1);
      }
    }
  }
}

.day {
  color: #0b1427;
  font-family: "bold";
  font-size: 1rem;
}
</style>
