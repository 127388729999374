<script setup>
import { ref, defineProps, watch, defineEmits } from "vue";
import Dialog from "primevue/dialog";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
// Define props with default values
const props = defineProps({
  excelData: {
    type: Array,
    default: () => [],
  },
  clientData: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: "",
  },
  endpoint: {
    type: String,
    default: "",
  },
});

// Define emits
const emit = defineEmits(["sentedData"]);

// Reactive variables
const visible = ref(false);
const headers = ref([]);
const updatedArray = ref([]);
const filteredObject = ref([]);

// Function to extract headers from the data
const extractHeaders = (data) => {
  return data.length > 0 ? Object.keys(data[0]) : [];
};

// Initialize updatedArray and headers when excelData changes
const initializeData = (newData) => {
  updatedArray.value = newData.map(() => ({
    name: null,
    phone: null,
    email: null,
  }));
  headers.value = extractHeaders(newData);
};

watch(
  () => props.excelData,
  (newData) => initializeData(newData),
  { immediate: true },
);

// Function to create the Excel object
const createExcelObject = (selectedData, searchKey, title) => {
  selectedData.forEach((item, index) => {
    if (Object.prototype.hasOwnProperty.call(item, searchKey)) {
      updatedArray.value[index][title] = item[searchKey];
    }
  });

  filteredObject.value = updatedArray.value;
  console.log(filteredObject.value);

  return updatedArray.value;
};

//check excel sheet
const checkExcelSheet = () => {
  const formData = new FormData();
  filteredObject.value.forEach((item, index) => {
    Object.keys(item).forEach((key) => {
      formData.append(`${props.type}[${index}][${key}]`, item[key]);
    });
  });
  axios
    .post("" + props.endpoint + "", formData)
    .then(({ data }) => {
      console.log(data);
      emit("sentedData", data.data);
      if (data.data.length === 0) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.message,
        });
      }
      visible.value = false;
    })
    .catch((error) => {
      console.log(error);
    });
};
</script>

<template>
  <Button
    v-if="excelData.length > 0"
    type="button"
    @click="visible = true"
    class="btn PrimaryButton mt-2"
  >
    <span>{{ $t("check") }}</span>
    <i class="fa-solid fa-check"></i>
  </Button>
  <Dialog
    v-model:visible="visible"
    modal
    dismissableMask
    :header="$t('check_data')"
    class="w-100 h-50"
  >
    <span class="p-text-secondary block mb-5">{{ $t("update data") }} </span>
    <form class="row" @submit.prevent="checkExcelSheet()">
      <div
        class="flex align-items-center gap-3 mb-3 col-12 col-md-6 col-lg-4"
        v-for="client in clientData"
        :key="client"
      >
        <label for="username" class="font-semibold w-6rem">{{
          client.title
        }}</label>
        <Multiselect
          v-model="client.data"
          mode="tags"
          :options="headers"
          :placeholder="$t('please choose data')"
          :clear-on-select="true"
          select-label=""
          :hide-selected="true"
          @select="createExcelObject(excelData, client.data, client.title)"
        ></Multiselect>
      </div>
      <div class="flex justify-content-end gap-2">
        <Button type="submit" class="btn PrimaryButton mt-2">
          <span>{{ $t("check") }}</span>
          <i class="fa-solid fa-check"></i>
        </Button>
      </div>
    </form>
  </Dialog>
</template>

<style scoped lang="scss"></style>
