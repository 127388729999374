<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_airport") }}</h4>
    </div>

    <form @submit.prevent="addAirports()">
      <div class="row">
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="countryValue"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The City") }}</label>
            <Multiselect
              v-model="cityValues"
              :options="cities.map((city) => city.id)"
              :custom-label="(opt) => cities.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("write_name") }} (EN)</label>
            <input
              type="text"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('write_name')"
              v-model="title_en"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("write_name") }} (AR)</label>
            <input
              type="text"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('write_name')"
              v-model="title_ar"
            />
          </div>
        </div>
        <!-- <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="lat">lat</label>
            <input
              type="text"
              class="form-control mt-2"
              id="lat"
              placeholder="lat"
              v-model="lat"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="long">long</label>
            <input
              type="text"
              class="form-control mt-2"
              id="long"
              placeholder="long"
              v-model="long"
            />
          </div>
        </div> -->
        <div class="col-md-12 mb-4">
          <div class="form-group">
            <label for="adress">{{ $t("code") }}</label>
            <input
              type="text"
              class="form-control mt-2"
              id="code"
              :placeholder="$t('code')"
              v-model="code"
            />
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("add") }}
      </button>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "addHotel",
  components: { Multiselect },
  data() {
    return {
      title_en: "",
      title_ar: "",
      countryValue: "",
      countries: [],
      cityValues: "",
      cities: [],
      code: "",
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    FetchCityFromCountry(value) {
      let basic_country_ids = {
        country_id: [value],
      };
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },

    addAirports() {
      const formData = new FormData();
      formData.append("title_en", this.title_en);
      formData.append("country_id", this.countryValue);
      formData.append("city_id", this.cityValues);
      formData.append("title_ar", this.title_ar);
      formData.append("code", this.code);

      axios
        .post("/airports", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/airport");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetch_country();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style></style>
