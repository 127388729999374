<template>
  <Loading v-if="loading" class="loading w-100" />
  <section class="show_receipt card_style">
    <div class="row">
      <div class="row options">
        <div class="col-4">
          <div class="main_header_style">
            <h4>{{ $t("flight_company") }}</h4>
          </div>
        </div>
        <form class="col-4">
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('search by name')"
            v-model="flightName"
            @keyup="FetchFlightCompany"
            style="padding-inline-start: 2.5rem !important"
          />
        </form>
        <div class="col-4">
          <router-link
            :to="{ name: 'flightCompanyAdd' }"
            class="PrimaryButton btn"
            ><i class="fa-solid fa-plus"></i>
            {{ $t("add_company") }}</router-link
          >
        </div>
      </div>
      <table class="table" id="revenueTable">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("name_en") }}</th>
            <!-- <th>{{ $t("country") }}</th> -->
            <!-- <th>{{ $t("city") }}</th> -->
            <th>{{ $t("tax_number") }}</th>
            <th>{{ $t("disntaion_number") }}</th>
            <th>{{ $t("Code") }}</th>
            <th>{{ $t("image") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in company.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <!-- <td>{{ item.country == null ? "" : item.country.title }}</td> -->
            <!-- <td>{{ item.city == null ? "" : item.city.title }}</td> -->
            <td>{{ item.code }}</td>
            <td>{{ item.code }}</td>
            <td>{{ item.code }}</td>
            <td><img :src="item.image" alt="compnay" /></td>

            <td>
              <router-link
                class="btn btn-success text-white mx-1"
                :to="`/flight-company/edit/${item.id}`"
                :title="$t('edit')"
              >
                <i class="fa-solid fa-pen"></i>
              </router-link>

              <button
                class="btn btn-danger text-white remove_btn"
                @click="DeleteAlert(index)"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
              </button>
              <button
                @click="favorite(item.id)"
                :class="`btn mx-1 ${
                  item.is_favorite == 1 ? 'btn-warning' : 'btn-secondary'
                }`"
              >
                <i class="fa-solid fa-star text-white"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="company"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchFlightCompany"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/loading.vue";
export default {
  name: "show-company",
  components: {
    pagination,
    Loading,
  },
  data() {
    return {
      company: [],
      flightName: "",
      loading: false,
    };
  },
  methods: {
    // toggle menu
    favorite(id) {
      this.loading = true;
      axios
        .post("/change_flight_company_favorite_status", {
          flight_company_id: id,
        })
        .then(() => {
          this.FetchFlightCompany();
          Swal.fire(this.$t("change status"), "", "success");
          this.loading = false;
        });
    },
    // fetch airport data
    FetchFlightCompany(page = 1) {
      // const search = { search: this.flightName };
      axios
        .get(`/flight_companies?page=${page}&search=${this.flightName}`)
        .then(({ data }) => {
          this.company = data.data;
          // console.log(this.company);
        });
    },
    //delete revenue
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.company.data[index].id;
          const fromClick = true;
          axios
            .delete("/flight_companies/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.company.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchFlightCompany();
  },
  updated() {
    // axios.get("API_Goes_Here").then((response) => {
    //   this.products = response.data;
    // });
    $("#revenueTable").DataTable({
      bPaginate: false,
      retrieve: true,
      searching: false,
      // displayStart: 5,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("airport"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("airport"),
      },
    });
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_flight";
img {
  width: 100px;
  height: 100px;
}
</style>
