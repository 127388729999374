<template>
  <table v-if="loading" class="w-100">
    <hotelBoking />
  </table>
  <div class="books-wrapper" v-else>
    <div
      class="books mt-3 d-flex align-items-center py-4 px-3"
      v-for="(book, index) in user.data"
      :key="index"
    >
      <div class="id">
        <h4 class="text-center">
          {{ book.id }}

          <span class="hint_element bank_hint">{{ $t("book_details") }}</span>
        </h4>
      </div>
      <div
        class="info d-flex align-items-center justify-content-end flex-column"
      >
        <div class="d-flex">
          <p>
            {{ book.hotel_name }}
          </p>
          <span
            class="nights d-flex align-items-center justify-content-center mx-1"
            >{{ book.number_of_nights }}N</span
          >
        </div>
        <p>{{ book.check_in }} -- {{ book.check_out }}</p>
      </div>
      <div class="rooms mt-0 mb-0">
        <div
          v-for="(room, index) in book.rooms"
          :key="index"
          class="room d-block flex-row-reverse"
        >
          <!-- <span>{{ room.count }}</span> -->
          <p class="text-center">
            {{ room.count }}x {{ room.title }} -
            {{ room.description }}
          </p>

          <div class="hint_element room_hint">
            {{ $t("room_details") }}
          </div>
        </div>
      </div>
      <div class="cost d-flex justify-content-center gap-3">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <p class="total mb-0">
            {{ Math.floor(book.total_selling_price * book.client_percentage) }}
            {{ book.client_currency_code }}
            <span class="hint_element bank_hint">{{ $t("total_client") }}</span>
          </p>
          <div
            v-if="book.payment_status == '0'"
            :class="`icons pt-2 text-center ${
              book.payment_status == '0'
                ? 'bg-warning text-white p-1 rounded'
                : ''
            }`"
          >
            <p
              class="paid d-flex align-items-center justify-content-center px-2"
            >
              {{ $t("Pending_payment") }}
              <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
            </p>
          </div>
          <div
            v-if="book.payment_status == '1'"
            :class="`icons ${
              book.payment_status == '1'
                ? 'bg-success text-white p-1 rounded'
                : ''
            }`"
          >
            <p
              class="paid d-flex align-items-center justify-content-center px-2"
            >
              {{ $t("paid_ok") }}
              <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
            </p>
          </div>
          <div
            v-if="book.payment_status == '2'"
            :class="`icons ${
              book.payment_status == '2'
                ? 'bg-danger text-white p-1 rounded'
                : ''
            }`"
          >
            <p
              class="paid d-flex align-items-center justify-content-center px-2"
            >
              {{ $t("Decline_payment") }}
              <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
            </p>
          </div>
          <div
            v-if="book.payment_status == '3'"
            :class="`icons ${
              book.payment_status == '3'
                ? 'bg-warning text-white p-1 rounded'
                : ''
            }`"
          >
            <p
              class="paid d-flex align-items-center justify-content-center px-2"
            >
              {{ $t("REFUND PENDING") }}
              <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
            </p>
          </div>
          <div
            v-if="book.payment_status == '4'"
            :class="`icons ${
              book.payment_status == '4'
                ? 'bg-success text-white p-1 rounded'
                : ''
            }`"
          >
            <p
              class="paid d-flex align-items-center justify-content-center px-2"
            >
              {{ $t("REFUND Accept") }}
              <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
            </p>
          </div>
          <div
            v-if="book.payment_status == '5'"
            :class="`icons ${
              book.payment_status == '5'
                ? 'bg-danger text-white p-1 rounded'
                : ''
            }`"
          >
            <p
              class="paid d-flex align-items-center justify-content-center px-2"
            >
              {{ $t("REFUND Decline") }}
              <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
            </p>
          </div>
          <div
            v-if="book.payment_status == '6'"
            :class="`icons ${
              book.payment_status == '6'
                ? 'bg-success text-white p-1 rounded'
                : ''
            }`"
          >
            <p
              class="paid d-flex align-items-center justify-content-center px-2"
            >
              {{ $t("payment sent") }}
              <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
            </p>
          </div>
        </div>

        <div class="d-flex flex-column">
          <p class="total_company">
            {{ book.organization_currency_code }}
            {{ Math.floor(375 * book.organization_percentage) }}
            <span class="hint_element bank_hint">{{
              $t("total_company")
            }}</span>
          </p>
          <div class="icons">
            <p
              class="bank d-flex align-items-center justify-content-center px-2 bank_element"
            >
              {{ $t("banky") }}
              a <img src="@/assets/media/image/wallet.svg" alt="" />
            </p>
          </div>
        </div>
      </div>
      <div
        class="conf flex-column px-2 text-center d-flex align-items-start justify-content-between"
      >
        <h6
          class="mb-0"
          style="width: -webkit-fill-available; text-align: center"
        >
          X-coders
        </h6>

        <div v-if="book.status === 2">
          <p>H.Conf : #{{ book.confirmation_number }}</p>
        </div>
        <span
          style="width: -webkit-fill-available; text-align: center"
          v-else
          :class="[
            'badge  text-center d-flex align-items-center justify-content-center',
            book.status === 0
              ? 'text-primary'
              : book.status === 1
              ? 'text-warning '
              : book.status === 2
              ? 'text-success'
              : book.status === 3
              ? 'text-info '
              : book.status === 4
              ? 'text-danger'
              : '',
          ]"
        >
          {{
            book.status === 0
              ? $t("initial")
              : book.status === 1
              ? $t("pending")
              : book.status === 2
              ? $t("completed")
              : book.status === 3
              ? $t("edited")
              : book.status === 4
              ? $t("canceled")
              : ""
          }}
        </span>
      </div>
      <div
        class="client d-flex flex-column align-items-center justify-content-end"
      >
        <h4 class="text-center">{{ book.client_name }}</h4>
        <div class="d-flex align-items-center justify-content-center gap-3">
          <div class="d-flex align-items-center gap-3">
            <img src="@/assets/media/image/frame.svg" alt="" />
            <p class="mx-1">{{ book.number_of_adults }} {{ $t("adult") }}</p>
          </div>
          <div class="d-flex align-items-center gap-3">
            <img src="@/assets/media/image/people.svg" alt="" />
            <p class="mx-1">
              {{ book.number_of_children }} {{ $t("children") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <pagination
      :data="user"
      class="mx-auto d-flex align-items-center justify-content-center pagination"
      @pagination-change-page="fetchUser"
    >
      <template v-slot:prev-nav>&lt;</template>
      <template v-slot:next-nav>&gt;</template>
    </pagination>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import hotelBoking from "@/components/skelton/hotelBoking.vue";
export default {
  data() {
    return {
      user: [],
      loading: true,
    };
  },
  methods: {
    fetchUser() {
      const client_id = { client_id: this.$route.params.id };
      axios.post(`fetch_client_bookings`, client_id).then(({data})=>{
          this.user = data.data;
          this.loading = false;
      }).catch((error)=>{
        if(error.response.status == 404){
          this.user = [];
          this.loading = false;
        }
        else{
          Swal.fire({
            icon: "error",
            text: error.response.data.message,
          });
        }
      })
    },
  },

  mounted() {
    this.fetchUser();
  },
  components: {
    pagination,
    hotelBoking,
  },
};
</script>

<style lang="scss" scoped>
@import "@/view/dashboard/offline/_offline.scss";
@import "@/assets/scss/_variables.scss";

// @import "./_user.scss";
.image {
  height: 8rem;
}

.main_header_style {
  align-items: center;

  h4 {
    margin-bottom: 0;
  }
}

.search-user {
  form {
    position: relative;

    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }

    input {
      padding-right: 40px !important;
    }
  }
}

.avatar {
  text-align: start;
  width: 50%;

  .person_name {
    color: #0b1427;
    font-family: "bold";
    font-size: 1.5rem;
  }

  .device_details {
    display: flex;
    align-items: flex-end;

    .icon {
      width: 1.2rem;
    }
  }

  .email_contact {
    background-color: #0a909b;
    color: white;
    padding: 0.5rem 1rem;
    width: 80%;
  }

  .connects {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .sms {
      border: 1px solid #0a909b;
      border-radius: 6px;
      padding: 0.5rem 1rem;
      background-color: transparent;

      span {
        color: #0b1427;
        font-size: 1rem;
      }
    }

    .whatsapp {
      border: 1px solid #64bf72;
      border-radius: 6px;
      padding: 0.5rem 1rem;
      background-color: transparent;

      span {
        color: #0b1427;
        font-size: 1rem;
      }
    }
  }
}

.points {
  background-image: url("@/assets/media/image/icon/Rectangle 10280.svg");
  background-size: cover;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 7%;
  min-height: 40dvh;
  height: 40dvh;

  .image_point {
    // height: 40dvh;
  }

  .points_title {
    color: #fff;
    font-family: "regular";
    font-size: 1.2rem;
  }

  .points_number {
    color: white;
    font-family: "bold";
    font-size: 2rem;
  }
}

.personal_details {
  color: #0b1427;
  font-family: "bold";
  font-size: 1.5rem;
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  width: 80%;
  margin-top: 1.3rem;
}

.details_two {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  width: 50%;
  justify-content: space-between;
}

.detailsss {
  color: #0b1427;
  font-size: 1.5rem;
  font-family: "bold";
}

p {
  color: #9ca2ac;
  font-size: 1.1rem;
  font-family: "regular";
  margin-bottom: 0.5rem;
}

.all_emp .card_style {
  background: transparent !important;
}
</style>
