<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>
          {{ $t("package_Star_Category") }}
        </h4>
      </div>
      <input
        type="text"
        v-model="starText"
        class="form-control w-50"
        @keyup="get_Package((page = 1))"
        :placeholder="$t('search...')"
      />

      <div>
        <router-link
          to="/package_Star_Category_Add"
          class="PrimaryButton btn w-100"
          ><i class="fa-solid fa-plus"></i> {{$t("add_category")}}
        </router-link>
      </div>
    </div>

    <!-- <div class="search-visa my-3">
         <form>
           <i class="fa-solid fa-magnifying-glass"></i>
           <input
             type="text"
             class="form-control"
             :placeholder="$t('search...')"
           />
         </form>
       </div> -->

    <div class="row">
      <table class="table" id="VisaTable">
        <thead>
          <tr>
            <th>ID</th>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("star_number") }}</th>
            <th class="text-center">{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="item in 10" :key="item">
            <td v-for="item in 4" :key="item">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(item, index) in packages" :key="index">
            <td>{{ item.id }}</td>
            <td class="text-center">{{ item.title }}</td>
            <td class="text-center">{{ item.star_count }}</td>
            <td
              class="text-center d-flex align-items-center justify-content-center"
            >
              <button
                @click="
                  this.$router.push(`/package_Star_Category_Edit/${item.id}`)
                "
                class="btn btn-success mx-1 w-auto"
              >
                <i class="fa-regular fa-edit"></i>
              </button>
              <button
                @click="deleteitem(item.id, index)"
                class="btn btn-danger mx-1 w-auto"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="packages"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="get_Package"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";

// import $ from "jquery";
export default {
  name: "allVisa",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      packages: [],
      starText: "",
      loading: true,
    };
  },
  methods: {
    get_Package(page = 1) {
      axios
        .post(`/index_package_star_category?page=${page}`)
        .then(({ data }) => {
          this.packages = data.data;
          // console.log(this.packages.data);
          this.loading = false;
        });
    },
    //delete visa
    deleteitem(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.package.id;
          const fromClick = true;
          axios.delete(`/packagestarcategory/${id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.packages.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  created() {
    this.get_Package();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
