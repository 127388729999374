<template>
  <section class="all_emp card_style mt-0">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">{{ $t("employeeLogs") }} ({{ name }})</h4>
    </div>
    <!-- <div class="main_header_style mb-2">
      <input
        type="text"
        v-model="employeeLogsText"
        class="form-control w-50"
        :placeholder="$t('search...')"
        @keyup="fetchemployeeLogs((page = 1))"
      />
      <router-link to="/employeeLogs_website/add" class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add service") }}</router-link
      >
    </div> -->

    <div class="row">
      <div class="table-responsive">
        <table class="table employeeLogs">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>

              <!-- <th>{{ $t("Title") }}</th> -->
              <th>{{ $t("action") }}</th>
              <th>{{ $t("date") }}</th>
              <!-- <th>{{ $t("Control") }}</th> -->
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="service in 10" :key="service">
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>

              <td v-for="service in 4" :key="service">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(log, index) in employeeLogs.data" :key="index">
              <td>{{ log.id }}</td>

              <!-- <td>{{ log.organization_employee.name }}</td> -->
              <td>
                <router-link
                  v-if="log.indirect_id"
                  :to="`/hotels/book_offline/${log.indirect_id}`"
                  target="_blank"
                >
                  {{ $t(log.direct_name) }} {{ $t(log.indirect_name) }}
                </router-link>
                <p v-else>{{ $t(log.action) }}</p>
              </td>
              <td>
                {{ log.create_at }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="employeeLogs"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchemployeeLogs"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";

import Skeleton from "primevue/skeleton";
export default {
  name: "employee-logs",
  data() {
    return {
      employeeLogs: [],
      loading: true,
      employeeLogsText: "",
      name: "",
    };
  },
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  methods: {
    fetchEmployData() {
      let id = this.$route.params.id;
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/organization_employees/" + id).then(({ data }) => {
        this.name = data.data.name;
      });
    },
    fetchemployeeLogs(page = 1) { 
      axios
        .post(`/fetch_employee_logs?page=${page}`, {
          organization_employee: this.$route.params.id,
        })
        .then(({ data }) => {
          this.employeeLogs = data.data;
          this.loading = false;
          // console.log(this.employeeLogs.data);
        });
    },
  },
  mounted() {
    this.fetchemployeeLogs();
    this.fetchEmployData();
  },
};
</script>

<style scoped>
table tbody tr td img {
  width: 100px;
  height: 100px;
}
</style>
