<template>
  <section class="book_hotel">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="pages_tree pt-4 m-0">
            <li>
              <router-link to="">{{ $t("home") }}</router-link>
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li>
              <router-link to="">{{ $t("Hotels") }}</router-link>
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li>
              {{ hotelInfo?.city?.title }}
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li>
              {{ hotelInfo?.title }}<i class="fa-solid fa-caret-left"></i>
            </li>
            <li>{{ $t("payment") }}</li>
          </ul>
        </div>
        <div class="col-12 col-lg-12 mx-auto py-4">
          <div class="booking_container">
            <div class="booking">
              <form class="pb-0">
                <!-- <div class="switch-container">
                  <div class="form-check form-switch">
                    <label for="toggle_form_data" class="text_design">
                      {{
                        toggle_form_data ? $t("another_persons") : $t("for_me")
                      }}
                    </label>
                    <input
                      class="form-check-input switch mx-1"
                      type="checkbox"
                      id="toggle_form_data"
                      v-model="toggle_form_data"
                    />
                  </div>
                </div> -->
                <div class="row" v-for="(adult, index) in adults" :key="index">
                  <div class="head">
                    <h6 class="title">{{ $t("guest") }} ({{ $t("adult") }})</h6>
                    <div class="form-group gendersADT">
                      <Multiselect
                        v-model="adults[index].salutation"
                        :options="gendersADT.map((gender) => gender.title_id)"
                        :custom-label="
                          (opt) =>
                            gendersADT.find((x) => x.title_id == opt).title
                        "
                        :clear-on-select="true"
                        select-label=""
                        :placeholder="$t('gender')"
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <label for="">{{ $t("first_name") }}</label>
                      <template
                        v-if="
                          index === 0 && adults.length > 0 && toggle_form_data
                        "
                      >
                        <input
                          type="text"
                          class="form-control"
                          v-model="first_name"
                          :placeholder="$t('first_name')"
                        />
                      </template>
                      <template v-else>
                        <input
                          type="text"
                          class="form-control"
                          v-model="adults[index].first_name"
                          :placeholder="$t('first_name')"
                        />
                      </template>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <label for="">{{ $t("last_name") }}</label>
                      <template
                        v-if="
                          index === 0 && adults.length > 0 && toggle_form_data
                        "
                      >
                        <input
                          type="text"
                          class="form-control"
                          v-model="last_name"
                          :placeholder="$t('last_name')"
                        />
                      </template>
                      <template v-else>
                        <input
                          type="text"
                          class="form-control"
                          v-model="adults[index].last_name"
                          :placeholder="$t('last_name')"
                        />
                      </template>
                    </div>
                  </div>
                  <!-- <div class="col-lg-6 col-md-12 col-12">
                          <div class="form-group">
                              <input type="number" class="form-control" min="11" v-model="adults[index].age" :placeholder="$t('age')">
                          </div>
                      </div> -->
                </div>

                <div
                  class="row"
                  v-for="(children, index) in childrens"
                  :key="index"
                >
                  <div class="head">
                    <h6 class="title">
                      {{ $t("guest") }} {{ $t("the_children") }}
                      {{ adults.length + 1 }}
                    </h6>
                    <div class="form-group gendersADT">
                      <Multiselect
                        v-model="childrens[index].salutation"
                        :options="gendersADT.map((gender) => gender.title_id)"
                        :custom-label="
                          (opt) =>
                            gendersADT.find((x) => x.title_id == opt).title
                        "
                        :clear-on-select="true"
                        select-label=""
                        :placeholder="$t('gender')"
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="childrens[index].first_name"
                        :placeholder="$t('first_name')"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="childrens[index].last_name"
                        :placeholder="$t('last_name')"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <button
                    v-if="auth && allDataValid"
                    type="button"
                    class="btn PrimaryButton"
                    @click="formBooking()"
                  >
                    {{ $t("save") }}
                  </button>
                  <button
                    v-if="!auth"
                    type="button"
                    class="btn PrimaryButton"
                    data-bs-toggle="modal"
                    data-bs-target="#registerModal"
                  >
                    {{ $t("save") }}
                  </button>
                </div>
              </form>

              <!-- <div class="payment_data" v-if="payTypeStatus">
                  <p>{{ $t("done_payment_go_to_payfort") }}</p>
                  <p>{{ $t("security_visa") }}</p>
                  <p>
                    {{ $t("discount_price") }}
                    {{
                      coupon_submit == true
                        ? convertedPrice(price_after_coupon)
                        : convertedPrice(totalPrice)
                    }}
                    {{ coinCode }}
                  </p>
                  <p>{{ $t("send_email_after_pay") }}</p>
                </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="col-12 col-lg-4">
          <div class="hotel_container">
            <div class="hotel">
              <div class="hotel_name align-items-start">
                <div class="">
                  <img width="80" :src="imageHotel" alt="hotel-image" />
                </div>
                <div class="text">
                  <h5>{{ hotelInfo?.title }}</h5>
                  <p class="address">
                    {{ hotelInfo?.address }}, {{ hotelInfo?.city?.title }},
                    {{ hotelInfo?.country?.title }}
                  </p>
                  <div class="rating_data d-flex align-items-center">
                    <div class="rete-number">
                      ( {{ hotelInfo?.rating }} {{ $t("rate") }})
                    </div>
                  </div>
                </div>
              </div>
              <ul class="hotel_details">
                <li>
                  <div class="detail">
                    <i class="fa-solid fa-users"></i>
                    {{ $t("number of person") }} :
                    {{
                      reservation_info.rooms_guests[0].adults_count +
                      reservation_info.rooms_guests[0].children_count
                    }}
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <i class="fa-regular fa-calendar"></i>
                    {{ $t("date of arrival") }} :
                  </div>
                  <div class="detail_value">
                    {{ reservation_info.check_in }} ({{ hotelInfo.check_in }})
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <i class="fa-regular fa-calendar"></i>
                    {{ $t("Departure Date") }} :
                  </div>
                  <div class="detail_value">
                    {{ reservation_info.check_out }} ({{ hotelInfo.check_out }})
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <i class="fa-solid fa-bed"></i>
                    {{ $t("room") }} :
                  </div>
                  <div class="detail_value">
                    <span
                      v-for="(room, index) in roomData.rooms"
                      :key="index"
                      >{{ room.room_type_name }}</span
                    >
                  </div>
                </li>
                <li>
                  <a href="#popup" @click="checkRoomCancellationPolicies()">
                    <div class="detail">
                      <i class="fa-solid fa-circle-exclamation"></i>
                    </div>
                    <div class="detail_value">
                      {{ $t("Cancellation policy details") }}
                    </div>
                  </a>
                  <div id="popup" class="popup">
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <h4 class="title py-0">
                        {{ $t("Cancellation Policies") }}
                      </h4>
                      <a href="#" class="close">&times;</a>
                    </div>
                    <h5 class="title">
                      {{
                        cancellation_rate_basis == null
                          ? ""
                          : cancellation_rate_basis.description
                      }}
                    </h5>
                    <div
                      v-if="
                        cancellation_rate_basis == null ||
                        cancellation_rate_basis.cancellation_rules
                      "
                    >
                      <span
                        >{{ $t("cancel_free_from") }}
                        {{
                          cancellation_rate_basis == null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[0]
                                .from_date
                        }}
                        {{ $t("to") }}
                        {{
                          cancellation_rate_basis == null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[0]
                                .to_date
                        }}</span
                      >
                      <span
                        >{{ $t("cancel_from") }}
                        {{
                          cancellation_rate_basis == null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1]
                                .from_date
                        }}
                        {{ $t("to") }}
                        {{
                          cancellation_rate_basis == null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1]
                                .to_date
                        }}
                        {{ $t("by_cost") }}
                        {{
                          cancellation_rate_basis == null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1]
                                .cancel_charge
                        }}</span
                      >
                      <span
                        >{{ $t("amend_from") }}
                        {{
                          cancellation_rate_basis == null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1]
                                .from_date
                        }}
                        {{ $t("to") }}
                        {{
                          cancellation_rate_basis == null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1]
                                .to_date
                        }}
                        {{ $t("by_cost") }}
                        {{
                          cancellation_rate_basis == null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                            ? ""
                            : cancellation_rate_basis.cancellation_rules[1]
                                .amend_charge
                        }}</span
                      >
                    </div>
                  </div>
                  <a href="#" class="close-popup"></a>
                </li>
              </ul>
              <hr />
              <div class="payment">
                <h5>{{ $t("payment details") }}</h5>
                <ul class="payment_details">
                  <li v-for="(room, index) in allRooms" :key="index">
                    <span class="title"
                      >{{ $t("the room") }} {{ room.room_type_name }} (
                      {{ number_of_nights }} {{ $t("nights") }} )</span
                    >
                    <span class="value">
                      {{}}
                        {{ (convertedPrice(room.room_rate_basis.selling_price) * number_of_nights).toFixed(2) }}
                      {{ coinCode }}
                    </span>
                  </li>
                  <li class="total">
                    <span class="title">{{
                      $t("The total includes value added tax")
                    }}</span>
                    <span class="total_value">
                      {{ convertedPrice(totalPrice) }} {{ coinCode }}
                    </span>
                  </li>
                  <li class="coupon_submit" v-if="coupon_submit">
                    <span class="title">{{ $t("price_atfer_coupon") }}</span>
                    <span class="total_value">
                      {{ convertedPrice(price_after_coupon) }} {{ coinCode }}
                    </span>
                  </li>
                </ul>
                <div class="coupon_code">
                  <form @submit.prevent="sendCoupon()">
                    <div class="form-group mb-3">
                      <label class="mb-2">{{ $t("coupons") }}</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="coupon_code"
                        placeholder=""
                      />
                    </div>
                    <button
                      type="submit"
                      :disabled="coupon_submit"
                      class="btn PrimaryButton"
                    >
                      {{ $t("submit_coupon") }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
// import { VueMultiImageUpload } from "@zakerxa/vue-multiple-image-upload";
import axios from "axios";
import Swal from "sweetalert2";
// import PopupPayment from "./popupPayment.vue";

export default {
  data() {
    return {
      toggle_form_data: false,
      first_name: JSON.parse(localStorage.getItem("user"))?.first_name || null,
      last_name: JSON.parse(localStorage.getItem("user"))?.last_name || null,
      coupon_submit: false,
      paymentBook: {},
      auth: false,
      step: 1,
      guests: [],
      check_person_responsible: false,
      showPopup: false,
      // payment_method: 1,
      genders: [
        {
          id: 1,
          title: this.$t("mr"),
          code: "mr",
        },
        {
          id: 2,
          title: this.$t("ms"),
          code: "ms",
        },
      ],
      city: JSON.parse(localStorage.getItem("bookDetails"))?.city_title,
      hotelName: JSON.parse(localStorage.getItem("hotel_Details")).title,
      component: {},
      options: {},
      imageSize: 20000000, // 2MB
      formatType: ["image/jpeg", "image/png", "image/jpg"],
      // bindProps: {
      //     mode: "international",
      //     defaultCountry: "EG",
      //     valid: true,
      //     inputOptions: {
      //     maxlength: 25,
      //     type: "tel",
      //     placeholder: this.$t("phone"),
      //     },
      //     validCharactersOnly: true
      // },
      passengerType: [
        {
          id: 1,
          title: "Adult",
          code: "ADT",
        },
        {
          id: 2,
          title: "Children",
          code: "CHD",
        },
        {
          id: 3,
          title: "Baby",
          code: "INF",
        },
      ],
      no_of_rooms: 0,
      session_id: "",
      result_index: 1,
      response_time: 0,
      roomData: JSON.parse(localStorage.getItem("roomData")),
      adults: JSON.parse(localStorage.getItem("roomData")).rooms[0].room_guests
        .adults,
      childrens: JSON.parse(localStorage.getItem("roomData")).rooms[0]
        .room_guests.children,
      hotel_name: "",
      hotel_code: null,
      guest_nationality: JSON.parse(localStorage.getItem("bookDetails"))
        .guest_nationality,
      reservation_info: JSON.parse(localStorage.getItem("hotel_Details")),
      checkin: null,
      checkout: null,
      number_of_nights: 0,
      hotel_rooms: [],
      hotelInfo: {
        // city: {},
        // country: {},
        // images: [],
      },
      coupon_code: "",
      room_price: {},
      coinCode:
        localStorage.getItem("coinCode") == null
          ? "USD"
          : localStorage.getItem("coinCode"),
      guest_types: [
        {
          id: 1,
          title: this.$t("adult"),
          key: "Adult",
        },
        {
          id: 2,
          title: this.$t("the_children"),
          key: "Children",
        },
      ],
      gendersADT: [],
      gendersCHD: [
        {
          id: 1,
          title: "Mstr",
          code: "Mstr",
        },
        {
          id: 2,
          title: "Miss",
          code: "Miss",
        },
      ],
      details: {
        guest_name: [],
      },
      dataPayment: "",
      showForm: false,
      cancellation_rate_basis: {},
      originalString: "@Laiali_123455",
      hashedString: "",
      formattedNumber: "",
      allRooms: JSON.parse(localStorage.getItem("roomData")).rooms,
      payTypeStatus: false,
      book_id: "",
      overall_selling_price: null,
      imageHotel: "",
      payment_fees_value: "",
      payment_fees_percentage: "",
      webPath: window.location.origin, //"https://kanaya.x-coders.net", //"https://softtechweb.crazyidea.online",
      isSignedIn: JSON.parse(localStorage.getItem("user")),
    };
  },
  components: {
    Multiselect,
    // PopupPayment
    // VueMultiImageUpload,
  },
  computed: {
    allDataValid() {
      // Check if all necessary data is filled
      // Assuming here that you have some conditions to check the validity of data
      // You can modify this according to your actual data structure and validation logic
      return (
        this.adults.every((adult) => adult.first_name && adult.last_name) &&
        this.childrens.every((child) => child.first_name && child.last_name)
        // Add more conditions as needed
      );
    },
    totalPrice() {
      let totalPrice = 0;
      this.allRooms.forEach((room) => {
        totalPrice =
          totalPrice + parseFloat(room.room_rate_basis.selling_price);
        // console.log(totalPrice)
      });

      return totalPrice * this.number_of_nights;
    },
  },
  methods: {
    sendCoupon() {
      const couponData = {
        coupon_type: "hotel",
        coupon_id: this.coupon_code,
        coupon_specification_id: parseInt(
          JSON.parse(localStorage.getItem("hotel_Details"))
        ),
        total_price: this.totalPrice,
        converter_status: 1,
      };
      axios
        .post("/check_coupon", couponData)
        .then(({ data }) => {
          this.price_after_coupon = data.data.final_value;
          this.coupon_submit = true;
          Swal.fire({
            title: "",
            text: this.$t("submit_coupon_success"),
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
    paymentBookFunction(book_id) {
      let paymentData = {
        price:
          this.coupon_submit == true
            ? Math.round(this.price_after_coupon * 3.75) +
              this.payment_fees_value +
              (this.price_after_coupon * 3.75 * this.payment_fees_percentage) /
                100 +
              "00"
            : Math.round(this.overall_selling_price * 3.75) +
              this.payment_fees_value +
              (this.price_after_coupon * 3.75 * this.payment_fees_percentage) /
                100 +
              "00",
        email:
          JSON.parse(localStorage.getItem("user")) == null
            ? null
            : JSON.parse(localStorage.getItem("user")).email,
        return_url: `https://softtechweb.crazyidea.online/success_hotel_book/${book_id}`,
        language: "ar",
      };
      axios
        .post("/payment_payfort", paymentData)
        .then(({ data }) => {
          this.paymentBook = data.data;
          // console.log(this.paymentBook)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
    formBooking() {
      this.loading = true; // Start loading
      // let bookData = JSON.parse(localStorage.getItem("hotel_Details"));
      let roomData = JSON.parse(localStorage.getItem("roomData"));
      let selectedCoin = JSON.parse(localStorage.getItem("coinCodes"))?.find(
        (coin) => coin.code === this.coinCode
      );
      let isMasterCoin = JSON.parse(localStorage.getItem("coinCodes"))?.find(
        (coin) => coin.is_master === 1
      );
      let requiredPercent = selectedCoin?.price ?? 0;
      let isMasterPrice = isMasterCoin?.price ?? 0;

      let data = {
        check_in: roomData.check_in,
        check_out: roomData.check_out,
        from_b2b: 1,
        hotel_id: roomData.hotel_id,
        has_coupon: this.coupon_submit == true ? 1 : 0,
        coupon_id: parseInt(this.coupon_code),
        hotel_name: roomData.hotel_name,
        nationality_code: roomData.nationality_code,
        // payment_method: parseInt(this.payment_method),
        passenger_country_of_residence_code:
          roomData.passenger_country_of_residence_code,
        rooms: roomData.rooms.map((ele) => {
          return {
            room_type_name: ele.room_type_name,
            organization_price: ele.room_rate_basis.organization_price,
            organization_price_after_tax:
              ele.room_rate_basis.organization_price_after_tax,
            organization_tax: ele.room_rate_basis.organization_tax,
            // original_price: ele.room_rate_basis.original_price,
            original_price_after_tax:
              ele.room_rate_basis.original_price_after_tax,
            original_tax: ele.room_rate_basis.original_tax,
            selling_price: ele.room_rate_basis.selling_price,
            selling_price_after_tax:
              ele.room_rate_basis.selling_price_after_tax,
            selling_tax: ele.room_rate_basis.selling_tax,
            description: ele.description,
            room_number: ele.room_number,
            rate_basis: ele.rate_basis,
            allocation_details: ele.allocation_details,
            meta_data: ele.meta_data,
            room_guests: {
              adults: [],
              children: [],
              extrabeds: [],
            },
          };
        }),
        meta_data: roomData.meta_data,
        converter_status: 1,
        client_currency_id: selectedCoin?.id ?? null,
        organization_percentage:
          selectedCoin?.is_default && selectedCoin.is_master
            ? 1
            : 1 / isMasterPrice,
        provider_percentage:
          selectedCoin?.is_default && selectedCoin.is_master
            ? 1
            : (1 / isMasterPrice) * requiredPercent,
      };

      data.rooms &&
        data.rooms.forEach((ele) => {
          ele.room_guests.adults = this.adults.map((adult, index) => {
            const guestData = {
              ...adult,
              leading: index === 0,
            };

            if (this.toggle_form_data) {
              guestData.first_name = this.first_name;
              guestData.last_name = this.last_name;
            } else {
              guestData.first_name = adult.first_name;
              guestData.last_name = adult.last_name;
            }

            return guestData;
          });

          ele.room_guests.children = this.childrens.map((child) => {
            return {
              ...child,
              ...child.age,
              leading: false,
            };
          });
        });

      if (
        JSON.parse(localStorage.getItem("roomData")).meta_data.room_index ==
        null
      ) {
        data.converter_status = 1;
      }

      axios
        .post("/new_book_hotel", data)
        .then(({ data }) => {
          if (data.status == true) {
            this.loading = false; //the loading ended
            this.showPopup = true; // Show the popup
            this.total_selling_price_with_tax =
              data.data.total_selling_price_with_tax;
            this.overall_selling_price = data.data.overall_selling_price;
            localStorage.setItem("newBook", JSON.stringify(data.data));
            this.payTypeStatus = true;
            this.book_id = data.data.id;
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/book_table_type/b2c");
            // console.log(data.data.payment_link.payment_link);
            // if (this.payment_method == 2) {
            //   window.location = data.data.payment_link.payment_link;
            // } else if (this.payment_method == 1) {
            //   Swal.fire({
            //     title: "",
            //     text: this.$t("pending_book"),
            //     icon: "success",
            //   });
            //   this.$router.push("/");
            // }
          } else {
            this.loading = false; //the loading ended
            Swal.fire({
              title: "",
              text: data.message,
              icon: "error",
            });
          }
          // console.log(data)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
    pushArrayGuests() {
      // Reset the guests array by setting its length to 0
      this.form.guests.length = 0;

      const guestsCount = this.check_person_responsible
        ? 1
        : JSON.parse(localStorage.getItem("bookDetails")).adults +
          JSON.parse(localStorage.getItem("bookDetails")).childrens;
      // console.log(guestsCount)

      for (let index = 0; index < guestsCount; index++) {
        this.form.guests.push({
          gender: "",
          name: "",
          email: "",
          phone: "",
          card_id: "",
          nationality: "",
          images: [],
        });
      }
    },
    fetch_nationalities(event) {
      const searchText = { word: event.target.value };
      axios.post("/fetch_nationalities", searchText).then(({ data }) => {
        this.nationalities = data.data.data;
        // console.log(this.countries);
      });
    },
    // upload multi images
    images(e, index) {
      this.guests[index].images = [];
      e.map((res) => this.guests[index].images.push(res));
      // console.log(this.discution_images);
    },
    changeStep() {
      this.step = 2;
    },
    changeStepStatusOne() {
      this.step = 1;
    },

    fetchTitleProvider() {
      // const provider_id = {
      //   provider_id: JSON.parse(localStorage.getItem("hotelDataSearch"))
      //     ?.meta_data?.provider_id,
      // };
      const provider_id = {
        provider_id: 2,
      };
      axios.post(`/fetch_title_provider`, provider_id).then(({ data }) => {
        this.gendersADT = data.data;
        // console.log(data.data)
      });
    },

    fetchHotelDetails() {
      const provider_id = {
        hotel_id: JSON.parse(localStorage.getItem("hotel_Details")).id,
      };
      axios.post(`/fetch_new_hotel_details`, provider_id).then(({ data }) => {
        this.hotelInfo = data.data;
        this.imageHotel = data.data.images[0]?.image;
        const checkInDate = new Date(this.reservation_info.check_in);
        const checkOutDate = new Date(this.reservation_info.check_out);

        // Calculate the time difference in milliseconds
        const timeDiff = checkOutDate.getTime() - checkInDate.getTime();

        // Calculate the number of nights
        const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));

        // Update the numberOfNights data property
        this.number_of_nights = nights;
        // console.log(data.data)
      });
    },
    checkRoomCancellationPolicies() {
      this.cancellation_rate_basis = JSON.parse(
        localStorage.getItem("cancellation_policy")
      );
    },
  },
  mounted() {
    this.fetchTitleProvider();
    this.fetchHotelDetails();
  },

  created() {
    this.auth = this.$store.getters["isAuthenticated"];
    // this.paymentBookFunction();
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./_bookHotel.scss";
  </style> -->
<style lang="scss">
@import "@/assets/scss/variables.scss";
.pages_tree {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;

  li {
    font-size: 15px;
    color: $PrimaryColor;

    a {
      color: $PrimaryColor;
    }

    i {
      color: #d0d2d7;
      margin-left: 10px;
      margin-right: 10px;
    }

    &:last-of-type {
      color: #656b78;
    }
  }
}
</style>
