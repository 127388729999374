<template>
  <div class="tabs">
    <ul class="nav nav-tabs" id="hotel_tabs" role="tablist">
      <li class="nav-item">
        <button
          class="nav-link active"
          id="room-list-tab"
          data-bs-toggle="tab"
          data-bs-target="#room-list-tab-pane"
          type="button"
          role="tab"
          aria-controls="room-list-tab-pane"
          aria-selected="true"
        >
          {{ $t("rooming_list") }}
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          id="service-tab"
          data-bs-toggle="tab"
          data-bs-target="#service-tab-pane"
          type="button"
          role="tab"
          aria-controls="service-tab-pane"
          aria-selected="false"
        >
          {{ $t("added services") }}
        </button>
      </li>
      <li class="nav-item">
        <button
          class="nav-link"
          id="paid-tab"
          data-bs-toggle="tab"
          data-bs-target="#paid-tab-pane"
          type="button"
          role="tab"
          aria-controls="paid-tab-pane"
          aria-selected="false"
          v-if="this.$route.params.id"
        >
          {{ $t("paid") }}
        </button>
      </li>
    </ul>

    <div class="tab-content" id="hotel_tabs_content">
      <div
        class="tab-pane fade show active"
        id="room-list-tab-pane"
        role="tabpanel"
        aria-labelledby="room-list-tab"
        tabindex="0"
      >
        <roomingList v-if="rooming_list.length > 0" :rooming_list="rooming_list"/>
      </div>

      <div
        class="tab-pane fade"
        id="service-tab-pane"
        role="tabpanel"
        aria-labelledby="service-tab"
        tabindex="0"
      >
        <addedServices @getServices="getServices"/>
      </div>

      <div
        class="tab-pane fade"
        id="paid-tab-pane"
        role="tabpanel"
        aria-labelledby="paid-tab"
        tabindex="0"
        v-if="this.$route.params.id"
      >
        <paids />
      </div>
    </div>
  </div>
</template>

<script>
import roomingList from "@/components/offline/rooming_list.vue";
import paids from "@/components/offline/paids.vue";
import addedServices from "@/components/offline/addedServices.vue";

export default {
  name: "test-page",
  props:{
    rooming_list: Array
  },
  emits: ["getServices"],
  components: {
    roomingList,
    paids,
    addedServices
  },
  methods:{
    getServices(services){
      this.$emit("getServices" , services)
    }
  },
  mounted(){
    if(this.$route.params.id){
      //////
    }
  }
};
</script>

