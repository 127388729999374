<template>
    <button
        class="btn btn-success mx-1 w-auto"
        @click="(visible = true)"
        >
        <i class="fa-solid fa-check"></i>
    </button>
    <Dialog
      v-model:visible="visible"
      modal
      header="Post to Account"
      :style="{ width: '50vw' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      :dismissableMask="true"
    >
      <form @submit.prevent="actionReceipt(id)">
        <AccountsBanks
          @updateAccountValue="updateAccountValue"
          @updateSafeValue="updateSafeValue"
          :organizationAccounts="organizationAccounts"
          :organizationSafe="organizationSafe"
          class="my-3"
        />
        <button type="submit" class="btn PrimaryButton">{{ $t("save") }}</button>
      </form>
    </Dialog>
  </template>
  
  <script>
  import axios from "axios";
  import Swal from "sweetalert2";
  import Dialog from "primevue/dialog";
  import AccountsBanks from "@/components/clients/AccountsBanks.vue";
  
  export default {
    name: "post_to_account",
    data() {
      return {
        amount: "",
        suppliers: [],
        visible: false,
        organizationAccounts: [],
        organizationSafe: [],
        accountValue: "",
        safeValue: "",
      };
    },
    props: {
        id: Number
    },
    components: {
      Dialog,
      AccountsBanks,
    },
    methods: {
        updateAccountValue(value) {
            this.accountValue = value;
        },
        updateSafeValue(value) {
            this.safeValue = value;
        },
        actionReceipt(id) {
            const formData = new FormData();
            formData.append("check_id", id);
            if (this.accountValue != "" && this.safeValue == "") {
                formData.append("organization_account_id", this.accountValue.id);
            }
            if (this.safeValue != "") {
                formData.append("organization_account_id", this.safeValue.id);
            }
            axios
            .post(`accept_check`, formData)
            .then(({ data }) => {
                Swal.fire({
                    title: data.message,
                    icon: "success",
                    confirmButtonText: this.$t("ok"),
                });
                this.visible = false;
            })
            .catch((error) => {
                Swal.fire({
                    title: error.response.data.message,
                    icon: "error",
                    confirmButtonText: this.$t("ok"),
                });
            });
        },
        async fetchSupplires() {
            axios
            .post("/fetch_consumer_by_role", { role: 2 })
            .then(({ data }) => {
                this.suppliers = data.data.data;
            })
            .catch((error) => {
                Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
                });
            });
        },
        fetchOrganizationAccounts() {
            axios
                .post("/fetch_my_organization_accounts", { type: 1 })
                .then(({ data }) => {
                this.organizationAccounts = data.data.data;
                //   console.log(this.organizationAccounts);
                });
        },
        fetchOrganizationSafe() {
        axios
            .post("/fetch_my_organization_accounts", { type: 2 })
            .then(({ data }) => {
            this.organizationSafe = data.data.data;
            // console.log(this.organizationSafe);
            });
        },
  
      // fetch suppliers data
    },
    mounted() {
      this.fetchSupplires();
      this.fetchOrganizationAccounts();
      this.fetchOrganizationSafe();
    }
  };
  </script>
  
<style lang="scss" scoped>
    .p-dialog.p-component {
        // height: 40vh;
        .p-dialog-content {
            .flex {
                margin-bottom: 1rem;
            }
            .PrimaryButton {
                margin-top: 3rem !important;
            }
        }
    }
</style>
  