<template>
  <div class="PdfDialog">
    <i class="fa-regular fa-file-pdf" @click="(visible = true), pdf()"></i>
    <Dialog v-model:visible="visible" modal header="Pdf" :dismissableMask="true">
      <div class="content" id="content">
        <div
          class="pdf-header d-flex align-items-center justify-content-between"
        >
          <h4 class="w-50">
            {{ name }}
            <p>{{ $t("for book travel and flights") }}</p>
          </h4>
          <img :src="logo" alt="" class="w-25" />
        </div>
        <div class="pdf-body">
          <h4 v-if="type == '2'" class="text-center fw-bold">
            {{ $t("recipt") }}
          </h4>
          <h4 v-else class="text-center fw-bold">{{ $t("exchange") }}</h4>
          <div class="d-flex align-items-center justify-content-between">
            <div class="w-50">
              <h4>{{ $t("number") }}/{{ pdfData.id }}</h4>
              <h4>{{ $t("date") }}: {{ pdfData.date }}</h4>
            </div>
            <div class="w-25 text-center total">
              <p>{{ $t("total") }}</p>
              <p>{{ pdfData.amount }}</p>
            </div>
          </div>
          <div class="d-flex flex-column my-3">
            <p
              class="d-flex justify-content-between align-items-center row-reverse text-center"
            >
              <span>مبلغ وقدره</span> <span>{{ pdfData.amount }}</span>
              <span>Amount</span>
            </p>
            <p
              v-if="pdfData && pdfData.from && pdfData.from[0]"
              class="d-flex justify-content-between align-items-center row-reverse text-center"
            >
              <span>من</span> <span>{{ pdfData.from[0].branch.title }}</span>
              <span>From</span>
            </p>
            <p
              v-if="pdfData && pdfData.to && pdfData.to[0]"
              class="d-flex justify-content-between align-items-center row-reverse text-center"
            >
              <span>الي</span>
              <span>{{ pdfData.to[0].branch.title }}</span>
              <span>To</span>
            </p>
          </div>
          <!-- <p>
            {{
              $t(
                "Their entitlement to the specified amount has been confirmed, with immediate delivery expected. This will be facilitated through a bank transfer from our company's account to theirs. This document is considered official, providing legal proof of the transaction."
              )
            }}
          </p> -->
          <div
            class="d-flex align-items-center justify-content-between signature"
          >
            <p>{{ $t("signature_manager") }}</p>
            <p>{{ $t("signature_otherside") }}</p>
          </div>
        </div>
        <div id="print-button" class="print_button w-100">
          <button class="PrimaryButton btn" v-print="'#content'">
            {{ $t("print") }}
          </button>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";
import axios from "axios";
export default {
  props: {
    id: Number,
    type: String,
  },
  components: {
    Dialog,
  },
  data() {
    return {
      visible: false,
      pdfData: {},
      logo: JSON.parse(localStorage.getItem("user")).logo,
      name: JSON.parse(localStorage.getItem("user")).organization_name,
    };
  },
  methods: {
    printContent() {
      let printContents = document.querySelector(".content").outerHTML;
      let originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();

      // Restore the original content after printing
      document.body.innerHTML = originalContents;
    },
    async pdf() {
      const { data } = await axios.get(`/daily_restriction/${this.id}`);
      this.pdfData = data.data;
      console.log(this.pdfData);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@media print {
  #print-button {
    display: none;
  }
  #content {
    width: 100%;
    height: 100%;
    padding: 25px;
  }
}
.PdfDialog {
  i {
    cursor: pointer;
    font-size: 1.2rem;
  }
  .content {
    .pdf-header {
      h4 {
        text-wrap: pretty;
      }
      img {
        width: 130px;
      }
    }
    .pdf-body {
    }
  }
}
.total {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 0;
  p {
    margin-bottom: 0;
    &:first-child {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #ccc;
    }
  }
}
p.d-flex {
  span {
    flex-basis: 33%;
  }
}
.signature {
  p {
    color: rgba(11, 20, 39, 1);
    font-weight: bold;
  }
}
p {
  color: rgba(11, 20, 39, 1);
}
.content {
  width: 595px;
  height: 628px;
  background-image: url("../../assets/media/image/A4.png");
}
</style>
