<template>
  <canvas id="myChart1"></canvas>
</template>


<script setup>
import { onMounted } from "@vue/runtime-core";
import { Chart } from "chart.js";
import { defineProps } from "vue";
import axios from "axios";

const props = defineProps({
  reserve_rate: String
})

onMounted(async () => {
  // Make an Axios request to fetch data
  try {
    const response = await axios.get("/fetch_home_data"); // Replace with your actual API endpoint
    let labels;
    let dataValues;
    // console.log(props.reserve_rate)
    if (props.reserve_rate == 'hotel' || props.reserve_rate == '') {
      labels = response.data.data.hotel_bookig_chart.hotel_bookig_months;
      dataValues = response.data.data.hotel_bookig_chart.hotel_bookig_count;
      // console.log("hotel")
    }
    else {
      labels = response.data.data.flight_bookig_chart.hotel_flight_bookig_months;
      dataValues = response.data.data.flight_bookig_chart.hotel_flight_bookig_count;
      // console.log("flight")
    }

    // console.log(response.data.data)

    // Create the Chart
    let myChart = new Chart("myChart1", {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Weeks",
            data: dataValues,
            backgroundColor: "rgba(10, 144, 155, 0.2)",
            borderColor: "rgba(10, 144, 155, 1)",
            fill: "origin",
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
      },
    });

    // Update Chart
    // myChart.data.datasets[0].data[0] = 10;
    myChart.update();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});
</script>
