<template>
  <section class="all_emp card_style mt-0">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        {{ $t("revenues ofline") }}
      </h4>
    </div>

    <div class="row">
      <!-- <div class="col-md-4">
        <div class="form-group">
          <label for="">
            {{ $t("search") }}
          </label>
          <div class="main_header_style">
            <input
              type="text"
              v-model="revenuesText"
              class="form-control"
              :placeholder="$t('search...')"
              @keyup="fetchrevenues((page = 1))"
            />
       
          </div>
        </div>
      </div> -->
      <div class="col-md-5">
        <div class="form-group">
          <label class="form-label" for="hotel_supplier">{{
            $t("clients")
          }}</label>
          <Multiselect
            v-model="client"
            :options="clients"
            label="name"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            @search-change="fetchConsumer"
          ></Multiselect>
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <label class="form-label" for="hotel_supplier">{{
            $t("Hotels")
          }}</label>
          <Multiselect
            v-model="hotel"
            :options="hotels"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
            @search-change="fetchHotel"
          ></Multiselect>
        </div>
      </div>
      <div
        class="col-md-2 d-flex align-items-center justify-content-end"
        v-if="revenues.data"
      >
        <button class="btn SecondButton mx-2" v-print="'#printMe'" title="PDF">
          <i class="fa-solid fa-file-pdf"></i>
        </button>
      </div>
    </div>

    <div id="printMe" class="row mb-5">
      <div class="table-responsive">
        <HeaderPdf
          :title="$t('revenues ofline')"
          :hotel="hotel"
          :client="client"
          :fromDate="''"
          :toDate="''"
        />
        <table class="table revenues">
          <thead>
            <tr>
              <th>{{ $t("reservation_number") }}</th>
              <th>{{ $t("client_name") }}</th>
              <th>{{ $t("Hotel") }}</th>
              <th>{{ $t("cost") }}</th>
              <th>{{ $t("taxes_value_selling") }}</th>
              <th>{{ $t("taxes_value_cost") }}</th>
              <th>{{ $t("selling price") }}</th>
              <th>{{ $t("revenue") }}</th>
              <th>{{ $t("revenue net") }}</th>
              <!-- <th>{{ $t("Control") }}</th> -->
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="service in 10" :key="service">
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>

              <td v-for="service in 8" :key="service">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(service, index) in revenues.data" :key="index">
              <td>
                <p v-if="service?.book_number == null">
                  {{ service?.book_number ? service?.book_number : "-" }}
                </p>
                <router-link
                  v-else
                  :to="`hotels/book_offline/${service?.id}`"
                  target="_blank"
                  >{{
                    service?.book_number ? service?.book_number : "-"
                  }}</router-link
                >
              </td>

              <td>
                <p v-if="service.client?.name == ''">
                  {{ service.client?.name ? service.client?.name : "-" }}
                </p>
                <router-link
                  v-else
                  :to="`clients/details/${service.client?.id}`"
                  >{{
                    service.client?.name ? service.client?.name : "-"
                  }}</router-link
                >
              </td>
              <td>
                <router-link
                  v-if="service.hotels.length == 0"
                  :to="`hotel/details/${service.hotel?.hotel_id}`"
                >
                  {{ service.hotel?.title ? service.hotel?.title : "-" }}
                </router-link>
                <div v-else class="d-flex flex-column">
                  <p v-for="hotel in service.hotels" :key="hotel">
                    <router-link :to="`hotel/details/${hotel?.hotel_id}`">
                      {{ hotel?.hotel_name ? hotel?.hotel_name : "-" }}
                    </router-link>
                  </p>
                </div>
              </td>
              <td>
                {{ service.total_cost_price_with_tax.toFixed(2) }}
              </td>
              <td>
                {{ service.selling_tax.toFixed(2) }}
              </td>
              <td>
                {{ service.cost_tax.toFixed(2) }}
              </td>
              <td>
                {{ service.total_selling_price_with_tax.toFixed(2) }}
              </td>
              <td>
                {{ service.irad.toFixed(2) }}
              </td>
              <td>
                {{ service.elsafi.toFixed(2) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="revenues"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchrevenues"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { Multiselect } from "vue-multiselect";
import Skeleton from "primevue/skeleton";
import HeaderPdf from "@/components/pdf/headerPdf.vue";

export default {
  name: "revenues-page",
  data() {
    return {
      revenues: [],
      loading: true,
      revenuesText: "",
      clients: [],
      hotels: [],
      client: "",
      hotel: "",
    };
  },
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
    Multiselect,
    HeaderPdf,
  },
  methods: {
    fetchrevenues(page = 1) {
      this.loading = true;
      axios
        .post(`/fetch_offline_booking_hotel_profit?page=${page}`, {
          organization_employee: this.$route.params.id,
          //   word: this.revenuesText,
          hotel_id: this.hotel.id,
          client_id: this.client.id,
        })
        .then(({ data }) => {
          this.revenues = data.data;
          //   console.log(this.revenues);
          this.loading = false;
          // console.log(this.revenues.data);
        });
    },
    fetchConsumer(search) {
      const data = {
        role: 1,
        word: search,
      };
      axios.post("/fetch_consumer_by_role", data).then(({ data }) => {
        //   console.log(data.data);
        this.clients = data.data.data;
        //   this.fetchrevenues();
      });
    },
    fetchHotel(search) {
      axios
        .post("/fetch_custom_hotels", {
          word: search,
        })
        .then(({ data }) => {
          // console.log(data.data);
          this.hotels = data.data.data;
          // this.fetchrevenues();
        });
    },
  },
  mounted() {
    this.fetchrevenues();
    this.fetchConsumer();
    this.fetchHotel();
    this.$store.state.hotelValue = null;
    this.$store.state.roomValue = null;
    this.$store.state.supplierValue = null;
    this.$store.state.client_id = null;
    this.$store.state.payment_status = null;
    this.$store.state.booking_status = null;
    this.$store.state.post_status = null;
    this.$store.state.city = null;
    this.$store.state.nationality = null;
    this.$store.state.book_number = null;
    this.$store.state.hotel_ref = null;
    this.$store.state.suplier_ref = null;
    this.$store.state.hotel_as_suplier = null;
    this.$store.state.employ = null;
    this.$store.state.meal = null;
    this.$store.state.rate = null;
    this.$store.state.client_category = null;
    this.$store.state.paymentMethod = null;
    this.$store.state.paymentSuplier = null;
    this.$store.state.clientType = null;
    this.$store.state.suplierType = null;
    this.$store.state.discountType = null;
    this.$store.state.guest = null;
    this.$store.state.share_amount = null;
  },
  watch: {
    client: {
      handler(newValue) {
        if (newValue) {
          this.fetchrevenues();
        }
      },
    },
    hotel: {
      handler(newValue) {
        if (newValue) {
          this.fetchrevenues();
        }
      },
    },
  },
};
</script>

<style scoped>
table tbody tr td img {
  width: 100px;
  height: 100px;
}
</style>
