<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("site_map") }}</h4>
    </div>
    <form @submit.prevent="addSiteMap()">
      <div class="form-group">
        <label>{{ $t("title") }}</label>
        <textarea v-model="title" class="form-control" type="text" />
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import Swal from "sweetalert2";

const title = ref("");
const robots = ref("");

const addSiteMap = async () => {
  await axios
    .post("store_org_setting_dashboard", {
      site_map: title.value,
      robots: robots.value,
    })
    .then((res) => {
      Swal.fire({
        icon: "success",
        title: res.data.message,
        timer: 1500,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const getSiteMap = async () => {
  await axios
    .get("show_org_setting")
    .then((res) => {
      title.value = res.data.data.site_map;
      robots.value = res.data.data.robots;
    })
    .catch((err) => {
      console.log(err);
    });
};

onMounted(async () => {
  await getSiteMap();
});
</script>

<style lang="scss" scoped></style>
