<template>
  <section class="portrait">
    <div class="container-fluid">
      <div class="main-parnt p-4">
        <div
          id="print-button"
          class="print_button d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center">
            <button class="PrimaryButton printButton" v-print="'#specific-section'">
              <i class="fa fa-print"></i>
            </button>
            <button class="PrimaryButton mx-2 printButton" @click="visible = true" v-if="pills.lock_status == 'posted' && pills.HasTaxInvoice != 1">
              {{ pills.HasTaxInvoice == 1 ? $t('show') + ' ' + $t('TAX INVOICE') + ' AR' : $t('export') + ' ' + $t('TAX INVOICE') + ' AR' }}
            </button>
            <router-link :to="`/tax-invoice-ar/${pills.base_booking_id}`" class="PrimaryButton mx-2 printButton" v-if="pills.lock_status == 'posted' && pills.HasTaxInvoice == 1">
              {{ pills.HasTaxInvoice == 1 ? $t('show') + ' ' + $t('TAX INVOICE') + ' AR' : $t('export') + ' ' + $t('TAX INVOICE') + ' AR' }}
            </router-link>
          </div>
          <div class="d-flex align-items-end gap-3">
            <router-link :to="`/hotels/book_offline/${book_id}`" target="_blank">{{ pills.book_number }}</router-link>
            <h4 class="invoice-title">Book number</h4>
          </div>
        </div>
        <sidebar-pill :book_status_name="book_status_name" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="main-parnt a4 pb-5">
        <div class="row">
          <div class="col-12">
            <div class="specific-section" id="specific-section">
              <taxInnvoiceEn
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                :organization_name="organization_name"
                :organization_tax_number="organization_tax_number"
                :tax_invoice_number="tax_invoice_number"
                :qr_code="qr_code"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import taxInnvoiceEn from "@/components/offline/newInvoice/taxInnvoiceEn.vue";

export default {
  name: "pill-hotel-cancel",
  components: {
    taxInnvoiceEn,
  },
  data() {
    return {
      pills: {},
      total_selling_price_with_out_tax: "",
      selling_tax: "",
      total_selling_price: "",
      bookId: null,
      book_status_name: "",
      book_id: this.$route.params.id,
      optionDate: null,
      terms: "",
      organization_name: "",
      organization_tax_number: "",
      tax_invoice_number: null,
      qr_code: ""
    };
  },

  methods: {
    fetchOfflineInvoice() {
      axios
        .post("/store_base_booking_tax_invoice", {
          base_booking_id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.pills = data.data;
          this.organization_name = data.data.organization?.name;
          this.organization_tax_number = data.data.organization?.tax_number;
          this.book_status_name = data.data.book_status_name;
          this.tax_invoice_number = data.data.tax_invoice_number;
          this.optionDate = data.data.client_option_date;
          this.total_selling_price_with_out_tax =
            data.data.total_selling_price_with_out_tax.toFixed(2);
          this.selling_tax = data.data.selling_tax.toFixed(2);
          this.total_selling_price = data.data.total_selling_price.toFixed(2);
          this.qr_code = data.data.qr_code;
          // console.log(this.pills);
        });
    },
  },
  watch: {
    "$route.params.type": {
      immediate: true,
      handler(newType) {
        if (newType === 'tax-innvoice-ar' || newType === 'voucher-ar') {
          axios.defaults.headers.common["Accept-Language"] = "ar";
        } else {
          axios.defaults.headers.common["Accept-Language"] = "en";
        }
        this.type = newType; // Update the type in the component
        this.fetchOfflineInvoice(); // Call fetchOfflineInvoice after setting the language
        // this.fetchTermsInvoice(newType)
      },
    },
  },
  created() {
    document.body.className = "portrait";
    const style = document.createElement('style');
    style.innerHTML = `@page { size: A4 portrait; }`;
    document.head.appendChild(style);
  },
  beforeUnmount() {
    // Clean up the class when the component is destroyed
    document.body.className = "landscape";
    const style = document.createElement('style');
    style.innerHTML = `@page { size: A4 landscape; }`;
    document.head.appendChild(style);
  }
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
.printButton {
  border-radius: 8px;
}
</style>
