<template>
  <section class="main">
    <div class="container-fluid">
      <skeltonFlight v-if="loading" />
      <div class="row" v-else>
        <div class="col-lg-9 col-md-9 col-12">
          <div class="details_title">
            <h3>{{ $t("reservation_details") }}</h3>
          </div>
          <div class="flight_details_section">
            <div class="row details-sec">
              <div class="col-lg-2 col-md-6 col-12">
                <div class="d-flex align-items-center gap-3">
                  <img
                    src="@/assets/media/image/task-square.svg"
                    alt=""
                    class="mx-2"
                  />
                  <span>{{ flight?.reservation_num }}</span>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12" style="width: max-content">
                <div class="d-flex align-items-center gap-3">
                  <img
                    src="@/assets/media/image/flight-icon.svg"
                    alt=""
                    class="mx-2"
                  />
                  <span>{{
                    flight?.flight_type === "one_way"
                      ? $t("one_way")
                      : flight?.flight_type === "two_way"
                      ? $t("two_way")
                      : $t("multi_way")
                  }}</span>
                </div>
              </div>
              <div class="col-lg-2 col-md-6 col-12" style="width: max-content">
                <div class="d-flex align-items-center gap-3">
                  <img
                    src="@/assets/media/image/people.svg"
                    alt=""
                    class="mx-2"
                  />
                  <span
                    >{{ flight?.client && flight?.client?.length }}
                    {{ $t("persons") }}</span
                  >
                </div>
              </div>
              <div class="col-lg-1 col-md-6 col-12">
                <div class="d-flex align-items-center gap-3">
                  <img
                    src="@/assets/media/image/fi_chair.svg"
                    alt=""
                    class="mx-2"
                  />
                  <span>{{ flight?.trip_degree }}</span>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <div
                  class="d-flex align-items-center"
                  style="width: max-content"
                >
                  <img
                    src="@/assets/media/image/calendar.svg"
                    alt=""
                    class="mx-2"
                  />
                  <span>{{ $t("Reservation date") }}: </span>
                  {{ flight?.date_trip }}
                </div>
              </div>
              <div class="col-lg-1 col-md-6 col-12">
                <div
                  class="align-items-center reservation_type"
                  :class="[
                    'badge',
                    flight?.reservation_status == 'basically'
                      ? 'bg-primary'
                      : flight?.reservation_status == 'confirmed'
                      ? 'bg-warning text-dark'
                      : flight?.reservation_status == 'updated'
                      ? 'bg-success'
                      : flight?.reservation_status == 'accepted'
                      ? 'bg-info text-dark'
                      : flight?.reservation_status == 'canceled'
                      ? 'bg-danger'
                      : '',
                  ]"
                >
                  {{
                    flight?.reservation_status == "basically"
                      ? $t("basically")
                      : flight?.reservation_status == "confirmed"
                      ? $t("confirmed")
                      : flight?.reservation_status == "updated"
                      ? $t("updated")
                      : flight?.reservation_status == "accepted"
                      ? $t("accepted")
                      : flight?.reservation_status == "canceled"
                      ? $t("canceled")
                      : ""
                  }}
                </div>
              </div>
            </div>

            <div class="row flight-sec">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="image">
                  <img
                    :src="flight?.flight_company?.image || this.defaultImage"
                    alt="company_image"
                    @error="handleImageError"
                    class="rounded-circle"
                  />
                </div>
              </div>
              <div class="col-lg-8 col-md-8 col-12">
                <div
                  class="trip_data"
                  v-if="flight && flight?.trips && flight?.trips?.length > 0"
                >
                  <div class="time_card">
                    <h5>{{ flight?.trips[0]?.launch_time }}</h5>
                    <h6>{{ flight?.trips[0]?.launch_date }}</h6>
                    <h6>
                      {{ flight?.trips[0]?.airport_departure_name?.title }} ({{
                        flight?.trips[0]?.departure
                      }})
                    </h6>
                  </div>
                  <div class="stop">
                    <h5>
                      {{ calculateTimeDifference(flight) }} {{ $t("hours") }}
                    </h5>

                    <div class="shape">
                      <i class="fas fa-plane"></i>
                      <span> </span>
                      <i class="fas fa-plane"></i>
                    </div>
                    <h4
                      :class="
                        flight?.trips?.length == 1
                          ? 'text-success'
                          : 'text-danger'
                      "
                    >
                      {{
                        flight?.trips.length == 1 ? $t("live") : $t("non_live")
                      }}
                    </h4>
                  </div>
                  <div class="time_card">
                    <h5>
                      {{ flight?.trips[flight?.trips.length - 1].arrive_time }}
                    </h5>
                    <h6>
                      {{ flight?.trips[flight?.trips.length - 1].arrive_date }}
                    </h6>
                    <h6>
                      {{
                        flight?.trips[flight?.trips.length - 1]
                          .airport_arrival_name?.title
                      }}
                      ({{ flight?.trips[flight?.trips.length - 1].arrival }})
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div class="row details-sec">
              <div class="col-12">
                <div class="d-flex align-items-center gap-3">
                  <img
                    src="@/assets/media/image/bag-icon.svg"
                    alt=""
                    class="mx-2"
                  />
                  <span class="allowed_bags_title">{{
                    $t("allowed_bags")
                  }}</span>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-12">
                <div class="d-flex align-items-center gap-3">
                  <i class="fa-solid fa-check"></i>
                  <span>{{ $t("total_bag_weight") }}</span>
                </div>
                <div class="d-flex align-items-center mx-2">
                  <div v-for="(item, index) in flight?.trips" :key="index">
                    <ul v-for="(bag, index) in item?.bags" :key="index">
                      <li>
                        <span class="price"
                          >{{ bag?.weight }} {{ $t("KG") }}</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="table_section">
            <div class="d-flex align-items-center title">
              <img src="@/assets/media/image/people.svg" alt="" class="mx-2" />
              <span>
                {{ $t("passengers_details") }} ({{
                  flight && flight?.client && flight?.client?.length
                }})
              </span>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{ $t("passenger_name") }}</th>
                    <th>{{ $t("nickname") }}</th>
                    <th>{{ $t("phone_number") }}</th>
                    <th>{{ $t("email") }}</th>
                    <th>{{ $t("passport") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in flight?.client" :key="index">
                    <td>{{ item?.first_Name }}</td>
                    <td>{{ item.Sur_name }}</td>
                    <td>{{ item.phone_number || "---" }}</td>
                    <td>{{ item.email || "---" }}</td>
                    <td>{{ item.IdentityDocID }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-12">
          <!-- <div class="col-lg-6 col-md-6 col-12 mb-3"> -->
          <router-link
            :to="`/flights/flight-pdf/${id}`"
            class="btn SecondButton mb-3"
          >
            <i class="fa-solid fa-print"></i>
            {{ $t("print_pill") }}
          </router-link>
          <!-- </div> -->
          <div class="payment_details">
            <div class="payment">
              <h5>{{ $t("payment_details") }}</h5>
              <ul class="prices_details">
                <li>
                  <span>{{ $t("price_without_tax") }}</span>
                  <span class="price">{{
                    flight?.offer?.orginzation_base_amount
                  }}</span>
                </li>
                <li>
                  <span>{{ $t("taxes_value") }}</span>
                  <span class="price">{{
                    flight?.offer?.orginzation_tax_amount
                  }}</span>
                </li>
                <li class="total">
                  <span>{{ $t("price_including_tax") }}</span>
                  <span class="price">{{
                    flight?.offer?.orginzation_total_amount
                  }}</span>
                </li>

                <div class="qrcode_img">
                  <img
                    src="@/assets/media/image/qr-code.png"
                    alt="qrcode_image"
                  />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import skeltonFlight from "@/components/flight/skeltonFlight.vue";
export default {
  name: "b2cUserFlightDetails",
  data() {
    return {
      flight: {},
      loading: true,
      id: this.$route.params.id,
      defaultImage: require("@/assets/media/image/img-flight.png"), // Replace with your default image URL,
    };
  },

  methods: {
    handleImageError(event) {
      // When the image fails to load, replace it with the default image
      event.target.src = this.defaultImage;
    },
    fetchflightDetails() {
      let id = this.$route.params.id;
      axios.get("/flight/" + id).then(({ data }) => {
        // console.log(data);
        this.flight = data.data;
        this.loading = false;
      });
    },

    calculateTimeDifference(flight) {
      const startTime = flight?.trips[0]?.launch_time;
      const endTime = flight?.trips[flight?.trips.length - 1].arrive_time;

      // Parse the time strings to create Date objects
      const startDate = new Date(`2000-01-01 ${startTime}`);
      const endDate = new Date(`2000-01-01 ${endTime}`);

      // Calculate the time difference in milliseconds
      const timeDifferenceMs = endDate - startDate;

      // Convert the time difference to hours
      const timeDifferenceHours = Math.abs(
        Math.round(timeDifferenceMs / (1000 * 60 * 60))
      );

      return timeDifferenceHours;
    },
  },

  mounted() {
    this.fetchflightDetails();
  },
  components: {
    skeltonFlight,
  },
};
</script>

<style lang="scss" scoped>
@import "./b2cUserFlightDetails";
</style>
