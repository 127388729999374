<template>
  <section>
    <div class="container-fluid">
      <div class="main-parnt p-4">
        <div
          id="print-button"
          class="print_button d-flex align-items-center justify-content-between"
        >
          <button class="PrimaryButton printButton" v-print="'#specific-section'">
            <i class="fa fa-print"></i>
          </button>
          <div class="d-flex align-items-end gap-3">
            <router-link :to="`/hotels/book_offline/${book_id}`" target="_blank">{{ pills.book_number }}</router-link>
            <h4 class="invoice-title">Book number</h4>
          </div>
        </div>
        <sidebar-pill :book_status_name="book_status_name" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="main-parnt a4">
        <div class="row">
          <div class="col-12">
            <div class="specific-section" id="specific-section">
              <amendment
                :book_status_name="book_status_name"
                :pills="pills"
                :service="pills.service"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'amendment'"
              />
              <cancelation
                :book_status_name="book_status_name"
                :pills="pills"
                :service="pills.service"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :optionDate="optionDate"
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'cancelation'"
              />
              <optionDate
                :book_status_name="book_status_name"
                :pills="pills"
                :service="pills.service"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'option-date'"
              />
              <priceOffer
                :book_status_name="book_status_name"
                :pills="pills"
                :service="pills.service"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'price-offer'"
              />
              <taxInnvoiceEn
                :book_status_name="book_status_name"
                :pills="pills"
                :service="pills.service"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'tax-innvoice-en'"
              />
              <taxInnvoiceAr
                :book_status_name="book_status_name"
                :pills="pills"
                :service="pills.service"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'tax-innvoice-ar'"
              />
              <confirm
                :book_status_name="book_status_name"
                :pills="pills"
                :service="pills.service"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'pill-confirm'"
              />
              <nonRefund
                :book_status_name="book_status_name"
                :pills="pills"
                :service="pills.service"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'non-refundable'"
              />
              <report
                :book_status_name="book_status_name"
                :pills="pills"
                :service="pills.service"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :optionDate="optionDate"
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'report'"
              />
              <roomingList
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'rooming-list'"
              />
              <supplierVoucher
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'supplier-voucher'"
              />
              <voucherEn
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'voucher-en'"
              />
              <voucherAr
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'voucher-ar'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import sidebarPill from "@/view/dashboard/offline/sidebarPill.vue";
import amendment from "@/components/offline/invoice/amendment.vue";
import cancelation from "@/components/offline/invoice/cancelation.vue";
import confirm from "@/components/offline/invoice/pillConfirm.vue";
import nonRefund from '@/components/offline/invoice/nonRefund.vue';
import taxInnvoiceEn from "@/components/offline/invoice/taxInnvoiceEn.vue";
import taxInnvoiceAr from "@/components/offline/invoice/taxInnvoiceAr.vue";
import optionDate from "@/components/offline/invoice/optionDate.vue";
import priceOffer from "@/components/offline/invoice/priceOffer.vue";
import report from "@/components/offline/invoice/report.vue";
import roomingList from "@/components/offline/invoice/roomingList.vue";
import supplierVoucher from "@/components/offline/invoice/supplierVoucher.vue";
import voucherEn from "@/components/offline/invoice/voucherEn.vue";
import voucherAr from "@/components/offline/invoice/voucherAr.vue";

export default {
  name: "pill-hotel-cancel",
  components: {
    sidebarPill,
    priceOffer,
    optionDate,
    amendment,
    cancelation,
    taxInnvoiceEn,
    taxInnvoiceAr,
    confirm,
    nonRefund,
    report,
    roomingList,
    supplierVoucher,
    voucherEn,
    voucherAr,
  },
  data() {
    return {
      pills: {},
      total_selling_price_with_out_tax: "",
      selling_tax: "",
      total_selling_price: "",
      bookId: null,
      book_status_name: "",
      type: this.$route.params.type,
      book_id: this.$route.params.id,
      optionDate: null,
      terms: ""
    };
  },

  methods: {
    fetchOfflineInvoice() {
      axios
        .post("/fetch_offline_book_hotel_invoice", {
          offline_booking_hotel_id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.pills = data.data;
          this.book_status_name = data.data.book_status_name;
          this.optionDate = data.data.client_option_date;
          this.total_selling_price_with_out_tax =
            data.data.total_selling_price_with_out_tax.toFixed(2);
          this.selling_tax = data.data.selling_tax.toFixed(2);
          this.total_selling_price = data.data.total_selling_price.toFixed(2);
          // console.log(this.pills);
        });
    },
  },
  watch: {
    "$route.params.type": {
      immediate: true,
      handler(newType) {
          const formData = new FormData(); // Declare formData here
          formData.append("offline_booking_hotel_id", this.$route.params.id);
          // if (newType === 'tax-innvoice-ar' || newType === 'voucher-ar') {
          //   axios.defaults.headers.common["Accept-Language"] = "ar";
          // } else 
          if(newType === 'pill-confirm') {
            formData.append("invoice_type", 1);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else if(newType === 'non-refundable') {
            formData.append("invoice_type", 2);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else if(newType === 'tax-innvoice-en') {
            formData.append("invoice_type", 3);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else if(newType === 'tax-innvoice-ar') {
            formData.append("invoice_type", 4);
            axios.defaults.headers.common["Accept-Language"] = "ar";
          } else if(newType === 'option-date') {
            formData.append("invoice_type", 5);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else if(newType === 'price-offer') {
            formData.append("invoice_type", 6);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else if(newType === 'report') {
            formData.append("invoice_type", 7);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else if(newType === 'rooming-list') {
            formData.append("invoice_type", 8);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else if(newType === 'supplier-voucher') {
            formData.append("invoice_type", 9);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else if(newType === 'voucher-ar') {
            formData.append("invoice_type", 10);
            axios.defaults.headers.common["Accept-Language"] = "ar";
          } else if(newType === 'voucher-en') {
            formData.append("invoice_type", 11);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else if(newType === 'cancelation') {
            formData.append("invoice_type", 12);
            axios.defaults.headers.common["Accept-Language"] = "en";
          } else {
            axios.defaults.headers.common["Accept-Language"] = "en";
          }
          this.type = newType; // Update the type in the component
          this.fetchOfflineInvoice(formData); // Call fetchOfflineInvoice after setting the language
          // this.fetchTermsInvoice(newType)
        },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
.printButton {
  border-radius: 8px;
}
</style>
