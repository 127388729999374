<template>
  <div v-if="loading" class="loading w-100">
    <Loading />
  </div>
  <div class="selects row w-100 mb-0">
    <div class="col-xl-8 col-lg-8 col-md-6 col-12">
      <div
        class="row mb-1"
        v-for="(airport, airportIndex) in allAirports"
        :key="airportIndex"
      >
        <div class="col-xl-8 col-lg-8 col-md-7 col-12">
          <div class="d-flex align-items-center position-relative pt-1">
            <img
              src="@/assets/media/image/icon/launchFlight.svg"
              class="image"
            />
            <div class="form-group w-100">
              <img src="@/assets/media/image/icon/plane-arrive(2).svg" />
              <input
                type="search"
                class="form-control search"
                :placeholder="$t('Departure airport')"
                v-model="airport.lunchTitle"
                @keyup="searchLunchAirport($event, airportIndex)"
              />

              <div
                class="resultSearch"
                v-if="airport.lunchTitle.length > 2 && airport.lunchArr.length"
              >
                <h6 class="head_search">{{ $t("Arrival airport") }}</h6>
                <ul class="hotels">
                  <li
                    v-for="(stop, index) in airport.lunchArr"
                    :key="index"
                    @click="
                      sendStopName(
                        stop.title == null ? '' : stop.title,
                        stop.code,
                        airportIndex
                      )
                    "
                  >
                    <div class="hotel_image">
                      {{ stop.code == null ? "" : stop.code }}
                    </div>
                    <div class="texts">
                      <p class="title">
                        {{ stop.title == null ? "" : stop.title }}
                      </p>
                      <p class="country">
                        {{ stop.country == null ? "" : stop.country }} -
                        {{ stop.city_title == null ? "" : stop.city_title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group w-100">
              <img src="@/assets/media/image/icon/download.svg" />
              <input
                type="search"
                class="form-control search"
                :placeholder="$t('Arrival airport')"
                v-model="airport.returnTitle"
                @keyup="searchReturnAirport($event, airportIndex)"
              />

              <div
                class="resultSearch"
                v-if="
                  airport.returnTitle.length > 2 && airport.returnArr.length
                "
              >
                <h6 class="head_search">{{ $t("Arrival airport") }}</h6>
                <ul class="hotels">
                  <li
                    v-for="(stop, index) in airport.returnArr"
                    :key="index"
                    @click="
                      sendreturnName(
                        stop.title == null ? '' : stop.title,
                        stop.code,
                        airportIndex
                      )
                    "
                  >
                    <div class="hotel_image">
                      {{ stop.code == null ? "" : stop.code }}
                    </div>
                    <div class="texts">
                      <p class="title">
                        {{ stop.title == null ? "" : stop.title }}
                      </p>
                      <p class="country">
                        {{ stop.country == null ? "" : stop.country }} -
                        {{ stop.city_title == null ? "" : stop.city_title }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="{
            'col-xl-4 col-lg-4 col-md-4 col-12': airportIndex == 0,
            'col-xl-3 col-lg-3 col-md-3 col-12': airportIndex != 0,
          }"
        >
          <div class="form-group w-100">
            <Datepicker
              v-model="airport.date"
              :lang="lang"
              class="Datepicker w-100 p-0"
              :placeholder="$t('date')"
              :disabled-start-date="{ to: new Date() }"
            />
          </div>
        </div>
        <div class="col-1" v-if="airportIndex != 0">
          <button
            class="btn remove-btn"
            @click="remove_airport(airportIndex)"
            v-if="airportIndex != 0"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-5 col-sm-12">
      <div class="form-group w-100">
        <div class="dropdown">
          <button
            type="button"
            class="btn btn-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-auto-close="outside"
          >
            {{ $t("Adults") }} {{ adults }} - {{ $t("childrens") }}
            {{ childrens }} - {{ $t("babies") }} {{ babies }}
          </button>
          <div class="dropdown-menu">
            <div class="list">
              <span>{{ $t("Adults") }}</span>
              <div class="number">
                <button class="btn minus" @click="minusQuantityAdults()">
                  <i class="fa-solid fa-minus"></i>
                </button>
                <p class="count">{{ adults }}</p>
                <button class="btn add" @click="addQuantityAdults()">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="list">
              <span class="d-flex align-items-center"
                >{{ $t("childrens") }}
                <span class="mx-2" style="font-size: 0.6rem"
                  >({{ $t("policy children") }})</span
                ></span
              >
              <div class="number">
                <button class="btn minus" @click="minusQuantityChildrens()">
                  <i class="fa-solid fa-minus"></i>
                </button>
                <!-- <input type="text" class="count form-control" v-model=""> -->
                <p class="count">{{ childrens }}</p>
                <button class="btn add" @click="addQuantityChildrens()">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="list">
              <span class="d-flex align-items-center"
                >{{ $t("babies") }}
                <span class="mx-2" style="font-size: 0.6rem"
                  >({{ $t("policy babies") }})</span
                ></span
              >
              <div class="number">
                <button class="btn minus" @click="minusQuantityBabies()">
                  <i class="fa-solid fa-minus"></i>
                </button>
                <!-- <input type="text" class="count form-control" v-model=""> -->
                <p class="count">{{ babies }}</p>
                <button class="btn add" @click="addQuantityBabies()">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-1 col-md-5 col-sm-12">
      <div class="form-group w-100">
        <Multiselect
          v-model="cabin_code"
          :options="cabin_codes.map((cabin_code) => cabin_code.id)"
          :custom-label="(opt) => cabin_codes.find((x) => x.id == opt).title"
          :clear-on-select="true"
          :placeholder="$t('cabin_codes')"
          select-label=""
          :hide-selected="true"
        >
        </Multiselect>
      </div>
    </div>

    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-12">
      <button class="btn PrimaryButton w-100" @click="FetchFlights()">
        <i class="fa-solid fa-magnifying-glass"></i>
      </button>
    </div>
  </div>

  <button
    class="btn add-btn"
    @click="add_airport"
    v-if="allAirports.length < 6"
  >
    <i class="fa-solid fa-plus"></i>
    {{ $t("add_multi_flight") }}
  </button>
  <flightCard :offers="offers" :metadata="metadata" :paxs="paxs" />
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import moment from "moment";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import flightCard from "@/components/flight/flightCard.vue";
import VueDatepickerUi from "vue-datepicker-ui";
import Loading from "@/components/LottieFiles/loading.vue";
import Swal from "sweetalert2";
import axios from "axios";
export default {
  components: {
    Datepicker: VueDatepickerUi,
    Multiselect,
    flightCard,
    Loading,
  },
  data() {
    return {
      loading: false,
      message: "",
      lang:
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == undefined
          ? "ar"
          : "en",
      companies: [],
      companyData: "",
      dateValue: [new Date(), ""],
      companyId: "",
      minSearc: 1,
      airports: [],
      launchAirport: {},
      depAirport: {},
      show: false,
      cabin_codes: [
        {
          id: 1,
          title: this.$t("First Class"),
        },
        {
          id: 2,
          title: this.$t("Business Class"),
        },
        {
          id: 3,
          title: this.$t("Economy Standard"),
        },
        {
          id: 4,
          title: this.$t("Premium Economy"),
        },
        {
          id: 5,
          title: this.$t("Economy"),
        },
      ],
      tableRows: [],

      allAirports: [
        {
          lunchTitle: "",
          lunchId: "",
          lunchArr: [],
          returnTitle: "",
          returnId: "",
          returnArr: [],
        },
      ],
      cabin_code:
        JSON.parse(localStorage.getItem("flightData")) == null
          ? 5
          : JSON.parse(localStorage.getItem("flightData")).cabin_code,
      adults: 1,
      childrens: 0,
      babies: 0,
      offers: [],
      metadata: {},
      paxs: [],
    };
  },
  methods: {
    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // babies Minus && Add
    minusQuantityBabies() {
      if (this.babies > 0) {
        this.babies -= 1;
      }
    },
    addQuantityBabies() {
      this.babies += 1;
    },

    searchLunchAirport(event, index) {
      const searchText = { title: event.target.value };
      axios
        .post(`/website/search_home_airports`, searchText)
        .then(({ data }) => {
          this.allAirports[index].lunchArr = data.data;
        })
        .catch((err) => console.log(err));
    },

    searchReturnAirport(event, index) {
      const searchText = { title: event.target.value };
      axios
        .post(`/website/search_home_airports`, searchText)
        .then(({ data }) => {
          this.allAirports[index].returnArr = data.data;
        })
        .catch((err) => console.log(err));
    },

    sendStopName(stopTitle, stop_id, airportIndex) {
      this.allAirports[airportIndex].lunchId = stop_id;
      this.allAirports[airportIndex].lunchTitle = stopTitle;
      this.allAirports[airportIndex].lunchArr = [];
    },

    sendreturnName(stopTitle, stop_id, airportIndex) {
      this.allAirports[airportIndex].returnId = stop_id;
      this.allAirports[airportIndex].returnTitle = stopTitle;
      this.allAirports[airportIndex].returnArr = [];
    },

    sendCompany(stopTitle, stop_id) {
      this.companyData = stopTitle;
      this.companyId = stop_id;
      this.companies = [];
    },
    //  End flight companies

    //  Start flight companies
    checkAirports(event) {
      if (event.target.value.length > this.minSearc) {
        this.fetchAirports(event.target.value);
      }
    },
    fetchAirports(title_airport) {
      const title = { title: title_airport };
      axios.post(`/search_airports`, title).then((res) => {
        this.airports = res.data.data;
        // console.log(this.arivalAirports);
      });
    },

    //  End flight companies

    add_airport() {
      this.allAirports.push({
        lunchTitle: "",
        lunchId: "",
        lunchArr: [],
        returnTitle: "",
        returnId: "",
        returnArr: [],
        date: "",
      });
    },

    remove_airport(index) {
      this.allAirports.splice(index, 1);
    },

    // fetch flights
    async FetchFlights() {
      // Define your function logic here
      let pax = [];
      if (this.adults > 0) {
        pax.push({
          type: "ADT",
          count: this.adults,
        });
      }
      if (this.childrens > 0) {
        pax.push({
          type: "CHD",
          count: this.childrens,
        });
      }
      if (this.babies > 0) {
        pax.push({
          type: "INF",
          count: this.babies,
        });
      }
      // console.log(pax)

      let flight = [];
      flight = this.allAirports.map((item) => {
        return {
          from: item.lunchId == null ? null : item.lunchId,
          to: item.returnId == null ? null : item.returnId,
          date:
            moment(item.date).format("YYYY-MM-DD") == [new Date(), ""]
              ? [new Date(), ""]
              : moment(item.date).format("YYYY-MM-DD"),
        };
      });

      const flightData = {
        status: "multi_city",
        trips: flight,
        paxs: pax,
        cabin_code: this.cabin_code,
      };
      localStorage.setItem("flightData", JSON.stringify(flightData));
      this.loading = true;
      // this.$router.push("/flight-search");
      // this.$emit("runFlight");
      axios.post("/flight/air_shopping", flightData).then((res) => {
        if (res.data.data.offers) {
          Swal.fire({
            icon: "success",
            title: "Flight successfully added",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          this.message = res.data.DescText;
        }
        this.loading = false;
      });
      // console.log(this.tableRows, "tableRows");
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
@import "./_flight.scss";
</style>
