<template>
  <div
    class="modal fade"
    :id="`exampleModal_show${book_id}`"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content px-5 py-2">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("receipt") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img :src="image" class="img-fluid w-100" alt="..." />
        </div>
        <div class="form-group">
          <Multiselect
            v-model="organization"
            :options="organizations"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose')"
            select-label=""
            :hide-selected="true"
          ></Multiselect>
        </div>
        <div class="form-group">
          <label for="">{{ $t("amount") }}</label>
          <input type="number" v-model="amount" class="form-control" />
        </div>
        <div class="modal-footer">
          <button
            @click="upladReceipt"
            type="button"
            class="btn btn-outline-secondary"
            data-bs-dismiss="modal"
          >
            {{ $t("order_receipt") }} <i class="fa-solid fa-info"></i>
          </button>
          <button
            @click="rejectAccept(1)"
            type="button"
            class="btn btn-outline-success"
            data-bs-dismiss="modal"
          >
            {{ $t("Accept") }} <i class="fa-solid fa-check"></i>
          </button>
          <button
            @click="rejectAccept(2)"
            type="button"
            class="btn btn-outline-danger"
            data-bs-dismiss="modal"
          >
            {{ $t("Cancel") }} <i class="fa-solid fa-x"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { Multiselect } from "vue-multiselect";
export default {
  props: ["book_id", "book_type", "image", "organizations"],
  data() {
    return {
      organization: "",
      amount: null,
    };
  },
  methods: {
    upladReceipt() {
      axios
        .post("/send_receipt_request", {
          book_id: this.book_id,
          book_type: this.book_type,
        })
        .then(() => {
          Swal.fire({
            title: "",
            text: this.$t("order_receipt_sent"),
            icon: "success",
          });
        });
    },
    rejectAccept(receipt_status) {
      console.log(this.organization);
      axios
        .post("/change_receipt_status", {
          book_id: this.book_id,
          book_type: this.book_type,
          accountant_id: this.organization.id,
          receipt_status: receipt_status,
          amount: this.amount,
        })
        .then(() => {
          // console.log(response.data, "eeeeeee");
          Swal.fire({
            title: "",
            text:
              receipt_status == 1
                ? this.$t("receipt sent")
                : this.$t("not receipt sent"),
            icon: "success",
          });
        });
    },
  },
  components: {
    Multiselect,
  },
};
</script>
