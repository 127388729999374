<template>
  <div class="row">
    <div class="col-lg-9">
      <Skeleton width="15%" class="mb-2"></Skeleton>
      <div class="card border p-3">
        <div class="d-flex align-items-center justify-content-between">
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
        </div>
        <Skeleton width="100%" height="150px" class="my-3"></Skeleton>
        <div class="d-flex flex-column w-100 justify-content-between">
          <Skeleton width="30%" class="mb-2"></Skeleton>
          <Skeleton width="30%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <Skeleton width="25%" height="2rem"></Skeleton>
      <div class="card w-100 my-3 border p-3">
        <Skeleton width="15%" class="mb-2"></Skeleton>
        <div class="d-flex justify-content-between mt-3">
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
        </div>
        <div class="d-flex justify-content-between mt-3">
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
        </div>
        <hr />
        <div class="d-flex justify-content-between mt-1">
          <Skeleton width="15%" class="mb-2"></Skeleton>
          <Skeleton width="15%" class="mb-2"></Skeleton>
        </div>
        <div
          class="w-100 d-flex justify-content-center align-items-center mt-3"
        >
          <Skeleton size="12rem"></Skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  components: {
    Skeleton,
  },
};
</script>

<style></style>
