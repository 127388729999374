<template>
  <i
    class="fa-solid fa-circle-plus dialog"
    @click="
      visible = true;
      fetch_country();
      fetch_client_types();
      fetch_discount_value();
    "
  ></i>
  <Dialog
    v-model:visible="visible"
    modal
    header="Edit Profile"
    :dismissableMask="true"
    :style="{ width: '80vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
  >
    <add-client @closrDilaog="closrDilaog" />
  </Dialog>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import Dialog from "primevue/dialog";
// import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
import addClient from "@/components/clients/addClient.vue";
export default {
  name: "add-suppliers",
  emits: ["supplierAdded"],
  data() {
    return {
      // v$: useVuelidate(),
      visible: false,
      pageId: this.$route.params.page,
      name: "",
      email: "",
      phone: "",
      address: "",
      countryValues: "",
      countries: [],
      credit_limit: 0, // Initialize as an empty string to store the selected option
      down_payment: 0,
      paids: [
        {
          id: 1,
          title: this.$t("treasury"),
        },
        {
          id: 2,
          title: this.$t("bank"),
        },
        {
          id: 3,
          title: this.$t("ewalllets"),
        },
        {
          id: 4,
          title: this.$t("checks_under_collection"),
        },
      ],
      accountValue: "",
      paidValues: "",
      accounts: [],
      client_type: "",
      types: [
        {
          id: 1,
          title: "person",
        },
        {
          id: 2,
          title: "company",
        },
      ],
      payment_method: 1,
      registration_number: "",
      tax_number: "",
      client_types: [],
      client_type_value: "",
      discount_types: [],
      discount_value: "",
    };
  },
  components: {
    // Multiselect,
    Dialog,
    addClient,
  },
  // validations() {
  //   return {
  //     name: { required },
  //     // email: { required },
  //     // phone: { required },
  //     // address: { required },
  //     client_type: { required },
  //     client_type_value: { required },
  //     // countryValues: { required },
  //     // tax_number: this.client_type == 2 ? { required } : {},
  //     // credit_limit: this.client_type == 2 ? { required } : {},
  //     // registration_number: this.client_type == 2 ? { required } : {},
  //     // down_payment: this.payment_method == 3 ? { required } : {},
  //     // paidValues: this.payment_method == 3 ? { required } : {},
  //     // accountValue: this.payment_method == 3 ? { required } : {},
  //     // discount_value: { required },
  //   };
  // },
  methods: {
    closrDilaog(data) {
      this.visible = data;
    },
    addClient() {
      // this.v$.$validate();

      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("address", this.address);
      formData.append("country_id", this.countryValues);
      formData.append("role", 1);
      formData.append("client_type", this.client_type);
      formData.append("client_type_id", this.client_type_value.id);
      if (this.discount_value?.id != undefined) {
        formData.append("discount_type_id", this.discount_value?.id);
      }
      formData.append(`payment_method`, this.payment_method);
      if (this.payment_method == 2) {
        formData.append("Pending_limit", this.credit_limit);
      }
      if (this.payment_method == 3) {
        formData.append("prepayment_amount", this.down_payment);
      }
      if (this.accountValue) {
        formData.append(`safe_branch_id`, this.accountValue);
      }
      if (this.client_type == 2) {
        formData.append("registration_number", this.registration_number);
        formData.append("tax_number", this.tax_number);
      }
      axios
        .post("/consumers", formData)
        .then(({ data }) => {
          this.visible = !this.visible;
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.resetForm();
          this.$emit("supplierAdded");
        })
        .catch((error) => {
          this.visible = !this.visible;
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    resetForm() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.address = "";
      this.countryValues = "";
      this.client_type = "";
      this.client_type_value = "";
      this.payment_method = 1;
      this.registration_number = "";
      this.tax_number = "";
      this.credit_limit = 0;
      this.down_payment = 0;
      this.accountValue = "";
      this.paidValues = "";
      this.discount_value = "";
    },

    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.countries);
      });
    },

    FetchAccounts(account_id) {
      let type = { payment_method: account_id };
      axios.post(`/fetch_payment_method`, type).then(({ data }) => {
        this.accounts = data.data;
        //  console.log(this.accounts)
      });
    },
    fetch_client_types() {
      axios.post("/index_client_types").then(({ data }) => {
        // console.log(data.data.data);
        this.client_types = data.data.data;
      });
    },
    fetch_discount_value() {
      axios.get("/discount_types").then(({ data }) => {
        // console.log(data);
        this.discount_types = data.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/view/dashboard/clients/_clients.scss";

form {
  label {
    display: inline-block;
    margin-bottom: 10px;
    font-family: "bold" !important;
  }

  input,
  select {
    display: block;
    width: 100%;
    // height: 68px;
    border-radius: 4px;
    border: 1px solid #e8e8e8;
    color: #9ca2ac;
    padding: 10px 15px;
  }
}

.form-check-inline {
  display: inline-flex;
  flex-direction: column;
  margin-top: 0;
}

.form-check-input {
  width: 17px;
  height: 17px;
  padding: 0;
}
</style>
