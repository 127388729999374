<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>
          {{ $t("book_pending") }}
        </h4>
      </div>

      <div class="search-user w-50 my-3">
        <form>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            class="form-control"
            :placeholder="$t('search...')"
            v-model="query"
            @keyup="fetchBookPayment"
          />
        </form>
      </div>
    </div>

    <div class="row">
      <HotelsBookPending
        v-if="book_type == 'Hotel'"
        :books="books"
        :loading="loading"
      />
      <FlightBookPending
        v-if="book_type == 'Flight'"
        :books="books"
        :loading="loading"
      />
      <PackageBookPending
        v-if="book_type == 'Package'"
        :books="books"
        :loading="loading"
      />
    </div>

    <div class="row">
      <pagination
        :data="books"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchBookPayment"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import HotelsBookPending from "@/components/bookPending/hotel.vue";
import FlightBookPending from "@/components/bookPending/flight.vue";
import PackageBookPending from "@/components/bookPending/package.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "book-pending",
  components: {
    pagination,
    HotelsBookPending,
    FlightBookPending,
    AddFavDailog,
    PackageBookPending,
  },
  data() {
    return {
      books: [],
      query: "",
      book_type: this.$route.params.type,
      loading: true,
    };
  },
  methods: {
    fetchBookPayment() {
      const data = {
        book_type: this.book_type,
        word: this.query,
      };
      axios
        .post(`/fetch_book_payment`, data)
        .then(({ data }) => {
          this.books = data.data;
          // console.log(this.books)
          this.loading = false;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetchBookPayment();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.main_header_style {
  align-items: center;

  h4 {
    margin-bottom: 0;
  }
}

.search-user {
  form {
    position: relative;

    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }

    input {
      padding-right: 40px !important;
    }
  }
}
.reservation_num {
  unicode-bidi: plaintext;
  text-align: end;
}
.btn-warning {
  @media (max-width: 1200px) {
    font-size: 14px;
    // margin-top: 10px;
  }
}
</style>
