<template>
  <section class="card_style">
    <form @submit.prevent="addService()">
      <div class="d-flex gap-3 align-items-center mb-3 px-3">
        <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
        <h4 class="title">{{ $t("DiscountTypeAdd") }}</h4>
      </div>
      <div class="row">
        <!-- <h4>
             <i class="fa-solid fa-caret-left"></i>
             المرحله الاولي
           </h4> -->
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <TabView>
              <TabPanel
                v-for="(car, index) in clientType"
                :key="index"
                :class="[car.title !== '' ? 'text-success' : '']"
                :header="$t('title') + ' - ' + car.locale"
              >
                <div class="form-group mb-0">
                  <input
                    type="text"
                    :placeholder="$t('title')"
                    class="form-control"
                    :id="`title${index}`"
                    v-model="car.title"
                  />
                  <!-- Add any additional content specific to each tab here -->
                </div>
              </TabPanel>
            </TabView>
            <!-- <span class="input-errors" v-if="v$.clientType.$error">{{
              v$.clientType.$errors[0].$message
            }}</span> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-12">
          <div class="form-group mt-5">
            <label>{{ $t("discount") }}</label>
            <input
              type="number"
              class="form-control"
              :placeholder="$t('percentage')"
              v-model="percentage"
            />
            <span class="input-errors" v-if="v$.percentage.$error"
              >{{ v$.percentage.$errors[0].$message }}
            </span>
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-12">
          <div class="form-group mt-5">
            <label>{{ $t("value_type") }}</label>
            <Multiselect
              v-model="statusValue"
              :options="statusOptions"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('status')"
              select-label=""
              :hide-selected="true"
            ></Multiselect>
          </div>
        </div>
        <div class="col-lg-3 mb-2">
          <label for="type" class="fw-bold"
            >{{ $t("Manual code") }}

            <sub class="info">
              <b>( {{ $t("unique code for discount type") }} )</b>
            </sub>
          </label>
          <input
            class="form-control"
            type="text"
            :placeholder="$t('Manual code')"
            v-model="manual_code"
          />
          <span class="input-errors" v-if="v$.manual_code.$error"
            >{{ v$.manual_code.$errors[0].$message }}
          </span>
        </div>
      </div>

      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "add-service",
  data() {
    return {
      statusValue: "",
      statusOptions: [
        { id: 1, title: this.$t("value") },
        { id: 2, title: this.$t("percentages") },
      ],
      v$: useVuelidate(),
      clientType: [],
      languages: ["ar", "en", "id"],
      fileData: null,
      manual_code: "",
      Form: {
        title: [],
      },
      percentage: 0,
    };
  },
  validations() {
    return {
      manual_code: { required },
      percentage: {
        required,
      },
      Form: {
        title: this.Form.title.map(() => {
          return {
            title: { required },
          };
        }),
      },
    };
  },
  methods: {
    setCarCatName() {
      this.languages.forEach((language) => {
        this.clientType.push({
          locale: language,
          title: "",
        });
      });
    },

    addService() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.$route.params.id != undefined) {
          const params = new URLSearchParams();
          params.append("manual_code", this.manual_code);

          this.clientType.forEach((car) => {
            params.append(`title_${car.locale}`, car.title);
          });

          params.append("discount_type", this.statusValue.id);

          params.append("percentage", this.percentage);
          axios
            .post(`/update_discount_type/${this.$route.params.id}`, params)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/DiscountType");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          const formData = new FormData();

          this.clientType.forEach((car) => {
            formData.append(`title_${car.locale}`, car.title);
          });

          formData.append("percentage", this.percentage);

          formData.append("discount_type", this.statusValue.id);

          formData.append("manual_code", this.manual_code);
          axios
            .post(`/discount_types`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/DiscountType");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
  },
  created() {
    this.setCarCatName();
  },
  mounted() {
    if (this.$route.params.id != undefined) {
      axios.get(`/discount_types/${this.$route.params.id}`).then((res) => {
        this.clientType?.map((car) => {
          return {
            locale: car.locale,
            title: car.title,
          };
        });
        this.clientType = res.data?.data?.titles;
        this.percentage = res.data.data.percentage;
        this.manual_code = res.data.data.manual_code;
      });
    }
  },
  components: {
    AddFavDailog,
    Multiselect,
    TabView,
    TabPanel,
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

.btn-stratigy {
  background-color: #0a909b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}

input.form-control {
  padding: 0.8rem !important;
}
.fa-caret-left {
  color: #0a909b;
}
</style>
