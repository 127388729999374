<template>
  <section class="card_style">
    <div class="main_header_style">
        <h5 class="mb-4">{{$t("Edit Bed")}}</h5>
    </div>

    <form @submit.prevent="editBed()">
        <div class="row mb-5 mt-3">
            <div class="col-lg-6">
                <label>{{$t("Bed Name in arabic")}}</label>
                <input type="text" :placeholder="$t('enter bed name')" v-model="bedName_ar" />
            </div>
            <div class="col-lg-6">
                <label>{{$t("Bed Name in english")}}</label>
                <input type="text" :placeholder="$t('enter bed name')" v-model="bedName_en" />
            </div>
        </div>

        <button type="submit" class="edit save btn" >{{$t("edit")}}</button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';

export default {
    data(){
        return{
            bedId: this.$route.params.id ,
            bedName_ar:"",
            bedName_en:""
        }
    },
    methods:{
        fetchBedData(){
            axios.get(`/bed_types/${this.bedId}`).then(({data})=>{
                this.bedName_ar = data.data.title_ar;
                this.bedName_en = data.data.title_en;
            })
        },
        editBed(){
            axios.put(`/bed_types/${this.bedId}` , {
                title_en : this.bedName_en ,
                title_ar : this.bedName_ar
            }).then(({data})=>{
                Swal.fire({
                    title: "",
                    text: data.message,
                    icon: "success",
                });
                this.$router.push('/bed')
            })
            .catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        }
    },
    created(){
        this.fetchBedData();
        localStorage.removeItem("flightData");
        localStorage.removeItem("flightTrips");
        localStorage.removeItem("bookDataFlight");
        localStorage.removeItem("bookDetails");
        localStorage.removeItem("hotelData");
        localStorage.removeItem("bookData");
    }
}
</script>

<style lang="scss" scoped>
@import "../_crudStyle.scss";
</style>