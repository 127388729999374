<template>
  <section class="main">
    <div class="container-fluid">
      <div class="card" v-if="loading">
        <div class="row">
          <div class="col-lg-9">
            <div class="d-flex align-items-center gap-1">
              <div class="d-flex flex-column">
                <Skeleton class="my-2"></Skeleton>
                <Skeleton size="10rem" class="my-1 mr-2"></Skeleton>
              </div>
              <div class="d-flex flex-column w-100">
                <div class="d-flex flex-column w-100">
                  <Skeleton width="20%" class="my-2"></Skeleton>
                  <Skeleton width="20%" class="my-2"></Skeleton>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between w-100 mt-3"
                >
                  <Skeleton width="15%" class="my-2"></Skeleton>
                  <Skeleton width="15%" class="my-2"></Skeleton>
                  <Skeleton width="15%" class="my-2"></Skeleton>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="d-flex gap-2 my-2">
              <Skeleton width="4rem" height="2rem"></Skeleton>
              <Skeleton width="4rem" height="2rem"></Skeleton>
              <Skeleton width="4rem" height="2rem"></Skeleton>
            </div>
            <div class="card border p-2">
              <div class="d-flex align-items-center justify-content-between">
                <Skeleton width="50%" class="mb-2"></Skeleton>
                <Skeleton width="20%" height="2rem"></Skeleton>
              </div>

              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex flex-column justify-content-between mt-5 w-100"
                >
                  <Skeleton width="50%" class="mb-2"></Skeleton>
                  <Skeleton width="50%" class="mb-2"></Skeleton>
                  <Skeleton width="50%" class="mb-2"></Skeleton>
                </div>
                <Skeleton size="6rem" class="mt-4"></Skeleton>
              </div>
              <hr />
              <Skeleton width="50%" class="mb-2"></Skeleton>
              <div class="d-flex justify-content-between my-2">
                <Skeleton width="20%" class="mb-2"></Skeleton>
                <Skeleton width="20%" class="mb-2"></Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-else>
        <div class="col-lg-9 col-md-12 col-12">
          <div class="right-sec">
            <detials-reservation :hotelsDetials="hotelsDetials" />
            <hr />
            <hotelAnswer :hotelsDetials="hotelsDetials" />
          </div>
        </div>
        <sidebar
          :hotelsDetials="hotelsDetials"
          :rooms="hotelsDetials?.rooms"
          :clientPhone="hotelsDetials?.client?.phone ?? ''"
          :clientEmail="hotelsDetials?.client?.email ?? ''"
        />
      </div>
    </div>
  </section>
</template>

<script>
import sidebar from "@/components/bookDetails/sidebar.vue";
import hotelAnswer from "@/components/bookDetails/hotelAnswer.vue";
import detialsReservation from "@/components/bookDetails/detialsReservation.vue";
import axios from "axios";
import Skeleton from "primevue/skeleton";

export default {
  components: {
    sidebar,
    hotelAnswer,
    detialsReservation,
    Skeleton,
  },
  name: "single-booking",
  data() {
    return {
      loading: true,
      hotelsDetials: {
        hotels: {},
        guests: {},
        price_model: {},
        rooms: {},
        client: {
          phone: "",
          email: "",
        },
      },
    };
  },
  methods: {
    fetchHotelDetails() {
      let booking_id = { booking_id: this.$route.params.id };
      axios
        .post(`/fetch_booking_details`, booking_id)
        .then(({ data }) => {
          this.hotelsDetials = data.data;
          // console.log(this.hotelsDetials);

          this.loading = false;
          // console.log(this.hotelsDetials);
          // console.log(this.loading);
        })
        .catch((error) => {
          return error;
        });
    },
  },
  mounted() {
    this.fetchHotelDetails();
  },
};
</script>

<style scoped>
@import url("./_singleHotelBooking.scss");
</style>
