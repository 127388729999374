<template>
  <div class="row p-2 bg-white">
    <div class="col-lg-4">
      <div class="avatar w-75 border-start">
        <p class=""><Skeleton height="2rem" class="mb-2"></Skeleton></p>
        <h4 class="person_name my-3">
          <Skeleton height="2rem" class="mb-2"></Skeleton>
        </h4>

        <div class="connects justify-content-between d-flex align-items-center">
          <Skeleton width="30%" height="2rem" class="mb-2"></Skeleton>
          <Skeleton width="30%" height="2rem" class="mb-2"></Skeleton>

          <Skeleton width="30%" height="2rem" class="mb-2"></Skeleton>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div class="row">
        <div class="col-lg-9">
          <div class="border-bottom w-100">
            <p class="">
              <Skeleton width="20%" height="1rem" class="mb-2"></Skeleton>
            </p>
            <div
              class="details d-flex justify-content-between align-items-center"
            >
              <div class="date w-25">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
              </div>

              <div class="date w-25">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
              </div>

              <div class="date w-25">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
              </div>
            </div>
            <div class="details d-flex gap-4 my-3 align-items-center">
              <div class="date w-25">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
              </div>

              <div class="date w-25">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
              </div>
            </div>
          </div>
          <div class="border-bottom w-100 my-3">
            <p class="">
              <Skeleton width="20%" height="1rem" class="mb-2"></Skeleton>
            </p>
            <div
              class="details d-flex justify-content-between align-items-center"
            >
              <div class="date w-25">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
              </div>

              <div class="date w-25">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
              </div>

              <div class="date w-25">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
              </div>
            </div>
            <div class="details d-flex gap-4 my-3 align-items-center">
              <div class="date w-25">
                <Skeleton height="2rem" class="mb-2"></Skeleton>
                <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
export default {
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped></style>
