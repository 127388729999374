<template>
  <section class="setting">
    <div class="container">
        <div class="d-flex justify-content-between align-items-start">
            <div class="texts w-75">
                <router-link class="back" to="/setting"><i class="fa-solid fa-arrow-right"></i> {{$t("back")}}</router-link>
                <h4 class="title_setup">{{$t("selling_fees_companies")}}</h4>
                <p class="text_setup">{{$t("select_fees_want_add_companies_according_following")}}</p>
            </div>
            <router-link class="btn skipButton" to="/dashboard">{{$t("skip")}}</router-link>
        </div>
        <customizeDefultSetup />
        <customizeCompanySetup />
        <hr class="my-5">
        <ul class="instructions">
            <h5 class="title">تعليمات هامة</h5>
            <li>تحديد رسوم الربح هو من اهم الخطوات التى تقوم بها قبل البدأ فى استخدام النظام لضمان الحصول على النسبة التى تريدها</li>
            <li>فى حالة عدم تحديد نسبة الربح التى تريدها سيتم تطبيق النسبة المحددة من قبل الادراة وهو نسبة 10 % فقط بالنسبة للشركت والعملاء</li>
            <li>تحديد رسوم الربح هو من اهم الخطوات التى تقوم بها قبل البدأ فى استخدام النظام لضمان الحصول على النسبة التى تريدها</li>
            <li>فى حالة عدم تحديد نسبة الربح التى تريدها سيتم تطبيق النسبة المحددة من قبل الادراة وهو نسبة 10 % فقط بالنسبة للشركت والعملاء</li>
        </ul>
    </div>
  </section>
</template>

<script>
import customizeCompanySetup from './customizeSetup.vue';
import customizeDefultSetup from '@/components/setup/customizeDefaultSetup.vue';

export default {
    name: "company-setup",
    components: {
        customizeCompanySetup,
        customizeDefultSetup
    },
    created() {
        localStorage.removeItem("flightData");
        localStorage.removeItem("flightTrips");
        localStorage.removeItem("bookDataFlight");
        localStorage.removeItem("bookDetails");
        localStorage.removeItem("hotelData");
        localStorage.removeItem("bookData");
    }
}
</script>

<style lang="scss" scoped>
    @import "../_setup.scss";
</style>
