<template>
  <section class="card_style add_hotel">
    <form @submit.prevent="addOfflineHotel()">
      <div class="control">
        <router-link to="/allHotels" class="return"
          ><i class="fa-solid fa-arrow-right-long"></i>
          {{ $t("return") }}</router-link
        >
        <button type="submit" class="btn PrimaryButton">
          <img src="@/assets/media/image/icon/save.png" class="ms-1" />
          {{ $t("save") }}
        </button>
      </div>

      <div class="row hotel_details">
        <h4>{{ $t("hotel details") }}</h4>

        <div class="col-lg-2 col-md-3">
          <div class="image_upload">
            <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
              <div class="image_caption">
                <i class="fa-regular fa-image"></i>
                <span>
                  {{ $t("Attach a photo of the hotel no larger than 3.5 MB") }}
                </span>
                <a>{{ $t("Attach a photo") }}</a>
              </div>
            </label>
            <img
              :src="imageEntry"
              v-else-if="imageEntry"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              id="imageEntry"
              class="form-control"
              @change="onFileChange"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
          </div>
        </div>
        <div class="col-lg-10 col-md-3">
          <label class="package-label form-label"
            >{{ $t("package_images") }}-({{ $t("Maximum images is 4") }})</label
          >
          <div class="umrah_images_container">
            <div class="umrah_images" :class="{ disable_add_btn: disable_btn }">
              <vue-multi-image-upload
                @data-image="uploadImages"
                :max="max_images_num"
                :image-size="4e6"
                :alert-timeout="3e3"
                :accept="imageType"
                :preview="{ h: 100, w: 100 }"
                :resize="{ h: 500, w: 500, keepRatio: true }"
                :data-reset="component"
                id="multi_image_upload"
              />
              <label class="image_label">
                <img
                  class="main_image"
                  src="@/assets/media/image/icon/image_icon.png"
                />
                <span
                  >{{ $t("Add one or more pictures to the trip content") }}
                  <br />
                  (1250px*500px)</span
                >
              </label>
            </div>
            <div class="images_container" v-if="fetchedImages.length">
              <div class="row">
                <div
                  class="image col-6"
                  v-for="(image, index) in fetchedImages"
                  :key="index"
                >
                  <img :src="image.image" />
                  <button
                    type="button"
                    class="btn remove"
                    @click="deleteFetchedImage(index)"
                  >
                    <i class="fa-solid fa-x"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-6 col-12">
          <div class="row">
            <div
              class="col-4"
              v-for="(hotel, index) in hotel_name"
              :key="index"
            >
              <div class="form-group">
                <label class="form-label"
                  >{{ $t("hotel_name") }} ({{ hotel.lang }})</label
                >
                <img src="@/assets/media/image/icon/buildings.png" />
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('hotel_name')"
                  v-model="hotel.title"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="form-label">{{ $t("country") }}</label>
                <img src="@/assets/media/image/icon/house.png" />
                <Multiselect
                  v-model="country_value"
                  :options="countries"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose_country')"
                  select-label=""
                  :hide-selected="true"
                  @update:model-value="FetchCityFromCountry"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="form-label">{{ $t("city") }}</label>
                <img src="@/assets/media/image/icon/house-2.png" />
                <Multiselect
                  v-model="city_value"
                  :options="cities"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose_city')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="form-label">{{ $t("room_count") }}</label>
                <img src="@/assets/media/image/icon/bed.png" />
                <input
                  type="number"
                  class="form-control"
                  :placeholder="$t('room_count')"
                  v-model="room_count"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="form-label">{{ $t("lng") }}</label>
                <img src="@/assets/media/image/icon/house.png" />
                <input
                  type="number"
                  class="form-control"
                  :placeholder="$t('lng')"
                  v-model="lng"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="form-label">{{ $t("lat") }}</label>
                <img src="@/assets/media/image/icon/house.png" />
                <input
                  type="number"
                  class="form-control"
                  :placeholder="$t('lng')"
                  v-model="lat"
                />
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label class="form-label">{{ $t("star_rating") }}</label>
                <Rating v-model="rate" />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label class="form-label">{{ $t("fatalities") }}</label>
                <Multiselect
                  v-model="fatality_value"
                  :options="fatalities"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('fatalities')"
                  select-label=""
                  :hide-selected="true"
                  :multiple="true"
                >
                </Multiselect>
              </div>
            </div>

            <div class="col-12">
              <div class="form-group">
                <TabView>
                  <TabPanel
                    v-for="(item, index) in addressDetails"
                    :key="index"
                    :class="[item.title !== '' ? 'text-success' : '']"
                    :header="$t('title') + ' - ' + item.locale"
                  >
                    <div class="form-group mb-0">
                      <input
                        type="text"
                        :placeholder="$t('title')"
                        class="form-control"
                        :id="`title${index}`"
                        v-model="item.title"
                      />
                      <!-- Add any additional content specific to each tab here -->
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
            <div class="col-12">
              <div
                class="form-group"
                v-for="(business, index) in businessArray"
                :key="business"
              >
                <div
                  :class="[
                    'd-flex',
                    'justify-content-between',
                    'align-items-center',
                    index === 0 ? 'mb-2' : '',
                  ]"
                >
                  <label class="form-label">{{ $t("business") }}</label>
                  <div class="d-flex gap-2 align-items-center">
                    <div
                      v-if="index === businessArray.length - 1"
                      @click="pushBusiness"
                      class="text-info p-2 border rounded-circle d-flex align-items-center justify-content-center"
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>
                    <div
                      @click="removeBusiness(index)"
                      class="text-danger p-2 border rounded-circle d-flex align-items-center justify-content-center"
                    >
                      <i class="fa-solid fa-minus"></i>
                    </div>
                  </div>
                </div>
                <div class="d-flex gap-2">
                  <div
                    class="form-group"
                    v-for="title in business.title"
                    :key="title"
                  >
                    <label class="form-label">{{ title.locale }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('amenity')"
                      v-model="title.title"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="col-12">
              <div
                class="form-group"
                v-for="(amenity, index) in amenities"
                :key="index"
              >
                <div
                  :class="[
                    'd-flex',
                    'justify-content-between',
                    'align-items-center',
                    index === 0 ? 'mb-2' : '',
                  ]"
                >
                  <label class="form-label">{{ $t("amenity") }}</label>
                  <div class="d-flex gap-2 align-items-center">
                    <div
                      v-if="index === amenities.length - 1"
                      @click="pushAmenities"
                      class="text-info p-2 border rounded-circle d-flex align-items-center justify-content-center"
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>
                    <div
                      @click="removeAmenities(index)"
                      class="text-danger p-2 border rounded-circle d-flex align-items-center justify-content-center"
                    >
                      <i class="fa-solid fa-minus"></i>
                    </div>
                  </div>
                </div>
                <div class="d-flex gap-2">
                  <div
                    class="form-group"
                    v-for="title in amenity.title"
                    :key="title"
                  >
                    <label class="form-label">{{ title.locale }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('amenity')"
                      v-model="title.title"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="col-12">
              <div
                class="form-group"
                v-for="(entertainment, index) in entertainments"
                :key="index"
              >
                <div
                  :class="[
                    'd-flex',
                    'justify-content-between',
                    'align-items-center',
                    index === 0 ? 'mb-2' : '',
                  ]"
                >
                  <label class="form-label">{{ $t("entertainment") }}</label>
                  <div class="d-flex gap-2 align-items-center">
                    <div
                      v-if="index === entertainments.length - 1"
                      @click="pushEntertainment"
                      class="text-info p-2 border rounded-circle d-flex align-items-center justify-content-center"
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>
                    <div
                      @click="removeEntertainment"
                      class="text-danger p-2 border rounded-circle d-flex align-items-center justify-content-center"
                    >
                      <i class="fa-solid fa-minus"></i>
                    </div>
                  </div>
                </div>

                <div class="d-flex gap-2">
                  <div
                    class="form-group"
                    v-for="title in entertainment.title"
                    :key="title"
                  >
                    <label class="form-label">{{ title.locale }}</label>
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('entertainment')"
                      v-model="title.title"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="col-12">
              <div
                class="form-group"
                v-for="(description, index) in descriptions"
                :key="index"
              >
                <div
                  :class="[
                    'd-flex',
                    'justify-content-between',
                    'align-items-center',
                    index === 0 ? 'mb-2' : '',
                  ]"
                >
                  <label class="form-label">{{ $t("descriptions") }}</label>
                  <div
                    class="d-flex gap-2 align-items-center"
                    v-if="index === 0"
                  >
                    <div
                      @click="pushdescriptions"
                      class="text-info p-2 border rounded-circle d-flex align-items-center justify-content-center"
                    >
                      <i class="fa-solid fa-plus"></i>
                    </div>
                    <div
                      @click="removedescriptions()"
                      class="text-danger p-2 border rounded-circle d-flex align-items-center justify-content-center"
                    >
                      <i class="fa-solid fa-minus"></i>
                    </div>
                  </div>
                </div>
                <div class="d-flex gap-2">
                  <div class="form-group w-100">
                    <label class="form-label">{{ description.locale }}</label>
                    <Textarea
                      class="form-control"
                      :placeholder="$t('descriptions')"
                      v-model="description.description"
                    />
                  </div>
                </div>
              </div>
              <!-- <div class="col-4">
                        <div class="form-group">
                            <label class="form-label">{{$t("Date_created")}}</label>
                            <img src="@/assets/media/image/icon/calendar-tick.png" />
                            <datepicker
                                v-model="date"
                                name="from_date"
                                :placeholder="$t('from')"
                            ></datepicker>
                        </div>
                    </div> -->
            </div>
            <hr />
          </div>
        </div>

        <hr />

        <div class="row">
          <h4>{{ $t("Details of hotel facilities") }}</h4>
          <button
            type="button"
            class="btn add_data"
            @click="this.add_attachment = !this.add_attachment"
          >
            <i class="fa-solid fa-plus"></i> {{ $t("add_attachments") }}
          </button>
          <Attachments
            v-if="add_attachment"
            @selected_attachments="selected_attachments"
            :fetchedAmenities="fetchedAmenities"
          />
        </div>

        <hr />
      </div>
    </form>
  </section>
</template>

<script>
// import Datepicker from "vuejs3-datepicker";
import Multiselect from "vue-multiselect";
import Attachments from "@/components/hotelOffline/attachments.vue";
import axios from "axios";
import { VueMultiImageUpload } from "@zakerxa/vue-multiple-image-upload";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Rating from "primevue/rating";

// import moment from 'moment';
import Swal from "sweetalert2";

import Textarea from "primevue/textarea";

export default {
  components: {
    // Datepicker ,
    Multiselect,
    Rating,
    Attachments,
    Textarea,
    VueMultiImageUpload,
    TabView,
    TabPanel,
  },
  data() {
    return {
      addressDetails: [],
      languages: ["ar", "en", "id"],
      inputImages: [],
      component: {},
      fatalities: [
        {
          id: 1,
          title: "wifi",
          icon: "wifi",
        },
        {
          id: 2,
          title: "swimming bool",
          icon: "person-swimming",
        },
        {
          id: 3,
          title: "Restaurant",
          icon: "utensils",
        },
        {
          id: 4,
          title: "Non-smoking rooms",
          icon: "ban-smoking",
        },
        {
          id: 5,
          title: "Family rooms",
          icon: "people-roof",
        },
        {
          id: 6,
          title: "Tea/coffee maker in all rooms",
          icon: "mug-saucer",
        },
        {
          id: 7,
          title: "Superb breakfast",
          icon: "bowl-food",
        },
        {
          id: 8,
          title: "Bar",
          icon: "wine-glass",
        },
        {
          id: 9,
          title: "Private beach area",
          icon: "umbrella-beach",
        },
        {
          id: 10,
          title: "Airport shuttle",
          icon: "van-shuttle",
        },
        {
          id: 12,
          title: "Spa and wellness centre",
          icon: "spa",
        },
        {
          id: 13,
          title: "Fitness centre",
          icon: "dumbbell",
        },
        {
          id: 15,
          title: "Free parking",
          icon: "square-parking",
        },
        {
          id: 17,
          title: "Bicycles available",
          icon: "bicycle",
        },
        {
          id: 18,
          title: "Dry cleaning",
          icon: "soap",
        },
        {
          id: 19,
          title: "Meeting rooms",
          icon: "handshake",
        },

        {
          id: 20,
          title: "24-hour security",
          icon: "shield-halved",
        },
        {
          id: 21,
          title: "ATM on site",
          icon: "money-bills",
        },
        {
          id: 22,
          title: "Air conditioning",
          icon: "fan",
        },
        {
          id: 23,
          title: "Health care",
          icon: "house-medical",
        },
        {
          id: 24,
          title: "Flatscreen TV",
          icon: "tv",
        },
        {
          id: 26,
          title: "Safe",
          icon: "building-shield",
        },
        {
          id: 28,
          title: "Pets Allowed",
          icon: "dog",
        },
        {
          id: 29,
          title: "Taxi service",
          icon: "taxi",
        },
        {
          id: 30,
          title: "Concierge",
          icon: "bell-concierge",
        },
      ],
      lat: 0,
      lng: 0,
      fatality_value: "",
      hotel_name: [],
      descriptions: [],
      amenities: [],
      imageEntry: "",
      videoEntry: "",
      address: "",
      room_count: null,
      countries: [],
      country_value: "",
      cities: [],
      city_value: "",
      add_attachment: false,
      date: new Date(),
      fetchedAmenities: [],
      businessArray: [],
      entertainments: [],
      attachment: [],
      max_images_num: 4,
      fetchedImages: [],
      imageType: ["image/jpeg", "image/png", "image/gif"],
      disable_btn: false,
      sendImages: [],
      rate: 0,
    };
  },
  methods: {
    setCarCatName() {
      this.languages.forEach((language) => {
        this.addressDetails.push({
          locale: language,
          title: "",
        });
      });
    },
    deleteFetchedImage(index) {
      this.fetchedImages.splice(index, 1);
    },
    set_hotel_name() {
      this.languages.forEach((ele) => {
        this.hotel_name.push({
          lang: ele,
          title: "",
        });
      });
    },
    pushEntertainment() {
      this.entertainments.push({
        title: [
          { locale: "en", title: "" },
          { locale: "ar", title: "" },
          { locale: "id", title: "" },
        ],
        id: "",
      });
    },

    pushBusiness() {
      this.businessArray.push({
        title: [
          { locale: "en", title: "" },
          { locale: "ar", title: "" },
          { locale: "id", title: "" },
        ],
        id: "",
      });
    },
    pushAmenities() {
      this.amenities.push({
        title: [
          { locale: "en", title: "" },
          { locale: "ar", title: "" },
          { locale: "id", title: "" },
        ],
        id: "",
      });
    },
    pushdescriptions() {
      this.descriptions.push(
        {
          locale: "en",
          description: "",
        },
        {
          locale: "ar",
          description: "",
        },
        {
          locale: "id",
          description: "",
        },
      );
    },
    removeBusiness(index) {
      this.businessArray.splice(index, 1);
    },
    clear() {
      this.component.clear = true;
    },
    removeAmenities(index) {
      this.amenities.splice(index, 1);
    },
    removeEntertainment(index) {
      this.entertainments.splice(index, 1);
    },
    removedescriptions() {
      this.descriptions.splice(-1, 1);
    },
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    processLanguageArray(array) {
      return this.languages.map((lang) => {
        const foundItem = array.find((item) => item.locale === lang);
        return {
          locale: lang,
          title: foundItem ? foundItem.title : "",
        };
      });
    },
    FetchCityFromCountry(value) {
      let basic_country_ids = {
        country_id: [value.id],
      };
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },
    uploadImages(e) {
      // Calculate the maximum number of images that can be added
      let max_number = 4 - this.fetchedImages.length;
      this.max_images_num = max_number;

      // Enable or disable the add button based on the total number of images
      if (this.inputImages.length + this.fetchedImages.length == 4) {
        this.disable_btn = true;
      } else {
        this.disable_btn = false;
      }

      this.inputImages = e;

      // Create FormData object to hold the images
      let formData = new FormData();
      let base64Strings = []; // Array to store base64 strings

      // Process each image file and convert it to a base64 string
      this.inputImages.forEach((imgFile, index) => {
        const reader = new FileReader();

        // Read the file as a data URL
        reader.readAsDataURL(imgFile);

        // Once the file is loaded, convert it to base64 and append to formData
        reader.onloadend = () => {
          const base64String = `data:image/png;base64,${reader.result.split(",")[1]}`;
          formData.append(`images[${index}]`, base64String);

          // Add the base64 string to the array
          base64Strings.push(base64String);
          this.sendImages = base64Strings; // Update the sendImages
        };
      });
    },

    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    onVideoChange(event) {
      this.VideoData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.VideoData);
      reader.onload = () => (this.videoEntry = reader.result);
    },
    selected_attachments(data) {
      this.attachment = data;
      console.log(this.attachment);
    },
    fetch_hotel_data() {
      axios.get(`/offline_hotels/${this.$route.params.id}`).then(({ data }) => {
        this.addressDetails = data.data.title;
        this.addressDetails?.map((ele) => {
          return {
            locale: ele.locale,
            title: ele.title,
          };
        });
        this.hotel_name = this.languages.map((lang) => {
          const foundTitle = data.data.title.find(
            (title) => title.locale === lang,
          );
          return {
            lang: lang,
            title: foundTitle ? foundTitle.title : "", // Set empty string if title not found for a language
          };
        });
        this.address = data.data.address;
        this.room_count = data.data.room_count;
        this.country_value = data.data.country;
        this.city_value = data.data.city;
        this.amenities = data.data.amenities;
        this.entertainments = data.data.entertainments;
        this.businessArray = data.data.business;
        this.descriptions = data.data.description;
        this.fetchedImages = data.data.images;
        this.fatality_value = data.data.facilities;
        this.rate = data.data.star_count;
        this.lat = data.data.lat;
        this.lng = data.data.lng;
        // console.log(this.fetchedImages);
        if (this.$route.params.id) {
          this.FetchCityFromCountry(this.country_value);
        }
        if (this.businessArray.length < 1) {
          this.pushBusiness();
        }
        if (this.entertainments.length < 1) {
          this.pushEntertainment();
        }
        if (this.amenities.length < 1) {
          this.pushAmenities();
        }
        if (this.descriptions.length < 1) {
          this.pushdescriptions();
        }
        this.amenities.forEach((ele) => {
          ele.title = this.processLanguageArray(ele.title);
        });
        this.entertainments.forEach((ele) => {
          ele.title = this.processLanguageArray(ele.title);
        });
        this.businessArray.forEach((ele) => {
          ele.title = this.processLanguageArray(ele.title);
        });
        // this.descriptions = this.processLanguageArray(this.descriptions);
        // this.fetchedAmenities = data.data.hotel_amenities;
        this.fetchedAmenities = data.data.amenity_types.map((ele) => {
          return {
            attachment_type_value: {
              id: ele.id,
              title: ele.title,
            },
            attachment_values: ele.amenities,
            attachments: [],
          };
        });
      });
    },

    addOfflineHotel() {
      const formData = new FormData();
      if (this.fileData instanceof File) {
        formData.append("image", this.fileData);
      }
      // formData.append("video" , this.VideoData);
      formData.append("city_id", this.city_value.id);
      formData.append("address", this.address);
      formData.append("room_count", this.room_count);
      formData.append("lat", this.lat);
      formData.append("lng", this.lng);
      this.addressDetails.forEach((item) => {
        formData.append(`address_details_${item.locale}`, item.title);
      });
      this.entertainments.forEach((ele, index) => {
        // formData.append(
        //   `entertainment[${index}][id]`,
        //   ele.id === "" ? null : ele.id,
        // );
        ele.title.forEach((element) => {
          formData.append(
            `entertainment[${index}][title_${element.locale}]`,
            element.title,
          );
        });
      });
      this.sendImages.forEach((ele) => {
        formData.append("new_images[]", ele);
      });
      this.fatality_value?.forEach((ele, index) => {
        formData.append(`facilities[${index}][title_en]`, ele.title);
        formData.append(`facilities[${index}][icon]`, ele.icon);
      });
      formData.append("star_count", this.rate);
      // console.log(this.businessArray, "businessArray");
      this.businessArray.forEach((ele, index) => {
        // formData.append(
        //   `business[${index}][id]`,
        //   ele.id === "" ? null : ele.id,
        // );
        ele.title.forEach((element) => {
          formData.append(
            `business[${index}][title_${element.locale}]`,
            element.title,
          );
        });
      });
      this.amenities.forEach((ele, index) => {
        // formData.append(
        //   `amenities[${index}][id]`,
        //   ele.id === "" ? null : ele.id,
        // );
        ele.title.forEach((element) => {
          formData.append(
            `amenities[${index}][title_${element.locale}]`,
            element.title,
          );
        });
      });
      this.descriptions.forEach((ele) => {
        formData.append(`description_${ele.locale}`, ele.description);
      });
      this.attachment.forEach((ele, index) => {
        // formData.append(`attachments[${index}][id]`, ele.id ? ele.id : null);
        formData.append(
          `attachments[${index}][amenity_type_id]`,
          ele.attachment_type_value.id,
        );
        ele.attachment_values.forEach((element) => {
          formData.append(`attachments[${index}][amenity_id]`, element.id);
        });
      });

      // formData.append("created_date" , moment(this.date).format("DD-MM-YYYY"));
      // formData.append("country_id" , this.country_value.id);

      this.hotel_name.forEach((ele) => {
        formData.append(`title_${ele.lang}`, ele.title);
      });

      // this.amenities.forEach((ele, index) => {
      //   formData.append(
      //     `amenities[${index}][amenity_type_id]`,
      //     ele.attachment_type_value.id,
      //   );
      //   ele.attachment_values.forEach((element, elementIndex) => {
      //     formData.append(
      //       `amenities[${index}][amenity_ids][${elementIndex}]`,
      //       element.id,
      //     );
      //   });
      // });

      if (this.$route.params.id != undefined) {
        axios
          .post(`/update_offline_hotel/${this.$route.params.id}`, formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/allHotels");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      } else {
        axios
          .post(`/offline_hotels`, formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/allHotels");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  mounted() {
    this.set_hotel_name();
    this.fetch_country();
    this.pushdescriptions();
    this.pushAmenities();
    this.pushBusiness();
    this.pushEntertainment();
    this.setCarCatName();
    if (this.$route.params.id !== undefined) {
      this.fetch_hotel_data();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

h4 {
  color: #0b1427;
  font-weight: 700;
  font-size: 16px;
}

.control {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.image_upload {
  margin: unset;
  border: unset;
  .upload_img {
    border: 1px dashed #d0d2d7;
    color: #576b74;
    font-size: 12px;
    background-color: #d0d2d736;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    div {
      display: flex;
      flex-direction: column;
      text-align: center;
      i {
        color: #656b78;
        font-size: 18px;
      }
      a {
        color: $PrimaryColor;
        text-decoration: underline;
      }
    }
  }
}
hr {
  width: 100%;
  height: 4px;
  background-color: #e7e8eb;
  border-color: #e7e8eb;
}
.add_data {
  width: auto;
  color: $PrimaryColor;
}
.offcanvas {
  height: 80%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.return {
  color: #656b78;
}
.image_caption {
  width: 80%;
}
.form-group {
  position: relative;
  img {
    position: absolute;
    right: 0;
    top: 3rem;
    z-index: 33;
  }
  .form-control {
    padding: 0.7rem !important;
    padding-right: 35px !important;
  }
}
.images_container {
  .image {
    width: 100px;
    height: 100px;
    position: relative;
    border: 2px solid #dfd4d4;
    box-sizing: content-box;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    button {
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      outline: none;
      border: none;
      i {
        position: absolute;
        top: 5px;
        right: 5px;
        color: red;
        border-radius: 50%;
        border: 1px solid red;
        padding: 5px;
        font-size: 10px;
      }
    }
  }
}
.umrah_images_container {
  display: flex;
  align-items: center;

  gap: 5px;
  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column-reverse;
  }
}
.umrah_images {
  height: 100%;
  position: relative;
  .form-label {
    color: #656b78;
    font-family: "bold";
  }
  .image_label {
    width: 100px;
    height: 100px;
    z-index: 10;
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    box-sizing: content-box;
    margin-bottom: unset !important;
    font-size: unset !important;
    background-color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #9ca2ac;
    border-radius: 4px;
    span {
      display: inline-block;
      font-size: 11px;
      color: #656b78;
    }
  }
}
.umrah_images.disable_add_btn {
  .image_label {
    opacity: 0;
  }
}
</style>
