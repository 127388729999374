<template>
  <section class="all_emp card_style">
    <div class="row mb-3">
      <div class="col-lg-2 col-md-12 col-12 gap-3 d-flex align-items-center">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("room reservations") }}</h4>
      </div>
      <div class="col-lg-5 col-md-12 col-12">
        <form>
          <div class="form-group book_search">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control"
              v-model="bookSearch"
              :placeholder="$t('search by name')"
              @keyup="fetchBooks((page = 1))"
            />
          </div>
        </form>
      </div>
    </div>
    <Transition name="fade">
      <div class="books-wrapper" v-if="loading">
        <div
          class="books mt-1 row py-1 shadow px-3 align-items-center"
          v-for="book in 10"
          :key="book"
        >
          <div class="col-1">
            <Skeleton height="2rem"></Skeleton>
          </div>
          <div class="col-1 mt-2 mb-2">
            <Skeleton height="1rem" class="mb-2"></Skeleton>
            <Skeleton height="1rem"></Skeleton>
          </div>
          <div v-for="index in 5" :key="index" class="col-2 mt-2 mb-2">
            <Skeleton height="1rem" class="mb-2"></Skeleton>
            <Skeleton height="1rem"></Skeleton>
          </div>
        </div>
      </div>
      <div class="books-wrapper" v-else>
        <div
          class="books mt-1 d-flex align-items-center py-1 shadow px-3 flex-lg-nowrap"
          v-for="(book, index) in books"
          :key="index"
        >
          <div class="id">
            <router-link
              class="details_id"
              :to="`/hotels/book_offline/${book.id}`"
              target="_blank"
              :title="$t('edit')"
            >
              <h4 class="text-center" v-tooltip.left="$t('show_details')">
                {{ index + 1 }}
              </h4>
            </router-link>
          </div>
          <div
            class="d-flex serial align-items-center justify-content-center flex-column"
          >
            <p class="text-center text-temperature">{{ book.book_number }}</p>
            <span
              v-tooltip.bottom="$t('book_status')"
              :class="[
                'badge mx-1',
                book?.book_offline_status == 0
                  ? 'text-secondary text-capitalize'
                  : book?.book_offline_status == 1
                    ? 'text-primary text-capitalize'
                    : book?.book_offline_status == 2
                      ? 'text-success text-capitalize'
                      : book?.book_offline_status == 3
                        ? 'text-danger text-capitalize'
                        : book?.book_offline_status == 4
                          ? 'text-danger text-capitalize'
                          : '',
              ]"
            >
              {{
                book?.book_offline_status == 0
                  ? $t("pending")
                  : book?.book_offline_status == 1
                    ? $t("tentative")
                    : book?.book_offline_status == 2
                      ? $t("confirmed")
                      : book?.book_offline_status == 3
                        ? $t("cancelled")
                        : book?.book_offline_status == 4
                          ? $t("canceled")
                          : ""
              }}
            </span>
          </div>
          <div class="book_type">
            <p>{{ $t("book_type") }}</p>
            <p>{{ book?.book_type }}</p>
          </div>
          <div class="option_date">
            <p>{{ $t("option_date") }}</p>
            <p>
              {{
                book?.client_option_date == "" ? "--" : book?.client_option_date
              }}
            </p>
          </div>
          <div
            class="info d-flex align-items-center justify-content-end flex-column"
          >
            <div class="d-flex">
              <p class="fw-bold" v-tooltip.left="$t('hotel_name')">
                {{ book.hotel_name }}
              </p>
              <span
                class="nights p-1 d-flex align-items-center justify-content-center mx-1"
                v-tooltip.left="$t('number_of_nights')"
                ><span>{{ book.num_of_nights }}</span> <span> N</span>
              </span>
            </div>
            <p v-tooltip.left="$t('check-in:check-out')">
              {{ book.check_in_date }} -- {{ book.check_out_date }}
            </p>
          </div>
          <div class="rooms mt-0 mb-0">
            <div
              v-for="(room, index) in book.rooms"
              :key="index"
              class="room d-block flex-row-reverse"
              style="width: max-content"
            >
              <!-- <span>{{ room.count }}</span> -->
              <div class="d-flex align-items-center">
                <p
                  class="text-center text-primary"
                  v-tooltip.left="$t('room_type')"
                >
                  {{ room?.title }}
                </p>
                <!-- <p
                  class="text-center text-success"
                  v-tooltip.left="$t('supplier')"
                >
                  {{ room?.supplier?.title }}
                </p> -->
                <span
                  v-if="room?.rate_code"
                  class="text-center text-capitalize text-info"
                  v-tooltip.left="$t('rate_code')"
                  >, {{ room?.rate_code }}</span
                >
              </div>
            </div>
          </div>

          <div class="d-flex room_con flex-column">
            <div
              class="d-flex align-items-center justify-content-center"
              v-for="(room, index) in book.rooms"
              :key="index"
            >
              <p v-tooltip.left="$t('hotel_confirmation')">h.con:</p>
              <p class="hot_con text-center" v-tooltip.left="$t('code')">
                {{ room?.hotel_reference }}
              </p>
            </div>
          </div>

          <div class="cost d-flex justify-content-end gap-3">
            <div class="d-flex flex-column w-50 mx-2">
              <p style="width: max-content" v-tooltip.left="$t('Date_created')">
                {{ book?.created_at }}
              </p>
              <p style="width: max-content" v-tooltip.left="$t('client_name')">
                {{ book?.client }}
              </p>
            </div>
            <div
              class="d-flex flex-column"
              :class="`${book.post_status == '0' ? 'd-none' : ''}`"
            >
              <div
                v-if="book.post_status == '0'"
                :class="`icons w-100 ${
                  book.post_status == '0' ? ' text-white p-1 rounded ' : ''
                }`"
              >
                <p
                  class="paid d-flex text-warning align-items-center justify-content-center px-2"
                ></p>
              </div>
              <div
                v-else-if="book.post_status == '1'"
                :class="`icons w-100 ${
                  book.post_status == '1' ? ' text-white p-1 rounded ' : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center text-warning justify-content-center px-2"
                >
                  {{ $t("POST") }}
                </p>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center total_price w-50">
              <p class="total mb-0" v-tooltip.left="$t('total')">
                {{ book.total_selling_price }}
              </p>

              <!-- <p>{{ book.client_currency_code }}</p> -->
              <p class="total_company" v-tooltip.left="$t('total_without_tax')">
                <!-- {{ book.organization_currency_code }} -->
                {{ Math.round(book?.total_room_cost_with_out_tax) }}
              </p>
              <div
                :class="` ${
                  book.payment_status == '0'
                    ? ' text-danger p-1 rounded mb-1'
                    : ''
                }`"
              ></div>
              <span class="hint_element bank_hint">{{
                $t("total_company")
              }}</span>
              <span class="hint_element bank_hint">{{
                $t("total_client")
              }}</span>

              <!-- <div
                v-if="book.book_status == '1'"
                :class="`icons w-100 ${
                  book.book_status == '1'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("paid_ok") }}
                </p>
              </div>
              <div
                v-if="book.book_status == '2'"
                :class="`icons w-100 ${
                  book.book_status == '2'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("Decline_payment") }}
                </p>
              </div>
              <div
                v-if="book.book_status == '3'"
                :class="`icons  w-100 ${
                  book.book_status == '3' ? ' text-white p-1 rounded' : ''
                }`"
              >
                <p
                  class="paid text-warning d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND PENDING") }}
                </p>
              </div>
              <div
                v-if="book.book_status == '4'"
                :class="`icons w-100 ${
                  book.book_status == '4'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Accept") }}
                </p>
              </div>
              <div
                v-if="book.book_status == '5'"
                :class="`icons w-100 ${
                  book.book_status == '5'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Decline") }}
                </p>
              </div>
              <div
                v-if="book.book_status == '6'"
                :class="`icons w-100 ${
                  book.book_status == '6'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("payment sent") }}
                </p>
              </div> -->
            </div>
          </div>
          <!-- <div
            class="conf flex-column px-2 text-center d-flex align-items-start justify-content-between"
          >
            <h6 class="mb-0" v-tooltip.bottom="$t('hotel_name')">
              {{ book.hotel_name }}
            </h6>
  
            <div v-if="book.book_status === 2">
              <p>H.Conf : #{{ book.confirmation_number }}</p>
            </div>
          </div> -->
          <!-- <div
            class="client d-flex flex-column align-items-center justify-content-end"
          >
            <h4 class="text-center" v-tooltip.bottom="$t('client_name')">
              {{ book?.client?.name }}
            </h4>
            <div
              class="d-flex align-items-center justify-content-center flex-column"
            >
              <p v-tooltip.bottom="$t('guest_name')">
                {{ book?.guest_name?.name }}
              </p>
            </div>
          </div> -->
        </div>
      </div>
    </Transition>

    <div class="row mt-3">
      <pagination
        :data="books"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchBooks"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>
<script>
import axios from "axios";
// import { useBookStore } from "@/store/rooms/index";
// import { mapState } from "pinia";
import Skeleton from "primevue/skeleton";
import pagination from "laravel-vue-pagination";

export default {
  data() {
    return {
      books: [],
      indexPage: 1,
      loading: true,
      bookSearch: "",
      day: this.$route.params.day,
      contract: this.$route.params.contract,
      status: this.$route.params.status,
      hotelId: this.$route.params.hotel_id,
    };
  },
  components: {
    Skeleton,
    pagination,
  },
  methods: {
    fetchBooks() {
      const formData = new FormData();
      formData.append("day", this.day);
      formData.append("type", this.status);
      formData.append("hotel_id", this.hotelId);
      axios
        .post("/fetch_offline_booking_upon_type_and_day", formData)
        .then(({ data }) => {
          this.books = data.data;
          this.loading = false;
          // console.log(this.books);
        })
        .catch((error) => {
          this.books = [];
          this.loading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchBooks();
  },
};
</script>

<style lang="scss" scoped>
//   @import "@/view/dashboard/offline/_offline.scss";
@import "@/view/dashboard/hotelsBookOffline/_hotelsBookOffline.scss";
@import "@/assets/scss/_variables.scss";

.text-warning {
  font-size: 0.8rem !important;
}
.text-temperature {
  text-align: center !important;
}
.postToAccount {
  width: 10%;
}
.SecondButton {
  height: unset !important;
}
.total_price {
  width: 10%;
}
.book_search {
  position: relative;
  margin-bottom: unset !important;
  i {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  input {
    width: 100%;
    padding-right: unset !important;
    padding-right: 40px !important;
  }
}
.add_book.PrimaryButton {
  margin: unset !important;
}
.book_type {
  width: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
