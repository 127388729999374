<template>
  <section class="card_style">
    <!-- skelton -->
    <div class="row" v-if="$route.params.id && loading">
      <div class="col-lg-12 col-md-12 col-12 my-3">
        <Skeleton width="20%" class="mb-2"></Skeleton>
      </div>
      <div class="col-lg-5 col-md-4 col-12 my-3">
        <Skeleton width="10%" class="mb-2"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
      </div>
      <div class="col-lg-5 col-md-4 col-12 my-3">
        <Skeleton width="10%" class="mb-2"></Skeleton>
        <Skeleton height="2rem" class="mb-2"></Skeleton>
      </div>
    </div>
    <!-- skelton -->

    <form @submit.prevent="addService()" v-else>
      <div class="row edit-package-star">
        <h4 v-if="!$route.params.id">{{ $t("Add_package_Star_Category") }}</h4>
        <h4 v-else>{{ $t("Edit_package_Star_Category") }}</h4>

        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group">
            <TabView>
              <TabPanel
                v-for="(blog_title, index) in Form.title"
                :key="index"
                :class="[blog_title.title !== '' ? 'text-success' : '']"
                :header="$t('program name') + ' - ' + blog_title.locale"
              >
                <div class="form-group mb-0">
                  <input
                    type="text"
                    :placeholder="$t('name')"
                    class="form-control"
                    :id="`name${index}`"
                    v-model="blog_title.title"
                  />
                  <!-- Add any additional content specific to each tab here -->
                </div>
              </TabPanel>
            </TabView>
            <!-- <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(blog_title, index) in Form.title"
                      :key="index"
                    >
                      <label for="name"
                        >{{ $t("title") }} ({{ blog_title.locale }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="blog_title.title"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <span
                class="text-danger"
                v-if="v$.Form.title.$error"
                v-text="v$.Form.title.$errors[0].$message"
              ></span>
            </div> -->
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12 mt-2">
          <label for="inputPassword5" class="form-label">{{
            $t("star_number")
          }}</label>
          <input
            type="number"
            id="inputPassword5"
            class="form-control"
            aria-describedby="passwordHelpBlock"
            v-model="star_count"
            min="1"
            max="5"
          />
          <span
            class="text-danger"
            v-if="v$.star_count.$error"
            v-text="v$.star_count.$errors[0].$message"
          ></span>
        </div>
      </div>

      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Skeleton from "primevue/skeleton";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
export default {
  name: "add-service",
  data() {
    return {
      v$: useVuelidate(),
      languages: ["ar", "en", "id"],
      fileData: null,
      Form: {
        title: [],
      },
      star_count: 0,
      loading: true,
    };
  },
  validations() {
    return {
      Form: {
        title: this.Form.title.map(() => {
          return {
            title: { required },
          };
        }),
      },
      star_count: { required },
    };
  },
  methods: {
    setBlogTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          locale: language,
          title: "",
        });
      });
    },

    addService() {
      this.v$.$validate();
      if (!this.v$.$error) {
        if (this.$route.params.id != undefined) {
          const params = new URLSearchParams();

          this.Form.title.forEach((ele) => {
            params.append(`title_${ele.locale}`, ele.title);
          });

          params.append("star_count", this.star_count);
          axios
            .put(`/packagestarcategory/${this.$route.params.id}`, params)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/package_Star_Category");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          const formData = new FormData();

          this.Form.title.forEach((ele) => {
            formData.append(`title_${ele.locale}`, ele.title);
          });

          formData.append("star_count", this.star_count);
          axios
            .post(`/packagestarcategory`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/package_Star_Category");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
  },
  created() {
    this.setBlogTitle();
  },
  mounted() {
    if (this.$route.params.id != undefined) {
      axios.get(`/packagestarcategory/${this.$route.params.id}`).then((res) => {
        this.Form.title = res.data.data.titles;
        this.star_count = res.data.data.star_count;
        this.loading = false;
      });
    }
  },
  components: {
    Skeleton,
    TabView,
    TabPanel,
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

.btn-stratigy {
  background-color: #0a909b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
}

input.form-control {
  padding: 0.8rem !important;
}
.fa-caret-left {
  color: #0a909b;
}
</style>
