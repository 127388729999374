<template>
  <section class="details_organization_card">
    <div class="main_header_style bg-white my-4">
      <h4>{{ $t("company_back") }}</h4>
      <div class="d-flex flex-row justify-content-between w-50">
        <button
          style="margin-inline-end: 1%"
          @click="BLOCKAlert()"
          class="PrimaryButton delete btn w-50 bg-primary"
          v-if="block_company == true"
        >
          {{ $t("unbolck_company") }}
        </button>
        <button
          style="margin-inline-end: 1%"
          @click="BLOCKAlert()"
          class="PrimaryButton delete btn w-50 bg-danger"
          v-else
        >
          {{ $t("bolck_company") }}
        </button>
        <router-link
          :to="{ name: 'editOrganization' }"
          class="PrimaryButton btn w-50"
        >
          {{ $t("edit_company") }}</router-link
        >
      </div>
    </div>
    <div class="title">
      <img src="../../assets/media/image/details-shape.png" alt="" />
      <h6>{{ $t("general_details") }}</h6>
      <router-link class="edit" :to="{ name: 'editOrganization' }">
        <i class="fa-solid fa-pen"></i
      ></router-link>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-lg-6 col-12">
          <h4>{{ details.name }}</h4>
          <ul>
            <li><i class="fa-solid fa-phone"></i> {{ details.phone }}</li>
            <li style="margin-inline-start: 9%">
              <i class="fa-solid fa-envelope"></i> {{ details.email }}
            </li>
            <li class="w-100">
              <i class="fa-solid fa-map-pin"></i> {{ details.address }}
            </li>
          </ul>

          <div class="map">
            <GoogleMap
              :center="{
                lat: parseFloat(details.lat),
                lng: parseFloat(details.lon),
              }"
              :zoom="zoom"
              :api-key="googleMapsApiKey"
            >
              <Marker
                :position="{
                  lat: parseFloat(details.lat),
                  lng: parseFloat(details.lon),
                }"
                :icon="customIcon"
              />
            </GoogleMap>
          </div>

          <div class="respons_details">
            <h6>{{ $t("respons_detials") }}</h6>
            <h4>{{ details.lat }}</h4>
            <ul>
              <li><i class="fa-solid fa-phone"></i> {{ details.phone }}</li>
              <li style="margin-inline-start: 9%">
                <i class="fa-solid fa-envelope"></i> {{ details.email }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div
            class="total-balance d-flex align-items-center justify-content-between"
          >
            <img src="@/assets/media/image/Mask-group.png" />
            <img src="@/assets/media/image/Mask-group.png" />
            <img src="@/assets/media/image/Mask-group.png" />
            <div class="d-flex align-items-center gap-3">
              <div class="image">
                <img src="@/assets/media/image/wallet.png" alt="wallet" />
              </div>
              <div class="text">
                <p>{{ $t("the total current balance") }}</p>
                <p class="price">{{ details.my_earning }} SAR</p>
              </div>
            </div>
            <div>
              <button
                class="charge btn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
              >
                {{ $t("charge balance") }}
              </button>
            </div>
          </div>
          <!-- chart -->
          <div class="percentage_profite respons_details w-100 mt-5">
            <h4>{{ $t("percentage_profite") }}</h4>
            <div class="row">
              <div class="col-lg-7 col-12">
                <vc-donut :sections="sections">{{ $t("profite") }}</vc-donut>
              </div>
              <div class="col-lg-5 col-12">
                <ul>
                  <li>
                    <span class="my_profite"></span> {{ $t("my_profite") }}
                  </li>
                  <li>
                    <span class="my_all_profite"></span>
                    {{ $t("my_all_compny") }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- chart -->
        </div>
      </div>
    </div>
    <!-- charge wallet popup -->
    <CharegeWalletOffcanves />
    <!-- @custom-event="fetchWalletsData()" -->
    <!-- end charge wallet popup -->
  </section>
</template>

<script>
import { GoogleMap, Marker } from "vue3-google-map";
// import chart from "./chart.vue";
import CharegeWalletOffcanves from "../wallet/CharegeWalletOffcanves.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "details-componenets",
  components: {
    GoogleMap,
    Marker,
    // chart,
    CharegeWalletOffcanves,
  },
  props: {
    details: {},
  },
  data() {
    return {
      customIcon: {
        url: "https://cdn-icons-png.flaticon.com/512/11083/11083698.png",
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      zoom: 3, // Default zoom level,
      sections: [{ value: 20 }, { value: 80 }],
      block_company: false,
    };
  },
  computed: {
    googleMapsApiKey() {
      return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    },
  },
  methods:{
    BLOCKAlert() {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_block"),
      }).then((result) => {
        if (result.isConfirmed) {
          let block_id = { organization_id: this.$route.params.id };
          const newLocal = this.$i18n.locale;
          axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios
            .post("/change_block_organization", block_id)
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.block_company = data.data.block == 1 ? true : false;
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../view/dashboard/organization/_organization.scss";
</style>
