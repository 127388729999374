<template>
  <section class="card_style">
    <div class="d-flex gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h4>
        <!-- {{
          this.route_type == 1
            ? $t("salesAnalysis for clients")
            : this.route_type == 2
              ? $t("salesAnalysis for hotels")
              : this.route_type == 3
                ? $t("salesAnalysis for nationality")
                : this.route_type == 4
                  ? $t("salesAnalysis for supplier")
                  : this.route_type == 5
                    ? $t("salesAnalysis for empolyee")
                    : $t("salesAnalysis")
        }} -->

        {{ $t("total-salesAnalysis") }}
      </h4>
    </div>
    <form @submit.prevent="filterData()">
      <div class="row mb-3">
        <div class="col-lg-2">
          <datepicker
            v-model="from_date"
            name="from_date"
            :placeholder="$t('from')"
            class="w-100"
          ></datepicker>
          <div class="input-errors" v-if="v$.from_date.$error">
            {{ v$.from_date.$errors[0].$message }}
          </div>
        </div>
        <div class="col-lg-2">
          <datepicker
            v-model="to_date"
            name="from_date"
            :placeholder="$t('to')"
            :disabled-dates="{
              to: new Date(from_date),
            }"
            class="w-100"
          ></datepicker>
          <div class="input-errors" v-if="v$.to_date.$error">
            {{ v$.to_date.$errors[0].$message }}
          </div>
        </div>
        <div class="col-lg-2">
          <Multiselect
            v-model="route_type"
            :options="types.map((country) => country.id)"
            :custom-label="(opt) => types.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :hide-selected="true"
            :placeholder="$t('Booking Type')"
            select-label=""
            @update:model-value="resetData()"
            class="w-100"
          >
          </Multiselect>
          <div class="input-errors" v-if="v$.route_type.$error">
            {{ v$.route_type.$errors[0].$message }}
          </div>
        </div>

        <div class="col-lg-2">
          <button class="btn PrimaryButton w-100" type="submit">
            <i class="fa-solid fa-filter"></i> {{ $t("filter") }}
          </button>
        </div>
        <div
          class="col-lg-3 col-md-12 col-12 d-flex align-items-center justify-content-end"
          v-if="clients.length > 0"
        >
          <button
            class="btn SecondButton mx-2"
            v-print="'#printMe'"
            title="PDF"
          >
            <i class="fa-solid fa-file-pdf"></i>
          </button>
        </div>
      </div>
    </form>

    <div id="printMe" class="row">
      <div class="table-responsive" v-if="clients.length > 0">
        <HeaderPdf
          :title="
            route_type == 1
              ? $t('salesAnalysis for clients')
              : route_type == 2
                ? $t('salesAnalysis for hotels')
                : route_type == 3
                  ? $t('salesAnalysis for nationality')
                  : route_type == 4
                    ? $t('salesAnalysis for supplier')
                    : route_type == 5
                      ? $t('salesAnalysis for employee')
                      : $t('salesAnalysis')
          "
          :fromDate="from_date"
          :toDate="to_date"
        />

        <table class="table clients" id="RoomTable">
          <thead>
            <tr>
              <!-- <th>{{ $t("Serial") }}</th> -->
              <th class="no-print">{{ $t("Id") }}</th>
              <th>
                {{
                  this.route_type == 1
                    ? $t("Client Name")
                    : this.route_type == 2
                      ? $t("hotel Name")
                      : this.route_type == 3
                        ? $t("nationality Name")
                        : this.route_type == 4
                          ? $t("Supplier Name")
                          : this.route_type == 5
                            ? $t("Empolyee Name")
                            : "name"
                }}
              </th>
              <!-- <th>{{ $t("Nationality") }}</th> -->
              <th>{{ $t("number of Reversed") }}</th>
              <th>{{ $t("number of rooms") }}</th>
              <th>{{ $t("number of nights") }}</th>
              <th>{{ $t("Revenu") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="client in 10" :key="client">
              <td v-for="client in 6" :key="client">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(client, index) in clients" :key="index">
              <!-- <td>{{ index + 1 }}</td> -->
              <td class="no-print">{{ client.id }}</td>
              <td>{{ client.title }}</td>
              <!-- <td>{{ client.Nationality }}</td> -->
              <td>
                <span
                  v-tooltip.top="
                    $t('canceled') +
                    ': ' +
                    client.booked_canceled_number +
                    ' ' +
                    $t('tentative') +
                    ': ' +
                    client.booked_tentative_number +
                    ' ' +
                    $t('booked') +
                    ': ' +
                    client.booked_confirmed_number +
                    ' ' +
                    $t('pending') +
                    ': ' +
                    client.booked_pending_number
                  "
                  >{{ client.booked_number }}</span
                >
              </td>
              <td>{{ client.rooms_count }}</td>
              <td>{{ client.rooms_nights }}</td>
              <td>{{ client.selling_price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <div class="d-flex align-items-center justify-content-center">
          <img
            src="@/assets/media/image/no-data.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <p class="text-center my-5 fs-3">{{ $t("Please Enter Data") }}</p>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="clients"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_clients_analysis"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
import Datepicker from "vuejs3-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderPdf from "@/components/pdf/headerPdf.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "salesAnalysis",
  components: {
    pagination,
    Skeleton,
    Datepicker,
    Multiselect,
    HeaderPdf,
    AddFavDailog,
  },
  data() {
    const date = new Date();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
      v$: useVuelidate(),
      loading: false,
      clients: [],
      type: "",
      types: [
        {
          id: 1,
          title: this.$t("client"),
        },
        {
          id: 2,
          title: this.$t("hotels"),
        },
        {
          id: 3,
          title: this.$t("nationality"),
        },
        {
          id: 4,
          title: this.$t("supplier"),
        },
        {
          id: 5,
          title: this.$t("empolyee"),
        },
      ],
      from_date: firstDayOfMonth,
      to_date: lastDayOfMonth,
      route_type: this.$route.params.type,
    };
  },
  validations() {
    return {
      from_date: { required },
      to_date: { required },
      route_type: { required },
    };
  },
  methods: {
    fetch_clients_analysis() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;

        let fromDate = moment(this.from_date).format("YYYY-MM-DD");
        let toDate = moment(this.to_date).format("YYYY-MM-DD");

        const formData = new FormData();
        formData.append("type", this.route_type);
        formData.append("from_date", fromDate);
        formData.append("to_date", toDate);

        axios
          .post(`/offlineBookingTypeReport`, formData)
          .then(({ data }) => {
            this.clients = data.data;
            this.loading = false;
            if (this.clients.length === 0) {
              this.loading = false;
              Swal.fire({
                title: "",
                text: this.$t("No Data Found"),
                icon: "error",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false;
          });
        this.$router.push(
          `/salesAnalysis/${this.route_type}/${fromDate}/${toDate}`
        );
      }
    },
    filterData() {
      this.fetch_clients_analysis();
    },
    resetData() {
      this.clients = [];
    },
  },
  mounted() {
    this.route_type = this.$route.params.type;

    if (this.$route.params.checkin !== undefined) {
      this.from_date = this.$route.params.checkin;
      this.to_date = this.$route.params.checkout;

      this.fetch_clients_analysis();
    }
  },
  watch: {
    $route(to) {
      this.route_type = to.params.type;
      this.clients = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .no-print {
    display: none !important;
  }
  .table {
    thead {
      tr {
        th {
          padding-inline: 1.5rem !important;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-inline: 1.5rem !important;
        }
      }
    }
  }
}
</style>
