<template>
  <section class="card_style">
    <div class="d-flex align-items-center gap-3 mb-3">
      <Fav v-tooltip.left="$t('add_to_favorites')" />
      <h4>{{ $t("The Wallet") }}</h4>
    </div>
    <!-- <div class="row">
      <div class="col-12 col-lg-6">
        <div class="wall">
          <div
            class="total-balance d-flex align-items-center justify-content-between"
          >
            <img src="@/assets/media/image/Mask-group.png" />
            <img src="@/assets/media/image/Mask-group.png" />
            <img src="@/assets/media/image/Mask-group.png" />
            <div class="d-flex align-items-center gap-3">
              <div class="image">
                <img src="@/assets/media/image/wallet.png" alt="wallet" />
              </div>
              <div class="text">
                <p>{{ $t("the total current balance") }}</p>
                <p class="price">{{ userWallet.wallet }} SAR</p>
              </div>
            </div>
            <div>
              <button
                class="charge btn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottom"
                aria-controls="offcanvasBottom"
              >
                {{ $t("charge balance") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 col-12">
        <div class="wall">
          <div
            class="credit-balance d-flex align-items-center justify-content-between"
          >
            <div class="text">
              <p>{{ $t("credit outstanding balance") }}</p>
              <p class="price">{{ userWallet.to_pending }} SAR</p>
            </div>
            <i class="fa-solid fa-arrow-up-long"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 col-12">
        <div
          class="debit-balance d-flex align-items-center justify-content-between"
        >
          <div class="text">
            <p>{{ $t("debit outstanding balance") }}</p>
            <p class="price">{{ userWallet.for_pending }} SAR</p>
          </div>
          <i class="fa-solid fa-arrow-up-long"></i>
        </div>
      </div>
    </div> -->
    <!-- charge wallet popup -->
    <CharegeWalletOffcanves @custom-event="fetchWalletsData()" />
    <!-- end charge wallet popup -->

    <div class="row my-2">
      <div class="col-lg-6 col-md-12 col-12">
        <h4>{{ $t("Transaction History") }}</h4>
      </div>
      <div class="col-lg-3 col-md-12 col-12">
        <!-- <select class="form-select one" aria-label="Default select example">
            <option value="all">{{ $t("shipping requests") }}</option>
            <option value="all">{{ $t("transfer requests") }}</option>
            <option value="all">{{ $t("company transactions") }}</option>
          </select> -->
        <!-- <multiselect
          v-model="value_wallet"
          :options="types"
          :custom-label="nameWithLang"
          :placeholder="$t('type')"
          label="name"
          track-by="name"
          select-label=""
          :hide-selected="true"
          @update:modelValue="fetchpending"
        ></multiselect> -->
      </div>
      <div class="col-lg-3 col-md-12 col-12">
        <!-- <select class="form-select two" aria-label="Default select example">
            <option value="all">{{ $t("balance transfer") }}</option>
            <option value="all">{{ $t("all transfers") }}</option>
            <option value="all">{{ $t("charging the wallet") }}</option>
          </select> -->
        <!-- <multiselect
          v-model="value"
          :options="transfer"
          :custom-label="nameWithLang"
          :placeholder="$t('transfer request from')"
          label="name"
          track-by="name"
          select-label=""
          :hide-selected="true"
          @update:modelValue="fetchpending"
        ></multiselect> -->
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table wallet" id="safeTable">
          <thead>
            <tr>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("the amount") }}</th>
              <!-- <th>{{ $t("the date of application") }}</th> -->
              <th>{{ $t("date") }}</th>
              <th>{{ $t("Status") }}</th>
              <!-- <th>{{ $t("Receipt") }}</th> -->
              <!-- <th>{{ $t("type") }}</th> -->
              <!-- <th>{{ $t("amount transferred") }}</th> -->
              <!-- <th class="w-25">{{ $t("description") }}</th> -->
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="wallet in 10" :key="wallet">
              <td v-for="wallet in 8" :key="wallet">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(wallet, index) in wallets.data" :key="index">
              <td>
                {{ wallet.organization?.name }}
              </td>
              <td>{{ wallet.amount }}</td>

              <td>{{ wallet.created_at }}</td>

              <td>
                <span class="" v-if="wallet.status == 0">
                  {{ $t("Pending") }}
                </span>
                <span class="" v-else-if="wallet.status == 1">
                  {{ $t("Accept") }}
                </span>
                <span class="" v-else-if="wallet.status == 2">
                  {{ $t("Decline") }}
                </span>
                <span class="" v-else-if="wallet.status == 3">
                  {{ $t("Refund Pending") }}
                </span>
                <span class="" v-else-if="wallet.status == 4">
                  {{ $t("Refund Accept") }}
                </span>
                <span class="" v-else-if="wallet.status == 5">
                  {{ $t("Refund Decline") }}
                </span>
                <span class="" v-else-if="wallet.status == 6">
                  {{ $t("Payment Sent") }}
                </span>
              </td>

              <!-- <td>{{ wallet.accepted_amount }}</td> -->
              <!-- dropdown -->
              <td>
                <div class="buttons d-flex align-items-center">
                  <div
                    class="btn btn-secondary mx-1 align-items-center"
                    type="button"
                    data-bs-toggle="offcanvas"
                    :data-bs-target="`#offcanvasBottom${wallet.id}`"
                    aria-controls="offcanvasBottom"
                    :title="$t('request\'s Details')"
                  >
                    <i class="fa-solid fa-circle-exclamation"></i>
                  </div>

                  <div
                    class="buttons d-flex align-items-center"
                    v-if="wallet.status == 0"
                  >
                    <acceptwallet
                      :amount="wallet.amount"
                      :orgName="
                        wallet.to_organization
                          ? wallet.to_organization.name
                          : '-'
                      "
                      :ined="index"
                      :wallets="wallet"
                      :fetchWalet="fetchWalletsData"
                    />

                    <button
                      class="btn btn-danger text-white mx-1"
                      @click="declineRequest(wallet.id)"
                    >
                      <i class="fa-regular fa-circle-xmark"></i>
                    </button>
                  </div>
                </div>
              </td>
              <!-- toggle popup -->
              <div
                class="offcanvas offcanvas-bottom"
                tabindex="-1"
                :id="`offcanvasBottom${wallet.id}`"
                aria-labelledby="offcanvasBottomLabel"
              >
                <div class="offcanvas-header">
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body small">
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="wallet-charge">
                        <div class="detail-row">
                          <p class="details-name">{{ $t("request type") }}</p>
                          <p>
                            {{
                              wallet.payment_type == 1 ? $t("bank") : $t("safe")
                            }}
                          </p>
                        </div>
                        <div class="detail-row">
                          <p class="details-name">{{ $t("charge from") }}</p>
                          <p>
                            {{
                              wallet.from_organization == null
                                ? "-"
                                : wallet.from_organization.name
                            }}
                          </p>
                        </div>
                        <div class="detail-row">
                          <p class="details-name">
                            {{ $t("the date of application") }}
                          </p>
                          <p>{{ wallet.created_at }}</p>
                        </div>
                        <div class="detail-row">
                          <p class="details-name">
                            {{ $t("transfer from account") }}
                          </p>
                          <p>{{ wallet.from_account_id }}</p>
                        </div>
                        <div class="detail-row">
                          <p class="details-name">
                            {{ $t("transfer to account") }}
                          </p>
                          <p>{{ wallet.to_account_id }}</p>
                        </div>
                        <div class="detail-row">
                          <p class="details-name">IBAN{{ $t("Number") }}</p>
                          <p>{{ wallet.iban }}</p>
                        </div>
                        <div class="detail-row">
                          <p class="details-name">SWIFT{{ $t("Number") }}</p>
                          <p>{{ wallet.swift }}</p>
                        </div>
                        <div class="detail-row">
                          <p class="details-name">{{ $t("request status") }}</p>
                          <p>
                            <!-- <span class="status">{{ $t(wallet.status) }}</span> -->
                          </p>
                        </div>
                        <div class="detail-row">
                          <p class="details-name">{{ $t("the amount") }}</p>
                          <p>{{ wallet.amount }}</p>
                        </div>
                        <div class="detail-row">
                          <p class="details-name">
                            {{ $t("transfer number") }}
                          </p>
                          <p>{{ wallet.number_transform }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-6">
                      <div class="receipt-details">
                        <h4 class="my-4">{{ $t("the recepit") }}</h4>
                        <div class="row content" v-if="wallet.receipt">
                          <div class="col-12 col-lg-6 mb-4 image">
                            <img :src="wallet.receipt" alt="receipt" />
                          </div>
                          <div class="col-12 col-lg-6 details">
                            <a
                              :href="wallet.receipt"
                              target="_blank"
                              class="btn"
                              ><i class="fa-solid fa-download"></i>
                              {{ $t("Download") }}</a
                            >
                          </div>
                        </div>
                        <div v-else class="text-center p-1">
                          {{ $t("receipt doesn't exist") }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn cancel my-5"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <i class="fa-solid fa-upload"></i>
                    {{ $t("Cancel Request") }}
                  </button>
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="wallets"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchWalletsData"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";
import CharegeWalletOffcanves from "../../../components/wallet/CharegeWalletOffcanves.vue";
// import Multiselect from "vue-multiselect";
import Fav from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
import acceptwallet from "@/components/wallet/acceptWalletSharks.vue";
export default {
  name: "walletPage",
  data() {
    return {
      amount: "",
      wallets: [],
      userWallet: {},
      receipt: "",
      type: 1,
      stage: JSON.parse(localStorage.getItem("user")).stage,
      types: [
        { id: 0, name: "company transactions" },
        { id: 1, name: "transfer requests" },
        { id: 2, name: "shipping requests" },
      ],
      value_wallet: "",
      transfer: [
        { id: 3, name: "balance transfer" },
        { id: 4, name: "all transfers" },
        { id: 5, name: "charging the wallet" },
      ],
      value: "",
      loading: true,
    };
  },
  components: {
    pagination,
    CharegeWalletOffcanves,
    // Multiselect,
    Fav,
    Skeleton,
    acceptwallet,
  },
  methods: {
    // fetch_organization_accounts() {
    //   axios
    //     .post("/fetch_my_organization_accounts", {
    //       type: 1,
    //     })
    //     .then(({ data }) => {
    //       this.types = data.data.data;
    //     });
    // },
    fetchWalletsData(page = 1) {
      axios.post(`/sharks/get_all_wallets?page=${page}`).then(({ data }) => {
        this.wallets = data;
        this.loading = false;
        // console.log(this.wallets.data);
      });
    },
    fetchWallet() {
      axios.get("/fetch_wallet").then(({ data }) => {
        this.userWallet = data.data;
      });
    },
    acceptRequest(index) {
      Swal.fire({
        title: this.$t("check_accept"),
        text: this.$t("are_you_sure_you_want_to_accept"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_accept"),
      }).then((result) => {
        if (result.isConfirmed) {
          let wallet_id = {
            wallet_id: this.wallets.data[index].id,
            to_account_id: this.value_wallet.id,
          };
          axios.post("/accept_request", wallet_id).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
    declineRequest(id) {
      Swal.fire({
        title: this.$t("check_decline"),
        text: this.$t("are_you_sure_you_want_to_decline"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_decline"),
      }).then((result) => {
        if (result.isConfirmed) {
          let wallet_id = { wallet_id: id };
          axios
            .post("/sharks/decline_wallet_portal", wallet_id)
            .then(({ data }) => {
              this.fetchWalletsData();
              if (data.status == true) {
                Swal.fire("", data.message, "success");
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  created() {
    this.fetchWalletsData();
    this.fetchWallet();
  },
};
</script>

<!-- <style src="vue-multiselect/dist/vue-multiselect.css"></style> -->
<style lang="scss" scoped></style>
