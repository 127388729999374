<template>
  <form class="book_offline" @submit.prevent="confirm_booking()">
    <div class="">
      <div class="row">
        <div class="col-12">
          <div
            class="d-flex justify-content-between align-items-end flex-row flex-wrap mb-2"
          >
            <div class="d-flex align-items-center gap-3">
              <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
              <h3 class="title">
                {{ $t("new_book") }}
              </h3>
            </div>
            <div class="form-group d-flex align-items-center w-25 mb-1">
              <label
                for="reservation_number"
                style="font-size: 0.8rem; margin-bottom: 0"
                >{{ $t("reservation_number") }}</label
              >
              <input
                type="number"
                name=""
                id="reservation_number"
                class="form-control w-75 mx-2"
                v-model="reservation_number"
                disabled
              />
            </div>
            <div class="buttons mb-2">
              <button type="submit" class="btn PrimaryButton nadf">
                {{ $t("save") }}
              </button>
              <button @click="newBook()" class="btn SecondButton nadf">
                {{ $t("New Book") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-12 col-12">
          <div class="new_book">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <div class="guest_label">
                    <label class="guest_label-1" for="">
                      <img
                        class="icon-s"
                        src="@/assets/media/image/icon/clint-1.svg"
                        alt=""
                      />
                      {{ $t("client") }}
                      <!-- <router-link :to="{ name: 'addClients' , params:{page:1} }"
                      class="btn"
                    >
                      <span><i class="fa-solid fa-circle-plus"></i></span>
                    </router-link> -->
                    </label>
                    <a :href="`/clients/add/${1}`" class="btn" target="_blank"
                      ><span><i class="fa-solid fa-circle-plus"></i></span
                    ></a>
                  </div>
                  <Multiselect
                    v-model="client_id"
                    :options="clients"
                    label="name"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label class="guest_label" for="">
                    <div class="guest_label-1">
                      <img
                        class="icon-s"
                        src="@/assets/media/image/icon/user-profile-check.svg"
                        alt=""
                      />
                      {{ $t("guest_name") }}
                    </div>
                    <button
                      class="btn btn-primary canvas"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottomGuest"
                      aria-controls="offcanvasBottomGuest"
                    >
                      <span><i class="fa-solid fa-circle-plus"></i></span>
                    </button>
                  </label>

                  <div class="name-form-group">
                    <Multiselect
                      v-model="genderValue"
                      :options="genders.map((gender) => gender.id)"
                      :custom-label="
                        (opt) => genders.find((x) => x.id == opt).title
                      "
                      :clear-on-select="true"
                      :placeholder="$t('gender')"
                      select-label=""
                      :hide-selected="true"
                    >
                    </Multiselect>
                    <input
                      type="text"
                      class="form-control"
                      v-model="guest_name"
                      :placeholder="$t('full_name')"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label class="guest_label" for="">
                    <div class="ff">
                      <img
                        class="icon-s"
                        src="@/assets/media/image/icon/fffff.svg"
                        alt=""
                      />
                      {{ $t("suppliers") }}
                    </div>
                    <!-- <router-link :to="{ name: 'addSuppliers' , params:{page:1} }"
                      class="btn"
                    >
                      <span><i class="fa-solid fa-circle-plus"></i></span>
                    </router-link> -->
                    <a :href="`/suppliers/add/${1}`" class="btn" target="_blank"
                      ><span><i class="fa-solid fa-circle-plus"></i></span
                    ></a>
                  </label>
                  <Multiselect
                    v-model="supplierValue"
                    :options="suppliers.map((supplier) => supplier.id)"
                    :custom-label="
                      (opt) => suppliers.find((x) => x.id == opt).name
                    "
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <img
                    class="icon-s"
                    src="@/assets/media/image/icon/date.svg"
                    alt=""
                  />
                  <label for="">{{ $t("Date of entry") }}</label>
                  <Datepicker
                    v-model="fromDate"
                    lang="ar"
                    @change="updateToDateFrom"
                    :disabled-start-date="{ to: new Date() }"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label for="number_of_nights">
                    <img
                      class="icon-s"
                      src="@/assets/media/image/icon/cont.svg"
                      alt=""
                    />
                    {{ $t("number of nights") }}</label
                  >
                  <input
                    type="number"
                    name=""
                    id="number_of_nights"
                    class="form-control"
                    v-model="number_of_nights"
                    @input="updateToDateFrom"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-12">
                <div class="form-group">
                  <label for="">
                    <img
                      class="icon-s"
                      src="@/assets/media/image/icon/go.svg"
                      alt=""
                    />
                    {{ $t("Departure Date") }}</label
                  >
                  <Datepicker
                    v-model="toDate"
                    lang="ar"
                    @change="updateFromDateTo"
                    :disabled-start-date="{
                      to: fromDate == null ? new Date() : fromDate,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="row details_reservation">
            <!-- <div class="col-md-4 col-12">
              <div class="form-group">
                <label for="reference number">{{
                  $t("reference number")
                }}</label>
                <input
                  type="number"
                  name=""
                  id="reference number"
                  class="form-control"
                  v-model="reference_number"
                />
              </div>
            </div> -->
            <div class="col-md-4 col-12">
              <div class="form-group nad">
                <label for="">
                  <img
                    class="go"
                    src="@/assets/media/image/icon/stut.svg"
                    alt=""
                  />
                  {{ $t("reservation_status") }}</label
                >
                <Multiselect
                  v-model="statusValue"
                  :options="statuses.map((status) => status.id)"
                  :custom-label="
                    (opt) => statuses.find((x) => x.id == opt).title
                  "
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="form-group" v-if="statusValue == 0">
                <label for="">option date</label>
                <VueDatePicker
                  v-model="option_date"
                  locale="en-US"
                  time-picker-inline
                />
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="form-group" v-if="statusValue == 0">
                <label for="">supplier option date</label>
                <VueDatePicker
                  v-model="supplier_option_date"
                  locale="en-US"
                  time-picker-inline
                />
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group nad">
                <label for="">
                  <img
                    class="total"
                    src="@/assets/media/image/icon/total.svg"
                    alt=""
                  />
                  {{ $t("total cost of the rooms") }}</label
                >
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="totalCost"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group nad">
                <label for="">
                  <img
                    class="total"
                    src="@/assets/media/image/icon/cost.svg"
                    alt=""
                  />
                  {{ $t("cost of the rooms is taxed") }}</label
                >
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="costAfterTax"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group nad">
                <label for="">
                  <img
                    class="total"
                    src="@/assets/media/image/icon/dispoui.svg"
                    alt=""
                  />
                  {{ $t("tax value") }}</label
                >
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="taxCost"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group nad">
                <label for="">
                  <img
                    class="total"
                    src="@/assets/media/image/icon/tootal.svg"
                    alt=""
                  />
                  {{ $t("total selling price of the rooms") }}</label
                >
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="totalSelling"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group nad">
                <label for="">
                  <img
                    class="total"
                    src="@/assets/media/image/icon/prise.svg"
                    alt=""
                  />
                  {{ $t("sales price with tax") }}</label
                >
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="sellingAfterTax"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group nad">
                <label for="">
                  <img
                    class="total"
                    src="@/assets/media/image/icon/dispoui.svg"
                    alt=""
                  />
                  {{ $t("tax value") }}</label
                >
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="taxSelling"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="new_book">
            <div class="row">
              <div class="col-lg-2 col-md-6 col-12 d-flex align-items-center">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="check_person_responsible"
                    v-model="hotel_contract"
                    @change="resetRooms"
                  />
                  <label
                    class="form-check-label"
                    for="check_person_responsible"
                    >{{ $t("Select the hotel from the contracting") }}</label
                  >
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                <div class="selects">
                  <div class="form-group d-flex align-items-center">
                    <label for="hotles">{{ $t("hotels") }}</label>
                    <input
                      type="search"
                      class="form-control"
                      :placeholder="$t('Hotel')"
                      v-model="searchValue"
                      @keyup="searchHotels"
                    />
                    <div
                      class="resultSearch"
                      v-if="searchValue.length > 2 && hotelsSearch.length"
                    >
                      <!-- hotelValue.length > 2 &&  -->
                      <!-- <h6 class="head_search">{{$t("hotels")}}</h6> -->
                      <ul class="hotels">
                        <li
                          v-for="(hotel, index) in hotelsSearch"
                          :key="index"
                          @click="
                            sendHotelName(hotel.title, hotel.id, hotel.city_id)
                          "
                        >
                          <img
                            :src="hotel.image"
                            class="hotel_image"
                            :alt="hotel.title"
                          />
                          <div class="texts">
                            <p class="title">{{ hotel.title }}</p>
                            <p class="country">
                              {{ hotel.country_title }} , {{ hotel.city_title }}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6 col-12"
                v-if="hotel_contract == true"
              >
                <div class="form-group d-flex align-items-center">
                  <label for="choose_contracts">{{
                    $t("choose_from_contracts")
                  }}</label>
                  <Multiselect
                    v-model="contractValue"
                    :options="contracts.map((contract) => contract.id)"
                    :custom-label="
                      (opt) => contracts.find((x) => x.id == opt).name
                    "
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    @update:model-value="get_rate_codes"
                  >
                  </Multiselect>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-6 col-12"
                v-if="hotel_contract == true"
              >
                <div class="form-group d-flex align-items-center">
                  <label for="rate_code" class="rate_code">Rate Code</label>
                  <Multiselect
                    v-model="rateValue"
                    :options="rateCode.map((code) => code.id)"
                    :custom-label="
                      (opt) => rateCode.find((x) => x.id == opt)?.name
                    "
                    :clear-on-select="true"
                    :placeholder="$t('choose')"
                    select-label=""
                    :hide-selected="true"
                    @update:model-value="fetch_contract_rooms"
                  >
                  </Multiselect>
                  <!-- <span v-if="rooms.length" style="font-size: 0.8rem">{{rooms[0].hotel.title}}</span> -->
                </div>
              </div>
              <div class="col-12">
                <div class="available_rooms">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <h4 class="title">{{ $t("available_rooms") }}</h4>
                    <div class="tax_details">
                      <form class="d-flex align-items-center">
                        <div class="form-group d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="cost_tax"
                            name="cost-tax"
                            class="mx-3"
                            v-model="costApply"
                          />
                          <label for="cost_tax" class="tax-label">{{
                            $t("cost with tax")
                          }}</label>
                        </div>
                        <div class="form-group d-flex align-items-center">
                          <input
                            type="checkbox"
                            id="selling_tax"
                            name="selling_tax"
                            class="mx-3"
                            v-model="sellingApply"
                          />
                          <label for="selling_tax" class="tax-label">{{
                            $t("selling price with tax")
                          }}</label>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="not_available" v-if="!rooms.length">
                    <img
                      src="@/assets/media/image/available_rooms.png"
                      alt="available_rooms"
                    />
                    <h5 class="text">
                      {{
                        $t("Enter the required data to get the available rooms")
                      }}
                    </h5>
                  </div>
                  <div
                    class="rooms"
                    v-else
                    v-for="(room, index) in rooms"
                    :key="index"
                  >
                    <div class="room_details">
                      <div class="details">
                        <p class="title">{{ room.title }}</p>
                        <ul class="services">
                          <li>
                            <i class="fa-solid fa-bed"></i>
                            {{ room.number_of_beds }}
                          </li>
                        </ul>
                        <div class="form-group">
                          <label for="supplier">{{ $t("suppliers") }}</label>
                          <Multiselect
                            v-model="room.supplier_id"
                            :options="suppliers.map((supplier) => supplier.id)"
                            :custom-label="
                              (opt) => suppliers.find((x) => x.id == opt).name
                            "
                            :clear-on-select="true"
                            :placeholder="$t('choose')"
                            select-label=""
                            :hide-selected="true"
                          >
                          </Multiselect>
                        </div>
                        <div class="form-group">
                          <label for="supplier_code">{{
                            $t("supplier_code")
                          }}</label>
                          <input
                            type="number"
                            name=""
                            id="supplier_code"
                            class="form-control"
                            v-model="room.supplier_phone"
                          />
                        </div>
                        <div class="form-group">
                          <label for="hotel_code">{{ $t("hotel_code") }}</label>
                          <input
                            type="number"
                            name=""
                            id="hotel_code"
                            class="form-control"
                            v-model="room.hotel_phone"
                          />
                        </div>
                        <div class="d-flex align-items-center gap-3">
                          <!-- <select v-model="room.selectedMeal.meal_type_id">
                                <option v-for="(meal , index) in room.meals" :key="index" :value="meal.meal_type_id">{{meal.title}}</option>
                              </select> -->
                          <div class="form-group">
                            <label>{{ $t("meals") }}</label>
                            <Multiselect
                              v-model="room.selectedMeal.meal_type_id"
                              :options="
                                room.meals.map((meal) => meal.meal_type_id)
                              "
                              :custom-label="
                                (opt) =>
                                  room.meals.find((x) => x.meal_type_id == opt)
                                    .title
                              "
                              :clear-on-select="true"
                              :placeholder="$t('choose meal')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                          </div>
                          <div class="d-flex align-items-center gap-3">
                            <div class="form-group">
                              <label for="meal_cost" class="mb-2">{{
                                $t("cost")
                              }}</label>
                              <input
                                type="number"
                                id="meal_cost"
                                placeholder="0"
                                class="form-control"
                                v-model="room.selectedMeal.meal_cost"
                              />
                            </div>
                            <div class="form-group">
                              <label for="meal_selling" class="mb-2">{{
                                $t("selling price")
                              }}</label>
                              <input
                                type="number"
                                id="meal_selling"
                                placeholder="0"
                                class="form-control"
                                v-model="room.selectedMeal.meal_selling"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="price_quantity">
                        <p class="price" v-if="hotel_contract == true">
                          {{ room.price }}
                        </p>
                        <div class="quantity">
                          <button
                            class="btn minus"
                            type="button"
                            @click="minusQuantity(index)"
                          >
                            <i class="fa-solid fa-minus"></i>
                          </button>
                          <span class="number">{{ room.quantity }}</span>
                          <button
                            class="btn add"
                            type="button"
                            @click="addQuantity(index)"
                          >
                            <i class="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="details_price" v-if="room.quantity >= 1">
                      <div class="row">
                        <div class="col-lg-12 col-md-12">
                          <!-- <div class="row mb-2">
                            <div class="col-md-4 col-12">
                              <div class="form-group">
                                <label for="supplier_code">{{
                                  $t("supplier_code")
                                }}</label>
                                <input
                                  type="number"
                                  name=""
                                  id="supplier_code"
                                  class="form-control"
                                  v-model="room.supplier_phone"
                                />
                              </div>
                            </div>
                            <div class="col-md-4 col-12">
                              <div class="form-group">
                                <label for="hotel_code">{{
                                  $t("hotel_code")
                                }}</label>
                                <input
                                  type="number"
                                  name=""
                                  id="hotel_code"
                                  class="form-control"
                                  v-model="room.hotel_phone"
                                />
                              </div>
                            </div>
                            <div class="col-md-4 col-12">
                              <div class="form-group">
                                <label for="supplier">{{
                                  $t("suppliers")
                                }}</label>
                                <Multiselect
                                  v-model="room.supplier_id"
                                  :options="suppliers.map((supplier) => supplier.id)"
                                  :custom-label="
                                    (opt) => suppliers.find((x) => x.id == opt).name
                                  "
                                  :clear-on-select="true"
                                  :placeholder="$t('choose')"
                                  select-label=""
                                  :hide-selected="true"
                                >
                                </Multiselect>
                              </div>
                            </div>
                          </div> -->
                          <div class="row">
                            <div class="col-lg-4 col-md-5 col-12">
                              <div class="form-group">
                                <label :for="`cost${room.id}`">{{
                                  $t("cost")
                                }}</label>
                                <input
                                  type="number"
                                  class="form-control"
                                  v-model="room.cost"
                                  :id="`cost${room.id}`"
                                  :disabled="hotel_contract == true"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-5 col-12">
                              <div class="form-group">
                                <label :for="`selling${room.id}`">{{
                                  $t("selling price")
                                }}</label>
                                <input
                                  type="number"
                                  name=""
                                  :id="`selling${room.id}`"
                                  class="form-control"
                                  placeholder="0"
                                  v-model="room.selling_price"
                                />
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-2 col-12">
                              <button
                                type="button"
                                class="btn PrimaryButton w-100"
                                @click="save_changes(index)"
                              >
                                {{ $t("save_changes") }}
                              </button>
                            </div>
                          </div>
                          <div class="details_room">
                            <div class="table-responsive">
                              <table class="table table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">{{ $t("date") }}</th>
                                    <th
                                      scope="col"
                                      v-for="(day, item) in room.selectedDays"
                                      :key="item"
                                    >
                                      <span>{{ formatDate(day.date) }}</span
                                      ><span>{{ day.dayOfWeek }}</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">{{ $t("cost") }}</th>
                                    <td
                                      v-for="(day, item) in room.selectedDays"
                                      :key="item"
                                    >
                                      <input
                                        type="number"
                                        class="form-control"
                                        v-model="room.selectedDays[item].cost"
                                        :disabled="hotel_contract == true"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      {{ $t("selling price") }}
                                    </th>
                                    <td
                                      v-for="(day, item) in room.selectedDays"
                                      :key="item"
                                    >
                                      <input
                                        type="number"
                                        class="form-control"
                                        v-model="
                                          room.selectedDays[item].selling_price
                                        "
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-lg-4 col-md-12">
                          <div class="">
                            <h4 class="title">{{ $t("meals") }}</h4>
                            <div class="d-flex align-items-center ">
                              <select v-model="room.selectedMeal.meal_type_id">
                                <option v-for="(meal , index) in room.meals" :key="index" :value="meal.meal_type_id">{{meal.title}}</option>
                              </select>
                              <div class="d-flex align-items-center gap-3">
                                <div class="form-group">
                                  <label for="meal_cost" class="mb-2">{{ $t("cost") }}</label>
                                  <input
                                    type="number"
                                    id="meal_cost"
                                    placeholder="0"
                                    class="form-control"
                                    v-model="room.selectedMeal.meal_cost"
                                    
                                  />
                                </div>
                                <div class="form-group">
                                  <label for="meal_selling" class="mb-2">{{
                                    $t("selling price")
                                  }}</label>
                                  <input
                                    type="number"
                                    id="meal_selling"
                                    placeholder="0"
                                    class="form-control"
                                    
                                    v-model="room.selectedMeal.meal_selling"
                                  />
                                </div>
                              </div>
                            </div>                      
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <roomingTabs :rooming_list="rooming_list" @getServices="getServices" />
      </div>
    </div>
  </form>
  <!-- <addClient /> -->

  <!-- Add guest details -->
  <div
    class="offcanvas offcanvas-bottom"
    tabindex="-1"
    id="offcanvasBottomGuest"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasBottomLabel">
        {{ $t("add_details_guest") }}
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body small">
      <div class="row">
        <div class="col-lg-6 my-3">
          <label for="name">{{ $t("name") }}</label>
          <input type="text" id="name" v-model="guest_name" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 my-3">
          <label for="email">{{ $t("email") }}</label>
          <input type="email" id="email" v-model="guest_email" />
        </div>
        <div class="col-lg-3 my-3">
          <label for="phone">{{ $t("phone_number") }}</label>
          <input type="number" id="phone" v-model="guest_phone" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 my-3">
          <label for="country">{{ $t("choose_nationality") }}</label>
          <Multiselect
            v-model="guest_nationality_id"
            :options="nationalities"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_nationality')"
            select-label=""
            :hide-selected="true"
            @keyup="fetch_nationalities"
          >
          </Multiselect>
        </div>
        <div class="col-lg-3 my-3">
          <label for="address">{{ $t("Nationality ID") }}</label>
          <input type="text" id="address" v-model="guest_National_ID" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import Swal from "sweetalert2";
import axios from "axios";
// import addClient from "../../../components/offline/addClient.vue";
import moment from "moment";
import roomingTabs from "@/components/offline/rooming_tabs.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "book-offline",
  data() {
    return {
      hotel_contract: false,
      contracts: [],
      contractValue: null,
      rateCode: [],
      rateValue: "",
      nationalities: [],
      country_id: "",
      clients: [],
      client_id: "",
      suppliers: [],
      supplierValue: "",
      guest_name: "",
      guest_email: "",
      guest_phone: "",
      guest_nationality_id: "",
      guest_National_ID: "",

      date: null,
      genders: [
        {
          id: 1,
          title: this.$t("mr"),
        },
        {
          id: 2,
          title: this.$t("ms"),
        },
      ],
      genderValue: "",
      // reservations: [],
      // reservationValue: "",
      fromDate: new Date(),
      toDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      rooms: [
        // {
        //   hotel: {}
        // }
      ],
      meals: [],
      hotels: [],
      statusValue: 1,
      statuses: [
        {
          id: 0,
          title: this.$t("initial"),
        },
        {
          id: 1,
          title: this.$t("pending"),
        },
        {
          id: 2,
          title: this.$t("completed"),
        },
        {
          id: 3,
          title: this.$t("edited"),
        },
        {
          id: 4,
          title: this.$t("canceled"),
        },
      ],
      totalCost: 0,
      totalSelling: 0,
      // tax_cost: 0,
      // tax_selling: 0,
      tax: 0,
      costAfterTax: 0,
      taxCost: 0,
      costApply: true,
      sellingAfterTax: 0,
      taxSelling: 0,
      sellingApply: true,
      reservation_number: "",
      // reference_number: "",
      hotel_phone: "",
      supplier_phone: "",
      number_of_nights: 1,
      selectedRooms: [],

      // providers: [],
      option_date: null,
      searchValue: "",
      hotelsSearch: [],
      citiesSearch: [],
      hotelValue: "",
      // services_types:[],
      rooming_list: [],
      services: [],
    };
  },
  components: {
    Multiselect,
    Datepicker: VueDatepickerUi,
    // addClient,
    VueDatePicker,
    roomingTabs,
    AddFavDailog,
  },
  computed: {
    selectedDays() {
      const selectedDays = [];
      if (this.fromDate && this.toDate && this.number_of_nights > 0) {
        const fromDate = new Date(this.fromDate);
        for (let i = 0; i < this.number_of_nights; i++) {
          const currentDate = new Date(fromDate);
          currentDate.setDate(fromDate.getDate() + i);
          selectedDays.push({
            date: currentDate.toISOString().split("T")[0],
            dayOfWeek: this.getDayOfWeek(currentDate.getDay()),
          });
        }
      }
      return selectedDays;
    },
  },
  methods: {
    newBook() {
      location.reload();
    },
    // fetch_services(){
    //   axios.get("/additional_services").then(({data})=>{
    //     this.services_types = data.data
    //   })
    // },
    fetch_nationalities(event) {
      const searchText = { title: event.target.value };
      axios.post("/fetch_nationalities", searchText).then(({ data }) => {
        this.nationalities = data.data.data;
      });
    },
    getDayOfWeek(dayIndex) {
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[dayIndex];
    },
    minusQuantity(index) {
      if (this.rooms[index].quantity > 0) {
        this.rooms[index].quantity -= 1;
      }
      if (this.rooms[index].quantity == 0) {
        this.rooms[index].selected = false;

        let list_index;
        this.rooming_list.forEach((list) => {
          if (list.room_id == this.rooms[index].id) {
            list_index = this.rooming_list.indexOf(list);
          }
        });
        this.rooming_list.splice(list_index, 1);
      }
    },
    addQuantity(index) {
      console.log(this.rooms[index].maximum);
      if (this.rooms[index].maximum == -1) {
        this.rooms[index].quantity += 1;
      } else if (this.rooms[index].maximum > this.rooms[index].quantity) {
        this.rooms[index].quantity += 1;
        this.rooming_list.push({
          guest_name: "",
          conformation_number: "",
          notes: "",
          room_id: this.rooms[index].id,
        });
      }
      if (this.hotel_contract == false) {
        this.rooming_list.push({
          guest_name: "",
          conformation_number: "",
          notes: "",
          room_id: this.rooms[index].id,
        });
      }
      this.rooms[index].selected = true;
    },
    async fetchRooms(hotelValue) {
      let hotel_id = { hotel_id: hotelValue };
      await axios
        .post("/get_customization_rooms", hotel_id)
        .then(({ data }) => {
          this.rooms = data.data.rooms.map((room) => {
            return {
              ...room,
              quantity: 0,
              selected: false,
              supplier_phone: "",
              hotel_phone: "",
              supplier_id: "",
              selectedMeal: {
                meal_cost: "",
                meal_selling: "",
                meal_type_id: "",
              },
              meals: this.meals.map((meal) => {
                return {
                  ...meal,
                  checked: false,
                };
              }),
              selectedDays: this.selectedDays.map((day) => {
                return {
                  ...day,
                  cost: 0,
                  selling_price: 0,
                };
              }),
            };
          });
        });
      // this.fetchMeals();
    },
    fetchClients() {
      axios.post("/fetch_consumer_by_role", { role: 1 }).then(({ data }) => {
        // console.log(data.data);
        this.clients = data.data.data;
      });
    },
    formatDate(value) {
      return moment(value).format("DD- MM");
    },
    //Search Hotels
    //Search Hotels
    searchHotels(event) {
      var formData = {};
      formData["word"] = event.target.value;
      formData["from_date"] = moment(this.fromDate).format("YYYY-MM-DD");
      formData["to_date"] = moment(this.toDate).format("YYYY-MM-DD");
      axios
        .post(`/fetch_contract_hotel_home`, formData)
        .then(({ data }) => {
          this.hotelsSearch = data.data.hotel;
          this.citiesSearch = data.data.contract;
        })
        .catch((err) => console.log(err));
    },

    // fetch hotels
    sendHotelName(hotelName, hotelID, cityId) {
      // this.hotel_contract = false;
      this.hotelID = hotelID;
      this.hotelName = hotelName;
      this.searchValue = hotelName;
      this.city_id = cityId;
      this.hotelsSearch = [];
      this.citiesSearch = [];

      if (this.hotel_contract == true) {
        this.fetchContractHotel();
      } else {
        this.fetchRooms(this.hotelID);
      }
    },
    fetchContractHotel() {
      let filter = {
        hotel_id: this.hotelID,
        from_date: moment(this.fromDate).format("YYYY-MM-DD"),
        to_date: moment(this.toDate).format("YYYY-MM-DD"),
      };
      axios.post("/fetch_contract_by_hotel_id", filter).then(({ data }) => {
        this.contracts = data.data;
      });
    },
    sendContractId(contractName, contractId, hotel_id) {
      this.hotel_contract = true;
      this.contractValue = contractId;
      this.contractName = contractName;
      this.searchValue = contractName;
      this.hotelID = hotel_id;
      this.hotelsSearch = [];
      this.citiesSearch = [];
      // console.log(contractId);
      this.get_rate_codes(contractId);
    },

    updateToDateFrom() {
      if (this.fromDate) {
        const nights = parseInt(this.number_of_nights, 10) || 1;
        // console.log(nights)
        const fromDate = new Date(this.fromDate);
        const toDate = new Date(
          fromDate.getTime() + nights * 24 * 60 * 60 * 1000
        );
        this.toDate = toDate;
      }
    },
    updateFromDateTo() {
      if (this.toDate) {
        const toDate = new Date(this.toDate);
        const fromDate = new Date(
          toDate.getTime() - this.number_of_nights * 24 * 60 * 60 * 1000
        );
        this.fromDate = fromDate;
      }
    },
    fetchMeals() {
      axios.post("/fetch_meal_types").then(({ data }) => {
        this.meals = data.data.data;
        // console.log(this.meals);
      });
    },
    get_rate_codes(contract_id) {
      var formData = {};
      formData["contract_id"] = contract_id;
      formData["start_date"] = moment(this.fromDate).format("YYYY-MM-DD");
      formData["end_date"] = moment(this.toDate).format("YYYY-MM-DD");
      axios
        .post(`/get_rate_code_from_contract_id_with_date`, formData)
        .then(({ data }) => {
          this.rateCode = data.data;
          this.rooms = [];
          this.meals = [];
        });
    },
    async fetch_contract_rooms(rateValue) {
      let filter = {
        rate_code: rateValue,
        start_date: moment(this.fromDate).format("YYYY-MM-DD"),
        end_date: moment(this.toDate).format("YYYY-MM-DD"),
      };
      await axios
        .post("/get_customization_rooms", filter)
        .then(({ data }) => {
          console.log(data.data);
          this.meals = data.data.meals;
          this.rooms = data.data.rooms.map((room) => {
            return {
              ...room,
              quantity: 0,
              selected: false,
              supplier_phone: "",
              hotel_phone: "",
              supplier_id: "",
              selectedMeal: {
                meal_cost: "",
                meal_selling: "",
                meal_type_id: "",
              },
              meals: this.meals.map((meal) => {
                return {
                  ...meal,
                  checked: false,
                };
              }),
              selectedDays: this.selectedRateCodeDays(room.days),
            };
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    selectedRateCodeDays(days) {
      let main_days = [];
      let total = [];
      // console.log(this.selectedDays);
      // console.log(days)
      this.selectedDays.forEach((selectedDay) => {
        main_days = days
          .filter((day) => {
            return day.date == selectedDay.date;
          })
          .map((myDay) => {
            return {
              ...myDay,
              selling_price: myDay.b2b_price,
            };
          });
        // console.log(main_days)
        total.push(...main_days);
      });
      // console.log(total)
      return total;
    },
    resetRooms() {
      this.rooms = [];
      this.hotelValue = "";
      this.country_id = "";
      this.contractValue = "";
      this.rateValue = "";
      if (this.hotelID) {
        this.fetchContractHotel();
      }
    },
    // fetch suppliers data
    FetchSuppliers() {
      axios.post("/fetch_consumer_by_role", { role: 2 }).then(({ data }) => {
        this.suppliers = data.data.data;
        // this.providers = data.data;
      });
    },
    confirm_booking() {
      this.sendedData();
      var formData = {};

      if (this.option_date) {
        formData["option_date"] = moment(this.option_date).format(
          "YYYY-MM-DD h:mm:ss"
        );
      }
      if (this.supplier_option_date) {
        formData["supplier_option_date"] = moment(
          this.supplier_option_date
        ).format("YYYY-MM-DD h:mm:ss");
      }
      if (this.services[0].supplier_id) {
        formData["services"] = this.services.map((service) => {
          return {
            supplier_id: service.supplier_id.id,
            additional_service_id: service.service_type.id,
            count: service.service_count,
            cost: service.Service_cost,
            total_cost: service.service_total_cost,
            total_selling_price: service.service_total_selling,
            selling_price: service.Service_selling,
            notes: service.service_notes,
          };
        });
      }
      formData["rooming_list"] = this.rooming_list;

      formData["guest_name"] = this.guest_name;
      formData["guest_email"] = this.guest_email;
      formData["guest_phone"] = this.guest_phone;
      formData["guest_nationality_id"] = this.guest_nationality_id.id;
      formData["guest_National_ID"] = this.guest_National_ID;
      formData["client_id"] = this.client_id.id;
      formData["supplier_id"] = this.supplierValue;
      formData["checkin"] = moment(this.fromDate).format("YYYY-MM-DD");
      formData["checkout"] = moment(this.toDate).format("YYYY-MM-DD");
      formData["number_of_nights"] = this.number_of_nights;
      if (this.hotel_contract == true) {
        formData["contract_id"] = this.contractValue;
        formData["rate_code_id"] = this.rateValue;
      } else {
        formData["hotel_id"] = this.hotelID;
      }
      formData["status"] = this.statusValue;
      formData["booking_number"] = this.reservation_number;
      formData["hotel_phone"] = this.hotel_phone;
      formData["supplier_phone"] = this.supplier_phone;
      // formData["reference_number"] = this.reference_number;
      formData["total_cost"] = this.totalCost;
      formData["total_cost_with_tax"] = this.costAfterTax;
      formData["cost_tax"] = this.tax;
      formData["total_selling_price"] = this.totalSelling;
      formData["total_selling_price_with_tax"] = this.totalSelling;
      formData["total_selling_price"] = parseInt(this.sellingAfterTax);
      formData["selling_price_tax"] = this.tax;
      formData["is_tax_cost"] = this.costApply;
      formData["is_tax_selling_price"] = this.sellingApply;
      formData["is_contracting"] = this.hotel_contract == false ? 0 : 1;
      formData["country_id"] = this.country_id;
      formData["rooms"] = this.selectedRooms;

      axios
        .post("/book_offline_rooms", formData)
        .then(({ data }) => {
          console.log(data);
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/book_table");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    sendedData() {
      // selected rooms

      this.selectedRooms = this.rooms
        .filter((room) => room.selected == true)
        .map((room) => {
          return {
            room_id: room.id,
            count: room.quantity,
            supplier_num: room.supplier_phone,
            hotel_num: room.hotel_phone,
            supplier_id: room.supplier_id,
            days: room.selectedDays.map((day) => {
              return {
                date: day.date,
                cost: day.cost,
                selling_price: day.selling_price,
              };
            }),
            meals: [room.selectedMeal],
          };
        });
    },
    save_changes(index) {
      this.rooms[index].selectedDays.forEach((day) => {
        if (this.hotel_contract == false) {
          day.cost = this.rooms[index].cost;
        }
        day.selling_price = this.rooms[index].selling_price;
      });
    },
    addService() {
      this.services.push({
        supplier_id: "",
        service_type: "",
        service_count: 0,
        Service_cost: 0,
        Service_selling: 0,
        service_total_cost: 0,
        service_total_selling: 0,
        service_notes: "",
      });
    },
    removeService(index) {
      this.services.splice(index, 1);
    },
    // Service_cost(index) {
    //   this.services[index].service_total_cost =
    //     this.services[index].Service_cost * this.services[index].service_count;
    // },
    // Service_selling(index) {
    //   this.services[index].service_total_selling =
    //     this.services[index].Service_selling *
    //     this.services[index].service_count;
    // },
    fetchTax() {
      axios.get("/fetch_organization_tax").then(({ data }) => {
        this.tax = data.data.tax;
      });
    },
    calc_total_cost() {
      let day_cost = 0;
      let totalMeals = 0;
      let service_cost = 0;
      let number_of_adults = 0;
      let room_quantity;
      let number_of_days = 0; // Add this variable for number of days
      this.rooms.forEach((room) => {
        if (room.selected == true) {
          number_of_adults = parseInt(room.number_of_adults);
          room_quantity = parseInt(room.quantity);
          number_of_days = room.selectedDays.length;
          room.selectedDays.forEach((day) => {
            day_cost = day_cost + day.cost * number_of_adults * room_quantity;
          });
          totalMeals =
            totalMeals +
            room.selectedMeal.meal_cost *
              number_of_adults *
              room_quantity *
              number_of_days;
        }
      });
      this.services.forEach((service) => {
        service_cost =
          service_cost +
          service.service_total_cost *
            number_of_days *
            number_of_adults *
            room_quantity;
      });

      if (this.costApply == true) {
        this.costAfterTax = day_cost + totalMeals + service_cost;
        this.taxCost =
          this.costAfterTax -
          Math.round(
            (day_cost + totalMeals + service_cost) / (1 + this.tax / 100)
          );
        return Math.round(
          (day_cost + totalMeals + service_cost) / (1 + this.tax / 100)
        );
      } else {
        this.costAfterTax = Math.round(
          (day_cost + totalMeals + service_cost) * (1 + this.tax / 100)
        );
        this.taxCost =
          this.costAfterTax - (day_cost + totalMeals + service_cost);
        return day_cost + totalMeals + service_cost;
      }
    },
    calc_total_selling() {
      let day_selling = 0;
      let totalMeals = 0;
      let service_selling = 0;
      let number_of_adults = 0;
      let room_quantity;
      let number_of_days = 0; // Add this variable for number of days
      this.rooms.forEach((room) => {
        if (room.selected == true) {
          number_of_adults = parseInt(room.number_of_adults);
          room_quantity = parseInt(room.quantity);
          number_of_days = room.selectedDays.length;
          room.selectedDays.forEach((day) => {
            day_selling =
              day_selling +
              day.selling_price * number_of_adults * room_quantity;
          });
          totalMeals =
            totalMeals +
            room.selectedMeal.meal_selling *
              number_of_adults *
              room_quantity *
              number_of_days;
        }
      });

      this.services.forEach((service) => {
        service_selling =
          service_selling +
          service.service_total_selling *
            number_of_days *
            number_of_adults *
            room_quantity;
      });
      if (this.sellingApply == true) {
        this.sellingAfterTax = day_selling + totalMeals + service_selling;
        this.taxSelling =
          this.sellingAfterTax -
          Math.round(
            (day_selling + totalMeals + service_selling) / (1 + this.tax / 100)
          );
        return Math.round(
          (day_selling + totalMeals + service_selling) / (1 + this.tax / 100)
        );
      } else {
        this.sellingAfterTax = Math.round(
          (day_selling + totalMeals + service_selling) * (1 + this.tax / 100)
        );
        this.taxSelling =
          this.sellingAfterTax - (day_selling + totalMeals + service_selling);
        return day_selling + totalMeals + service_selling;
      }
    },
    getServices(my_services) {
      this.services = my_services;
      this.calc_total_cost();
      this.calc_total_selling();
    },
  },
  mounted() {
    this.fetchClients();
    this.fetchTax();
    this.FetchSuppliers();
    // this.fetch_services();
    this.fetchMeals();
  },
  updated() {
    this.totalCost = this.calc_total_cost();

    this.totalSelling = this.calc_total_selling();
  },
  watch: {
    toDate() {
      if (this.toDate) {
        const toDate = new Date(this.toDate);
        const fromDate = new Date(this.fromDate);
        var timeDiff = Math.abs(toDate.getTime() - fromDate.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
        this.number_of_nights = numberOfNights;

        // edit room days
        if (this.hotel_contract == false) {
          if (this.rooms.length > 0) {
            this.rooms.forEach((room) => {
              room.selectedDays = this.selectedDays.map((day) => {
                return {
                  ...day,
                  cost: 0,
                  selling_price: 0,
                };
              });
            });
          }
        } else {
          if (this.rooms.length > 0) {
            this.rooms.forEach((room) => {
              room.selectedDays = this.selectedRateCodeDays(room.days);
            });
          }
        }
      }
    },
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_offline.scss";

.canvas {
  background-color: transparent;
  border-color: transparent;
  color: black;
}
.tax_details {
  width: 70%;
}
.tax-label {
  font-size: 0.8rem;
}
.table {
  .table-title {
    th {
      background-color: rgba(10, 144, 155, 0.09);
      color: #0b1427;
      padding: 20px;
      text-align: start;
      font-weight: 400;
      font-size: 16px;
    }
  }
  tbody {
    td {
      padding: 20px;
      .minus {
        background-color: red;
        border-radius: 50%;
        color: #fff;
        opacity: 0.6;
        transition: 0.3s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.multiselect__placeholder {
  font-size: 0.8rem;
}
.hidden {
  display: none;
}
a.btn {
  padding: 0;
}
</style>
