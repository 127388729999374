<template>
    <div class="mide-list">
        <ul>
            <li>
                <div class="w-35 d-flex align-items-center justify-content-between">
                    <h4>{{ total_selling_price_with_out_tax }}</h4>
                    <h3>إجمالي السعر بدون ضريبة</h3>
                </div>
            </li>
            <li>
                <div class="w-35 d-flex align-items-center justify-content-between">
                    <h4>{{ selling_tax }}</h4>
                    <h3>الضريبة</h3>
                </div>
            </li>
            <div class="last-li">
                <li>
                    <h4>
                        {{ total_selling_price }}
                    </h4>
                    <h3>إجمالي السعر شامل الضريبة</h3>
                </li>
            </div>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'total-price-ar',
    props: {
        total_selling_price_with_out_tax: {
            type: Number
        },
        selling_tax: {
            type: Number
        },
        total_selling_price: {
            type: Number
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../_pill.scss";
</style>