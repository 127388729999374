<template>
  <tbody class="text-center">
    <tr class="bg-white border-1 mb-2" v-for="contract in 15" :key="contract">
      <td class="bg-white my-3" v-for="contract in 6" :key="contract">
        <Skeleton height="2rem" class="my-2"></Skeleton>
      </td>
    </tr>
  </tbody>
</template>

<script>
import Skeleton from "primevue/skeleton";
export default {
  components: {
    Skeleton,
  },
};
</script>

<style></style>
