<template>
  <section class="card_style">
    <h3 class="main_header">{{$t("add_hotel_attachments")}}</h3>

    <form @submit.prevent="add_hotel_attachment()">
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label">{{$t("the_attachment_for")}} : </label>
                    <div class="d-flex align-items-center gap-3">
                        <div class="form-check">
                            <input class="form-check-input ms-1" type="radio" name="flexRadioDisabled" id="hotel" value="1" v-model="attachment_for">
                            <label class="form-check-label" for="hotel">
                                {{$t("hotels")}}
                            </label>
                        </div>

                        <div class="form-check">
                            <input class="form-check-input ms-1" type="radio" name="flexRadioDisabled" id="room" value="2" v-model="attachment_for">
                            <label class="form-check-label" for="room">
                                {{$t("rooms")}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="col-md-6 col-12" v-if="attachment_for == 1">
                <div class="form-group">
                    <label class="form-label">{{$t("attachment_type")}}</label>
                    <Multiselect
                        v-model="attachment_type_value"
                        :options="attachment_types"
                        :placeholder="$t('choose')"
                        label="title"
                        track-by="id"
                        :clear-on-select="true"
                        select-label=""
                        :hide-selected="true"
                    >
                    </Multiselect>

                    <!-- <Multiselect
                        v-model="attachment_type_value"
                        :options="attachment_types.map((ele) => ele.id)"
                        :custom-label="(opt) => attachment_types.find((x) => x.id == opt).title"
                        :clear-on-select="true"
                        :placeholder="$t('choose')"
                        select-label=""
                        class="mt-2"
                        :hide-selected="true"
                    >
                    </Multiselect> -->
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="type">
                            <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#typeOne"
                            aria-expanded="false"
                            aria-controls="typeOne"
                            >
                            {{ $t("attachment_name") }}
                            </button>
                        </h2>
                        <div
                            id="typeOne"
                            class="accordion-collapse collapse show"
                            aria-labelledby="type"
                            data-bs-parent="#accordionExample"
                        >
                            <div class="accordion-body">
                            <div
                                class="form-group"
                                v-for="(attachment, index) in attachment_name"
                                :key="index"
                            >
                                <label for="name"
                                >{{ $t("name") }} ({{ attachment.lang }})</label
                                >
                                <input
                                type="text"
                                :placeholder="$t('name')"
                                class="form-control"
                                :id="`name${index}`"
                                v-model="attachment.name"
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <input
                    type="file"
                    name=""
                    id="imageEntry"
                    class="form-control"
                    @change="onFileChange"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                />
            </div>
        </div>
        <button type="submit" class="btn PrimaryButton">{{$t("save")}}</button>
    </form>
  </section>
</template>

<script>
import axios from 'axios';
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
    name: "addHotelAttachments",
    components:{Multiselect},
    data(){
        return{
            languages: ["ar","en","id"],
            attachment_name: [],
            attachment_types: [],
            attachment_type_value: "",
            attachment_for: 1,
        }
    },
    methods:{
        fetch_amenities_data(){
            axios.get(`/amenties/${this.$route.params.id}`).then(({data})=>{
                console.log(data)
                this.attachment_name = data.data.titles.map((ele)=>{
                    return{
                        lang: ele.locale,
                        name: ele.title
                    }
                });
                // amenity_type_id
                this.attachment_for = data.data.amenity_status == "Hotel" ? 1 : 2;
                this.imageEntry = data.data.icon;
                this.attachment_type_value = data.data.amenity_type;
            })
        },
        fetch_amenities_type(){
            axios.get(`/amenity_types`).then(({data})=>{
                this.attachment_types = data.data.data;
            })
        },
        onFileChange(event) {
            this.fileData = event.target.files[0];

            const reader = new FileReader();
            reader.readAsDataURL(this.fileData);
            reader.onload = () => (this.imageEntry = reader.result);
        },
        add_hotel_attachment(){
            const formData = new FormData();

            this.attachment_name.forEach((ele)=>{
                formData.append(`title_${ele.lang}` , ele.name);
            })

            formData.append("amenity_status" , this.attachment_for);
            if(this.attachment_for == 1){
                formData.append("amenity_type_id" , this.attachment_type_value.id);
            }
        
            if (this.fileData instanceof File) {
                formData.append("icon", this.fileData);
            }

            axios.post(`/amentiys/update/${this.$route.params.id}` , formData).then(({data})=>{
                Swal.fire({
                    title: "",
                    text: data.message,
                    icon: "success",
                });
                this.$router.push("/hotel/all_hotel_attachment");
            }).catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        }
    },
    mounted(){
        this.fetch_amenities_type();
        this.fetch_amenities_data();
    }
}
</script>

<style scoped>
.main_header{
    font-size: 20px;
    font-weight: bold;
}
.form-check .form-check-input {
    float: unset;
    margin-left: unset;
}
hr{
    width: 100%;
    height: 4px;
    background-color: #E7E8EB;
    border-color: #E7E8EB;
}
</style>