<template>
  <div class="customize_profit_fee">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center w-75">
          <h5 class="title">{{$t("customize_profit_fee")}}</h5>
          <hr>
        </div>
        <button type="button" class="btn SecondButton" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">{{$t("add_new_customization")}}</button>
    </div>
    <div class="row mt-4">
      <table class="table" id="customizationHotelTable">
        <thead>
          <tr>
            <th>{{ $t("service")}}</th>
            <th>{{ $t("country") }}</th>
            <th>{{ $t("city") }}</th>
            <th>{{ $t("the_company") }}</th>
            <th>{{ $t("time_period") }}</th>
            <th>{{ $t("value") }}</th>
            <th>{{ $t("type") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(customization, index) in customizations.data" :key="index">
          <td>{{ customization.service_type == 1 ? $t("hotels") : customization.service_type == 2 ? $t("flight") : customization.service_type == 3 ? $t("transportation") : customization.service_type == 4 ? $t("private_trips") : "" }}</td>
          <td>
            <span v-for="(country, index) in customization.countries" :key="index">{{country.title}}, </span>
          </td>
          <td>
            <span v-for="(city, index) in customization.cities" :key="index">{{city.title}}, </span>
          </td>
          <td>
            <span v-for="(organization, index) in customization.organizations" :key="index">{{organization.title}}, </span>
          </td>
          <td>{{ customization.from_date }} - {{ customization.to_date }}</td>
          <td>{{ customization.percentage }}</td>
          <td>{{ customization.type_profit == 1 ? $t("precent") : customization.type_profit == 2 ? $t("fixed_number") : "" }}</td>
          <td>
          <!-- <router-link to="/questionstype2"><i class="fa-solid fa-eye btn-sm show_details" :title="$t('show')"></i></router-link> -->
          <router-link
              class="btn edit_btn"
              :title="$t('edit')"
              :to="`/custom_setup_fees/edit/${customization.id}`"
            >
              <i class="fa-regular fa-pen-to-square"></i>
          </router-link>
          <button class="btn remove_btn" type="button" :title="$t('delete')" @click="DeleteAlert(index)">
            <i class="fa-regular fa-trash-can"></i>
          </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
          :data="customizations"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="FetchCustomizations"
      >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "table-customization",
  components: {
    pagination,
  },
  data() {
    return {
      customizations: [],
    };
  },
  methods: {
    // fetch Customizations table
    FetchCustomizations(page = 1) {
      let type = {type: this.$route.params.type_id}
      axios.post(`/fetch_organization_customization_settings?page=${page}`, type).then(({ data }) => {
        this.customizations = data.data;
        //  console.log(this.empolyment)
      });
    },
    //delete Customizations
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.customizations.data[index].id;
          const fromClick = true;
          axios
            .delete("/customization_settings/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.customizations.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchCustomizations();
  },
  updated() {
    $("#customizationHotelTable").DataTable({
      bPaginate: false,
      bInfo : false,
      retrieve: true,
      sEmptyTable: false,
      // displayStart: 5,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("hotels"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("hotels"),
      },
      oLanguage: {
        sEmptyTable: " "
      }
    });
  },
};
</script>

<style lang="scss" scoped>
  @import "@/view/setup/_setup.scss";
</style>