<script setup>
import Skeleton from "primevue/skeleton";
import { onMounted, ref } from "vue";
import axios from "axios";
import Swal from "sweetalert2";

const loading = ref(true);
const campaigns = ref([]);

onMounted(() => {
  axios
    .post("/fetch_all_advertising_campaign")
    .then((res) => {
      campaigns.value = res.data.data;
      loading.value = false;
    })
    .catch((err) => {
      console.log(err);
    });
});

const DeleteItem = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  })
    .then((result) => {
      if (result.isConfirmed) {
        axios
          .post("/delete_advertising_campaign", {
            advertising_campaign_id: id,
          })
          .then((res) => {
            Swal.fire("Deleted!", res.data.message, "success");
            campaigns.value = campaigns.value.filter(
              (campaign) => campaign.id !== id,
            );
          });
      }
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: err.response.data.message,
        timer: 1500,
      });
    });
};

const hotelName = (hotels) => {
  let name = "";
  console.log(hotels);
  hotels.forEach((hotel) => {
    name += `${hotel.title}, `;
  });
  return name;
};
</script>

<template>
  <section class="card_style">
    <div class="row">
      <div class="main_header_style col-1 col-md-6">
        <h4>{{ $t("campaigns") }}</h4>
      </div>
      <div class="col-1 col-md-6 d-flex justify-content-end mb-2">
        <router-link to="/campaigns/add" class="PrimaryButton btn">
          <i class="fa-solid fa-plus"></i> {{ $t("add_new_campaign") }}
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table exchange">
          <thead>
            <tr>
              <th>{{ $t("id") }}</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("slug") }}</th>
              <th>{{ $t("hotels") }}</th>
              <th>{{ $t("actions") }}</th>

              <!-- <th></th> -->
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 5" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in campaigns" :key="index">
              <td>
                {{ item.id }}
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.slug }}</td>
              <td>
                {{
                  item.hotels
                    ? hotelName(item.hotels)
                    : item.hotels.map((item) => item.title)
                }}
              </td>
              <td>
                <router-link :to="`/edit-campaign/${item.id}`">
                  <i class="fa-solid fa-pen"></i>
                </router-link>
                <button
                  class="btn remove_btn"
                  type="button"
                  :title="$t('delete')"
                  @click="DeleteItem(item.id)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss"></style>
