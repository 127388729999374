<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>Transportation Rate Code</h4>
      <div>
        <router-link
          :to="`/contract-transport/${this.contract_id}/season-transport/${this.season_id}/rate-code/add`"
          class="PrimaryButton btn w-100"
          ><i class="fa-solid fa-plus"></i>Add new Rate Code</router-link
        >
      </div>
    </div>

    <div class="search-visa my-3">
      <form>
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search...')"
        />
      </form>
    </div>

    <div class="row">
      <table class="table" id="RateTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("season") }}</th>
            <th>{{ $t("From Date") }}</th>
            <th>{{ $t("To Date") }}</th>
            <th>{{ $t("cars") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="rate in 10" :key="rate">
            <td v-for="rate in 7" :key="rate">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr v-for="(rate, index) in allRateCode.data" :key="index">
            <td>{{ rate.id }}</td>
            <td>{{ rate.title }}</td>
            <td>{{ rate.season.title }}</td>

            <td>{{ rate.from_date }}</td>
            <td>{{ rate.to_date }}</td>
            <td>{{ rate.cars.length }}</td>
            <td>
              <router-link
                :to="`/contract-transport/${this.contract_id}/season-transport/${this.season_id}/rate-code/${rate.id}`"
                class="btn btn-success text-white mx-1"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1"
                @click="deleteRateCode(index)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="allRateCode"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchRateCodes"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";

import Skeleton from "primevue/skeleton";

export default {
  name: "allRateCode",
  components: {
    pagination,
    Skeleton,
  },
  data() {
    return {
      allRateCode: [],
      contract_id: this.$route.params.id,
      season_id: this.$route.params.seasonId,
      loading: true,
    };
  },
  methods: {
    fetchRateCodes() {
      //   let season_contract_id = {season_contract_id: this.season_id}
      // axios.get(`/rate_codes?page=${page}`).then(({ data }) => {
      //   this.allRateCode = data.data;
      //   console.log(this.allRateCode);
      // });
      axios
        .post(`/fetch_transportation_rate_codes_by_season_id/${this.season_id}`)
        .then(({ data }) => {
          // console.log(data);
          this.allRateCode = data.data;
          this.loading = false;
        });
    },
    //delete contract
    deleteRateCode(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.allRateCode.data[index].id;
          const fromClick = true;
          axios.delete(`/rate_codes/${delete_id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.allRateCode.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.fetchRateCodes();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
</style>
