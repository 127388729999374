<template>
  <div class="card_style row mb-3 mt-3">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h4 class="mx-2">
        {{ $t("Add Room View") }}
      </h4>
    </div>
    <form @submit.prevent="addRoomView" class="row mt-3">
      <div class="col-lg-6 col-md-4 col-12 form-group">
        <label class="form-label"> {{ $t("Room Type") }}</label>
        <multiselect
          v-model="room"
          :options="rooms"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="$t('choose')"
          label="title"
          track-by="id"
        ></multiselect>
      </div>
      <div class="col-lg-6 col-md-4 col-12 form-group">
        <label class="form-label"> {{ $t("View") }}</label>
        <multiselect
          v-model="view"
          :options="views"
          :close-on-select="true"
          :show-labels="false"
          :placeholder="$t('choose')"
          label="title"
          track-by="id"
          :hide-selected="true"
          :multiple="true"
        ></multiselect>
      </div>
      <div class="col-lg-12 col-md-4 col-12 d-flex justify-content-end">
        <button type="submit" class="btn PrimaryButton w-25">
          {{ $t("Add") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  components: {
    Multiselect,
    AddFavDailog,
  },
  data() {
    return {
      rooms: [],
      views: [],
      room: null,
      view: null,
      loading: true,
      viewRoomData: {},
    };
  },
  methods: {
    async fetchAllViews() {
      await axios.post("fetch_all_view").then(({ data }) => {
        this.views = data.data.data;
        // console.log(this.views);
      });
    },
    async fetchAllRooms() {
      await axios.post("fetch_all_rooms").then(({ data }) => {
        this.rooms = data.data;
        // console.log(this.rooms);
      });
    },
    async addRoomView() {
      if (this.$route.params.id == undefined) {
        const formData = new FormData();
        formData.append("room_id", this.room.id);
        this.view.forEach((element) => {
          formData.append("view_type_ids[]", element.id);
        });

        axios
          .post("store_final_rooms", formData)
          .then(({ data }) => {
            Swal.fire({
              title: this.$t("success"),
              text: data.message,
              icon: "success",
            });
            this.$router.push("/roomViewGlobal");
          })
          .catch((error) => {
            Swal.fire({
              title: this.$t("error"),
              text: error.response.data.message,
              icon: "error",
            });
          });
      } else {
        const params = new URLSearchParams();
        params.append("room_id", this.room.id);
        this.view.forEach((element) => {
          params.append("view_type_ids[]", element.id);
        });
        axios
          .put("update_final_room", params)
          .then(({ data }) => {
            Swal.fire({
              title: this.$t("success"),
              text: data.message,
              icon: "success",
            });
            this.$router.push("/roomViewGlobal");
          })
          .catch((error) => {
            Swal.fire({
              title: this.$t("error"),
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    fetchViewsForUpdate() {
      axios
        .post("show_final_room", { id: this.$route.params.id })
        .then(({ data }) => {
          this.room = data.data.room;
          this.view = data.data.view_type;
          this.loading = false;
        });
    },
  },
  async mounted() {
    if (this.$route.params.id != undefined) {
      this.fetchViewsForUpdate();
    }
    await this.fetchAllViews();
    await this.fetchAllRooms();
  },
};
</script>

<style lang="scss" scoped></style>
