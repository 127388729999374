<template>
  <div class="exchange-recipt">
    <div class="container">
      <div class="d-flex align-items-center py-3">
        <AddFavDailog />
        <h4 v-if="$route.params.type === 'exchange'" class="px-2">
          {{ $t("add_exchange") }}
        </h4>
        <h4 v-else-if="$route.params.type === 'receipt'" class="px-2">
          {{ $t("add_receipt") }}
        </h4>
      </div>
    </div>
    <div class="container">
      <form @submit.prevent="addExchangeReceipt()">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-12">
            <div class="form-group">
              <label>{{ $t("date") }}</label>
              <Datepicker v-model="Form.date" disabled />
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <div class="form-group">
              <label>{{ $t("consumer") }}</label>
              <Multiselect
                v-model="Form.consumer"
                :options="providers"
                label="name"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
              <span
                class="text-danger"
                v-if="v$.Form.consumer.$error"
                v-text="v$.Form.consumer.$errors[0].$message"
              ></span>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <div class="form-group">
              <label>{{ $t("organization_account") }}</label>
              <Multiselect
                v-model="Form.organization_account"
                :options="organizationAccounts"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.organization_account.$error"
              v-text="v$.Form.organization_account.$errors[0].$message"
            ></span>
          </div>
          
          <div class="col-lg-6 col-md-12 col-12">
            <div class="form-group">
              <label>{{ $t("collect_date") }}</label>
              <Datepicker
                v-model="Form.collectDate"
                :placeholder="$t('collect_date')"
              />
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.collectDate.$error"
              v-text="v$.Form.collectDate.$errors[0].$message"
            ></span>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <div class="form-group">
              <label>{{ $t("amount") }}</label>
              <input
                type="number"
                class="form-control"
                placeholder="Amount"
                v-model="Form.amount"
              />
              <span
                class="text-danger"
                v-if="v$.Form.amount.$error"
                v-text="v$.Form.amount.$errors[0].$message"
              ></span>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-12 col-12"
            v-if="$route.params.type === 'receipt'"
          >
            <div class="form-group">
              <label>{{ $t("bank_name") }}</label>
              <input type="text" class="form-control" v-model="Form.bankName" />
              <!-- <span
                class="text-danger"
                v-if="v$.Form.bankName.$error"
                v-text="v$.Form.bankName.$errors[0].$message"
              ></span> -->
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <div class="form-group">
              <label>{{ $t("sheet_number") }}</label>
              <input
                type="text"
                :placeholder="$t('enter_sheet_number')"
                class="form-control py-2"
                v-model="Form.sheetNumber"
              />
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.sheetNumber.$error"
              v-text="v$.Form.sheetNumber.$errors[0].$message"
            ></span>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <label class="form-label"> {{ $t("statement") }}</label>
            <div class="input-group">
              <textarea
                class="form-control"
                :placeholder="$t('add_statement')"
                v-model="Form.report"
              ></textarea>
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.report.$error"
              v-text="v$.Form.report.$errors[0].$message"
            ></span>
          </div>
          
          <div class="col-lg-6 col-md-12 col-12">
            <div class="form-group">
              <label>{{ $t("image") }}</label>
              <vue-multi-image-upload
                @data-image="uploadImages"
                :max="4"
                :image-size="4e6"
                :alert-timeout="3e3"
                :accept="imageType"
                :preview="{ h: 100, w: 100 }"
                :resize="{ h: 500, w: 500, keepRatio: true }"
                :data-reset="component"
              />
              <button
                v-if="inputImages?.length > 0"
                class="btn"
                type="button"
                @click="clear()"
              >
                {{ $t("Clear") }}
              </button>
            </div>
          </div>
          <div class="col-12 mt-3">
            <button type="submit" class="add btn PrimaryButton">
              <i class="fa-solid fa-spinner fa-spin-pulse" v-if="loading"></i>
              <span v-else>{{ $t("add") }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";
import { VueMultiImageUpload } from "@zakerxa/vue-multiple-image-upload";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  components: {
    AddFavDailog,
    Multiselect,
    VueMultiImageUpload,
    Datepicker,
  },
  validations() {
    return {
      Form: {
        consumer: {
          required,
        },
        organization_account: {
          required,
        },
        date: {
          required,
        },
        amount: {
          required,
        },
        collectDate: {
          required,
        },
        report: {
          required,
        },
        sheetNumber: {
          required,
        },
        // bankName: {
        //   required,
        // },
      },
    };
  },
  data() {
    return {
      v$: useVuelidate(),
      imageType: ["image/jpeg", "image/png", "image/gif"],
      component: {},
      Form: {
        date: new Date(),
        amount: "",
        consumer: "",
        organization_account: "",
        bankName: "",
        sheetNumber: "",
        collectDate: "",
        report: "",
      },
      inputImages: [],
      loading: false,
      providers: [],

      organizationAccounts: [],
    };
  },
  methods: {
    clear() {
      this.component.clear = true;
    },
    uploadImages(e) {
      this.inputImages = e;

      // The way to append images to FormData.
      let formData = new FormData();
      let base64Strings = []; // Array to store base64 strings

      // Convert each image to base64 and append to formData
      this.inputImages.forEach((imgFile, index) => {
        const reader = new FileReader();

        // Read the file as a data URL
        reader.readAsDataURL(imgFile);

        // Once the file is loaded, convert it to base64 and append to formData
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];
          formData.append(`images[${index}]`, base64String);
          base64Strings.push(base64String);
        };
      });
    },
    addExchangeReceipt() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.loading = true;
        const formData = new FormData();
        if (this.$route.params.type === "receipt") {
          formData.append("type", 1);
        } else {
          formData.append("type", 2);
        }
        formData.append(
          "check_number",
          moment(this.Form.date).format("YYYY-MM-DD")
        );
        formData.append("value", this.Form.amount);
        formData.append("consumer_id", this.Form.consumer.id);
        formData.append(
          "organization_account_id",
          this.Form.organization_account.id
        );
        if (this.$route.params.type === "receipt") {
          formData.append("bank_name", this.Form.bankName);
        }
        formData.append("sheet_number", this.Form.sheetNumber);
        formData.append(
          "due_date",
          moment(this.Form.collectDate).format("YYYY-MM-DD")
        );
        formData.append("description", this.Form.report);
        this.inputImages.forEach((ele, index) => {
          if (ele instanceof File) {
            formData.append(`images[${index}]`, ele);
          }
        });
        axios
          .post("/store_check", formData)
          .then(({ data }) => {
            this.loading = false;
            Swal.fire({
              title: this.$t("success"),
              text: data.message,
              icon: "success",
            });
            this.Form = {
              date: new Date(),
              amount: "",
              consumer: "",
              organization_account: "",
              bankName: "",
              sheetNumber: "",
              collectDate: "",
              report: "",
            };
            this.inputImages = [];
            if (this.$route.params.type === "receipt") {
              this.$router.push({ path: "/show/receipt" });
            } else {
              this.$router.push({ path: "/show/exchange" });
            }
          })
          .catch((error) => {
            this.loading = false;
            Swal.fire({
              title: this.$t("error"),
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    FetchSuppliers() {
      // const word =  {word: event == undefined ? "" : event.target.value};
      axios.post("/fetch_consumer_by_role").then(({ data }) => {
        this.providers = data.data.data;
      });
    },
    fetchOrganizationAccounts() {
      axios
        .post("/fetch_my_organization_accounts", { type: 1 })
        .then(({ data }) => {
          this.organizationAccounts = data.data.data;
          //   console.log(this.organizationAccounts);
        });
    },
  },
  mounted() {
    this.FetchSuppliers();
    this.fetchOrganizationAccounts();
  },
};
</script>

<style>
.vuejs3-datepicker {
  display: block !important;
}
.exchange-recipt .form-group img {
  position: unset !important;
  transform: unset !important;
}
</style>
