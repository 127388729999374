<template>
  <div class="row">
    <div class="main_header_style">
      <!-- <h4>
        {{ $t("total_reservation") }} <span>({{ }})</span>
      </h4> -->

      <!-- <div class="d-flex flex-row justify-content-end w-50 mt-4">
        <div style="margin-inline-end: 1%" class="input-group w-50">
          <Multiselect
            v-model="bankAccount"
            :options="banks.map((bank) => bank.id)"
            :custom-label="(opt) => banks.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('reservation_status')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      
      </div> -->
    </div>
    <tabs />
  </div>
</template>

<script>
// import "jquery/dist/jquery.min.js";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "datatables.net-dt/js/dataTables.dataTables";
// import "datatables.net-dt/css/jquery.dataTables.min.css";
// import axios from "axios";
// import Swal from "sweetalert2";
// import $ from "jquery";
import tabs from "@/components/consumer/tabs.vue";
export default {
  name: "reservation-branch",
  components: {
    tabs,
  },
  data() {
    return {
      // bankAccount: ""
    };
  },

  

 
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "../../view/dashboard/organization/_organization.scss";
</style>
