<template>
  <section class="all_emp card_style py-1">
    <div class="row options mb-3 align-items-center my-2">
      <div class="d-flex align-items-center col-lg-4 col-md-12 col-12">
        <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
        <h4>
          {{ $t("reservation") }}
          {{
            this.book_type == "b2b"
              ? "B2B"
              : this.book_type == "b2c"
              ? "B2C"
              : ""
          }}
        </h4>
      </div>

      <!-- <div class="col-lg-2 col-md-12 col-12"></div> -->
      <form class="col-lg-2 col-md-12 col-12">
        <i class="fa-solid fa-magnifying-glass"></i>
        <!-- <input type="text" :placeholder="$t('search by name')" /> -->
        <input
          type="text"
          class="form-control search"
          :placeholder="$t('search by name')"
          v-model="resrvationName"
          @keyup="fetchSearch((page = 1))"
        />
      </form>

      <div class="col-lg-2 col-md-12 col-12 d-flex justify-content-end">
        <filterBookings @getData="getData" :booking_type="book_type" />
      </div>

      <div
        class="col-lg-4 col-md-12 col-12 d-flex justify-content-end align-items-center"
      >
        <div class="d-flex">
          <ToggleButton
            v-model="checked"
            :onLabel="this.$t('hide_table')"
            :offLabel="this.$t('show_table')"
            class="SecondButton"
          />
          <!-- <filterBookings @fetchBooks = "fetchBooks"/> -->
        </div>
        <div class="d-flex">
          <button
            class="SecondButton btn action d-flex align-items-center mx-2"
            @click="exportToExcel"
            v-if="checked"
          >
            <i class="fa-solid fa-file-excel"></i>
            <div
              class="img-border d-flex align-items-center justify-content-center"
            >
              <img src="@/assets/media/image/fi_7268609.svg" alt="" />
            </div>
          </button>
          <div id="print-button" class="print_button" v-else>
            <button
              class="SecondButton btn action d-flex align-items-center"
              v-print="'#books-wrapper'"
            >
              <img src="@/assets/media/image/fi_7180363.svg" alt="" />
              <div
                class="img-border d-flex align-items-center justify-content-center"
              >
                <img src="@/assets/media/image/fi_7268609.svg" alt="" />
              </div>
            </button>
          </div>
          <!-- <filterBookings @fetchBooks = "fetchBooks"/> -->
        </div>
      </div>

      <!-- <div class="col-2 d-flex justify-content-end">
        <router-link
          :to="{ name: 'bookOfflinePage' }"
          class="PrimaryButton btn w-100"
          ><i class="fa-solid fa-plus"></i> {{ $t("New Book") }}</router-link
        >
      </div> -->
    </div>
    <Transition name="fade"
      ><div class="row" v-if="checked">
        <div class="table_row">
          <div class="table-responsive">
            <table class="table offline" id="bookTable">
              <thead>
                <tr>
                  <th>{{ $t("ID") }}</th>
                  <th>{{ $t("Serial Number") }}</th>
                  <th>{{ $t("confimration_number") }}</th>
                  <th>{{ $t("date") }}</th>
                  <th v-if="book_type == 'b2b'">{{ $t("seller") }}</th>
                  <th v-if="book_type == 'b2b'">{{ $t("suppliers") }}</th>
                  <th v-if="book_type == 'b2b'">{{ $t("supplier_number") }}</th>
                  <th v-if="book_type == 'b2b'">{{ $t("reservton_type") }}</th>
                  <th>{{ $t("whoseller") }}</th>
                  <th>{{ $t("client_name") }}</th>
                  <th>{{ $t("hotel_code") }}</th>
                  <th>{{ $t("currency") }}</th>
                  <th>{{ $t("checkin") }}</th>
                  <th>{{ $t("checkout") }}</th>
                  <th class="night">{{ $t("number_of_nights") }}</th>
                  <th>{{ $t("Hotel Name") }}</th>
                  <th>{{ $t("title_room") }}</th>
                  <th class="room">{{ $t("number_of_rooms") }}</th>
                  <th>{{ $t("meals") }}</th>
                  <th>{{ $t("price") }}</th>
                  <th>{{ $t("coupon") }}</th>
                  <th>{{ $t("status") }}</th>
                  <th>{{ $t("status_payment") }}</th>
                  <th>{{ $t("Control") }}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(book, index) in books.data" :key="index">
                  <td>{{ total - ((indexPage - 1) * per_page + index) }}</td>
                  <td style="width: 100px">
                    {{ book.booking_number }}
                  </td>
                  <td style="width: 150px">{{ book.confirmation_number }}</td>
                  <td style="width: 140px">{{ book.created_date }}</td>

                  <td style="width: 140px" v-if="book_type == 'b2b'">
                    {{ book.booking_by }}
                  </td>
                  <td style="width: 180px" v-if="book_type == 'b2b'">
                    {{ book.supplier_name }}
                  </td>
                  <td style="width: 180px" v-if="book_type == 'b2b'">
                    {{ book.supplier_phone }}
                  </td>
                  <td style="width: 140px" v-if="book_type == 'b2b'">
                    {{ book.booking_type }}
                  </td>
                  <td style="width: 140px">X-coders</td>
                  <td style="width: 140px">{{ book.client_name }}</td>
                  <td style="width: 140px">{{ book.hotel_phone }}</td>
                  <td style="width: 140px">{{ book.client_currency_code }}</td>
                  <td style="width: 140px">{{ book.check_in }}</td>
                  <td style="width: 140px">{{ book.check_out }}</td>
                  <td class="night" style="width: 30px">
                    {{ book.numberOfNights }}
                  </td>
                  <td style="width: 250px">{{ book.hotel_name }}</td>
                  <td style="width: 250px">
                    <span
                      v-for="(room, index) in book.rooms"
                      :key="index"
                      class="px-3"
                      >{{ room.title }}</span
                    >
                  </td>
                  <td class="room" style="width: 30px">
                    {{ book.number_of_rooms }}
                  </td>
                  <td style="width: 150px">
                    <span
                      v-for="(meal, id) in book.rooms"
                      :key="id"
                      class="meal_type"
                    >
                      <span
                        >{{ meal.description }} <span class="dash"> - </span>
                      </span></span
                    >
                  </td>
                  <td>
                    {{ book.client_currency_code }}
                    {{
                      book.booking_type == "online"
                        ? (
                            book.total_selling_price + book.selling_price_tax
                          ).toFixed(2)
                        : book.total_selling_price.toFixed(2)
                    }}
                  </td>
                  <td>{{ book.coupon == null ? "" : book.coupon.value }}</td>
                  <td>
                    <span
                      :class="[
                        'badge p-2 ',
                        book.status === 0
                          ? 'bg-primary'
                          : book.status === 1
                          ? 'bg-warning text-dark'
                          : book.status === 2
                          ? 'bg-success'
                          : book.status === 3
                          ? 'bg-info text-dark'
                          : book.status === 4
                          ? 'bg-danger'
                          : '',
                      ]"
                    >
                      {{
                        book.status === 0
                          ? $t("initial")
                          : book.status === 1
                          ? $t("pending")
                          : book.status === 2
                          ? $t("completed")
                          : book.status === 3
                          ? $t("edited")
                          : book.status === 4
                          ? $t("canceled")
                          : ""
                      }}
                    </span>
                  </td>
                  <td v-if="book.payment_status == '0'">{{ $t("Pending") }}</td>
                  <td v-if="book.payment_status == '1'">{{ $t("Accept") }}</td>
                  <td v-if="book.payment_status == '2'">{{ $t("Decline") }}</td>
                  <td v-if="book.payment_status == '3'">
                    {{ $t("REFUND PENDING ") }}
                  </td>
                  <td v-if="book.payment_status == '4'">
                    {{ $t("REFUND Accept ") }}
                  </td>
                  <td v-if="book.payment_status == '5'">
                    {{ $t("REFUND Decline ") }}
                  </td>
                  <td v-if="book.payment_status == '6'">
                    {{ $t("payment send ") }}
                  </td>
                  <td class="">
                    <router-link
                      class="btn btn-secondary mx-1"
                      :to="`/history/${book.id}`"
                      :title="$t('history')"
                    >
                      <i class="fa-solid fa-file-waveform"></i>
                    </router-link>

                    <router-link
                      v-if="book.booking_type == 'offline'"
                      :to="`/book_table/edit/${book.id}`"
                      class="btn btn-success mx-1 text-white"
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </router-link>

                    <router-link
                      v-else
                      :to="`/hotel-view/${book.id}`"
                      class="btn btn-info mx-1"
                      :title="$t('show_details')"
                    >
                      <i class="fa-regular fa-pen-to-square"></i>
                    </router-link>

                    <router-link
                      :to="`/book_table/${book.id}/pill`"
                      class="btn btn-primary mx-1 text-white"
                      :title="$t('pills')"
                    >
                      <i class="fa-solid fa-file-invoice"></i>
                    </router-link>

                    <router-link
                      class="btn btn-success mx-1 text-white"
                      :to="`/book_table/${book.id}/paid`"
                      :title="$t('paid')"
                    >
                      <i class="fa-solid fa-money-bill-transfer"></i>
                    </router-link>

                    <div
                      class="btn btn-danger text-white mx-1"
                      @click="cancelBook(index, book.id)"
                      :title="$t('cancelBook')"
                    >
                      <i class="fa-solid fa-trash"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- start new design -->
      <table v-else-if="loading" class="w-100">
        <hotelBoking />
      </table>

      <div class="books-wrapper" id="books-wrapper" v-else>
        <div
          :class="[
            'books',
            'mt-1',
            'd-flex',
            'align-items-center',
            'py-1',
            'px-3',
            'flex-lg-nowrap',
            book?.status == 0
              ? 'bg-default'
              : book?.status == 1
              ? 'bg-warring'
              : book?.status == 2
              ? 'bg-confirmed'
              : book?.status == 3
              ? 'bg-canceled'
              : book?.status == 4
              ? 'bg-canceled'
              : '',
          ]"
          v-for="(book, index) in books.data"
          :key="index"
        >
          <div class="id">
            <router-link class="details_id" :to="`/hotel-view/${book.id}`"
              ><h4 class="text-center">
                {{ total - ((indexPage - 1) * per_page + index) }}
                <span class="hint_element bank_hint">{{
                  $t("book_details")
                }}</span>
              </h4></router-link
            >
          </div>
          <div
            class="info d-flex align-items-center justify-content-end flex-column"
          >
            <div class="d-flex">
              <p class="text-center">
                {{ book.hotel_name }}
              </p>
              <span
                class="nights d-flex align-items-center justify-content-center mx-1"
                >{{ book.number_of_nights }}N</span
              >
            </div>
            <p>{{ book.check_in }} -- {{ book.check_out }}</p>
          </div>
          <div class="rooms mt-0 mb-0">
            <div
              v-for="(room, index) in book.rooms"
              :key="index"
              class="room d-block flex-row-reverse"
            >
              <!-- <span>{{ room.count }}</span> -->
              <p class="text-center">
                {{ room.count }}x {{ room.title }} -
                {{ room.description }}
              </p>

              <div class="hint_element room_hint">
                {{ $t("room_details") }}
              </div>
            </div>
          </div>
          <div class="cost d-flex justify-content-center gap-3">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <p class="total mb-0">
                {{ Math.floor(book.total_selling_price) }}
                {{ book.client_currency_code }}
                <span class="hint_element bank_hint">{{
                  $t("total_client")
                }}</span>
              </p>
              <div
                v-if="book.payment_status == '1'"
                :class="`icons ${
                  book.payment_status == '1'
                    ? 'bg-warning text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("Pending_payment") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.payment_status == '2'"
                :class="`icons ${
                  book.payment_status == '2'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("paid_ok") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.payment_status == '3'"
                :class="`icons ${
                  book.payment_status == '3'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("Decline_payment") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.payment_status == '4'"
                :class="`icons ${
                  book.payment_status == '4'
                    ? 'bg-warning text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("Receipt Is Pending") }}
                  <!-- {{ $t("REFUND PENDING") }} -->
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <!-- <div
                v-if="book.payment_status == '4'"
                :class="`icons ${
                  book.payment_status == '4'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Accept") }}
                </p>
              </div> -->
              <!-- <div
                v-if="book.payment_status == '5'"
                :class="`icons ${
                  book.payment_status == '5'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Decline") }}
                </p>
              </div> -->
              <!-- <div
                v-if="book.payment_status == '6'"
                :class="`icons ${
                  book.payment_status == '6'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("payment sent") }}
                </p>
              </div> -->
            </div>

            <div class="d-flex flex-column align-items-center">
              <p class="total_company">
                {{ book.organization_currency_code }}
                {{ Math.floor(book.total_cost) }}
                <span class="hint_element bank_hint">{{
                  $t("total_company")
                }}</span>
              </p>
              <div class="icons">
                <!-- <p
                  class="bank d-flex align-items-center justify-content-center px-2 bank_element"
                >
                  {{ $t("banky") }}
                  <img src="@/assets/media/image/wallet.svg" alt="" />
                </p> -->
                <button
                  type="button"
                  class="btn bank d-flex align-items-center justify-content-center px-2 bank_element"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  {{
                    book.payment_method == 1
                      ? $t("cash")
                      : book.payment_method == 2
                      ? $t("online")
                      : $t("wallet")
                  }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="conf flex-column px-2 text-center d-flex align-items-start justify-content-center"
          >
            <h6 class="mb-0">X-coders</h6>

            <div class="w-100" v-if="book.status === 2">
              <p class="text-center">
                H.Conf : #{{ book.confirmation_number }}
              </p>
            </div>
            <span
              style="width: -webkit-fill-available; text-align: center"
              v-else
              :class="[
                'badge  text-center d-flex align-items-center justify-content-center',
                book.status === 0
                  ? 'text-primary'
                  : book.status === 1
                  ? 'text-warning '
                  : book.status === 2
                  ? 'text-success'
                  : book.status === 3
                  ? 'text-info '
                  : book.status === 4
                  ? 'text-danger'
                  : '',
              ]"
            >
              {{
                book.status === 0
                  ? $t("initial")
                  : book.status === 1
                  ? $t("pending")
                  : book.status === 2
                  ? $t("completed")
                  : book.status === 3
                  ? $t("edited")
                  : book.status === 4
                  ? $t("canceled")
                  : ""
              }}
            </span>
          </div>
          <div
            class="client d-flex flex-column align-items-center justify-content-end"
          >
            <h4 class="text-center">{{ book.client_name }}</h4>
            <div class="d-flex align-items-center justify-content-center">
              <div class="d-flex align-items-center gap-3">
                <img src="@/assets/media/image/frame.svg" alt="" />
                <p class="mx-1">
                  {{ book.number_of_adults }} {{ $t("adult") }}
                </p>
              </div>
              <div class="d-flex align-items-center gap-3">
                <img src="@/assets/media/image/people.svg" alt="" />
                <p class="mx-1">
                  {{ book.number_of_children }} {{ $t("children") }}
                </p>
              </div>
            </div>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {{ $t("the reciept of your reservation") }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="reciepts" v-if="book.receipts.length > 0">
                    <div
                      class="reciept"
                      v-for="(reciept, index) in book.receipts"
                      :key="index"
                    >
                      <img :src="reciept" alt="reciept" />
                    </div>
                  </div>
                  <p v-else>{{ $t("no reciept exist") }}</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    {{ $t("Reject") }}
                  </button>
                  <button type="button" class="btn btn-primary">
                    {{ $t("Acceptance") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <pagination
            :data="books"
            class="mx-auto d-flex align-items-center justify-content-center pagination"
            @pagination-change-page="fetchBooks"
          >
            <template v-slot:prev-nav>&lt;</template>
            <template v-slot:next-nav>&gt;</template>
          </pagination>
        </div>
      </div>
    </Transition>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import filterBookings from "../../../components/offline/filterBookings.vue";
import Swal from "sweetalert2";
// import Loading from "@/components/LottieFiles/loading.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import ToggleButton from "primevue/togglebutton";
import * as XLSX from "xlsx/xlsx.mjs";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
// import Skeleton from "primevue/skeleton";
import hotelBoking from "@/components/skelton/hotelBoking.vue";
export default {
  name: "bookTablePage",
  data() {
    return {
      books: [],
      formData: {},
      organizationAccounts: [],
      organizationSafe: [],
      suppliers: [],
      bookingType: "",
      resrvationName: "",
      loading: true,
      book_type: this.$route.params.bookType,
      filterButton: "",
      checked: false,
      indexPage: 1,
      per_page: 1,
      total: 1,
    };
  },
  components: {
    pagination,
    AddFavDailog,
    filterBookings,
    ToggleButton,
    hotelBoking,
  },
  methods: {
    FetchSuppliers(page = 1) {
      // const search = { word: this.supplier_name };
      axios
        .post(`/fetch_consumer_by_role?page=${page}`, {
          role: 2,
          word: this.supplierText,
        })
        .then(({ data }) => {
          this.suppliers = data.data.data;
        });
    },
    fetchOrganizationAccounts() {
      axios
        .post("/fetch_my_organization_accounts", { type: 1 })
        .then(({ data }) => {
          this.organizationAccounts = data.data.data;
          // console.log(this.organizationAccounts);
        });
    },
    fetchOrganizationSafe() {
      axios
        .post("/fetch_my_organization_accounts", { type: 2 })
        .then(({ data }) => {
          this.organizationSafe = data.data.data;
          // console.log(this.organizationSafe);
        });
    },
    getTotalAdults(rooms) {
      return rooms.reduce((total, room) => {
        return (
          total +
          room.guests.reduce(
            (roomTotal, guest) => roomTotal + guest.number_of_adults,
            0
          )
        );
      }, 0);
    },
    getTotalChildren(rooms) {
      return rooms.reduce((total, room) => {
        return (
          total +
          room.guests.reduce(
            (roomTotal, guest) => roomTotal + guest.number_of_children,
            0
          )
        );
      }, 0);
    },
    fetchBooks(page = 1) {
      const search = { word: this.resrvationName };
      // this.loading = true; //the loading begin
      let booking_type = {};
      if (this.book_type == "b2b") {
        booking_type = { booking_type: 2, book_person_type: 1 };
      } else if (this.book_type == "b2c") {
        booking_type = { booking_type: 2, book_person_type: 2 };
      }
      if (!this.filterButton) {
        axios
          .post(`/filter_offline_bookings?page=${page}`, booking_type, search)
          .then(({ data }) => {
            this.books = data.data;
            this.loading = false; //the loading ended
            this.indexPage = page;
            this.per_page = data.data.meta.per_page;
            this.total = data.data.meta.total;
            // console.log(this.books, "weee");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false;
          });
      } else {
        axios
          .post(`/filter_offline_bookings?page=${page}`, this.formData, search)
          .then(({ data }) => {
            this.books = data.data;
            this.loading = false; //the loading ended
            this.indexPage = page;
            this.per_page = data.data.meta.per_page;
            this.total = data.data.meta.total;
            // console.log(this.books);
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false;
          });
      }
    },
    fetchSearch(page = 1) {
      // Check if the search term is not empty
      if (this.resrvationName.trim() === "") {
        // Handle the case where the search input is empty
        this.books.data = [];
        return;
      }
      const search = { word: this.resrvationName };
      axios
        .post(`/filter_offline_bookings?page=${page}`, search)
        .then(({ data }) => {
          this.books = data.data;
        });
    },
    getData(data, filtered) {
      this.formData = data;
      this.filterButton = filtered;
      this.fetchBooks();
    },
    cancelBook(index, bookID) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085D6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`/cancel_booking`, { booking_id: bookID })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.books.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
            });
        }
      });
    },
    exportToExcel() {
      // Get the table element
      const table = document.getElementById("bookTable");
      // Convert the table to a worksheet
      const ws = XLSX.utils.table_to_sheet(table);
      // Create a workbook with a single sheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the workbook as an Excel file
      XLSX.writeFile(wb, "exported_data.xlsx");
    },
  },
  created() {
    this.fetchBooks();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
    this.FetchSuppliers();
    this.fetchOrganizationSafe();
    this.fetchOrganizationAccounts();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "offline.scss";
@import "@/assets/scss/_variables.scss";
</style>
