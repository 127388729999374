<template>
  <section class="show_receipt card_style">
    <div class="main_header_style">
      <h4 v-if="flightReservation.length === 0">
        {{ $t("no_reservation") }}
      </h4>
      <h4 v-else>
        {{ $t("total_reservation") }} ({{ flightReservation.length }})
      </h4>
    </div>

    <div class="row options mb-5">
      <form class="col-lg-8 col-12">
        <!-- <i class="fa-solid fa-magnifying-glass"></i> -->
        <input
          type="text"
          :placeholder="$t('search by name')"
          v-model="titlenation"
          @keyup="FetchFlight"
        />
      </form>

      <div class="col-lg-4 col-12 d-flex justify-content-end">
        <Multiselect
          v-model="statusValue"
          :options="status.map((statu) => statu.id)"
          :custom-label="(opt) => status.find((x) => x.id == opt).title"
          :clear-on-select="true"
          :placeholder="$t('status')"
          select-label=""
          :hide-selected="true"
        >
        </Multiselect>
      </div>
    </div>
    <div class="row" v-if="flightReservation.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ $t("ID") }}</th>
            <th scope="col">{{ $t("trip number") }}</th>
            <th scope="col">{{ $t("client_name") }}</th>
            <th scope="col">{{ $t("supplier_name") }}</th>
            <th scope="col">{{ $t("seller_name") }}</th>
            <th scope="col">{{ $t("reservation_number") }}</th>
            <th scope="col">{{ $t("flight_status") }}</th>
            <th scope="col">{{ $t("trip_kind") }}</th>
            <th scope="col">{{ $t("trip_departure") }}</th>
            <th scope="col">{{ $t("trip_arrival") }}</th>
            <!-- <th scope="col">{{ $t("price") }}</th> -->
            <th scope="col">{{ $t("status") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(book, index) in flightReservation"
            :key="index"
            :class="{
              accept: book.status == 0,
              confirm: book.status == 1,
              edit: book.status == 2,
              principle: book.status == 3,
              cancel: book.status == 4,
            }"
          >
            <td :data-label="$t('ID')">{{ book.id }}</td>
            <td :data-label="$t('trip number')">{{ book.trip_num }}</td>
            <td :data-label="$t('client_name')">{{ book.client }}</td>
            <td :data-label="$t('supplier_name')">{{ book.supplier }}</td>
            <td :data-label="$t('seller_name')">{{ book.main_seller }}</td>

            <td :data-label="$t('reservation_number')">
              {{ book.reservation_num }}
            </td>
            <td :data-label="$t('flight_status')">
              {{ book.flight_status }}
            </td>
            <td :data-label="$t('trip_kind')">
              <span v-if="book.flight_type == 'one_way'">{{
                $t("go_only")
              }}</span>
              <span v-else>{{ $t("go_and_back") }}</span>
            </td>
            <td :data-label="$t('trip_departure')">
              {{ book.trip_departure }}
            </td>
            <td :data-label="$t('trip_arrival')">{{ book.trip_arrival }}</td>
            <!-- <td :data-label="$t('price')">{{ book.price }}</td> -->
            <td :data-label="$t('status')">
              <span
                :class="[
                  'badge',
                  book.reservation_status === 'basically'
                    ? 'bg-primary'
                    : book.reservation_status === 'confirmed'
                      ? 'bg-warning text-dark'
                      : book.reservation_status === 'updated'
                        ? 'bg-success'
                        : book.reservation_status === 'accepted'
                          ? 'bg-info text-dark'
                          : book.reservation_status === 'canceled'
                            ? 'bg-danger'
                            : '',
                ]"
              >
                {{
                  book.reservation_status == "basically"
                    ? $t("initial")
                    : book.reservation_status === "confirmed"
                      ? $t("confirmed")
                      : book.reservation_status === "updated"
                        ? $t("updated")
                        : book.reservation_status === "accepted"
                          ? $t("accepted")
                          : book.reservation_status === "canceled"
                            ? $t("canceled")
                            : ""
                }}
              </span>
            </td>
            <td :data-label="$t('actions')">
              <a class="btn btn-primary text-white" href="#"
                ><i class="fa-regular fa-envelope"></i>
              </a>

              <a class="btn btn-success text-white mx-1" href="#"
                ><i class="fa-brands fa-whatsapp"></i>
              </a>

              <a class="btn btn-secondary text-white mx-1" href="#"
                ><i class="fa-solid fa-download"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h2 v-if="flightReservation.length === 0">{{ $t("no_reservation") }}</h2>
    <div class="row">
      <pagination
        :data="flightReservation"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchFlight"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Multiselect from "vue-multiselect";

// import Swal from "sweetalert2";
export default {
  name: "flight-reservation",
  data() {
    return {
      flightReservation: [],
      titlenation: "",
      statusValue: "",
      status: [
        {
          id: 0,
          value: "0",
          title: this.$t("initial"),
        },
        {
          id: 1,
          value: "1",
          title: this.$t("pending"),
        },
        {
          id: 2,
          value: "2",
          title: this.$t("completed"),
        },
        {
          id: 3,
          value: "3",
          title: this.$t("edited"),
        },
        {
          id: 4,
          value: "4",
          title: this.$t("canceled"),
        },
      ],
    };
  },
  components: {
    pagination,
    Multiselect,
  },
  methods: {
    FetchFlight(page = 1) {
      // const search = { word: this.titlenation };
      const data = {
        client_id: this.$route.params.id,
      };
      if (this.titlenation != "") {
        data.word = this.titlenation;
      }
      if (this.statusValue != "") {
        data.reservation_status = this.statusValue;
      }
      axios
        .post(`/fetch_organization_flight?page=${page}`, data)
        .then(({ data }) => {
          this.flightReservation = data.data.data;
        });
    },
  },
  mounted() {
    this.FetchFlight();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
// @import "@/assets/scss/variables.scss";

thead {
  border-radius: 10px 10px 0px 0px;
  border-radius: 20px;
  // border: 1px solid $PrimaryColor !important ;
  tr {
    border-radius: 10px 10px 0px 0px !important;
    // border: 1px solid $PrimaryColor !important ;
    th {
      text-align: start !important;
      font-family: "bold";
      font-weight: 100;
      background-color: #eef4f4;
      color: #656b78;
    }
  }
}
tbody {
  tr {
    border-bottom: unset !important;
    border-top: unset !important;
    &.main {
      td {
        background-color: #0a909b17;
      }
    }
    .status {
      display: inline-block;
      width: 88px;
      font-size: 0.6rem;
      border-radius: 24px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      &.main {
        background-color: #1664b857;
        color: #1664b8;
      }
      &.accept {
        background-color: #88c64b3d;
        color: #88c64b;
      }
      &.pending {
        color: #0a8f9b;
        background-color: #0a8f9b46;
        width: 130px;
      }
      &.decline {
        color: #c57016;
        background-color: rgba($color: #c57016, $alpha: 0.1);
      }
    }
    td {
      padding: 0.75rem 0;
      font-family: "regular";
      font-size: 0.9rem;
      border-bottom: unset !important;
      border-top: unset !important;
      color: black;
      padding-inline-start: 0 !important;
      .btn-sm {
        padding: 0.35rem 0.55rem;
      }
      a {
        // &.receipt {
        //   color: $PrimaryColor;
        //   text-decoration: underline;
        // }
      }
    }
    .offcanvas.offcanvas-bottom {
      height: auto;
    }
  }
}
</style>
