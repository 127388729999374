<template>
  <div class="success-card">
    <div class="success-book my-3">
      <img
        class="my-1"
        src="@/assets/media/image/icon/Interactions.png"
        alt="Success Image"
      />
      <h4 class="title-success-message tetx-center">
        {{ $t("sccess_booking") }}
      </h4>
      <p class="my-2">{{ $t("reservation_process") }}</p>
      <span class="confirm-number my-3"
        >{{ $t("Booking_confirmation") }} : #{{ confirmation_number }}</span
      >
    </div>

    <div class="container my-3 border-top border-bottom">
      <div class="row d-flex align-items-end justify-content-center">
        <div class="col-lg-1 my-2">
          <img
            class="side-mage"
            src="@/assets/media/image/icon/Group-000004941.svg"
            alt=""
          />
        </div>

        <div class="col-lg-10 my-2">
          <div class="w-100">
            <div class="contain-airport">
              <div class="details">
                <div class="image">
                  <img
                    src="@/assets/media/image/icon/airport.svg"
                    alt=""
                    srcset=""
                  />
                </div>

                <div class="detail">
                  <h5 class="title m-0">مطار الغردقة الدولي ( HRG )</h5>

                  <span class="text mt-1 me-2">
                    <img
                      src="@/assets/media/image/icon/calendar.svg"
                      alt=""
                      srcset=""
                    />
                    03:15 مساءً , 25 , يناير 2023
                  </span>
                </div>
              </div>
              <img
                src="@/assets/media/image/icon/date.svg"
                width="65"
                height="65"
                class="img-special"
              />
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/put-palan.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <span class="text text-color"
                  >محطة توقف في القاهرة 2 ساعة 40 دقيقة</span
                >
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/end-airport.svg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="detail">
                <h5 class="title m-0">مطار الملك خالد الدولي ( RUH )</h5>
                <span class="text mt-1 me-2">
                  <img
                    src="@/assets/media/image/icon/calendar.svg"
                    alt=""
                    srcset=""
                  />
                  03:15 مساءً , 25 , يناير 2023
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="total-book container">
      <div class="row total-book">
        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <img
              src="@/assets/media/image/icon/sale.svg"
              width="20"
              height="20"
              class="mx-2"
            />
            <span class="total">{{ $t("total price") }}</span>
          </div>

          <div class="col-4 text-center">
            <b class="total"> EGP {{ total_cost }}</b>
          </div>
        </div>

        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <img
              src="@/assets/media/image/icon/dollar.svg"
              width="20"
              height="20"
              class="mx-2"
            />
            <span class="total"> {{ $t("Value added tax") }}</span>
          </div>
          <div class="col-4 text-center">
            <b class="total"> EGP {{ total_cost_with_tax - total_cost }}</b>
          </div>
        </div>

        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <img
              src="@/assets/media/image/icon/taxt.svg"
              width="20"
              height="20"
              class="mx-2"
            />
            <span class="total">
              {{ $t("The total includes value added tax") }}
            </span>
          </div>
          <div class="col-4 text-center">
            <b class="total text-black"> EGP {{ total_cost_with_tax }}</b>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="btns my-2">
      <router-link to="/book_table" class="Detail-btn">{{
        $t("show_details")
      }}</router-link>

      <router-link to="/hotels/new" class="new-book">
        {{ $t("New Book") }}</router-link
      >
    </div>
    <!-- Buttons -->
  </div>
</template>

<script>
export default {
  name: "successFlightbook",
    data() {
      return {
        details: {
          guest_name: []
        },
        userName: {},
        check_in: "",
        check_out: "",
        hotel_name: "",
        total_cost_with_tax: 0,
        total_cost: 0,
        total_selling_price: 0,
        confirmation_number: "",
        fromDate: new Date(),
        toDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
        number_of_nights: 1,
        room_type: "",
      };
    },
    mounted() {
      this.userName = JSON.parse(
        localStorage.getItem("newBook")
      ).guest_name[0].name;
      this.details = JSON.parse(localStorage.getItem("newBook"));
      // console.log(this.details);
      this.check_in = this.details.check_in;
      this.check_out = this.details.check_out;
      this.hotel_name = this.details.hotel_name;
      this.total_cost_with_tax = this.details.total_cost_with_tax;
      this.total_cost = this.details.total_cost;
      this.total_selling_price = this.details.total_selling_price;
      this.confirmation_number = this.details.confirmation_number;
      this.room_type = this.details.room_type;
      // this.userName = this.details.name
    },
    methods: {},
    watch: {
      toDate() {
        if (this.toDate) {
          const toDate = new Date(this.toDate);
          const fromDate = new Date(this.fromDate);
          var timeDiff = Math.abs(toDate.getTime() - fromDate.getTime());
          var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
          this.number_of_nights = numberOfNights;
        }
      },
    },
};
</script>

<style lang="scss" scoped>
.success-book {
  width: 100%;
  margin: 0 auto;
  padding: 1.6rem 0;
  text-align: center;
  .title-success-message {
    font-size: 1.8rem;
    font-family: "bold";
    color: #0a909b;
  }

  .confirm-number {
    font-size: 1rem;
    font-family: "bold";
    color: #ababab;
  }
}

.contain-detail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  text-align: center;
  line-height: 3.5rem;
  margin-inline-end: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.success-card {
  width: -moz-fit-content;
  min-width: 45%;
  width: 45%;
  max-width: fit-content;
  margin: 0 auto;
  border: 1px solid #9ca2ac !important;
  background-color: #fff;
  border-radius: 1rem;
}

.total {
  font-family: "medium";
  font-size: 1.2rem;
  color: #656b78;
}

.total-book {
  width: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
}

.btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.detail {
  .title {
    color: #0b1427;
    font-family: "bold";
    font-size: 1.2rem;
  }
  .text {
    color: #9ca2ac;
    font-family: "regular";
    font-size: 1rem;
    margin-top: 1rem !important;
  }
}

.Detail-btn {
  border: 2px solid;
  padding: 1rem 3rem;
  border-radius: 10px;
  color: #fff;
  background: #0a909b;
}

.new-book {
  border: 2px solid #0a909b;
  padding: 1rem 3rem;
  border-radius: 10px;
  background: #fff;
  color: #0a909b;
}
.text-color {
  color: #0a909b !important;
  width: 70%;
  display: flex;
}

.contain-airport {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-mage {
  width: 2.375rem;
  height: 12.8125rem;
}
</style>
