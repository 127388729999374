<template>
  <button
    class="btn btn-sm btn-outline-secondary"
    @click="(visible = true), fetchSupplires()"
  >
    {{ $t("supplier_payment") }}
  </button>

  <Dialog
    v-model:visible="visible"
    modal
    header="Post to Account Supplier"
    :style="{ width: '50vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :dismissableMask="true"
  >
    <form @submit.prevent="PostToAccount(book_id, book_type)">
      <div class="d-flex align-items-center">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="paid"
            value="1"
            v-model="paid_type"
            id="totalPaid"
            checked
          />
          <label class="form-check-label" for="totalPaid">
            {{ $t("total_paid") }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="paid"
            value="2"
            v-model="paid_type"
            id="partPaid"
          />
          <label class="form-check-label" for="partPaid">
            {{ $t("part_paid") }}
          </label>
        </div>
      </div>
      <!-- <div class="d-flex align-items-center justify-content-around w-100 gap-1">
          <div class="form-group w-50 mb-0">
            <label for="">{{ $t("the amount paid") }}</label>
            <p class="form-control disabled mb-0">{{ paid_amount }}</p>
          </div>
          <div class="form-group w-50 mb-0">
            <label for="">{{ $t("the amount remaining") }}</label>
            <p class="form-control disabled mb-0">{{ remaining_amount }}</p>
          </div>
        </div> -->

      <!-- <p class="mb-2">{{ $t("transaction_type") }}</p> -->
      <div class="form-group">
        <label for="">{{ $t("supplier") }}</label>
        <Multiselect
          v-model="supplier_id"
          :options="suppliers"
          :placeholder="$t('supplier')"
          label="title"
          track-by="supplier_id"
          :clear-on-select="true"
          select-label=""
          :hide-selected="true"
          @keyup="fetchSupplires"
          @update:model-value="fetchSubInvoices(supplier_id.supplier_id)"
        >
        </Multiselect>
      </div>
      <div
        class="d-flex gap-3 align-items-center border-1 border p-2 rounded"
        v-if="supplier_id && paid_type == 1"
      >
        <div class="form-group w-100">
          <label for="">{{ $t("total") }}</label>
          <p class="form-control disabled mb-0">{{ supplier_id.total }}</p>
        </div>
        <div class="form-group w-100">
          <label for="">{{ $t("the amount paid") }}</label>
          <p class="form-control disabled mb-0">{{ supplier_id.paid }}</p>
        </div>
        <div class="form-group w-100">
          <label for="">{{ $t("the amount remaining") }}</label>
          <p class="form-control disabled mb-0">{{ supplier_id.remain }}</p>
        </div>
      </div>
      <div class="form-group" v-if="paid_type == 1">
        <label for="amount">{{ $t("the amount") }}</label>
        <input
          type="number"
          v-model="amountForAll"
          min="0"
          class="form-control"
          :placeholder="$t('the amount')"
        />
      </div>
      <div class="form-group" v-if="paid_type == 2">
        <label for="">{{ $t("the amount") }}</label>
        <input
          v-if="totalAmountForSelectedInvoices"
          type="number"
          v-model="totalAmountForSelectedInvoices"
          min="0"
          :max="remaining_amount"
          class="form-control"
          :placeholder="$t('the amount')"
        />
        <input
          v-else
          type="number"
          v-model="totalAmount"
          min="0"
          :max="remaining_amount"
          class="form-control"
          :placeholder="$t('the amount')"
        />
      </div>
      <div class="w-100 d-flex justify-content-end my-2" v-if="paid_type == 2">
        <button class="btn PrimaryButton" type="button" @click="speardCost">
          {{ $t("Spread the cost") }}
        </button>
      </div>
      {{ supplier_id.type }}
      <table class="table" v-if="paid_type == 2">
        <thead>
          <tr>
            <th>{{ $t("choose") }}</th>
            <th v-if="supplier_id.type !== 2">{{ $t("hotel_name") }}</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("paid_up") }}</th>
            <th>{{ $t("remaining") }}</th>
            <th>{{ $t("Distributed amount") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invoice, index) in invoices" :key="index">
            <td>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="selectedInvoices"
                  :value="invoice.id"
                  id="choose"
                />
              </div>
            </td>
            <td v-if="invoice.type !== 2">
              {{ invoice.hotel_name }}
            </td>
            <td>{{ invoice.hotel_name }}</td>
            <td>{{ invoice.title }}</td>
            <td>{{ invoice.supplier_paid_amount }}</td>
            <td>{{ invoice.supplier_remaining_amount }}</td>
            <td v-if="selectedInvoices.includes(invoice.id)">
              <div class="form-group">
                <label>{{ $t("the amount") }}</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="invoiceAmounts[invoices.indexOf(invoice)]"
                  min="0"
                />
              </div>
            </td>
            <td v-else>
              <div class="form-group">
                <label>{{ $t("the amount") }}</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="invoiceAmounts[index]"
                  :disabled="!selectedInvoices.includes(invoice.id)"
                  min="0"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <AccountsBanks
            @updateAccountValue="updateAccountValue"
            @updateSafeValue="updateSafeValue"
            :organizationAccounts="organizationAccounts"
            :organizationSafe="organizationSafe"
            class="my-3"
          />
        </div>
        <div
          class="col-lg-6 col-md-6 col-12 d-flex align-items-center justify-content-between"
        >
          <div class="w-100" style="margin-top: 2rem; margin-bottom: 0.5rem">
            <vue-multi-image-upload
              @data-image="uploadImages"
              :max="4"
              :image-size="4e6"
              :alert-timeout="3e3"
              :accept="imageType"
              :preview="{ h: 100, w: 100 }"
              :resize="{ h: 500, w: 500, keepRatio: true }"
              :data-reset="component"
            />
            <button
              v-if="inputImages?.length > 0"
              class="btn"
              type="button"
              @click="clear()"
            >
              {{ $t("Clear") }}
            </button>
          </div>
        </div>
      </div>
      <button type="submit" class="btn PrimaryButton">{{ $t("save") }}</button>
    </form>
  </Dialog>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Dialog from "primevue/dialog";
import Multiselect from "vue-multiselect";
// import RadioButton from "primevue/radiobutton";
import AccountsBanks from "@/components/clients/AccountsBanks.vue";
import { VueMultiImageUpload } from "@zakerxa/vue-multiple-image-upload";

export default {
  name: "post_to_account",
  data() {
    return {
      amountForAll: "",
      invoiceAmounts: [],
      amount: "",
      transaction_type: 2,
      supplier_id: "",
      visible: false,
      accountValue: "",
      safeValue: "",
      suppliers: [],
      component: {},
      inputImages: [],
      invoices: [],
      selectedInvoices: [],
      paid_type: 1,
      imageType: ["image/jpeg", "image/png", "image/gif"],
      defaultImage: require("@/assets/media/image/placeholder.png"),
    };
  },
  components: {
    Dialog,
    AccountsBanks,
    VueMultiImageUpload,
    Multiselect,
    // RadioButton,
  },

  props: [
    "book_id",
    "book_type",
    "baseBookId",
    "organizationAccounts",
    "organizationSafe",
    "paid_amount",
    "remaining_amount",
  ],
  emits: ["inputImagesEmit", "fetchPaymentMethods"],
  methods: {
    speardCost() {
      const totalClientRemainingAmount = this.invoices.reduce(
        (sum, invoice) => sum + invoice.supplier_remaining_amount,
        0,
      );

      if (this.totalAmount > totalClientRemainingAmount) {
        Swal.fire({
          icon: "error",
          title: this.$t("total_amount_exceeds_remaining_amounts"),
        });
        return;
      }

      this.amount = this.totalAmount;
      this.invoices.forEach((invoice, index) => {
        if (
          this.amount > 0 &&
          this.amount >= invoice.supplier_remaining_amount
        ) {
          this.invoiceAmounts[index] = invoice.supplier_remaining_amount;
          this.amount -= invoice.supplier_remaining_amount;
        } else if (
          this.amount > 0 &&
          this.amount < invoice.supplier_remaining_amount
        ) {
          this.invoiceAmounts[index] = this.amount;
          this.amount = 0;
        } else {
          this.invoiceAmounts[index] = 0;
          this.amount = 0;
        }
      });

      // Ensure remaining_amount is not zero
      if (this.remaining_amount === 0) {
        Swal.fire({
          icon: "error",
          title: this.$t("remaining_amount_cannot_be_zero"),
        });
      }
    },
    clear() {
      this.component.clear = true;
    },
    uploadImages(e) {
      this.inputImages = e;

      // The way to append images to FormData.
      let formData = new FormData();
      let base64Strings = []; // Array to store base64 strings

      // Convert each image to base64 and append to formData
      this.inputImages.forEach((imgFile, index) => {
        const reader = new FileReader();

        // Read the file as a data URL
        reader.readAsDataURL(imgFile);

        // Once the file is loaded, convert it to base64 and append to formData
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1];
          formData.append(`images[${index}]`, base64String);

          // Add the base64 string to the array
          base64Strings.push(base64String);

          // Emit the array of base64 strings after processing all images
          if (base64Strings.length === this.inputImages.length) {
            this.$emit("inputImagesEmit", base64Strings);
          }
        };
      });

      // console.log(this.inputImages);

      // If you still need to emit the array of inputImages, you can do so here
      // this.$emit("inputImagesEmit", this.inputImages);
    },
    updateAccountValue(value) {
      this.accountValue = value;
    },
    updateSafeValue(value) {
      this.safeValue = value;
    },
    fetchSubInvoices(supplier_id) {
      const data = {
        base_booking_id: this.$route.params.id,
        is_main: 0,
        supplier_id: supplier_id,
      };
      axios.post(`/fetch_sub_invoices`, data).then(({ data }) => {
        this.invoices = data.data;
      });
    },
    PostToAccount() {
      if (this.paid_type == 2 && !this.selectedInvoices.length) {
        Swal.fire({
          icon: "warning",
          title: this.$t("please_select_invoice"),
        });
      } else {
        const formData = new FormData();
        // formData.append("book_id", this.book_id);
        // formData.append("book_type", this.book_type);
        formData.append("base_booking_id", this.baseBookId);
        formData.append("transaction_type", this.transaction_type);
        formData.append("paid_type", this.paid_type);
        if (this.paid_type == 1) {
          formData.append("amount", parseInt(this.amountForAll));
        }

        formData.append("consumer_id", this.supplier_id.supplier_id);
        if (this.paid_type == 2) {
          // for (let i = 0; i < this.selectedInvoices.length; i++) {
          //   formData.append(`invoices[${i}]`, this.selectedInvoices[i]); // Include selected invoice IDs
          // }
          this.invoices.forEach((invoice, index) => {
            if (this.invoiceAmounts[index]) {
              formData.append(
                `invoices[${index}][base_booking_invoice_id]`,
                invoice.id,
              );
              formData.append(
                `invoices[${index}][amount]`,
                this.invoiceAmounts[index],
              );
            }
          });
        }
        if (this.accountValue != "" && this.safeValue == "") {
          formData.append("organization_account_id", this.accountValue.id);
        }
        if (this.safeValue != "") {
          formData.append("organization_account_id", this.safeValue.id);
        }
        // formData.append("client_id", 1);
        this.inputImages.forEach((ele, index) => {
          if (ele instanceof File) {
            formData.append(`images[${index}]`, ele);
          }
        });
        axios
          .post(`/store_post_to_account_order`, formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });

            this.$emit("fetchPaymentMethods");
            this.resetForm();
            this.visible = false;
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.visible = false;
          });
      }
    },

    resetForm() {
      this.amount = 0;
      this.selectedInvoices = [];
      this.accountValue = "";
      this.safeValue = "";
      this.paid_type = 1;
      this.inputImages = [];
      this.transaction_type = 1;
      this.supplier_id = "";
      this.visible = false;
      this.accountValue = "";
      this.safeValue = "";
    },

    async fetchSupplires(event) {
      const data = {
        base_booking_id: this.$route.params.id,
        role: 2,
        word: event == undefined ? "" : event.target.value,
      };
      axios
        .post("/fetch_offline_booking_supplier", data)
        .then(({ data }) => {
          this.suppliers = data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    // fetch suppliers data
  },
  computed: {
    totalAmountForSelectedInvoices() {
      return this.invoices.reduce((total, _, index) => {
        const amount = parseFloat(this.invoiceAmounts[index] || 0);
        return total + amount;
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.opacity {
  opacity: 0;
}
p.form-control.disabled {
  background-color: #e9e9e9;
}
.form-check .form-check-input {
  float: right;
  margin-inline-start: 0.5em;
}
.form-check-label {
  margin-inline-start: 2rem;
}
</style>
