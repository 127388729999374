<script setup>
import { defineProps, ref, reactive, watch } from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import { useDeleteItem } from "@/components/excelsheet/deletingItems";

const props = defineProps({
  sentedData: {
    type: Array,
    default: () => [],
    // required: false,
  },
  endpoint: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "",
  },
});
const arrData = ref([]);

const addItemToExcel = (item, index, checked) => {
  // Check if the item already exists in the array based on its index
  const existsIndex = arrData.value.findIndex(
    (_, existingIndex) => existingIndex === index,
  );

  // If the item does not exist and checkbox is checked, add it to the array
  if (checked && existsIndex === -1) {
    arrData.value.push(item);
  } else if (!checked && existsIndex !== -1) {
    // If the item exists and checkbox is unchecked, remove it from the array
    arrData.value.splice(existsIndex, 1);
  }
  console.log(arrData.value);
};
const handleCheckboxChange = (event, item, index) => {
  addItemToExcel(item, index, event.target.checked);
};

const addData = () => {
  const formData = new FormData();
  arrData.value.forEach((item, index) => {
    handleDelete(index);
    Object.keys(item).forEach((key) => {
      if (item[key] !== null) {
        formData.append(`${props.type}[${index}][${key}]`, item[key]);
      }
    });
  });
  axios
    .post("" + props.endpoint + "", formData)
    .then(({ data }) => {
      // console.log(data);
      // props.sentedData.filter((item, index) => {
      //   return arrData.value.includes(index);
      // });
      if (data.data.length === 0) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: data.message,
        });
      }
    })
    .catch((error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    });
};

const data = reactive([]);
watch(
  () => props.sentedData,
  (newData) => {
    data.value = newData;
  },
  { immediate: true },
);
const { handleDelete } = useDeleteItem(data);
</script>

<template>
  <div class="excel-table border py-4 px-2 rounded mt-2">
    <div class="row">
      <h4 class="col-6">{{ $t("excel information") }}</h4>
      <div class="col-6 d-flex justify-content-end" v-if="arrData.length > 0">
        <button class="btn SecondButton" @click="addData">
          {{ $t("add") }}
        </button>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-6 d-flex gap-2 align-items-center">
        <span class="bg-danger p-2"></span>
        <span>{{ $t("this data is exist") }}</span>
      </div>
      <div class="col-6 d-flex gap-2 align-items-center">
        <span class="bg-warning p-2"></span>
        <span>{{ $t("this data is invaild") }}</span>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between py-2 px-1 border rounded mb-2 shadow-sm bg-secondary fw-bold bg-opacity-25"
    >
      <span>#</span>
      <span v-if="this.$route.name === 'DiscountTypeExcel'">
        {{ $t("discount type code") }}
      </span>
      <span v-if="this.$route.name === 'DiscountTypeExcel'">
        {{ $t("discount type") }}
      </span>
      <span v-if="this.$route.name === 'SupplierTypeExcel'">
        {{ $t("supplier type code") }}
      </span>
      <span v-if="this.$route.name === 'SupplierTypeExcel'">
        {{ $t("supplier type") }}
      </span>
      <span v-if="this.$route.name === 'ClientTypeExcel'">
        {{ $t("client type code") }}
      </span>
      <span v-if="this.$route.name === 'ClientTypeExcel'">
        {{ $t("client type") }}
      </span>
      <span
        v-if="
          this.$route.name === 'ExcelClient' ||
          this.$route.name === 'ExcelSupplier'
        "
      >
        {{ $t("name") }}
      </span>
      <span
        v-if="
          this.$route.name === 'ExcelClient' ||
          this.$route.name === 'ExcelSupplier'
        "
      >
        {{ $t("phone") }}
      </span>
      <span
        v-if="
          this.$route.name === 'ExcelSupplier' ||
          this.$route.name === 'ExcelClient'
        "
      >
        {{ $t("email") }}
      </span>
      <span>{{ $t("status") }}</span>
      <span>{{ $t("actions") }}</span>
    </div>
    <div
      class="d-flex align-items-center justify-content-between py-2 px-1 border rounded mb-2 shadow-sm"
      v-for="(item, index) in props.sentedData"
      :key="index"
    >
      <span>
        <input
          class="form-check-input"
          type="checkbox"
          :value="item"
          :disabled="item.status === false"
          id="flexCheckDefault"
          @change="handleCheckboxChange($event, item, index)"
        />
      </span>
      <span
        v-if="this.$route.name === 'DiscountTypeExcel'"
        :class="{
          'text-danger': item.reasons.some((reason) => reason === 'N750'),
          'text-warning': item.reasons.some((reason) => reason === 'N800'),
        }"
      >
        {{ item.discount_type_coding }}
      </span>
      <span
        v-if="this.$route.name === 'DiscountTypeExcel'"
        :class="{
          'text-danger': item.reasons.some((reason) => reason === 'N750'),
          'text-warning': item.reasons.some((reason) => reason === 'N800'),
        }"
      >
        {{ item.discount_type }}
      </span>
      <span
        v-if="this.$route.name === 'SupplierTypeExcel'"
        :class="{
          'text-danger': item.reasons.some((reason) => reason === 'N750'),
          'text-warning': item.reasons.some((reason) => reason === 'N800'),
        }"
      >
        {{ item.supplier_type_coding }}
      </span>
      <span
        v-if="this.$route.name === 'SupplierTypeExcel'"
        :class="{
          'text-danger': item.reasons.some((reason) => reason === 'N750'),
          'text-warning': item.reasons.some((reason) => reason === 'N800'),
        }"
      >
        {{ item.supplier_type }}
      </span>
      <span
        v-if="this.$route.name === 'ClientTypeExcel'"
        :class="{
          'text-danger': item.reasons.some((reason) => reason === 'N750'),
          'text-warning': item.reasons.some((reason) => reason === 'N800'),
        }"
      >
        {{ item.client_type_coding }}
      </span>
      <span
        v-if="this.$route.name === 'ClientTypeExcel'"
        :class="{
          'text-danger': item.reasons.some((reason) => reason === 'N750'),
          'text-warning': item.reasons.some((reason) => reason === 'N800'),
        }"
      >
        {{ item.client_type }}
      </span>
      <span
        v-if="
          this.$route.name === 'ExcelClient' ||
          this.$route.name === 'ExcelSupplier'
        "
        :class="{
          'text-danger': item.reasons.some((reason) => reason === 'N750'),
          'text-warning': item.reasons.some((reason) => reason === 'N800'),
        }"
      >
        {{ item.name }}
      </span>
      <span
        v-if="
          this.$route.name === 'ExcelClient' ||
          this.$route.name === 'ExcelSupplier'
        "
        :class="{
          'text-danger': item.reasons.some((reason) => reason === 'P750'),
          'text-warning': item.reasons.some((reason) => reason === 'P800'),
        }"
      >
        {{ item.phone }}
      </span>
      <span
        v-if="
          this.$route.name === 'ExcelClient' ||
          this.$route.name === 'ExcelSupplier'
        "
        :class="{
          'text-danger': item.reasons.some((reason) => reason === 'E750'),
          'text-warning': item.reasons.some((reason) => reason === 'E800'),
        }"
      >
        {{ item.email }}
      </span>
      <span>
        <span
          :class="
            item.reasons.length > 0
              ? 'badge rounded-pill text-bg-danger'
              : 'badge rounded-pill text-bg-success'
          "
          >{{ item.reasons.length > 0 ? $t("invalid") : $t("valid") }}</span
        >
      </span>

      <span class="d-flex gap-2">
        <button
          type="button"
          class="btn btn-danger"
          @click="handleDelete(index)"
        >
          {{ $t("delete") }}</button
        ><button type="button" class="btn btn-info text-white">
          <!--          @click="handleDelete(item, index)"-->
          {{ $t("edit") }}
        </button>
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
