<template>
  <form action="#" class="book_offline" @submit.prevent="confirm_flight()">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-12">
          <div class="new_book">
            <h4 class="title">{{ $t("flight_offline_reservation") }}</h4>
          </div>
            <div class="row">
                <div class="col-lg-4 col-md-12 col-12">
                    
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="buttons">
            <button type="submit" class="btn PrimaryButton">
              {{ $t("save") }}
            </button>
            <button @click="newBook()" class="btn SecondButton">
              {{ $t("New Book") }}
            </button>
          </div>
          <div class="row details_reservation">
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="reservation_number">{{
                  $t("reservation_number")
                }}</label>
                <input
                  type="number"
                  name=""
                  id="reservation_number"
                  class="form-control"
                  v-model="reservation_number"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="reference number">{{
                  $t("reference number")
                }}</label>
                <input
                  type="number"
                  name=""
                  id="reference number"
                  class="form-control"
                  v-model="reference_number"
                />
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group">
                <label for="">{{ $t("reservation_status") }}</label>
                <Multiselect
                  v-model="statusValue"
                  :options="statuses.map((status) => status.id)"
                  :custom-label="
                    (opt) => statuses.find((x) => x.id == opt).title
                  "
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                >
                </Multiselect>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-group" v-if="statusValue == 0">
                <label for="">option date</label>
                <VueDatePicker
                  v-model="option_date"
                  enable-seconds
                  locale="en-US"
                  time-picker-inline
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group">
                <label for="">{{ $t("total cost of the rooms") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="totalCost"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group">
                <label for="">{{ $t("cost of the rooms is taxed") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="costAfterTax"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group">
                <label for="">{{ $t("tax value") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="taxCost"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group">
                <label for="">{{
                  $t("total selling price of the rooms")
                }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="totalSelling"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group">
                <label for="">{{ $t("sales price with tax") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="sellingAfterTax"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12">
              <div class="form-group">
                <label for="">{{ $t("tax value") }}</label>
                <input
                  type="number"
                  name=""
                  id=""
                  class="form-control"
                  v-model="taxSelling"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
// import "vue-datepicker-ui/lib/vuedatepickerui.css";
// import VueDatepickerUi from "vue-datepicker-ui";
// import axios from "axios";
// import moment from "moment";
// import Swal from "sweetalert2";
// import VueDatePicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "flight-offline",
  data() {
    return {
      statusValue: 1,
      statuses: [
        {
          id: 0,
          title: this.$t("initial"),
        },
        {
          id: 1,
          title: this.$t("pending"),
        },
        {
          id: 2,
          title: this.$t("completed"),
        },
        {
          id: 3,
          title: this.$t("edited"),
        },
        {
          id: 4,
          title: this.$t("canceled"),
        },
      ],
      option_date: null,
      tax: 0,
      costAfterTax: 0,
      taxCost: 0,
      costApply: true,
      sellingAfterTax: 0,
      taxSelling: 0,
      totalSelling: 0,
      sellingApply: true,
      reservation_number: "",
      reference_number: "",
      number_of_nights: 1,
    };
  },
  components: {
    Multiselect,
    // Datepicker: VueDatepickerUi,
    // VueDatePicker,
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_offline.scss";

.canvas {
  background-color: transparent;
  border-color: transparent;
  color: black;
}
.tax_details {
  width: 70%;
}
.tax-label {
  font-size: 0.8rem;
}
.table {
  .table-title {
    th {
      background-color: rgba(10, 144, 155, 0.09);
      color: #0b1427;
      padding: 20px;
      text-align: start;
      font-weight: 400;
      font-size: 16px;
    }
  }
  tbody {
    td {
      padding: 20px;
      .minus {
        background-color: red;
        border-radius: 50%;
        color: #fff;
        opacity: 0.6;
        transition: 0.3s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.multiselect__placeholder {
  font-size: 0.8rem;
}
.hidden {
  display: none;
}
</style>
