<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>{{$t("all_attachments_type")}}</h4>

        <div>
            <router-link to="/hotel/add_attachment_type" class="PrimaryButton btn w-100"
            ><i class="fa-solid fa-plus"></i>{{$t("add_new_attachment_type")}}</router-link
            >
      </div>
    </div>

    <div class="search-visa my-3">
        <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input type="text" class="form-control" :placeholder="$t('search...')" />
        </form>
    </div>

    <div class="row">
      <table class="table" id="SeasonTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in all_attachments.data" :key="index">
            <td> {{item.id}} </td>
            <td>{{ item.title }}</td>
            <td :data-label="$t('actions')">
 
                <router-link
                  class="btn edit_btn"
                  :title="$t('edit')"
                  :to="`/hotel/edit_attachment_type/${item.id}`"
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                </router-link>
   
                
                <!-- <router-link
                  class="btn edit_btn"
                  :title="$t('rate code')"
                  :to="`/visa/contract/${contract_id}/seasons/${season.id}/allRateCode`"
                >
                  <i class="fa-solid fa-water"></i>
                </router-link> -->

                <button
                  class="btn remove_btn"
                  type="button"
                  :title="$t('delete')"
                  @click="deleteAttachment(index)"
                >
                  <i class="fa-regular fa-trash-can"></i>
                </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="row">
      <pagination
        :data="all_attachments"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_amenties"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";

export default {
  name: "allAttachments",
  components: {
    pagination,
  },
  data() {
    return {
        all_attachments: []
    };
  },
  methods: {
    fetch_amenties(){
        axios.get("/amenity_types").then(({data})=>{
            this.all_attachments = data.data;
        })
    },
    // delete contract
    deleteAttachment(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.all_attachments.data[index].id;
          const fromClick = true;
          axios
            .delete(`/amenity_types/${delete_id}`, { fromClick })
            .then(({ data }) => {
               if (data.status == true) {
                 Swal.fire("", data.message, "success");
                 this.all_attachments.data.splice(index, 1); // Remove the deleted item from the array
              }  else {
                 Swal.fire("", data.message, "error");
               }
               return data;
            });
        }
      });
    },
  },
  mounted(){
    this.fetch_amenties();
  }
};
</script>

<style lang="scss" scoped>
.search-visa{
  .form-group{
      position: relative;
      .form-control{
          padding-right: 30px !important;
      }
      i{
          position: absolute;
          top: 10px;
          right: 10px;
      }
  }
}
</style>
