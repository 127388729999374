<template>
  <div class="success-card">
    <div class="success-book my-3">
      <img
        class="my-1"
        src="@/assets/media/image/icon/Interactions.png"
        alt="Success Image"
      />
      <h4 class="title-success-message tetx-center">
        {{ $t("sccess_booking") }}
      </h4>
      <p class="my-2">{{ $t("reservation_process") }}</p>
      <span class="confirm-number my-3"
        >{{ $t("Booking_confirmation") }} : #{{ confirmation_number }}</span
      >
    </div>

    <div class="container my-3 border-top border-bottom">
      <div class="row d-flex align-items-center justify-content-center">
        <div class="col-lg-6 my-2">
          <div class="w-100">
            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/user-name.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <h5 class="title m-0">{{ details.guest_name.length }} فرد</h5>
                <span class="text"
                  >باسم : <b>{{ userName }}</b>
                </span>
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/date.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <h5 class="title m-0">{{ $t("Arrival date") }}</h5>
                <span class="text">{{ check_in }}</span>
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/room.svg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="detail">
                <h5 class="title m-0">{{ $t("room") }}</h5>
                <span class="text"> {{ room_name }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="w-100">
            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/date.svg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="detail">
                <h5 class="title m-0">{{ $t("Check Out") }}</h5>
                <span class="text"> {{ check_out }} </span>
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/Hotel-icon.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <h5 class="title m-0">{{ $t("Hotel") }}</h5>
                <span class="text">{{ hotel_name }} </span>
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/night.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <h5 class="title m-0">{{ $t("number of nights") }}</h5>
                <span class="text"> {{ number_of_nights }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="total-book container">
      <div class="row total-book">
        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <span class="total">{{ $t("total price") }}</span>
          </div>

          <div class="col-4 text-center">
            <b class="total"> EGP {{ total_cost }}</b>
          </div>
        </div>

        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <span class="total"> {{ $t("Value added tax") }}</span>
          </div>
          <div class="col-4 text-center">
            <b class="total"> EGP {{ total_cost_with_tax - total_cost }}</b>
          </div>
        </div>

        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <span class="total">
              {{ $t("The total includes value added tax") }}
            </span>
          </div>
          <div class="col-4 text-center">
            <b class="total text-black"> EGP {{ total_cost_with_tax }}</b>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="btns my-2">
      <router-link to="/book_table" class="Detail-btn">{{
        $t("show_details")
      }}</router-link>

      <router-link to="/hotels/new" class="new-book">
        {{ $t("New Book") }}</router-link
      >
    </div>
    <!-- Buttons -->
  </div>
</template>

<script>
export default {
  name: "success_Hotel_Book",
  data() {
    return {
      details: {
        guest_name: [],
      },
      userName: {},
      check_in: "",
      check_out: "",
      hotel_name: "",
      total_cost_with_tax: 0,
      total_cost: 0,
      total_selling_price: 0,
      confirmation_number: "",
      fromDate: new Date(),
      toDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      number_of_nights: 1,
      room_type: "",
    };
  },
  mounted() {
    this.userName = JSON.parse(
      localStorage.getItem("newBook")
    ).guest_name[0].name;
    this.details = JSON.parse(localStorage.getItem("newBook"));
    this.check_in = this.details.check_in;
    this.check_out = this.details.check_out;
    this.hotel_name = this.details.hotel_name;
    this.total_cost_with_tax = this.details.total_cost_with_tax;
    this.total_cost = this.details.total_cost;
    this.total_selling_price = this.details.total_selling_price;
    this.confirmation_number = this.details.confirmation_number;
    this.room_type = this.details.room_type;
    // this.userName = this.details.name
    // console.log(this.details);
  },
  methods: {},
  watch: {
    toDate() {
      if (this.toDate) {
        const toDate = new Date(this.toDate);
        const fromDate = new Date(this.fromDate);
        var timeDiff = Math.abs(toDate.getTime() - fromDate.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
        this.number_of_nights = numberOfNights;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.success-book {
  width: 100%;
  margin: 0 auto;
  padding: 1.6rem 0;
  text-align: center;
  .title-success-message {
    font-size: 1.8rem;
    font-family: "bold";
    color: #0a909b;
  }

  .confirm-number {
    font-size: 1rem;
    font-family: "bold";
    color: #ababab;
  }
}

.contain-detail {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  width: 3rem;
  height: 3rem;
  background-color: rgba(10, 144, 155, 0.2);
  border-radius: 50%;
  text-align: center;
  line-height: 3.5rem;
  margin-inline-end: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.success-card[data-v-79d45e82] {
  min-width: 56%;
  width: 56%;
  max-width: fit-content;
  margin: 0 auto;
  border: 1px solid #9ca2ac !important;
  border-radius: 1rem;
  background-color: #fff;
}

.total {
  font-family: "medium";
  font-size: 1.2rem;
  color: #656b78;
}

.total-book {
  width: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
}

.btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.detail {
  .title {
    color: #9ca2ac;
    font-family: "bold";
    font-size: 1.2rem;
  }
  .text {
    color: #656b78;
    font-family: "regular";
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

.Detail-btn {
  border: 2px solid;
  padding: 1rem 3rem;
  border-radius: 10px;
  color: #fff;
  background: #0a909b;
}

.new-book {
  border: 2px solid #0a909b;
  padding: 1rem 3rem;
  border-radius: 10px;
  background: #fff;
  color: #0a909b;
}
</style>
