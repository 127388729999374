<template>
  <div class="repeated_reservation">
    <div class="row">
      <div class="col-lg-6 col-12">
        <ul>
          <li>
            <a href="#"> <i class="fa-solid fa-envelope"></i></a>
          </li>
          <li>
            <a href="#"> <i class="fa-solid fa-print"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa-brands fa-whatsapp"></i></a>
          </li>
        </ul>
      </div>
      <div class="col-lg-6 col-12">
        <div class="button">
          <router-link to="/contract/add" class="btn SecondButton">{{
            $t("restore_reservation")
          }}</router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="reservation_detials">
    <div class="return">
      <router-link to="/contract/add" class="">
        <i class="fa-solid fa-arrow-right"></i>
        {{ $t("back_reservation") }}</router-link
      >
    </div>
    <div class="main_header_style">
      <h4>
        {{ $t("reservation_detials") }} {{ hotel_name }} ({{ hotel_detials }})
      </h4>
      <span
        :class="[
          'badge',
          status === 0 ? 'bg-primary' : status === 1 ? 'bg-success' : '',
        ]"
      >
        {{
          status === 0
            ? $t("initial")
            : status === 1
            ? $t("complete_reservation")
            : ""
        }}
      </span>
    </div>
    <ul class="info">
      <li>
        <span>{{ $t("reservation_date") }} :</span> {{ reservation_date }}
      </li>
      <li>
        <span>{{ $t("reservation_number") }}</span> {{ reservation_number }}
      </li>
    </ul>
    <div>
      <router-link class="show" to="#">
        {{ $t("show_reservation") }}
      </router-link>
    </div>

    <div class="row">
      <!-- <h6>{{ $t("details_booking") }}</h6> -->
      <div class="col-lg-6 col-12">
        <div class="box_resrv">
          <div class="head">
            <h6>{{ $t("Departure flight") }}</h6>
            <router-link to="#"> {{ $t("edit_details_booking") }} </router-link>
          </div>
          <div class="row">
            <div class="col-lg-10 col-12">
              <p>
                {{ city1 }} <i class="fa-regular fa-compass"></i> {{ city2 }}
              </p>
              <ul class="info">
                <li>
                  <i class="fa-regular fa-clock"></i>
                  {{ esitmate_time }}
                </li>
                <li>
                  <i class="fa-regular fa-calendar"></i>
                  {{ reservation_date }}
                </li>
              </ul>
            </div>
            <div class="col-lg-2 col-12">
              <img
                class="q_code"
                src="@/assets/media/image/egyptair-logo.png"
                alt=""
              />
            </div>
            <div class="col-12">
              <div class="card_airport">
                <div class="time_card">
                  <h5>{{ start_time }}</h5>
                  <h6>{{ start_location }}</h6>
                </div>
                <div class="stop">
                  <h4>{{ $t("live") }}</h4>
                  <hr />
                  <h5>{{ esitmate_time }} {{ $t("hours") }}</h5>
                </div>
                <div class="time_card">
                  <h5>{{ end_time }}</h5>
                  <h6>{{ end_location }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="box_resrv">
          <h6>{{ $t("reservation_data") }}</h6>
          <ul>
            <li>
              <span>{{ $t("reservation_number") }} :</span>
              {{ reservation_number }}
            </li>
            <li>
              <span>{{ $t("booking_by") }} :</span> {{ reservation_by }}
            </li>
            <li>
              <span>{{ $t("client_name") }} :</span> {{ client_name }}
            </li>
            <li>
              <span>{{ $t("reservation_date") }} :</span> {{ reservation_date }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <div class="tarvel_bag">
          <h6>{{ $t("travel baggage") }}</h6>
          <div class="content">
            <div class="info">
              <div class="img">
                <img src="@/assets/media/image/ok.png" alt="" />
              </div>
              <div class="text">
                <p>7 كجم حقيبة الظهر</p>
                <span>1 حقيبة</span>
              </div>
            </div>
            <div class="info">
              <div class="img">
                <img src="@/assets/media/image/ok.png" alt="" />
              </div>
              <div class="text">
                <p>مجموع وزن أمتعة السفر 40 كغم</p>
                <span>2 حقيبة </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="client_detials">
    <div class="main_header_style">
      <h4>تفاصيل النزلاء ( 12 نزيل )</h4>
    </div>

    <div class="row">
      <table class="table" id="revenueTable">
        <thead>
          <tr>
            <th>{{ $t("guest_name") }}</th>
            <th>{{ $t("nationality") }}</th>
            <th>{{ $t("ID Number") }}</th>
            <th>{{ $t("phone") }}</th>
            <th>{{ $t("email") }}</th>
            <th>{{ $t("Image") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in revenue" :key="index">
            <td>{{ item.employee.name }}</td>
            <td>{{ index }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="add_recipt">
    <div class="main_header_style">
      <h4>{{ $t("payment_details") }}</h4>
      <span
        :class="[
          'badge',
          status === 0 ? 'bg-primary' : status === 1 ? 'bg-success' : '',
        ]"
      >
        {{ status === 0 ? $t("initial") : status === 1 ? $t("paid_ok") : "" }}
      </span>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table_first">
          <tr>
            <td>{{ $t("total_price_room_one_night") }}</td>
            <td>
              <strong> {{ price.room_price }} </strong>
            </td>
          </tr>
          <tr>
            <td>{{ price.room_name }}</td>
            <td>{{ price.room_price }}</td>
          </tr>
        </table>
      </div>
      <div class="col-lg-6 col-12">
        <table class="table table_second mt-3">
          <tr>
            <td>{{ $t("tax value") }}</td>
            <td class="text-start">
              <strong>{{ price.tax_value }}</strong>
            </td>
          </tr>
          <tr>
            <td>{{ $t("price with tax") }}</td>
            <td class="text-start">
              <strong>{{ price.price_with_tax }}</strong>
            </td>
          </tr>
          <tr>
            <td>{{ $t("price without tax") }}</td>
            <td class="text-start">
              <strong> {{ price.price_without_tax }} </strong>
            </td>
          </tr>
        </table>
        <img class="q_code" src="@/assets/media/image/qr-code.png" alt="" />
      </div>
      <div class="col-lg-6 col-12">
        <div class="total_price">
          <div>
            <h6>{{ $t("total_prices") }}</h6>
            <span>
              <strong>{{ price.total_price }}</strong></span
            >
          </div>
          <p>السعر يشمل الضريبة المفروضة بقيمة 20 ر.س ويشمل ضريبة البيع</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
export default {
  name: "reserve-detials",
  data() {
    return {
      hotel_name: "لفندق آمون",
      hotel_detials: " 4 بالغين , 5 ليالي , 3 غرف",
      status: 1,
      reservation_date: "22 مارس - 8:30 م",
      reservation_number: "1234",
      reservation_by: "شركة الصفوة",
      client_name: "حمد عبد الرحمن",
      esitmate_time: "2h 45 m",
      city1: "القاهرة",
      city2: "الدمام",
      start_time: "6:40 am",
      end_time: "9:20 am",
      start_location: "مطار القاهرة الدولى CAI",
      end_location: "مطار الملك خالد  DMM",
      revenue: [],
      price: {
        total_price_room: "20000 SAR",
        room_price: "20000 SAR",
        room_name: "2x غرفة ديلوكس ل 2 شخص بالغ فى الليلة",
        tax_value: "58 SAR",
        price_with_tax: "20000 SAR",
        price_without_tax: "18000 SAR",
        total_price: "20020 SAR",
      },
    };
  },
  methods: {
    // toggle menu

    // fetch revenue data
    FetchRevenue(page = 1) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/revenue_type?page=" + page).then(({ data }) => {
        this.revenue = data.data;
        console.log(this.revenue);
      });
    },
  },
  mounted() {
    this.FetchRevenue();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>
<style lang="scss" scoped>
@import "./_reservation.scss";
</style>
