<template>
  <section class="invoice invoice-ar">
    <!-- Head invoice -->
    <div class="head_invoice">
      <div class="book_status">
        <h6>فاتورة ضريبية</h6>
        <div class="chart"></div>
        <div class="chart"></div>
      </div>
      <div class="logo">
        <img :src="pills.organization?.default_image" />
      </div>
      <div class="book_number">
        <div class="chart"></div>
        <div class="chart"></div>
        <h6 v-if="route_name == 'newInvoiceOffline'">
          رقم الحجز : 
          <span>#{{ pills.book_number }}</span>
        </h6>
        <h6 v-else>رقم الفاتورة :{{pills.tax_invoice_number}}</h6>
      </div>
    </div>
    <!-- Head invoice -->

    <!-- client reservation data -->
    <dataInvoicePerson :pills="pills" /> 
    <!-- client reservation data -->

    <!-- Table reservation -->
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="frist">الفندق</th>
            <th class="frist">نوع الغرفة</th>
            <th scope="col">الوجبة</th>
            <th scope="col">عدد الأشخاص</th>
            <th scope="col">تاريخ الدخول</th>
            <th scope="col">تاريخ الخروج</th>
            <th scope="col">الليالي</th>
            <th scope="col">رقم التأكيد</th>
            <th scope="col">سعر الليلة</th>
            <th class="last">السعر الكلي</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in pills.rooms" :key="index">
            <td>{{room.hotel_name}}</td>
            <td>
              <p class="mb-0 fs-8">{{room.room_number}} X {{ room.room_name }}</p>
              <p class="mb-0 fs-8">{{ room.title_view }}</p>
            </td>
            <td>{{ room.meal_type?.title }}</td>
            <td>{{ room.guest_number }}</td>
            <td>
              <p class="mb-0">
                {{ room.check_in_date }}
              </p>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_in_date))
              }}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ room.check_out_date }}
              </P>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_out_date))
              }}</span>
            </td>
            <td>{{ room.number_of_nights }}</td>
            <td>{{ room.hotel_reference }}</td>
            <td>{{ (room.total_selling_price_room_without_tax / room.number_of_nights).toFixed(2) }}</td>
            <td class="last">
              {{ room.total_selling_price_room_without_tax }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Table reservation -->

    <!-- Table services -->
    <services :service="service" v-if="service.length" />
    <!-- Table services -->

    <!-- Terms & amount reservation -->
    <div class="amounts_terms">
      <!-- Terms -->
      <Terms :terms="pills?.Conditions" />
      <!-- Terms -->
  
      <TotalPrice :total_selling_price_with_out_tax="total_selling_price_with_out_tax" :selling_tax="selling_tax" :total_selling_price="total_selling_price" />
    </div>
    <!-- Terms & amount reservation -->

    <!-- Invoice Organization Data -->
    <div class="invoice_organiztion_data">
      <ul class="social_accounts">
        <li>
          <i class="fa-solid fa-globe"></i>
          <a :href="pills.organization?.website_link" target="_blank" class="text">{{ pills.organization?.website_link }}</a>
        </li>
        <li>
          <i class="fa-solid fa-envelope"></i>
          <a :href="`mailto:${pills.organization?.email}`" class="text">{{ pills.organization?.email }}</a>
        </li>
        <li>
          <i class="fa-solid fa-phone"></i>
          <a :href="`tel:${pills.organization?.phone}`" class="text">{{ pills.organization?.phone }}</a>
        </li>
        <li>
          <i class="fa-solid fa-location-dot"></i>
          <p class="text">{{ pills.organization?.address }}</p>
        </li>
      </ul>

      <qrCodeInvoice :qr_code="qr_code" />

      <div class="logo">
        <img :src="pills.organization?.default_image" />
        <employeeData :pills="pills" />
        <socialMedia :organization="pills.organization" />
      </div>
    </div>
    <!-- Invoice Organization Data -->

    <div class="footer_invoice"></div>
  </section>
</template>

<script>
import services from './component/servicesAR.vue';
import TotalPrice from './component/TotalPriceAr.vue';
import socialMedia from './component/socialMedia.vue';
import Terms from './component/termsAr.vue';
import qrCodeInvoice from './component/qrCodeInvoice.vue';
import dataInvoicePerson from './component/dataInvoicePersonAr.vue';
import employeeData from './component/employeeData.vue';

export default {
  name: "pill-hotel-confirm",
  data() {
    return {
      route_name: this.$route.name,
      organizationTaxNumber: "",
      organizationName: "",
      totalSellingPriceWithOutTax: "",
      sellingTax: "",
      totalSellingPrice: "",
    }
  },
  props: {
    pills: Object,
    service: {
      type: Array,
      required: true,
      default: () => []
    },
    qr_code: String,
    organization_name: String,
    organization_tax_number: String,
    terms: String,
    total_selling_price_with_out_tax: String,
    selling_tax: String,
    total_selling_price: String,
    book_status_name: String,
    tax_invoice_number: Number
  },
  components: {
    services,
    TotalPrice,
    socialMedia,
    Terms,
    qrCodeInvoice,
    dataInvoicePerson,
    employeeData
  },
  watch: {
    qr_code: {
      handler: 'handleOrganizationDataChange',
      immediate: true,
      deep: true
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
</style>
