<template>
  <section class="all_emp card_style">
    <div class="all-supp">
      <div class="row options mb-2">
        <div class="d-flex align-items-center gap-3">
          <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
          <h4>
            {{ $t("suppliers") }}
          </h4>
        </div>

        <div class="col-lg-8 col-md-12 col-12">
          <div class="form-group">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control w-50"
              :placeholder="$t('search by name')"
              v-model="supplierText"
              @keyup="FetchSuppliers((page = 1))"
            />
          </div>
        </div>

        <div class="col-lg-2 col-md-12 col-12">
          <div class="suppli">
            <router-link
              :to="{ name: 'addSuppliers', params: { page: 2 } }"
              class="PrimaryButton btn"
              ><i class="fa-solid fa-plus"></i>
              {{ $t("addsupplier") }}</router-link
            >
          </div>
        </div>

        <div class="col-lg-2 col-md-12 col-12">
          <div class="cli">
            <button
              class="SecondButton btn action d-flex align-items-center mx-2"
              @click="exportToExcel"
            >
              <i class="fa-solid fa-file-excel"></i>
              {{ $t("export_to_excel") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table suppliers" id="RoomTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("email") }}</th>
              <th>{{ $t("phone") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="suplier in 10" :key="suplier">
              <td v-for="suplier in 5" :key="suplier">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(supplier, index) in suppliers.data" :key="index">
              <td>{{ supplier.id }}</td>
              <td>{{ supplier.name }}</td>
              <td>{{ supplier.email }}</td>
              <td>{{ supplier.phone }}</td>
              <td>
                <router-link
                  class="btn btn-success mx-1 w-auto"
                  :to="`/suppliers/edit/${supplier.id}`"
                  :title="$t('edit')"
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>
                <router-link
                  class="btn btn-info mx-1 w-auto text-white"
                  :to="`/suppliers/details/${supplier.id}`"
                  :title="$t('show')"
                >
                  <i class="fa-solid fa-eye"></i>
                </router-link>

                <div
                  class="btn btn-danger mx-1 w-auto"
                  @click="deleteSupplier(supplier.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="suppliers"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchSuppliers"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
import * as XLSX from "xlsx/xlsx.mjs";
export default {
  name: "all-Suppliers",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      suppliers: [],
      supplierText: "",
      loading: true,
    };
  },
  methods: {
    async exportToExcel() {
      try {
        const response = await axios.post("/fetch_consumer_by_role", {
          role: 2,
          word: this.supplierText,
          // per_page: 10,
        });
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "Supliers.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    // fetch suppliers data
    FetchSuppliers(page = 1) {
      // const search = { word: this.supplier_name };
      axios
        .post(`/fetch_consumer_by_role?page=${page}`, {
          role: 2,
          word: this.supplierText,
          per_page: 10,
        })
        .then(({ data }) => {
          this.suppliers = data.data;
          this.loading = false;
        });
    },
    //delete suppliers
    deleteSupplier(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.suppliers[index].id;
          const fromClick = true;
          axios
            .delete(`/consumers/${id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.suppliers.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                console.log(fromClick);
                Swal.fire("", data.message, "error");
              }
              return data;
            })
            .catch((error) => {
              // console.log(fromClick)
              if (error.response.status != 403) {
                Swal.fire("", error.response.data.message, "error");
              }
            });
        }
      });
    },
  },
  mounted() {
    this.FetchSuppliers();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_suppliers";
.options form .form-control {
  padding-inline-start: 3rem !important;
}
</style>
