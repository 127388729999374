<template>
  <section class="card_style">
    <!-- skelton -->

    <div class="row" v-if="loading && $route.params.id">
      <div class="col-12">
        <Skeleton width="20%" class="mb-2"></Skeleton>
      </div>
      <div class="col-12 my-3" v-for="i in 2" :key="i">
        <Skeleton width="20%" class="mb-2"></Skeleton>
        <Skeleton width="60%" height="2rem" class="mb-2"></Skeleton>
      </div>
    </div>
    <!-- skelton -->
    <form class="form_topic" @submit.prevent="editSafe()" v-else>
      <div class="main_header_style">
        <h4>{{ $t("Edit Safe") }}</h4>
      </div>
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("Safe Name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('Write Safe Name')"
              v-model="title"
              required
            />
          </div>
        </div>

        <div class="col-12 col-lg-12">
          <div class="input-group">
            <label class="form-label"> {{ $t("Safe text") }}</label>
            <textarea
              type="text"
              class="form-control"
              :placeholder="$t('Write Safe text')"
              v-model="text"
              required
            ></textarea>
          </div>
        </div>
        <!-- finish input group -->

        <div class="col-12 mb-4 safe-status">
          <h4>{{ $t("Safe Status") }}</h4>
          <form class="row">
            <div class="form-check col-3 d-flex align-items-center">
              <input
                class="form-check-input mx-3"
                type="radio"
                name="status"
                v-model="active"
                value="1"
              />
              <label class="form-check-label">
                {{ $t("Active") }}
              </label>
            </div>
            <div class="form-check col-3 d-flex align-items-center">
              <input
                class="form-check-input mx-3"
                type="radio"
                name="status"
                v-model="active"
                value="0"
              />
              <label class="form-check-label">
                {{ $t("Not Active") }}
              </label>
            </div>
          </form>
        </div>
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "edit-safe",
  data() {
    return {
      title: "",
      loading: true,
      text: "",
      active: "",
      money: "",
      branch: "",
      type: 2,
    };
  },
  methods: {
    fetchSafeData() {
      let id = this.$route.params.id;
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get(`/organization_accounts/${id}`).then(({ data }) => {
        this.title = data.data.title;
        this.text = data.data.text;
        this.active = data.data.active;
        this.money = data.data.money;
        this.loading = false;
        // this.branch = data.data.branch;
        // console.log(data.data);
      });
    },
    async editSafe() {
      let id = this.$route.params.id;
      const params = new URLSearchParams();
      params.append("title", this.title);
      params.append("text", this.text);
      params.append("active", this.active);
      params.append("money", this.money);
      params.append("type", this.type);
      // params.append("branch", this.branch);
      axios
        .put(`/organization_accounts/${id}`, params)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/safe");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.fetchSafeData();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
  components: {
    Skeleton,
  },
};
</script>
<style lang="scss" scoped>
@import "./_safe";
</style>
