<template>
  <section class="card_style">
    <div class="main_header">
      <h2>{{$t("the transfers")}}</h2>

      <router-link
          :to="{ name: 'addTransfer' }"
          class="PrimaryButton btn"
          ><i class="fa-solid fa-plus"></i> {{ $t("New transfer") }}
      </router-link>
    </div>

    <div class="row">
      <table class="table" id="transferTable">
        <thead>
          <tr>
            <th>{{ $t("id") }}</th>
            <th>{{ $t("Transfer From") }}</th>
            <th>{{ $t("Transfer To") }}</th>
            <th>{{ $t("the amount") }}</th>
            <th>{{ $t("notes") }}</th>
            <th>{{ $t("date of transfer") }}</th>
            <th>{{ $t("actions") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(transfer, index) in allTransfers.data" :key="index">
            <td>{{transfer.id}}</td>
            <td>{{transfer.from?.title}}</td>
            <td><div v-for="(item , itemIndex) in transfer.to" :key="itemIndex">{{item?.account?.title}}</div></td>
            <td>{{transfer.from?.amount}}</td>
            <td>{{transfer.text}}</td>
            <td>{{dateFormate(transfer.date)}}</td>
            <td :data-label="$t('actions')">
              <router-link
                class="btn edit_btn"
                :title="$t('details')"
                :to="`/allTransfers/show/${transfer.id}`"
              >
                <i class="fa-solid fa-circle-exclamation"></i>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="allTransfers"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchAllTransfers"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import pagination from "laravel-vue-pagination";
import moment from "moment";

export default {
    name: "AllTransfers",
    components: {
      pagination,
    },
    data(){
      return{
        allTransfers:[],
      }
    },
    methods:{
      fetchAllTransfers(page=1){
        axios.post(`/fetch_transfer_accounts?page=${page}`).then(({data})=>{
          this.allTransfers = data.data;
        });
      },
      dateFormate(value){
        let date = moment(value).format('LL');
        return date;
      },
      timeFormate(value){
        let time = moment(value).format('LT');
        return time;
      },
    },
    created(){
      this.fetchAllTransfers();
    }
}
</script>

<style lang="scss" scoped>
@import "./_safe";
</style>