<template>
  <form @submit.prevent="submit">
    <div class="row">
      <h3 class="form-label mb-3">
        {{ $t("add") }} {{ $t("Private hotels") }}
      </h3>
      <div class="col-12 col-lg-6">
        <div class="input-group">
          <label class="form-label"> {{ $t("Private hotels") }}</label>
          <Multiselect
            v-model="hotelValues"
            :options="hotels"
            :clear-on-select="true"
            :placeholder="$t('Private hotels')"
            select-label=""
            :hide-selected="true"
            label="title"
            track-by="id"
            :multiple="true"
            @search-change="fetchHotelsByCityId"
          >
          </Multiselect>
          <span class="input-errors" v-if="v$.hotelValues.$error"
            >{{ v$.hotelValues.$errors[0].$message }}
          </span>
        </div>
      </div>
    </div>
    <button class="btn PrimaryButton">{{ $t("save") }}</button>
  </form>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "organizationHotelsAdd-page",
  data() {
    return {
      v$: useVuelidate(),
      hotels: [],
      hotelValues: "",
    };
  },
  components: {
    Multiselect,
  },
  validations() {
    return {
      hotelValues: {
        required,
      },
    };
  },
  methods: {
    fetchHotelsByCityId(search) {
      axios
        .post("/fetch_organization_hotels_in_city", {
          word: search,
        })
        .then(({ data }) => {
          this.hotels = data.data;
        });
    },
    submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const organizationHotelIds = this.hotelValues.map((hotel) => hotel.id);
        axios
          .post("/store_organization_hotels", {
            organization_hotel_ids: organizationHotelIds,
          })
          .then((res) => {
            console.log(res.data);
          });
      }
    },
  },
  mounted() {
    this.fetchHotelsByCityId();
  },
};
</script>
