<template>
  <section class="show_receipt card_style">
    <div class="row options mb-2">
      <div class="col-4">
        <div class="main_header_style">
          <h4>{{ $t("nationalities") }}</h4>
        </div>
      </div>
      <form class="col-8 form-grouo">
         <i class="fa-solid fa-magnifying-glass"></i>
        <input
          class="form-control"
          type="text"
          :placeholder="$t('search by name')"
          v-model="titlenation"
          @keyup="FetchNation"
        />
      </form>

<!--      <div class="col-6">-->
<!--        <router-link-->
<!--          :to="{ name: 'nationalityAdd' }"-->
<!--          class="PrimaryButton btn w-50"-->
<!--          ><i class="fa-solid fa-plus"></i> {{ $t("add_nation") }}</router-link-->
<!--        >-->
<!--      </div>-->
    </div>
    <div class="row">
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("name_en") }}</th>
            <!-- <th>{{ $t("number") }}</th> -->

            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in nationalities.data" :key="index">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>

            <td>
              <button
                  @click="changeStatus(item.id)"
                  v-tooltip.top="$t('status')"
                  :class="`btn ${
                  item.is_nationality_favorite == 1 ? 'btn-warning' : 'btn-secondary'
                }`"
              >
                <i class="fa-solid fa-star text-white"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="nationalities"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchNation"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
export default {
  name: "show-nation",
  components: {
    pagination,
  },
  data() {
    return {
      nationalities: [],
      titlenation: "",
    };
  },
  methods: {
    // fetch nationalities data
    FetchNation(page = 1) {
      const search = { word: this.titlenation };
      axios
        .post(`/fetch_custom_countries?page=${page}`, search)
        .then(({ data }) => {
          this.nationalities = data.data;
        });
    },

    changeStatus(nationality_id) {
      this.loading = true;
      axios
          .post(`/store_favorites_nationality`, { nationality_id: nationality_id })
          .then(() => {
            Swal.fire(this.$t("change status"), "", "success");
            this.FetchNation();
            this.loading = false;
          });
    },
  },
  mounted() {
    this.FetchNation();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style lang="scss" scoped>
@import "./_nationality.scss";
</style>
