<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Add New Country") }}</h4>
    </div>

    <div class="row">
      <form @submit.prevent="addCountry()">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
              <label for="">{{ $t("title") }}</label>
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      {{ $t("title") }}
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body">
                      <div
                        class="form-group"
                        v-for="(country, index) in Form.title"
                        :key="index"
                      >
                        <label for="name"
                          >{{ $t("title") }} ({{ country.lang }})</label
                        >
                        <input
                          type="text"
                          :placeholder="$t('name')"
                          class="form-control"
                          :id="`name${index}`"
                          v-model="country.title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <span
                class="text-danger"
                v-if="v$.Form.title.$error"
                v-text="v$.Form.title.$errors[0].$message"
              ></span> -->
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="form-group">
              <label for="code">{{ $t("Country code") }}</label>
              <input
                type="text"
                class="form-control mt-2"
                id="code"
                :placeholder="$t('Add Country Code')"
                v-model="code"
              />
            </div>
          </div>
        </div>
        <button type="submit" class="add btn PrimaryButton">
          {{ $t("add") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "addCountry",
  data() {
    return {
      title: "",
      code: "",
      Form: {
        title: [],
      },
      languages: ["ar", "en", "id"],
    };
  },
  methods: {
    setCountryTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          lang: language,
          name: "",
        });
      });
    },
    addCountry() {
      const formData = new FormData();
      this.Form.title.forEach((ele) => {
        formData.append(`title_${ele.lang}`, ele.title);
      });
      formData.append(`code`, this.code);

      axios
        .post("/countries", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/countries");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    this.setCountryTitle();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
