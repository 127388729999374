<template>
  <navbar />
  <router-view></router-view>
</template>

<script>
import navbar from "@/view/setup/navbar.vue";

require("@/assets/css/main.css");

export default {
  name: "dashboard-page",
  components: {
    navbar,
  },
  // created() {
  //   localStorage.removeItem("flightData");
  //   localStorage.removeItem("flightTrips");
  //   localStorage.removeItem("bookDataFlight");
  //   localStorage.removeItem("bookDetails");
  //   localStorage.removeItem("hotelData");
  //   localStorage.removeItem("bookData");
  // }
};
</script>