<template>
  <div class="main-head">
    <h4>{{ $t("reservation_detials") }}</h4>
  </div>
  <div class="card_details_hotel">
    <div class="photo_img">
      <img
        v-if="hotelsDetials.hotels !== null"
        :src="hotelsDetials.hotels.image"
        alt=""
      />
      <img v-else src="@/assets/media/image/placeholder.png" alt="" />
    </div>
    <div class="details_hotel w-100">
      <div class="adress-rete">
        <div
          class="adress d-flex align-items-center justify-content-between w-100"
        >
          <h4
            @click="
              $router.push('/hotel/details/' + hotelsDetials?.hotels?.id + '')
            "
          >
            {{ hotelsDetials?.hotels?.title }}
          </h4>
          <div class="d-flex align-items-center gap-3">
            <span
              :class="[
                'badge mx-1',
                hotelsDetials?.status === 0
                  ? 'bg-primary'
                  : hotelsDetials?.status === 1
                  ? 'bg-warning text-dark'
                  : hotelsDetials?.status === 2
                  ? 'bg-success'
                  : hotelsDetials?.status === 3
                  ? 'bg-info text-dark'
                  : hotelsDetials?.status === 4
                  ? 'bg-danger'
                  : '',
              ]"
            >
              {{
                hotelsDetials?.status === 0
                  ? $t("initial")
                  : hotelsDetials?.status === 1
                  ? $t("pending")
                  : hotelsDetials?.status === 2
                  ? $t("completed")
                  : hotelsDetials?.status === 3
                  ? $t("edited")
                  : hotelsDetials?.status === 4
                  ? $t("canceled")
                  : ""
              }}
            </span>
            <!-- <span
              :class="[
                'badge mx-1',

                hotelsDetials.payment_status === '0' ||
                hotelsDetials.payment_status === '3'
                  ? 'bg-warning text-dark'
                  : hotelsDetials.payment_status === '4' ||
                    hotelsDetials.payment_status === '1' ||
                    hotelsDetials.payment_status === '6'
                  ? 'bg-success'
                  : hotelsDetials.payment_status === '5' ||
                    hotelsDetials.payment_status === '2'
                  ? 'bg-danger'
                  : '',
              ]"
            >
              {{
                hotelsDetials.payment_status === "0"
                  ? $t("Pending_payment")
                  : hotelsDetials.payment_status === "1"
                  ? $t("paid_ok")
                  : hotelsDetials.payment_status === "2"
                  ? $t("Decline_payment")
                  : hotelsDetials.payment_status === "3"
                  ? $t("REFUND PENDING")
                  : hotelsDetials.payment_status === "4"
                  ? $t("REFUND Accept")
                  : hotelsDetials.payment_status === "5"
                  ? $t("REFUND Decline")
                  : hotelsDetials.payment_status === "6"
                  ? $t("payment sent")
                  : ""
              }}
            </span> -->
          </div>
        </div>
      </div>
      <div class="egy">
        <h4>
          {{
            hotelsDetials?.hotels?.address == ""
              ? hotelsDetials?.hotels?.country +
                "," +
                hotelsDetials?.hotels?.city
              : hotelsDetials?.hotels?.address
          }}
        </h4>
      </div>
      <div class="all-text">
        <h4>
          <i class="fa-solid fa-people-group"></i
          ><span> {{ $t("number of person") }} :</span>
          {{ hotelsDetials.guests.length }}
        </h4>
        <!-- <h4>
          <i class="fa-solid fa-child"></i>
          <span> {{ $t("Children") }} :</span>
          {{ hotelsDetials.children }}
        </h4> -->
        <h4>
          <i class="fa-solid fa-calendar"></i
          ><span> {{ $t("date of arrival") }} :</span>
          {{ hotelsDetials.check_in }}
        </h4>
        <h4>
          <i class="fa-solid fa-calendar"></i
          ><span> {{ $t("Departure Date") }} :</span>
          {{ hotelsDetials.check_out }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detials-reservation",
  props: { hotelsDetials: Object },
};
</script>

<style lang="scss" scoped>
@import url("../../view/dashboard/hotels/_singleHotelBooking.scss");
</style>
