<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Add New City") }}</h4>
    </div>

    <div class="row">
      <form @submit.prevent="addCity()">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-group">
            <label for="country">{{ $t("choose country") }}</label>
            <Multiselect
              v-model="countryValue"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              class="mt-2"
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-12" v-for="(title, index) in titles" :key="index">
            <div class="form-group">
              <label :for="`name${index}`">{{ $t("Add City") }} {{ title.lang }}</label>
              <input
                type="text"
                class="form-control mt-2"
                :id="`name${index}`"
                v-model="title.title"
                :placeholder="$t('Add City')"
              />
            </div>
          </div>
        </div>

        <button type="submit" class="add btn PrimaryButton">
          {{ $t("add") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "addCity",
  components: { Multiselect },
  data() {
    return {
      countryValue: "",
      countries: [],
      title_id: "",
      title_ar: "",
      title_en: "",
      titles: [],
      languages: ["ar", "en", "id"],
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    setTitlesLang(){
      this.languages.forEach((ele)=>{
        this.titles.push({
          lang: ele,
          title: ""
        })
      })
    },
    addCity() {
      const formData = new FormData();
      this.titles.forEach((title)=>{
        formData.append(`title_${title.lang}`, title.title);
      })
      formData.append("country_id", this.countryValue);
      axios
        .post(`/cities`, formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/cities");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetch_country();
    this.setTitlesLang();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
