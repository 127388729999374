<template>
  <div class="services">
    <div class="">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="d-flex align-items-center md-flex-wrap suolier">
            <div class="col-lg-6 col-12">
              <div class="service_tupe px-2">
                <h6 class="text-book">{{ $t("services") }}</h6>
                <Multiselect
                  v-model="serviceObj"
                  :options="services"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                  :disabled="
                    booking_portal_type === 2
                      ? true
                      : booking_portal_type === 1
                        ? true
                        : booking_portal_type === 3
                          ? true
                          : false
                  "
                ></Multiselect>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="service_tupe px-2">
                <h6 class="text-book">{{ $t("supplier") }}</h6>
                <Multiselect
                  v-model="providerObj"
                  :options="providers"
                  label="name"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose')"
                  select-label=""
                  :hide-selected="true"
                  :custom-label="formatSupplierLabel"
                  :disabled="
                    booking_portal_type === 2
                      ? true
                      : booking_portal_type === 1
                        ? true
                        : booking_portal_type === 3
                          ? true
                          : false
                  "
                ></Multiselect>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-start suolier mt-3">
            <div class="col-lg-4 col-12 px-2">
              <h6 class="mb-0">{{ $t("count") }}</h6>
              <input
                type="number"
                min="1"
                v-model="count"
                class="form-control"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              />
              <span class="text-danger fs-12" v-if="count < 1">{{
                $t("count must be greater than 0")
              }}</span>
            </div>
            <div class="col-lg-4 col-12 px-2">
              <h6 class="mb-0">{{ $t("cost") }}</h6>
              <input
                type="number"
                v-model="cost"
                min="0"
                required
                class="form-control p-invaild"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              />
            </div>
            <div class="col-lg-4 col-12 px-2">
              <h6 class="mb-0">{{ $t("price") }}</h6>
              <input
                type="number"
                v-model="price"
                min="0"
                required
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
                class="form-control"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="form-group w-100">
            <!-- write input description -->

            <label class="form-label">{{ $t("description") }}</label>
            <textarea
              class="form-control"
              v-model="description"
              :disabled="
                booking_portal_type === 2
                  ? true
                  : booking_portal_type === 1
                    ? true
                    : booking_portal_type === 3
                      ? true
                      : false
              "
            ></textarea>
          </div>
        </div>
        <div class="col-lg-2 col-12">
          <div
            class="d-flex justify-content-between align-items-center gap-3 flex-column"
          >
            <div
              class="d-flex align-items-center justify-content-start text-center"
            >
              <InputSwitch
                v-model="checked"
                :disabled="
                  booking_portal_type === 2
                    ? true
                    : booking_portal_type === 1
                      ? true
                      : booking_portal_type === 3
                        ? true
                        : false
                "
              />
              <p class="mx-2 mb-0">{{ $t("Taxes included") }}</p>
            </div>
            <div class="col-lg-4">
              <div
                class="d-flex align-items-center justify-content-start text-center"
              >
                <button
                  class="sumbit-btn btn"
                  :disabled="
                    bookStatus == 3 || booking_portal_type === 2
                      ? true
                      : booking_portal_type === 1
                        ? true
                        : booking_portal_type === 3
                          ? true
                          : false
                  "
                  @click.prevent="add_services"
                >
                  {{ $t("add") }}
                </button>
                <!-- <button class="SecondButton" @click.prevent="add_Row_table">
                  <i class="fa-solid fa-plus"></i>
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row table-responsive">
        <table class="table bg-light">
          <thead>
            <tr>
              <th>{{ $t("service_name") }}</th>
              <th>{{ $t("count") }}</th>
              <th>{{ $t("supplier") }}</th>
              <th>{{ $t("cost") }}</th>
              <th>{{ $t("price") }}</th>
              <th>{{ $t("description") }}</th>
              <th>{{ $t("status") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in offlineServices" :key="index" class=" ">
              <td>
                <div class="border-1 border-secondary p-2 my-2 border-end">
                  {{ item.service?.title ? item.service?.title : "-" }}
                </div>
              </td>
              <td>
                <div class="border-1 border-secondary p-2 my-2">
                  {{ item.service_count }}
                </div>
              </td>
              <td>
                <div class="border-1 border-secondary p-2 my-2">
                  {{ item.supplier?.name ? item.supplier?.name : "-" }}
                </div>
              </td>
              <td>
                <div class="border-1 border-secondary p-2 my-2">
                  {{ item.cost ? item.cost : "-" }}
                </div>
              </td>
              <td>
                <div class="border-1 border-secondary p-2 my-2">
                  {{ item.selling ? item.selling : "-" }}
                </div>
              </td>
              <td>
                <div class="border-1 border-secondary p-2 my-2">
                  {{
                    item.notes && item.notes.length > 10
                      ? item.notes.slice(0, 130) + "..."
                      : item.notes
                        ? item.notes
                        : "-"
                  }}
                </div>
              </td>
              <td
                style="width: 12%"
                :class="`${
                  item.with_tax == '1' ? 'text-success' : 'text-danger'
                }`"
              >
                <div
                  class="border-1 border-secondary p-2 my-2"
                  style="width: 100%"
                >
                  {{
                    item.with_tax == "1"
                      ? $t("Taxes included")
                      : $t("Taxes not included")
                  }}
                </div>
              </td>
              <!-- add delete btn -->
              <td class="text-center">
                <div class="border-1 border-secondary p-2 my-2 border-start">
                  <i
                    style="cursor: pointer"
                    class="fa-solid fa-trash text-danger"
                    @click.prevent="DeleteAlert(index, item.id)"
                  ></i>
                </div>
              </td>
              <!-- end delete btn -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Multiselect } from "vue-multiselect";

import InputSwitch from "primevue/inputswitch";
import Swal from "sweetalert2";

export default {
  props: ["id", "offlineServices", "bookStatus", "booking_portal_type"],
  emits: ["serviceAdded"],
  data() {
    return {
      service_table: [],
      providers: [],
      services: [],
      serviceShow: [],
      serviceObj: "",
      providerObj: "",

      Form: {
        subtitle: [],
      },
      checked: true,
      cost: null,
      price: null,
      description: "",
      count: 1,
      status: null,
      bookId: this.$route.params.id,
    };
  },
  components: {
    Multiselect,
    InputSwitch,
  },
  methods: {
    DeleteAlert(index, id) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios
            .delete("/offline_booking_hotel_service/" + `${id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.$emit("serviceAdded");
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },

    fetch_services() {
      axios.post("/fetch_additional_services").then(({ data }) => {
        this.services = data.data.data;
      });
    },
    FetchSuppliers() {
      axios.post("/fetch_consumer_by_role", { role: 2 }).then(({ data }) => {
        this.providers = data.data.data;
      });
    },
    formatSupplierLabel(supplier) {
      return `${supplier.name} (${supplier.id})`;
    },
    add_services() {
      // offline_booking_hotel_service

      const formData = new FormData();
      formData.append("supplier_id", this.providerObj.id);
      formData.append("additional_service_id", this.serviceObj.id);
      formData.append("offline_booking_hotel_id", this.id);
      formData.append("cost", this.cost);
      formData.append("selling", this.price);
      formData.append("notes", this.description);
      formData.append("status", this.status ? "1" : "0");
      formData.append("with_tax", this.checked ? "1" : "0");
      formData.append("service_count", this.count);

      if (
        this.cost > 0 ||
        this.price > 0 ||
        this.count > 0 ||
        this.providerObj ||
        this.serviceObj
      ) {
        axios
          .post("/offline_booking_hotel_service", formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.serviceShow.push(data.data);
            this.cost = 0;
            this.price = 0;
            this.description = "";
            this.count = 1;
            this.providerObj = "";
            this.serviceObj = "";
            this.checked = false;
            this.status = null;
            this.$emit("serviceAdded");
            // this.fetch_table();
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
        // this.add_Row_table();
      }
    },
  },
  mounted() {
    this.fetch_services();
    this.FetchSuppliers();
  },
};
</script>

<style lang="scss" scoped>
@import "@/view/dashboard/hotelsBookOffline/hotelsBookOffline.scss";
.btns {
  height: 16vh;
}
@media screen and (max-width: 768px) {
  .suolier {
    flex-direction: column !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .suolier {
    flex-direction: column !important;
  }
}
@media screen and (min-width: 1024px) {
  .desc {
    margin-right: 10rem;
  }
}
textarea {
  width: 100%;
  height: 11vh;
  resize: vertical; /* Enable both horizontal and vertical resizing */
  overflow: auto; /* Add a scrollbar if content overflows */
  padding: 0.5rem;
}
.table-responsive {
  margin-top: 20px;
}
.table tbody tr td {
  padding: 0;
  font-family: "regular";
  font-size: 0.9rem;
  border-bottom: unset !important;
  border-top: unset !important;
  color: black;
  padding-inline-start: 0 !important;
  width: 10% !important;
  background: #fff !important;
  padding-bottom: 0.4rem !important;
  padding-top: 0.5rem !important;
  border-radius: 10px;
}

.border-1 {
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin: 0rem !important;
  width: 100%;
}
</style>
