  <template>
    <lottie-player
      :src="lottiePlayer"
      background="transparent"
      speed="2"
      loop
      autoplay
    ></lottie-player>
  </template>

  <script>
  export default {
    name: "loader-component",
    data() {
      return {
        lottiePlayer: require("@/assets/lottiefiles/flight_loader.json")
      }
    }
  };
  </script>

  <style scoped>
  lottie-player {
    width: 500px;
    height: 500px;
  }
  </style>
