<template>
<!-- Start csustom_organization_1 -->
<div class="p-4 ">
  <h4 class="title">{{$t("cutom_organization")}}</h4>
  <span class="text">   {{   $t("cutom_organization_text")  }}   </span>
</div>
<!-- End csustom_organization_1 -->

<!-- Start Buttons -->
<div class="d-flex align-items-center justify-content-around  my-3  ">
              <div  v-for="(customization,index) in customizations" :key="index" class="form-check mt-0">
                <div class="input-group">
                  <label >
                    <input
                      class="form-control"
                      type="checkbox"

                    />
                    <div class="contents d-flex align-items-center">
                     <img :src="customization.img" width="40" height="40"  class="mx-2">
                       <span class="label-span">{{ $t(customization.title) }}</span>
                    </div>
                  </label>
                </div>
              </div>

</div>
<!-- End buttons -->


<!-- Start csustom_organization_2 -->

<div class="p-4 my-4">

<div class="d-flex align-items-center justify-content-between" >
  <h4 class="title-two">{{  $t("custom_organization_title2") }}</h4>
 <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
  <label class="form-check-label" style="color: gray" for="flexCheckDefault">
   تحديد الكل
  </label>
</div>
</div>

<!-- Start End check service  -->
<div class="d-flex align-items-center justify-content-around my-3">

<div class="input-group-services">
                  <label >
                    <input
                      class="form-control"
                      type="checkbox"

                    />
                    <div class="contents d-flex align-items-center">
                       <span class="label-span">{{$t("custom_organization_2_button_text")}}</span>
                    </div>
                  </label>
</div>



<div class="input-group-services">
                  <label >
                    <input
                      class="form-control"
                      type="checkbox"

                    />
                    <div class="contents d-flex align-items-center">
                       <span class="label-span">{{$t("custom_organization_2_button_text")}}</span>
                    </div>
                  </label>
</div>


<div class="input-group-services">
                  <label >
                    <input
                      class="form-control"
                      type="checkbox"

                    />
                    <div class="contents d-flex align-items-center">
                       <span class="label-span">{{$t("custom_organization_2_button_text")}}</span>
                    </div>
                  </label>
</div>


<div class="input-group-services">
                  <label >
                    <input
                      class="form-control"
                      type="checkbox"

                    />
                    <div class="contents d-flex align-items-center">
                       <span class="label-span">{{$t("custom_organization_2_button_text")}}</span>
                    </div>
                  </label>
</div>



</div>
<!-- End check service -->

<select class="form-select w-25 my-4"  aria-label="Default select example">
  <option selected >
    
{{$t("cstom_organization_2_select")}}

  </option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>




<!-- Start Buttons -->

<div class="d-flex align-items-center justify-content-around my-3" v-for="(i,index ) in 4" :key="index">

<div class="input-group-services">
                  <label >
                    <input
                      class="form-control"
                      type="checkbox"

                    />
                    <div class="contents d-flex align-items-center">
                       <span class="label-span">{{$t("custom_organization_button_text")}}</span>
                    </div>
                  </label>
</div>



<div class="input-group-services">
                  <label >
                    <input
                      class="form-control"
                      type="checkbox"

                    />
                    <div class="contents d-flex align-items-center">
                       <span class="label-span">{{$t("custom_organization_button_text")}}</span>
                    </div>
                  </label>
</div>


<div class="input-group-services">
                  <label >
                    <input
                      class="form-control"
                      type="checkbox"

                    />
                    <div class="contents d-flex align-items-center">
                       <span class="label-span">{{$t("custom_organization_button_text")}}</span>
                    </div>
                  </label>
</div>


<div class="input-group-services">
                  <label >
                    <input
                      class="form-control"
                      type="checkbox"

                    />
                    <div class="contents d-flex align-items-center">
                       <span class="label-span">{{$t("custom_organization_button_text")}}</span>
                    </div>
                  </label>
</div>



</div>






<div class="p-4 ">
  <h4 class="title-two">{{$t("csum_organization_title3")}}</h4>
</div>


<div class="d-flex align-items-center justify-content-between">
  <select  class="form-select form-select-lg mb-3 w-25" aria-label=".form-select-lg example">
  <option selected class="text-start">Expedia</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>


<select  class="form-select form-select-lg mb-3 w-25" aria-label=".form-select-lg example">
  <option selected class="text-start">اختر</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</div>

<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="vuesax/linear/info-circle">
<g id="info-circle">
<path id="Vector" d="M7.99967 1.33268C11.6663 1.33268 14.6663 4.33268 14.6663 7.99935C14.6663 11.666 11.6663 14.666 7.99967 14.666C4.33301 14.666 1.33301 11.666 1.33301 7.99935C1.33301 4.33268 4.33301 1.33268 7.99967 1.33268Z" stroke="#0A909B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M8 10.666V7.33268" stroke="#0A909B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_3" d="M7.99609 5.33398H8.00208" stroke="#0A909B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</g>
</svg>
<span class="mx-1">

{{$t("custom_select_text")}}

</span>


<!-- Start selected  -->
<div class="my-4">
 
<div class="form-check d-flex align-items-center justify-content-around  my-4"          style="width:20%;">
  <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
  <label class="form-check-label" for="defaultCheck1">
   {{$t("Get_website")}}
  </label>
</div>
 
<div class="form-check d-flex align-items-center justify-content-around my-4          " style="width:20%;">
  <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
  <label class="form-check-label" for="defaultCheck1">
   {{$t("Get_mobile_application")}}
  </label>
</div>
 
<div class="form-check d-flex align-items-center justify-content-around my-4     " style="width:20%;">
  <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
  <label class="form-check-label" for="defaultCheck1">
   {{$t("Possibility_offline_booking")}}
  </label>
</div>
<button type="button" class="save-btn">
  

  {{ $t("save_data") }}
</button>




</div>
<!-- End selected  -->
</div>
<!-- End csustom_organization_2-->

</template>

<script>
export default {
  name: "set-Organizationprovider",
data() {
  return {
      customizations:[
        {
          title:"custom_btn1_text",
          img:require("../../assets/media/image/icon/airplane.svg")
        },
        {
          title:"custom_btn2_text",
          img:require("../../assets/media/image/icon/airplane.svg")
        },
        {
          title:"custom_btn3_text",
          img:require("../../assets/media/image/icon/airplane.svg")
        },
        {
          title:"custom_btn4_text",
          img:require("../../assets/media/image/icon/tree.svg")
        },
      ]
  }


}
}
</script>

<style lang="scss" scoped>

.title {
  font-size: 2rem;
  font-family: "bold";
  color: #0B1427;
}
.text{
  font-size: 1.2rem;
  font-family: "regular";
  color: #0B1427;
}


  .title-two{
    color: var(--txt1, #0B1427);
    text-align: right;
    font-family: 'bold';
    font-size: 1.25rem;
  }






.label-odd{
    background-color: rgba($color: #0A909B, $alpha: 0.1);
    color:#0A909B;


}

.label:nth-child(even){
    background-color: rgba($color: #fff, $alpha: 0.1);
  border: 1px solid var(--txt3, #9CA2AC);
    color: #9CA2AC;


}


   
.label-serve {
  width: 8.75rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.1875rem;
  cursor: pointer;
  border: 1px solid var(--txt3, #9CA2AC);
}



.save-btn{
  width: 16.3125rem;
  height: 3.75rem;
  border-radius: .25rem;
  background: #0A909B;
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  cursor: pointer;
  font-family: 'bold';
  border: none;
  outline: none;
}




// Style Check box

  input[type="checkbox"]:checked + .contents {
    background-color: rgba($color: #0A909B, $alpha: 0.1);
   border-color: transparent;
  }

  
.contents.d-flex.align-items-center {
    flex-direction: row !important;
    display: flex;
}



  input[type="checkbox"]:checked + .contents span {
       color: #0A909B;
  }

  .input-group {
   
    cursor: pointer;

    label {
      border: solid 1px #9CA2AC;
      border-radius: .75rem;
      position: relative;
      cursor: pointer;
      width: 100%;
      transition: 0.25s all ease-in-out;

      .contents {
        display: flex;
        padding: 5% 0;
        width: 100%;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        transition: 0.25s all ease-in-out;
        border-radius: .75rem;


      }

      input[type="checkbox"] {
        position: absolute;
        left: 0;
        height: 90%;
        width: 100%;
        opacity: 0;
        z-index: 9999;
        display: none;
      }

      input[type="checkbox"]:checked + label {
        background-color: #007bff;
        border:1px solid transparent;
        color: #fff;
      }
    }
  }

  .form-check.mt-0 {
    width: 25%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}


 .input-group-services {
    width: 10%;
    cursor: pointer;

    label {
      border: solid 1px #9CA2AC;
      border-radius: 19px;
      margin-bottom: 3%;
      position: relative;
      cursor: pointer;
      width: 100%;
      margin-inline-end: 3%;
      transition: 0.25s all ease-in-out;

      .contents {
        display: flex;
        padding: 3%;
        width: 100%;
        justify-content: center;
        cursor: pointer;
        align-items: center;
        flex-direction: column;
        transition: 0.25s all ease-in-out;
        border-radius: 19px;

 
      }

      input[type="checkbox"] {
        position: absolute;
        left: 0;
        height: 90%;
        width: 100%;
        opacity: 0;
        z-index: 9999;
        display: none;
      }

      input[type="checkbox"]:checked + label {
        background-color: #007bff;
        border:1px solid transparent;
        color: #fff;
      }
    }
  }
</style>

