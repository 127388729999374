<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>{{ $t("The Facilities") }}</h4>
    </div>

    <div class="row options mb-5">
      <form class="col-6">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input type="text" :placeholder="$t('search by name')" />
      </form>

      <div class="col-6 d-flex justify-content-end">
        <button
          class="PrimaryButton btn w-50"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasBottom"
          aria-controls="offcanvasBottom"
        >
          <i class="fa-solid fa-plus"></i> {{ $t("New Facility") }}
        </button>
      </div>
      <addFacilities @fetch-data="reloadData" />
    </div>

    <div class="row">
      <table class="table" id="facilityTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("title") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(facility, index) in facilities.data" :key="index">
            <td>{{ facility.id }}</td>
            <td>{{ facility.title }}</td>
            <td>
              <router-link
                :to="`/facilities/edit/${facility.id}`"
                class="btn btn-success text-white mx-1"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1"
                @click="deleteFacility(facility.id, index)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="facilities"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchfacilities"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import addFacilities from "../../../../components/room/addFacilities.vue";

export default {
  name: "allBeds",
  components: {
    pagination,
    addFacilities,
  },
  data() {
    return {
      facilities: [],
    };
  },
  methods: {
    fetchfacilities(page = 1) {
      axios
        .post(`/fetch_features?page=${page}`, { count: 10 })
        .then(({ data }) => {
          // console.log(data.data.data)
          this.facilities = data.data;
          // console.log(this.facilities.data)
        });
    },
    deleteFacility(facilityId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios
            .delete(`/features/${facilityId}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.facilities.data.splice(index, 1);
              } else {
                Swal.fire("", data.message, "error");
              }
            })
            .catch((error) => {
              if(error.response.status != 403){
                Swal.fire("", error.message, "error");
              }
            });
        }
      });
    },
    reloadData() {
      this.fetchfacilities();
    },
  },
  created() {
    this.fetchfacilities();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "../_room";
</style>
