<template>
  <div class="card_style">
    <div class="row">
      <div class="col-lg-6 col-12 d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
        <h4 class="mx-2">
          {{ $t("Room View") }}
        </h4>
      </div>
      <div class="col-lg-6 col-12 d-flex justify-content-end">
        <router-link to="/add/roomViewGlobal" class="PrimaryButton btn w-25">
          <i class="fa-solid fa-plus"></i>
          {{ $t("Room View") }}
        </router-link>
      </div>
    </div>
    <table class="table table-hover mt-3">
      <thead>
        <tr>
          <th>{{ $t("ID") }}</th>
          <th>{{ $t("Room Type") }}</th>
          <th>{{ $t("Room View") }}</th>
          <th>{{ $t("Control") }}</th>
        </tr>
      </thead>
      <tbody v-if="loading" class="text-center">
        <tr v-for="roomView in 10" :key="roomView">
          <td v-for="roomView in 5" :key="roomView">
            <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="(roomView, index) in roomViews" :key="index">
          <td>{{ roomView.id }}</td>
          <td>{{ roomView.room?.title }}</td>
          <td>{{ roomView.view_type?.title }}</td>
          <td>
            <router-link
              :to="`/edit/roomViewGlobal/${roomView.id}`"
              class="btn btn-success text-white mx-1"
              v-tooltip="$t('edit')"
            >
              <i class="fa-solid fa-pen-to-square"></i
            ></router-link>
            <button
              type="button"
              class="btn btn-danger text-white mx-1"
              v-tooltip="$t('delete')"
              @click="deleteRoomView(roomView.id, index)"
            >
              <i class="fa-solid fa-trash"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";

import axios from "axios";
import Skeleton from "primevue/skeleton";
import swal from "sweetalert2";

export default {
  name: "roomViewTable",
  components: {
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      roomViews: [],
      loading: true,
    };
  },
  methods: {
    getRoomViews() {
      axios
        .get("fetch_final_rooms")
        .then((res) => {
          this.roomViews = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteRoomView(id, index) {
      swal
        .fire({
          title: this.$t("check_delete"),
          text: this.$t("are_you_sure_delete"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("back"),
          confirmButtonText: this.$t("confirm_delete"),
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post("destroy_final_room", { id: id })
              .then((res) => {
                swal.fire({
                  title: this.$t("success"),
                  text: res.data.message,
                  icon: "success",
                });
                this.roomViews.splice(index, 1);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
  },
  mounted() {
    this.getRoomViews();
  },
};
</script>

<style lang="scss" scoped></style>
