<template>
  <section class="all_emp card_style mt-0">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        {{ $t("services") }}
      </h4>
    </div>
    <div class="main_header_style mb-2">
      <input
        type="text"
        v-model="servicesText"
        class="form-control w-50"
        :placeholder="$t('search...')"
        @keyup="fetchServices((page = 1))"
      />
      <router-link to="/services_website/add" class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add service") }}</router-link
      >
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table services">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("Image") }}</th>
              <th>{{ $t("Title") }}</th>
              <th>{{ $t("describtion") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="service in 10" :key="service">
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
              <td width="10%">
                <Skeleton shape="circle" size="5rem"></Skeleton>
              </td>

              <td v-for="service in 5" :key="service">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(service, index) in services.data" :key="index">
              <td>{{ service.id }}</td>
              <td><img :src="service.image" :alt="service.title" /></td>
              <td>{{ service.title }}</td>
              <td>
                <span
                  v-html="service.subtitle.substring(0, 50) + ' ...'"
                ></span>
              </td>
              <td>
                <router-link
                  :to="`/services_website/edit/${service.id}`"
                  class="btn btn-success mx-1 w-auto"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>

                <div
                  class="btn btn-danger mx-1 w-auto"
                  @click="deleteService(service.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="services"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchServices"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "all-services",
  data() {
    return {
      services: [],
      loading: true,
      servicesText: "",
    };
  },
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  methods: {
    fetchServices(page = 1) {
      axios
        .post(`/services?page=${page}`, { word: this.servicesText })
        .then(({ data }) => {
          this.services = data.data;
          this.loading = false;
          // console.log(this.services.data);
        });
    },
    deleteService(serviceId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios.delete(`/website/services/${serviceId}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.services.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
          });
        }
      });
    },
  },
  mounted() {
    this.fetchServices();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style scoped>
table tbody tr td img {
  width: 100px;
  height: 100px;
}
</style>
