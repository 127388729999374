<template>
  <Loading v-if="loading" />
  <section class="account_statement">
    <div class="container-fluid">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex align-items-center gap-3">
          <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />

          <h4 class="mb-0">
            {{ $t("general_account_statement") }} {{ branch.branch_name }}
          </h4>
        </div>
        <div id="print-button" class="print_button mx-2">
          <button class="PrimaryButton btn" v-print="'#printStatments'">
            {{ $t("print") }}
          </button>
        </div>
      </div>

      <div class="filter">
        <div class="item">
          <div class="form-group">
            <label>{{ $t("type_account_statement") }}</label>
            <Multiselect
              v-model="typeValue"
              :options="types"
              track-by="id"
              label="title"
              :clear-on-select="true"
              :placeholder="$t('type_account_statement')"
              select-label=""
              :hide-selected="true"
              @update:model-value="fetchBranchType($event, 1)"
            >
            </Multiselect>
          </div>
          <div class="form-group" v-if="showAdvanceSearch">
            <label>{{ $t("books_status") }}</label>
            <Multiselect
              v-model="statusValues"
              :options="statuss"
              track-by="id"
              label="title"
              :clear-on-select="true"
              :placeholder="$t('books_status')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
          </div>
        </div>

        <div class="item">
          <div class="form-group">
            <label>{{ typeValue.title }}</label>
            <Multiselect
              v-model="branch"
              :options="branches"
              track-by="branch_id"
              label="branch_name"
              :clear-on-select="true"
              :placeholder="$t('type')"
              select-label=""
              :hide-selected="true"
              @keyup="fetchBranchType($event, 2)"
            >
            </Multiselect>
          </div>
          <div class="form-group" v-if="showAdvanceSearch">
            <label>{{ $t("room_status") }}</label>
            <Multiselect
              v-model="roomstatusValue"
              :options="room_status"
              track-by="id"
              label="title"
              :clear-on-select="true"
              :placeholder="$t('room_status')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
          </div>
        </div>

        <div class="item">
          <div class="form-group">
            <label for="checkin" class="form-label">{{ $t("checkin") }}</label>
            <Datepicker
              v-model="from_date"
              class="w-100"
              lang="ar"
              :placeholder="$t('checkin')"
            />
          </div>
          <div class="form-group" v-if="showAdvanceSearch">
            <label>{{ $t("sent_to_accounts") }}</label>
            <Multiselect
              v-model="sent_to_accounts_value"
              :options="sent_to_accounts"
              track-by="id"
              label="title"
              :clear-on-select="true"
              :placeholder="$t('sent_to_accounts')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>

        <div class="item">
          <div class="form-group">
            <label for="checkout" class="form-label">{{
              $t("checkout")
            }}</label>
            <Datepicker
              v-model="to_date"
              class="w-100"
              lang="ar"
              :disabled-dates="{ to: from_date }"
              :placeholder="$t('checkout')"
            />
          </div>
          <div
            class="form-group"
            style="margin-top: 4rem"
            v-if="showAdvanceSearch"
          >
            <!-- <label>{{ $t("not_sent_to_accounts") }}</label>
            <Multiselect
              v-model="statusValue"
              :options="status"
              track-by="id"
              label="title"
              :clear-on-select="true"
              :placeholder="$t('not_sent_to_accounts')"
              select-label=""
              :hide-selected="true"
              @update:model-value="fetchBranchType($event, 1)"
            

            >
            </Multiselect> -->
          </div>
        </div>

        <div class="item">
          <div class="form-group">
            <label>{{ $t("typeTransaction") }}</label>
            <Multiselect
              v-model="typeTransactionValue"
              track-by="id"
              label="title"
              :options="typeTransaction"
              :clear-on-select="true"
              :placeholder="$t('typeTransaction')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
          <div
            class="form-group"
            style="margin-top: 4rem"
            v-if="showAdvanceSearch"
          >
            <!-- <label>{{ $t("status") }}</label>
            <Multiselect
              v-model="statusValue"
              :options="status"
              track-by="id"
              label="title"
              :clear-on-select="true"
              :placeholder="$t('type_account_statement')"
              select-label=""
              :hide-selected="true"
              @update:model-value="fetchBranchType($event, 1)"
            

            >
            </Multiselect> -->
          </div>
        </div>

        <div class="submitButtons">
          <button class="btn PrimaryButton" type="button" v-if="loadingspin">
            <i class="fa-solid fa-spinner fa-spin-pulse"></i>
          </button>
          <button
            v-else
            class="btn PrimaryButton"
            type="button"
            @click="fetchAccountStatments"
            :disabled="!isValid"
          >
            {{ $t("submit") }}
          </button>
        </div>
        <div class="submitButtons">
          <button
            class="btn PrimaryButton px-3"
            type="button"
            @click="toggleshowAdvanceSearch"
          >
            <span v-if="!showAdvanceSearch">
              {{ $t("advance_search") }}
            </span>
            <span v-else>{{ $t("close_advance_search") }}</span>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="table-responsive" id="printStatments">
          <HeaderPdf
            :title="$t('general_account_statement')"
            :fromDate="from_date"
            :toDate="to_date"
            :typeValue="typeValue"
            :typeTransactionValue="typeTransactionValue"
            :branch="branch"
            :statusValues="statusValues"
            :roomstatusValue="roomstatusValue"
            :sent_to_accounts_value="sent_to_accounts_value"
          />

          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ $t("book_number") }}</th>
                <th>{{ $t("main_serial_number") }}</th>
                <th>{{ $t("serial_number") }}</th>
                <th>{{ $t("date") }}</th>
                <th>{{ $t("Cost_center") }}</th>
                <th>{{ $t("creditor") }}</th>
                <th>{{ $t("debtor") }}</th>
                <th>{{ $t("book_status") }}</th>
                <th class="w-25">{{ $t("description") }}</th>
                <th>{{ $t("clerck") }}</th>
                <!-- <th>{{ $t("progress") }}</th> -->
              </tr>
            </thead>
            <tbody v-if="loading" class="text-center">
              <tr v-for="statment in 10" :key="statment">
                <td v-for="statment in 8" :key="statment">
                  <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(statment, index) in statments" :key="index">
                <td>
                  <router-link
                    :to="`/hotels/book_offline/${statment.morph_data.id}`"
                    target="_blank"
                  >
                    {{ statment.morph_data.book_number || "-" }}
                  </router-link>
                </td>
                <td>
                  <button
                    @click="getTransferItem(statment.daily_restrictions_id)"
                    class="btn text-info"
                    type="button"
                    data-bs-toggle="offcanvas"
                    :data-bs-target="`#offcanvasBottomDetails${item.id}`"
                    aria-controls="offcanvasBottom"
                    :title="$t('details')"
                    v-if="statment.main_serial_number"
                  >
                    {{ statment.main_serial_number }}
                  </button>
                </td>
                <td>
                  {{
                    statment.exchange_serial_number
                      ? statment.exchange_serial_number
                      : statment.receipt_serial_number
                        ? statment.receipt_serial_number
                        : ""
                  }}
                </td>
                <td>{{ statment.daily_restriction_date }}</td>
                <td>{{ statment.cost_center?.title }}</td>
                <td>{{ statment.creditor }}</td>
                <td>{{ statment.debtor }}</td>
                <td>
                  {{
                    statment.book_offline_status == 0
                      ? $t("pending")
                      : statment.book_offline_status == 1
                        ? $t("tentative")
                        : statment.book_offline_status == 2
                          ? $t("confirmed")
                          : statment.book_offline_status == 3
                            ? $t("cancelled")
                            : statment.book_offline_status == 4
                              ? $t("amended")
                              : statment.book_offline_status == 5
                                ? $t("confirmed")
                                : statment.book_offline_status == 6
                                  ? $t("draft")
                                  : ""
                  }}
                </td>
                <td style="width: 45%; padding: 0 2rem">
                  {{ statment.description }}
                </td>
                <td>
                  {{ statment.employee?.name }}
                </td>
              </tr>
              <tr
                v-if="statments.length > 0"
                style="border-top: 1px solid #111 !important"
              >
                <td></td>
                <td></td>

                <td></td>
                <td></td>
                <td></td>
                <td>
                  <div class="">
                    <!-- <p  class="mb-1">{{ $t("total_creditor") }}</p> -->
                    {{ totalCreditor }}
                  </div>
                </td>
                <td>
                  <div class="">
                    <!-- <p class="mb-1">{{ $t("total_debtor") }} </p> -->
                    {{ totalDebtor }}
                  </div>
                </td>
                <td></td>
                <td></td>

                <td>
                  <div class="d-flex align-items-center gap-1">
                    <p class="mb-1">{{ $t("total") }} :</p>
                    <div class="d-flex align-items-center">
                      {{ totalAll }}
                      <p class="mb-0 mx-1" v-if="totalCreditor > totalDebtor">
                        ( {{ $t("creditor") }} )
                      </p>
                      <p class="mb-0 mx-1" v-if="totalDebtor > totalCreditor">
                        ( {{ $t("debtor") }} )
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row mt-3">
        <pagination
          :data="statments"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="fetchAccountStatments"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </section>
  <detailsOffCanvas :item="item" />
</template>

<script>
import axios from "axios";
import HeaderPdf from "@/components/pdf/headerPdf.vue";

import Multiselect from "vue-multiselect";
import VueDatepickerUi from "vuejs3-datepicker";
import Loading from "@/components/LottieFiles/loading.vue";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
import detailsOffCanvas from "@/components/dailyConstruct/detailsOffCanvas.vue";
import moment from "moment";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  data() {
    return {
      loadingspin: false,
      showAdvanceSearch: false,
      statusValues: "",
      statuss: [
        {
          id: 0,
          title: this.$t("pending"),
        },
        {
          id: 1,
          title: this.$t("tentative"),
        },
        {
          id: 2,
          title: this.$t("confirmed"),
        },
      ],
      sent_to_accounts: [
        {
          id: 1,
          title: this.$t("send_to_accounts"),
        },
        {
          id: 2,
          title: this.$t("not_sent_to_accounts"),
        },
      ],
      sent_to_accounts_value: "",
      room_status: [
        {
          id: 0,
          title: this.$t("pending"),
        },
        {
          id: 1,
          title: this.$t("tentative"),
        },
        {
          id: 2,
          title: this.$t("confirmed"),
        },
      ],
      roomstatusValue: "",
      types: [
        {
          id: 1,
          title: this.$t("clients"),
        },
        // {
        //   id: 2,
        //   title: this.$t("client_company"),
        // },
        // {
        //   id: 3,
        //   title: this.$t("banks"),
        // },
        // {
        //   id: 4,
        //   title: this.$t("safe_box"),
        // },
        {
          id: 5,
          title: this.$t("suppliers"),
        },
        {
          id: 6,
          title: this.$t("hotels"),
        },
      ],
      typeValue: {
        id: 1,
        title: this.$t("clients"),
      },
      typeTransaction: [
        {
          id: 2,
          title: this.$t("store"),
        },
        {
          id: 1,
          title: this.$t("transfer"),
        },
      ],
      typeTransactionValue: "",
      from_date: "",
      to_date: "",
      loading: false,
      statments: [],
      branch: {
        branch_id: "",
        branch_name: "",
      },
      item: {},
      branches: [],
    };
  },
  components: {
    Multiselect,
    Loading,
    Datepicker: VueDatepickerUi,
    pagination,
    Skeleton,
    detailsOffCanvas,
    HeaderPdf,
    AddFavDailog,
  },
  methods: {
    fetchAccountStatments() {
      this.loadingspin = true;
      const formData = new FormData();
      formData.append("branch_id", this.branch.branch_id);
      if (this.from_date) {
        formData.append("from", moment(this.from_date).format("YYYY-MM-DD"));
      }
      if (this.to_date) {
        formData.append("to", moment(this.to_date).format("YYYY-MM-DD"));
      }
      if (this.typeTransactionValue) {
        formData.append("type", this.typeTransactionValue.id);
      }

      formData.append("morph_type", this.typeValue.id);

      if (this.statusValues) {
        // formData.append("status", this.statusValue.id);
        this.statusValues.forEach((element, index) => {
          formData.append(`book_offline_status[${index}]`, element.id);
        });
      }

      if (this.roomstatusValue) {
        // formData.append("book_offline_room_status", this.roomstatusValue.id);
        this.roomstatusValue.forEach((element, index) => {
          formData.append(`book_offline_room_status[${index}]`, element.id);
        });
      }

      if (this.sent_to_accounts_value) {
        formData.append("posted", this.sent_to_accounts_value.id);
        // this.sent_to_accounts_value.forEach((element, index) => {
        //   formData.append(`posted[${index}]`, element.id);
        // });
      }

      axios
        .post("/new_fetch_branch_statements", formData)
        .then(({ data }) => {
          this.statments = data.data;
          this.loadingspin = false;
          // console.log(this.statments);
        })
        .catch((error) => {
          this.loadingspin = false;
          console.log(error);
        });
    },
    fetchBranchType(event, type) {
      const formData = new FormData();
      formData.append("type", this.typeValue.id); //this.branchId);
      if (event != undefined && type == 2) {
        formData.append("word", event.target.value);
        // console.log(event.target.value);
      }
      axios.post("/fetch_branch_type", formData).then(({ data }) => {
        this.branches = data.data;
        // console.log(this.statments);
      });
    },
    toggleshowAdvanceSearch() {
      this.showAdvanceSearch = !this.showAdvanceSearch;
      if (this.showAdvanceSearch == false) {
        this.sent_to_accounts_value = null;
        this.roomstatusValue = null;
        this.statusValues = null;
      }
    },
    getTransferItem(daily_restrictions_id) {
      axios
        .get(`/daily_restriction/${daily_restrictions_id}`)
        .then(({ data }) => {
          this.item = data.data;
          // console.log(this.item);
        });
    },
  },
  mounted() {
    // this.fetchAccountStatments();
    this.fetchBranchType();
  },
  computed: {
    isValid() {
      return this.typeValue && this.branch.branch_id;
    },
    totalDebtor() {
      return this.statments?.reduce(
        (sum, statment) => sum + statment.debtor,
        0
      );
    },
    totalCreditor() {
      return this.statments?.reduce(
        (sum, statment) => sum + statment.creditor,
        0
      );
    },
    totalAll() {
      return this.totalDebtor - this.totalCreditor;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  .form-group {
    width: 100%;
  }
  display: flex;
  align-items: center;
  border: 1px solid #377373;
  border-radius: 10px;

  .item {
    width: 18%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .submitButtons {
    width: 10%;
  }
}
</style>
