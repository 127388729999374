<script setup>
import FilterGroup from "@/components/multipleReservations/filterGroup.vue";
import MultiResverationTable from "@/components/multipleReservations/multiResverationTable.vue";
import axios from "axios";
import { onMounted, ref } from "vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

// Reactive state variables
const reversations = ref([]);
const loader = ref(true);
const reservationIds = ref([]);

// Functions
async function fetchReversations(data = {}) {
  try {
    const response = await axios.post("/fetch_all_posted", data);
    reversations.value = response.data.data;
    loader.value = false;
    reversations.value = reversations.value.map((reversation) => ({
      ...reversation,
      checked: false,
    }));
  } catch (error) {
    console.error("Error fetching conversations:", error);
  }
}

const ConfirmReservations = (data) => {
  reservationIds.value = data;
};

onMounted(() => {
  fetchReversations();
});

function fetchReversationsFilter(filterData) {
  fetchReversations(filterData); // Call the main fetch function with filters
}
</script>

<template>
  <div class="confirm-reservations card_style">
    <div class="d-flex align-items-center gap-3 mb-3">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h3>{{ $t("confirm_reservations_posted") }} (UnPost)</h3>
    </div>
    <FilterGroup
      @fetchReservations="fetchReversationsFilter"
      :reservationIds="reservationIds"
      @fetchReservationEndPoint="fetchReversations"
    />
    <hr />
    <MultiResverationTable
      :dataArray="reversations"
      :loader="loader"
      @ConfirmReservations="ConfirmReservations"
    />
  </div>
</template>

<style scoped lang="scss"></style>
