<template>
  <!-- <div v-if="loading" class="loading w-100">
    <Loading />
  </div> -->
  <section class="flight">
    <div class="">
      <div class="filter">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center gap-3">
            <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
            <h4 class="title">
              {{ $t("New flight reservation") }}
            </h4>
          </div>
          <button
            class="btn btn-sm btn-danger"
            type="button"
            @click="removeSearch"
          >
            <i class="fa-solid fa-filter-circle-xmark"></i>
          </button>
        </div>
        <p class="text">
          {{
            $t(
              "Enter the following data and choose the appropriate hotel to follow the reservation process"
            )
          }}
        </p>

        <div class="return_went">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="one_Way"
              value="one_way"
              v-model="ways"
            />
            <label class="form-check-label one_Way" for="one_Way">{{
              $t("go_only")
            }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="two_way"
              value="two_way"
              v-model="ways"
            />
            <label class="form-check-label two_way" for="two_way">{{
              $t("go_back")
            }}</label>
          </div>

          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="multi_way"
              value="multi_way"
              v-model="ways"
            />
            <label class="form-check-label multi_way" for="multi_way">{{
              $t("multi_city")
            }}</label>
          </div>
        </div>
        <div
          class="selects flight-search row w-100"
          v-if="ways == 'one_way' || ways == 'two_way'"
        >
          <div
            v-if="ways == 'one_way' || ways == 'two_way'"
            class="col-xl-4 col-lg-4 col-md-6 col-12"
          >
            <div class="row">
              <div class="d-flex align-items-center" style="position: relative">
                <img
                  src="@/assets/media/image/icon/launchFlight.svg"
                  class="image"
                />
                <div class="form-group w-100">
                  <img src="@/assets/media/image/icon/plane-arrive(2).svg" />
                  <input
                    type="search"
                    class="form-control custom-input"
                    :placeholder="$t('Departure airport')"
                    v-model="searchValueLaunchonly"
                    @keyup="searchLaunch"
                  />
                  <div
                    class="resultSearch"
                    v-if="
                      searchValueLaunchonly.length > 2 &&
                      launchSearchonly.length
                    "
                  >
                    <h6 class="head_search">{{ $t("Departure airport") }}</h6>
                    <ul class="hotels">
                      <li
                        v-for="(launch, index) in launchSearchonly"
                        :key="index"
                        @click="
                          sendLaunchName(
                            launch.title == null ? '' : launch.title,
                            launch.code == null ? '' : launch.code
                          )
                        "
                      >
                        <div class="texts">
                          <p class="title">
                            {{ launch.title == null ? "" : launch.title }}
                          </p>
                          <p class="country">
                            {{ launch.country }} -
                            {{ launch.city_title }}
                          </p>
                        </div>
                        <div class="hotel_image">
                          {{ launch.code == null ? "" : launch.code }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="form-group w-100">
                  <img
                    class="plane_image"
                    src="@/assets/media/image/icon/download.svg"
                  />
                  <input
                    type="search"
                    class="form-control"
                    :placeholder="$t('Arrival airport')"
                    v-model="searchValueReturn"
                    @keyup="searchReturn"
                  />
                  <div
                    class="resultSearch"
                    v-if="searchValueReturn.length > 2 && returnSearch.length"
                  >
                    <h6 class="head_search">{{ $t("Arrival airport") }}</h6>
                    <ul class="hotels">
                      <li
                        v-for="(returns, indexx) in returnSearch"
                        :key="indexx"
                        @click="
                          sendReturnName(
                            returns.title == null ? '' : returns.title,
                            returns.code == null ? '' : returns.code
                          )
                        "
                      >
                        <div class="texts">
                          <p class="title">
                            {{ returns.title }}
                          </p>
                          <p class="country">
                            {{ returns.country }} -
                            {{ returns.city_title }}
                          </p>
                        </div>
                        <div class="hotel_image">
                          {{ returns.code == null ? "" : returns.code }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-xl-3 col-lg-3 col-md-6 col-12"
            v-if="ways == 'one_way'"
          >
            <div class="form-group w-100">
              <Datepicker
                v-model="dateValue"
                :lang="lang"
                class="Datepicker p-0"
                :placeholder="$t('date')"
                :disabled-start-date="{ to: new Date() }"
              />
            </div>
          </div>

          <div
            class="col-xl-3 col-lg-3 col-md-6 col-sm-12"
            v-if="ways == 'two_way'"
          >
            <div class="d-flex align-items-center w-100">
              <div class="form-group w-100">
                <Datepicker
                  v-model="from"
                  :lang="lang"
                  class="Datepicker p-0"
                  :disabled-start-date="{ to: new Date() }"
                  :placeholder="$t('from')"
                />
              </div>
              <div class="form-group w-100">
                <Datepicker
                  v-model="to"
                  :lang="lang"
                  class="mx-1 p-0 Datepicker"
                  :disabled-start-date="{ to: addOneDay(from) }"
                  :placeholder="$t('to')"
                />
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-lg-2 col-md-5 col-sm-12">
            <div class="form-group w-100">
              <div class="dropdown">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  {{ $t("Adults") }} {{ adults }} - {{ $t("childrens") }}
                  {{ childrens }} - {{ $t("babies") }} {{ babies }}
                </button>
                <div class="dropdown-menu">
                  <div class="list">
                    <span>{{ $t("Adults") }}</span>
                    <div class="number">
                      <button class="btn minus" @click="minusQuantityAdults()">
                        <i class="fa-solid fa-minus"></i>
                      </button>
                      <p class="count">{{ adults }}</p>
                      <button class="btn add" @click="addQuantityAdults()">
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="list">
                    <span class="d-flex align-items-center"
                      >{{ $t("childrens") }}
                      <span class="mx-2" style="font-size: 0.6rem"
                        >({{ $t("policy children") }})</span
                      ></span
                    >
                    <div class="number">
                      <button
                        class="btn minus"
                        @click="minusQuantityChildrens()"
                      >
                        <i class="fa-solid fa-minus"></i>
                      </button>
                      <!-- <input type="text" class="count form-control" v-model=""> -->
                      <p class="count">{{ childrens }}</p>
                      <button class="btn add" @click="addQuantityChildrens()">
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="list">
                    <span class="d-flex align-items-center"
                      >{{ $t("babies") }}
                      <span class="mx-2" style="font-size: 0.6rem"
                        >({{ $t("policy babies") }})</span
                      ></span
                    >
                    <div class="number">
                      <button class="btn minus" @click="minusQuantityBabies()">
                        <i class="fa-solid fa-minus"></i>
                      </button>
                      <!-- <input type="text" class="count form-control" v-model=""> -->
                      <p class="count">{{ babies }}</p>
                      <button class="btn add" @click="addQuantityBabies()">
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-1 col-md-5 col-sm-12">
            <div class="form-group w-100">
              <Multiselect
                v-model="cabin_code"
                :options="cabin_codes.map((cabin_code) => cabin_code.id)"
                :custom-label="
                  (opt) => cabin_codes.find((x) => x.id == opt).title
                "
                :clear-on-select="true"
                :placeholder="$t('cabin_codes')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
            </div>
          </div>

          <div
            class="col-xl-2 col-lg-2 col-md-2 col-sm-12"
            v-if="
              searchValueLaunchonly &&
              searchValueReturn &&
              dateValue &&
              cabin_code
            "
          >
            <button class="btn PrimaryButton w-100" @click="FetchFlights()">
              {{ $t("search_now") }}
            </button>
          </div>
        </div>
        <multiTrips v-if="ways == 'multi_way'" />
      </div>

      <div class="emptyFlight" v-if="offers.length == 0 && loading == false">
        <div class="empty-1">
          <h4>خطوات بسيطة لحجز رحلة طيرانك</h4>
        </div>
        <div class="imge-ph-1">
          <img src="@/assets/media/image/gggguu.png" alt="" />
        </div>
        <div class="loty-1">
          <lottie-player
            :src="lottiePlayer"
            background="transparent"
            speed="1"
            loop
            autoplay
            style="width: 300px; height: 300px"
          ></lottie-player>
        </div>
      </div>

      <!-- start filter -->
      <div class="row filter_sec" v-if="showFirstFilter == true">
        <div class="col-lg-10 .col-md-10 col-12">
          <div class="advice">
            <div class="d-flex w-100">
              <div class="form-check">
                <div class="input-group">
                  <h4 class="for"></h4>
                  <label for="zero">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="1"
                      id="zero"
                      v-model="cheapest_price"
                      @change="FetchFlights"
                    />
                    <div class="contents">
                      <div>
                        <h6>{{ $t("cheaper") }}</h6>
                        <div class="text">1.500 SAR <span>2h . 45m</span></div>
                      </div>
                      <div><i class="fa-solid fa-tag"></i></div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="form-check">
                <div class="input-group">
                  <label for="one">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="2"
                      id="one"
                      v-model="shortest_distance"
                      @change="FetchFlights"
                    />
                    <div class="contents">
                      <div>
                        <h6>{{ $t("shorter") }}</h6>
                        <div class="text">1.500 SAR <span>2h . 45m</span></div>
                      </div>
                      <div><i class="fa-regular fa-clock"></i></div>
                    </div>
                  </label>
                </div>
              </div>
              <div class="form-check">
                <div class="input-group">
                  <label for="two">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value="3"
                      id="two"
                      v-model="best_suggistion"
                      @change="FetchFlights"
                    />
                    <div class="contents">
                      <div>
                        <h6>{{ $t("best_suggestions") }}</h6>
                        <div class="text">1.500 SAR <span>2h . 45m</span></div>
                      </div>
                      <div><i class="fa-regular fa-star"></i></div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-1 col-12">
          <button class="filter_btn btn" @click="showFilterRow()">
            <i class="fa-solid fa-filter"></i>
          </button>
        </div>
        <div class="col-lg-1 col-12">
          <button
            class="btn btn-sm btn-danger"
            type="button"
            @click="removeSearch"
          >
            <i class="fa-solid fa-filter-circle-xmark"></i>
          </button>
        </div>
      </div>
      <!-- finish filter -->
      <div class="show_filter" v-if="showFilter">
        <div class="row">
          <!-- start input group -->
          <!-- <div class="col-lg-4 col-md-6 col-12 mb-4">
          <div class="input-group">
            <Multiselect
              v-model="timeValue"
              :options="times.map((time) => time.id)"
              :custom-label="(opt) => times.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('suitable_time')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div> -->
          <!-- finish input group -->

          <!-- start input group -->
          <div class="col-lg-3 col-md-6 col-12 mb-4 position-relative">
            <form>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('Airlines')"
                v-model="flightName"
                @keyup="FetchFlightCompany"
              />
            </form>
            <div
              class="resultSearch"
              v-if="flightName.length > 1 && flightName.length"
            >
              <ul v-for="(item, index) in company" :key="index">
                <li
                  @click="sendNameAirline(item.code == null ? '' : item.code)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <!-- finish input group -->

          <!-- start input group -->
          <div class="col-lg-2 col-md-6 col-12 mb-4">
            <div class="input-group">
              <Multiselect
                v-model="direct"
                :options="stops.map((stop) => stop.id)"
                :custom-label="(opt) => stops.find((x) => x.id == opt).title"
                :clear-on-select="true"
                :placeholder="$t('stope')"
                select-label=""
                :hide-selected="true"
                @update:model-value="FetchFlights"
              >
              </Multiselect>
            </div>
          </div>
          <!-- finish input group -->

          <!-- start input group -->
          <div class="col-lg-2 col-md-6 col-12 mb-4">
            <form>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('airport')"
                v-model="titleAirport"
                @keyup="FetchAirports"
              />
            </form>
            <div
              class="resultSearch"
              v-if="titleAirport.length > 2 && titleAirport.length"
            >
              <ul v-for="(item, index) in airports" :key="index">
                <li
                  @click="sendNameAirpotrs(item.code == null ? '' : item.code)"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div>
          </div>
          <!-- finish input group -->

          <!-- start input group -->
          <!-- <div class="col-lg-4 col-md-6 col-12 mb-4">
          <div class="input-group">
            <Multiselect
              v-model="laugageValue"
              :options="laugages.map((laugage) => laugage.id)"
              :custom-label="(opt) => laugages.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('laugage')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div> -->
          <!-- finish input group -->

          <!-- start input group -->
          <div class="col-lg-2 col-md-6 col-12 mb-4">
            <h6>{{ $t("price") }}</h6>

            <vue-slider
              v-model="priceValue"
              :min="minValue"
              :max="maxValue"
              :step="stepValue"
              :lazy="true"
              direction="rtl"
              :tooltip="'always'"
              :tooltip-placement="['top', 'bottom']"
            ></vue-slider>
          </div>
          <!-- finish input group -->

          .
          <div class="col-lg-2 col-md-6 col-12 mb-4">
            <button class="PrimaryButton btn" @click="logFilter()">
              {{ $t("search_now") }}
            </button>
          </div>
        </div>
      </div>
      <onlineFlightSearch v-if="loading" />
      <flightCard v-else :offers="offers" :metadata="metadata" :paxs="paxs" />
    </div>
  </section>
</template>

<script>
import onlineFlightSearch from "@/components/flight/onlineFlightSearch.vue";
import axios from "axios";
import Multiselect from "vue-multiselect";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import moment from "moment";
import flightCard from "@/components/flight/flightCard.vue";
import multiTrips from "@/view/dashboard/flight/multiTrip.vue";
import Swal from "sweetalert2";
// import Loading from "@/components/LottieFiles/loading.vue";

import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "flight-tabs",
  mounted() {
    // Log the value of ways when the component is mounted
    console.log("Current value of ways:", this.ways);
  },
  data() {
    return {
      message: "",
      tableRows: [{ from: "", to: "", dateValue: "" }],
      counter: 1,
      form: {
        parent_id: [],
      },
      lang:
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == undefined
          ? "ar"
          : "en",
      launch: [],
      return: [],
      countries: [],
      countryValue: "",
      cities: [],
      LaunchValue: "",
      ReturnValue: "",
      InterfaceValue: "",
      nationalities: [],
      nationalityValue: "",
      launchSearchonly: [],
      launchSearch: [],
      returnSearch: [],
      hotelValue: "",
      from: [new Date(), ""],
      to: [new Date(), ""],
      dateValue: [new Date(), ""],
      adults: 1,
      childrens: 0,
      rooms: 1,
      loading: false,
      flights: [],
      ways: "one_way",
      cabin_codes: [
        {
          id: 1,
          title: this.$t("First Class"),
        },
        {
          id: 2,
          title: this.$t("Business Class"),
        },
        {
          id: 3,
          title: this.$t("Economy Standard"),
        },
        {
          id: 4,
          title: this.$t("Premium Economy"),
        },
        {
          id: 5,
          title: this.$t("Economy"),
        },
      ],
      searchValueLaunchonly:
        JSON.parse(localStorage.getItem("flightData")) == null
          ? ""
          : JSON.parse(localStorage.getItem("flightData")).trips[0].from,
      searchValueLaunch:
        JSON.parse(localStorage.getItem("flightData")) == null
          ? ""
          : JSON.parse(localStorage.getItem("flightData")).trips[0].from,
      searchValueReturn:
        JSON.parse(localStorage.getItem("flightData")) == null
          ? ""
          : JSON.parse(localStorage.getItem("flightData")).trips[0].to,
      searchValueReturnCode: "",
      searchValueLaunchCode: "",
      searchValueLaunchCodeonly: "",
      cityValue: [],
      citiesSearch: [],
      cabin_code:
        JSON.parse(localStorage.getItem("flightData")) == null
          ? 5
          : JSON.parse(localStorage.getItem("flightData")).cabin_code,
      babies: 0,
      offers: [],
      metadata: {},
      paxs: [],
    };
  },
  components: {
    Multiselect,
    Datepicker: VueDatepickerUi,
    flightCard,
    multiTrips,
    // Loading,
    onlineFlightSearch,
    AddFavDailog,
  },
  methods: {
    validateFormData() {
      if (!this.cabin_code) {
        Swal.fire({
          title: "Error",
          text: this.$t("please_select_cabin_code"),
          icon: "error",
        });
        return false;
      }
      if (!this.to) {
        Swal.fire({
          title: "Error",
          text: this.$t("date"),
          icon: "error",
        });
        return false;
      }
      if (!this.from) {
        Swal.fire({
          title: "Error",
          text: this.$t("date"),
          icon: "error",
        });
        return false;
      }
      if (!this.dateValue) {
        Swal.fire({
          title: "Error",
          text: this.$t("date"),
          icon: "error",
        });
        return false;
      }
      if (!this.searchValueReturn) {
        Swal.fire({
          title: "Error",
          text: this.$t("please_select_return_city"),
          icon: "error",
        });
        return false;
      }
      if (!this.searchValueLaunchonly) {
        Swal.fire({
          title: "Error",
          text: this.$t("please_select_launch_city"),
          icon: "error",
        });
        return false;
      }
      return true;
    },
    addOneDay(date) {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 2);
      return newDate.toISOString().split("T")[0];
    },

    //Search Hotels
    searchLaunch(event) {
      const searchText = { title: event.target.value };
      axios
        .post(`/website/search_home_airports`, searchText)
        .then(
          (res) => (this.launchSearchonly = res.data.data)
          // (this.citiesSearch = res.data.data.cities)
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.launchSearch);
      // console.log(this.citiesSearch);
    },
    searchLaunchMulti(event) {
      const searchTextmulti = { title: event.target.value };
      axios
        .post(`/emptyFlightemptyFlightemptyFlight`, searchTextmulti)
        .then(
          (res) => (this.launchSearch = res.data.data)
          // (this.citiesSearch = res.data.data.cities)
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
      console.log(this.launchSearch);
      // console.log(this.citiesSearch);
    },
    searchLaunchMultireturn(event) {
      const searchTextmulti = { title: event.target.value };
      axios
        .post(`/website/search_home_airports`, searchTextmulti)
        .then(
          (res) => (this.launchSearch = res.data.data)
          // (this.citiesSearch = res.data.data.cities)
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
      console.log(this.launchSearch);
      // console.log(this.citiesSearch);
    },

    searchReturn(event) {
      const searchText = { title: event.target.value };
      axios
        .post(`/website/search_home_airports`, searchText)
        .then(
          (res) => (this.returnSearch = res.data.data)
          // (this.citiesSearch = res.data.data.cities)
        )
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.launchSearch);
      // console.log(this.citiesSearch);
    },

    // fetch launch
    sendLaunchName(launchTitle, launchCode) {
      this.launchTitle = launchTitle;
      this.searchValueLaunchonly = launchTitle;
      this.searchValueLaunch = launchTitle;
      this.searchValueLaunchCode = launchCode;
      this.searchValueLaunchCodeonly = launchCode;

      this.launchSearchonly = [];
      // console.log(this.launchSearch)
      // console.log(this.searchValueLaunch)
    },

    // fetch return
    sendReturnName(returnTitle, returnCode) {
      this.returnTitle = returnTitle;
      this.searchValueReturn = returnTitle;
      this.searchValueReturnCode = returnCode;

      this.returnSearch = [];
    },

    // fetch flights
    async FetchFlights() {
      if (this.validateFormData()) {
        // Define your function logic here
        let pax = [];
        if (this.adults > 0) {
          pax.push({
            type: "ADT",
            count: this.adults,
          });
        }
        if (this.childrens > 0) {
          pax.push({
            type: "CHD",
            count: this.childrens,
          });
        }
        if (this.babies > 0) {
          pax.push({
            type: "INF",
            count: this.babies,
          });
        }
        // console.log(pax)

        let flight = {};
        if (this.ways == "one_way") {
          flight = [
            {
              from: this.searchValueLaunchCode,
              arrive_from_title: this.searchValueLaunchonly,
              to: this.searchValueReturnCode,
              arrive_to_title: this.searchValueReturn,
              date: moment(this.dateValue).format("YYYY-MM-DD"),
            },
          ];
        } else {
          flight = [
            {
              from: this.searchValueLaunchCode,
              arrive_from_title: this.searchValueLaunchonly,
              to: this.searchValueReturnCode,
              arrive_to_title: this.searchValueReturn,
              date: moment(this.from).format("YYYY-MM-DD"),
            },
            {
              from: this.searchValueReturnCode,
              arrive_from_title: this.searchValueReturn,
              to: this.searchValueLaunchCode,
              arrive_to_title: this.searchValueLaunchonly,
              date: moment(this.to).format("YYYY-MM-DD"),
            },
          ];
        }
        const flightData = {
          status: this.ways,
          trips: flight,
          paxs: pax,
          cabin_code: this.cabin_code,
        };
        localStorage.setItem("flightData", JSON.stringify(flightData));
        // this.$router.push("/flight-search");
        this.loading = true;
        axios.post("/flight/air_shopping", flightData).then((res) => {
          // console.log(res.data.data.offers, "ddd");
          if (res.data.data.offers) {
            this.offers = res.data.data.offers;
            this.metadata = res.data.data.metadata;
            this.paxs = res.data.data.paxs;
            Swal.fire({
              icon: "success",
              title: "Flight successfully added",
              showConfirmButton: false,
              timer: 1000,
            });
          } else {
            this.message = res.data.DescText;
          }

          this.loading = false;
        });

        // this.$emit("runFlight");
        // console.log(this.tableRows, "tableRows");
      }
    },

    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // babies Minus && Add
    minusQuantityBabies() {
      if (this.babies > 0) {
        this.babies -= 1;
      }
    },
    addQuantityBabies() {
      this.babies += 1;
    },

    // Rooms Minus && Add
    minusQuantityRooms() {
      if (this.rooms > 1) {
        this.rooms -= 1;
      }
    },
    addQuantityRooms() {
      this.rooms += 1;
    },
    calculateTimeDifference(flight) {
      const startTime = flight.round_trips[0].launch.time;
      const endTime =
        flight.round_trips[flight.round_trips.length - 1].arrive.time;

      // Parse the time strings to create Date objects
      const startDate = new Date(`2000-01-01 ${startTime}`);
      const endDate = new Date(`2000-01-01 ${endTime}`);

      // Calculate the time difference in milliseconds
      const timeDifferenceMs = endDate - startDate;

      // Convert the time difference to hours
      const timeDifferenceHours = Math.abs(
        Math.round(timeDifferenceMs / (1000 * 60 * 60))
      );

      return timeDifferenceHours;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
@import "./_flight.scss";
</style>
