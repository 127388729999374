<template>
  <section class="show_receipt card_style">
    <div class="main_header_style">
      <h4>{{ $t("edit_meals") }}</h4>
    </div>
    <form class="custom_rooms form_topic w-100" v-on:submit.prevent="editMeals">
      <div class="contract_meals">
        <div
          class="form-check card_meal"
          v-for="(meal, index) in meals"
          :key="index"
        >
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`included_${meal.id}`"
              v-model="meal.isIncluded"
              :checked="meal.is_included == 1"
              @change="toggleIncluded(meal)"
            />
            <label class="form-check-label" :for="`included_${meal.id}`">{{
              $t("included")
            }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              :id="`notIncluded_${meal.id}`"
              v-model="meal.isNotIncluded"
              :checked="meal.is_included == 0"
              @change="toggleNotIncluded(meal)"
            />
            <label class="form-check-label" :for="`notIncluded_${meal.id}`">{{
              $t("hidden")
            }}</label>
          </div>
          <h6 class="form-check-label">
            {{ meal.meal_type_title }}
          </h6>
          <!-- <p>{{meal.is_included}}</p>
          <p>{{meal.isIncluded}}</p>
          <p>{{meal.isNotIncluded}}</p> -->
          <div class="inputs" v-if="meal.isIncluded == false && meal.isNotIncluded == false">
            <div class="form-group">
              <label for="">{{ $t("cost") }}</label>
              <input type="number" class="form-control" v-model="meal.cost" />
            </div>
            <div class="form-group margin">
              <label for="">{{ $t("selling price") }}</label>
              <input type="number" class="form-control" v-model="meal.selling_price" />
            </div>
          </div>
        </div>
      </div>
      <!-- start input group -->
      <div class="col-12">
        <button type="submit" class="btn PrimaryButton w-25">
          {{ $t("save_changes") }}
        </button>
      </div>
      <!-- finish input group -->
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "edit-meals",
  data() {
    return {
      meals: [],
      // selectedOption: new Array(meals.length).fill("option1"),
    };
  },
  methods: {
    fetch_meal_types() {
      axios
        .post("/get_season_rate_code_meal_type", {
          season_rate_code_id: this.$route.params.rate_id,
        })
        .then(({ data }) => {
          this.meals = data.data.map(meal => {
            return {
              ...meal,
              isIncluded: meal.is_included == 2 ? false : meal.is_included == 1 ? true : meal.is_included == 0 ? false : false,
              isNotIncluded: meal.is_included == 2 ? false : meal.is_included == 1 ? false : meal.is_included == 0 ? true : false
            };
          });
          // console.log(this.meals);
        });
    },

    prepareData() {
      return this.meals.map(meal => {
        return {
          meal_type_id: meal.meal_type_id,
          cost: meal.cost,
          price: meal.selling_price,
          is_included: meal.isIncluded == (1 || true) ? 1 : meal.isNotIncluded == (1 || true) ? 0 : (meal.isIncluded && meal.isNotIncluded) == (0 || false) ? 2 : 2,
          // Add other properties if needed
        };
      });
    },

    editMeals() {
      let data = {
        season_ratecode_id: this.$route.params.rate_id,
        meals: this.prepareData(),
      };
      axios
        .post("/store_season_rate_code_meal_type", data)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push(`/contract/${this.$route.params.id}/seasons`);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },

    toggleIncluded(meal) {
      if (meal.isIncluded) {
        meal.isNotIncluded = false;
      }
    },

    toggleNotIncluded(meal) {
      if (meal.isNotIncluded) {
        meal.isIncluded = false;
      }
    },
  },
  created() {
    this.fetch_meal_types();
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>
<style lang="scss" scoped>
@import "./_season.scss";
</style>
