<template>
    <!-- service table -->
    <div class="table-responsive mt-3">
      <table class="table">
        <thead>
          <tr>
            <th class="frist">Service name</th>
            <th scope="col">Count</th>
            <th scope="col">Price</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(service, index) in service" :key="index">
            <td>{{ service.service?.title }}</td>
            <td>{{ service.service_count }}</td>
            <td>{{ service.selling }}</td>
            <td>{{ (service.service_count)*(service.selling) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- service table -->
</template>

<script>
export default {
    name: "services-table",
    props: {
        service: {
        type: Array,
        required: true,
        default: () => []
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../_pill.scss";
</style>