<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add to favorites')" />
      <h4>{{ $t("Visa Contracts") }}</h4>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="search-visa my-3 w-100">
          <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control w-50"
              :placeholder="$t('search...')"
              v-model="contractText"
              @keyup="fetchContracts((page = 1))"
            />
          </form>
        </div>
        <router-link to="/visa/add_contract" class="mt-0 PrimaryButton btn w-25"
          ><i class="fa-solid fa-plus"></i
          >{{ $t("Add new contract") }}</router-link
        >
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table allcontracts" id="ContractTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("contract name") }}</th>
              <th>{{ $t("visa") }}</th>
              <th>{{ $t("From_Date") }}</th>
              <th>{{ $t("To_Date") }}</th>
              <th>{{ $t("nationality") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="contract in 10" :key="contract">
              <td v-for="contract in 7" :key="contract">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(contract, index) in allContracts.data" :key="index">
              <td>{{ contract.id }}</td>
              <td>{{ contract.name_contract }}</td>
              <td>
                <div
                  v-for="(contract_visa, index) in contract.visa_type_ids"
                  :key="index"
                >
                  {{ contract_visa.type_name ? contract_visa.type_name : "-" }}
                </div>
              </td>

              <td>{{ contract.from }}</td>
              <td>{{ contract.to }}</td>
              <td>
                <div
                  v-for="(country, index) in contract.country_ids"
                  :key="index"
                >
                  {{ country.title }}
                </div>
              </td>
              <!-- <td>
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-bars"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link :to="`/visa/add_contract/${contract.id}`" class="dropdown-item">
                      <i class="fa-regular fa-pen-to-square"></i>
                      <span>{{$t("Edit")}}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="`/visa/contract/${contract.id}/rate-code`" class="dropdown-item">
                      <span>{{$t("Add")}} Rate Code</span>
                    </router-link>
                  </li>
                  <li>
                    <div class="dropdown-item text-danger" @click="deleteContract(index)">
                      <i class="fa-solid fa-trash"></i>
                      <span>{{$t("Delete")}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </td> -->
              <td :data-label="$t('actions')">
                <!-- edit contract -->
                <router-link
                  class="btn btn-success mx-1 w-auto"
                  :title="$t('edit')"
                  :to="`/visa/contract/edit/${contract.id}`"
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                </router-link>
                <!-- edit contract -->

                <router-link
                  class="btn btn-info mx-1 w-auto"
                  :title="$t('seasons')"
                  :to="`/visa/contract/${contract.id}/seasons`"
                >
                  <i class="fa-solid fa-water"></i>
                </router-link>

                <!-- Delete contract -->
                <button
                  class="btn btn-danger mx-1 w-auto"
                  type="button"
                  :title="$t('delete')"
                  @click="deleteContract(index)"
                >
                  <i class="fa-regular fa-trash-can"></i>
                </button>
                <!-- Delete contract -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="allContracts"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchContracts"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";

export default {
  name: "allContracts",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      allContracts: [],
      contractText: "",
      loading: true,
    };
  },
  methods: {
    fetchContracts(page = 1) {
      axios
        .post(`/index_visa_contract?page=${page}`, { word: this.contractText })
        .then(({ data }) => {
          // console.log(data.data);
          this.allContracts = data.data;
          this.loading = false;
        });
    },
    //delete contract
    deleteContract(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.allContracts.data[index].id;
          const fromClick = true;
          axios.delete(`/visa_contract/${delete_id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.allContracts.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.fetchContracts();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
</style>
