<template>
    <section class="card_style">
        <div class="main_header">
            <h4>{{ $t("The Transfers") }}</h4>
        </div>

        <form class="charge_wallet_request" @submit.prevent="save()">
            <div class="row">
                <div class="col-lg-6 mb-2">
                    <div class="transfer-data">

                        <div class="row">
                            <h5> {{$t("From")}} </h5>
                            <div class="col-lg-6">
                                <div class="bank service_type">
                                    <input
                                    v-model="transferTypeFrom"
                                    value="bank"
                                    type="radio"
                                    name="transfer_from"
                                    id="bank" 
                                    disabled />
                                    <label for="bank"><i class="fa-solid fa-building-columns"></i> {{$t("bank account")}}</label>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="bank service_type">
                                    <input
                                    v-model="transferTypeFrom"
                                    value="safe"
                                    type="radio"
                                    name="transfer_from"
                                    id="safe"
                                    disabled />
                                    <label for="safe"><i class="fa-solid fa-box-archive"></i> {{$t("safe")}}</label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div v-if="transferTypeFrom == 'bank'">
                                <label class="d-block mb-2">{{$t("bank account")}}</label>
                                <input type="text" class="form-control" v-model="from_account_title" disabled/>
                            </div>
                            <div v-if="transferTypeFrom == 'safe'">
                                <label class="d-block mb-2">{{$t("The Safe")}}</label>
                                <input type="text" class="form-control" v-model="from_account_title" disabled/>
                            </div>
                            
                        </div>

                        <div class="row mt-4">
                            <div class="col-4">
                                <label for="amount" class="d-block mb-2">{{$t("the amount")}}</label>
                                <input type="text" id="amount" v-model="amount" disabled/>
                            </div>
                            <div class="col-4">
                                <label for="before" class="d-block mb-2">{{$t("avalible_before")}}</label>
                                <input type="text" id="before" :value="from_account.money" disabled/>
                            </div>
                            <div class="col-4">
                                <label for="after" class="d-block mb-2">{{$t("avalible_after")}}</label>
                                <input type="text" id="after" :value="FromMoneyAfter" disabled />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mb-2">
                    <div class="to_transfer_container">
                        <div class="transfer-data mb-3" v-for="(item , index) in this.toAccounts" :key="item.id">
                            <div class="row">
                                <h5> {{$t("To")}} </h5>
                                <div class="col-lg-6">
                                    <div class="bank service_type">
                                        <input
                                        v-model="item.transferTypeTo"
                                        value="bank"
                                        type="radio"
                                        :name="`transfer_to${index}${item.id}`"
                                        :id="`bank${index}${item.id}`"
                                        disabled
                                        />
                                        <label :for="`bank${index}${item.id}`"><i class="fa-solid fa-building-columns"></i> {{$t("bank account")}}</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="bank service_type">
                                        <input
                                        v-model="item.transferTypeTo"
                                        value="safe"
                                        type="radio"
                                        :name="`transfer_to${index}${item.id}`"
                                        :id="`safe${index}${item.id}`" 
                                        disabled />
                                        <label :for="`safe${index}${item.id}`"><i class="fa-solid fa-box-archive"></i> {{$t("safe")}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div v-if="item.transferTypeTo == 'bank'">
                                    <label class="d-block mb-2">{{$t("bank account")}}</label>
                                    <input type="text" class="form-control" v-model="item.to_account_title" disabled />
                                </div>
                                <div v-if="item.transferTypeTo == 'safe'">
                                    <label class="d-block mb-2">{{$t("The Safe")}}</label>
                                    <input type="text" class="form-control" v-model="item.to_account_title" disabled />
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="col-4">
                                    <label for="amount" class="d-block mb-2">{{$t("the amount")}}</label>
                                    <input type="text" id="amount" v-model="item.amount" disabled/>
                                </div>
                                <div class="col-4">
                                    <label for="before" class="d-block mb-2">{{$t("avalible_before")}}</label>
                                    <input type="text" id="before" :value="item.to_account?.money" disabled/>
                                </div>
                                <div class="col-4">
                                    <label for="after" class="d-block mb-2">{{$t("avalible_after")}}</label>
                                    <input type="text" id="after" :value="item.toMoneyAfter" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row my-4">
                <div class="col-6">
                    <label for="date" class="d-block mb-3">{{$t("transfer's date and time")}}</label>
                    <input type="date" id="date" v-model="date" disabled/>
                </div>
                <div class="col-6">
                    <label for="notes" class="d-block mb-3">{{$t("notes")}}</label>
                    <input type="text" id="notes" placeholder="..." v-model="notes" disabled>
                </div>
            </div>
        </form>
    </section>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return{
            transfer_id: this.$route.params.id,
            transferTypeFrom:'bank',
            type: 1,
            from_account:'',
            amount:"",
            FromMoneyAfter:"",
            date:"",
            notes:"",
            toAccounts:[
                {
                    id:1,
                    transferTypeTo: "bank",
                    to_account: "",
                    to_account_title: "",
                    amount: "",
                    toMoneyAfter: ""
                }
            ],
        }
    },
    methods:{
        calcTransferFromMoney(){
            this.FromMoneyAfter = parseFloat(this.from_account.money) - parseFloat(this.amount);
        },
        fetchTransferData(){
            axios.post(`/fetch_specific_transfer_account` , {transfer_account_id: this.transfer_id}).then(({data})=>{
                // console.log(data)
                this.notes = data.data.text;
                this.date = data.data.date;
                this.toAccounts = data.data.to.map((ele , index)=>{
                    return{
                        id: index,
                        transferTypeTo: ele.account.type == "Account" ? "bank" : "safe",
                        to_account: ele.account,
                        to_account_title: ele.account.title,
                        amount: ele.amount,
                        toMoneyAfter: parseFloat(ele.account.money) + parseFloat(ele.amount)
                    }
                });

                this.transferTypeFrom = data.data.from.type == "Account" ? "bank" : "safe";
                this.type = this.transferTypeFrom == "Account" ? 1 : 2;
                this.from_account = data.data.from;
                this.from_account_title = data.data.from.title;
                this.amount = data.data.from.amount;
                this.calcTransferFromMoney();
            })
        },
    },
    mounted(){
        this.fetchTransferData();
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_transfer.scss";
</style>


