<template>
  <div class="flex align-items-center justify-content-around mx-2">
    <RadioButton v-model="selectedOption" inputId="Bank" value="Bank" />
    <label for="Bank" class="mx-2 mb-0 label">{{
      $t("bank_account")
    }}</label>
    <RadioButton v-model="selectedOption" inputId="pocket" value="pocket" />
    <label for="pocket" class="mx-2 mb-0 label">{{ $t("Safe") }}</label>
  </div>
  <div class="flex mt-2">
    <Multiselect
      v-model="accountValue"
      :options="organizationAccounts"
      :placeholder="$t('account')"
      label="title"
      track-by="id"
      :clear-on-select="true"
      select-label=""
      @update:model-value="accountVal(accountValue)"
      :hide-selected="true"
      v-if="selectedOption === 'Bank'"
    >
    </Multiselect>
    <Multiselect
      v-model="safeValue"
      :options="organizationSafe"
      :placeholder="$t('safe')"
      label="title"
      track-by="id"
      :clear-on-select="true"
      select-label=""
      :hide-selected="true"
      @update:model-value="safeVal(safeValue)"
      v-if="selectedOption === 'pocket'"
    >
    </Multiselect>
  </div>
</template>
<script>
// import axios from "axios";
import RadioButton from "primevue/radiobutton";
import Multiselect from "vue-multiselect";
export default {
  name: "accounts-bank_accounts",
  props:["organizationAccounts" , "organizationSafe"],
  emits: ["updateAccountValue", "updateSafeValue"],
  data() {
    return {
      selectedOption: "", // Tracks the selected radio button
      bankIngredient: [], // Define separate v-models
      pocketIngredient: [], // Define separate v-models
      safeValue: "",
      accountValue: "",
    };
  },
  components: {
    RadioButton,
    Multiselect,
  },
  methods: {
    accountVal(accountValue) {
      //   this.accountValue = accountValue;
      this.$emit("updateAccountValue", accountValue);
    },
    safeVal(safeValue) {
      //   this.safeValue = safeValue;
      this.$emit("updateSafeValue", safeValue);
    },
    // fetchOrganizationAccounts() {
    //   axios
    //     .post("/fetch_my_organization_accounts", { type: 1 })
    //     .then(({ data }) => {
    //       this.organizationAccounts = data.data.data;
    //       //   console.log(this.organizationAccounts);
    //     });
    // },
    // fetchOrganizationSafe() {
    //   axios
    //     .post("/fetch_my_organization_accounts", { type: 2 })
    //     .then(({ data }) => {
    //       this.organizationSafe = data.data.data;
    //       // console.log(this.organizationSafe);
    //     });
    // },
  },
  mounted() {
    // this.fetchOrganizationAccounts();
    // this.fetchOrganizationSafe();
  },
};
</script>

<style lang="scss" scoped>
.label {
  font-weight: bold;
  font-size: 1.2rem;
  color: #656b78;
}
</style>
