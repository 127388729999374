<template>
  <section class="show_receipt card_style">
    <div class="main_header_style"></div>
    <div class="d-flex gap-2 px-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
      <h4 class="head-we">{{ $t("post_to_account") }}</h4>
    </div>
    <div class="row options mb-2">
      <form class="from-group col-12 col-lg-6">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          :placeholder="$t('search by name')"
          v-model="titlenation"
          @keyup="fetchPostToAccount((page = 1))"
          class="form-control w-50"
        />
      </form>
      <div class="row col-12 col-lg-6">
        <!-- <div class="col-lg-4">
          <paidDialog />
        </div> -->
        <div class="form-group col-lg-12">
          <Multiselect
            style="margin-inline-start: auto"
            v-model="status_select"
            :options="status"
            class="w-100"
            :placeholder="$t('status')"
            label="title"
            track-by="id"
            :clear-on-select="true"
            select-label=""
            @update:model-value="fetchPostToAccount((page = 1))"
            :hide-selected="true"
          >
          </Multiselect>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table contract">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("book number") }}</th>
              <th>{{ $t("account") }}</th>
              <th>{{ $t("book_details") }}</th>
              <th>{{ $t("the amount") }}</th>
              <th>{{ $t("created_at") }}</th>
              <th>{{ $t("transaction_type") }}</th>
              <th>{{ $t("paid_type") }}</th>
              <th>{{ $t("description") }}</th>
              <th>{{ $t("attachment") }}</th>
              <th>{{ $t("actions") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 12" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in accounts.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.consumer }}</td>
              <td>
                <router-link :to="`/hotels/book_offline/${item.book_id}`" target="_blank">{{
                  item.book_number
                }}</router-link>
              </td>
              <td>{{ item.account }}</td>
              <td>
                <div v-if="item.type === 1">
                  <router-link :to="`/hotels/book_offline/${item.book_id}`" target="_blank">{{
                    $t("book_offline")
                  }}</router-link>
                </div>
                <div v-else-if="item.type === 2">
                  <router-link :to="`/book-offline-service/${item.book_id}`">{{
                    $t("book_service_offline")
                  }}</router-link>
                </div>
              </td>
              <td>{{ item.amount }}</td>
              <td>{{ item.created_at }}</td>
              <td>{{ $t(item?.transaction_type) }}</td>
              <td>{{ $t(item?.paid_type) }}</td>
              <td>
                <ShowDesciptionPayment
                  :invoices="item?.invoices"
                  v-if="item?.paid_type == 'Invoices'"
                />
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  v-if="item?.attachments.length"
                  :data-bs-target="`#attachmentModal${item?.id}`"
                  v-tooltip.top="$t('show_attachment')"
                >
                  <i class="fa-solid fa-images"></i>
                </button>
                <span
                  v-else
                  class="btn btn-warring"
                  v-tooltip.top="$t('no_attachment')"
                >
                  <i class="fa-solid fa-eye-slash"></i>
                </span>
                <!-- Modal -->
                <div
                  class="modal fade"
                  :id="`attachmentModal${item?.id}`"
                  tabindex="-1"
                  aria-labelledby="attachmentModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-body">
                        <img
                          v-for="(image, index) in item?.attachments"
                          :key="index"
                          :src="image.image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <span
                  v-if="item.status == 2 || item.status == 3"
                  :class="[
                    'badge',
                    item.status === 0
                      ? 'bg-primary'
                      : item.status === 1
                        ? 'bg-warning '
                        : item.status === 2
                          ? 'bg-success'
                          : item.status === 3
                            ? 'bg-danger'
                            : '',
                  ]"
                >
                  {{
                    item.status === 0
                      ? $t("initial")
                      : item.status === 1
                        ? $t("pending")
                        : item.status === 2
                          ? $t("completed")
                          : item.status === 3
                            ? $t("canceled")
                            : ""
                  }}
                </span>
                <button
                  v-if="item.status == 1"
                  @click="changeStatus(item.id, 2)"
                  class="btn btn-success mx-2"
                  style="font-size: 0.8rem"
                >
                  <i class="fa-solid fa-check"></i> {{ $t("ACCEPTED") }}
                </button>
                <button
                  v-if="item.status == 1"
                  @click="changeStatus(item.id, 3)"
                  class="btn btn-danger mx-2"
                  style="font-size: 0.8rem"
                >
                  <i class="fa-solid fa-ban"></i> {{ $t("DECLINE") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="accounts"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchPostToAccount"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Skeleton from "primevue/skeleton";
// import paidDialog from "@/components/Dialogs/paidDialog.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import ShowDesciptionPayment from "@/components/offline/show_description_payment.vue";

export default {
  name: "show-nation",
  components: {
    // paidDialog,
    pagination,
    Multiselect,
    Skeleton,
    AddFavDailog,
    ShowDesciptionPayment,
  },
  data() {
    return {
      accounts: [],
      loading: true,
      titlenation: "",
      status_select: {
        id: 0,
        title: this.$t("All"),
      },
      status: [
        {
          id: 0,
          title: this.$t("All"),
        },
        {
          id: 1,
          title: this.$t("Pending"),
        },
        {
          id: 2,
          title: this.$t("ACCEPTED"),
        },
        {
          id: 3,
          title: this.$t("Decline"),
        },
      ],
    };
  },
  methods: {
    fetchPostToAccount(page = 1) {
      const Data = {
        word: this.titlenation,
      };

      if (this.status_select.id !== 0) {
        Data.status = this.status_select.id;
      }
      axios
        .post(`/fetch_all_post_to_account_order?page=${page}`, Data)
        .then(({ data }) => {
          this.accounts = data.data;
          this.loading = false;
          // console.log(this.contracts);
        });
    },
    changeStatus(id, state) {
      axios
        .post(`/change_post_to_account_order_status`, {
          post_to_account_order_id: id,
          status: state,
        })
        .then(({ data }) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: data.message,
          });
          this.fetchPostToAccount();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
    this.fetchPostToAccount();
  },
};
</script>
