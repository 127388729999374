<script setup>
import { defineEmits, defineProps, ref } from "vue";
import Skeleton from "primevue/skeleton";

const props = defineProps({
  dataArray: {
    type: Array,
  },
  loader: {
    type: Boolean,
  },
});

const emit = defineEmits(["ConfirmReservations"]);
const checkedArr = ref([]);
const pushData = (item) => {
  if (item.checked) {
    checkedArr.value.push(item.id);
  } else {
    checkedArr.value.splice(checkedArr.value.indexOf(item.id), 1);
  }

  emit("ConfirmReservations", checkedArr.value);
};
const findNearestSets = (number) => {
  if (isNaN(number)) {
    return number; // Return the original value if not a number
  }
  return Math.round(number * 10000) / 10000;
};
</script>

<template>
  <div class="multiRev-table">
    <div
      class="d-flex align-center justify-content-between border rounded p-2 mt-2 bg-opacity-25 bg-secondary"
    >
      <span>{{ $t("choose") }}</span>
      <span>{{ $t("Reservation number") }}</span>
      <span>{{ $t("client") }}</span>
      <span>{{ $t("hotel") }}</span>
      <span>{{ $t("start date") }}</span>
      <span>{{ $t("end date") }}</span>
      <span>{{ $t("cost tax") }}</span>
      <span>{{ $t("cost") }}</span>
      <span>{{ $t("tax") }}</span>
      <span>{{ $t("price") }}</span>
    </div>
    <div v-if="loader">
      <div
        class="d-flex align-center justify-content-between border rounded p-2 mt-2 shadow"
        v-for="i in 5"
        :key="i"
      >
        <span v-for="i in 10" :key="i">
          <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
        </span>
      </div>
    </div>
    <div v-else>
      <div
        class="d-flex align-center justify-content-between border rounded p-2 mt-2 shadow"
        v-for="item in props.dataArray"
        :key="item.id"
      >
        <span
          ><input
            type="checkbox"
            name=""
            id=""
            v-model="item.checked"
            @change="pushData(item)"
        /></span>
        <span>{{ item.book_number }}</span>
        <span>{{ item.client?.name }}</span>
        <span>
          <span class="mb-0" v-for="hotel in item?.hotels" :key="hotel.id">
            {{ hotel.title }}
          </span>
        </span>
        <span>{{ item.check_in }}</span>
        <span>{{ item.check_out }}</span>
        <span>{{ findNearestSets(item.cost_tax) }}</span>
        <span>{{ findNearestSets(item.total_cost_price) }}</span>
        <span>{{ findNearestSets(item.selling_tax) }}</span>
        <span>{{ findNearestSets(item.total_selling_price) }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.d-flex.align-center.justify-content-between {
  span {
    width: calc(100% / 10);
    text-align: center;
  }
}
</style>
