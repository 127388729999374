<template>
  <section class="card_style tree_organization mt-0">
    <div class="main_header_style mb-3">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4 class="mb-0">
          {{ $t("company_tree") }}
        </h4>
      </div>
      <div class="d-flex align-items-center gap-3">
        <button
          class="SecondButton btn action d-flex align-items-center mx-2"
          @click="exportToExcel"
        >
          <i class="fa-solid fa-file-excel"></i>
          {{ $t("export_to_excel") }}
        </button>
        <router-link
          :to="{ name: 'FormOrganization' }"
          class="PrimaryButton btn tree"
          ><i class="fa-solid fa-plus"></i>
          {{ $t("add_new_Company") }}</router-link
        >
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table tree" id="organizationTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("the_company") }}</th>
              <th>{{ $t("company_balance") }}</th>
              <th>{{ $t("company_type") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 5" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in tree.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.wallet }}</td>
              <td>
                <span v-if="item.is_portal === 1"> {{$t("portal")}}</span>
                <span v-else> {{ $t("companny") }} </span>
              </td>

              <td>
                <router-link
                  class="btn btn-warning"
                  :to="`/sub_organization/${item.id}`"
                  :title="$t('sub_organization')"
                >
                  <i class="fa-solid fa-bars-staggered"></i>
                </router-link>

                <router-link
                  class="btn btn-primary mx-1"
                  :to="`/organization-form/details/${item.id}`"
                  :title="$t('company_detials')"
                >
                  <i class="fa-solid fa-circle-info"></i>
                </router-link>

                <router-link
                  class="btn btn-success text-white mx-1"
                  :to="`/organization-form/edit/${item.id}`"
                  :title="$t('edit')"
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>
                <router-link
                  :to="`/organization-theme/${item.id}`"
                  class="btn SecondButton mx-3"
                  v-if="status === 1"
                >
                  <i class="fa-solid fa-palette"></i>
                  {{ $t("company_theme") }}
                </router-link>

                <button
                  class="btn btn-info text-white w-25r"
                  @click="blockAlert(index)"
                >
                  <i class="fa-solid fa-ban"></i>
                  {{ item.block == 0 ? $t("bolck_company") : $t("unblock") }}
                </button>

                <button
                  class="btn btn-danger text-white mx-1 remove_btn"
                  @click="DeleteAlert(index)"
                >
                  <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="tree"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchTreeOrganization"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";
import Swal from "sweetalert2";
// import $ from "jquery";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  name: "tree-organization",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      isMenuVisible: false,
      tree: [],
      status: JSON.parse(localStorage.getItem("user")).status,
      loading: true,
      // showChildTree: false,
    };
  },
  computed: {
    organization_id() {
      // You can adjust this depending on your actual data structure
      if (this.tree.data.length > 0) {
        return this.tree.data[0].id;
      }
      // Default value if the tree is empty
      return null;
    },
  },

  methods: {
    toggleMenu(index) {
      this.tree.data[index].isMenuVisible =
        !this.tree.data[index].isMenuVisible;
    },
    //fetch companies data
    FetchTreeOrganization(page = 1) {
      axios
        .post(`/fetch_my_organization_child?page=${page}`, { per_page: 10 })
        .then(({ data }) => {
          this.tree = data.data;
          this.loading = false;
        });
    },
    async exportToExcel() {
      try {
        const response = await axios.post("/fetch_my_organization_child");
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "my_organization_child.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    async fetchChildTree(item) {
      const formData = new FormData();
      formData.append("organization_id", item.id);
      await axios
        .post("/fetch_child_organizations", formData)
        .then(({ data }) => {
          // Update item.childern with fetched child items
          item.childern = data.data;
          item.showChildTree = !item.showChildTree;
        });
    },
    // block company
    blockAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_block"),
      }).then((result) => {
        if (result.isConfirmed) {
          let block_id = { organization_id: this.tree.data[index].id };
          const newLocal = this.$i18n.locale;
          axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios
            .post("/change_block_organization", block_id)
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.FetchTreeOrganization();
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
    // delete company
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.tree.data[index].id;
          const fromClick = true;
          axios
            .delete("/organizations/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.tree.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchTreeOrganization();
  },
};
</script>

<style lang="scss" scoped>
@import "./organization.scss";
</style>
