<template>
  <section class="all_emp card_style">
    <div
      class="main_header_style d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("news") }}</h4>
      </div>
    </div>

    <div class="row mt-4">
      <div class="table-responsive">
        <table class="table" id="PackageTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("phone") }}</th>
            </tr>
          </thead>
          <tbody v-if="loading" class="text-center">
            <tr v-for="n in 10" :key="n">
              <td v-for="m in 3" :key="m">
                <Skeleton height="2rem" width="90%" class="mb-2" />
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in news.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.phone }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mt-4">
      <pagination
        :data="news"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchAllNews"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Skeleton from "primevue/skeleton";

export default {
  name: "newOrganization",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      news: [],
      loading: true,
    };
  },
  methods: {
    fetchAllNews(page = 1) {
      axios.post(`/index_news_letter_phone?page=${page}`).then(({ data }) => {
        this.news = data.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.fetchAllNews();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}

.search-form {
  position: relative;
  .search-icon {
    position: absolute;
    top: 12px;
    right: 15px;
    color: #9ca2ac;
  }
  input {
    padding-right: 40px !important;
  }
}

.table {
  text-align: center;
  thead tr th {
    text-align: center !important;
  }
  tbody tr:nth-child(even) td {
    background-color: #e9e9e95c;
  }
}
</style>
