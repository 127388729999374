<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <h4>Flight Rate Code</h4>

      <div>
        <router-link
          :to="`/flight-contract/${this.contract_id}/flight-season/${this.season_id}/rateCodes/add`"
          class="PrimaryButton btn w-100"
          ><i class="fa-solid fa-plus"></i
          >{{ $t("Add new Rate Code") }}</router-link
        >
      </div>
    </div>

    <div class="search-visa my-3">
      <form>
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search...')"
        />
      </form>
    </div>

    <div class="row">
      <table class="table" id="RateTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("visa") }}</th>
            <th>{{ $t("From Date") }}</th>
            <th>{{ $t("To Date") }}</th>
            <th>{{ $t("country") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr v-for="(rate, index) in allRateCode.data" :key="index">
            <td> {{rate.id}} </td>
            <td>{{ rate.name }}</td>
            <td>
                <div v-for="(rate_visa , index) in rate.Visa_card" :key="index">{{rate_visa.visa_type_id}}</div>
            </td>
            
            <td>{{rate.from}}</td>
            <td>{{rate.to}}</td>
            <td>{{rate.country.title}}</td>
            <td>
              <div class="dropdown">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa-solid fa-bars"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <router-link :to="`/visa/contract/${this.contract_id}/seasons/${this.season_id}/rate-code/${rate.id}`" class="dropdown-item">
                      <i class="fa-regular fa-pen-to-square"></i>
                      <span>{{$t("Edit")}}</span>
                    </router-link>
                  </li>
                  <li>
                    <div class="dropdown-item text-danger" @click="deleteRateCode(index)">
                      <i class="fa-solid fa-trash"></i>
                      <span>{{$t("Delete")}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="allRateCode"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchRateCodes"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
  import axios from "axios";
  import pagination from "laravel-vue-pagination";
  import Swal from "sweetalert2";

  export default {
    name: "flightRateCodesIndex",
    components: {
      pagination,
    },
    data() {
      return {
        allRateCode: [],
        contract_id: this.$route.params.id,
        season_id: this.$route.params.seasonId,
      };
    },
    methods: {
      fetchRateCodes(page = 1) {
        axios
          .get(`/flight_rate_code?flight_season=${this.season_id}&page=${page}`)
          .then(({ data }) => {
            this.allRateCode = data.data;
            // console.log(this.allRateCode);
          });
      },
      //delete contract
      deleteRateCode(index) {
        Swal.fire({
          title: this.$t("check_delete"),
          text: this.$t("are_you_sure_delete"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.$t("back"),
          confirmButtonText: this.$t("confirm_delete"),
        }).then((result) => {
          if (result.isConfirmed) {
            let delete_id = this.allRateCode.data[index].id;
            const fromClick = true;
            axios.delete(`/flight_rate_code/${delete_id}`, { fromClick }).then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.allRateCode.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
          }
        });
      },
    },
    mounted() {
      this.fetchRateCodes();
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/variables.scss";
  // @import "./_visa.scss";

  .main_header_style {
    align-items: center;
    h4 {
      margin-bottom: 0;
    }
  }
  .search-visa {
    form {
      position: relative;
      i {
        position: absolute;
        top: 12px;
        right: 15px;
        color: #9ca2ac;
      }
      input {
        padding-right: 40px !important;
      }
    }
  }
</style>
