<template>
  <section class="all_emp card_style" style="height: 100dvh">
    <div
      class="main_header_style d-flex justify-content-between align-items-center my-2"
    >
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
        <h4 class="coupons">{{ $t("Pending") }} {{ $t("Markters") }}</h4>
      </div>
    </div>
    <div>
      <div class="search-user w-50 my-3">
        <form>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            v-model="pendingText"
            class="form-control"
            :placeholder="$t('search...')"
            @keyup="fetchpending((page = 1))"
          />
        </form>
      </div>
    </div>

    <div class="row">
      <table class="table" id="userTable">
        <thead>
          <tr>
            <th class="text-center">ID</th>
            <th class="text-center">{{ $t("name") }}</th>
            <th class="text-center">{{ $t("email") }}</th>
            <th class="text-center">{{ $t("phone") }}</th>
            <th class="text-center">{{ $t("invitation_code") }}</th>
            <th class="text-center">{{ $t("type") }}</th>
            <!-- <th class="text-center">{{ $t("Discount code") }}</th> -->
            <th class="text-center">{{ $t("Control") }}</th>
          </tr>
        </thead>

        <tbody v-if="loading" class="text-center">
          <tr v-for="user in 10" :key="user">
            <td v-for="user in 7" :key="user">
              <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
            </td>
          </tr>
        </tbody>

        <tbody class="bg-primary" v-else>
          <tr v-for="(user, index) in pending.data" :key="index">
            <td>{{ user.id }}</td>
            <td>{{ user.first_name }} {{ user.last_name }}</td>
            <td>{{ user.email }}</td>
            <td>
              {{ user.phone }}
            </td>
            <td>{{ user.invitation_code }}</td>
            <td>
              {{ user.stage_percentage_type }}
            </td>
            <!-- <td class="text-center">{{ user.sale }}</td> -->
            <td>
              <button
                class="btn btn-danger w-auto text-center"
                @click="reject_marketer(user.id, index)"
              >
                <i class="fa-solid fa-cancel"></i>
              </button>
              <button
                class="btn btn-success w-auto text-center mx-2"
                @click="aprrove_marketer(user.id, index)"
              >
                <i class="fa-solid fa-check"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="pending"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchpending"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";

export default {
  name: "Coupons_show",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      pending: [],
      pendingText: "",
      loading: true,
    };
  },
  methods: {
    fetchpending(page = 1) {
      //   axios.defaults.headers.common[
      //     "Authorization"
      //   ] = `Bearer ${localStorage.getItem("token_marketer")}`;
      axios
        .post(`/fetch_pending_marketers?page=${page}`, {
          word: this.pendingText,
        })
        .then((res) => {
          this.pending = res.data.data;
          this.loading = false;
          // console.log(res.data.data, "pending");
        });
    },

    aprrove_marketer(id, index) {
      //   console.log(id, "id");
      axios.post("/aprrove_marketer", { marketer_id: id }).then(({ data }) => {
        if (data.status == true) {
          Swal.fire("", data.message, "success");

          this.pending.data.splice(index, 1); // Remove the deleted item from the array
        } else {
          Swal.fire("", data.message, "error");
        }
      });
    },
    reject_marketer(id, index) {
      axios.post("/reject_marketer", { marketer_id: id }).then(({ data }) => {
        if (data.status == true) {
          Swal.fire("", data.message, "success");

          this.pending.data.splice(index, 1); // Remove the deleted item from the array
        } else {
          Swal.fire("", data.message, "error");
        }
      });
    },
  },
  mounted() {
    this.fetchpending();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_user.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-user {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.btn_trip {
  border: 1px solid #96c28a;
  outline: none;
  padding: 0.5rem 0.8rem;
  color: #96c28a;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 0.2rem;
  font-family: "reqular";
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none !important;
}

.msg {
  border: none;
  outline: none;
  padding: 0.4rem 0.7rem;
  background-color: rgba($color: #e23535, $alpha: 10%);
  border-radius: 4px;
  color: #e23535;
}
.PrimaryButton {
  background-color: #96c28a !important;
}
.fw-bold {
  font-family: "bold" !important;
}
.PrimaryButton[data-v-07d1af3d]:hover {
  border: 1px solid #96c28a !important;
  color: #96c28a !important;
}
.coupons {
  color: #0b1427;
  font-family: "bold";
  font-size: 2rem;
}
</style>
