<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <div class="Trip-options">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-6 col-6 p-0">
          <div class="hotelsSec">
            <div
              class="hotel_title d-flex align-items-center justify-content-start p-2"
            >
              <div class="d-flex align-items-center mx-1">
                <img src="@/assets/media/image/icon/tree2.svg" />
                <h6 class="hotel_settings m-0">{{ $t("Hotel_settings") }}</h6>
              </div>

              <div class="d-flex align-items-center justify-content-center">
                <div
                  class="d-flex align-items-center"
                  v-for="(dest, index) in allDestinations"
                  :key="index"
                >
                  <h6 class="subtitle-hotel-settings m-0">{{ dest.title }}</h6>
                  <img
                    src="@/assets/media/image/icon/arrow-2.svg"
                    width="14"
                    height="14"
                    class="mx-1"
                    :class="{ disable: index == allDestinations.length - 1 }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="package_hotel">
    <div class="hotel_data mb-3" v-for="(hotel, item) in hotels" :key="item">
      <div class="destination">
        <div class="title">{{ $t("Destination") }}</div>
        <div class="content d-flex flex-column">
          <div class="form-group" v-if="types_Value_id !== 1">
            <label class="typo__label custom_label">{{
              $t("choose country")
            }}</label>
            <Multiselect
              v-model="hotel.lunch_country_value"
              :options="countries"
              label="title"
              track-by="id"
              :clear-on-select="true"
              :placeholder="$t('choose country')"
              select-label=""
              :hide-selected="true"
              @keyup="fetch_country"
              :custom-label="formatNationalityLabel"
              @update:model-value="selectCountry(lunch_country_value)"
            >
            </Multiselect>
          </div>
          <div class="form-group">
            <label class="typo__label custom_label">{{
              $t("choose_city")
            }}</label>
            <div class="hotel-form">
              <Multiselect
                v-model="hotel.distination_city"
                :options="cities"
                label="title"
                track-by="id"
                :custom-label="formatCityLabel"
                :clear-on-select="true"
                :placeholder="$t('choose_city')"
                select-label=""
                :hide-selected="true"
                @keyup="FetchCityFromCountry(item)"
                @update:model-value="searchHotels(hotel.distination_city, item)"
              >
              </Multiselect>
            </div>
          </div>
          <div class="form-group">
            <label class="typo__label custom_label">{{
              $t("Choose_hotel")
            }}</label>
            <div class="hotel-form">
              <Multiselect
                v-model="hotel.distination_hotel"
                :options="hotelsSearch"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('Choose_hotel')"
                select-label=""
                :hide-selected="true"
                :custom-label="formatHotelLabel"
                @update:model-value="
                  setHotelRooms(hotel.distination_hotel, item)
                "
              >
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="hotel">
        <div class="title">{{ $t("Hotel") }}</div>
        <div class="content content_hotel_data">
          <div class="form-group">
            <label class="typo__label custom_label">{{ $t("Supplier") }}</label>
            <Multiselect
              v-model="hotel.supplierObj"
              :options="providers"
              label="name"
              track-by="id"
              :custom-label="formatSupplierLabel"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            ></Multiselect>
          </div>
          <div class="form-group">
            <label for="">{{ $t("number of nights") }}</label>
            <input
              type="text"
              name=""
              id=""
              class="form-control"
              v-model="hotel.nights_count"
              :placeholder="$t('number of nights')"
            />
            <div
              v-for="error in v$.hotels.$each.$response.$errors[item]
                .nights_count"
              :key="error"
              class="input-errors"
            >
              {{ error.$message }}
            </div>
          </div>
          <div class="form-group d-flex align-items-center gap-2">
            <label for="person">{{ $t("by_person") }}</label>
            <InputSwitch v-model="hotel.is_person" />
          </div>
        </div>
      </div>
      <div class="price">
        <div class="title">{{ $t("adult price") }}</div>
        <div class="content">
          <div class="content_flex">
            <div
              class="form-group"
              v-for="(room, index) in this.hotels[item].rooms"
              :key="index"
            >
              <label for="">{{ room.title }} ({{ $t("selling") }})</label>
              <input
                type="number"
                name=""
                id=""
                class="form-control"
                v-model="room.adult.selling"
                @keyup="calcTotalSelling(room.room_id)"
              />
            </div>
          </div>
          <div class="content_flex">
            <div
              class="form-group"
              v-for="(room, index) in this.hotels[item].rooms"
              :key="index"
            >
              <label for="">{{ room.title }} ({{ $t("cost") }})</label>
              <input
                type="number"
                name=""
                id=""
                class="form-control"
                v-model="room.adult.cost"
                @keyup="calcTotalSelling(room.room_id)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="price">
        <div class="title">{{ $t("Child Price") }}</div>
        <div class="content">
          <div class="content_flex">
            <div
              class="form-group"
              v-for="(room, index) in this.hotels[item].rooms"
              :key="index"
            >
              <label for="">{{ room.title }} ({{ $t("selling") }})</label>
              <input
                type="number"
                name=""
                id=""
                class="form-control"
                v-model="room.child.selling"
                @keyup="calcTotalSelling(room.room_id)"
              />
            </div>
          </div>
          <div class="content_flex">
            <div
              class="form-group"
              v-for="(room, index) in this.hotels[item].rooms"
              :key="index"
            >
              <label for="">{{ room.title }} ({{ $t("cost") }})</label>
              <input
                type="number"
                name=""
                id=""
                class="form-control"
                v-model="room.child.cost"
                @keyup="calcTotalSelling(room.room_id)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="title"><i class="fa-solid fa-gear"></i></div>
        <div class="content w-100 px-0">
          <div
            class="form-group w-100 d-flex justify-content-center align-items-start"
            v-if="item != 0"
          >
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="removePackageHotel(item)"
            >
              <i class="fa-solid fa-trash-can"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="btn PrimaryButton" @click="appendPackageHotel">
      {{ $t("add_destination") }} <i class="fa-solid fa-circle-plus"></i>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { Multiselect } from "vue-multiselect";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/loading.vue";
import useVuelidate from "@vuelidate/core";

import InputSwitch from "primevue/inputswitch";
import { helpers, required, minValue } from "@vuelidate/validators";

export default {
  name: "hotelSettings",
  props: [
    "dateEnd",
    "dateSrtart",
    "lunchObj",
    "destinations",
    "country_id",
    "editedHotel",
    "providers",
    "types_Value_id",
  ],
  emits: ["saveHotelData", "saveDestinationsData"],
  components: {
    Multiselect,
    Loading,
    InputSwitch,
  },
  watch: {
    editedHotel: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (this.$route.params.id) {
          console.log(newValue, "hotels");
          newValue.forEach((hotel, index) => {
            this.hotels[index] = {
              lunch_country_value: hotel.country,
              distination_city: hotel.city,
              distination_hotel: { id: hotel.id, title: hotel.title },
              supplierObj: hotel.supplier,
              nights_count: hotel.nights,
              is_person: hotel.is_person === 0 ? false : true,
              rooms: hotel.rooms.map((room) => {
                return {
                  id: room.id,
                  room_id: room.room_id,
                  title: room.title,
                  number_of_children: room.number_of_children,
                  number_of_adults: room.number_of_adults,
                  adult: {
                    cost:
                      room.adult.total_adult_cost == ""
                        ? 0
                        : room.adult.total_adult_cost,
                    selling:
                      room.adult.total_adult_selling == ""
                        ? 0
                        : room.adult.total_adult_selling,
                  },
                  child: {
                    cost:
                      room.child.total_child_cost == ""
                        ? 0
                        : room.child.total_child_cost,
                    selling:
                      room.child.total_child_selling == ""
                        ? 0
                        : room.child.total_child_selling,
                  },
                };
              }),
            };
            console.log(this.hotels[index], "hotel loop");
          });
          this.hotels.forEach((hotel) => {
            hotel.rooms.forEach((room) => {
              this.calcTotalSelling(room.room_id);
            });
          });
          this.sendHotelData();
        }
      },
    },
  },

  data() {
    return {
      v$: useVuelidate(),
      loading: false,
      allDestinations: [],
      all_rooms: [],
      selected_rooms: [],
      distination_hotel: "",
      distination_city: "",
      hotelsSearch: [],
      cities: [],
      countries: [],

      hotels: [
        {
          lunch_country_value: "",
          distination_city: "",
          distination_hotel: "",
          number_of_children: "",
          number_of_adults: "",
          is_person: true,
          rooms: [],
          nights_count: 1,
          supplierObj: {},
        },
      ],
      hotelRooms: [],
      rooms_total_prices: [],
    };
  },
  validations() {
    return {
      hotels: {
        $each: helpers.forEach({
          nights_count: {
            required,
            minLength: minValue(1),
          },
          distination_hotel: {
            required,
          },
          supplierObj: {
            required,
          },
          distination_city: {
            required,
          },
        }),
      },
    };
  },
  methods: {
    formatSupplierLabel(supplier) {
      return Object.entries(supplier).length == 0
        ? ""
        : `${supplier.name}  (${supplier.id}) `;
    },
    clearHotelData(item) {
      // Reset relevant properties to empty or default values
      this.hotels[item].distination_hotel = null;
      this.hotels[item].nights_count = "";
      this.hotels[item].supplierObj = {};
      // if(this.hotels[item].rooms.length > 0){
      //   this.hotels[item].rooms.forEach((room) => {
      //     room.adult.selling = "";
      //     room.adult.cost = "";
      //     room.child.selling = "";
      //     room.child.cost = "";
      //   });
      // }
      // this.hotels[item].rooms.length = 0;
      this.hotels[item].rooms = [];
    },
    searchHotels(ele, index) {
      this.clearHotelData(index);
      axios
        .post(`/fetch_hotels_by_city_id`, { city_ids: [ele.id] })
        .then(({ data }) => {
          this.hotelsSearch = data.data.map((hotel) => {
            // Append the rooms data to each hotel
            hotel.rooms = this.all_rooms.map((room) => ({
              title: room.title,
              room_id: room.id,
              number_of_children: room.number_of_children,
              number_of_adults: room.number_of_adults,
              adult: {
                cost: 0,
                selling: 0,
              },
              child: {
                cost: 0,
                selling: 0,
              },
            }));
            return hotel;
          });
          console.log(this.hotelsSearch);
        })
        .catch((err) => console.log(err));
    },

    fetch_country() {
      if (this.types_Value_id) {
        axios.post("/fetch_countries").then(({ data }) => {
          this.countries = data.data;
        });
        this.cities = [];
      } else {
        Swal.fire({
          icon: "warning",
          text: this.$t("Please select type first"),
          confirmButtonText: this.$t("ok"),
        });
      }
    },
    formatNationalityLabel(nationality) {
      return `${nationality.title} - ${nationality.code} (${nationality.id})`;
    },
    selectCountry(country_id) {
      if (country_id != undefined) {
        this.$emit("selectedCountryID", country_id.id);
      }
    },
    formatHotelLabel(hotel) {
      return `${hotel.title} - (${hotel.id})`;
    },
    isHotelSelected(hotelId, excludeIndex) {
      return this.hotels.some((hotel, index) => {
        return (
          index !== excludeIndex && hotel.distination_hotel?.id === hotelId
        );
      });
    },

    setHotelRooms(hotelRooms, item) {
      const selectedHotelId = hotelRooms.id;
      if (this.isHotelSelected(selectedHotelId, item)) {
        // Show an alert
        Swal.fire({
          text: this.$t(
            "This hotel is already selected in another destination."
          ),
          icon: "warning",
          confirmButtonText: this.$t("ok"),
        });

        // Clear the selected hotel
        this.hotels[item].distination_hotel = null;
      } else {
        // Proceed with setting the hotel rooms
        this.hotels[item].rooms = hotelRooms.rooms;
      }
    },
    formatCityLabel(city) {
      return Object.entries(city).length == 0
        ? ""
        : `${city.title}  (${city.id}) `;
    },

    FetchCityFromCountry(index) {
      let basic_country_ids = {
        country_id: [
          this.hotels[index].lunch_country_value.id
            ? this.hotels[index].lunch_country_value.id
            : 598,
        ],
      };

      // console.log(basic_country_ids.country_id, index, "basic_country_ids");
      // console.log(this.types_Value_id, "types_Value_id");

      if (this.types_Value_id) {
        // console.log(this.types_Value_id, "types_Value_id");
        // this.cities = [];
        axios
          .post("/fetch_cities_by_country_id", basic_country_ids)
          .then(({ data }) => {
            this.cities = data.data;
            // console.log(this.cities, "cities");
          });
      } else {
        Swal.fire({
          title: "",
          text: this.$t("please select country"),
          icon: "warning",
        });
      }
    },

    fetch_contract_by_hotel_id(distIndex) {
      if (this.allDestinations[distIndex].is_contract) {
        this.allDestinations[distIndex].rooms = [];
        this.allDestinations[distIndex].selected_rooms = [];

        const data = {
          hotel_id: this.allDestinations[distIndex].hotel.id,
          from_date: this.dateSrtart,
          to_date: this.dateEnd,
        };
        axios
          .post("/fetch_hotel_contracts_by_hotel_id", data)
          .then((res) => {
            this.allDestinations[distIndex].contracts = res.data.data;
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    fetch_rate_code_by_contract_id(index) {
      axios
        .post(`/fetch_hotel_rate_codes_by_contract_id`, {
          contract_id: this.allDestinations[index].contract.id,
          from_date: this.dateSrtart,
          to_date: this.dateEnd,
        })
        .then((data) => {
          this.allDestinations[index].rate_codes = data.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    fetch_contract_rooms(index) {
      axios
        .post(`/get_customization_rooms`, {
          rate_code: this.allDestinations[index].rate_code.id,
          start_date: this.dateSrtart,
          end_date: this.dateEnd,
        })
        .then(({ data }) => {
          this.allDestinations[index].rooms = data.data.rooms.map((room) => {
            return {
              ...room,
              adults: {
                cost: room.cost,
                selling: room.selling_price,
              },
              childrens: {
                cost: room.cost,
                selling: room.selling_price,
              },
            };
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    fetch_all_rooms() {
      axios.post(`fetch_all_rooms`, { service_type: 2 }).then(({ data }) => {
        this.all_rooms = data.data.map((room) => {
          return {
            ...room,
            adult: {
              cost: 0,
              selling: 0,
            },
            child: {
              cost: 0,
              selling: 0,
            },
          };
        });

        this.rooms_total_prices = data.data.map((ele) => {
          return {
            title: ele.title,
            room_id: ele.id,
            adult: {
              total_adult_cost: 0,
              total_adult_selling: 0,
              penifit: 0,
            },
            child: {
              total_child_cost: 0,
              total_child_selling: 0,
              penifit: 0,
            },
          };
        });
        // console.log(this.rooms_total_prices)
      });
    },
    updateHotelsRooms() {
      this.allDestinations.forEach((hotel) => {
        hotel.selected_rooms = this.selected_rooms.map((ele) => {
          return {
            ...ele,
            adults: {
              cost: 0,
              selling: 0,
            },
            childrens: {
              cost: 0,
              selling: 0,
            },
          };
        });
      });
    },
    appendPackageHotel() {
      this.hotels.push({
        lunch_country_value: "",
        distination_city: "",
        distination_hotel: "",
        is_person: true,
        rooms: [],
        nights_count: 1,
        supplierObj: {},
      });
    },
    removePackageHotel(index) {
      this.hotels.splice(index, 1);
    },
    calcTotalSelling(roomId) {
      let total_adult_selling = 0;
      let total_adult_cost = 0;
      let total_child_selling = 0;
      let total_child_cost = 0;

      this.hotels.forEach((hotel) => {
        hotel.rooms.forEach((room) => {
          console.log(room.number_of_adults, "number of adults");
          if (room.room_id == roomId) {
            total_adult_selling = total_adult_selling + room.adult.selling;
            total_adult_cost = total_adult_cost + room.adult.cost;
            total_child_selling = total_child_selling + room.child.selling;
            total_child_cost = total_child_cost + room.child.cost;
            console.log(total_adult_selling);
            this.rooms_total_prices.forEach((ele) => {
              if (ele.room_id == roomId) {
                ele.adult.total_adult_selling = total_adult_selling;
                ele.adult.total_adult_cost = total_adult_cost;
                ele.child.total_child_selling = total_child_selling;
                ele.child.total_child_cost = total_child_cost;
                ele.adult.penifit = 0;
                ele.child.penifit = 0;
              }
            });
            console.log(this.rooms_total_prices);
          }
        });
      });
    },
    // fetchEditRooms() {
    //   // console.log(this.editedHotel, "editedHotel");
    //   this.editedHotel.forEach((hotel, index) => {
    //     // Assuming you have a reactive data property named 'editedHotels'
    //     this.hotels[index] = {
    //       distination_city: hotel.city,
    //       distination_hotel: { id: hotel.id, title: hotel.title },
    //       nights_count: hotel.nights,
    //       rooms: hotel.rooms.map((room) => {
    //         return {
    //           room_id: room.id,
    //           adult: {
    //             cost: room.adult_cost,
    //             selling: room.adult_selling,
    //           },
    //           child: {
    //             cost: room.child_cost,
    //             selling: room.child_selling,
    //           },
    //         };
    //       }),
    //     };
    //   });
    // },
    sendHotelData() {
      let hotels = this.hotels.map((hotel) => {
        return {
          country_id: hotel.lunch_country_value?.id
            ? hotel.lunch_country_value?.id
            : 598,
          city_id: hotel.distination_city?.id,
          hotel_id: hotel.distination_hotel?.id,
          nights: hotel.nights_count,
          supplier_id: hotel.supplierObj?.id,
          is_contract: 0,
          contract_id: null,
          rate_code_id: null,
          is_person: hotel.is_person === true ? 1 : 0,
          rooms: hotel.rooms.map((room) => {
            return {
              room_id: room.room_id,
              title: room.title,
              number_of_adults: room.number_of_adults,
              number_of_children: room.number_of_children,
              is_person: hotel.is_person,
              adult: {
                sendCost:
                  room.adult.cost === ""
                    ? 0
                    : hotel.is_person
                      ? room.adult.cost
                      : room.adult.cost / room.number_of_adults,
                cost: room.adult.cost,
                sendSelling:
                  room.adult.selling === ""
                    ? 0
                    : hotel.is_person
                      ? room.adult.selling
                      : room.adult.selling / room.number_of_adults,
                selling: room.adult.selling,
              },
              child: {
                sendCost:
                  room.child.cost === ""
                    ? 0
                    : hotel.is_person
                      ? room.child.cost
                      : room.child.cost / room.number_of_children,
                cost: room.child.cost,
                sendSelling:
                  room.child.selling === ""
                    ? 0
                    : hotel.is_person
                      ? room.child.selling
                      : room.child.selling / room.number_of_children,
                selling: room.child.selling,
              },
            };
          }),
        };
      });
      // console.log(hotels);
      let destinations = this.hotels.map((hotel) => {
        return {
          city_id: hotel.distination_city?.id,
          nights_count: hotel.nights_count,
        };
      });

      if (this.hotels.length === 1) {
        // console.log(hotels);
        this.$emit("saveHotelData", hotels, hotels[0].rooms);
      } else if (this.hotels.length > 1) {
        console.log(hotels, "elsayed");
        const mappedRooms = hotels.flatMap((hotel) => hotel.rooms);
        console.log(mappedRooms, "fathy");
        const summedRooms = mappedRooms.reduce((acc, room) => {
          const existingRoom = acc.find((r) => r.room_id === room.room_id);

          if (existingRoom) {
            existingRoom.adult.cost += room.adult.cost;
            existingRoom.adult.selling += room.adult.selling;
            existingRoom.child.cost += room.child.cost;
            existingRoom.child.selling += room.child.selling;
            existingRoom.adult.sendSelling += room.adult.sendSelling;
            existingRoom.adult.sendCost += room.adult.sendCost;
            existingRoom.child.sendSelling += room.child.sendSelling;
            existingRoom.child.sendCost += room.child.sendCost;
          } else {
            acc.push({
              room_id: room.room_id,
              title: room.title,
              number_of_adults: room.number_of_adults,
              number_of_children: room.number_of_children,
              adult: {
                cost: room.adult.cost,
                selling: room.adult.selling,
                sendCost: room.adult.sendCost,
                sendSelling: room.adult.sendSelling,
              },
              child: {
                cost: room.child.cost,
                selling: room.child.selling,
                sendCost: room.child.sendCost,
                sendSelling: room.child.sendSelling,
              },
            });
          }

          return acc;
        }, []);
        console.log(summedRooms);
        this.$emit("saveHotelData", hotels, summedRooms);
      }

      this.$emit("saveDestinationsData", destinations);
    },
  },
  mounted() {
    // this.types_Value_id = this.$route.params.type == "tourism" ? 2 : 1;
    this.fetch_all_rooms();
  },
  updated() {
    this.sendHotelData();
  },
};
</script>

<style lang="scss" scoped>
$PrimaryColor: #0a909b;
@import url("./_packageHotel.scss");
</style>
