<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3 mb-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("employ") }}</h4>
      </div>
      <router-link :to="{ name: 'AddEmpolyment' }" class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add_employm") }}</router-link
      >
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table empolyment" id="employTable">
          <thead>
            <tr>
              <th class="text-center">ID</th>
              <th class="text-center">{{ $t("empoly_name") }}</th>
              <th class="text-center">{{ $t("phone_num") }}</th>
              <th class="text-center">{{ $t("email") }}</th>
              <th class="text-center">{{ $t("role") }}</th>
              <th class="text-center">{{ $t("the_salary") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 7" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in empolyment.data" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.Permission_role?.name }}</td>
              <td>{{ item.salary }}</td>
              <td>
                <!-- <router-link to="/questionstype2"><i class="fa-solid fa-eye btn-sm show_details" :title="$t('show')"></i></router-link> -->
                <router-link
                  v-if="item.id != 1"
                  class="btn btn-success text-white mx-2 edit_btn"
                  :to="`/employees/edit/${item.id}`"
                  :title="$t('edit')"
                >
                  <i class="fa-solid fa-pen"></i
                ></router-link>
                <button
                  v-if="item.id != 1"
                  class="btn btn-danger text-white mx-2 remove_btn"
                  @click="DeleteAlert(index)"
                >
                  <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                </button>
                <router-link
                  :to="`/employee-logs/${item.id}`"
                  class="btn btn-info text-white mx-2 edit_btn"
                  :title="$t('permissions')"
                >
                  <i class="fa-solid fa-eye"></i>
                </router-link>

                <!-- <whatsAppScan :employeeId="item.id" /> -->

                <!-- <router-link
                  class="btn btn-info text-white mx-2 edit_btn"
                  :to="`/permission/${item.id}`"
                  :title="$t('permissions')"
                >
                  <i class="fa-solid fa-lock"></i>
                </router-link> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="empolyment"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchEmploy"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import $ from "jquery";
import Skeleton from "primevue/skeleton";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
// import whatsAppScan from "@/components/employee/whatsAppScan.vue";
export default {
  name: "all-employment",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
    // whatsAppScan,
  },
  data() {
    return {
      empolyment: [],
      loading: true,
    };
  },
  methods: {
    // fetch empolyment data
    FetchEmploy(page = 1) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/organization_employees?page=" + page).then(({ data }) => {
        this.empolyment = data.data;
        this.loading = false;
        //  console.log(this.empolyment)
      });
    },
    //delete empolyment
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.empolyment.data[index].id;
          const fromClick = true;
          axios
            .delete("/organization_employees/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.empolyment.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.FetchEmploy();
  },
  updated() {
    // axios.get("API_Goes_Here").then((response) => {
    //   this.products = response.data;
    // });
    $("#employTable").DataTable({
      bPaginate: false,
      retrieve: true,
      // displayStart: 5,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("employ"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("employ"),
      },
    });
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_employment";
</style>
