<template>
  <section class="add_recipt card_style">
    <div class="main_header_style">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
        <h4 class="mb-0">
          {{ $t("add_traansfer") }}
        </h4>
      </div>
    </div>
    <form class="mt-3" @submit.prevent="addTransfer()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("date_time_transfer") }}</label>
            <datepicker
              v-model="date"
              name="from_date"
              :placeholder="$t('from')"
              :disabled="true"
            ></datepicker>
          </div>
        </div>
        <!-- finish input group -->
        <div class="col-12"></div>

        <!-- start input group -->
        <div class="col-12 col-lg-6 mb-3">
          <div class="transfer">
            <div class="">
              <h4>{{ $t("from") }}</h4>
            </div>
            <div class="transfer_container">
              <div
                class="row"
                v-for="(fromAccount, index) in fromAccounts"
                :key="index"
              >
                <div class="col-3 d-flex align-items-center">
                  <div class="form-group mb-0">
                    <label for="from_value" class="form-label">{{
                      $t("the value")
                    }}</label>
                    <input
                      type="number"
                      id="from_value"
                      class="form-control"
                      :placeholder="0"
                      min="0"
                      v-model="fromAccount.amountFrom"
                    />
                  </div>
                </div>
                <div class="col-7 d-flex align-items-center">
                  <div class="input-group">
                    <label for="from_acc" class="form-label">{{
                      $t("the account")
                    }}</label>
                    <Multiselect
                      v-model="fromAccount.branch_from"
                      :options="branches.map((branch) => branch.id)"
                      :custom-label="
                        (opt) =>
                          branches.find((x) => x.id == opt).title_ar +
                          ' (' +
                          branches.find((x) => x.id == opt).parent_name +
                          ')'
                      "
                      :clear-on-select="true"
                      :placeholder="$t('account')"
                      select-label=""
                      :hide-selected="true"
                    >
                    </Multiselect>
                  </div>
                </div>
                <div class="col-2 d-flex align-items-center">
                  <div
                    class="addAccount"
                    @click="addFromAccount()"
                    v-if="index == 0"
                  >
                    <i class="fa-solid fa-plus"></i>
                  </div>
                  <div
                    class="removeAccount"
                    @click="removeFromAccount(index)"
                    v-else
                  >
                    <i class="fa-solid fa-minus"></i>
                  </div>
                </div>

                <div class="col-lg-10">
                  <label>{{ $t("Cost_center") }}</label>
                  <multiselect
                    v-model="fromAccount.costCenter"
                    :options="fromAccount.costCenters"
                    :clear-on-select="true"
                    :placeholder="$t('Cost_center')"
                    label="title"
                    track-by="id"
                    select-label=""
                    :hide-selected="true"
                  >
                  </multiselect>
                </div>
                <div class="col-lg-10">
                  <label>{{ $t("construct statement") }}</label>
                  <input
                    type="text"
                    v-model="fromAccount.constructFrom"
                    class="form-control w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6 mb-3">
          <div class="transfer">
            <div class="">
              <h4>{{ $t("to") }}</h4>
            </div>
            <div class="transfer_container">
              <div
                class="row"
                v-for="(toAccount, index) in toAccounts"
                :key="index"
              >
                <div class="col-3 d-flex align-items-center">
                  <div class="form-group mb-0">
                    <label for="to_value" class="form-label">{{
                      $t("the value")
                    }}</label>
                    <input
                      type="number"
                      id="to_value"
                      class="form-control"
                      min="0"
                      :placeholder="0"
                      v-model="toAccount.amountTo"
                    />
                  </div>
                </div>
                <div class="col-7 d-flex align-items-center">
                  <div class="input-group">
                    <label for="to_acc" class="form-label">{{
                      $t("the account")
                    }}</label>
                    <Multiselect
                      v-model="toAccount.branch_to"
                      :options="branches.map((branch) => branch.id)"
                      :custom-label="
                        (opt) =>
                          branches.find((x) => x.id == opt).title_ar +
                          ' (' +
                          branches.find((x) => x.id == opt).parent_name +
                          ')'
                      "
                      :clear-on-select="true"
                      :placeholder="$t('account')"
                      select-label=""
                      :hide-selected="true"
                    >
                    </Multiselect>
                  </div>
                </div>
                <div class="col-2 d-flex align-items-center">
                  <div
                    class="addAccount"
                    @click="addToAccount()"
                    v-if="index == 0"
                  >
                    <i class="fa-solid fa-plus"></i>
                  </div>
                  <div
                    class="removeAccount"
                    @click="removeToAccount(index)"
                    v-else
                  >
                    <i class="fa-solid fa-minus"></i>
                  </div>
                </div>

                <div class="col-lg-10">
                  <label>{{ $t("Cost_center") }}</label>
                  <multiselect
                    v-model="toAccount.costCenterTo"
                    :options="toAccount.costCenters"
                    :clear-on-select="true"
                    :placeholder="$t('Cost_center')"
                    label="title"
                    track-by="id"
                    select-label=""
                    :hide-selected="true"
                  ></multiselect>
                </div>
                <div class="col-lg-10">
                  <label>{{ $t("construct statement") }}</label>
                  <input
                    type="text"
                    v-model="toAccount.constructTo"
                    class="form-control w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="form-label"> {{ $t("statement") }}</label>
            <textarea
              class="form-control"
              :placeholder="$t('write here....')"
              v-model="text"
            ></textarea>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12">
          <button
            v-if="isButtonVisible"
            type="submit"
            class="btn PrimaryButton w-25"
          >
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
export default {
  name: "add-construct",
  components: {
    Multiselect,
    Datepicker,
    AddFavDailog,
  },
  computed: {
    isButtonVisible() {
      return (
        this.fromAccounts.some((account) => account.amountFrom > 0) &&
        moment(this.date).format("YYYY-MM-DD") &&
        this.fromAccounts.every((account) => account.branch_from !== null) &&
        this.toAccounts.some((account) => account.amountTo > 0) &&
        this.toAccounts.every((account) => account.branch_to !== null) &&
        this.fromAccounts.reduce(
          (total, account) => total + account.amountFrom,
          0
        ) ===
          this.toAccounts.reduce(
            (total, account) => total + account.amountTo,
            0
          )
      );
    },
  },
  data() {
    return {
      branches: [],
      fromAccounts: [
        {
          id: 1,
          branch_from: null,
          amountFrom: 0,
          constructFrom: "",
          costCenter: null,
          costCenters: [],
        },
      ],
      toAccounts: [
        {
          id: 1,
          branch_to: null,
          amountTo: 0,
          constructTo: "",
          costCenterTo: null,
          costCenters: [],
        },
      ],
      date: new Date(),
      text: "",
    };
  },
  methods: {
    addFromAccount() {
      this.fromAccounts.push({
        id: this.fromAccounts.length,
        branch_from: null,
        amountFrom: 0,
        constructFrom: "",
        costCenter: null,
        costCenters: [],
      });
    },
    removeFromAccount(index) {
      this.fromAccounts.splice(index, 1);
    },
    addToAccount() {
      this.toAccounts.push({
        id: this.toAccounts.length,
        branch_to: null,
        amountTo: 0,
        constructTo: "",
        costCenterTo: null,
        costCenters: [],
      });
    },
    removeToAccount(index) {
      this.toAccounts.splice(index, 1);
    },
    async addTransfer() {
      let totalAmountFrom = 0;
      this.fromAccounts.forEach((account) => {
        totalAmountFrom = totalAmountFrom + account.amountFrom;
      });
      let totalAmountTo = 0;
      this.toAccounts.forEach((account) => {
        totalAmountTo = totalAmountTo + account.amountTo;
      });
      // console.log(totalAmountFrom);
      // console.log(totalAmountTo);
      if (totalAmountFrom == totalAmountTo) {
        let daily_restriction = {
          text: this.text,
          type: 1,
          date: moment(this.date).format("YYYY-MM-DD"),
          from: this.fromAccounts.map((account) => {
            return {
              branch_id: account.branch_from,
              amount: account.amountFrom,
              report: account.constructFrom,
              cost_center_id: account.costCenter ? account.costCenter.id : "",
            };
          }),
          to: this.toAccounts.map((account) => {
            return {
              branch_id: account.branch_to,
              amount: account.amountTo,
              report: account.constructTo,
              cost_center_id: account.costCenterTo
                ? account.costCenterTo.id
                : "",
            };
          }),
        };
        await axios
          .post("/daily_restriction", daily_restriction)
          .then(({ data }) => {
            // console.log(data);
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/restrictions");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.message,
              icon: "error",
            });
          });
      } else {
        Swal.fire({
          title: "",
          text: "from Transfer not equal to Transfer",
          icon: "error",
        });
      }
    },
    async fetch_cost_centers() {
      await axios.post("/fetch_cost_center").then(({ data }) => {
        const costCentersData = data.data.data;

        this.fromAccounts.forEach((account) => {
          account.costCenters = costCentersData;
        });

        this.toAccounts.forEach((account) => {
          account.costCenters = costCentersData;
        });
      });
    },
    async getBranches() {
      await axios.get("/get_latest_branch").then(({ data }) => {
        this.branches = data.data;
      });
    },
  },
  mounted() {
    this.getBranches();
    this.fetch_cost_centers();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./_constructor.scss";
.form_topic {
  width: 100%;
}
.transfer {
  .title {
    h2 {
      text-align: center;
      font-family: "bold";
      font-size: 20px;
      color: #0b1427;
    }
  }
  .transfer_container {
    // border: 1px solid #9CA2AC;
    border-radius: 0 0 10px 10px;
    border-top: 0;
    label {
      font-family: "regular" !important;
    }
  }
  .addAccount,
  .removeAccount {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #fff;
    background-color: #0a909b;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
  }
  .removeAccount {
    background-color: #e23535;
  }
}
textarea {
  height: 150px;
}

input.form-control.w-100 {
  height: 10vh;
}
.form_topic .form-group .form-control {
  padding: 0.65rem !important;
}
</style>
