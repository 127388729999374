<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <div class="container" v-else>
    <div class="hotel_details">
      <div class="head">
        <div class="image">
          <img :src="hotel.image" :alt="hotel.title" />
        </div>
        <div class="details_head">
          <h4 class="title">{{ hotel.title }}</h4>
          <p class="address">
            <i class="fa-solid fa-map-pin"></i> {{ hotel.address }}
          </p>
          <ul class="services">
            <li v-for="(service, index) in hotel.services" :key="index">
              {{ service.title }}
            </li>
          </ul>
        </div>
        <div class="details_price">
          <div class="prices">
            <h4 class="price">
              {{ hotel.price }}
              <span class="text_price">
                / {{ $t("Less price for the night") }}</span
              >
            </h4>
            <!-- <router-link :to="`/hotel/${hotel.id}`" class="btn PrimaryButton">{{$t("show_details")}} <i class="fa-solid fa-chevron-left"></i></router-link> -->
            <!-- <p class="offers">{{$t("Get more prices and offers from")}} <span class="here">{{$t("here")}}</span></p> -->
          </div>
          <div class="rate">
            <p class="number_rate">{{ hotel.starCount }}</p>
            <div class="number_assessments">
              ({{ hotel.number_assessments }} {{ $t("evaluation") }})
            </div>
          </div>
        </div>
      </div>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="details-tab"
            data-bs-toggle="tab"
            data-bs-target="#details-tab-pane"
            type="button"
            role="tab"
            aria-controls="details-tab-pane"
            aria-selected="false"
          >
            {{ $t("general_details") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="images-tab"
            data-bs-toggle="tab"
            data-bs-target="#images-tab-pane"
            type="button"
            role="tab"
            aria-controls="images-tab-pane"
            aria-selected="false"
          >
            {{ $t("images") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="address-tab"
            data-bs-toggle="tab"
            data-bs-target="#address-tab-pane"
            type="button"
            role="tab"
            aria-controls="address-tab-pane"
            aria-selected="false"
          >
            {{ $t("address") }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="rooms-tab"
            data-bs-toggle="tab"
            data-bs-target="#rooms-tab-pane"
            type="button"
            role="tab"
            aria-controls="rooms-tab-pane"
            aria-selected="true"
          >
            {{ $t("rooms") }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <generalDetails
          :services="hotel.services"
          :title="hotel.title"
          :rate="hotel.starCount"
          :address="hotel.address"
          :description="hotel.description"
        />
        <Images :images="hotel.images" />
        <Address
          :description="hotel.address"
          :lat="hotel.lat"
          :long="hotel.long"
        />
        <!-- <Rooms :rooms="hotel.rooms" /> -->

        <div
          class="tab-pane fade show active"
          id="rooms-tab-pane"
          role="tabpanel"
          aria-labelledby="rooms-tab"
          tabindex="0"
        >
          <div class="check_room_is_empty" v-if="roomsSelected">
            <div class="room_selected">
              <!--  v-if="hotelId == hotel.id || hotelIdLocal == hotel.id" -->
              <h5 class="title">{{ $t("room_selected") }}</h5>
              <div
                class="card_room_selected"
                v-for="(room, index) in roomsSelected.hotel_rooms"
                :key="index"
              >
                <div class="card_quantity">
                  <div class="details">
                    <p class="title">
                      {{ room.room_index }} - {{ room.room_type_name }}
                    </p>
                    <!-- <ul class="services">
                                            <li v-for="(service, index) in room.services" :key="index">
                                                <i class="fa-solid fa-check"></i> {{ service.title }}
                                            </li>
                                        </ul> -->
                  </div>
                  <div class="price_quantity">
                    <p class="price">
                      {{ room.room_rate.total_fare }} /
                      {{ room.room_rate.currency }}
                    </p>
                    <div class="quantity">
                      <button class="btn minus" @click="minusQuantity(index)">
                        <i class="fa-solid fa-minus"></i>
                      </button>
                      <span class="number">{{ room.quantity }}</span>
                      <button class="btn add" @click="addQuantity(index)">
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  class="btn remove"
                  @click="removeRooms(index)"
                >
                  <i class="fa-regular fa-circle-xmark"></i>
                </button>
              </div>
              <div class="buttons">
                <button
                  type="button"
                  class="btn PrimaryButton"
                  @click="newBookCheckPrice"
                >
                  {{ $t("book_now") }}
                </button>
              </div>
            </div>
          </div>
          <div class="show_offers">
            <div
              class="line_offer"
              v-if="offers.length && lengthOffers == true"
            ></div>
            <div
              class="head_offer"
              v-if="offers.length && lengthOffers == true"
            >
              <h4 class="title">{{ $t("Other prices offers") }}</h4>
              <!-- <i class="fa-solid fa-xmark" @click="removeOffers()"></i> -->
            </div>
            <hotelOffers
              :offers="offers"
              :hotel_id="hotel.id"
              v-if="offers.length && lengthOffers == true"
              :hotel_code="hotel_code"
              :guest_nationality="guest_nationality"
              :hotel_name="hotel_name"
              :resultIndex="resultIndex"
              :session_id="session_id"
              @rooms-selected="receiveData"
              @hotel-selected="receiveHotelId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import generalDetails from "@/components/hotels/details/generalDetails.vue";
import Images from "@/components/hotels/details/images.vue";
import Address from "@/components/hotels/details/address.vue";
import hotelOffers from "@/components/hotels/hotelOffers.vue";
// import Rooms from '@/components/hotels/details/rooms.vue';
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/loading.vue";

export default {
  name: "hotel-details",
  data() {
    return {
      hotel: {},
      guest_nationality: null,
      hotel_code: null,
      hotel_name: null,
      resultIndex: null,
      session_id: null,
      offers: [],
      lengthOffers: false,
      roomsSelected: [],
      hotelId: "",
      hotelIdLocal: "",
      loading: false,
    };
  },
  components: {
    generalDetails,
    Images,
    Address,
    hotelOffers,
    Loading,
    // Rooms
  },
  methods: {
    // fetch hotels
    FetchHotels() {
      let hotel_id = { hotel_id: this.$route.params.id };
      axios.post("/fetch_hotel_details", hotel_id).then(({ data }) => {
        this.hotel = data.data;
        // console.log(this.hotel)
      });
    },
    getHotelData() {
      let hotel_data = JSON.parse(localStorage.getItem("hotelData"));
      this.guest_nationality = hotel_data.guest_nationality;
      this.hotel_code = hotel_data.hotel_code;
      this.hotel_name = hotel_data.hotel_name;
      this.resultIndex = hotel_data.resultIndex;
      this.session_id = hotel_data.session_id;
    },

    // fetch offers
    FetchOffers() {
      this.loading = true; //the loading begin

      let filter = {
        session_id: this.session_id,
        result_index: this.resultIndex,
        hotel_code: this.hotel_code,
        response_time: 0,
      };
      axios
        .post("/fetch_available_rooms", filter)
        .then(({ data }) => {
          if (data.status == true) {
            this.offers = data.data;
            this.lengthOffers = true;
            // console.log(this.offers)

            let bookDetails = JSON.parse(localStorage.getItem("bookDetails"));

            // Update the hotel_name property
            bookDetails.hotel_name = this.hotel_name;

            // Save the updated bookDetails back to localStorage
            localStorage.setItem("bookDetails", JSON.stringify(bookDetails));

            this.loading = false; //the loading ended
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: data.message,
              timer: 3000,
            });
            this.loading = false; //the loading ended
          }
        })
        .catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
          });
          this.loading = false; //the loading ended
          return error;
        })
        .finally(() => {
          //Perform action in always
        });
    },

    newBookCheckPrice() {
      this.loading = true; //the loading begin
      const bookDetails = JSON.parse(localStorage.getItem("bookDetails"));
      const bookData = JSON.parse(localStorage.getItem("bookData"));
      for (let index = 0; index < bookData.hotel_rooms.length; index++) {
        this.room_id = bookData.hotel_rooms[index].room_index;
        this.room_count = bookData.hotel_rooms[index].quantity;
        this.room_fare = bookData.hotel_rooms[index].room_rate.room_fare;
        this.room_tax = bookData.hotel_rooms[index].room_rate.room_tax;
        this.total_fare = bookData.hotel_rooms[index].room_rate.total_fare;
      }
      const checkPrice = {
        session_id: bookData.session_id,
        result_index: bookData.result_index,
        response_time: bookDetails.response_time,
        hotel_code: bookData.hotel_code,
        rooms: [
          {
            room_id: this.room_id,
            room_count: this.room_count,
            room_fare: this.room_fare,
            room_tax: this.room_tax,
            total_fare: this.total_fare,
          },
        ],
      };
      axios
        .post("/check_availability_and_price", checkPrice)
        .then(({ data }) => {
          this.is_available_for_confirm_book =
            data.data.is_available_for_confirm_book;
          this.is_available_for_book = data.data.is_available_for_book;
          if (
            this.is_available_for_confirm_book == true &&
            this.is_available_for_book == true
          ) {
            this.loading = false; //the loading ended
            this.$router.push("/new_book");
          } else {
            this.loading = false; //the loading ended
            Swal.fire({
              position: "center",
              icon: "error",
              title: this.$t("room_not_available"),
              timer: 3000,
            });
          }
        });
    },

    // removeOffers() {
    //     this.lengthOffers = false;
    // },

    receiveData(data) {
      this.roomsSelected = data;
      // console.log(this.roomsSelected)
    },
    receiveHotelId(data) {
      this.hotelId = data;
    },

    minusQuantity(index) {
      if (this.roomsSelected.hotel_rooms[index].quantity > 1) {
        this.roomsSelected.hotel_rooms[index].quantity -= 1;
        this.updateLocalStorage();
      }
    },
    addQuantity(index) {
      this.roomsSelected.hotel_rooms[index].quantity += 1;
      this.updateLocalStorage();
    },

    removeRooms(index) {
      this.roomsSelected.hotel_rooms.splice(index, 1); // Remove 1 item at the specified index
      this.updateLocalStorage();
    },

    updateLocalStorage() {
      localStorage.setItem("bookData", JSON.stringify(this.roomsSelected));
      if (!this.roomsSelected.length) {
        localStorage.removeItem("hotelId");
      }
    },
  },
  created() {
    this.FetchHotels();
    this.getHotelData();
    this.FetchOffers();

    const roomsSelected = localStorage.getItem("bookData");
    const hotelId = localStorage.getItem("bookDetails");
    this.roomsSelected = JSON.parse(roomsSelected);
    if (roomsSelected) {
      this.hotelIdLocal = JSON.parse(hotelId).hotel_code;
      // console.log(this.hotelIdLocal);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./_hotels.scss";
.room_card {
  padding-top: 1rem;
}
</style>
