<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        {{ $t("Blogs") }}
      </h4>
    </div>
    <div class="main_header_style mb-2">
      <input
        type="text"
        v-model="blogsText"
        @keyup="fetchBlogs((page = 1))"
        class="form-control w-50"
        :placeholder="$t('search...')"
      />
      <router-link :to="`/blogs/${type}/add`" class="PrimaryButton mt-0 btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add blog") }}
      </router-link>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table blogs">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("Image") }}</th>
              <th>{{ $t("Title") }}</th>
              <th>{{ $t("describtion") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="blog in 10" :key="blog">
              <td>
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
              <td width="10%">
                <Skeleton shape="circle" size="5rem"></Skeleton>
              </td>
              <td v-for="blog in 3" :key="blog">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(blog, index) in blogs.data" :key="index">
              <td>{{ blog.id }}</td>
              <td><img :src="blog.image" :alt="blog.title" /></td>
              <td>{{ blog.title }}</td>
              <td>
                <div v-html="blog.content.substring(0, 50)"></div>
              </td>
              <td>
                <router-link
                  :to="`/blogs/${type}/edit/${blog.id}`"
                  class="btn btn-success mx-1 w-auto"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>

                <div
                  class="btn btn-danger mx-1 w-auto"
                  @click="deleteBlog(blog.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="blogs"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchBlogs"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
export default {
  name: "blogs-website",
  data() {
    return {
      blogs: [],
      blogsText: "",
      loading: true,
      type: this.$route.params.type,
    };
  },
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  methods: {
    async fetchBlogs(page = 1) {
      const formData = new FormData();
      formData.append("word", this.blogsText);
      if(this.$route.params.type === "portal") {
        formData.append("is_portal", 1);
      }
      await axios
        .post(`/blogs?page=${page}`, formData)
        .then(({ data }) => {
          this.blogs = data.data;
          this.loading = false;
        });
    },
    deleteBlog(blogId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios.delete(`/website/blogs/${blogId}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.blogs.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
          });
        }
      });
    },
  },
  mounted() {
    this.fetchBlogs();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style scoped>
table tbody tr td img {
  width: 100px;
  height: 100px;
}
</style>
