<template>
   <section class="welcome">
        <div class="travel-home">
            <div class="container">
                <img src="../../assets/media/image/logo.png" alt="logo">
                <h3>{{$t("welcome_to")}} TRAVEL SOFTTECH</h3>
                <p>{{$t("start_setup_text")}}</p>
                <router-link to="/setting" class="btn">{{$t("start_now")}}</router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "welcome-setup",
    created() {
        localStorage.removeItem("flightData");
        localStorage.removeItem("flightTrips");
        localStorage.removeItem("bookDataFlight");
        localStorage.removeItem("bookDetails");
        localStorage.removeItem("hotelData");
        localStorage.removeItem("bookData");
    }
}
</script>

<style lang="scss" scoped>
    @import "./_setup.scss";
</style>