<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center mb-2 gap-3">
      <AddFavDailog v-tooltip.left="$t('add to favorites')" />
      <h4>
        {{ $t("VISA TYPES") }}
      </h4>
    </div>
    <div class="main_header_style mb-2">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="search-visa w-100">
          <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control w-50"
              :placeholder="$t('search...')"
              v-model="visaText"
              @keyup="fetchAllVisa((page = 1))"
            />
          </form>
        </div>
        <router-link to="/visa/add" class="PrimaryButton mt-0 btn w-25"
          ><i class="fa-solid fa-plus"></i> {{ $t("add visa type") }}
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table visaType" id="VisaTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("visa type") }}</th>
              <th>{{ $t("visa country") }}</th>
              <th>{{ $t("Entry type") }}</th>
              <th>{{ $t("Duration of stay") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="visa in 10" :key="visa">
              <td v-for="visa in 6" :key="visa">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(visa, index) in allVisa.data" :key="index">
              <td>{{ visa.id }}</td>
              <td>{{ visa.type_name }}</td>
              <td>{{ visa.country == null ? "" : visa.country.title }}</td>
              <td>
                {{
                  visa.accessibility == 2
                    ? $t("multiple")
                    : visa.accessibility == 1
                    ? "once"
                    : ""
                }}
              </td>
              <td>{{ visa.duration_of_stay }}</td>
              <td>
                <router-link
                  :to="`/visa/edit/${visa.id}`"
                  class="btn btn-success mx-1 w-auto"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>

                <div
                  class="btn btn-danger mx-1 w-auto"
                  @click="deleteVisa(index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="allVisa"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchAllVisa"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
// import $ from "jquery";
export default {
  name: "allVisa",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      allVisa: [],
      visaText: "",
      loading: true,
    };
  },
  methods: {
    fetchAllVisa(page = 1) {
      axios
        .post(`/fetch_visa_types?page=${page}`, { word: this.visaText })
        .then(({ data }) => {
          this.allVisa = data.data;
          this.loading = false;
          // console.log(this.allVisa.data);
          // console.log(this.allVisa);
        });
    },
    //delete visa
    deleteVisa(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.allVisa.data[index].id;
          const fromClick = true;
          axios.delete(`/visa_types/${delete_id}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.allVisa.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.fetchAllVisa();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_visa.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
</style>
