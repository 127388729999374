<template>
  <div v-if="loading" class="loading w-100">
    <Loading />
  </div>
  <section class="all_emp card_style">
    <div class="row options mb-2">
      <div class="col-2">
        <div class="main_header_style">
          <h4>{{ $t("The Countries") }}</h4>
        </div>
      </div>
      <form class="col-6">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('search by name')"
          v-model="titleCountry"
          @keyup="fetchCountries"
        />
      </form>

      <div class="col-4">
        <router-link :to="{ name: 'addCountry' }" class="PrimaryButton btn w-50"
          ><i class="fa-solid fa-plus"></i> {{ $t("New Country") }}</router-link
        >
      </div>
    </div>

    <div class="row">
      <table class="table" id="countryTable">
        <thead>
          <tr>
            <th>{{ $t("ID") }}</th>
            <th>{{ $t("Title") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(country, index) in countries.data" :key="index">
            <td>{{ country.id }}</td>
            <td>{{ country.title }}</td>
            <td>
              <div
                class="btn btn-primary mx-1 text-white"
                @click="callCities(country.id)"
                v-tooltip.top="$t('call_hotels')"
              >
                <i class="fa-solid fa-city"></i>
              </div>

              <div
                class="btn btn-info mx-1"
                v-tooltip.top="$t('call_airports')"
                @click="callAirports(country.id)"
              >
                <i class="fa-solid fa-plane"></i>
              </div>

              <router-link
                :to="`/countries/edit/${country.id}`"
                v-tooltip.top="$t('edit')"
                class="btn btn-success mx-1 text-white"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </router-link>

              <button
                class="btn btn-danger mx-1 text-white"
                v-tooltip.top="$t('delete')"
                @click="deleteCountry(country.id, index)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
              <button
                @click="changeStatus(country.id)"
                v-tooltip.top="$t('status')"
                :class="`btn ${
                  country.is_favorite == 1 ? 'btn-warning' : 'btn-secondary'
                }`"
              >
                <i class="fa-solid fa-star text-white"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="countries"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchCountries"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";
import Loading from "@/components/LottieFiles/loading.vue";
// import { timers } from "jquery";

export default {
  name: "AllCountries",
  data() {
    return {
      countries: [],
      titleCountry: "",
      loading: false,
    };
  },
  components: {
    Loading,
    pagination,
  },
  methods: {
    changeStatus(country_id) {
      this.loading = true;
      axios
        .post(`/change_country_favorite_status`, { country_id: country_id })
        .then(() => {
          Swal.fire(this.$t("change status"), "", "success");
          this.fetchCountries();
          this.loading = false;
        });
    },
    fetchCountries(page = 1) {
      const search = { word: this.titleCountry };
      axios
        .post(`/fetch_custom_countries?page=${page}`, search)
        .then(({ data }) => {
          this.countries = data.data;
        });
    },
    deleteCountry(countryId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios
            .delete(`/countries/${countryId}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.countries.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
            });
        }
      });
    },

    callAirports(countryId) {
      let id = { country_id: countryId };
      this.loading = true; // Start loading
      axios
        .post(`/fetch_airports_by_country`, id)
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.loading = false; //the loading ended
          } else {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },

    callCities(countryId) {
      let id = { country_id: countryId };
      this.loading = true; // Start loading
      axios
        .post(`/update_city_by_country_id`, id)
        .then(({ data }) => {
          if (data.status == true) {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.loading = false; //the loading ended
          } else {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  mounted() {
    this.fetchCountries();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scope>
@import "./_country";
.options {
  .form-control {
    padding-inline-start: 3rem !important;
  }
}
</style>
