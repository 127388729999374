<template>
  <section class="all_emp card_style">
    <div class="row options mb-2">
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
        <h4>
          {{ $t("clients_portal") }}
        </h4>
      </div>

      <div class="col-lg-10 col-md-12 col-12">
        <div class="form-group">
          <div class="search">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control w-50"
              :placeholder="$t('search by name')"
              v-model="clientText"
              @keyup="fetch_portal_client((page = 1))"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12 col-12">
        <Multiselect
          v-model="portal_status"
          :options="statuses"
          :placeholder="$t('status')"
          label="title"
          track-by="id"
          :clear-on-select="true"
          select-label=""
          :hide-selected="true"
          @update:model-value="fetch_portal_client()"
        >
        </Multiselect>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table portal" id="RoomTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("email") }}</th>
              <th>{{ $t("phone") }}</th>
              <th>{{ $t("block_status") }}</th>
              <th>{{ $t("status") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="client in 10" :key="client">
              <td v-for="client in 6" :key="client">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(client, i) in clients.data" :key="i">
              <td>
                {{ client.id }}
              </td>
              <td>{{ client.name }}</td>
              <td>{{ client.email }}</td>
              <td>{{ client.phone }}</td>
              <td>
                {{ client.is_blocked == 0 ? $t("unblocked") : $t("blocked") }}
              </td>
              <td>
                {{
                  client.portal_status == 0
                    ? $t("Pending")
                    : client.portal_status == 1
                      ? $t("Active")
                      : $t("INACTIVE")
                }}
              </td>
              <td class="actions">
                <button
                  v-if="client.portal_status == 0"
                  @click="OpenDialog(client.id, 1, i)"
                  class="btn btn-success"
                >
                  <i class="fa-regular fa-circle-check"></i>
                </button>
                <button
                  v-if="client.portal_status == 0"
                  @click="changeStatusClient(client.id, 2, i)"
                  class="btn btn-danger"
                >
                  <i class="fa-regular fa-circle-xmark"></i>
                </button>
                <button
                  style="color: white; border: none"
                  class="btn btn-warning"
                  :class="client.is_blocked == 0 ? 'bg-success' : 'bg-danger'"
                  @click="block_user(client.id, i)"
                  v-if="client.portal_status !== 0"
                  v-tooltip.top="
                    client.is_blocked == 0 ? $t('unblock') : $t('block')
                  "
                >
                  <i
                    :class="
                      client.is_blocked == 0
                        ? 'fa-solid fa-lock-open'
                        : 'fa-solid fa-lock'
                    "
                  ></i>
                </button>
                <router-link
                  class="btn btn-info text-white mx-1"
                  :to="`/clients/details/${client.id}`"
                  v-tooltip.top="$t('client Details')"
                >
                  <i class="fa-solid fa-circle-exclamation"></i>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Dialog
      v-model:visible="visible"
      pt:mask:class="backdrop-blur-sm"
      modal
      :style="{ width: '50rem' }"
    >
      <Multiselect
        v-model="client_type"
        :options="client_types"
        :placeholder="$t('status')"
        label="title"
        track-by="id"
        :clear-on-select="true"
        select-label=""
        :hide-selected="true"
        @search-change="fetch_client_type_portal"
      >
      </Multiselect>

      <div class="flex justify-end gap-2" style="margin-top: 5rem">
        <Button
          type="button"
          class="btn PrimaryButton rounded-md"
          label="Save"
          @click="accept(client_id, number, i)"
        ></Button>
      </div>
    </Dialog>

    <div class="row">
      <pagination
        :data="clients"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_portal_client"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>
<script>
import Multiselect from "vue-multiselect";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
// import Swal from "sweetalert2";
// import acceptPortal from "@/components/portal/accceptPortal.vue";
import Skeleton from "primevue/skeleton";
import Swal from "sweetalert2";

import Button from "primevue/button";

import Dialog from "primevue/dialog";

export default {
  name: "portalUser",
  components: {
    Skeleton,
    pagination,
    Multiselect,
    AddFavDailog,
    // acceptPortal,
    Dialog,
    Button,
  },
  data() {
    return {
      visible: false,
      number: 1,
      i: "",
      client_id: "",
      client_types: [],
      client_type: "",
      clients: {
        data: [],
      },
      word: "",
      clientText: "",
      statuses: [
        {
          id: 1,
          title: this.$t("Active"),
        },
        {
          id: 2,
          title: this.$t("INACTIVE"),
        },
        {
          id: 0,
          title: this.$t("Pending"),
        },
      ],
      portal_status: "",
    };
  },
  methods: {
    OpenDialog(id, status, index) {
      this.visible = true;
      this.client_id = id;
      this.i = index;
      this.number = status;
    },
    fetch_client_type_portal(search) {
      axios
        .post("/fetch_client_type_portal", {
          word: search,
        })
        .then(({ data }) => {
          this.client_types = data.data.data;
        });
    },
    fetch_portal_client(page = 1) {
      const formData = new FormData();
      if (this.clientText != "") {
        formData.append("word", this.clientText);
      }
      // console.log(this.portal_status);
      if (this.portal_status != "") {
        formData.append("portal_status", this.portal_status.id);
      }

      axios
        .post(`/fetch_portal_client?page=${page}`, formData)
        .then(({ data }) => {
          this.clients = data.data;
        });
    },
    changeStatusClient(id, status, index) {
      const formData = new FormData();
      formData.append("client_id", id);
      formData.append("status", status);

      axios
        .post(`/change_portal_client_status`, formData)
        .then(({ data }) => {
          this.clients.data[index].portal_status = status;
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.message,
            icon: "error",
          });
        });
    },
    accept(id, status, index) {
      const formData = new FormData();
      formData.append("client_type_id", this.client_type.id);
      formData.append("client_id", id);
      formData.append("status", status);

      axios.post(`/change_portal_client_status`, formData).then(({ data }) => {
        this.visible = false;
        this.clients.data[index].portal_status = status;
        // this.fetch_portal_client();
        Swal.fire({
          title: "",
          text: data.message,
          icon: "success",
        });
      });
    },
    block_user(id, i) {
      const formData = new FormData();
      formData.append("client_id", id);
      axios
        .post(`/change_portal_client_block_status`, formData)
        .then(({ data }) => {
          this.clients.data[i].is_blocked == 0
            ? (this.clients.data[i].is_blocked = 1)
            : (this.clients.data[i].is_blocked = 0);
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        });
    },
  },
  mounted() {
    this.fetch_portal_client();
    this.fetch_client_type_portal();
  },
};
</script>
<style scoped lang="scss">
button.btn.btn-success {
  margin-left: 2px;
}

.search {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 0.5rem;
  input {
    border: none;
    outline: none;
    width: 100% !important;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
