<template>
    <p class="title_setup">{{$t("customize_by")}}</p>
    <ul class="customize_by">
        <li>
            <div class="form-check">
                <label class="form-check-label" for="country">
                    <input type="radio" class="form-check-input" name="customization_type" id="country" v-model="customizationTypeValue" @change="emitRadioSelected" value="1" checked>
                    البلاد
                </label>
            </div>
        </li>
        <li>
            <div class="form-check">
                <label class="form-check-label" for="city">
                    <input type="radio" class="form-check-input" name="customization_type" id="city" v-model="customizationTypeValue" @change="emitRadioSelected" value="2">
                    المدن
                </label>
            </div>
        </li>
        <li>
            <div class="form-check">
                <label class="form-check-label" for="company">
                    <input type="radio" class="form-check-input" name="customization_type" id="company" v-model="customizationTypeValue" @change="emitRadioSelected" value="3">
                    الشركات
                </label>
            </div>
        </li>
        <li v-if="type == 2">
            <div class="form-check">
                <label class="form-check-label" for="nationality">
                    <input type="radio" class="form-check-input" name="customization_type" id="nationality" v-model="customizationTypeValue" @change="emitRadioSelected" value="4">
                    الجنسيات
                </label>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            customizationTypeValue: '',
            type: this.$route.params.type_id,
        };
    },
    methods: {
        emitRadioSelected() {
            this.$emit('radio-selected', this.customizationTypeValue);
        },
    },
}
</script>

<style lang="scss" scoped>
    @import "../../../view/setup/_setup.scss";
</style>