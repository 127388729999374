<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center mb-2 gap-3">
      <AddFavDailog v-tooltip.left="$t('add to favorites')" />
      <h4>
        {{ $t("terms_and_conditions") }}
      </h4>
    </div>
    <div class="main_header_style mb-2">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="search-visa w-100">
          <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control w-50"
              :placeholder="$t('search...')"
              v-model="terms_search"
              @keyup="fetchAllTerms((page = 1))"
            />
          </form>
        </div>
        <router-link
          to="/terms_and_conditions/add"
          class="PrimaryButton mt-0 btn w-25"
          ><i class="fa-solid fa-plus"></i> {{ $t("add_terms") }}
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table visaType" id="VisaTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("title") }}</th>
              <th>{{ $t("description") }}</th>
              <th>{{ $t("invoices_type") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="term in 10" :key="term">
              <td v-for="term in 4" :key="term">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(term, index) in allTerms" :key="index">
              <td>{{ term.id }}</td>
              <td>{{ term.title }}</td>

              <td>
                <span
                  v-html="term?.description.substring(0, 50) + ' ...'"
                ></span>
              </td>
              <td>
                <ul>
                  <li v-for="item in term.invoice_types" :key="item">
                    {{ item.status + " - " }}
                  </li>
                </ul>
              </td>
              <td>
                <router-link
                  :to="`/terms_and_conditions/edit/${term.id}`"
                  class="btn btn-success mx-1 w-auto"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>

                <div
                  class="btn btn-danger mx-1 w-auto"
                  @click="deleteTerm(term.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="allTerms"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchAllTerms"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
export default {
  name: "allBanners",
  components: {
    pagination,
    Skeleton,
    AddFavDailog,
  },
  data() {
    return {
      allTerms: [],
      terms_search: "",
      loading: true,
    };
  },
  methods: {
    fetchAllTerms(page = 1) {
      axios
        .post(`/index_terms_and_conditions?page=${page}`)
        .then(({ data }) => {
          this.allTerms = data.data;
          this.loading = false;
        });
    },
    // delete banner
    deleteTerm(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios
            .delete(`/delete_terms_and_conditions/${id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.allTerms.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.fetchAllTerms();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
</style>
