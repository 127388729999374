<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_new_payment") }}</h4>
      <!-- <p>{{ $t("add_new_company_parg") }}</p> -->
    </div>

    <form class="form_topic" @submit.prevent="addPayment()">
      <div class="row">
        <!-- start input group -->
        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <div class="input-group">
            <label class="form-label">{{ $t("pay_benfites") }}</label>
            <Multiselect
              v-model="benfitesValue"
              :options="benfites.map((benfite) => benfite.id)"
              :custom-label="(opt) => benfites.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <div class="input-group">
            <label class="form-label">{{ $t("client") }}</label>
            <Multiselect
              v-model="clientValue"
              :options="clients.map((client) => client.id)"
              :custom-label="(opt) => clients.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <div class="input-group">
            <label class="form-label">{{ $t("date") }}</label>
            <datepicker
              v-model="date"
              name="from_date"
              :placeholder="$t('from')"
            ></datepicker>
          </div>
        </div>
        <!-- finish input group -->

        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <div class="input-group">
            <label class="form-label">{{ $t("benfite_type") }}</label>
            <Multiselect
              v-model="benfitesType"
              :options="types.map((type) => type.id)"
              :custom-label="(opt) => types.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <div class="input-group">
            <label class="form-label">{{ $t("safes") }}</label>
            <Multiselect
              v-model="safeValue"
              :options="safes.map((safe) => safe.id)"
              :custom-label="(opt) => safes.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-4 col-md-6 col-12 mb-4">
          <div class="input-group">
            <label class="form-label">{{ $t("the amount") }}</label>
            <input class="form-control" type="number" v-model="amount" />
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 mb-4">
          <div class="input-group">
            <label class="form-label">{{ $t("write_notes") }}</label>
            <textarea
              name=""
              class="form-control"
              placeholder="اكتب ملاحظاتك هنا ..."
              v-model="notes"
            ></textarea>
          </div>

          <div class="col-12">
            <button type="submit" class="btn PrimaryButton">
              {{ $t("add") }}
            </button>
          </div>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "vuejs3-datepicker";
import moment from "moment";

export default {
  name: "add-payment",
  components: { Multiselect, Datepicker },
  data() {
    return {
      benfites: [
        {
          id: 1,
          title: "Benfite 1",
        },
        {
          id: 2,
          title: "Benfite 2",
        },
      ],
      clients: [
        {
          id: 1,
          title: "Client 1",
        },
        {
          id: 2,
          title: "Client 2",
        },
      ],
      types: [
        {
          id: 1,
          title: "Type 1",
        },
        {
          id: 2,
          title: "Type 2",
        },
      ],
      safes: [
        {
          id: 1,
          title: "Safe 1",
        },
        {
          id: 2,
          title: "Safe 2",
        },
      ],
      benfitesValue: "",
      clientValue: "",
      date: null,
      benfitesType: "",
      safeValue: "",
    };
  },
  methods: {
    addPayment() {
      const formData = new FormData();
      formData.append("title", this.benfitesValue);
      formData.append("date", moment(this.date).format("YYYY-MM-DD"));
      formData.append("city_id", this.clientValue);
      formData.append("starCount", this.benfitesType);
      formData.append("lat", this.amount);
      formData.append("long", this.notes);
      formData.append("image", this.safeValue);

      axios
        .post("/hotels", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/allHotels");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>

<style lang="scss" scoped>
@import "./__payment.scss";
</style>
