<template>
  <section class="show_receipt card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{ $t("All Transportation Contract") }}
      </h4>
    </div>
    <div class="row options mb-2">
      <form class="col-lg-10 col-md-12 col-12">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          :placeholder="$t('search by name')"
          v-model="titlenation"
          @keyup="fetchContracts((page = 1))"
          class="form-control w-50"
        />
      </form>

      <div class="col-lg-2 col-md-12 col-12">
        <router-link
          :to="{ name: 'contractTransportAdd' }"
          class="PrimaryButton btn contcart"
          ><i class="fa-solid fa-plus"></i> {{ $t("Transportation Contract") }}
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="table-responsive">
        <table class="table contract">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("contract_name") }}</th>
              <th>{{ $t("contract_country") }}</th>
              <th>{{ $t("Routes") }}</th>
              <th>{{ $t("suppliers") }}</th>
              <th>{{ $t("From_Date") }}</th>
              <th>{{ $t("To_Date") }}</th>

              <th></th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="contractTransport in 10" :key="contractTransport">
              <td v-for="contractTransport in 7" :key="contractTransport">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in contracts.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.contract_country?.title }}</td>
              <td>{{ item.routes[0]?.text }}</td>
              <td>{{ item.supplier?.name }}</td>
              <td>{{ item.from_date }}</td>
              <td>{{ item.todate }}</td>
              <td>
                <router-link
                  class="btn btn-success mx-1 w-auto"
                  :to="`/contract-transport/edit/${item.id}`"
                  :title="$t('edit')"
                >
                  <i class="fa-solid fa-pen"></i>
                </router-link>
                <router-link
                  class="btn btn-info mx-1 w-auto"
                  :to="`/contract-transport/${item.id}/season-transport`"
                  :title="$t('seasons')"
                >
                  <i class="fas fa-water"></i>
                </router-link>
                <button
                  class="btn btn-danger mx-1 w-auto"
                  @click="DeleteAlert(index)"
                >
                  <i class="fa-solid fa-trash" :title="$t('delete')"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="contracts"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchContracts"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
export default {
  name: "show-nation",
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      contracts: [],
      titlenation: "",
      contracts_children: [],
      contracts_children_childer: [],
      route: "",
      loading: true,
    };
  },
  methods: {
    fetchContracts(page = 1) {
      axios
        .post(`/fetch_transportation_contracts?page=${page}`, {
          word: this.titlenation,
        })
        .then(({ data }) => {
          this.contracts = data.data;
          this.loading = false;
          // console.log(this.contracts);
        });
    },
    get_contracts_children() {
      axios
        .post(`/fetch_transportation_contracts`, { word: this.titlenation })
        .then(({ data }) => {
          this.contracts_children = data.data.data;
          this.contracts_children.forEach((e) => {
            this.route = e;
            // console.log(this.route, "dasdsadd");
          });
        });
    },
    //delete expness
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.contracts.data[index].id;
          const fromClick = true;
          axios
            .delete("/transportation_contracts/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.contracts.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.fetchContracts();
    this.get_contracts_children();
  },
};
</script>
<style lang="scss" scoped>
@import "./_contract.scss";
</style>
