<template>
  <section class="book">
    <div class="d-flex align-items-center justify-content-between">
      <h5 class="title">{{ $t("reservation") }}</h5>
      <router-link to="/hotels" class="btn PrimaryButton">
        <i class="fa-solid fa-plus mx-2"></i> {{ $t("new_reservation") }}
      </router-link>
    </div>
    <div class="row mt-4">
      <table class="table" id="bookingTable">
        <thead>
          <tr>
            <th scope="col">{{ $t("ID") }}</th>
            <th scope="col">{{ $t("client") }}</th>
            <th scope="col">{{ $t("Reservation by") }}</th>
            <th scope="col">{{ $t("Access - departure") }}</th>
            <th scope="col">{{ $t("number of nights") }}</th>
            <th scope="col">{{ $t("Hotel- reservation number") }}</th>
            <th scope="col">{{ $t("number of rooms") }}</th>
            <th scope="col">{{ $t("price") }}</th>
            <th scope="col">{{ $t("status") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(book, index) in books.data"
            :key="index"
            :class="{
              accept: book.status == 1,
              confirm: book.status == 2,
              edit: book.status == 3,
              principle: book.status == 4,
              cancel: book.status == 5,
            }"
          >
            <td :data-label="$t('ID')">{{ book.id }}</td>
            <td :data-label="$t('client')">{{ book.client_name }}</td>
            <td :data-label="$t('Reservation by')">{{ book.booking_by }}</td>
            <td :data-label="$t('Access - departure')">
              {{ book.check_in }} - {{ book.check_out }}
            </td>
            <td :data-label="$t('number of nights')">
              {{ book.number_of_nights }}
            </td>
            <td :data-label="$t('Hotel- reservation number')">
              {{ book.booking_number }}
            </td>
            <td :data-label="$t('number of rooms')">{{ book.rooms_number }}</td>
            <td :data-label="$t('price')">{{ book.price }}</td>
            <td :data-label="$t('status')">
              {{
                book.status == 1
                  ? $t("Accept")
                  : book.status == 2
                  ? $t("Confirm")
                  : book.status == 3
                  ? $t("Edit")
                  : book.status == 4
                  ? $t("Principle")
                  : $t("Cancel")
              }}
            </td>
            <!-- <td :data-label="$t('actions')">
                <div class="dropdown">
                    <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fa-solid fa-bars"></i>
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#" v-if="book.status == 1 || book.status == 4"><i class="fa-solid fa-circle-info"></i> {{$t("details_booking")}}</a></li>
                        <li><a class="dropdown-item" href="#" v-if="book.status == 1 || book.status == 4"><i class="fa-solid fa-pen-to-square"></i> {{$t("edit")}}</a></li>
                        <li><a class="dropdown-item" href="#" v-if="book.status == 1 || book.status == 4"><i class="fa-regular fa-trash-can"></i> {{$t("Cancel")}}</a></li>
                        <li><a class="dropdown-item" href="#"><i class="fa-regular fa-envelope"></i> {{$t("send_email")}}</a></li>
                        <li><a class="dropdown-item" href="#"><i class="fa-brands fa-whatsapp"></i> {{$t("send_whatsapp")}}</a></li>
                        <li><a class="dropdown-item" href="#"><i class="fa-solid fa-download"></i> {{$t("download_pdf")}}</a></li>
                    </ul>
                </div>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="books"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchBookings"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "table-customization",
  components: {
    pagination,
  },
  data() {
    return {
      books: [],
    };
  },
  methods: {
    // fetch_bookings table
    fetchBookings(page = 1) {
      axios.get(`/fetch_bookings?page=${page}`).then(({ data }) => {
        this.books = data.data;
        // console.log(this.books);
      });
    },
    //delete Customizations
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.customizations.data[index].id;
          const fromClick = true;
          axios
            .delete("/customization_settings/" + `${delete_id}`, { fromClick })
            .then(({ data }) => {
              // console.log(result);
              // console.log(delete_id);
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.customizations.data.splice(index, 1); // Remove the deleted item from the array
                // console.log(data);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.fetchBookings();
  },
  updated() {
    $("#bookingTable").DataTable({
      bPaginate: false,
      bInfo: false,
      retrieve: true,
      sEmptyTable: false,
      // displayStart: 5,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("books"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("books"),
      },
      oLanguage: {
        sEmptyTable: " ",
      },
    });
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style lang="scss" scoped>
@import "./_book.scss";
</style>
