<template>
  <div class="header_dashboard">
    <div class="row">
      <div class="col-lg-4 col-md-2 col-12">
        <h2>
          {{ $t("welcome") }}
          <img src="@/assets/media/image/Waving.png" alt="hand" />
        </h2>
        <p>{{ $t("wish_day_happy") }}</p>
      </div>
      <div class="col-lg-8 col-md-10 col-12">
        <div class="collection_btn">
          <router-link class="btn btn-main" to="/hotels" v-if="userEmail != targetEmail">
            <img src="@/assets/media/image/btn1.png" alt="btn" />
            {{ $t("online hotel reservation") }}
          </router-link>
          <router-link class="btn btn-main" to="/book_offline" v-if="userEmail != targetEmail">
            <img src="@/assets/media/image/btn1.png" alt="btn" />
            {{ $t("offline hotel reservation") }}
          </router-link>
          <router-link class="btn btn-main" to="/flight" v-if="userEmail != targetEmail">
            <img src="@/assets/media/image/btn2.png" alt="btn" />
            {{ $t("online flight reservation") }}
          </router-link>
          <router-link class="btn btn-main" to="/flight-booking" v-if="userEmail != targetEmail">
            <img src="@/assets/media/image/btn2.png" alt="btn" />
            {{ $t("offline flight reservation") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-home",
  data() {
    return {
      userEmail: JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).email_company : null,
      targetEmail: "info@tripsavisor.com"
    }
  }
};
</script>

<style scoped>
  .collection_btn {
    justify-content: flex-end !important;
  }
</style>
