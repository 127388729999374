<template>
  <section class="card_style">
    <!-- skelton -->
    <div class="w-100" v-if="loading && $route.params.id">
      <Skeleton width="10%" class="mb-5"></Skeleton>
      <div class="d-flex flex-column gap-2 w-100 my-2" v-for="i in 2" :key="i">
        <Skeleton width="4%"></Skeleton>
        <Skeleton height="2rem" width="20%" class="mb-2"></Skeleton>
      </div>
    </div>
    <!-- skelton -->
    <form class="form_topic" @submit.prevent="editServices()" v-else>
      <div class="main_header_style">
        <h4>{{ $t("edit_service") }}</h4>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="accordion" id="accordionExample1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingName">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseName"
                  aria-expanded="false"
                  aria-controls="collapseName"
                >
                  {{ $t("service_title") }}
                </button>
              </h2>
              <div
                id="collapseName"
                class="accordion-collapse collapse show"
                aria-labelledby="headingName"
                data-bs-parent="#accordionExample1"
              >
                <div class="accordion-body">
                  <div
                    class="form-group"
                    v-for="(title, index) in titles"
                    :key="index"
                  >
                    <label :for="`name${index}`"
                      >{{ $t("title") }} ({{ title.lang }})</label
                    >
                    <input
                      type="text"
                      :placeholder="$t('name')"
                      class="form-control"
                      :id="`name${index}`"
                      v-model="title.title"
                    />
                    <div
                      v-for="error in v$.titles.$each.$response.$errors[index].title"
                      :key="error"
                      class="input-errors"
                    >
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("edit") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "primevue/skeleton";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
  name: "edit-revenue",
  data() {
    return {
      title_ar: "",
      title_en: "",
      loading: true,
      languages: ["ar","en","id"],
      titles: [],
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      titles:{
        $each: helpers.forEach({
          title: {required}
        })
      }
    };
  },
  methods: {
    setTitlesLang(){
      this.languages.forEach((ele)=>{
        this.titles.push({
          lang: ele,
          title: ""
        })
      })
    },
    // fetch expness data
    fetchServicesData() {
      let id = this.$route.params.id;
      axios.get("/additional_services/" + id).then(({ data }) => {
        // console.log(data.data);
        this.titles = data.data.titles.map((ele)=>{
          return{
            lang: ele.locale,
            title: ele.title
          }
        })
        this.loading = false;
      });
    },
    // add revenue
    async editServices() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let id = this.$route.params.id;
        const params = new URLSearchParams();
        this.titles.forEach((ele)=>{
          params.append(`title_${ele.lang}`, ele.title);
        })
        axios
          .put(`/additional_services/${id}`, params)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/services_dashboard");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  created() {
    this.setTitlesLang();
    this.fetchServicesData();
  },
  components: {
    Skeleton,
  },
};
</script>
<style lang="scss" scoped>
@import "./_services.scss";
</style>
