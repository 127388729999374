<template>
  <section class="add-currency">
    <div class="w-100" v-if="loading && $route.params.id">
      <div
        class="my-3 main_header_style w-100 d-flex align-items-center justify-content-start"
      >
        <Skeleton shape="circle" size="3rem" class="mx-2"></Skeleton>
        <Skeleton width="20%" class="mb-2"></Skeleton>
      </div>
      <Skeleton size="10rem" class="my-4"></Skeleton>
      <Skeleton height="2rem" width="10%" class="my-2"></Skeleton>
      <Skeleton height="2rem"></Skeleton>
      <Skeleton height="2rem" width="10%" class="mt-4"></Skeleton>
      <Skeleton height="2rem" class="my-2"></Skeleton>
    </div>

    <form @submit.prevent="onSubmit()" v-else>
      <div class="mt-4 main_header_style">
        <h4 class="d-flex align-items-center gap-3">
          <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
          <span>{{
            this.currencyId ? $t("edit_currency") : $t("add_currency")
          }}</span>
        </h4>
      </div>
      <div class="row">
        <div class="col-12 mb-3">
          <div class="image_upload">
            <label for="imageEntry" class="upload_img" v-if="imageEntry == ''">
              <img
                :src="currency?.flag ? currency?.flag : defaultImage"
                alt="flag"
              />
            </label>
            <img
              :src="imageEntry"
              v-else-if="imageEntry"
              class="entry_image"
              alt="entry"
            />
            <input
              type="file"
              id="imageEntry"
              class="form-control"
              @change="onFileChange"
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
            <span
              class="text-danger"
              v-if="v$.imageEntry.$error"
              v-text="v$.imageEntry.$errors[0].$message"
            ></span>
          </div>
          <div class="form-group">
            <label for="">{{ $t("title") }}</label>
            <div class="accordion" id="accordionExample1">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingName">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ this.currencyId ? Form.title[0]?.title : $t("title") }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    <div
                      class="form-group"
                      v-for="(currency_title, index) in Form.title"
                      :key="index"
                    >
                      <label :for="`name${index}`"
                        >{{ $t("title") }} ({{ currency_title.locale }})</label
                      >
                      <input
                        type="text"
                        :placeholder="$t('name')"
                        class="form-control"
                        :id="`name${index}`"
                        v-model="currency_title.title"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span
              class="text-danger"
              v-if="v$.Form.title.$error"
              v-text="v$.Form.title.$errors[0].$message"
            ></span>
          </div>
          <div class="code">
            <label for="name">{{ $t("code") }}</label>
            <input
              type="text"
              :placeholder="$t('code')"
              class="form-control"
              :id="`name${index}`"
              v-model="currency.code"
            />
            <span
              class="text-danger"
              v-if="v$.currency.code.$error"
              v-text="v$.currency.code.$errors[0].$message"
            ></span>
          </div>
        </div>
      </div>
      <button type="submit" class="PrimaryButton col-12 mt-3 btn">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
export default {
  name: "currencysWebsiteAdd",
  data() {
    return {
      v$: useVuelidate(),
      loading: true,
      currencyId: this.$route.params.id,
      languages: ["id", "en", "ar"],
      imageEntry: "",
      defaultImage: require("@/assets/media/image/user.png"),
      Form: {
        title: [],
      },
      currency: {
        flag: "",
        code: "",
      },
    };
  },

  validations() {
    return {
      imageEntry: { required },
      Form: {
        title: this.Form.title.map(() => {
          return {
            title: { required },
          };
        }),
      },
      currency: {
        code: { required },
      },
    };
  },

  methods: {
    resetForm() {
      this.currency = {
        flag: "",
        code: "",
      };

      this.Form.title = [];
      this.setcurrencyTitle(); // Reset language titles
      this.imageEntry = ""; // Clear the uploaded image
    },

    fetchCurrencyData() {
      axios
        .get(`organization_currencies/${this.currencyId}`)
        .then(({ data }) => {
          // console.log(data);
          this.currency.code = data.data?.code;
          this.currency.flag = data.data?.flag;
          this.Form.title = data.data?.titles;
          this.Form.title &&
            this.Form.title?.length > 0 &&
            this.Form.title?.map((el) => {
              return {
                locale: el.locale,
                title: el.title,
              };
            });
          this.loading = false;
        });
    },

    setcurrencyTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          locale: language,
          title: "",
        });
      });
    },

    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },

    onSubmit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();

        if (this.fileData instanceof File) {
          formData.append("flag", this.fileData);
        }
        formData.append("code", this.currency.code);
        this.Form.title.forEach((ele) => {
          formData.append(`title_${ele.locale}`, ele.title);
        });

        if (this.$route.params.id != undefined) {
          // edit
          axios
            .post(`organization_curreny/update/${this.currencyId}`, formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.$router.push("/currencies");
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        } else {
          //add
          axios
            .post("organization_currencies", formData)
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.resetForm();
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
            });
        }
      }
    },
  },
  components: {
    AddFavDailog,
    Skeleton,
  },
  mounted() {
    this.setcurrencyTitle();

    if (this.currencyId) {
      this.fetchCurrencyData();
    }
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}

.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;

  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;

    img {
      width: 100%;
      height: 150px;
    }
  }
}

.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}

.main_header_style {
  margin-bottom: 0;
}
</style>
