<template>
  <section class="all_emp card_style">
    <div class="main_header_style">
      
    </div>

    <div class="row options mb-2">
      <div class="col-lg-2 col-md-12 col-12">
        <h4 class="haw">{{ $t("arrival") }}</h4>
      </div>
      <form class="col-lg-3 col-md-12 col-12">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          :placeholder="$t('search by name')"
          class="form-control"
          v-model="resrvationName"
          @keyup="resrvationName ? fetchSearch() : fetchBooks()"
        />
      </form>

      <div class="col-lg-2 col-md-12 col-12 d-flex justify-content-end">
        <a
          class="btn SecondButton offline w-100"
          data-bs-toggle="modal"
          href="#exampleModalToggle"
          role="button"
          ><img src="@/assets/media/image/fi_2676818.svg" alt="" />
          {{ $t("Filter Bookings") }}</a
        >
        <filterBookings @getData="getData" :booking_type="book_type" />
        <!-- <filterBookings @fetchBooks = "fetchBooks"/> -->
      </div>

      <div class="col-lg-2 col-md-12 col-12 d-flex justify-content-end">
        <router-link
          :to="{ name: 'bookOfflinePage' }"
          class="PrimaryButton btn w-100 new"
          ><i class="fa-solid fa-plus"></i> {{ $t("New Book") }}</router-link
        >
      </div>
    </div>

    <div class="row">
      <div class="table_row">
        <table class="table arrival" id="bookTable">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("Serial Number") }}</th>
              <th>{{ $t("Hotel Ref") }}</th>
              <th>{{ $t("seller") }}</th>
              <th>{{ $t("online_client") }}</th>
              <th>{{ $t("visitor") }}</th>
              <th>{{ $t("reservton_type") }}</th>
              <th>{{ $t("Check In") }}</th>
              <th>{{ $t("Check Out") }}</th>
              <th>{{ $t("Hotel Name") }}</th>
              <th style="width: 280px">{{ $t("type_room_number") }}</th>
              <th>{{ $t("meals") }}</th>
              <th>{{ $t("Status") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(book, index) in books.data" :key="index">
              <td>{{ book.id }}</td>
              <td>{{ book.booking_number }}</td>
              <td>{{ book.hotel_phone }}</td>
              <td>{{ book.booking_by }}</td>
              <td>{{ book.guest_name }}</td>
              <td>{{ book.client_name }}</td>
              <td>{{ book.booking_type }}</td>
              <td>{{ book.check_in }}</td>
              <td>{{ book.check_out }}</td>
              <td>{{ book.hotel_name }}</td>
              <td>{{ book.rooms_number }} / {{ book.room_type }}</td>
              <td>
                <span
                  v-for="(meal, id) in book.meal_types"
                  :key="id"
                  class="meal_type"
                >
                  <span
                    >{{ meal.title }} <span class="dash"> - </span>
                  </span></span
                >
              </td>

              <td>
                <span
                  :class="[
                    'badge',
                    book.status === 0
                      ? 'bg-primary'
                      : book.status === 1
                      ? 'bg-warning text-dark'
                      : book.status === 2
                      ? 'bg-success'
                      : book.status === 3
                      ? 'bg-info text-dark'
                      : book.status === 4
                      ? 'bg-danger'
                      : '',
                  ]"
                >
                  {{
                    book.status === 0
                      ? $t("initial")
                      : book.status === 1
                      ? $t("pending")
                      : book.status === 2
                      ? $t("completed")
                      : book.status === 3
                      ? $t("edited")
                      : book.status === 4
                      ? $t("canceled")
                      : ""
                  }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mt-4">
      <pagination
        :data="books"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchBooks"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import filterBookings from "../../../components/offline/filterBookings.vue";
import Swal from "sweetalert2";

import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

export default {
  name: "bookTablePage",
  data() {
    return {
      books: [],
      formData: {},
      resrvationName: "",
    };
  },
  components: {
    pagination,
    filterBookings,
  },
  methods: {
    fetchBooks(page = 1) {
      axios
        .post(`/filter_offline_bookings?page=${page}`, this.formData)
        .then(({ data }) => {
          this.books = data.data;
          console.log(this.books);
        });
    },
    fetchSearch(page = 1) {
      if (this.resrvationName.trim() === '') {
        // Handle the case where the search input is empty
        this.books.data = [];
        return;
      }

      const search = { word: this.resrvationName };

      axios
        .post(`/filter_offline_bookings?page=${page}`, search)
        .then(({ data }) => {
          this.books = data.data;
        });
    },

    getData(data) {
      this.formData = data;
      this.fetchBooks();
    },

    deleteBook(index, bookID) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(`/delete_offline_booking`, { booking_id: bookID })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.books.data.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
            });
        }
      });
    },
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
    this.fetchBooks();
  },
  updated() {
    $("#bookTable").DataTable({
      bPaginate: false,
      retrieve: true,
      searching: false,
      paging:   false,
      ordering: false,
      info:     false,
      oLanguage: {"sZeroRecords": "", "sEmptyTable": ""},
      dom: "Blfrtip",
      buttons: [
        {
          extend: "csvHtml5",
          text: '<i class="fa-solid fa-file-csv"></i>',
          titleAttr: "CSV",
          title: this.$t("reservation"),
          charset: "utf-8",
          bom: "true",
          init: function (node) {
            $(node).removeClass("btn-default");
          },
          exportOptions: {
            columns: ':not(:last-child)'
          }
        },
      ],
    });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "offline.scss";

.selects {
  .form-group {
    width: 100%;
  }
}
.options {
  form {
    .form-control {
      padding-inline-start: 3rem !important;
    }
  }
}
</style>
