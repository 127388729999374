<template>
  <form @submit.prevent="saveDate">
    <div class="card" style="min-height: 100vh">
      <div class="row">
        <div class="main_header_style p-3">
          <div class="d-flex gap-3">
            <AddFavDailog v-tooltip.left="$t('Evidence limitations')" />
            <h4>{{ $t("Evidence limitations") }}</h4>
          </div>
        </div>
      </div>
      <div class="row p-5">
        <div class="col-lg-5 col-md-12 col-12">
          <div class="input-group mb-1">
            <label class="form-label fs-6 fw-bold">{{
              $t("Sales record")
            }}</label>
            <div class="input-group mb-1">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input font-weight-bold"
                  type="radio"
                  name="salesRecordOptions"
                  id="salesCheckin"
                  v-model="selectedRadioSalesRecord"
                  value="0"
                  :disabled="disableRadio"
                />
                <label class="form-check-label" for="salesCheckin">{{
                  $t("checkin")
                }}</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input font-weight-bold"
                  type="radio"
                  name="salesRecordOptions"
                  id="salesCheckout"
                  v-model="selectedRadioSalesRecord"
                  value="1"
                  :disabled="disableRadio"
                />
                <label class="form-check-label" for="salesCheckout">{{
                  $t("checkout")
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 col-12">
          <label class="form-label fs-6 fw-bold">{{
            $t("Purchases record")
          }}</label>
          <div class="input-group mb-1">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input font-weight-bold"
                type="radio"
                name="purchasesRecordOptions"
                id="purchasesCheckin"
                v-model="selectedRadioPurchasesRecord"
                value="0"
                :disabled="disableRadio"
              />
              <label class="form-check-label" for="purchasesCheckin">{{
                $t("checkin")
              }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="purchasesRecordOptions"
                id="purchasesCheckout"
                v-model="selectedRadioPurchasesRecord"
                value="1"
                :disabled="disableRadio"
              />
              <label
                class="form-check-label font-weight-bold"
                for="purchasesCheckout"
                >{{ $t("checkout") }}</label
              >
            </div>
          </div>
        </div>
        <div class="px-4 mt-5">
          <button v-if="showButton" type="submit" class="btn PrimaryButton">
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "EvidenceLimitations",
  components: {
    AddFavDailog,
  },
  data() {
    return {
      selectedRadioPurchasesRecord: "",
      selectedRadioSalesRecord: "",
      showButton: true,
      disableRadio: false,
    };
  },
  methods: {
    saveDate() {
      Swal.fire({
        icon: "warning",
        title: this.$t("This selection cannot be modified"),
        showCancelButton: true,
        cancelButtonText: this.$t("Reject"),
        confirmButtonText: this.$t("save"),
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            const formData = new FormData();
            formData.append(
              "Proof_of_purchases",
              this.selectedRadioPurchasesRecord
            );
            formData.append("Proof_of_sales", this.selectedRadioSalesRecord);

            const res = axios.post("/store_accountant_setting", formData);
            this.showButton = false;
            this.disableRadio = true;
            console.log(res.data);
          } catch (err) {
            console.error("Error saving data:", err);
          }
        }
      });
    },
    async fetchData() {
      try {
        const res = await axios.get("/show_accountant_setting");
        this.selectedRadioPurchasesRecord = res.data.data.Proof_of_purchases;
        this.selectedRadioSalesRecord = res.data.data.Proof_of_sales;
        this.showButton = false;
        this.disableRadio = true;
        // console.log(res.data.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.card {
  min-height: 100vh;
}
.main_header_style {
  padding: 1rem;
}
.input-group {
  margin-bottom: 1rem;
}
</style>
