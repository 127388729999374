<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
const dailyRestriction = ref(null);
import axios from "axios";
// import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";
// import moment from "moment";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
// import useVuelidate from "@vuelidate/core";
// import { required, minValue } from "@vuelidate/validators";

onMounted(() => {
  axios
    .get(`/daily_restriction/${route.params.id}`)
    .then(({ data }) => {
      dailyRestriction.value = data.data;
    })
    .catch((error) => {
      console.log(error);
    });
});
</script>

<template>
  <div class="card_style">
    <div class="d-flex">
      <h4 class="title">{{ $t("copy_receipt") }}</h4>
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
    </div>
    <form class="" @submit.prevent="addExchange()">
      <div class="row">
        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-label"> {{ $t("construction_date") }}</label>
            <datepicker
              v-model="created_at"
              name="from_date"
              :placeholder="$t('from')"
              disabled
            ></datepicker>
          </div>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-12 col-lg-6">
          <div class="form-group mb-1">
            <label class="form-label"> {{ $t("value_recipt") }}</label>
            <input
              type="number"
              class="form-control"
              :placeholder="$t('enter_value_recipt')"
              v-model="amount"
              min="0"
              required
            />
          </div>
          <span class="input-errors" v-if="v$.amount.$error"
            >{{ v$.amount.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->

        <!-- start input group -->
        <div class="col-lg-6 col-12">
          <div class="form-group mb-1">
            <label class="form-label">{{ $t("choose_payment_method") }}</label>
            <Multiselect
              v-model="paidValues"
              :options="paids.map((paid) => paid.id)"
              :custom-label="(opt) => paids.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_payment_method')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchAccounts(paidValues)"
            >
            </Multiselect>
          </div>
          <span class="input-errors" v-if="v$.paidValues.$error">
            {{ v$.paidValues.$errors[0].$message }}
          </span>
        </div>
        <!-- finish input group -->

        <div class="col-lg-6 col-12">
          <label class="form-label">{{ $t("accounts") }}</label>
          <Multiselect
            v-model="accountValue"
            :options="accounts.map((account) => account.id)"
            :custom-label="(opt) => accounts.find((x) => x.id == opt).title_ar"
            :clear-on-select="true"
            :placeholder="$t('accounts')"
            select-label=""
            :hide-selected="true"
          >
          </Multiselect>
          <span class="input-errors" v-if="v$.accountValue.$error"
            >{{ v$.accountValue.$errors[0].$message }}
          </span>
        </div>

        <!-- start input group -->
        <div class="col-lg-6 col-md-12 col-12">
          <div class="d-flex w-100 gap-2">
            <div class="form-group mb-1 w-100">
              <div class="d-flex gap-4 align-items-center my-2">
                <label class="form-label mb-0"> {{ $t("recieved") }}</label>
                <div
                  class="d-flex align-items-center justify-content-center gap-3"
                >
                  <InputSwitch v-model="checked" />
                  <label class="form-label mb-0">{{ $t("clients for") }}</label>
                </div>
              </div>
              <Multiselect
                v-if="checked"
                v-model="expense_type_id"
                :options="expness"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('recieved')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
              <Multiselect
                v-else
                v-model="expense_type_id"
                :options="expness"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('clients for')"
                select-label=""
                :hide-selected="true"
              >
              </Multiselect>
            </div>
            <span class="input-errors" v-if="v$.expense_type_id.$error"
              >{{ v$.expense_type_id.$errors[0].$message }}
            </span>
          </div>
        </div>
        <!-- finish input group -->
        <div class="col-12 col-lg-6" v-if="!checked">
          <div class="form-group">
            <label class="form-label" for="Passport">{{
              $t("invoices")
            }}</label>
            <Multiselect
              v-model="Invoice"
              :options="Invoices"
              label="title"
              track-by="id"
              :custom-label="invoicesLabel"
              :clear-on-select="true"
              :placeholder="$t('invoices')"
              select-label=""
              :hide-selected="true"
              :multiple="true"
            >
            </Multiselect>
          </div>
        </div>
        <!-- start input group -->
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="form-label"> {{ $t("statement") }}</label>
            <textarea
              class="form-control"
              :placeholder="$t('add_statement')"
              v-model="report"
            ></textarea>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12 col-lg-12 mb-3">
          <div class="form-group">
            <label class="form-label" for="Passport">{{
              $t("attachment")
            }}</label>
            <div class="file-upload-form">
              <label
                for="files"
                class="form-control d-flex align-items-center justify-content-center w-100"
              >
                <i class="fa-solid fa-upload"></i>
                <div class="texts">
                  <p class="title mb-2">{{ $t("drag_image") }}</p>
                  {{ imageName == "" ? $t("upload_image_prompt") : imageName }}
                </div>
              </label>
              <input
                type="file"
                ref="file"
                id="files"
                hidden
                @change="previewImagePassport"
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
            </div>
          </div>
        </div>
        <!-- finish input group -->
        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton w-25">
            {{ $t("save") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss"></style>
