<script setup>
import { onMounted, ref } from "vue";
import pagination from "laravel-vue-pagination";
import axios from "axios";
import Swal from "sweetalert2";

const pages = ref([]);

const fetchPages = async () => {
  try {
    const response = await axios.get("/pages");
    pages.value = response.data;
  } catch (error) {
    console.log(error);
  }
};

const deletePage = async (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (!result.isConfirmed) return;

    axios
      .delete("/delete_page/" + id)
      .then(() => {
        fetchPages();
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      })
      .catch(() => {
        Swal.fire("Failed!", "There was something wrong.", "warning");
      });
  });
};

onMounted(async () => {
  await fetchPages();
});
</script>

<template>
  <div class="all_emp card_style mt-0">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center w-75">
        <h5 class="title">{{ $t("pages") }}</h5>
        <hr />
      </div>
      <router-link class="btn PrimaryButton btn-sm" to="/add-page">
        {{ $t("add_new_page") }}
      </router-link>
    </div>
    <div class="row mt-4">
      <table id="pageHotelTable" class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>{{ $t("page") }}</th>
            <th>{{ $t("Control") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(page, index) in pages.data" :key="index">
            <td>{{ page.id }}</td>
            <td>{{ page.title }}</td>
            <td>
              <!-- <router-link to="/questionstype2"><i class="fa-solid fa-eye btn-sm show_details" :title="$t('show')"></i></router-link> -->
              <router-link
                :title="$t('edit')"
                :to="`/edit-page/${page.id}`"
                class="btn edit_btn"
              >
                <i class="fa-regular fa-pen-to-square"></i>
              </router-link>
              <button
                :title="$t('delete')"
                class="btn remove_btn"
                type="button"
                @click="deletePage(page.id)"
              >
                <i class="fa-regular fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="pages"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchPages"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
