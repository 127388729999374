<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center gap-3 mb-2">
      <AddFavDailog v-tooltip.left="$t('add_to_favourite')" />
      <h4 class="mb-0">
        {{ $t("hotel_section") }}
      </h4>
    </div>
    <div class="main_header_style app mb-2">
      <input
        type="text"
        v-model="sectionText"
        @keyup="fetch_app_section((page = 1))"
        class="form-control w-50"
        :placeholder="$t('search...')"
      />
      <router-link to="/add_section" class="PrimaryButton btn"
        ><i class="fa-solid fa-plus"></i> {{ $t("add_section") }}</router-link
      >
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table app-sections">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("Title") }}</th>
              <th>{{ $t("describtion") }}</th>
              <th>{{ $t("The Countries") }}</th>
              <th>{{ $t("nationality") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="app_section in 10" :key="app_section">
              <td v-for="app_section in 6" :key="app_section">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(app_section, index) in app_section.data" :key="index">
              <td>{{ app_section.id }}</td>
              <td>{{ app_section?.title }}</td>
              <td v-html="app_section?.description"></td>
              <td>
                <p
                  v-for="(country, index) in app_section.countries"
                  :key="index"
                >
                  {{ country?.title }}
                </p>
              </td>
              <td>
                <p
                  v-for="(nationality, index) in app_section.nationalites"
                  :key="index"
                >
                  {{ nationality?.title }}
                </p>
              </td>
              <td>
                <button
                  class="btn btn-danger"
                  @click="deleteSection(app_section.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>

                <button
                  class="btn btn-success mx-2"
                  @click="$router.push(`/edit_section/${app_section.id}`)"
                >
                  <i class="fa-solid fa-pen"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="app_section"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_app_section"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
export default {
  name: "appSections",
  data() {
    return {
      app_section: {},
      sectionText: "",
      loading: true,
    };
  },
  components: {
    pagination,
    AddFavDailog,
    Skeleton,
  },
  methods: {
    fetch_app_section(page = 1) {
      axios
        .post(`/index_hotel_cards?page=${page}`, { word: this.sectionText })
        .then(({ data }) => {
          this.app_section = data.data;
          this.loading = false;
          // console.log(this.app_section.data);
        });
    },
    deleteSection(id, index) {
      Swal.fire({
        title: this.$t("delete_section"),
        text: this.$t("are_you_sure_you_want_to_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_decline"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios
            .delete(`/hotelcards/${id}`, { fromClick })
            .then(({ data }) => {
              Swal.fire({
                title: "",
                text: data.message,
                icon: "success",
              });
              this.app_section.data.splice(index, 1); // Remove the deleted item from the array
            })
            .catch((error) => {
              if(error.response.status != 403){
                Swal.fire({
                  title: "",
                  text: error.response.data.message,
                  icon: "error",
                });
              }
            });
        }
      });
    },
  },
  mounted() {
    this.fetch_app_section();
  },
};
</script>

<style>
table img {
  width: 100px;
}
</style>
