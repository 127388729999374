<template>
  <section class="card_style">
    <div class="main_header_style mb-2">
      <div class="d-flex align-items-center gap-3 mb-2">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("provider") }}</h4>
      </div>
      <router-link
        :to="{ name: 'AddProvider' }"
        class="PrimaryButton btn mt-0 w-25"
        ><i class="fa-solid fa-plus"></i> {{ $t("add_provider") }}</router-link
      >
    </div>

    <div class="row">
      <table class="table" id="exchangeTable">
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("provider_service") }}</th>
            <th>{{ $t("discription") }}</th>
            <!-- <th>Credintial</th> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in provider?.data" :key="index">
            <td>{{ item.id }} {{ item.name }}</td>
            <td class="service_lists">
              <span
                class="service_list"
                v-for="(service, indexx) in item.service_type"
                :key="indexx"
              >
                {{ $t(serviceTypeTranslations[service.service_type_id]) }}</span
              >
            </td>
            <td>{{ item.text }}</td>
            <!-- <td>
              <span
                v-if="item.credential && item.credential.active == 1"
                class="badge bg-success"
              >
                {{ $t("active") }}</span
              >
              <span
                v-else-if="item.credential && item.credential.active == 0"
                class="badge bg-danger"
              >
                {{ $t("diactive") }}</span
              >
              <router-link
                class="badge bg-info"
                :to="`provider/add-cred/${item.id}`"
                v-else-if="item.credential == null"
              >
                {{ $t("add_now") }}</router-link
              >
            </td> -->

            <td>
              <router-link
                :to="`/credintial/${item.id}`"
                class="btn btn-info text-white mx-1"
                :title="$t('all')"
              >
                <i class="fa-solid fa-circle-info"></i>
              </router-link>

              <router-link
                :to="`provider/add-cred/${item.id}`"
                class="btn btn-secondary text-white mx-1"
              >
                <i class="fa-solid fa-plus"></i>
              </router-link>

              <router-link
                class="btn btn-success text-white mx-1"
                :to="`/provider/edit/${item.id}`"
                :title="$t('edit')"
              >
                <i class="fa-solid fa-pen"></i>
              </router-link>

              <button
                class="btn btn-danger text-white mx-1 remove_btn"
                @click="DeleteAlert(index)"
              >
                <i class="fa-solid fa-trash" :title="$t('delete')"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="provider"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchProvider"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import Swal from "sweetalert2";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import $ from "jquery";
import AddFavDailog from "@/components/layout/addFavDailog.vue";

export default {
  name: "show-provider",
  components: {
    pagination,
    AddFavDailog,
  },
  data() {
    return {
      provider: {},
      serviceTypeTranslations: {
        1: "hotels",
        2: "flight",
        3: "transportation",
        4: "private_trips",
        // Add more mappings as needed...
      },
    };
  },
  methods: {
    //  active && disactive credintials
    async dealCredintial(id) {
      const formData = new FormData();

      formData.append("credential_id", id);

      try {
        const response = await axios.post(
          "/change_provider_credential_status",
          formData
        );
        Swal.fire({
          title: "",
          text: response.data.message,
          icon: "success",
        });
        location.reload(); // Refresh the current page
      } catch (error) {
        Swal.fire({
          title: "",
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    // fetch exchange data
    FetchProvider(page = 1) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/providers?page=" + page).then(({ data }) => {
        this.provider = data.data;
        // console.log(this.provider);
      });
    },
    //delete provider
    DeleteAlert(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.provider.data[index].id;
          const fromClick = true;
          axios.delete("/providers/" + `${delete_id}`, { fromClick }).then(({ data }) => {
            // console.log(result);
            // console.log(delete_id);
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.provider.data.splice(index, 1); // Remove the deleted item from the array
              // console.log(data);
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },

  mounted() {
    this.FetchProvider();
  },

  updated() {
    // axios.get("API_Goes_Here").then((response) => {
    //   this.products = response.data;
    // });
    $("#exchangeTable").DataTable({
      bPaginate: false,
      retrieve: true,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("classifications"),
        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("from") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("classifications"),
      },
    });
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "./provider.scss";
</style>
