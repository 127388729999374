<template>
  <section class="invoice">
    <!-- Head invoice -->
    <div class="head_invoice">
      <div class="book_status">
        <h6>
          booking status : 
          <span>{{ book_status_name }}</span>
        </h6>
        <div class="chart"></div>
        <div class="chart"></div>
      </div>
      <div class="logo">
        <img :src="pills.organization?.default_image" />
      </div>
      <div class="book_number">
        <div class="chart"></div>
        <div class="chart"></div>
        <h6>
          booking number : 
          <span>#{{ pills.book_number }}</span>
        </h6>
      </div>
    </div>
    <!-- Head invoice -->

    <!-- client reservation data -->
    <dataInvoicePerson :pills="pills" /> 
    <!-- client reservation data -->

    <!-- welcome invoice data -->
    <div class="welcome_text">
      <!-- <h4>Dear Sir : {{ pills.client_name }}</h4> -->
      <h5>
        Greeting From <span>{{ pills.organization?.name }}</span>
      </h5>
      <div class="pragra">
        <p>
          First of All, We would like to take this opportunity to welcome you
          at
          <strong> {{ pills.organization?.name }} </strong> and tourism We are
          pleased to confirm the following reservation on a
          <span>{{ book_status_name }}.</span>
        </p>
      </div>
    </div>
    <!-- welcome invoice data -->

    <!-- Table reservation -->
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="frist">HOTEL</th>
            <th class="frist">ROOM TYPE</th>
            <th scope="col">MEAL</th>
            <th scope="col">PAX</th>
            <th scope="col">CHECK IN</th>
            <th scope="col">CHECK OUT</th>
            <th scope="col">NTS</th>
            <th scope="col">Hotel conf</th>
            <th scope="col">RATE</th>
            <th class="last">TOTAL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in pills.rooms" :key="index">
            <td>{{room.hotel_name}}</td>
            <td>
              <p class="mb-0 fs-8">{{room.room_number}} X {{ room.room_name }}</p>
              <p class="mb-0 fs-8">{{ room.title_view }}</p>
            </td>
            <td>{{ room.meal_type?.title }}</td>
            <td>{{ room.guest_number }}</td>
            <td>
              <p class="mb-0">
                {{ room.check_in_date }}
              </p>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_in_date))
              }}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ room.check_out_date }}
              </P>
              <span class="hijri">{{
                updateHijriDate(new Date(room.check_out_date))
              }}</span>
            </td>
            <td>{{ room.number_of_nights }}</td>
            <td>{{ room.hotel_reference }}</td>
            <td>{{ (room.total_selling_price_room_without_tax / room.number_of_nights).toFixed(2) }}</td>
            <td class="last">
              {{ room.total_selling_price_room_without_tax }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Table reservation -->

    <!-- Table services -->
    <services :service="service" v-if="service.length" />
    <!-- Table services -->

    <!-- Terms & amount reservation -->
    <div class="amounts_terms">
      <!-- Terms -->
      <Terms :terms="pills?.Conditions" />
      <!-- Terms -->
  
      <TotalPrice :total_selling_price_with_out_tax="total_selling_price_with_out_tax" :selling_tax="selling_tax" :total_selling_price="total_selling_price" />
    </div>
    <!-- Terms & amount reservation -->

    <!-- Account Data -->
    <div class="table_account" v-if="pills?.account">
      <div class="list_account d-flex align-items-center justify-content-between w-100">
        <div class="account_data">
          <p class="title_account">Account Name</p>
          <p class="text_account">: {{ pills.account?.title }}</p>
        </div>
        <div class="account_data">
          <p class="title_account">IBAN</p>
          <p class="text_account">: {{ pills.account?.iban }}</p>
        </div>
        <div class="account_data">
          <p class="title_account">Account number</p>
          <p class="text_account">: {{ pills.account?.account_number }}</p>
        </div>
        <div class="account_data">
          <p class="title_account">Swift Code</p>
          <p class="text_account">: {{ pills.account?.swift_code }}</p>
        </div>
      </div>
    </div>
    <!-- Account Data -->

    <!-- Invoice Organization Data -->
    <div class="invoice_organiztion_data">
      <ul class="social_accounts">
        <li>
          <i class="fa-solid fa-globe"></i>
          <a :href="pills.organization?.website_link" target="_blank" class="text">{{ pills.organization?.website_link }}</a>
        </li>
        <li>
          <i class="fa-solid fa-envelope"></i>
          <a :href="`mailto:${pills.organization?.email}`" class="text">{{ pills.organization?.email }}</a>
        </li>
        <li>
          <i class="fa-solid fa-phone"></i>
          <a :href="`tel:${pills.organization?.phone}`" class="text">{{ pills.organization?.phone }}</a>
        </li>
        <li>
          <i class="fa-solid fa-location-dot"></i>
          <p class="text">{{ pills.organization?.address }}</p>
        </li>
      </ul>
      <div class="logo">
        <img :src="pills.organization?.default_image" />
        <employeeData :pills="pills" />
        <socialMedia :organization="pills.organization" />
      </div>
    </div>
    <!-- Invoice Organization Data -->

    <div class="footer_invoice"></div>
  </section>
</template>

<script>
import services from './component/servicesEN.vue';
import TotalPrice from './component/TotalPriceEn.vue';
import socialMedia from './component/socialMedia.vue';
import Terms from './component/termsEn.vue';
import dataInvoicePerson from './component/dataInvoicePersonEn.vue';
import employeeData from './component/employeeData.vue';

export default {
  name: "pill-hotel-confirm",
  props: {
    pills: Object,
    service: {
      type: Array,
      required: true,
      default: () => []
    },
    terms: String,
    total_selling_price_with_out_tax: String,
    selling_tax: String,
    total_selling_price: String,
    book_status_name: String,
  },
  components: {
    services,
    TotalPrice,
    socialMedia,
    Terms,
    dataInvoicePerson,
    employeeData
  }
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
.invoice {
  .invoice_organiztion_data {
    .social_accounts, .logo {
      width: 50% !important;
    }
  }
}
</style>
