<template>
  <div class="row">
    <div class="col-12">
      <Skeleton width="20%"></Skeleton>
    </div>
    <div class="col-12 my-3">
      <Skeleton size="10rem"></Skeleton>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 my-3" v-for="i in 2" :key="i">
      <Skeleton width="20%" class="mb-2"></Skeleton>
      <Skeleton width="70%" height="2rem"></Skeleton>
    </div>
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
  components: {
    Skeleton,
  },
};
</script>

<style></style>
