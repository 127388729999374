<template>
  <div class="grid-container">
    <template v-for="(key, index) in filterKeys" :key="index">
      <div class="grid-item" v-if="hasData($store.state.filteroffline[key])">
        <strong>{{ keyDescriptions[key] }}:</strong>
        <template v-if="isArray($store.state.filteroffline[key])">
          <Chip
            v-for="item in $store.state.filteroffline[key]"
            :key="item?.id"
            :label="formatLabel(item)"
          />
        </template>
        <template v-else>
          <Chip :label="formatLabel($store.state.filteroffline[key])" />
        </template>
      </div>
    </template>

    <div
      class="grid-item"
      v-if="hasData($store.state.filteroffline.create_at_to)"
    >
      <strong>{{ $t("create_at_to") }}:</strong>
      <Chip :label="formatDate($store.state.filteroffline.create_at_to[0])" />
    </div>

    <div
      class="grid-item"
      v-if="hasData($store.state.filteroffline.checkin_from)"
    >
      <strong>{{ $t("checkin") }}:</strong>
      <Chip :label="formatDate($store.state.filteroffline.checkin_from[0])" />
    </div>

    <div
      class="grid-item"
      v-if="hasData($store.state.filteroffline.checkout_to)"
    >
      <strong>{{ $t("checkout") }}:</strong>
      <Chip :label="formatDate($store.state.filteroffline.checkout_to[0])" />
    </div>

    <div
      class="grid-item"
      v-if="hasData($store.state.filteroffline.optionDate)"
    >
      <strong>{{ $t("option Date") }}:</strong>
      <Chip :label="formatDate($store.state.filteroffline.optionDate[1])" />
    </div>
  </div>
</template>

<script>
import Chip from "primevue/chip";
import moment from "moment";

export default {
  components: {
    Chip,
  },
  computed: {
    filterKeys() {
      return [
        "hotelValue",
        "roomValue",
        "supplierValue",
        "client_id",
        "payment_status",
        "booking_status",
        "post_status",
        "city",
        "nationality",
        "book_number",
        "hotel_ref",
        "suplier_ref",
        "hotel_as_suplier",
        "employ",
        "meal",
        "rate",
        "client_category",
        "paymentMethod",
        "paymentSuplier",
        "clientType",
        "suplierType",
        "discountType",
        "guest",
        "share_amount",
      ];
    },
    keyDescriptions() {
      return {
        hotelValue: this.$t("Hotel Name"),
        roomValue: this.$t("room_name"),
        supplierValue: this.$t("Supplier"),
        client_id: this.$t("client"),
        payment_status: this.$t("payment_book_status"),
        booking_status: this.$t("booking_offline_status"),
        post_status: this.$t("post_status"),
        city: this.$t("city"),
        nationality: this.$t("nationality"),
        book_number: this.$t("Book Number"),
        hotel_ref: this.$t("hotel_refs"),
        suplier_ref: this.$t("suplier_refs"),
        hotel_as_suplier: this.$t("hotel_as_supliers"),
        employ: this.$t("employ"),
        meal: this.$t("meals"),
        rate: this.$t("Rate Code"),
        client_category: this.$t("client category"),
        paymentMethod: this.$t("payment method"),
        paymentSuplier: this.$t("payment suplier status"),
        clientType: this.$t("client type"),
        suplierType: this.$t("suplier type"),
        discountType: this.$t("dsicount type"),
        guest: this.$t("guest"),
        share_amount: this.$t("share_amount"),
      };
    },
  },
  methods: {
    isArray(value) {
      return Array.isArray(value);
    },
    hasData(value) {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== undefined && value !== null && value !== "";
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    formatLabel(item) {
      if (
        typeof item === "string" &&
        moment(item, moment.ISO_8601, true).isValid()
      ) {
        return this.formatDate(item);
      }
      return item?.title || item?.name || item?.client_name || item;
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three equal columns */
  gap: 16px; /* Adjust spacing between grid items */
}

.grid-item {
  padding: 8px; /* Add padding inside each grid item */
}
</style>
