<template>
  <section class="all_emp card_style">
    <div class="d-flex align-items-center mb-2 gap-3">
      <AddFavDailog v-tooltip.left="$t('add to favorites')" />
      <h4>
        {{ $t("roles name") }}
      </h4>
    </div>
    <div class="main_header_style mb-2">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="search-visa w-100">
          <form>
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control w-50"
              :placeholder="$t('search...')"
              v-model="roles_search"
              @keyup="fetchroles((page = 1))"
            />
          </form>
        </div>
        <router-link to="/permission" class="PrimaryButton mt-0 btn w-25"
          ><i class="fa-solid fa-plus"></i> {{ $t("add") }}
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table visaType" id="VisaTable">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("title") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="role in 10" :key="role">
              <td v-for="role in 4" :key="role">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(role, index) in roles.data" :key="index">
              <td>{{ role.id }}</td>
              <td>{{ role?.name ? role?.name : "-" }}</td>

              <td>
                <router-link
                  v-if="role.id != 23"
                  :to="`/permission/${role.id}`"
                  class="btn btn-success mx-1 w-auto"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>

                <div
                  v-if="role.id != 23"
                  class="btn btn-danger mx-1 w-auto"
                  @click="deleterole(role.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="roles"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchroles"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import Skeleton from "primevue/skeleton";
export default {
  name: "permissionrole-page",
  components: {
    pagination,
    Skeleton,
    AddFavDailog,
  },
  data() {
    return {
      roles: [],
      roles_search: "",
      loading: true,
    };
  },
  methods: {
    fetchroles(page = 1) {
      axios
        .post(`/index_permissions_role?page=${page}`, {
          word: this.roles_search,
          per_page: 10,
        })
        .then(({ data }) => {
          this.roles = data.data;
          this.loading = false;
        });
    },
    // delete banner
    deleterole(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios
            .delete(`/delete_permissions_role/${id}`, { fromClick })
            .then(({ data }) => {
              if (data.status == true) {
                Swal.fire("", this.$t("success_delete"), "success");
                this.roles.data.splice(index, 1);
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            });
        }
      });
    },
  },
  mounted() {
    this.fetchroles();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-visa {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
</style>
