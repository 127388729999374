<template>
  <div class="tabs_sec">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="hotel-tab"
          data-bs-toggle="tab"
          data-bs-target="#hotel-tab-pane"
          type="button"
          role="tab"
          aria-controls="hotel-tab-pane"
          aria-selected="true"
        >
          {{ $t("rooms") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="hotel-tab"
          data-bs-toggle="tab"
          data-bs-target="#hotel-tab-description"
          type="button"
          role="tab"
          aria-controls="hotel-tab-description"
          aria-selected="true"
        >
          {{ $t("description") }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="flight-tab"
          data-bs-toggle="tab"
          data-bs-target="#flight-tab-pane"
          type="button"
          role="tab"
          aria-controls="flight-tab-pane"
          aria-selected="false"
        >
          {{ $t("Hotel annexes") }}
        </button>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="hotel-tab-pane"
        role="tabpanel"
        aria-labelledby="hotel-tab"
        tabindex="0"
      >
        <allRooms />
      </div>
      <div
        class="tab-pane fade show active"
        id="hotel-tab-description"
        role="tabpanel"
        aria-labelledby="hotel-tab-description"
        tabindex="0"
      >
        <descriptionHotel />
      </div>
      <div
        class="tab-pane fade"
        id="flight-tab-pane"
        role="tabpanel"
        aria-labelledby="flight-tab"
        tabindex="0"
      >
        <HotelAnnexes />
      </div>
    </div>
  </div>
</template>

<script>
import allRooms from "@/components/hotelOffline/allRooms.vue";
import descriptionHotel from "@/components/hotelOffline/descriptionHotel.vue";
import HotelAnnexes from "@/components/hotelOffline/HotelAnnexes.vue";

export default {
  name: "tabs-consumer",
  components: {
    allRooms,
    HotelAnnexes,
    descriptionHotel,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.tabs_sec {
  border-radius: 16px;
  ul {
    margin: 0;
  }
  h4 {
    font-size: 1.2rem;
    font-family: "bold";
    color: $PrimaryText;
    margin: 2% 0;
  }

  .nav-tabs {
    border-bottom: 1px solid #d0d2d7;

    li {
      .nav-link {
        font-size: 1rem;
        font-family: "light";
        color: #656b78;
      }
      .nav-link.active {
        border: unset;
        border-bottom: 2px solid $PrimaryColor;
        color: $PrimaryColor;
        font-weight: 700;
        background-color: transparent;
      }
    }
  }
}
</style>
