<template>
  <section class="card_style" style="min-height: 100dvh">
    <div class="w-100" v-if="loading && $route.params.id">
      <Skeleton width="10%" class="mb-2"></Skeleton>
      <Skeleton width="20%" height="5rem"></Skeleton>
      <div class="d-flex mt-4 w-100">
        <Skeleton width="30%" height="3rem"></Skeleton>
        <Skeleton width="30%" class="mx-2" height="3rem"></Skeleton>
        <Skeleton width="30%" height="3rem"></Skeleton>
      </div>
    </div>

    <form @submit.prevent="addTheme()" v-else>
      <div class="main_header_style">
        <h4 v-if="!$route.params.id">
          {{ $t("Add theme") }}
        </h4>
        <h4 v-else>
          {{ $t("Edit theme") }}
        </h4>
      </div>
      <div class="row d-flex align-items-center justify-content-center my-5">
        <div class="d-flex align-items-center gap-5 my-3">
          <div class="col-lg-2 col-md-4 col-12">
            <div class="image_upload mb-1">
              <label
                for="imageEntry"
                class="upload_img"
                v-if="imageEntry == ''"
              >
                <img :src="image ? image : defaultImage" alt="user" />
              </label>
              <img
                :src="imageEntry"
                v-else-if="imageEntry"
                class="entry_image"
                alt="entry"
              />
              <input
                type="file"
                id="imageEntry"
                class="form-control"
                @change="onFileChange"
                hidden
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
            </div>
            <span
              class="text-danger"
              v-if="v$.imageEntry.$error"
              v-text="v$.imageEntry.$errors[0].$message"
            ></span>
          </div>

          <div class="col-lg-2 col-md-2 col-12">
            <div class="form-group">
              <h4 class="point_title">{{ $t("code") }}</h4>
              <input
                type="text"
                class="form-control p-2"
                v-model="code"
                :placeholder="$t('code')"
              />
            </div>
            <span
              class="text-danger"
              v-if="v$.code.$error"
              v-text="v$.code.$errors[0].$message"
            ></span>
          </div>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("save") }}
      </button>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Editor from "primevue/editor";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import Skeleton from "primevue/skeleton";

export default {
  name: "Add-Gifts",
  data() {
    return {
      name: "AddInvoice-Theme",
      v$: useVuelidate(),

      imageEntry: "",
      defaultImage: require("@/assets/media/image/icon/user.png"),
      //   blog_id: undefined,
      code: "",
      loading: true,
    };
  },
  components: {
    // Editor,
    Skeleton,
  },
  validations() {
    return {
      code: {
        required,
      },
      imageEntry: this.$route.params.id ? {} : { required },
    };
  },
  methods: {
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    addTheme() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        formData.append("code", this.code);

        if (this.fileData != undefined) {
          formData.append("image", this.fileData);
        }

        axios
          .post(`/store_invoice_theme`, formData)
          .then(({ data }) => {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
            });
            this.$router.push("/invoice-theme/show");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
    fetchGifts() {
      if (this.$route.params.id != undefined) {
        //  this.blog_id = this.$route.params.id;
        axios
          .get(`/package_book_gift/${this.$route.params.id}`)
          .then(({ data }) => {
            // console.log(data.data, "wwww");

            this.imageEntry = data.data.image;
            this.code = data.data.code;
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.fetchGifts();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
.image_upload {
  width: 150px;
  height: 150px;
  margin-bottom: 1.5rem;
  .upload_img,
  .entry_image {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 150px;
    }
  }
}
.form-group {
  label {
    margin-bottom: 0.5rem;
  }
}
.main_header_style {
  margin-bottom: 0;
}
.point_title {
  color: #000;
  font-family: "bold";
  font-size: 1.2rem;
}
</style>
