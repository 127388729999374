<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_new_service") }}</h4>
    </div>
    <form class="form_topic" @submit.prevent="addservice()">
      <div class="row">
        <div class="col-12">
          <div class="accordion" id="accordionExample1">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingName">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseName"
                  aria-expanded="false"
                  aria-controls="collapseName"
                >
                  {{ $t("service_title") }}
                </button>
              </h2>
              <div
                id="collapseName"
                class="accordion-collapse collapse show"
                aria-labelledby="headingName"
                data-bs-parent="#accordionExample1"
              >
                <div class="accordion-body">
                  <div
                    class="form-group"
                    v-for="(title, index) in titles"
                    :key="index"
                  >
                    <label :for="`name${index}`"
                      >{{ $t("title") }} ({{ title.lang }})</label
                    >
                    <input
                      type="text"
                      :placeholder="$t('name')"
                      class="form-control"
                      :id="`name${index}`"
                      v-model="title.title"
                    />
                    <div
                      v-for="error in v$.titles.$each.$response.$errors[index].title"
                      :key="error"
                      class="input-errors"
                    >
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- start input group -->
        <div class="col-12">
          <button type="submit" class="btn PrimaryButton mt-5">
            {{ $t("add") }}
          </button>
        </div>
        <!-- finish input group -->
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
export default {
  name: "add-service",
  data() {
    return {
      languages: ["ar","en","id"],
      titles: [],
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      titles:{
        $each: helpers.forEach({
          title: {required}
        })
      }
    };
  },
  methods: {
    setTitlesLang(){
      this.languages.forEach((ele)=>{
        this.titles.push({
          lang: ele,
          title: ""
        })
      })
    },
    async addservice() {
      this.v$.$validate();
      if (!this.v$.$error) {
        const formData = new FormData();
        this.titles.forEach((ele)=>{
          formData.append(`title_${ele.lang}`, ele.title);
        })
        axios
          .post("/additional_services", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/services_dashboard");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
          });
      }
    },
  },
  mounted(){
    this.setTitlesLang();
  }
};
</script>
<style lang="scss" scoped>
@import "./_services.scss";
</style>
