<template>
  <section class="card_style" style="min-height: 100vh">
    <h4 class="mb-2">{{ $t("email_settings") }}</h4>
    <div class="main_body_style">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <div class="form-group">
            <label>{{ $t("user_name") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('user_name')"
              v-model="Form.userName"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="form-group">
            <label>{{ $t("email") }}</label>
            <input
              type="email"
              class="form-control"
              :placeholder="$t('email')"
              v-model="Form.email"
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <div class="form-group">
            <label>{{ $t("password") }}</label>
            <input
              type="password"
              class="form-control"
              :placeholder="$t('password')"
              v-model="Form.password"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="main_footer_style">
      <button type="button" class="btn PrimaryButton" @click="addEmail()">
        {{ $t("save") }}
      </button>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      Form: {
        password: "",
        email: "",
        userName: "",
      },
    };
  },
  methods: {
    async addEmail() {
      const formData = new FormData();
      formData.append("mail_username", this.Form.userName);
      formData.append("organization_mail", this.Form.email);
      formData.append("mail_password", this.Form.password);

      await axios.post(`/organization_mailers`, formData).then((res) => {
        Swal.fire({
          title: this.$t("success"),
          text: res.data.message,
          icon: "success",
        });
      });
    },
  },
  async mounted() {
    await axios.get(`/fetch_organization_mailer`).then((res) => {
      this.Form.email = res.data.data.organization_mail;
      this.Form.userName = res.data.data.mail_username;
      this.Form.password = res.data.data.mail_password;
      //   console.log(res.data.data);
    });
  },
};
</script>

<style></style>
