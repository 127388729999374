<template>
  <canvas id="myChart"></canvas>
</template>

<script setup>
  import { onMounted } from "@vue/runtime-core";
  import { Chart } from "chart.js";
  import axios from "axios";

  onMounted(async () => {
    try {
      const response = await axios.get("/fetch_home_data"); // Replace with your actual API endpoint
      const employee_names = response.data.data.employee_chart.employee_names;
      const employee_booking_count =
        response.data.data.employee_chart.employee_booking_count;
      const employee_flight_count =
        response.data.data.employee_chart.employee_flight_count;

      let myChart = new Chart("myChart", {
        type: "bar",
        data: {
          labels: employee_names,
          datasets: [
            {
              label: "hotel",
              backgroundColor: "rgba(197, 112, 22, 0.2)",
              borderColor: "rgba(197, 112, 22, 1)",
              borderWidth: 1,
              data: employee_booking_count,
            },
            {
              label: "flight",
              backgroundColor: "rgba(10, 144, 155, 0.2)",
              borderColor: "rgba(10, 144, 155, 1)",
              borderWidth: 1,
              data: employee_flight_count,
            },
          ],
        },
        options: {
          responsive: false,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
        },
      });

      //Udate Chart
      // myChart.data.datasets[0].data[0] = 10;
      myChart.update();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  });
</script>
