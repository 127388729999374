<template>
  <div
    title="whatsapp"
    class="btn SecondButton fs-12 d-flex align-items-center justify-content-center"
    @click="
      visible = true;
      fetchOfflineInvoice;
    "
  >
    <i class="fa-solid fa-envelope"></i>
  </div>

  <Dialog v-model:visible="visible" modal class="w-50" :dismissableMask="true">
    <h4 class="my-2">{{ $t("send_email") }}</h4>
    <form @submit.prevent="onSubmit()">
      <div class="d-flex align-items-center justify-content-between gap-2">
        <Multiselect
          :options="invoices"
          v-model="invoice"
          label="title"
          track-by="id"
          :clear-on-select="true"
          :placeholder="$t('invoice')"
          select-label=""
          :hide-selected="true"
          @update:model-value="generateEmailBody()"
        ></Multiselect>
        <router-link
          :to="`/book_table/${this.$route.params.id}/${invoice.router}`"
          target="_blank"
          class="btn PrimaryButton text-nowrap"
          v-if="invoice.router !== undefined"
        >
          {{ $t("download_pdf") }}
        </router-link>
      </div>
      <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">
          {{ $t("email") }}
        </label>
        <input
          type="email"
          class="form-control"
          id="exampleFormControlInput1"
          v-model="receiver_email"
          :placeholder="$t('email')"
          required
        />
      </div>
      <div class="mb-3">
        <label for="exampleFormControlTextarea1" class="form-label">{{
          $t("body")
        }}</label>
        <Editor
          class="form-control text-start"
          :placeholder="$t('body')"
          id="exampleFormControlTextarea1"
          rows="8"
          v-model="body"
          required
        />
      </div>
      <div class="form-group">
        <label for="file" class="form-label"
          >{{ $t("file") }}
          <div class="py-2 px-3 d-flex gap-2 align-items-center border rounded">
            <i class="fa-solid fa-file"></i>
            <span>
              {{ $t("Attach a File") }}
            </span>
          </div></label
        >
        <input
          id="file"
          type="file"
          hidden
          accept="application/pdf"
          @change="uploadFile"
        />
      </div>
      <button type="submit" class="btn SecondButton">{{ $t("send") }}</button>
    </form>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Editor from "primevue/editor";

import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      visible: false,
      receiver_email: this.clientEmail,
      subject: "",
      invoice: "",
      file: "",
      body: null, // This is where the user can enter custom body text
      organization_name: JSON.parse(localStorage.getItem("user"))
        .organization_name,
      invoices: [
        {
          id: 1,
          title: "AMENDMENT",
        },
        {
          id: 2,
          title: "Voucher",
          router: localStorage.getItem("lang") ? "voucher-en" : "voucher-ar",
        },
        {
          id: 3,
          title: "Supplier Voucher",
          router: "supplier-voucher",
        },
        {
          id: 4,
          title: "ROOMING LIST",
          router: "rooming-list",
        },
        {
          id: 5,
          title: "PRICE OFFER",
          router: "price-offer",
        },
        {
          id: 6,
          title: "TENTATIVE",
          router: "option-date",
        },
        {
          id: 7,
          title: "TAX INVOICE",
          router:
            localStorage.getItem("lang") == "en"
              ? "tax-innvoice-en"
              : "tax-innvoice-ar",
        },
        {
          id: 8,
          title: "Non Refundable",
          router: "non-refundable",
        },
        {
          id: 9,
          title: "Confirmation",
          router: "pill-confirm",
        },
        {
          id: 10,
          title: "Cancelation",
        },
      ],
    };
  },

  props: [
    "guest_name",
    "rooms",
    "total_cost_price_with_out_tax",
    "total_room_cost_with_tax",
    "clientEmail",
    "reservation_number",
    "tax",
  ],
  components: {
    Dialog,
    Editor,
    Multiselect,
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        console.log(this.invoice);
        // Set the email value to the clientEmail prop when the dialog is opened
        this.receiver_email = this.clientEmail;
        this.subject = "تفاصيل الحجز";
        this.invoice = {
          id: 9,
          title: "Confirmation",
        };

        this.body = this.generateEmailBody();
      }
    },
    invoice(newVal) {
      if (newVal) {
        this.body = this.generateEmailBody();
      }
    },
  },
  methods: {
    uploadFile(event) {
      this.file = event.target.files[0];
      console.log(this.file);
    },
    resetForm() {
      this.subject = "";
      this.body = "";
    },
    fetchOfflineInvoice() {
      axios
        .post("/fetch_offline_book_hotel_invoice", {
          offline_booking_hotel_id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.pills = data.data;
          this.book_status_name = data.data.book_status_name;
          this.optionDate = data.data.client_option_date;
          this.total_selling_price_with_out_tax =
            data.data.total_selling_price_with_out_tax.toFixed(2);
          this.selling_tax = data.data.selling_tax.toFixed(2);
          this.total_selling_price = data.data.total_selling_price.toFixed(2);
        });
    },

    generateEmailBody() {
      let roomsList = this.rooms
        .map((room, index) => {
          if (!room.room) {
            console.warn(
              `Room ${index + 1} is missing the 'room' property.`,
              room
            );
            return `<p>Room ${index + 1} is missing details.</p>`;
          }
          return `<p>Room ${index + 1}:<br>Hotel Title: ${room.hotel_title}<br>Room Title: ${room.room.title}</p>`;
        })
        .join("");

      return `<p>Dear: ${this.guest_name},</p>
          <p>Greeting From ${this.organization_name},</p>
          <p>First of all, we would like to take this opportunity to welcome you at ${this.organization_name} and tourism. We are pleased to confirm the following reservation on a ${this.invoice?.title || "Unknown"} basis.</p>
          <p>Reservation Number: # ${this.reservation_number}</p>
          <p>Rooms:</p>
          ${roomsList}
          <p>Total Cost Without Tax: ${Math.round(this.total_cost_price_with_out_tax)}</p>
          <p>Total Tax: ${Math.round(this.tax)}</p>
          <p>Total Cost With Tax: ${Math.round(this.total_room_cost_with_tax)}</p>
          <p>Best Regards,<br>${this.organization_name} Team</p>`;
    },

    onSubmit() {
      const formData = new FormData();

      formData.append("receiver_email", this.receiver_email);
      formData.append("subject", this.subject);
      formData.append("body", this.computedBody); // Use computedBody instead of directly using body
      formData.append("pdf_file", this.file);

      axios
        .post("/new_send_email", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.visible = !this.visible;
          this.resetForm();
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.visible = !this.visible;
          this.resetForm();
        });
    },
  },

  computed: {
    computedBody() {
      // If the user has entered something in the body, use it; otherwise, use the generated default
      return this.body ? this.body : this.generateEmailBody();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/offline/invoice/_pill.scss";
.logo {
  width: 100px;
  height: 100px;
}

.SecondButton {
  background-color: transparent;
  color: #0a909b;
  border: 1px solid #0a909b;
  font-size: 1.2rem;
  margin: 0 5px;
  font-family: "bold";
  padding: 0.6rem 1.1rem;
  transition: 0.7s ease-in-out;

  &:hover {
    background-color: #0a909b;
    color: #ffff;
    border: 1px solid #ffff;
  }
}
</style>
