<template>
  <section class="card_style">
    <div class="main_header">
      <h4>{{ $t("purchasesBillShow") }}</h4>
    </div>

    <form @submit.prevent="returnToIndexPage()">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group mt-3">
            <label>{{ $t("title") }}</label>
            <input
              type="text"
              class="form-control"
              :placeholder="$t('title')"
              v-model="title"
              disabled
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group mt-3">
            <label>{{ $t("amount") }}</label>
            <input
              type="number"
              class="form-control"
              min="0"
              v-model="amount"
              :placeholder="$t('amount')"
              disabled
            />
          </div>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <div class="form-group mt-3">
            <label>{{ $t("supplier") }}</label>
            <input type="text" class="form-control" v-model="suplierText" disabled />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group mt-3">
            <label>{{ $t("description") }}</label>
            <textarea
              rows="3"
              cols="40"
              type="number"
              class="form-control"
              resize
              :placeholder="$t('description')"
              v-model="report"
              disabled
            ></textarea>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-12 my-4">
          <div class="flex align-items-center justify-content-around mx-2">
            <RadioButton v-model="selectedOption" inputId="Bank" value="Bank" disabled/>
            <label for="Bank" class="mx-2 mb-0 label">{{
              $t("bank_account")
            }}</label>
            <RadioButton
              v-model="selectedOption"
              inputId="pocket"
              value="pocket"
              disabled
            />
            <label for="pocket" class="mx-2 mb-0 label">{{ $t("Safe") }}</label>
          </div>
          <div class="flex mt-2">
            <input type="text" class="form-control" v-model="accountValue" disabled />
          </div>
        </div>
        <div class="col-12">
          <button class="PrimaryButton btn" type="submit">
            {{ $t("return") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "axios";
import RadioButton from "primevue/radiobutton";

export default {
  name: "purchasesBillAdd",
  components: {
    RadioButton,
  },
  data() {
    return {
      supliers: [],
      suplierText: "",
      selectedOption: "Bank",
      bankIngredient: [],
      pocketIngredient: [],
      safeValue: "",
      accountValue: "",
      organizationAccounts: [],
      organizationSafe: [],
      amount: "",
      report: "",
      title: "",
    };
  },
  methods: {
    fetchBillData(){
        axios.get(`/show_purchases_bill/${this.$route.params.id}`).then(({data})=>{
            this.title = data.data.title;
            this.amount = data.data.amount;
            this.suplierText = data.data.supplier?.name;
            this.report = data.data.report;
            this.accountValue = data.data.account?.title;
            this.selectedOption = data.data.account?.type == 1 ? "Bank" : "pocket";
        })
    },
    returnToIndexPage(){
        this.$router.push("/purchases-bill")
    }
  },
  mounted(){
    this.fetchBillData();
  }
  
};
</script>

<style lang="scss" scoped>
textarea {
  width: 1810px;
  height: 120px;
  resize: none;
}
.label {
  font-weight: bold;
  font-size: 1.2rem;
  color: #656b78;
}
</style>
