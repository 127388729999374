<script setup>
import { ref } from "vue";
import axios from "axios";
import Multiselect from "vue-multiselect";
import { useRouter } from "vue-router";

const router = useRouter();

const hotel = ref("");
const hotels = ref([]);
const title = ref("");
const slug = ref("");

const onInput = async (word) => {
  console.log(word);
  if (word.length > 2) {
    await axios
      .post(`/fetch_new_home_hotels`, { word: word })
      .then((res) => {
        hotels.value = res.data.data.hotels;
        // console.log(this.hotelsArray[index].hotels);
      })
      .catch((err) => console.log(err));
  }
};

const addCampaign = async () => {
  let formData = new FormData();
  formData.append("name", title.value);
  formData.append("slug", slug.value);
  hotel.value.forEach((element) => {
    formData.append("hotel_ids[]", element.id);
  });
  await axios
    .post("/store_advertising_campaign", formData)
    .then((res) => {
      console.log(res);
      router.push("/campaigns");
    })
    .catch((err) => console.log(err));
};
</script>

<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("add_new_campaign") }}</h4>
    </div>
    <div class="card_body_style">
      <form @submit.prevent="addCampaign">
        <div class="form-group">
          <label>{{ $t("title") }}</label>
          <input type="text" class="form-control" v-model="title" required />
        </div>
        <div class="form-group">
          <label>{{ $t("slug") }}</label>
          <input type="text" class="form-control" v-model="slug" required />
        </div>

        <div class="custom-input-container">
          <label for=""> {{ $t("choose_hotel") }}</label>
          <Multiselect
            v-model="hotel"
            :options="hotels"
            label="title"
            @keyup="onInput($event.target.value)"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_hotel')"
            select-label=""
            :multiple="true"
            :hide-selected="true"
          ></Multiselect>
        </div>

        <br />
        <button type="submit" class="btn PrimaryButton mt-2">
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </section>
</template>

<style scoped lang="scss">
.custom-input-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  font-size: 0.8rem;
}

.custom-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.custom-input:focus {
  outline: none;
}

.suggestions-list {
  &::-webkit-scrollbar {
    display: block;
    width: 10px;
    height: 11px;
  }
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px; /* Adjust as needed */
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensures suggestions are on top */
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
  border-top: 1px solid #ccc;
  font-size: 0.8rem;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

.suggestion-item:active {
  background-color: #e0e0e0;
}
</style>
