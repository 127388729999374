<template>
  <section class="portrait">
    <div class="container-fluid">
      <div class="main-parnt p-4">
        <div
          id="print-button"
          class="print_button d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center">
            <button
              class="PrimaryButton printButton"
              v-print="'#specific-section'"
            >
              <i class="fa fa-print"></i>
            </button>
            <button
              class="PrimaryButton mx-2 printButton"
              @click="visible = true"
              v-if="pills.lock_status == 'posted' && pills.HasTaxInvoice != 1"
            >
              {{
                pills.HasTaxInvoice == 1
                  ? $t("show") + " " + $t("TAX INVOICE") + " EN"
                  : $t("export") + " " + $t("TAX INVOICE") + " EN"
              }}
            </button>
            <router-link
              :to="`/tax-invoice-en/${pills.base_booking_id}`"
              class="PrimaryButton mx-2 printButton"
              v-if="pills.lock_status == 'posted' && pills.HasTaxInvoice == 1"
            >
              {{
                pills.HasTaxInvoice == 1
                  ? $t("show") + " " + $t("TAX INVOICE") + " EN"
                  : $t("export") + " " + $t("TAX INVOICE") + " EN"
              }}
            </router-link>
          </div>
          <div class="d-flex align-items-end gap-3">
            <router-link :to="`/hotels/book_offline/${book_id}`" target="_blank">{{
              pills.book_number
            }}</router-link>
            <h4 class="invoice-title">Book number</h4>
          </div>
        </div>
        <sidebar-pill :book_status_name="book_status_name" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="main-parnt a4 pb-5">
        <div class="row">
          <div class="col-12">
            <div class="specific-section" id="specific-section">
              <amendment
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'amendment'"
              />
              <cancelation
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :optionDate="optionDate"
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'cancelation'"
              />
              <optionDate
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'option-date'"
              />
              <priceOffer
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'price-offer'"
              />
              <taxInnvoiceEn
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                :qr_code="qr_code"
                v-if="type == 'tax-innvoice-en'"
              />
              <taxInnvoiceAr
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                :qr_code="qr_code"
                v-if="type == 'tax-innvoice-ar'"
              />
              <confirm
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'pill-confirm'"
              />
              <nonRefund
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'non-refundable'"
              />
              <report
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :service="pills.service"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :optionDate="optionDate"
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'report'"
              />
              <roomingList
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'rooming-list'"
              />
              <supplierVoucher
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'supplier-voucher'"
              />
              <voucherEn
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'voucher-en'"
              />
              <voucherAr
                :book_status_name="book_status_name"
                :pills="pills"
                :terms="terms"
                :total_selling_price_with_out_tax="
                  total_selling_price_with_out_tax
                "
                :selling_tax="selling_tax"
                :total_selling_price="total_selling_price"
                v-if="type == 'voucher-ar'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <Dialog
    v-model:visible="visible"
    modal
    :dismissableMask="true"
    :header="$t('export_tax_invoice')"
    :style="{ width: '30rem' }"
  >
    <p class="h6 p-text-secondary block mb-3">{{ $t("issue_tax_invoice") }}</p>
    <router-link
      :to="`/tax-invoice-en/${pills.base_booking_id}`"
      class="btn PrimaryButton"
      >{{
        pills.HasTaxInvoice == 1
          ? $t("show") + " " + $t("TAX INVOICE") + " EN"
          : $t("export") + " " + $t("TAX INVOICE") + " EN"
      }}</router-link
    >
  </Dialog>
</template>

<script>
import axios from "axios";
import sidebarPill from "@/view/dashboard/offline/sidebarInvoiceNew.vue";
import amendment from "@/components/offline/newInvoice/amendment.vue";
import cancelation from "@/components/offline/newInvoice/cancelation.vue";
import confirm from "@/components/offline/newInvoice/pillConfirm.vue";
import nonRefund from "@/components/offline/newInvoice/nonRefund.vue";
import taxInnvoiceEn from "@/components/offline/newInvoice/taxInnvoiceEn.vue";
import taxInnvoiceAr from "@/components/offline/newInvoice/taxInnvoiceAr.vue";
import optionDate from "@/components/offline/newInvoice/optionDate.vue";
import priceOffer from "@/components/offline/newInvoice/priceOffer.vue";
import report from "@/components/offline/newInvoice/report.vue";
import roomingList from "@/components/offline/newInvoice/roomingList.vue";
import supplierVoucher from "@/components/offline/newInvoice/supplierVoucher.vue";
import voucherEn from "@/components/offline/newInvoice/voucherEn.vue";
import voucherAr from "@/components/offline/newInvoice/voucherAr.vue";
import Dialog from "primevue/dialog";

export default {
  name: "pill-hotel-cancel",
  components: {
    sidebarPill,
    priceOffer,
    optionDate,
    amendment,
    cancelation,
    taxInnvoiceEn,
    taxInnvoiceAr,
    confirm,
    nonRefund,
    report,
    roomingList,
    supplierVoucher,
    voucherEn,
    voucherAr,
    Dialog,
  },
  data() {
    return {
      pills: {},
      total_selling_price_with_out_tax: "",
      selling_tax: "",
      total_selling_price: "",
      bookId: null,
      book_status_name: "",
      type: this.$route.params.type,
      book_id: this.$route.params.id,
      optionDate: null,
      terms: "",
      visible: false,
      qr_code: ""
    };
  },

  methods: {
    fetchOfflineInvoice(formData) {
      axios
        .post("/fetch_offline_book_hotel_invoice", formData)
        .then(({ data }) => {
          this.pills = data.data;
          this.qr_code = data.data.qr_code;
          this.book_status_name = data.data.book_status_name;
          this.optionDate = data.data.client_option_date;
          this.total_selling_price_with_out_tax =
            data.data.total_selling_price_with_out_tax.toFixed(2);
          this.selling_tax = data.data.selling_tax.toFixed(2);
          this.total_selling_price = data.data.total_selling_price.toFixed(2);
          // console.log(this.invoiceData);
        });
    },
  },
  watch: {
    "$route.params.type": {
      immediate: true,
      handler(newType) {
        const formData = new FormData(); // Declare formData here
        formData.append("offline_booking_hotel_id", this.$route.params.id);
        // if (newType === 'tax-innvoice-ar' || newType === 'voucher-ar') {
        //   axios.defaults.headers.common["Accept-Language"] = "ar";
        // } else
        if (newType === "pill-confirm") {
          formData.append("invoice_type", 1);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else if (newType === "non-refundable") {
          formData.append("invoice_type", 2);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else if (newType === "tax-innvoice-en") {
          formData.append("invoice_type", 3);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else if (newType === "tax-innvoice-ar") {
          formData.append("invoice_type", 4);
          axios.defaults.headers.common["Accept-Language"] = "ar";
        } else if (newType === "option-date") {
          formData.append("invoice_type", 5);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else if (newType === "price-offer") {
          formData.append("invoice_type", 6);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else if (newType === "report") {
          formData.append("invoice_type", 7);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else if (newType === "rooming-list") {
          formData.append("invoice_type", 8);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else if (newType === "supplier-voucher") {
          formData.append("invoice_type", 9);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else if (newType === "voucher-ar") {
          formData.append("invoice_type", 10);
          axios.defaults.headers.common["Accept-Language"] = "ar";
        } else if (newType === "voucher-en") {
          formData.append("invoice_type", 11);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else if (newType === "cancelation") {
          formData.append("invoice_type", 12);
          axios.defaults.headers.common["Accept-Language"] = "en";
        } else {
          axios.defaults.headers.common["Accept-Language"] = "en";
        }
        this.type = newType; // Update the type in the component
        this.fetchOfflineInvoice(formData); // Call fetchOfflineInvoice after setting the language
        // this.fetchTermsInvoice(newType)
      },
    },
  },
  created() {
    document.body.className = "portrait";
    const style = document.createElement("style");
    style.innerHTML = `@page { size: A4 portrait; }`;
    document.head.appendChild(style);
  },
  beforeUnmount() {
    // Clean up the class when the component is destroyed
    document.body.className = "landscape";
    const style = document.createElement("style");
    style.innerHTML = `@page { size: A4 landscape; }`;
    document.head.appendChild(style);
  },
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
.printButton {
  border-radius: 8px;
}
@page {
  size: A4 portrait;
  margin: 0;
}
</style>
