<template>
    <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div class="offcanvas-header">
            <div class="offcanvas-title" id="offcanvasBottomLabel">
                <h5 class="mb-4">{{$t("Add New Bed")}}</h5>
                <p>{{$t("enter this data to continue")}}</p>
            </div>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body small">
            
            <form @submit.prevent="addBed()">
                <div class="row mb-5 mt-3">
                    <div class="col-lg-6">
                        <label>{{$t("Bed Name in arabic")}}</label>
                        <input type="text" :placeholder="$t('enter bed name')" v-model="bedName_ar" />
                    </div>
                    <div class="col-lg-6">
                        <label>{{$t("Bed Name in english")}}</label>
                        <input type="text" :placeholder="$t('enter bed name')" v-model="bedName_en" />
                    </div>
                </div>

                <button type="submit" class="add save btn">{{$t("add")}}</button>
            </form>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2';

export default {
    emits: ["fetch-data"],
    data(){
        return{
            bedName_ar:"",
            bedName_en:""
        }
    },
    methods:{
        addBed(){
            axios.post("/bed_types" , {
                title_en: this.bedName_en,
                title_ar: this.bedName_ar
            }).then(({data})=>{
                Swal.fire({
                    title: "",
                    text: data.message,
                    icon: "success",
                });
                this.$emit("fetch-data");
                this.$router.push("/bed");
            })
            .catch((error) => {
                Swal.fire({
                    title: "",
                    text: error.response.data.message,
                    icon: "error",
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../view/dashboard/wallet/_wallet.scss";
h5{
    color: #0B1427;
    font-weight: 700;
    font-size: 32px;
}
p{
    font-size: 24px;
    color: #656B78;
}
form{
  label{
    display: inline-block;
    margin-bottom: 10px;
    font-family: 'bold' !important;
  }
  input , select{
    display: block;
    width: 100% !important;
    // height: 68px;
    border-radius: 4px;
    border: 1px solid #9CA2AC !important;
    color: #9CA2AC;
    padding: 10px 15px;
  }
  i{
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-color: #0A909B;
    color: #fff;
    text-align: center;
    line-height: 58px;
    font-size: 24px;
    margin-inline-start: 15px;
    cursor: pointer;
  }
}
</style>