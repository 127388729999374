<template>
  <section class="dashboard">
    <div class="container-fluid">
      <div class="row flex-nowrap">
        <div class="p-0">
          <navbar />
          <div class="px-2">
            <Fav />
            <router-view></router-view>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import navbar from "@/components/layout/navbar.vue";
import Footer from "@/components/layout/footer.vue";
import Fav from "@/components/layout/fav.vue";
require("@/assets/css/main.css");

export default {
  name: "dashboard-page",
  components: {
    navbar,
    Footer,
    Fav,
  },
  // created() {
  //   localStorage.removeItem("flightData");
  //   localStorage.removeItem("flightTrips");
  //   localStorage.removeItem("bookDataFlight");
  //   localStorage.removeItem("bookDetails");
  //   localStorage.removeItem("hotelData");
  //   localStorage.removeItem("bookData");
  // }
};
</script>
