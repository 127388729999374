<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Add New Hotel") }}</h4>
    </div>

    <form @submit.prevent="addHotel()">
      <div class="row">
      
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The Country") }}</label>
            <Multiselect
              v-model="countryValue"
              :options="countries.map((country) => country.id)"
              :custom-label="(opt) => countries.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('choose_country')"
              select-label=""
              :hide-selected="true"
              @update:model-value="FetchCityFromCountry"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label>{{ $t("The City") }}</label>
            <Multiselect
              v-model="cityValues"
              :options="cities.map((city) => city.id)"
              :custom-label="(opt) => cities.find((x) => x.id == opt).title"
              :clear-on-select="true"
              :placeholder="$t('enter_city')"
              select-label=""
              :hide-selected="true"
            >
            </Multiselect>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="addHotel">{{ $t("Add Hotel") }}</label>
            <input
              type="text"
              class="form-control mt-2"
              id="addHotel"
              :placeholder="$t('Add Hotel')"
              v-model="title"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="starCount">{{ $t("Add starCount") }}</label>
            <input
              type="number"
              class="form-control mt-2"
              id="starCount"
              :placeholder="$t('Add starCount')"
              v-model="starCount"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="lat">lat</label>
            <input
              type="text"
              class="form-control mt-2"
              id="lat"
              placeholder="lat"
              v-model="lat"
            />
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="form-group">
            <label for="long">long</label>
            <input
              type="text"
              class="form-control mt-2"
              id="long"
              placeholder="long"
              v-model="long"
            />
          </div>
        </div>
        <div class="col-md-12 mb-4">
          <div class="form-group">
            <label for="adress">{{ $t("adress") }}</label>
            <input
              type="text"
              class="form-control mt-2"
              id="address"
              :placeholder="$t('adress')"
              v-model="address"
            />
          </div>
        </div>
        <div class="col-md-6 mb-6">
          <input type="file" id="file" @change="imageUpload" multiple hidden />

          <label for="file" class="add_file"
            ><i class="fa-solid fa-upload"></i> {{ $t("Add image") }}</label
          >
          <p>{{ this.imageHotelName }}</p>
        </div>
      </div>
      <button type="submit" class="add btn PrimaryButton">
        {{ $t("add") }}
      </button>
    </form>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "addHotel",
  components: { Multiselect },
  data() {
    return {
      title: "",
      countryValue: "",
      countries: [],
      cityValues: "",
      cities: [],
      starCount: 0,
      lat: 0,
      long: 0,
      imageHotel: "",
      imageHotelName: "",
      address: "",
    };
  },
  methods: {
    //fetch country
    fetch_country() {
      axios.post("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
      });
    },
    FetchCityFromCountry(value) {
      let basic_country_ids = {
        country_id: [value],
      };
      // console.log(value)
      axios
        .post("/fetch_cities_by_country_id", basic_country_ids)
        .then(({ data }) => {
          this.cities = data.data;
        });
    },
    // image
    imageUpload(event) {
      this.imageHotel = event.target.files[0];
      const name = this.imageHotel.name;

      const reader = new FileReader();
      reader.readAsDataURL(this.imageHotel);
      reader.onload = () => (this.imageHotelName = name);
      // reader.onload = () => (this.imageEntry = reader.result);
    },
    addHotel() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("country_id", this.countryValue);
      formData.append("city_id", this.cityValues);
      formData.append("starCount", this.starCount);
      formData.append("lat", this.lat);
      formData.append("long", this.long);
      formData.append("image", this.imageHotel);
      formData.append("address", this.address);

      axios
        .post("/hotels", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/allHotels");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetch_country();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style></style>
