<template>
  <section class="card_style">
    <div class="main_header_style">
      <h4>{{ $t("Edit City") }}</h4>
    </div>

    <div class="row">
      <form @submit.prevent="editCity()">
        <div class="row">
          <div class="col-lg-4 col-md-12" v-for="(title, index) in titles" :key="index">
            <div class="form-group">
              <label :for="`name${index}`">{{ $t("Edit City") }} ({{title.lang}})</label>
              <input
                type="text"
                class="form-control mt-2"
                :id="`name${index}`"
                :placeholder="$t('Edit City')"
                v-model="title.title"
              />
            </div>
          </div>
        </div>
        <button type="submit" class="add btn PrimaryButton">
          {{ $t("edit") }}
        </button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "editCity",
  data() {
    return {
      cityId: this.$route.params.id,
      title: "",
      title_ar: "",
      title_en: "",
      title_id: "",
      titles: [],
      languages: ["ar", "en", "id"],
    };
  },
  methods: {
    fetchCityData() {
      axios.get(`/cities/${this.cityId}`).then(({ data }) => {
        this.titles = data.data.titles.map((ele)=>{
          return{
            lang: ele.locale,
            title: ele.title
          }
        })
      });
    },
    setTitlesLang(){
      this.languages.forEach((ele)=>{
        this.titles.push({
          lang: ele,
          title: ""
        })
      })
    },
    editCity() {
      const params = new URLSearchParams();
      this.titles.forEach((ele) => {
        params.append(`title_${ele.lang}`, ele.title);
      });
      axios
        .put(`/cities/${this.cityId}`, params)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
          this.$router.push("/cities");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
  },
  mounted() {
    this.fetchCityData();
    this.setTitlesLang();
  },
  created() {
    localStorage.removeItem("flightData");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("bookData");
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
