<template>
  <section class="web-themes">
    <div class="d-flex align-items-center gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>{{ $t("web_themes") }}</h4>
    </div>
    <div class="themes d-flex align-items-center gap-3 mt-3">
      <div class="flex align-items-center">
        <RadioButton v-model="theme" inputId="theme1" name="temp1" value="1" />
        <label for="theme1" class="mb-0 fs-6 fw-bold">{{ $t("theme1") }}</label>
        <div class="card flex justify-content-center mt-2">
          <img
            src="@/assets/media/image/tem1.png"
            alt="Image"
            class="theme"
            @click="visible1 = true"
          />
          <Dialog v-model:visible="visible1" modal header="Theme 1" :dismissableMask="true">
            <img src="@/assets/media/image/tem1.png" alt="Image" />
          </Dialog>
        </div>
      </div>
      <div class="flex align-items-center">
        <RadioButton v-model="theme" inputId="theme2" name="temp2" value="2" />
        <label for="theme2" class="mb-0 fs-6 fw-bold">{{ $t("theme2") }}</label>
        <div class="card flex justify-content-center mt-2">
          <!-- Corrected the component name to Image -->
          <img
            src="@/assets/media/image/temp2.png"
            alt="Image"
            class="theme"
            @click="visible2 = true"
          />
          <Dialog v-model:visible="visible2" modal header="Theme 2" :dismissableMask="true">
            <img src="@/assets/media/image/tem1.png" alt="Image" />
          </Dialog>
        </div>
      </div>
    </div>
    <button class="PrimaryButton btn" @click="submit">{{ $t("save") }}</button>
  </section>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import RadioButton from "primevue/radiobutton";
import axios from "axios";
import swal from "sweetalert2";
import Dialog from "primevue/dialog";

export default {
  name: "web-themes",
  components: {
    AddFavDailog,
    RadioButton,
    Dialog,
  },
  data() {
    return {
      theme: "",
      ogranization_id: this.$route.params.id,
      visible1: false,
      visible2: false,
    };
  },
  methods: {
    submit() {
      const formData = new FormData();
      formData.append("organization_id", this.ogranization_id);
      formData.append("theme_number", this.theme);
      axios.post("/store_organization_theme", formData).then((res) => {
        swal.fire({
          title: this.$t("success"),
          text: res.data.message,
          icon: "success",
          confirmButtonText: this.$t("ok"),
        });
        this.$router.push("/organization-tree");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
img.theme {
  width: 250px;
  height: 250px;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    box-shadow: 0 0 0 2px #fff, 0 0 0 3px var(--primary-color);
  }
}
img {
  max-width: 100%;
}
</style>
