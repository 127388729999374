<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script setup>
import { ref, onMounted  } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Swal from "sweetalert2";
import { useStore } from "vuex";
const store = useStore();
const token = ref("");
//fireBase

const firebaseConfig = {
  apiKey: "AIzaSyCHMj23-1Knt8_0eZZyRn5sUmdf19rB5vs",
  authDomain: "travel-soft-tech-119ae.firebaseapp.com",
  projectId: "travel-soft-tech-119ae",
  storageBucket: "travel-soft-tech-119ae.appspot.com",
  messagingSenderId: "124395774810",
  appId: "1:124395774810:web:cd452b3ef66f2256130963",
  measurementId: "G-8DSHPQGQJP",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  Swal.fire({
    type: "success",
    position: "top-end",
    icon: "success",
    title: payload.data.title,
    text: payload.data.body,
    showConfirmButton: true,
  });
  if (payload.data) {
    store.dispatch("fetchNotifications");
  }
});
getToken(messaging, {
  vapidKey:
    "BAdGzvWp_YM96Jtq9hjB5NBhCl3jODofWkDcqbKU_SvM3y4uPY1ePsExafV25_1quka5vvl5vi_vePBKRN2Acj4",
})
  .then((currentToken) => {
    if (currentToken) {
      token.value = currentToken;
      // Only call notifications if user is present
      if (user) {
        noteifcations();
        store.dispatch("fetchNotifications");
        // console.log("token is: ", token.value);
        // console.log("current token is: ", currentToken);
      }
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
  });

const noteifcations = () => {
  const data = {
    device_name: "test",
    device_token: token.value,
    device_id: "1",
    device_type: "webSite",
  };
  axios.post("/store_employee_device", data).then(() => {
    // console.log(data, "datea");
  });
};
//fireBase

const user = JSON.parse(localStorage.getItem("user")) || null;
const primaryColor = ref(user?.PrimaryColor || "#0f3450");
const secondColor = ref(user?.SecondColor || "#17a2b8");

const router = useRouter();
const updatePrimaryColor = () => {
  const style = document.createElement("style");
  style.innerHTML = `:root { --primary-color: ${primaryColor.value}; --second-color: ${secondColor.value} }`;
  document.head.appendChild(style);

  // Website name
  const titleEl = document.querySelector("head title");
  if (titleEl) titleEl.textContent = `${user?.organization_name} Dashboard`;

  // favIcon
  const favIcon = document.querySelector("link[rel~='icon']");
  if (favIcon) favIcon.href = user?.logo;
};

onMounted(() => {
  updatePrimaryColor();

  const token = localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;
  }

  axios.interceptors.response.use(null, (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_type");
      router.push("/login"); // Assuming router is accessible here
    }
    return Promise.reject(error);
  });

  document.body.className = "landscape";
  const style = document.createElement('style');
  style.innerHTML = `@page { size: A4 landscape; }`;
  document.head.appendChild(style);
});

</script>

<style lang="scss">
:root {
  --primary-color: var(--primary-color);
  --second-color: var(--second-color);
}

@import "../src/assets/scss/variables.scss";
@import "../src/assets/scss/style.min.css";

.points_current {
  position: absolute;
  inset-inline: 77% !important;
  width: 8%;
}
div#offcanvasBottomDetails {
  height: 80vh;
  overflow-y: auto;
}
// .p-accordion-header {
//   display: flex !important;
//   align-items: center !important;
//   justify-content: flex-end !important;
// }
</style>