<template>
    <div v-if="loading" class="loading">
      <Loading />
    </div>
    <div class="hotel_offers py-2">
        <div class="offer_card mb-0">
            <div class="show_rooms mt-0">
                <div class="room_card" v-for="(roomPrice, index) in room.room_rate_basis" :key="index">
                    <div class="head_room">
                      <p class="title_room"> <span>{{ room.name }}</span> <span class="meal">({{roomPrice.description}})</span></p>
                      <a :href="`#popup${room.id}`" @click="checkRoomCancellationPolicies(index, 'cancel')" class="cancel_policy">{{ $t("Cancellation Policies") }}</a>
                      <div :id="`popup${room.id}`" class="popup">
                        <div class="d-flex align-items-center justify-content-between mb-3">
                          <h4 class="title">{{ $t("Cancellation Policies") }}</h4>
                          <a href="#" class="close">&times;</a>
                        </div>
                        <h5 class="title">{{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.description }}</h5>
                        <!-- <ul> -->
                          <!-- v-for="(rule, item) in cancellation_rate_basis.cancellation_rules" :key="item" -->
                          <!-- <li> -->
                            <div v-if="cancellation_rate_basis == null || cancellation_rate_basis.cancellation_rules">
                              <span>{{$t("cancel_free_from")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[0].from_date }} {{$t("to")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[0].to_date }}</span>
                              <span>{{$t("cancel_from")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].from_date }} {{$t("to")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].to_date }} {{ $t("by_cost") }} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].cancel_charge }}</span>
                              <span>{{$t("amend_from")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].from_date }} {{$t("to")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].to_date }} {{ $t("by_cost") }} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].amend_charge }}</span>
                            </div>
                            <!-- <span>{{$t("cancel_free_from")}} {{ cancellation_rate_basis.cancellation_rules[0].from_date }} {{$t("to")غغ}} {{ cancellation_rate_basis.cancellation_rules[0].to_date }}</span> -->
                          <!-- </li> -->
                        <!-- </ul> -->
                      </div>
                      <a href="#" class="close-popup"></a>
                       <!--  -->
                    </div>
                    <div class="actions_room">
                      <p class="price">{{ roomPrice.price }} / {{room.meta_data.room_rate == null ? "USD" : room.meta_data.room_rate.currency == null ? "USD" : room.meta_data.room_rate.currency }}</p>
                      <button class="btn PrimaryButton" type="button" @click="checkRoomCancellationPolicies(index, 'book')">{{ $t("Add") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from '@/components/LottieFiles/HotelSearchLoading.vue';

// import { elements } from 'chart.js';
export default {
  props: {
    room: Object,
    rooms: Array,
    index: Number,
    hotel_id: Number,
    session_id: String,
    hotel_code: Number,
    hotel_name: String,
    guest_nationality: String,
    resultIndex: Number,
    offer: Object,
    element_position: Number,
    credential_id: Number,
    provider_id: Number,
  },
  components: {
    Loading
  },
  data() {
    return {
      roomsSelected: {
        hotel_rooms: []
      },
      roomRateBasis: false,
      loading: false,
      cancellation_rate_basis: null
    };
  },
  methods: {
    showRoomRateBasis() {
      this.roomRateBasis = !this.roomRateBasis
    },

    checkRoomCancellationPolicies(index, type) {
      // let element_id = localStorage.getItem("element_id")
      // let element = document.getElementById(`book${element_id}`)
      // let y = element.offsetTop
      window.scrollTo({
        top: window.scrollY,
        behavior: 'smooth'
      });
      this.loading = true; //the loading begin
      
      let roomObject = {
          hotel_id: this.hotel_id,
          hotel_name: this.hotel_name,
          room_numbers: [this.room.id],
          nationality_code: this.guest_nationality,
          passenger_country_of_residence_code: this.guest_nationality,
          rooms_guests: [
              {
                  rate_basis: this.room.room_rate_basis[index].id,
                  adults_count: 1,
                  children_count: 0,
                  children_ages: []
              }
          ],
          check_in: JSON.parse(localStorage.getItem("bookDetails")).check_in,
          check_out: JSON.parse(localStorage.getItem("bookDetails")).check_out,
          meta_data: {
              credential_id: this.credential_id,
              provider_id: this.provider_id,
          }
      };

      if (this.resultIndex != null) {
        roomObject.meta_data.result_index = this.resultIndex;
        roomObject.meta_data.session_id = this.session_id;
      }
      if (this.resultIndex == null) {
        roomObject.converter_status = 1;
      }

  
      // Initialize hotel_rooms array if it doesn't exist
      if (!localStorage.getItem("bookData")) {
        localStorage.setItem("bookData", JSON.stringify([]));
      }
      // // Get the current bookData array from localStorage
      this.roomsSelected = roomObject;
      
      // Update the bookData array in localStorage
      localStorage.setItem("bookData", JSON.stringify(roomObject));

      let roomData = {
        room_type_name: this.room.meta_data.room_type_name,
        room_number: this.room.id,
        rate_basis: this.room.room_rate_basis[index].id,        
        allocation_details: this.room.room_rate_basis[index].allocationDetails,
        room_guests: {
          adults: [],
          children: [],
          extrabeds: [],
        },
        meta_data: {
          room_index: this.room.meta_data.room_index,
          room_type_code: this.room.meta_data.room_type_code,
          room_type_name: this.room.meta_data.room_type_name,
          rate_plan_code: this.room.meta_data.rate_plan_code,
          room_rate: {
            room_fare: this.room.meta_data.room_rate == null ? null : this.room.meta_data.room_rate.original_room_fare,
            total_fare: this.room.meta_data.room_rate == null ? null : this.room.meta_data.room_rate.original_total_fare,
            room_tax: this.room.meta_data.room_rate == null ? null : this.room.meta_data.room_rate.room_tax,
            currency: this.room.meta_data.room_rate == null ? null : this.room.meta_data.room_rate.currency,
            agent_mark_up: this.room.meta_data.room_rate == null ? null : 0
          },
          supplements: this.room.meta_data.supplements,
        }
      }
      
      // Iterate over the adults array
      for (let i = 0; i < JSON.parse(localStorage.getItem("bookDetails")).rooms_guests[0].adults_count; i++) {
        roomData.room_guests.adults.push({
          salutation: "",
          first_name: "",
          last_name: "",
          age: 30, // You can set the age to the desired value
        });
      }
      for (let i = 0; i < JSON.parse(localStorage.getItem("bookDetails")).rooms_guests[0].children_count; i++) {
        roomData.room_guests.children.push({
          salutation: "",
          first_name: "",
          last_name: "",
          age: 7, // You can set the age to the desired value
        });
      }

      localStorage.setItem("roomData", JSON.stringify(roomData));

      // // Assign the array of adult objects to roomData.room_guests.adults
      // roomData.room_guests.adults = adultObjects;

      this.$emit('rooms-selected', this.roomsSelected);
      this.$emit('hotel-selected', this.hotel_id);

      this.loading = true
      axios.post("/check_rooms_availability", roomObject).then(({ data }) => {
        this.available_for_booking = data.data.data[0].cancellation_rate_basis[index].available_for_booking;
        this.cancellation_rate_basis = data.data.data[0].cancellation_rate_basis[index]
        // console.log(this.cancellation_rate_basis)
        // console.log(data.data)
        if (this.available_for_booking == true) {
          this.loading = false; //the loading ended
        }
        else {
          this.loading = false; //the loading ended
          Swal.fire({
            title: "",
            text: this.$t("room is not available"),
            icon: "error",
          });
        }
        if(type == "book") {
          this.$router.push("/new_collection_book")
        }

      }).catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data.message,
            timer: 3000,
          });
          this.loading = false; //the loading Eneded
          return error;
        })
        .finally(() => {
          //Perform action in always
          this.loading = false; //the loading Eneded
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../view/dashboard/hotels/_hotelsNew.scss";
  .popup {
  position: fixed;
  padding: 10px;
  width: 60%;
  border-radius: 10px;
  top: 50%;
  left: 45%;
  transform: translate(-50%,-50%);
  background: rgba(255,255,255,.9);
  visibility: hidden;
  opacity: 0;
  /* "delay" the visibility transition */
  -webkit-transition: opacity .5s, visibility 0s linear .5s;
  transition: opacity .5s, visibility 0s linear .5s;
  z-index: 1;
}
.popup:target {
  visibility: visible;
  opacity: 1;
  /* cancel visibility transition delay */
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.popup-close {
  position: absolute;
  padding: 10px;
  max-width: 500px;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgba(255,255,255,.9);
}
.popup .close {
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 5px;
  color: #000;
  transition: color .3s;
  font-size: 2em;
  line-height: .6em;
  font-weight: bold;
}
.popup .close:hover {
  color: #00E5EE;
}
// .popup ul li {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

.popup span {
  width: 100%;
  display: block;
  font-family: "regular";
}

.close-popup {
  background: rgba(0,0,0,.7);
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  /* "delay" the visibility transition */
  -webkit-transition: opacity .5s, visibility 0s linear .5s;
  transition: opacity .5s, visibility 0s linear .5s;
}
.popup:target + .close-popup{  
  opacity: 1;
  visibility: visible;
  /* cancel visibility transition delay */
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
</style>
