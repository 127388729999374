<template>
  <section class="all_emp card_style index_offline">
    <div class="row mb-3">
      <div class="col-lg-2 col-md-12 col-12 gap-3 d-flex align-items-center">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4>{{ $t("reservation_offline") }}</h4>
      </div>
      <div class="col-lg-5 col-md-12 col-12">
        <form>
          <div class="form-group book_search">
            <i class="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              class="form-control"
              v-model="bookSearch"
              :placeholder="$t('search by name')"
              @keyup="FetchHotels((page = 1))"
            />
          </div>
        </form>
      </div>
      <div
        class="col-lg-5 col-md-12 col-12 d-flex justify-content-end align-items-center"
      >
        <FilterOfflineBooking :bookSearch="bookSearch" @getData="getData" />

        <router-link
          v-if="is_portal_contract === 0"
          :to="`/hotels/book_offline`"
          class="btn SecondButton offline w-25"
          ><i class="fa-solid fa-plus"></i> {{ $t("add_book") }}</router-link
        >
      </div>
    </div>
    <Transition name="fade">
      <div class="books-wrapper" v-if="loading">
        <div
          class="books mt-1 row py-1 shadow px-3 align-items-center"
          v-for="book in 10"
          :key="book"
        >
          <div class="col-1">
            <Skeleton height="2rem"></Skeleton>
          </div>
          <div class="col-1 mt-2 mb-2">
            <Skeleton height="1rem" class="mb-2"></Skeleton>
            <Skeleton height="1rem"></Skeleton>
          </div>
          <div v-for="index in 5" :key="index" class="col-2 mt-2 mb-2">
            <Skeleton height="1rem" class="mb-2"></Skeleton>
            <Skeleton height="1rem"></Skeleton>
          </div>
        </div>
      </div>
      <div class="books-wrapper" v-else>
        <div
          v-for="(book, index) in hotels.data"
          :class="[
            'books',
            'mt-1',
            'd-flex',
            'align-items-center',
            'py-1',
            'px-3',
            'flex-lg-nowrap',
            book?.book_offline_status == 0
              ? 'bg-warring'
              : book?.book_offline_status == 1
                ? 'bg-default'
                : book?.book_offline_status == 2
                  ? 'bg-confirmed'
                  : book?.book_offline_status == 3
                    ? 'bg-canceled'
                    : book?.book_offline_status == 4
                      ? 'bg-canceled'
                      : '',
          ]"
          :key="index"
        >
          <div class="id">
            <router-link
              class="details_id"
              :to="`/hotels/book_offline/${book.id}`"
              target="_blank"
              :title="$t('edit')"
            >
              <h4 class="text-center" v-tooltip.left="$t('show_details')">
                {{ total - ((indexPage - 1) * per_page + index) }}
                <!-- {{ (indexPage - 1) * 5 + index + 1 }} -->
              </h4>
            </router-link>
          </div>
          <div
            class="d-flex serial align-items-center justify-content-center flex-column"
          >
            <p class="text-center text-temperature">{{ book.book_number }}</p>
            <span
              v-tooltip.bottom="$t('book_status')"
              :class="[
                'badge mx-1',
                book?.book_offline_status == 0
                  ? 'text-warning text-capitalize'
                  : book?.book_offline_status == 1
                    ? 'text-primary text-capitalize'
                    : book?.book_offline_status == 2
                      ? 'text-success text-capitalize'
                      : book?.book_offline_status == 3
                        ? 'text-danger text-capitalize'
                        : book?.book_offline_status == 4
                          ? 'text-danger text-capitalize'
                          : '',
              ]"
            >
              {{
                book?.book_offline_status == 0
                  ? $t("pending")
                  : book?.book_offline_status == 1
                    ? $t("tentative")
                    : book?.book_offline_status == 2
                      ? $t("confirmed")
                      : book?.book_offline_status == 3
                        ? $t("cancelled")
                        : book?.book_offline_status == 4
                          ? $t("canceled")
                          : ""
              }}
            </span>
          </div>
          <div class="option_date">
            <p>{{ $t("option_date") }}</p>
            <p>
              {{
                book?.client_option_date == "" ? "--" : book?.client_option_date
              }}
            </p>
          </div>
          <div
            class="info d-flex align-items-center justify-content-end flex-column"
          >
            <span class="info" v-if="book.rooms.length">
              <span
                class="nights py-1 px-2 d-flex align-items-center justify-content-center mx-1"
                style="width: fit-content"
                v-tooltip.left="$t('room_count')"
                ><span>{{ book.rooms.length }}</span> <span> R</span>
              </span>
            </span>
            <div class="d-flex" v-if="book.hotel">
              <p
                class="fw-bold pe-pointer"
                v-tooltip.left="$t('hotel_name')"
                @click="$router.push(`/hotel/details/${book.hotel.hotel_id}`)"
              >
                {{ book.hotel?.hotel_name }}
              </p>
              <span
                class="nights p-1 d-flex align-items-center justify-content-center mx-1"
                v-tooltip.left="$t('number_of_nights')"
                ><span>{{ book.number_of_nights }} </span><span> N</span>
              </span>
            </div>
            <div
              v-else
              class="d-flex"
              v-for="hotel in book.hotels"
              :key="hotel"
            >
              <p
                class="fw-bold pe-pointer"
                v-tooltip.left="$t('hotel_name')"
                @click="$router.push(`/hotel/details/${hotel.hotel_id}`)"
              >
                {{ hotel.hotel_name }}
              </p>
            </div>
            <p v-tooltip.left="$t('check-in:check-out')">
              {{ book.check_in_date }} -- {{ book.check_out_date }}
            </p>
          </div>
          <div class="rooms mt-0 mb-0">
            <div
              v-for="(room, index) in book.rooms"
              :key="index"
              class="room d-block flex-row-reverse"
            >
              <!-- <span>{{ room.count }}</span> -->
              <div
                class="d-flex align-items-center text-center flex-wrap flex-column"
              >
                <!-- <i class="fa-solid fa-bed mx-1"></i> -->
                <div class="d-flex number_nights">
                  <span class="info px-0" style="width: fit-content">
                    <span
                      class="nights py-1 px-2 d-flex align-items-center justify-content-center mx-1"
                      style="width: fit-content"
                      v-tooltip.left="$t('number_of_nights')"
                      ><span>{{ room.number_of_nights }}</span> <span> N</span>
                    </span>
                  </span>
                  <p
                    class="text-center"
                    v-tooltip.left="
                      room?.room_name +
                      '\n ' +
                      room?.check_in_date +
                      ' / ' +
                      room?.check_out_date
                    "
                    v-html="room?.room_name.substring(0, 25)"
                  ></p>
                </div>

                <div class="d-flex align-items-center gap-1 mx-1">
                  <p
                    class="text-center text-primary"
                    v-tooltip.left="$t('supplier')"
                  >
                    {{ room?.supplier?.supplier_name }}
                  </p>
                  <div class="d-flex align-items-center supplier_type">
                    <span
                      v-if="room?.supplier_type == 'CONTRACT'"
                      class="text-center text-capitalize text-info"
                      v-tooltip.left="$t('rate_code')"
                      >,{{ room?.rate_code?.name }}</span
                    >
                    <span
                      v-if="room?.supplier_type == 'HOTEL'"
                      class="text-center text-success"
                      >{{ $tc("Hotel Direct") }}</span
                    >
                    <span
                      :class="`nights p-1 d-flex align-items-center justify-content-center mx-1 ${
                        room?.book_status == 'CANCELLED'
                          ? 'text-danger'
                          : room?.book_status == 'TENTATIVE'
                            ? 'text-primary'
                            : room?.book_status == 'CONFIRMED'
                              ? 'text-success'
                              : 'text-warning'
                      }`"
                      v-tooltip.left="$t('status')"
                      >{{ $t(room.book_status) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex room_con flex-column">
            <div
              class="d-flex align-items-center justify-content-center"
              style="line-height: 4"
              v-for="(room, index) in book.rooms"
              :key="index"
            >
              <span v-tooltip.left="$t('hotel_confirmation')">h.con:</span>
              <p class="hot_con text-center" v-tooltip.left="$t('code')">
                {{
                  room?.hotel_reference == "null"
                    ? ""
                    : room?.hotel_reference?.substring(0, 10)
                }}
              </p>
            </div>
          </div>

          <div class="cost d-flex justify-content-end gap-3">
            <div class="d-flex flex-column w-50 mx-2 align-items-center">
              <p style="width: max-content" v-tooltip.left="$t('Date_created')">
                {{ book?.created_at }}
              </p>
              <p style="max-width: 105px" v-tooltip.left="$t('client_name')">
                {{ book?.client?.name }}
              </p>
            </div>
            <div class="d-flex flex-column" style="width: 50px">
              <div
                :class="`icons w-100 ${
                  book.lock_status == 'posted' ? ' text-white p-1 rounded ' : ''
                }`"
              >
                <p
                  class="paid d-flex text-primary align-items-center justify-content-center px-2"
                  style="font-size: 0.7rem !important"
                >
                  {{ book.lock_status == "posted" ? $t("POST") : "" }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center total_price w-50">
              <p class="total mb-0" v-tooltip.left="$t('total')">
                {{ book.total_selling_price }}
              </p>

              <!-- <p>{{ book.client_currency_code }}</p> -->
              <p class="total_company" v-tooltip.left="$t('total_without_tax')">
                <!-- {{ book.organization_currency_code }} -->
                {{ Math.round(book?.selling?.total) }}
              </p>
              <div
                :class="` ${
                  book.client_payment_status != 'PAID'
                    ? ' text-danger p-1 rounded mb-1'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                  :class="
                    book.client_payment_status == 'PAID'
                      ? 'text-success'
                      : book.client_payment_status == 'PARTIALLY_PAID'
                        ? 'text-primary'
                        : 'text-danger'
                  "
                >
                  {{ $t(book.client_payment_status) }}
                  <span>{{
                    book.payment_method == "Cash_on_delivery"
                      ? $t("Payment upon arrival")
                      : ""
                  }}</span>
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <span class="hint_element bank_hint">{{
                $t("total_company")
              }}</span>
              <span class="hint_element bank_hint">{{
                $t("total_client")
              }}</span>

              <div
                v-if="book.book_status == '1'"
                :class="`icons w-100 ${
                  book.book_status == '1'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("paid_ok") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '2'"
                :class="`icons w-100 ${
                  book.book_status == '2'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("Decline_payment") }}
                  <!-- <img src="@/assets/media/image/Vector.sfvg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '3'"
                :class="`icons  w-100 ${
                  book.book_status == '3' ? ' text-white p-1 rounded' : ''
                }`"
              >
                <p
                  class="paid text-warning d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND PENDING") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '4'"
                :class="`icons w-100 ${
                  book.book_status == '4'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Accept") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '5'"
                :class="`icons w-100 ${
                  book.book_status == '5'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Decline") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '6'"
                :class="`icons w-100 ${
                  book.book_status == '6'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("payment sent") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
            </div>
          </div>
          <div class="actions">
            <router-link
              class="btn btn-sm btn-success"
              :to="`/hotels/book_offline/${book.id}/history`"
              v-tooltip="$t('History')"
            >
              <i class="fa-solid fa-clock-rotate-left"></i>
            </router-link>
            <!-- <router-link
              class="details_id btn btn-sm btn-primary"
              :to="`/hotels/book_offline/${book.id}`"
              v-tooltip="$t('show_details')"
              style="width: max-content !important"
            >
              <i class="fa fa-eye"></i>
            </router-link> -->
            <router-link
              class="details_id btn btn-sm btn-secondary"
              :to="`/${
                invoice_theme == 'IN01'
                  ? 'book_table'
                  : invoice_theme == 'IN02'
                    ? 'invoice'
                    : 'book_table'
              }/${book.id}/${
                book?.book_offline_status == 0
                  ? 'option-date'
                  : book?.book_offline_status == 1
                    ? 'price-offer'
                    : book?.book_offline_status == 2
                      ? 'pill-confirm'
                      : book?.book_offline_status == 3
                        ? 'cancelation'
                        : 'price-offer'
              }`"
              v-tooltip="$t('invoices')"
              style="width: max-content !important"
            >
              <i class="fa-solid fa-file-invoice"></i>
            </router-link>

            <!-- <router-link
              class="details_id btn btn-sm btn-primary"
              :to="`/tax-invoice-ar/${book.base_booking_id}`"
              v-tooltip="$t('TAX INVOICE') + ' EN'"
              style="width: max-content !important"
              v-if="book.lock_status == 'posted'"
            >
              <i class="fa-solid fa-file-invoice"></i>
            </router-link>


            <router-link
              class="details_id btn btn-sm btn-primary"
              :to="`/tax-invoice-ar/${book.base_booking_id}`"
              v-tooltip="$t('TAX INVOICE') + ' AR'"
              style="width: max-content !important"
              v-if="book.lock_status == 'posted'"
            >
              <i class="fa-solid fa-file-invoice"></i>
            </router-link> -->

            <router-link
              class="details_id btn btn-sm btn-info"
              :to="`/hotels/book_offline/${book.base_booking_id}/payment/${book.id}/${book?.book_offline_status}`"
              v-tooltip="$t('paid')"
              style="width: max-content !important"
            >
              <i class="fa-solid fa-money-bill-1-wave"></i>
            </router-link>
          </div>
        </div>
      </div>
    </Transition>

    <div class="row mt-3">
      <pagination
        :data="hotels"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchHotels"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";
// import PostToAccount from "@/components/offline/PostToAccount.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import FilterOfflineBooking from "@/components/offline_booking/FilterOfflineBooking.vue";

import Skeleton from "primevue/skeleton";

export default {
  name: "allHotels",
  data() {
    return {
      is_portal_contract: JSON.parse(localStorage.getItem("user"))
        ?.is_portal_contract,
      bookSearch: "",
      hotels: [],
      organizationAccounts: [],
      organizationSafe: [],
      filterParams: [],
      indexPage: 1,
      per_page: 1,
      total: 1,
      loading: true,
      status: "",
      invoice_theme: JSON.parse(localStorage.getItem("user"))?.invoice_theme,
    };
  },
  methods: {
    FetchHotels(page = 1) {
      let params = {
        ...this.filterParams,
      };
      if (this.bookSearch != "") {
        params.word = this.bookSearch;
      }
      axios
        .post(`/index_offline_booking_hotel?page=${page}`, this.filterParams)
        .then(({ data }) => {
          this.hotels = data.data;
          this.status = data.data.data;
          this.indexPage = page;
          this.per_page = data.data.meta.per_page;
          this.total = data.data.meta.total;
          this.loading = false;
          // console.log(this.hotels.data);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.hotels = [];
        });
    },
    getData(data) {
      this.filterParams = data;
      this.FetchHotels();
    },

    history() {
      this.$router.push({
        path: `/hotels/book_offline/${this.$route.params.id}/history`,
      });
    },

    //delete hotel
    deleteHotel(index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          let delete_id = this.hotels[index].id;
          const fromClick = true;
          axios
            .delete(`/offline_booking_hotels/${delete_id}`, { fromClick })
            .then(({ data }) => {
              //   console.log(data)
              if (data.status == true) {
                Swal.fire("", data.message, "success");
                this.hotels.splice(index, 1); // Remove the deleted item from the array
              } else {
                Swal.fire("", data.message, "error");
              }
              return data;
            })
            .catch((error) => {
              if (error.response.status != 403) {
                Swal.fire("", error.response.data.message, "error");
              }
            });
        }
      });
    },
  },
  mounted() {
    this.FetchHotels();
  },
  components: {
    pagination,
    // PostToAccount,
    AddFavDailog,
    FilterOfflineBooking,
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
//   @import "@/view/dashboard/offline/_offline.scss";
@import "./hotelsBookOffline.scss";
@import "@/assets/scss/_variables.scss";

.text-primary {
  font-size: 0.8rem !important;
}
.text-temperature {
  text-align: center !important;
}
.postToAccount {
  width: 10%;
}
.SecondButton {
  height: unset !important;
}
.total_price {
  width: 10%;
}
.book_search {
  position: relative;
  margin-bottom: unset !important;
  i {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  input {
    width: 100%;
    padding-right: unset !important;
    padding-right: 40px !important;
  }
}
.add_book.PrimaryButton {
  margin: unset !important;
}
</style>
