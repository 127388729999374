<template>
  <section class="invoice">
    <!-- Head invoice -->
    <div class="head_invoice">
      <div class="book_status">
        <h6>Rooming List</h6>
        <div class="chart"></div>
        <div class="chart"></div>
      </div>
      <div class="logo">
        <img :src="pills.organization?.default_image" />
      </div>
      <div class="book_number">
        <div class="chart"></div>
        <div class="chart"></div>
        <h6>
          booking number :
          <span>#{{ pills.book_number }}</span>
        </h6>
      </div>
    </div>
    <!-- Head invoice -->

    <!-- client reservation data -->
    <div class="data_invoice_person">
      <div
        class="list_account"
        v-for="(hotel, index) in roomigList"
        :key="index"
      >
        <div class="account_data">
          <p class="title_account">Hotel Name {{ index + 1 }}</p>
          <p class="text_account hotel_name">: {{ hotel.hotel_name }}</p>
        </div>
      </div>
    </div>
    <!-- client reservation data -->

    <!-- welcome invoice data -->
    <!-- <div class="welcome_text">
      <h5>
        Greeting From <span>{{ pills.organization?.name }}</span>
      </h5>
      <div class="pragra">
        <p>
          First of All, We would like to take this opportunity to welcome you
          at
          <strong> {{ pills.organization?.name }} </strong> and tourism We are
          pleased to confirm the following reservation on a
          <span>{{ book_status_name }}.</span>
        </p>
      </div>
    </div> -->
    <!-- welcome invoice data -->

    <!-- Table reservation -->
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="frist">ROOM TYPE</th>
            <th>GUEST NAME</th>
            <th>Book Number</th>
            <th>CHECK IN</th>
            <th>CHECK OUT</th>
            <th>ROOM NUMBER</th>
            <th>MEAL</th>
            <th>CONF NO</th>
            <th>NOTE</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(room, index) in roomigList" :key="index">
            <td class="frist">
              <p class="mb-0 fs-8">
                {{ room.room_name }}
              </p>
              <p>{{ room.hotel_name }}</p>
            </td>
            <td>
              <ol>
                <li
                  class="text-start"
                  v-for="(guest, index) in room.guests"
                  :key="index"
                >
                  {{ guest.guest_name }}
                </li>
              </ol>
            </td>
            <td>{{ pills.book_number }}</td>
            <td>
              <p class="mb-0">
                {{ room.checkin_date }}
              </p>
              <span class="hijri">{{
                updateHijriDate(new Date(room.checkin_date))
              }}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ room.checkout_date }}
              </P>
              <span class="hijri">{{
                updateHijriDate(new Date(room.checkout_date))
              }}</span>
            </td>
            <td>{{ room.room_number }}</td>
            <td>{{ room.meal_type_name }}</td>
            <td>{{ room.booking_number }}</td>
            <td>{{ room.notes }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Table reservation -->

    <div class="footer_invoice"></div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "pill-hotel-confirm",
  props: {
    pills: Object,
    terms: String,
    total_selling_price_with_out_tax: String,
    selling_tax: String,
    total_selling_price: String,
    book_status_name: String,
  },
  data() {
    return {
      roomigList: [],
    };
  },
  methods: {
    fetch_rooming_list_by_booking_id() {
      const formData = new FormData();
      if (this.$route.params.id) {
        formData.append("booking_hotel_id", this.$route.params.id);
      }
      if (this.$route.query.booking_room_id) {
        formData.append("booking_hotel_id", this.$route.query.booking_room_id);
      }
      // if(this.$route.query.booking_room_id) {
      //   formData.append("booking_room_id", this.$route.query.booking_room_id);
      // }
      if (this.$route.query.booking_room_id) {
        console.log(this.$route.query.booking_room_id);
        axios
          .get(
            `/offline_booking_room_lists/${this.$route.query.booking_room_id}`,
          )
          .then(({ data }) => {
            this.roomigList = data.data;
            // console.log(data.data);
          });
      } else {
        axios
          .post("/fetch_rooming_list_by_booking_id", formData)
          .then(({ data }) => {
            this.roomigList = data.data.data;
            // console.log(data.data);
          });
      }
    },
  },
  created() {
    this.fetch_rooming_list_by_booking_id();
  },
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
.invoice {
  .invoice_organiztion_data {
    .social_accounts, .logo {
      width: 50% !important;
    }
  }
}
</style>
