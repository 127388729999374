<template>
  <section class="flightPDF p-2 pt-2">
    <div class="container">
      <div class="info p-4">
        <div class="row">
          <div class="col-lg-11 col-11">
            <ul class="d-flex align-items-center gap-3">
              <li class="d-flex align-items-center">
                <div
                  class="image-container d-flex align-items-center justify-content-center rounded-circle"
                >
                  <img src="@/assets/media/image/passport.svg" alt="" />
                </div>
                <p>{{ $t("reserveno-number") }} :</p>
                <strong>{{ flight.reservation_num }}</strong>
              </li>
              <li class="d-flex align-items-center">
                <div
                  class="image-container d-flex align-items-center justify-content-center rounded-circle"
                >
                  <img src="@/assets/media/image/plane.svg" alt="" />
                </div>
                <p>{{ $t("reserveno-number") }} :</p>
                <strong>{{ flight.reservation_num }}</strong>
              </li>
            </ul>
          </div>
          <div class="col-lg-1 col-11">
            <span
              :class="[
                'badge',
                'p-2',
                flight.reservation_status == 'basically'
                  ? 'bg-primary'
                  : flight.reservation_status == 'confirmed'
                  ? 'bg-warning text-dark'
                  : flight.reservation_status == 'updated'
                  ? 'bg-success'
                  : flight.reservation_status == 'accepted'
                  ? 'bg-info text-dark'
                  : flight.reservation_status == 'canceled'
                  ? 'bg-danger'
                  : '',
              ]"
            >
              {{
                flight.reservation_status == "basically"
                  ? $t("basically")
                  : flight.reservation_status == "confirmed"
                  ? $t("confirmed")
                  : flight.reservation_status == "updated"
                  ? $t("updated")
                  : flight.reservation_status == "accepted"
                  ? $t("accepted")
                  : flight.reservation_status == "canceled"
                  ? $t("canceled")
                  : ""
              }}
            </span>
          </div>
          <div class="flight-sec col-lg-12 col-12">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-12">
                <div class="image">
                  <img
                    :src="flight?.flight_company?.image || this.defaultImage"
                    alt="company_image"
                    @error="handleImageError"
                    class="rounded-circle"
                  />
                </div>
              </div>
              <div class="col-lg-8 col-md-8 col-12">
                <div
                  class="trip_data"
                  v-if="flight && flight?.trips && flight?.trips?.length > 0"
                >
                  <div class="time_card">
                    <h5>{{ flight?.trips[0]?.launch_time }}</h5>
                    <h6>{{ flight?.trips[0]?.launch_date }}</h6>
                    <h6>
                      {{ flight?.trips[0]?.airport_departure_name?.title }} ({{
                        flight?.trips[0]?.departure
                      }})
                    </h6>
                  </div>
                  <div class="stop">
                    <h5>
                      {{ calculateTimeDifference(flight) }} {{ $t("hours") }}
                    </h5>

                    <div class="shape">
                      <i class="fas fa-plane"></i>
                      <span> </span>
                      <i class="fas fa-plane"></i>
                    </div>
                    <h4
                      :class="
                        flight?.trips?.length == 1
                          ? 'text-success'
                          : 'text-danger'
                      "
                    >
                      {{
                        flight?.trips.length == 1 ? $t("live") : $t("non_live")
                      }}
                    </h4>
                  </div>
                  <div class="time_card">
                    <h5>
                      {{ flight?.trips[flight?.trips.length - 1].arrive_time }}
                    </h5>
                    <h6>
                      {{ flight?.trips[flight?.trips.length - 1].arrive_date }}
                    </h6>
                    <h6>
                      {{
                        flight?.trips[flight?.trips.length - 1]
                          .airport_arrival_name?.title
                      }}
                      ({{ flight?.trips[flight?.trips.length - 1].arrival }})
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="passenger_detials p-4">
        <h4 class="mb-4">
          {{ $t("passenger_detials") }} ({{ $t("passengers") }}
          )
        </h4>
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t("traveler") }}</th>
              <th>{{ $t("trip_dirction") }}</th>
              <th>{{ $t("travel_laguage") }}</th>
              <th>{{ $t("hand_bag") }}</th>
              <th>{{ $t("addional_bag") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in flight.client" :key="index">
              <td>{{ item.first_Name }}</td>
              <td>
                <div
                  v-for="(item, index) in flight.trips"
                  :key="index"
                  class="d-flex align-items-center"
                >
                  <p>{{ item.departure }}</p>
                  <div class="img-container">
                    <img src="@/assets/media/image/fi_7118033.svg" alt="" />
                  </div>
                  <p>{{ item.arrival }}</p>
                </div>
              </td>
              <td>
                <p
                  v-for="(item, index) in flight.trips"
                  :key="index"
                  class="fw-bold text-black"
                >
                  {{ item.bags.length }} حقيبة , تسمح الخطوط الجوية عاد ًة بوزن
                  23 كجم للحقيبة الواحدة
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="important_detials">
        <h4 class="mb-4">{{ $t("important_details") }}</h4>
        <div class="content p-4">
          <div class="row">
            <div class="col-lg-6 col-12">
              <div class="head d-flex align-items-center">
                <img src="@/assets/media/image/pdf1.png" alt="" />
                <h6>{{ $t("travel_docmentury") }}</h6>
              </div>
              <ul>
                <li>
                  جواز سفر بصلاحية لا تتعدى 6 أشهر مع ضرورة وجود صفحات فارغة
                  وتكون كافية وخا ّصة من الخلف.
                </li>
                <li>
                  لطلبات الإلغاء أو التعديل، سيتم تطبيق رسوم من قِبل المسافر
                  التي تتراوح من SAR 35 أو أكثر لكل مسافر لما يصل إلى 4 ركاب
                  (باستثناء الأطفال الرضع) ، حسب نوع الرحلة ومصدر الطلب.
                </li>
                <li>
                  جواز سفر بصلاحية لا تتعدى 6 أشهر مع ضرورة وجود صفحات فارغة
                  وتكون كافية وخا ّصة من الخلف.
                </li>
                <li>
                  لطلبات الإلغاء أو التعديل، سيتم تطبيق رسوم من قِبل المسافر
                  التي تتراوح من SAR 35 أو أكثر لكل مسافر لما يصل إلى 4 ركاب
                  (باستثناء الأطفال الرضع) ، حسب نوع الرحلة ومصدر الطلب.
                </li>
              </ul>
            </div>
            <div class="col-lg-6 col-12">
              <div class="head d-flex align-items-center">
                <img src="@/assets/media/image/pdf2.png" alt="" />
                <h6>{{ $t("dont_miss_flight") }}</h6>
              </div>
              <ul>
                <li>تأكد من أن تكون في المطار قبل موعد مغادرة الرحلة.</li>
                <li>
                  بالنسبة للرحلات الدولية، ُيرجى الوصول إلى المطار قبل 4 ساعات
                  على الأقل من موعد المغادرة، ولكن قد تختلف القواعد
                </li>
              </ul>
              <div class="head d-flex align-items-center">
                <img src="@/assets/media/image/pdf3.svg" alt="" />
                <h6>{{ $t("cart_flught") }}</h6>
              </div>
              <ul>
                <li>
                  إن كنت ترغب في الحصول على بطاقة الصعود إلى الطائرة قبل الوصول
                  إلى المطار، يمكنك التواصل مع فريقنا المختص لمساعدتك.
                </li>
              </ul>
              <div class="head d-flex align-items-center">
                <img src="@/assets/media/image/pdf4.svg" alt="" />
                <h6>{{ $t("extra_laguage") }}</h6>
              </div>
              <ul>
                <li>
                  يمكنك الاتصال بمستشاري السفر لدينا لإضافة أمتعة إضافية ويعتمد
                  ذلك على موافقة شركة الطيران وسيكون بتكلفة إضافية.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "flight-pdf",
  data() {
    return {
      defaultImage: require("@/assets/media/image/img-flight.png"), // Replace with your default image URL,
      flight: {
        // trips: [],
        // clients: [],
      },
    };
  },
  methods: {
    // FetchPassenger() {
    //   let id = this.$route.params.id;
    //   axios.get("/show_flight_web/" + id).then(({ data }) => {
    //     this.flight = data;
    //     console.log(this.flight);
    //   });
    // },

    handleImageError(event) {
      // When the image fails to load, replace it with the default image
      event.target.src = this.defaultImage;
    },
    fetchflightDetails() {
      let id = this.$route.params.id;
      axios.get("/flight/" + id).then(({ data }) => {
        console.log(data);
        this.flight = data.data;
      });
    },
    calculateTimeDifference(flight) {
      const startTime = flight.trips[0].launch_time;
      const endTime = flight.trips[flight.trips.length - 1].arrive_time;

      // Parse the time strings to create Date objects
      const startDate = new Date(`2000-01-01 ${startTime}`);
      const endDate = new Date(`2000-01-01 ${endTime}`);

      // Calculate the time difference in milliseconds
      const timeDifferenceMs = endDate - startDate;

      // Convert the time difference to hours
      const timeDifferenceHours = Math.abs(
        Math.round(timeDifferenceMs / (1000 * 60 * 60))
      );

      return timeDifferenceHours;
    },
  },
  created() {
    this.fetchflightDetails();
  },
};
</script>

<!-- <style lang="scss" scoped>
// @import "@/assets/scss/_variables.scss";
// @import "./_flight.scss";

</style> -->

<style lang="scss" scoped>
@import "./_b2cUserFlightDetails.scss";
@import "./_flightpdf.scss";
</style>
