<template>
  <section class="show_receipt card_style">
    <Transition name="fade">
      <div class="books-wrapper">
        <div
          v-for="(book, index) in hotelReservation.data"
          :class="[
            'books',
            'mt-1',
            'd-flex',
            'align-items-center',
            'py-1',
            'px-3',
            'flex-lg-nowrap',
            book?.book_offline_status === 0
              ? 'bg-warring'
              : book?.book_offline_status === 1
                ? 'bg-default'
                : book?.book_offline_status === 2
                  ? 'bg-confirmed'
                  : book?.book_offline_status === 3
                    ? 'bg-canceled'
                    : book?.book_offline_status === 4
                      ? 'bg-canceled'
                      : '',
          ]"
          :key="index"
        >
          <div class="id">
            <router-link
              class="details_id"
              :to="`/hotels/book_offline/${book.id}`"
              target="_blank"
              :title="$t('edit')"
            >
              <h4 class="text-center" v-tooltip.left="$t('show_details')">
                {{ total - ((indexPage - 1) * per_page + index) }}
                <!-- {{ (indexPage - 1) * 5 + index + 1 }} -->
              </h4>
            </router-link>
          </div>
          <div
            class="d-flex serial align-items-center justify-content-center flex-column"
          >
            <p class="text-center text-temperature">{{ book.book_number }}</p>
            <span
              v-tooltip.bottom="$t('book_status')"
              :class="[
                'badge mx-1',
                book?.book_offline_status === 0
                  ? 'text-warning text-capitalize'
                  : book?.book_offline_status === 1
                    ? 'text-primary text-capitalize'
                    : book?.book_offline_status === 2
                      ? 'text-success text-capitalize'
                      : book?.book_offline_status === 3
                        ? 'text-danger text-capitalize'
                        : book?.book_offline_status === 4
                          ? 'text-danger text-capitalize'
                          : '',
              ]"
            >
              {{
                book?.book_offline_status === 0
                  ? $t("pending")
                  : book?.book_offline_status === 1
                    ? $t("tentative")
                    : book?.book_offline_status === 2
                      ? $t("confirmed")
                      : book?.book_offline_status === 3
                        ? $t("cancelled")
                        : book?.book_offline_status === 4
                          ? $t("canceled")
                          : ""
              }}
            </span>
          </div>
          <div class="option_date">
            <p>{{ $t("option_date") }}</p>
            <p>
              {{
                book?.client_option_date === ""
                  ? "--"
                  : book?.client_option_date
              }}
            </p>
          </div>
          <div
            class="info d-flex align-items-center justify-content-end flex-column"
          >
            <div class="d-flex" v-if="book.hotel">
              <p
                class="fw-bold pe-pointer"
                v-tooltip.left="$t('hotel_name')"
                @click="$router.push(`/hotel/details/${book.hotel.id}`)"
              >
                {{ book.hotel?.hotel_name }}
              </p>
              <span
                class="nights p-1 d-flex align-items-center justify-content-center mx-1"
                v-tooltip.left="$t('number_of_nights')"
                ><span>{{ book.number_of_nights }} </span> <span> N</span>
              </span>
            </div>
            <div
              v-else
              class="d-flex"
              v-for="hotel in book.hotels"
              :key="hotel"
            >
              <p
                class="fw-bold pe-pointer"
                v-tooltip.left="$t('hotel_name')"
                @click="$router.push(`/hotel/details/${book.hotel.id}`)"
              >
                {{ hotel.hotel_name }}
              </p>
              <span
                class="nights p-1 d-flex align-items-center justify-content-center mx-1"
                v-tooltip.left="$t('number_of_nights')"
                ><span>{{ book.number_of_nights }}</span><span> N</span>
              </span>
            </div>
            <p v-tooltip.left="$t('check-in:check-out')">
              {{ book.check_in_date }} -- {{ book.check_out_date }}
            </p>
          </div>
          <div class="rooms mt-0 mb-0">
            <div
              v-for="(room, index) in book.rooms"
              :key="index"
              class="room d-block flex-row-reverse"
            >
              <!-- <span>{{ room.count }}</span> -->
              <div class="d-flex align-items-center text-center flex-wrap">
                <i class="fa-solid fa-bed mx-1"></i>
                <p
                  class="text-center"
                  v-tooltip.left="$t('room_type')"
                  v-html="room?.room_name?.substring(0, 25)"
                ></p>
                ,
                <p class="text-center" v-tooltip.left="$t('supplier')">
                  {{ room?.supplier?.supplier_name }}
                </p>
                <span
                  v-if="room?.supplier_type === 'CONTRACT'"
                  class="text-center text-capitalize text-info"
                  v-tooltip.left="$t('rate_code')"
                  >,{{ room?.rate_code?.name }}</span
                >
                <span
                  v-if="room?.supplier_type === 'HOTEL'"
                  class="text-center text-success"
                  >{{ $tc("Hotel Direct") }}</span
                >
                <span
                  class="nights p-1 d-flex align-items-center justify-content-center mx-1"
                  v-tooltip.left="$t('status')"
                  >{{ $t(room.book_status) }}
                </span>
              </div>
            </div>
          </div>

          <div class="d-flex room_con flex-column">
            <div
              class="d-flex align-items-center justify-content-center"
              v-for="(room, index) in book.rooms"
              :key="index"
            >
              <span v-tooltip.left="$t('hotel_confirmation')">h.con:</span>
              <p class="hot_con text-center" v-tooltip.left="$t('code')">
                {{
                  room?.hotel_reference == "null"
                    ? ""
                    : room?.hotel_reference?.substring(0, 10)
                }}
              </p>
            </div>
          </div>

          <div class="cost d-flex justify-content-end gap-3">
            <div class="d-flex flex-column w-50 mx-2 align-items-center">
              <p style="width: max-content" v-tooltip.left="$t('Date_created')">
                {{ book?.created_at }}
              </p>
              <p style="width: max-content" v-tooltip.left="$t('client_name')">
                {{ book?.client?.name }}
              </p>
            </div>
            <div
              class="d-flex flex-column"
              :class="`${book.post_status == '0' ? 'd-none' : ''}`"
            >
              <div
                v-if="book.post_status == '0'"
                :class="`icons w-100 ${
                  book.post_status == '0' ? ' text-white p-1 rounded ' : ''
                }`"
              >
                <p
                  class="paid d-flex text-warning align-items-center justify-content-center px-2"
                >
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-else-if="book.post_status == '1'"
                :class="`icons w-100 ${
                  book.post_status == '1' ? ' text-white p-1 rounded ' : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center text-warning justify-content-center px-2"
                >
                  {{ $t("POST") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center total_price w-50">
              <p class="total mb-0" v-tooltip.left="$t('total')">
                {{ book.total_selling_price }}
              </p>

              <!-- <p>{{ book.client_currency_code }}</p> -->
              <p class="total_company" v-tooltip.left="$t('total_without_tax')">
                <!-- {{ book.organization_currency_code }} -->
                {{ Math.round(book?.selling?.total) }}
              </p>
              <div
                :class="` ${
                  book.payment_status == '0'
                    ? ' text-danger p-1 rounded mb-1'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                  :class="
                    book.client_payment_status == 'PAID'
                      ? 'text-success'
                      : book.client_payment_status == 'PARTIALLY_PAID'
                        ? 'text-primary'
                        : 'text-danger'
                  "
                >
                  {{ $t(book.client_payment_status) }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <span class="hint_element bank_hint">{{
                $t("total_company")
              }}</span>
              <span class="hint_element bank_hint">{{
                $t("total_client")
              }}</span>

              <div
                v-if="book.book_status == '1'"
                :class="`icons w-100 ${
                  book.book_status == '1'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("paid_ok") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '2'"
                :class="`icons w-100 ${
                  book.book_status == '2'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("Decline_payment") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '3'"
                :class="`icons  w-100 ${
                  book.book_status == '3' ? ' text-white p-1 rounded' : ''
                }`"
              >
                <p
                  class="paid text-warning d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND PENDING") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '4'"
                :class="`icons w-100 ${
                  book.book_status == '4'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Accept") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '5'"
                :class="`icons w-100 ${
                  book.book_status == '5'
                    ? 'bg-danger text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("REFUND Decline") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
              <div
                v-if="book.book_status == '6'"
                :class="`icons w-100 ${
                  book.book_status == '6'
                    ? 'bg-success text-white p-1 rounded'
                    : ''
                }`"
              >
                <p
                  class="paid d-flex align-items-center justify-content-center px-2"
                >
                  {{ $t("payment sent") }}
                  <!-- <img src="@/assets/media/image/Vector.svg" alt="" /> -->
                </p>
              </div>
            </div>
          </div>
          <div class="actions">
            <router-link
              class="btn btn-sm btn-success"
              :to="`/hotels/book_offline/${book.id}/history`"
              v-tooltip="$t('History')"
            >
              <i class="fa-solid fa-clock-rotate-left"></i>
            </router-link>
            <router-link
              class="details_id btn btn-sm btn-primary"
              :to="`/hotels/book_offline/${book.id}`"
              target="_blank"
              v-tooltip="$t('show_details')"
              style="width: max-content !important"
            >
              <i class="fa fa-eye"></i>
            </router-link>
            <router-link
              class="details_id btn btn-sm btn-secondary"
              :to="`/${invoice_theme == 1 ? 'book_table' : invoice_theme == 2 ? 'invoice' : 'book_table'}/${book.id}/${book?.book_offline_status == 0 ? 'option-date' : book?.book_offline_status == 1 ? 'price-offer' : book?.book_offline_status == 2 ? 'pill-confirm' : book?.book_offline_status == 3 ? 'cancelation' : 'price-offer'}`"
              v-tooltip="$t('invoices')"
              style="width: max-content !important"
            >
              <i class="fa-solid fa-file-invoice"></i>
            </router-link>
            <router-link
              class="details_id btn btn-sm btn-info"
              :to="`/hotels/book_offline/${book.base_booking_id}/payment/${book.id}/${book?.book_offline_status}`"
              v-tooltip="$t('paid')"
              style="width: max-content !important"
            >
              <i class="fa-solid fa-money-bill-1-wave"></i>
            </router-link>
          </div>
        </div>
      </div>
    </Transition>
    <div class="row mt-3">
      <pagination
        :data="hotelReservation"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchHotel"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
// import Multiselect from "vue-multiselect";
// import Skeleton from "primevue/skeleton";
// import Swal from "sweetalert2";
export default {
  name: "hotel-reservation",
  data() {
    return {
      hotelReservation: [],
      loading: true,
      titlenation: "",
      statusValue: "",
      indexPage: 1,
      per_page: 1,
      total: 1,
      status: [
        {
          id: 0,
          value: "0",
          title: this.$t("initial"),
        },
        {
          id: 1,
          value: "1",
          title: this.$t("pending"),
        },
        {
          id: 2,
          value: "2",
          title: this.$t("completed"),
        },
        {
          id: 3,
          value: "3",
          title: this.$t("edited"),
        },
        {
          id: 4,
          value: "4",
          title: this.$t("canceled"),
        },
      ],
    };
  },
  components: {
    pagination,
    // Multiselect,
    // Skeleton,
  },
  methods: {
    FetchHotel(page = 1) {
      // const data = {
      //   client_id: this.$route.params.id,
      // };
      // if (this.statusValue != "") {
      //   data.status = this.statusValue;
      // }
      // if (this.titlenation != "") {
      //   data.word = this.titlenation;
      // }
      axios
        .post(`/fetch_offline_booking_upon_client_id?page=${page}`, {
          client_id: this.$route.params.id,
          per_page: 5,
        })

        .then(({ data }) => {
          this.hotelReservation = data.data;
          this.indexPage = page;
          this.per_page = data.data.meta.per_page;
          this.total = data.data.meta.total;
          this.total = data.data.meta.total;
          console.log(this.hotelReservation);
        });
    },
  },
  mounted() {
    this.FetchHotel();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
// @import "@/assets/scss/variables.scss";
@import "@/view/dashboard/hotelsBookOffline/_hotelsBookOffline.scss";
thead {
  border-radius: 10px 10px 0px 0px;
  border-radius: 20px;

  // border: 1px solid $PrimaryColor !important ;
  tr {
    border-radius: 10px 10px 0px 0px !important;

    // border: 1px solid $PrimaryColor !important ;
    th {
      text-align: start !important;
      font-family: "bold";
      font-weight: 100;
      background-color: #eef4f4;
      color: #656b78;
    }
  }
}

tbody {
  tr {
    border-bottom: unset !important;
    border-top: unset !important;

    &.main {
      td {
        background-color: #0a909b17;
      }
    }

    .status {
      display: inline-block;
      width: 88px;
      font-size: 0.6rem;
      border-radius: 24px;
      height: 40px;
      line-height: 40px;
      text-align: center;

      &.main {
        background-color: #1664b857;
        color: #1664b8;
      }

      &.accept {
        background-color: #88c64b3d;
        color: #88c64b;
      }

      &.pending {
        color: #0a8f9b;
        background-color: #0a8f9b46;
        width: 130px;
      }

      &.decline {
        color: #c57016;
        background-color: rgba($color: #c57016, $alpha: 0.1);
      }
    }

    td {
      padding: 0.75rem 0;
      font-family: "regular";
      font-size: 0.9rem;
      border-bottom: unset !important;
      border-top: unset !important;
      color: black;
      padding-inline-start: 0 !important;

      .btn-sm {
        padding: 0.35rem 0.55rem;
      }

      a {
        // &.receipt {
        //   color: $PrimaryColor;
        //   text-decoration: underline;
        // }
      }
    }

    .offcanvas.offcanvas-bottom {
      height: auto;
    }
  }
}
</style>
