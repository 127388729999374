<template>
  <div class="contract_table">
    <div class="d-flex justify-content-between align-items-center gap-3 mb-2">
      <div class="d-flex align-items-center">
        <AddFavDailog v-tooltip.left="$t('add_to_faviorites')" />
        <h5 class="title mx-2">
          {{ $t("available_rooms") }}
        </h5>
      </div>
      <div class="buttons d-flex align-items-center">
        <button
          v-if="contracts"
          class="btn SecondButton mx-2"
          @click="exportToExcel"
          title="Excel"
        >
          <i class="fa-solid fa-file-excel"></i>
        </button>
        <button
          v-if="contracts"
          class="btn SecondButton mx-2"
          v-print="printObj"
          title="PDF"
          @click="print = true"
        >
          <i class="fa-solid fa-file-pdf"></i>
        </button>
      </div>
    </div>
    <form
      @submit.prevent="filterContracts"
      class="d-flex align-items-center gap-3 justify-content-between"
    >
      <Multiselect
        v-model="hotelValue"
        :options="hotels"
        label="title"
        track-by="id"
        :clear-on-select="true"
        :placeholder="$t('Choose_hotel')"
        select-label=""
        :hide-selected="true"
        :custom-label="formatHotelLabel"
        @keyup="searchHotel"
        @update:model-value="fetchContract()"
      >
      </Multiselect>
      <Datepicker
        v-model="checkIn"
        name="durationFrom"
        :placeholder="$t('From')"
        @input="UpdateModelCheckout"
      ></Datepicker>

      <Datepicker
        v-model="checkOut"
        name="durationTo  "
        :placeholder="$t('To')"
        :disabled-dates="{
          to: checkIn,
        }"
      ></Datepicker>

      <button type="submit" class="btn PrimaryButton">
        <i class="fa-solid fa-circle-notch fa-spin" v-if="loader"></i>
        <span v-else> {{ $t("filter") }} </span>
      </button>
    </form>
    <hr />
    <div class="my-2 room-avalibiltiy">
      <div v-if="contracts.length">
        <div
          class="d-flex align-items-start mb-2 rounded table-responsive not-print"
        >
          <div class="head">
            <p class="mb-0 border-bottom p-1 bg-first text-white">
              {{ $t("date") }}
            </p>
            <p class="mb-0 border-bottom p-1 bg-first text-white">
              {{ $t("day") }}
            </p>
            <p class="mb-0 border-bottom p-1 bg-first text-white">
              {{ $t("Hijri") }}
            </p>
            <p class="mb-0 border-bottom bg-second p-1">Allotment</p>
            <p class="mb-0 border-bottom bg-second p-1">Over Hotel</p>
            <p class="mb-0 border-bottom bg-second p-1">Over Agent</p>
            <p class="mb-0 border-bottom bg-second p-1">Direct</p>
            <p class="mb-0 border-bottom bg-second p-1">Agent</p>
            <p class="mb-0 border-bottom bg-second p-1">Over Booking</p>
            <p class="mb-0 border-bottom p-1 bg-third text-white">
              Total Reserved Rooms
            </p>
            <p class="mb-0 border-bottom bg-fourth text-white p-1">Confirmed</p>
            <p class="mb-0 border-bottom bg-fifth text-white p-1">Pending</p>
            <p class="mb-0 border-bottom p-1">Tentative</p>
            <p class="mb-0 border-bottom p-1 bg-primary text-white">
              Total Booked
            </p>
            <p class="mb-0 border-bottom bg-seventh text-white p-1">Canceled</p>
            <p class="mb-0 border-bottom bg-eighth p-1">No Show</p>
            <p class="mb-0 border-bottom text-info p-1">Over Booking</p>
            <p class="mb-0 border-bottom p-1 bg-success text-white">
              Total Balance
            </p>
            <p class="mb-0 border-bottom bg-eighth p-1">Check In</p>
            <p class="mb-0 border-bottom bg-eighth p-1">Check Out</p>
            <p class="mb-0 border-bottom bg-eighth p-1">In House</p>
            <p class="mb-0 border-bottom bg-info text-white p-1">% Occup</p>
            <p class="mb-0 border-bottom bg-eighth p-1">Room Rate</p>
          </div>
          <div v-for="(item, index) in contracts" :key="index" class="body">
            <p class="text-center mb-0 border-bottom p-1 bg-first text-white">
              {{ item.day }}
            </p>
            <p class="text-center mb-0 border-bottom p-1 bg-first text-white">
              {{ getDayOfWeek(item.day) }}
            </p>
            <p
              class="text-center mb-0 border-bottom p-1 hijri bg-first text-white"
            >
              {{ updateHijriDate(new Date(item.day)) }}
            </p>
            <p class="text-center mb-0 border-bottom bg-second p-1">
              {{ item.allotment }}
            </p>
            <p class="text-center mb-0 border-bottom bg-second p-1">
              {{ item.over_hotel }}
            </p>
            <p class="text-center mb-0 border-bottom bg-second p-1">
              {{ item.over_agent }}
            </p>
            <p class="text-center mb-0 border-bottom bg-second p-1">
              {{ item.direct }}
            </p>
            <p class="text-center bg-second border-bottom p-1">
              {{ item.agent }}
            </p>
            <p class="text-center bg-second border-bottom p-1">
              {{
                item.over_booking === -1
                  ? $t("infinity")
                  : item.over_booking === 0
                    ? $t("unavailable")
                    : item.over_booking
              }}
            </p>

            <p class="text-center mb-0 bg-third text-white border-bottom p-1">
              {{ item.total_reserved }}
            </p>
            <p
              class="text-center bg-fourth texx-white mb-0 border-bottom p-1"
              @click="fetchBookDay(item.day, 2, hotelValue.id)"
              style="cursor: pointer"
            >
              {{ item.confirmed }}
            </p>
            <p
              class="text-center mb-0 border-bottom bg-fifth text-white p-1"
              @click="fetchBookDay(item.day, 0, hotelValue.id)"
              style="cursor: pointer"
            >
              {{ item.pending }}
            </p>
            <p
              class="text-center mb-0 border-bottom p-1"
              @click="fetchBookDay(item.day, 1, hotelValue.id)"
              style="cursor: pointer"
            >
              {{ item.tentative }}
            </p>
            <p class="text-center mb-0 bg-primary text-white border-bottom p-1">
              {{ item.total_booked }}
            </p>
            <p
              class="text-center mb-0 bg-seventh text-white border-bottom p-1"
              @click="fetchBookDay(item.day, 3, hotelValue.id)"
              style="cursor: pointer"
            >
              {{ item.cancelled }}
            </p>
            <p class="text-center mb-0 bg-eighth border-bottom p-1">
              {{ item.no_show }}
            </p>
            <p class="text-center text-info mb-0 bg-eighth border-bottom p-1">
              {{ item.minus_count }}
            </p>
            <p
              class="text-center bg-eighth mb-0 bg-success text-white border-bottom p-1 total"
            >
              {{ item.total_balance }}
            </p>
            <p class="text-center bg-eighth mb-0 border-bottom p-1">
              {{ item.check_in }}
            </p>
            <p class="text-center bg-eighth mb-0 border-bottom p-1">
              {{ item.check_out }}
            </p>
            <p class="text-center bg-eighth mb-0 border-bottom p-1">
              {{ item.in_house ? item.in_house : 0 }}
            </p>
            <p class="text-center bg-info text-white mb-0 border-bottom p-1">
              {{ findNearestSets(item.occupancy) }}%
            </p>
            <p class="text-center bg-eighth p-1">
              {{ Math.round(item.ava_room_rate) }}
            </p>

            <!-- Displaying the key of the object -->
          </div>
        </div>
        <div
          class="row mb-2 rounded d-none"
          v-for="(row, index) in numberOfRows"
          :key="index"
        >
          <div class="col-2 head">
            <p class="text-start mb-0">
              {{ $t("date") }}
            </p>
            <p class="text-start mb-0">
              {{ $t("day") }}
            </p>
            <p class="text-start mb-0">
              {{ $t("Hijri") }}
            </p>
            <p class="text-start mb-0">Allotment</p>
            <p class="text-start mb-0">Over Hotel</p>
            <p class="text-start mb-0">Over Agent</p>
            <p class="text-start mb-0">Direct</p>
            <p class="text-start mb-0">Agent</p>
            <p class="text-start mb-0">Over Booking</p>
            <p class="text-start mb-0">Total Reserved Rooms</p>
            <p class="text-start mb-0">Confirmed</p>
            <p class="text-start mb-0">Pending</p>
            <p class="mb-0 border-bottom">Tentative</p>
            <p class="mb-0 text-start mb-0">Total Booked</p>
            <p class="text-start mb-0">Canceled</p>
            <p class="text-start mb-0">No Show</p>
            <p class="text-start mb-0">Over Booking</p>
            <p class="text-start mb-0">Total Balance</p>
            <p class="text-start mb-0">Check In</p>
            <p class="text-start mb-0">Check Out</p>
            <p class="text-start mb-0">In House</p>
            <p class="text-start mb-0">% Occup</p>
            <p class="mb-0">Room Rate</p>
          </div>
          <div
            class="col-2 data"
            v-for="(item, i) in itemsForCurrentRow(index)"
            :key="i"
          >
            <p class="text-center mb-0">
              {{ item.day }}
            </p>
            <p class="text-center mb-0">
              {{ getDayOfWeek(item.day) }}
            </p>
            <p class="text-center mb-0">
              {{ updateHijriDate(new Date(item.day)) }}
            </p>
            <p class="text-center mb-0">
              {{ item.allotment }}
            </p>
            <p class="text-center mb-0">
              {{ item.over_hotel }}
            </p>
            <p class="text-center mb-0">
              {{ item.over_agent }}
            </p>
            <p class="text-center mb-0">
              {{ item.direct }}
            </p>
            <p class="text-center mb-0">
              {{ item.agent }}
            </p>
            <p class="text-center mb-0">
              {{ item.over_booking ? $t("unavailable") : $t("available") }}
            </p>

            <p class="text-center mb-0">
              {{ item.total_reserved }}
            </p>
            <p
              class="text-center mb-0"
              @click="fetchBookDay(item.day, 2, hotelValue.id)"
            >
              {{ item.confirmed }}
            </p>
            <p
              class="text-center mb-0"
              @click="fetchBookDay(item.day, 0, hotelValue.id)"
            >
              {{ item.pending }}
            </p>
            <p
              class="text-center mb-0"
              @click="fetchBookDay(item.day, 1, hotelValue.id)"
            >
              {{ item.tentative }}
            </p>
            <p class="text-center mb-0">
              {{ item.total_booked }}
            </p>
            <p
              class="text-center mb-0"
              @click="fetchBookDay(item.day, 3, hotelValue.id)"
            >
              {{ item.cancelled }}
            </p>
            <p class="text-center mb-0">
              {{ item.no_show }}
            </p>
            <p class="text-center mb-0">
              {{ item.minus_count }}
            </p>
            <p class="text-center mb-0">
              {{ item.total_balance }}
            </p>
            <p class="text-center mb-0">
              {{ item.check_in }}
            </p>
            <p class="text-center mb-0">
              {{ item.check_out }}
            </p>
            <p class="text-center mb-0">
              {{ item.in_house ? item.in_house : 0 }}
            </p>
            <p class="text-center mb-0">{{ item.occupancy }}%</p>
            <p class="text-center mb-0">
              {{ Math.round(item.ava_room_rate) }}
            </p>

            <!-- Displaying the key of the object -->
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex align-items-center justify-content-center">
          <img
            src="@/assets/media/image/no-data.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <p class="text-center my-5 fs-3">{{ $t("Please Enter Data") }}</p>
      </div>
      <div v-show="print" id="printMe">
        <HeaderPdf
          :title="$t('sales_taxes_reports')"
          :toDate="checkIn"
          :fromDate="checkOut"
          :hotelValue="hotelValue"
        />
        <div
          class="row mb-2 rounded border-start bg-gray-200 border-bottom border-end"
          v-for="(row, index) in numberOfRows"
          :key="index"
        >
          <div class="col-2 head" style="background-color: #ccc">
            <p class="text-start mb-0 border-bottom">
              {{ $t("date") }}
            </p>
            <p class="text-start mb-0 border-bottom">
              {{ $t("day") }}
            </p>
            <p class="text-start mb-0 border-bottom">
              {{ $t("Hijri") }}
            </p>
            <p class="text-start mb-0 border-bottom">Allotment</p>
            <p class="text-start mb-0 border-bottom">Over Hotel</p>
            <p class="text-start mb-0 border-bottom">Over Agent</p>
            <p class="text-start mb-0 border-bottom">Direct</p>
            <p class="text-start mb-0 border-bottom">Agent</p>
            <p
              class="text-start mb-0 border-bottom fs-6"
              style="font-size: 0.4rem"
            >
              Total.R Rooms
            </p>
            <p class="text-start mb-0 border-bottom">Confirmed</p>
            <p class="text-start mb-0 border-bottom">Pending</p>
            <p class="mb-0 text-start border-bottom">Tentative</p>
            <p class="mb-0 text-start mb-0 border-bottom">Total Booked</p>
            <p class="text-start mb-0 border-bottom">Canceled</p>
            <p class="text-start mb-0 border-bottom">No Show</p>
            <p class="text-start mb-0 border-bottom">Total Balance</p>
            <p class="text-start mb-0 border-bottom">Check In</p>
            <p class="text-start mb-0 border-bottom">Check Out</p>
            <p class="text-start mb-0 border-bottom">In House</p>
            <p class="text-start mb-0 border-bottom">% Occup</p>
            <p class="mb-0 text-start border-bottom">Room Rate</p>
          </div>
          <div
            class="col-2 data border-start"
            v-for="(item, i) in itemsForCurrentRow(index)"
            :key="i"
          >
            <p class="text-center mb-0 border-bottom">
              {{ item.day }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ getDayOfWeek(item.day) }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ updateHijriDate(new Date(item.day)) }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.allotment }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.over_hotel }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.over_agent }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.direct }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.agent }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.total_reserved }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.confirmed }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.pending }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.tentative }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.total_booked }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.cancelled }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.no_show }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.total_balance }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.check_in }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.check_out }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ item.in_house ? item.in_house : 0 }}
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ findNearestSets(item.occupancy) }}%
            </p>
            <p class="text-center mb-0 border-bottom">
              {{ Math.round(item.ava_room_rate) }}
            </p>

            <!-- Displaying the key of the object -->
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="contracts"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="filterContracts"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </div>
</template>

<script>
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
// import Skeleton from "primevue/skeleton";
import Datepicker from "vuejs3-datepicker";
import Multiselect from "vue-multiselect";
import moment from "moment";
import { useBookStore } from "@/store/rooms/index";
import { mapActions } from "pinia";
import * as XLSX from "xlsx";
import HeaderPdf from "@/components/pdf/headerPdf.vue";
import Swal from "sweetalert2";

export default {
  name: "table-customization",
  components: {
    pagination,
    AddFavDailog,
    // Skeleton,
    HeaderPdf,
    Datepicker,
    Multiselect,
  },
  data() {
    return {
      contracts: [],
      print: false,
      printObj: {
        id: "printMe",
        closeCallback(vue) {
          vue.print = false;
        },
      },
      contractText: "",
      hotels: [],
      hotelValue: "",
      checkIn: "",
      checkOut: "",
      fetchContracts: [],
      contractValue: "",
      itemsPerRow: 5, // Number of items per row
      loader: false,
    };
  },
  computed: {
    numberOfRows() {
      return Math.ceil(this.contracts.length / this.itemsPerRow); // Calculate the number of rows needed
    },
  },
  methods: {
    ...mapActions(useBookStore, ["fetchBookInDay"]),
    formatHotelLabel(hotel) {
      return `${hotel.title} -
              ${hotel.city_title}, ${hotel.country_title} (${hotel.id})`;
    },
    getDayOfWeek(dateString) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      return days[dayIndex];
    },
    itemsForCurrentRow(rowIndex) {
      const startIndex = rowIndex * this.itemsPerRow;
      const endIndex = startIndex + this.itemsPerRow;
      return this.contracts.slice(startIndex, endIndex);
    },
    searchHotel(event) {
      const searchText = { word: event?.target?.value };
      if (event?.target?.value.length) {
        axios
          .post(`/fetch_new_home_hotels`, searchText)
          .then((res) => (this.hotels = res.data.data.hotels))
          .catch((err) => console.log(err));
      } else {
        axios
          .post(`/fetch_new_home_hotels`)
          .then((res) => (this.hotels = res.data.data.hotels))
          .catch((err) => console.log(err));
      }
    },
    async fetchContract() {
      const requestData = {
        hotel_id: this.hotelValue.id,
        // Add other properties as needed
      };
      try {
        const { data } = await axios.post(
          "fetch_contract_by_hotel_id",
          requestData,
        );
        this.fetchContracts = data.data;
        // console.log(this.contracts);
      } catch (error) {
        Swal.fire({
          text: error.response.data.message + this.$t("the_contract"),
          icon: "error",
        })
        // console.log(error);
      }
    },

    getDayName(date) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const dayIndex = new Date(date).getDay();
      return days[dayIndex];
    },

    findNearestSets(number) {
      if (isNaN(number)) {
        return number; // Return the original value if not a number
      }
      const rounded = Math.round(number * 10000) / 10000;
      return rounded;
    },

    filterContracts() {
      this.loader = true;
      const formData = new FormData();
      if(this.checkIn) {
        formData.append("from", moment(this.checkIn).format("YYYY-MM-DD"));
      }
      if(this.checkOut) {
        formData.append("to", moment(this.checkOut).format("YYYY-MM-DD"));
      }
      if(!this.checkIn || !this.checkOut) {
        Swal.fire({
          text: this.$t("choose_date"),
          icon: "error",
        })
        return
      }
      formData.append("hotel_id", this.hotelValue.id);

      axios
        .post(`/new_fetch_contract_report_upon_hotel`, formData)
        .then((res) => {
          this.contracts = res.data.data;
          this.loader = false;
          // console.log(this.contracts);
        })
        .catch((err) => {
          Swal.fire({
            text: err.response.data.message,
            icon: "error",
          })
          this.loader = false;
        });
    },

    UpdateModelCheckout() {
      this.checkOut = this.checkIn;
    },

    fetchBookDay(day, status, hotelId) {
      // console.log(day, status);
      const url = `/room/book/${day}/${status}/${hotelId}`;
      window.open(url, "_blank");
      // this.$router.push(`/room/book/${day}/${status}/${hotelId}`);
    },

    async exportToExcel() {
      try {
        this.loader = true;
        const formData = new FormData();
        formData.append("from", moment(this.checkIn).format("YYYY-MM-DD"));
        formData.append("to", moment(this.checkOut).format("YYYY-MM-DD"));
        formData.append("hotel_id", this.hotelValue.id);
        const response = await axios.post(
          "/new_fetch_contract_report_upon_hotel",
          formData,
        );
        const responseData = response.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "contractRoom.xlsx");
        this.loader = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loader = false;
        // Handle the error as needed, e.g., show a message to the user
      }
    },

    async pdf() {
      const formData = new FormData();
      formData.append("from", moment(this.checkIn).format("YYYY-MM-DD"));
      formData.append("to", moment(this.checkOut).format("YYYY-MM-DD"));
      formData.append("hotel_id", this.hotelValue.id);
      const { data } = await axios.post(
        "/new_fetch_contract_report_upon_hotel",
        formData,
      );
      this.pdfData = data.data;
    },
  },
  mounted() {
    this.searchHotel();
    window.addEventListener("afterprint", () => {
      this.print = false;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./_contract.scss";
</style>
