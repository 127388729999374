<template>
  <section class="card_style">
    <div class="container">
      <div class="main_header">
        <h3>{{$t("add season")}}</h3>
      </div>
      <form @submit.prevent="sendData()">
        <div class="row">
          <div class="col-lg-12 col-12"></div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          {{$t("season name")}}
                      </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                          <div class="form-group" v-for="(season , index) in seasonsName" :key="index">
                              <label for="name" class="form-label">{{$t("season name")}} ({{season.lang}})</label>
                              <input type="text" :placeholder="$t('enter season name')" class="form-control" :id="`name${index}`" v-model="season.name" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <label>{{$t("period_from")}}</label>
            <datepicker
              v-model="fromDate"
              name="fromDate"
              :placeholder="$t('from')"
              :disabled-dates="{
                to: new Date(
                  seasons.length ? getLastObjectSeason : contract_from
                ),
                from: new Date(
                  contract_to
                ),
              }"
              :value="seasons.length ? getLastObjectSeason : contract_from"
            ></datepicker>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <label>{{$t("period_to")}}</label>
            <datepicker
              v-model="toDate"
              name="toDate"
              :placeholder="$t('to')"
              :disabled-dates="{
                to: new Date(new Date(seasons.length ? getLastObjectSeason : contract_from).getTime() + 24 * 60 * 60 * 1000),
                from: new Date(
                  contract_to
                ),
              }"
              :value="contract_to"
            ></datepicker>
          </div>
        </div>
        <button type="submit" class="btn PrimaryButton">{{$t("save")}}</button>
      </form>
    </div>
  </section>
</template>

<script>
import Datepicker from "vuejs3-datepicker";
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: "addSeason",
    components: {Datepicker},
    data(){
      return{
        languages:["ar","en","id"],
        seasonsName:[],
        fromDate: null,
        toDate: null,
        contract_id: this.$route.params.id,
        contract_from: "",
        contract_to: "",
        seasons: [],
      }
    },
    computed:{
      getLastObjectSeason() {
        if (this.seasons.length > 0) {
          const startDateSeason = new Date(
            new Date(this.seasons[this.seasons.length - 1].to).getTime() +
              24 * 60 * 60 * 1000
          );
          const startDateSeasonFormat =
            moment(startDateSeason).format("YYYY-MM-DD");
          return startDateSeasonFormat;
        } else {
          return null;
        }
      },
    },
    methods:{
      setSeasonName(){
        this.languages.forEach((language)=>{
          this.seasonsName.push({
            lang: language,
            name: ""
          })
        })
      },
      fetchContractData(){
        axios.get(`/visa_contract/${this.contract_id}`).then(({data})=>{
          this.contract_from = moment(data.data.from).format("YYYY-MM-DD");
          this.contract_to = moment(data.data.to).format("YYYY-MM-DD");
          this.fromDate = this.contract_from;
          this.toDate = this.contract_to;
          this.seasons = data.data.seasons;
        })
      },
      sendData(){
        let seasonData = {
          visa_contract_id: this.contract_id,
          from: this.seasons.length ? this.getLastObjectSeason : this.contract_from,
          to: moment(this.toDate).format("YYYY-MM-DD"),
        }
        this.seasonsName.forEach((season)=>{
          seasonData['name_'+season.lang] = season.name
        })

        axios.post("/season_contract" , seasonData).then(({data})=>{
          console.log(data)
          Swal.fire({
              title: "",
              text: data.message,
              icon: "success",
          });
          this.$router.push(`/visa/contract/${this.contract_id}/seasons`);
        }).catch((error)=>{
            Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
            });
        })
      },
    },
    mounted(){
      this.setSeasonName();
      this.fetchContractData();
    }
}
</script>

<style>

</style>