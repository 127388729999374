<template>
  <nav class="navbar">
    <div class="logo">
      <img src="@/assets/media/image/logo.png" alt="logo" />
    </div>
    <div>
      <div class="changelocal d-inline-block">
        <div class="dropdown lang">
          <a
            class="btn dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"

            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <!-- lang -->
            <i class="fa-solid fa-earth-europe px-2"></i>
          </a>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <li>
              <a
                class="dropdown-item"
                v-for="entry in languages"
                :key="entry.title"
                @click="changeLocale(entry.language)"
                :iso="entry.flag"
                v-bind:squared="false"
              >
                <flag :iso="entry.flag" v-bind:squared="false" />
                {{ entry.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="dropdown d-inline-block">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="../../assets/media/image/user.png"
            class="admin_img"
            alt="person"
          />
          Admin
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <button class="dropdown-item removeToken" @click="removeToken">
              {{ $t("logout") }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    .removeToken {
      padding: 0.75rem 0.5rem;
    }
    .btn {
      background: transparent;
      border-color: transparent;
      color: #000;
      font-family: "regular";
      font-size: 0.8rem;
      &:focus {
        box-shadow: none;
      }
    }
    .logo {
      width: 150px;
      img {
        width: 100%;
      }
    }
    .admin_img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin: 0 0.5rem;
    }
  }
</style>

<script>
  export default {
    name: "navbar-layout",
    data() {
      return {
        languages: [
          { flag: "us", language: "en", title: this.$i18n.t("english") },
          { flag: "eg", language: "ar", title: this.$i18n.t("arabic") },
          // { flag: "tr", language: "tr", title: "Turkish" },
        ],
        profile: {},
      };
    },

    methods: {
      changeLocale(locale) {
        this.$i18n.locale = locale;
        const html = document.documentElement; // returns the html tag
        html.setAttribute("lang", locale);
        // this.$forceUpdate('lang',locale);
        localStorage.setItem("lang", locale);
        location.reload();
        // console.log(locale);
      },
      removeToken: function () {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        this.$router.go("/login");
      },
    },
    created() {
      // changeLocale(locale)
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", this.$i18n.locale);
      // console.log(i18n.locale);

      this.profile = JSON.parse(localStorage.getItem("user"));
      // console.log(this.profile)
    },
  };
</script>
