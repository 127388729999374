<template>
  <section class="px-5">
    <div
      class="main_header_style d-flex justify-content-between align-items-center my-2"
    >
      <div class="d-flex align-items-center gap-3">
        <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
        <h4 class="coupons mb-3">
          {{ $t("package_reservation") }}
        </h4>
      </div>
      <input
        type="text"
        v-model="booksPackageText"
        class="form-control w-50"
        :placeholder="$t('search...')"
        @keyup="fetchmy_reservations((page = 1))"
      />
      <div id="print-button" class="print_button d-flex align-items-center">
        <button
          class="btn SecondButton"
          title="Print"
          v-print="'#table-responsive'"
        >
          <i class="fa-solid fa-file-pdf"></i>
        </button>
        <button class="btn SecondButton" @click="exportToExcel" title="Excel">
          <i class="fa-solid fa-file-excel"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive" id="table-responsive">
        <table class="table" id="userTable">
          <thead>
            <tr>
              <th class="text-center">ID</th>
              <th class="text-center">{{ $t("date") }}</th>
              <th class="text-center">{{ $t("program") }}</th>
              <th class="text-center">{{ $t("date_trip") }}</th>
              <th class="text-center">{{ $t("package_services") }}</th>
              <th class="text-center">{{ $t("title") }}</th>
              <th class="text-center">{{ $t("number_of_adults") }}</th>
              <th class="text-center">{{ $t("number_of_childrens") }}</th>
              <th class="text-center">{{ $t("total_price") }}</th>
              <th class="text-center">{{ $t("Markters") }}</th>
              <th class="text-center">{{ $t("coupons") }}</th>
              <th class="text-center">{{ $t("Discount code") }}</th>
              <th class="text-center details">{{ $t("Control") }}</th>
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 13" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in my_reservations.data" :key="index">
              <td>{{ item.id }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.package?.title }}</td>
              <td>{{ item.package?.luanch_date }}</td>
              <td>
                <span
                  class="service"
                  v-for="(service, index) in item?.package?.package_services"
                  :key="index"
                  >{{ $t(`${service}`) }}<span class="dash"> - </span></span
                >
              </td>

              <td>
                <span v-for="guest in item.package_guests" :key="guest.id">{{
                  guest.first_name
                }}</span>
              </td>
              <td>{{ item.adult_count }}</td>
              <td>{{ item.child_count }}</td>
              <td>{{ item.total_price }}</td>
              <td>
                {{ item.marketer?.first_name ?? "-" }}
                {{ item.marketer?.last_name ?? "-" }}
              </td>

              <td>
                {{ item.coupon_code ? item.coupon_code : "-" }}
              </td>
              <td>
                {{ item.discount_value ? item.discount_value : "-" }}
              </td>
              <td class="details">
                <!-- <Button label="Show" @click="visible = true" /> -->
                <div class="actions d-flex align-items-center">
                  <button
                    class="btn btn-primary btn-sm fs-8"
                    label="Show"
                    @click="select_supplier(item.id, item.package.id)"
                  >
                    {{ $t("suppliers") }}
                  </button>

                  <router-link
                    :to="`/umrah/book_details/${item.id}`"
                    class="btn btn-info text-white mx-1 w-auto btn-sm fs-8"
                  >
                    <i class="fa-solid fa-circle-info mx-1"></i>
                  </router-link>
                  <button
                    class="btn btn-success btn-sm fs-8"
                    type="button"
                    data-bs-toggle="modal"
                    :data-bs-target="`#exampleModal_show${item.id}`"
                    v-if="
                      item.receipts.length > 0 && item.payment_method == 1
                        ? item?.receipts[0]?.image
                        : false
                    "
                  >
                    {{ $t("view receipt") }}
                  </button>
                  <button
                    class="btn btn-warning btn-sm fs-8"
                    type="button"
                    @click="upladReceipt(item.id, Package)"
                    v-else
                  >
                    {{ $t("order_receipt") }}
                  </button>
                </div>
                <showReceipt
                  :book_id="item.id"
                  :book_type="Package"
                  :status="item.receipts[0]?.book_status"
                  :image="
                    item?.receipts[0]?.image ? item?.receipts[0]?.image : ''
                  "
                  :organizations="organizations"
                  :fetchmy_reservations="fetchmy_reservations"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <supplierDialog /> -->

        <div class="card flex justify-content-center">
          <Dialog
            v-model:visible="visible"
            modal
            :header="$t('Selection of suppliers')"
            :style="{ width: '75rem' }"
            :dismissableMask="true"
          >
            <div class="hotels_container">
              <h5>
                <img src="@/assets/media/image/icon/tree.png" />
                {{ $t("hotels") }}
              </h5>
              <div class="all_hotels" v-if="package_hotels.length">
                <div
                  class="row"
                  v-for="(hotel, hotelIndex) in package_hotels"
                  :key="hotelIndex"
                >
                  <div class="col-md-4 d-flex align-items-center">
                    <h5 class="title">{{ hotel.title }}</h5>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="form-label" for="hotel_supplier">{{
                        $t("supplier")
                      }}</label>
                      <Multiselect
                        v-model="hotel.supplier_id"
                        :options="providers"
                        label="name"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('choose')"
                        select-label=""
                        :hide-selected="true"
                      ></Multiselect>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label" for="hotel_adult_cost">{{
                        $t("adult_cost")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_adult_cost"
                        v-model="hotel.adult_cost"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="hotel_adult_selling"
                        >{{ $t("adult_selling") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_adult_selling"
                        v-model="hotel.adult_selling"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label" for="hotel_child_cost">{{
                        $t("child_cost")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_child_cost"
                        v-model="hotel.child_cost"
                      />
                      cost_child
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="hotel_child_selling"
                        >{{ $t("child_selling") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_child_selling"
                        v-model="hotel.child_selling"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="flights_container">
              <h5>
                <img src="@/assets/media/image/icon/airplane.png" />
                {{ $t("flights") }}
              </h5>
              <div class="all_hotels" v-if="package_flights.length">
                <div
                  class="row"
                  v-for="(flight, flightIndex) in package_flights"
                  :key="flightIndex"
                >
                  <div class="col-md-4 d-flex align-items-center">
                    <h5 class="title">
                      <div class="airplane_name">
                        {{ flight.launch_airport?.title }}
                      </div>
                      <div class="airplane">
                        <img src="@/assets/media/image/icon/Vector.png" />
                        <div class="line"></div>
                        <img src="@/assets/media/image/icon/Vector.png" />
                      </div>
                      <div class="airplane_name">
                        {{ flight.arrival_airport?.title }}
                      </div>
                    </h5>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="form-label" for="hotel_supplier">{{
                        $t("supplier")
                      }}</label>
                      <Multiselect
                        v-model="flight.supplier_id"
                        :options="providers"
                        label="name"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('choose')"
                        select-label=""
                        :hide-selected="true"
                      ></Multiselect>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <label class="form-label" for="hotel_adult_cost">{{
                            $t("adult_cost")
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            id="hotel_adult_cost"
                            v-model="flight.adult_cost"
                          />
                        </div>
                        <div class="form-group">
                          <label class="form-label" for="hotel_adult_selling"
                            >{{ $t("adult_selling") }}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="hotel_adult_selling"
                            v-model="flight.adult_selling"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label class="form-label" for="hotel_child_cost">{{
                            $t("child_cost")
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            id="hotel_child_cost"
                            v-model="flight.child_cost"
                          />
                        </div>
                        <div class="form-group">
                          <label class="form-label" for="hotel_child_selling"
                            >{{ $t("child_selling") }}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="hotel_child_selling"
                            v-model="flight.child_selling"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <label class="form-label" for="hotel_inf_cost">{{
                            $t("baby_cost")
                          }}</label>
                          <input
                            type="text"
                            class="form-control"
                            id="hotel_inf_cost"
                            v-model="flight.inf_cost"
                          />
                        </div>
                        <div class="form-group">
                          <label class="form-label" for="hotel_inf_selling"
                            >{{ $t("baby_selling") }}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="hotel_inf_selling"
                            v-model="flight.inf_selling"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="visa_container">
              <h5>
                <img src="@/assets/media/image/icon/card-pos.png" />
                {{ $t("visa") }}
              </h5>
              <div class="all_hotels" v-if="package_visas.length">
                <div
                  class="row"
                  v-for="(visa, visaIndex) in package_visas"
                  :key="visaIndex"
                >
                  <div class="col-md-4 d-flex align-items-center">
                    <h5 class="title">{{ visa.title }}</h5>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="form-label" for="hotel_supplier">{{
                        $t("supplier")
                      }}</label>
                      <Multiselect
                        v-model="visa.supplier_id"
                        :options="providers"
                        label="name"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('choose')"
                        select-label=""
                        :hide-selected="true"
                      ></Multiselect>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label" for="hotel_adult_cost">{{
                        $t("adult_cost")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_adult_cost"
                        v-model="visa.adult_cost"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="hotel_adult_selling"
                        >{{ $t("adult_selling") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_adult_selling"
                        v-model="visa.adult_selling"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label" for="hotel_child_cost">{{
                        $t("child_cost")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_child_cost"
                        v-model="visa.child_cost"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="hotel_child_selling"
                        >{{ $t("child_selling") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_child_selling"
                        v-model="visa.child_selling"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="transportation_container">
              <h5>
                <img src="@/assets/media/image/icon/car.png" />
                {{ $t("Transportation") }}
              </h5>
              <div class="all_hotels" v-if="package_transportations.length">
                <div
                  class="row"
                  v-for="(trans, transIndex) in package_transportations"
                  :key="transIndex"
                >
                  <div class="col-md-4 d-flex align-items-center">
                    <h5 class="title">{{ trans.title }}</h5>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="form-label" for="hotel_supplier">{{
                        $t("supplier")
                      }}</label>
                      <Multiselect
                        v-model="trans.supplier_id"
                        :options="providers"
                        label="name"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('choose')"
                        select-label=""
                        :hide-selected="true"
                      ></Multiselect>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label" for="hotel_adult_cost">{{
                        $t("adult_cost")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_adult_cost"
                        v-model="trans.adult_cost"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="hotel_adult_selling"
                        >{{ $t("adult_selling") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_adult_selling"
                        v-model="trans.adult_selling"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label" for="hotel_child_cost">{{
                        $t("child_cost")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_child_cost"
                        v-model="trans.child_cost"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="hotel_child_selling"
                        >{{ $t("child_selling") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_child_selling"
                        v-model="trans.child_selling"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="insurance_container">
              <h5>
                <img src="@/assets/media/image/icon/user-octagon.png" />
                {{ $t("Insurances") }}
              </h5>
              <div class="all_hotels" v-if="package_insurances.length">
                <div
                  class="row"
                  v-for="(insurance, insuranceIndex) in package_insurances"
                  :key="insuranceIndex"
                >
                  <div class="col-md-4 d-flex align-items-center">
                    <h5 class="title">{{ insurance.title }}</h5>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="form-label" for="hotel_supplier">{{
                        $t("supplier")
                      }}</label>
                      <Multiselect
                        v-model="insurance.supplier_id"
                        :options="providers"
                        label="name"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('choose')"
                        select-label=""
                        :hide-selected="true"
                      ></Multiselect>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label" for="hotel_adult_cost">{{
                        $t("adult_cost")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_adult_cost"
                        v-model="insurance.adult_cost"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="hotel_adult_selling"
                        >{{ $t("adult_selling") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_adult_selling"
                        v-model="insurance.adult_selling"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="form-label" for="hotel_child_cost">{{
                        $t("child_cost")
                      }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_child_cost"
                        v-model="insurance.child_cost"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="hotel_child_selling"
                        >{{ $t("child_selling") }}
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="hotel_child_selling"
                        v-model="insurance.child_selling"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="btn PrimaryButton"
              @click="save_supplier()"
            >
              <img src="@/assets/media/image/icon/tick-circle.png" />
              {{ $t("save") }}
            </button>
          </Dialog>
        </div>
      </div>
      <pagination
        :data="my_reservations"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchmy_reservations"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import * as XLSX from "xlsx/xlsx.mjs";
import showReceipt from "@/view/dashboard/wallet/showReceiptUmrah.vue";
import Swal from "sweetalert2";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
// import supplierDialog from "@/components/UmrahPackage/supplierDialog.vue";
import Dialog from "primevue/dialog";
import { Multiselect } from "vue-multiselect";
import Skeleton from "primevue/skeleton";

export default {
  name: "booksPackage",
  components: {
    pagination,
    showReceipt,
    AddFavDailog,
    // supplierDialog
    Dialog,
    Multiselect,
    Skeleton,
  },
  data() {
    return {
      loading: true,
      Package: "BookPackage",
      booksPackageText: "",
      my_reservations: {
        data: [],
      },
      website_link: JSON.parse(localStorage.getItem("user")).website_link,
      visible: false,
      supplier: {},
      providers: [],
      package_hotels: [],
      package_flights: [],
      package_visas: [],
      package_transportations: [],
      package_insurances: [],
      bookId: null,
      organizations: [],
    };
  },

  methods: {
    save_supplier() {
      let package_data = {
        book_package_id: this.bookId,
      };
      package_data.package_hotel = this.package_hotels.map((ele) => {
        return {
          supplier_id: ele.supplier_id?.id,
          package_hotel_id: ele.id,
          adult_cost: ele.adult_cost,
          child_cost: ele.child_cost,
          adult_selling: ele.adult_selling,
          child_selling: ele.child_selling,
        };
      });
      package_data.package_flight = this.package_flights.map((ele) => {
        return {
          supplier_id: ele.supplier_id?.id,
          package_flight_id: ele.id,
          adult_cost: ele.adult_cost,
          child_cost: ele.child_cost,
          adult_selling: ele.adult_selling,
          child_selling: ele.child_selling,
          inf_cost: ele.inf_cost,
          inf_selling: ele.inf_selling,
        };
      });
      package_data.package_visa = this.package_visas.map((ele) => {
        return {
          supplier_id: ele.supplier_id?.id,
          package_visa_id: ele.id,
          adult_cost: ele.adult_cost,
          child_cost: ele.child_cost,
          adult_selling: ele.adult_selling,
          child_selling: ele.child_selling,
        };
      });
      package_data.package_transportation = this.package_transportations.map(
        (ele) => {
          return {
            supplier_id: ele.supplier_id?.id,
            package_transportation_id: ele.id,
            adult_cost: ele.adult_cost,
            child_cost: ele.child_cost,
            adult_selling: ele.adult_selling,
            child_selling: ele.child_selling,
          };
        },
      );
      package_data.package_insurance = this.package_insurances.map((ele) => {
        return {
          supplier_id: ele.supplier_id?.id,
          package_insurance_id: ele.id,
          adult_cost: ele.adult_cost,
          child_cost: ele.child_cost,
          adult_selling: ele.adult_selling,
          child_selling: ele.child_selling,
        };
      });

      axios
        .post(`/store_package_book_supplier`, package_data)
        .then(({ data }) => {
          this.visible = false;
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
        });
    },
    upladReceipt(id, type) {
      axios
        .post(`/send_receipt_request`, { book_id: id, book_type: type })
        .then(() => {
          Swal.fire({
            title: "",
            text: this.$t("order_receipt_sent"),
            icon: "success",
          });
        });
    },
    fetchmy_reservations(page = 1) {
      axios
        .post(`/fetch_my_book_package?page=${page}`, {
          word: this.booksPackageText,
        })
        .then(({ data }) => {
          this.my_reservations = data.data;
          // console.log(this.my_reservations, "my_reservations");
          this.loading = false;
        });
    },
    // printContent() {
    //   let printContents = document.querySelector(".table-responsive").outerHTML;
    //   let originalContents = document.body.innerHTML;

    //   document.body.innerHTML = printContents;
    //   window.print();

    //   // Restore the original content after printing
    //   document.body.innerHTML = originalContents;
    // },
    exportToExcel() {
      // Check if XLSX is defined
      if (typeof XLSX === "undefined") {
        console.error(
          "XLSX is not defined. Make sure the library is imported correctly.",
        );
        return;
      }
      const tableData = [
        // Header row
        [
          this.$t("ID"),
          this.$t("date"),
          this.$t("program"),
          this.$t("date_trip"),
          this.$t("package_services"),
          this.$t("title"),
          this.$t("number_of_adults"),
          this.$t("number_of_childrens"),
          this.$t("total_price"),
          this.$t("Markters"),
          this.$t("coupons"),
          this.$t("Discount code"),
        ],
        // Data rows
        ...this.my_reservations.data.map((item) => [
          item.id,
          item.date,
          item.package.title,
          item.package.luanch_date,
          item.package.package_services
            .map((service) => this.$t(`${service}`))
            .join(" - "),
          item.package_guests.map((guest) => guest.first_name).join(", "),
          item.adult_count,
          item.child_count,
          item.total_price,
          `${item.marketer?.first_name ?? "-"} ${
            item.marketer?.last_name ?? "-"
          }`,
          item.coupon_code ? item.coupon_code : "-",
          item.discount_value ? item.discount_value : "-",
        ]),
      ];

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(tableData);

      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, "exported_data.xlsx");
    },
    select_supplier(bookId, package_id) {
      this.visible = true;
      this.FetchSuppliers();
      this.fetchPackageData(bookId, package_id);
    },
    FetchSuppliers() {
      axios.post("/fetch_consumer_by_role", { role: 2 }).then(({ data }) => {
        this.providers = data.data.data;
      });
    },
    fetchPackageData(bookId) {
      axios
        .post(`/fetch_package_details_titles`, { book_package_id: bookId })
        .then(({ data }) => {
          this.bookId = bookId;
          this.package_hotels = data.data.package_hotels;

          this.package_flights = data.data.package_flights;
          this.package_visas = data.data.package_visas;
          this.package_transportations = data.data.package_transportations;
          this.package_insurances = data.data.package_insurances;
        });
    },
    fetch_my_organization_acounts() {
      axios.post(`/fetch_my_organization_accounts`).then(({ data }) => {
        this.organizations = data.data.data;
      });
    },
  },
  mounted() {
    this.fetchmy_reservations();
    this.fetch_my_organization_acounts();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_user.scss";

.main_header_style {
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
.search-user {
  form {
    position: relative;
    i {
      position: absolute;
      top: 12px;
      right: 15px;
      color: #9ca2ac;
    }
    input {
      padding-right: 40px !important;
    }
  }
}
.btn_trip {
  border: 1px solid #96c28a;
  outline: none;
  padding: 0.5rem 0.8rem;
  color: #96c28a;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 0.2rem;
  font-family: "reqular";
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none !important;
}

.msg {
  border: none;
  outline: none;
  padding: 0.4rem 0.7rem;
  background-color: rgba($color: #e23535, $alpha: 10%);
  border-radius: 4px;
  color: #e23535;
}
.PrimaryButton {
  background-color: #96c28a !important;
}
.fw-bold {
  font-family: "bold" !important;
}
.PrimaryButton[data-v-07d1af3d]:hover {
  border: 1px solid #96c28a !important;
  color: #96c28a !important;
}
.coupons {
  color: #0b1427;
  font-family: "bold";
  font-size: 2rem;
}
.service {
  &:last-child {
    .dash {
      display: none;
    }
  }
}
.print_button {
  gap: 15px;
}
@media print {
  .table-responsive {
    font-size: 8px !important;
    td.details,
    th.details {
      display: none;
    }
    tbody {
      td {
        font-size: 7px !important;
      }
    }
  }
}

.hotels_container,
.flights_container,
.visa_container,
.transportation_container,
.insurance_container {
  padding-top: 10px;
  padding-bottom: 10px;
  h5 {
    img {
      margin-inline-end: 5px;
    }
    font-size: 16px;
    font-weight: 700;
  }
  .all_hotels {
    border: 1px solid #9ca2ac81;
    border-radius: 6px;
    .row {
      border-bottom: 1px dotted #d0d2d7;
      padding: 10px;
      max-width: 100%;
      margin: auto;
      &:last-child {
        border-bottom: unset;
      }
    }
  }
}
hr {
  width: 100%;
  height: 5px;
  background-color: #f5f5f5;
  border-top: unset;
  opacity: unset;
}
.PrimaryButton {
  margin-inline-start: unset;
  width: 25%;
}

.flights_container {
  .row {
    h5 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      // flex-wrap: wrap;
      .airplane {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        min-width: 100px;
        max-width: 100px;
        .line {
          height: 2px;
          background-color: #9ca2ac;
          flex-grow: 1;
        }
      }
      // .airplane_name{
      //   text-wrap: wrap
      // }
    }
  }
}
</style>
