<template>
  <div class="rooming_list_table">
    <!-- <h4>{{$t("rooming_list")}}</h4> -->
   <div class="table-responsive">
     <table class="table">
        <thead>
            <tr>
                <th>{{$t("room_id")}}</th>
                <th>{{$t("guest_name")}}</th>
                <th>{{$t("confirmation_number")}}</th>
                <th>{{$t("notes")}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="list in rooming_list" :key="list.room_id">
                <td> {{list.room_id}} </td>
                <td> <input type="text" name="guest_name" class="form-control" v-model="list.guest_name"/> </td>
                <td> <input type="number" name="confirmation_number" class="form-control" v-model="list.conformation_number"/> </td>
                <td> <input type="text" name="notes" class="form-control" v-model="list.notes"/> </td>
            </tr>
        </tbody>
    </table>
   </div>
  </div>
</template>

<script>
export default {
    name:"roomingList",
    props:["rooming_list"],
    data(){
        return{
            rooms:[
                {
                    guest_name:"yasmen",
                    confirmation_number: 123,
                    notes:"lorem fdjhjandjkn dasjdkjksjdks djskdjskdjksjdks"
                }
            ]
        }
    }
}
</script>

<style>

</style>