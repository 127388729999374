<template>
  <section class="card_style">
    <div class="d-flex align-items-center mb-2 gap-3">
      <AddFavDailog v-tooltip.left="$t('add_to_favorites')" />
      <h4>
        {{ $t("daily_constuct") }}
      </h4>
    </div>
    <div class="main_header_style mb-2">
      <input
        type="text"
        v-model="restrictionsText"
        @keyup="FetchConstruct(1)"
        class="form-control w-50"
        :placeholder="$t('search...')"
      />
      <div class="d-flex align-items-center gap-3">
        <router-link to="/restrictions/add" class="btn PrimaryButton">
          <i class="fa-solid fa-plus"></i> {{ $t("new_consruct") }}
        </router-link>
        <button
          class="SecondButton btn action d-flex align-items-center mx-2"
          @click="exportToExcel"
        >
          <i class="fa-solid fa-file-excel"></i>
          {{ $t("export_to_excel") }}
        </button>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table dailyCons" id="exchangeTable">
          <thead>
            <tr class="text-center">
              <th>{{ $t("Registration_number") }}</th>
              <th>{{ $t("Bond No") }}</th>
              <th style="width: 100px">{{ $t("restriction_date") }}</th>
              <th>{{ $t("account") }}({{ $t("debtor") }})</th>
              <th>{{ $t("account") }}({{ $t("creditor") }})</th>
              <th>{{ $t("the amount") }}</th>
              <th class="w-25">{{ $t("statement") }}</th>
              <th>{{ $t("employee") }}</th>
              <!-- <th>{{ $t("details") }}</th> -->
            </tr>
          </thead>

          <tbody v-if="loading" class="text-center">
            <tr v-for="item in 10" :key="item">
              <td v-for="item in 9" :key="item">
                <Skeleton height="2rem" width="90%" class="mb-2"></Skeleton>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in construct.data" :key="index">
              <td>
                <button
                  @click="getTransferItem(item)"
                  data-bs-toggle="offcanvas"
                  :data-bs-target="`#offcanvasBottomDetails${transferItem.id}`"
                  aria-controls="offcanvasBottom"
                  :title="$t('details')"
                  class="btn text-primary cursor-pointer"
                >{{ item.main_serial_number }}</button>
              </td>
              <td>
                {{
                  item.exchange_serial_number
                    ? item.exchange_serial_number
                    : item.receipt_serial_number
                      ? item.receipt_serial_number
                      : ""
                }}
              </td>
              <td>{{ item.date }}</td>
              <td>
                <div class="d-flex flex-column">
                  <span v-for="(from, index) in item.from" :key="index">
                    - {{ from?.branch?.title }}
                  </span>
                </div>
              </td>
              <td>
                <p v-for="(to, index) in item.to" :key="index">
                  - {{ to?.branch?.title }}
                </p>
              </td>
              <td>{{ item.amount }}</td>
              <td>{{ item?.text ? item?.text : "-" }}</td>
              <td class="text-center">{{ item.employee?.name }}</td>
              <!-- <td>
                <button
                  @click="getTransferItem(item)"
                  class="btn PrimaryButton"
                  type="button"
                  data-bs-toggle="offcanvas"
                  :data-bs-target="`#offcanvasBottomDetails${transferItem.id}`"
                  aria-controls="offcanvasBottom"
                  :title="$t('details')"
                >
                  <i class="fa-solid fa-info"></i>
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="construct"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="FetchConstruct"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
  <addRestrictions @FetchConstruct="FetchConstruct" />
  <detailsOffCanvas :item="transferItem" />
</template>

<script>
import addRestrictions from "@/components/dailyConstruct/addRestrictions.vue";
import detailsOffCanvas from "@/components/dailyConstruct/detailsOffCanvas.vue";
import AddFavDailog from "@/components/layout/addFavDailog.vue";
import * as XLSX from "xlsx/xlsx.mjs";
import Skeleton from "primevue/skeleton";
import axios from "axios";
import pagination from "laravel-vue-pagination";
export default {
  name: "daily-construct",
  components: {
    pagination,
    addRestrictions,
    detailsOffCanvas,
    AddFavDailog,
    Skeleton,
  },
  data() {
    return {
      construct: [],
      transferItem: {},
      restrictionsText: "",
      loading: true,
    };
  },
  methods: {
    // fetch exchange data
    FetchConstruct(page = 1) {
      axios
        .post(`/fetch_daily_restrictions?page=${page}`, {
          word: this.restrictionsText,
          per_page: 10,
        })
        .then(({ data }) => {
          this.construct = data.data;
          this.loading = false;
        });
    },
    async exportToExcel() {
      try {
        const response = await axios.post("/fetch_daily_restrictions", {
          per_page: 0,
        });
        const responseData = response.data.data.data;

        const headers = Object.keys(responseData[0]);

        // Create tableData array
        const tableData = [
          // Header row
          headers.map((header) => this.$t(header)),
          // Data rows
          ...responseData.map((item) => headers.map((header) => item[header])),
        ];

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(tableData);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "daily_restrictions_export.xlsx");
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    getTransferItem(item) {
      this.transferItem = item;
      // console.log(this.transferItem);
    },
  },
  mounted() {
    this.FetchConstruct();
  },
};
</script>

<style lang="scss" scoped>
@import "./_constructor.scss";
</style>
