<script setup>
import InputSwitch from "primevue/inputswitch";
import Multiselect from "vue-multiselect";
import pagination from "laravel-vue-pagination";
import axios from "axios";
import Swal from "sweetalert2";
import { ref, onMounted } from "vue";

// Data (reactive state variables)
const checked = ref(true);
const options = ref([]);
const option = ref([]);
const value = ref(null);
const valueType = ref("fixed"); // Default valueType
const sharks = ref([]);
// Methods
const addPortalSetting = async () => {
  try {
    const formData = new FormData();
    formData.append("value", value.value);
    formData.append("value_type", valueType.value === "fixed" ? 0 : 1);
    formData.append("is_for_all_sharks", checked.value ? 1 : 0);

    const response = await axios.post("/sharks/store_portal_setting", formData);

    await Swal.fire({
      icon: "success",
      title: "Success",
      text: response.data.message,
    });
  } catch (error) {
    console.error("Error adding portal setting:", error);
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || "An error occurred",
    });
  }
};
const fetchOrganizations = async () => {
  try {
    const response = await axios.get("/fetch_organizations");
    options.value = response.data.data;
  } catch (error) {
    console.error("Error fetching organizations:", error);
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || "An error occurred",
    });
  }
};
const addOrganization = async () => {
  try {
    const formData = new FormData();
    option.value.forEach((item, index) => {
      formData.append(`sharks[${index}][organization_id]`, item.id);
      // formData.append(`sharks[${index}][value]`, null);
      // formData.append(`sharks[${index}][value_type]`, null);
    });

    const response = await axios.post("/sharks/store_sharks", formData);
    await fetchSharks();
    await Swal.fire({
      icon: "success",
      title: "Success",
      text: response.data.message,
    });

    // Handle successful addition (implementation depends on your API)
  } catch (error) {
    console.error("Error adding organization:", error);
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || "An error occurred",
    });
  }
};
// onMounted(() => {
//   fetchOrganizations();
// });
const fetchSharks = async (page = 1) => {
  try {
    const response = await axios.post(`/sharks/fetch_sharks?page=${page}`);
    sharks.value = response.data.data.data;
  } catch (error) {
    console.error("Error fetching sharks:", error);
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || "An error occurred",
    });
  }
};
const deleteShark = async (id) => {
  try {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await axios.post(`/sharks/delete_shark`, {
          shark_id: id,
        });
        await fetchSharks();
        await Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.message,
        });
      }
    });
  } catch (error) {
    console.error("Error deleting shark:", error);
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || "An error occurred",
    });
  }
};
const updateShark = async (item) => {
  try {
    const formData = new FormData();
    formData.append("shark_id", item.id);
    formData.append("organization_id", item.organization_id);
    formData.append("value", item.value);
    formData.append("value_type", item.value_type === "fixed" ? 0 : 1);
    const response = await axios.post(`/sharks/update_shark`, formData);
    await fetchSharks();
    await Swal.fire({
      icon: "success",
      title: "Success",
      text: response.data.message,
    });
  } catch (error) {
    console.error("Error deleting shark:", error);
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || "An error occurred",
    });
  }
};
onMounted(() => {
  fetchSharks();
});
</script>

<template>
  <div class="portal">
    <form @submit.prevent="addPortalSetting" class="row">
      <!--      <div class="form-group col-lg-4 col-md-6 col-12">-->
      <!--        <label for="" class="form-label">{{ $t("default_percentage") }}</label>-->
      <!--        <input type="number" class="form-control" />-->
      <!--      </div>-->
      <div class="form-group col-lg-4 col-md-4 col-4">
        <label for="" class="form-label">{{ $t("default_percentage") }}</label>
        <input type="number" v-model="value" class="form-control" />
      </div>
      <div
        class="form-group col-lg-2 col-md-6 col-12 d-flex align-items-start justify-content-between"
      >
        <label for="" class="form-label">{{ $t("value_type") }} :</label>
        <div class="d-flex flex-column">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              value="percent"
              v-model="valueType"
            />
            <label class="form-check-label" for="flexRadioDefault1"> % </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              value="fixed"
              checked
              v-model="valueType"
            />
            <label class="form-check-label" for="flexRadioDefault2"> $ </label>
          </div>
        </div>
      </div>
      <div
        class="from-group col-lg-2 col-md-4 col-4 d-flex align-items-center justify-content-center"
      >
        <label for="" class="form-label">{{ $t("all") }}</label>
        <InputSwitch v-model="checked" />
      </div>
      <div
        class="form-group col-12 col-md-2 col-lg-2 d-flex align-items-center justify-content-center"
      >
        <button class="btn PrimaryButton" type="submit">
          {{ $t("save") }}
        </button>
      </div>
    </form>
    <form @submit.prevent="addOrganization" class="row">
      <div class="form-group col-lg-4 col-12">
        <label for="" class="form-label">{{ $t("choose orgnaization") }}</label>
        <Multiselect
          v-model="option"
          :options="options"
          :searchable="true"
          :hide-selected="true"
          multiple="true"
          :close-on-select="true"
          :show-labels="false"
          label="name"
          track-by="id"
          placeholder="Pick one"
          @open="fetchOrganizations"
        ></Multiselect>
      </div>
      <div class="col-lg-8 col-12 d-flex align-items-center">
        <button
          class="btn mx-0 my-0 PrimaryButton"
          style="margin: 0 !important"
        >
          {{ $t("add") }}
        </button>
      </div>
    </form>
    <div
      class="row border rounded px-2 py-2 shadow align-items-center"
      v-for="(item, index) in sharks"
      :key="index"
    >
      <div class="col-4 border-start">
        <p class="mb-0">{{ item.organization?.name }}</p>
      </div>
      <div
        class="col-4 pt-2 border-start form-group d-flex gap-2 align-items-center justify-content-between"
      >
        <label for="" class="form-label mb-0">{{ $t("value") }}</label>
        <input type="text" v-model="item.value" class="form-control" />
      </div>
      <div
        class="col-1 pt-2 border-start form-group d-flex align-items-center justify-content-between"
      >
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            :name="'flexRadioDefault' + index"
            id="flexRadioDefault1"
            value="percent"
            :checked="item.value_type === 1"
            v-model="item.value_type"
          />
          <label class="form-check-label" for="flexRadioDefault1"> % </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            :name="'flexRadioDefault' + index"
            id="flexRadioDefault2"
            value="fixed"
            :checked="item.value_type === 0"
            v-model="item.value_type"
          />
          <label class="form-check-label" for="flexRadioDefault2"> $ </label>
        </div>
      </div>
      <div class="col-3 d-flex align-items-center justify-content-end gap-2">
        <button
          type="button"
          class="btn btn-primary"
          v-tooltip="$t('edit')"
          @click="updateShark(item)"
        >
          <i class="fa-solid fa-floppy-disk"></i>
        </button>
        <button
          type="button"
          class="btn btn-danger"
          v-tooltip="$t('delete')"
          @click="deleteShark(item.id)"
        >
          <i class="fa-solid fa-trash"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="sharks"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchSharks"
      />
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
