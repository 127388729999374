<template>
  <section class="all_emp card_style">
    <div class="main_header_style statuses">
      <h4>{{ $t("Web Statuses") }}</h4>
      <router-link to="/web_statuses/add" class="PrimaryButton btn">
        <i class="fa-solid fa-plus"></i> {{ $t("add status") }}
      </router-link>
    </div>

    <div class="row">
      <div class="table-responsive">
        <table class="table web-statuses">
          <thead>
            <tr>
              <th>{{ $t("ID") }}</th>
              <th>{{ $t("Image") }}</th>
              <th>{{ $t("email") }}</th>
              <th>{{ $t("phone") }}</th>
              <th>{{ $t("location") }}</th>
              <th>{{ $t("Control") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(status, index) in statuses.data" :key="index">
              <td>{{ status.id }}</td>
              <td><img :src="status.image" :alt="status.email" /></td>
              <td>{{ status.email }}</td>
              <td>{{ status.phone }}</td>
              <td>{{ status.location }}</td>
              <td>
                <router-link
                  :to="`/web_statuses/edit/${status.id}`"
                  class="btn btn-success mx-1 text-white"
                >
                  <i class="fa-regular fa-pen-to-square"></i>
                </router-link>

                <button
                  class="btn btn-danger mx-1 text-white"
                  @click="deleteService(status.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <pagination
        :data="statuses"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchStatuses"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";

export default {
  name: "webStatuses",
  data() {
    return {
      statuses: [],
    };
  },
  components: {
    pagination,
  },
  methods: {
    fetchStatuses(page = 1) {
      axios.get(`/web_statuses?page=${page}`).then(({ data }) => {
        this.statuses = data.data;
      });
    },
    deleteService(statusId, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          const fromClick = true;
          axios.delete(`/website/web_statuses/${statusId}`, { fromClick }).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.statuses.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
          });
        }
      });
    },
  },
  mounted() {
    this.fetchStatuses();
  },
};
</script>

<style></style>
