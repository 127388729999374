export function useDeleteItem(dataRef) {
  const handleDelete = (index) => {
    if (dataRef.value) {
      // Check if dataRef.value exists
      if (index >= 0 && index < dataRef.value.length) {
        dataRef.value.splice(index, 1);
      } else {
        console.error("Invalid index for deletion");
      }
    } else {
      console.warn("Data not yet available for deletion"); // Optional warning
    }
  };

  return { handleDelete };
}
