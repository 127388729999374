<template>
  <div class="d-flex">
    <div class="card width_nav">
      <TabView>
        <TabPanel :header="$t('hotels')">
          <Hotels_comp />
        </TabPanel>
        <TabPanel :header="$t('flight')"> <Flights_comp /> </TabPanel>
        <TabPanel :header="$t('umrah_trips')">
          <Umrah_trips />
        </TabPanel>
      </TabView>
    </div>
    <div class="text w_text pt-2 points_current">
      <p class="mb-0 mx-3 text-point text-center">
        {{ $t("current balance") }}
      </p>
      <div class="d-flex flex-row-reverse">
        <h5 class="point px-1">
          {{ wallet }}
        </h5>
        <h5 class="point">SAR</h5>
      </div>
    </div>
  </div>
</template>

<script>
  import TabView from "primevue/tabview";
  import TabPanel from "primevue/tabpanel";
  import Hotels_comp from "@/components/New_Home/Hotels.vue";
  import Flights_comp from "@/components/New_Home/Flights.vue";
  import Umrah_trips from "@/components/New_Home/Umrah_trips.vue";
  import axios from "axios";
  export default {
    name: "dashBoard",
    data() {
      return {
        wallet: 0,
      };
    },
    components: {
      TabView,
      TabPanel,
      Hotels_comp,
      Flights_comp,
      Umrah_trips,
    },
    methods: {
      fetch_wallet() {
        axios.get("/fetch_wallet").then(({ data }) => {
          this.wallet = data.data.wallet;
          // console.log(data, "wee");
        });
      },
    },
    mounted() {
      this.fetch_wallet();
    },
  };
</script>

<style lang="scss" scoped>
  .text[data-v-3352d802] {
    background: transparent !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    padding-top: 10px;
    h5 {
      margin-bottom: 0;
    }
  }

  .width_nav {
    width: 100% !important;
    border: none !important;
  }
  .p-tabview .p-tabview-nav {
    background: #ffffff;
    border: none !important;
    border-width: 0 !important;
  }
  .point {
    color: #0b1427;
    font-weight: bold;
    font-size: 1.3rem;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
</style>
