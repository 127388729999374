<template>
  <!-- {{ insurancesData }} -->
  <div class="Transformation_seeting">
    <div class="container">
      <div class="row border">
        <div class="col-lg-8 col-md-8 col-6">
          <div class="d-flex align-items-center justify-content-start p-2">
            <div class="d-flex align-items-center justify-content-center mx-1">
              <img src="@/assets/media/image/icon/user-octagon.svg" />
              <h6 class="hotel_settings m-0">
                {{ $t("Insurance settings") }}
              </h6>
            </div>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-4 col-6 border-start border-end bg-price d-flex align-items-center justify-content-center"
        >
          <div class="d-flex align-items-center justify-content-start p-2">
            <div class="d-flex align-items-center justify-content-center mx-1">
              <img src="@/assets/media/image/icon/moneys.svg" />
              <h6 class="hotel_settings m-0">{{ $t("prices") }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="row border-start border-end mb-2">
        <div class="col-lg-8 col-md-8">
          <div class="row">
            <div class="col-lg-6">
              <label class="typo__label custom_label">{{
                $t("insurance_type")
              }}</label>
              <multiselect
                v-model="insuranceValue"
                :options="insurances"
                label="titles"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('insurance_type')"
                select-label=""
                :hide-selected="true"
              ></multiselect>
              <div v-for="error in v$.insuranceValue.$errors" :key="error.$uid">
                <div class="invalid-feedback d-block">
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="typo__label custom_label">{{
                $t("company_name")
              }}</label>
              <multiselect
                v-model="insuranceCompany"
                :options="insurancesCompanies"
                label="title"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('company_name')"
                select-label=""
                :hide-selected="true"
              ></multiselect>
              <div
                v-for="error in v$.insuranceCompany.$errors"
                :key="error.$uid"
              >
                <div class="invalid-feedback d-block">
                  {{ error.$message }}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="typo__label custom_label">{{
                $t("Supplier")
              }}</label>
              <Multiselect
                v-model="supplierObj"
                :options="providers"
                label="name"
                track-by="id"
                :clear-on-select="true"
                :placeholder="$t('choose')"
                select-label=""
                :hide-selected="true"
              ></Multiselect>
              <div v-for="error in v$.supplierObj.$errors" :key="error.$uid">
                <div class="invalid-feedback d-block">
                  {{ error.$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="row">
            <div
              class="d-flex flex-column align-items-center"
              v-if="
                insuranceValue?.adults &&
                insuranceValue?.childrens &&
                insuranceCompany?.id
              "
            >
              <div class="w-100">
                <div class="d-flex align-items-center justify-content-start">
                  <span class="mt-4 mx-2">{{ $t("adults") }}</span>
                  <div class="mx-2">
                    <label class="typo__label custom_label m-0">{{
                      $t("cost")
                    }}</label>
                    <input
                      type="number"
                      class="multiselect__tags form-control text-end p-2"
                      placeholder="0"
                      v-model="insuranceValue.adults.cost"
                    />
                  </div>
                  <div class="mx-2">
                    <label class="typo__label custom_label m-0">{{
                      $t("selling")
                    }}</label>
                    <input
                      type="number"
                      class="multiselect__tags form-control text-end p-2"
                      placeholder="0"
                      v-model="insuranceValue.adults.selling"
                    />
                  </div>
                </div>
              </div>
              <div class="w-100">
                <div class="d-flex align-items-center justify-content-start">
                  <span class="mt-4 mx-2">{{ $t("childrens") }}</span>
                  <div class="mx-2">
                    <label class="typo__label custom_label m-0">{{
                      $t("cost")
                    }}</label>
                    <input
                      type="number"
                      class="multiselect__tags form-control text-end p-2"
                      placeholder="0"
                      v-model="insuranceValue.childrens.cost"
                    />
                  </div>
                  <div class="mx-2">
                    <label class="typo__label custom_label m-0">{{
                      $t("selling")
                    }}</label>
                    <input
                      type="number"
                      class="multiselect__tags form-control text-end p-2"
                      placeholder="0"
                      v-model="insuranceValue.childrens.selling"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Multiselect } from "vue-multiselect";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "hotelSettings",
  data() {
    return {
      insuranceValue: {},
      insuranceCompany: {},
      insurances: [],
      insurancesCompanies: [],
      supplierObj: {},
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      insuranceCompany: {
        required,
      },
      insuranceValue: {
        required,
      },
      supplierObj: {
        required,
      },
    };
  },
  props: ["insurancesData", "providers"],
  emits: ["saveInsuranceData"],
  components: {
    Multiselect,
  },
  methods: {
    getInsurance() {
      axios.post(`/get_all_insurance`).then(({ data }) => {
        this.insurances = data.data.data.map((ele) => {
          return {
            ...ele,
            adults: {
              cost: null,
              selling: null,
            },
            childrens: {
              cost: null,
              selling: null,
            },
          };
        });
        console.log(this.insurances);
      });
    },
    getIssuranceCompany() {
      axios.post(`/get_issurance_company`).then(({ data }) => {
        this.insurancesCompanies = data.data.data;
      });
    },
  },
  mounted() {
    this.getInsurance();
    this.getIssuranceCompany();

    // if (this.$route.params.id) {
    //   this.insurancesData.forEach((ele) => {
    //     // this.insuranceValue = ele.insurance;
    //     this.insuranceCompany = ele.insurance_company;
    //     this.insuranceValue = {
    //       ...ele.insurance,
    //       adults: {
    //         cost: ele.adult_cost,
    //         selling: ele.adult_selling,
    //       },
    //       childrens: {
    //         cost: ele.child_cost,
    //         selling: ele.child_selling,
    //       },
    //     };
    //     this.supplierObj = ele.supplier;
    //   });
    // }
  },
  watch: {
    insurancesData: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (this.$route.params.id) {
          console.log(newValue);
          newValue.forEach((element) => {
            this.insuranceCompany = element.insurance_company;
            this.insuranceValue = {
              ...element.insurance,
              adults: {
                cost: element?.adult_cost,
                selling: element?.adult_selling,
              },
              childrens: {
                cost: element?.child_cost,
                selling: element?.child_selling,
              },
            };
            this.supplierObj = element?.supplier;
          });
          let insuranceData = {
            insurance_id: this.insuranceValue?.id,
            insurance_company_id: this.insuranceCompany?.id,
            adult: this.insuranceValue?.adults,
            child: this.insuranceValue?.childrens,
            supplier_id: this.supplierObj?.id,
          };
          this.$emit("saveInsuranceData", insuranceData);
        }
      },
    },
  },
  updated() {
    let insuranceData = {
      insurance_id: this.insuranceValue?.id,
      insurance_company_id: this.insuranceCompany?.id,
      adult: this.insuranceValue?.adults,
      child: this.insuranceValue?.childrens,
      supplier_id: this.supplierObj?.id,
    };
    this.$emit("saveInsuranceData", insuranceData);
    console.log(insuranceData);
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0;
}
.Transformation_seeting {
  background-color: #fff;
  .title {
    font-family: "bold";
    color: #0b1427;
    font-size: 1.25rem;
  }
  .flight_num {
    color: #656b78;
    font-family: "regular";
    font-size: 0.8rem;
  }
  .flight-details {
    color: #0b1427;
    font-size: 1.2rem;
    font-family: "regular";
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
    width: min-content;
  }
  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }
  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }
  .hotel_settings {
    font-family: "bold";
    color: #0b1427;
    font-size: 1rem;
  }
  .subtitle-hotel-settings {
    color: #0b1427;
    font-size: 1rem;
  }

  .custom_label {
    font-size: 0.6875rem;
    font-family: "bold";
    color: #0b1427;
    position: relative;
    z-index: 22;
    // bottom: 5.5rem; //4.2rem
    top: 0.675rem;
    background: white;
    border-radius: 50%;
    text-align: center;
    color: #656b78;
    margin: 0 0.5rem;
  }
  .first_diriction {
    font-size: 1rem;
    font-family: "bold";
    color: #0b1427;
  }
  .form-check-input {
    margin: 0;
    float: none;
    border-color: #656b78;
  }
  .form-check-input:checked {
    margin: 0;
    float: none;
    border-color: #0a909b;
    background-color: #0a909b;
  }
  .form-check-label {
    color: #656b78;
    font-family: "bold";
    font-size: 1rem;
  }
}

.bg-wheate {
  background-color: #fdfbf8;
}

.bg-light-blue {
  background-color: rgba($color: #1664b8, $alpha: 3%);
}

.bg-price,
.adult {
  background-color: rgba($color: #0a909b, $alpha: 10%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap-4 {
  gap: 4.5rem !important;
}

.time {
  color: #0b1427;
  font-family: "bold";
  font-size: 1rem;
}
.number-available {
  border: 1px solid gray;
  width: fit-content;
  padding: 0 0.8rem;
  border-radius: 20px;
}
.live {
  color: #0a909b;
  font-family: "bold";
  font-size: 0.875rem;
}

// input[type="number"]::-webkit-outer-spin-button,
// input[type="number"]::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// input[type="number"] {
//   width: 50px;
// }

span {
  font-size: 0.875rem;
}
</style>
